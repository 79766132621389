import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {AppDataService} from "../../../../../core/services/AppDataService";
import {ItemProvider} from "../../../../../core/pages/item/providers/item-provider/item-provider.service";
import {Events} from "../../../../../framework/providers/events";
import {AdminService} from "../../../../../core/services/AdminService";
import {OrderService} from 'src/core/services/OrderService';
import {IonContent} from "@ionic/angular";
import {BusinessService} from "../../../../../core/services/BusinessService";

@Component({
    selector: 'app-inventory',
    templateUrl: './inventory.component.html',
    styleUrls: ['./inventory.component.scss'],
})
export class InventoryComponent implements OnInit {

    @Input()
    quickOrder;
    @Input()
    hideCartDetails = true;

    filters = {'topsec': true, 'includeInMenu': true, 'allimages': true, 'limit': 40}
    appDataKey = 'topSections'
    public data: object;
    public step = 0;
    public checkedData;
    public searchCode: string = '';

    itemViewSize: string = 'small';
    sections;

    inPageItems;
    page: number = 1;

    items;
    itemsCount;
    itemsReady = {};

    ready = true;
    currentSectionId;

    constructor(public appDataService: AppDataService,
                public businesService: BusinessService,
                public adminService: AdminService, public orderService: OrderService,
                public itemProvider: ItemProvider, public events: Events) {
    }

    public sectionsData = [];

    ngOnInit() {
        if (!this.data) {
            this.data = this.initObject();
            this.data['checkedData']['sections'] = true;
        }

        this.events.subscribe('chooseCurrentSection', (data) => {
            this.currentSectionId = data['sectionId'];
            this.inPageItems = this.sections[this.currentSectionId]['items'].slice(0, 20);
            this.page = 1;
        })

        let me = this;
        me.page = 1;
        me.currentSectionId = me.appDataService.appdata.topSections[0];

        this.setItems();
        this.events.subscribe('businessAppDataSets', (data) => {
            console.log('update items!');
            console.log('update items!');
            this.setItems();
        });
    }

    public setItems() {
        let me = this;

        console.log(me.appDataService.appdata);

        me.ready = false;
        setTimeout(function () {
            me.ready = true;
        })
        me.sections = me.appDataService.appdata['sections'];
        me.items = me.appDataService.appdata['items'];
        me.itemsCount = Object.keys(me.items).length;
        me.inPageItems = me.sections[me.currentSectionId]['items'].slice(0, 20);
        me.itemsReady = {};
    }

    public getItemModel(id) {
        if (!this.itemsReady[id]) {
            this.itemsReady[id] = this.appDataService.getItemModel(id);
            console.log(this.itemsReady[id]);
        }

        return this.itemsReady[id];
    }

    openModel(model) {
        if (model.prefix == 'item') {
            this.itemProvider.openItem(model);
            return;
        }

        if (this.data['checked'] == model.data.id) {
            this.data['checked'] = null;
            return
        }

        if (!this.data['checkedData'][model.data.id])
            this.data['checkedData'][model.data.id] = this.initObject(model);
        this.data['checked'] = model.data.id;

        this.setModelData(model.data.id);
        this.currentSectionId = model.data.id;
        // this.checkedData = this.data['checkedData'][model.data.id];
    }

    setModelData(modelId) {
        // this.data['models'] = this.appDataService.getSectionSubSections(modelId);
        this.data['items'] = this.appDataService.getSectionItems({'sectionId': modelId, 'limit': 100})['models'];
        //console.log'set items!');
        //console.logthis.data['items']);
    }

    initObject(model = null) {
        return {
            'model': model,
            'models': [],
            'checked': null,
            'checkedData': {
                'sections': true
            }
        }
    }

    nextPage() {
        this.page++;
        this.inPageItems = this.sections[this.currentSectionId]['items'].slice(0, this.page * 10);
    }

    onScroll() {
        let winel: HTMLElement = document.getElementById('itemsDivInt');
        console.log(winel.scrollTop);
        console.log(winel.scrollHeight);

        // console.log(this.itemsDiv.scrollHeight);
        if (winel.scrollHeight - 1200 < winel.scrollTop) {
            this.nextPage();
        }
    }

}
