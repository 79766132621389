import {Component, Input, OnInit} from '@angular/core';
import {NavController} from "@ionic/angular";
import {NavigationExtras} from "@angular/router";
import {Events} from "../../../../framework/providers/events";

@Component({
    selector: 'model-slider-headers',
    templateUrl: './model-slider-headers.component.html',
    styleUrls: ['./model-slider-headers.component.scss'],
})
export class ModelSliderHeadersComponent implements OnInit {

    @Input()
    public modelName: string;

    @Input()
    public filters: object = {};

    @Input()
    public options: object = {};

    @Input()
    public slidesPerView: number = 1;

    public models: Array<any> = [];

    @Input()
    public fields: Array<string> = [];

    public prefix = 'modelSlider_' + +Math.floor(Math.random() * Math.floor(1000000));

    @Input()
    public clickEvent: string;

    @Input()
    public appDataKey: string;

    @Input()
    public addParms: Array<string> = [];

    constructor(public events: Events, public navCtrl: NavController) {
    }

    ngOnInit() {
    }


    setModel(model) {
        if (!this.clickEvent) {
            let modelPages = {
                'section': 'section',
                'itemMaker': 'maker',
            }

            let data = {
                'model': model,
            }

            let navigationExtras: NavigationExtras = {
                queryParams: data
            };

            let page = modelPages[this.modelName];
            if (this.addParms.length) page += '/';
            for (var k in this.addParms)
                page += model.data[this.addParms[k]];
            this.navCtrl.navigateForward(page, navigationExtras);
        } else {
            this.events.publish(this.clickEvent, {model: model})
        }
    }


}
