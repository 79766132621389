import {Component, OnInit} from '@angular/core';

export class ItemToUser {
    data = {
        "id": null,
        "active": false,
        "weeklyStartStock": null,
        "weeklySold": null,
        "weeklyIncome": null,
        "weeklyReturned": null,
        "stock": null,
        "stockReported": false,
        "price": null,
        "comments": "",
        "business": null,
        "item": null,
        "appuser": null,
        "company": null
    };

    constructor(public data1: any) {
        this.data = data1;
    }

    //shortHeaders = [];
    prefix = "itemToUser";
    title = "itemToUser";
    dataFields = {
        id: {'title': 'ID', 'value': null, 'type': 'number', 'readonly': true},
        created: {'title': 'Created', 'value': null, 'type': 'date', 'readonly': true},
        updated: {'title': 'Updated', 'value': null, 'type': 'date', 'readonly': true},
        "weeklyStartStock": {'title': 'startStock', 'value': null, 'type': 'text'},
        "weeklySold": {'title': 'sold', 'value': null, 'type': 'text'},
        "weeklyIncome": {'title': 'income', 'value': null, 'type': 'text'},
        "weeklyReturned": {'title': 'returned', 'value': null, 'type': 'text'},
        "stock": {'title': 'availableCatNum', 'value': null, 'type': 'text'},
        "stockReported": {'title': 'stockReported', 'value': null, 'type': 'text'},
    }
}