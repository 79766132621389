import {Component, Input, OnInit} from '@angular/core';
import {ModelProvider} from "../../../../../../../framework/providers/modelProvider/ModelProvider";

import {PropsProvider} from "../../../../providers/props-provider/props-provider.service";
import {Events} from "../../../../../../../framework/providers/events";

@Component({
    selector: 'item-deal-props-type',
    templateUrl: './item-deal-props-type.component.html',
    styleUrls: ['./item-deal-props-type.component.scss'],
})
export class ItemDealPropsTypeComponent implements OnInit {

    @Input()
    public type: object;

    @Input()
    public options: object;

    @Input()
    public checkedProps: object;

    public ready: boolean = false;

    public requiredError: boolean = false;
    public focus: boolean = false;

    constructor(public modelProvider:ModelProvider, public events:Events, public propsProvider:PropsProvider) {
    }

    ngOnInit() {
        // //console.log"TYPE", this.type['id']);
        // //console.log"STR", JSON.stringify(this.checkedProps));

        // if (!this.checkedProps[this.type['id']]) {
        //     // //console.log"INIT TYPE!")
        //     this.checkedProps[this.type['id']] = {
        //         'data':this.type,
        //         'checked':{},
        //         'checkedData':{},
        //         'count':0
        //     }
        // }

        // //console.log"EX PROPS", this.checkedProps[this.type['id']]);
        // //console.log"COUNTER", this.checkedProps[this.type['id']]['count']);
        if(this.checkedProps[this.type['id']]['count']) return;
        //
        // //console.log"PASS")

        for(let k in this.type['props'])
        {
            if(this.type['props'][k]['default'])
            {
                this.setCheckedProp(this.type['props'][k])
            }
        }


        if(this.type['required'] && this.type['props'].length == 1)
        {
            this.setCheckedProp(this.type['props'][0]);
        }

        this.events.subscribe('typeFocus_'+this.type['id'], (params)=>{
            this.focus = true;
            this.requiredError = true;
            let me = this;
            setTimeout(function(){
                me.focus = false;
            }, 500)
        })
    }

    ngAfterViewInit() {
        let me = this;
        setTimeout(function()
        {
            me.ready = true;
        })
    }

    setCheckedProp(prop) {
        this.propsProvider.setCheckedProp(this.checkedProps, this.type, prop);
        // let typeres = this.checkedProps[this.type['id']];
        // let alreadycheckedprop;
        // if(!this.type['multiple']) {
        //     alreadycheckedprop = typeres['checked'][prop['id']];
        //     typeres['checked'] = {};
        //     typeres['count'] = 0;
        // }
        //
        //
        // if(this.type['multiple'] && this.type['amount'] &&   // if its multiple type.
        //     typeres['count'] == this.type['amount'] &&   // if limit is full.
        //     !typeres['checked'][prop['id']])  // if adding another one.
        // {
        //     return;  // skip.
        // }
        //
        // if(alreadycheckedprop)
        //     return;
        // typeres['checked'][prop['id']] = !typeres['checked'][prop['id']];
        // typeres['checked'][prop['id']] ? typeres['count']++ : typeres['count']--;
        // typeres['checkedData'][prop['id']] = prop;

    }

}
