import {Component, OnInit} from '@angular/core';
import {PopoverController} from "@ionic/angular";
import {AppItemComponent} from "../item/components/app-item/app-item.component";
import {AppUser} from "../../../framework/model/core/appuser/appuser";
import {OrderService} from "../../services/OrderService";
import {AgentKeyboardPage} from "../agent/agent-keyboard/agent-keyboard.page";
import {BusinessService} from "../../services/BusinessService";
import {ModalController} from "@ionic/angular";
import {Events} from "../../../framework/providers/events";

@Component({
    selector: 'app-catalog-list',
    templateUrl: './catalog-list.page.html',
    styleUrls: ['./catalog-list.page.scss'],
})
export class CatalogListPage implements OnInit {

    // public appuser: AppUser;
    // public tabsObj: object;
    // public tabs: Array<object> = [];

    public data:object;

    constructor(public events: Events, public popoverCtrl: PopoverController, public businessService:BusinessService,
                public orderService: OrderService, public modalController: ModalController) {

    }

    ngOnInit() {
    }


    // ngOnInit1() {
    //     this.appuser = this.orderService.getAppUser();
    //
    //     let business = this.businessService.getBusiness();
    //
    //     let partsType = business.data.GlobalSettingsData.quickNavKey;
    //
    //     let parts = [
    //         {'modelName': 'section', 'filterKey':'sectionId'},
    //         {'modelName': 'section', 'filterKey':'sectionId'},
    //         {'modelName': 'miniitem', 'filterKey':'sectionId'}
    //     ]
    //     if(partsType == 'maker')
    //     {
    //         parts[1]['modelName'] = 'itemMaker';
    //         parts[2]['filterKey'] = 'itemMaker'
    //     }
    //
    //     this.tabsObj = {
    //         'family': {
    //             'key': 'family', 'title': 'family', 'modelName': parts[0]['modelName'], 'active': true,
    //             'filters': {'topsec': true}, 'models': [], 'prefix': 'topSectionsList', 'options': {},
    //             'part': parts[0],
    //             'fields': ['name'],
    //             'onclick': this.setModel, 'events': this.events, 'next': 'maker'
    //         },
    //
    //         'maker': {
    //             'key': 'maker', 'title': 'makers', 'modelName': parts[1]['modelName'], 'active': false,
    //             'filters': {}, 'models': [], 'prefix': 'makerSectionsList', 'options': {},
    //             'part': parts[1],
    //             'fields': ['name'],
    //             'events': this.events, 'onclick': this.setModel, 'next': 'model'
    //         },
    //
    //         'model': {
    //             'key': 'model', 'title': 'items', 'modelName': parts[2]['modelName'], 'active': false,
    //             'filters': {}, 'models': [], 'prefix': 'makerModelsList', 'options': {},
    //             'fields': ['code', 'name'],
    //             'part': parts[2],
    //             'events': this.events,
    //             'onclick': this.setItem,
    //             'showItemPopuup': this.showItemPopuup,
    //             'showAgentKeyboard': this.showAgentKeyboard,
    //             'popoverCtrl': this.popoverCtrl,
    //             'modalController': this.modalController,
    //             'appuser': this.appuser
    //         },
    //     };
    //
    //     for (let k in this.tabsObj) // for ngFor.
    //     {
    //         this.tabs.push(this.tabsObj[k])
    //     }
    // }
    //
    // setModel(tabsObj, model, key) {
    //     let makerTab = tabsObj[tabsObj[key]['next']];
    //
    //     makerTab['active'] = true;
    //     makerTab['filters'][makerTab['part']['filterKey']] = model.data.id;
    //     makerTab['filters']['limit'] = 100;
    //     makerTab['models'].length = 0;
    //
    //     tabsObj[key]['activeModelId'] = model.data.id;
    //
    //     makerTab['events'].publish(makerTab['prefix'] + '_updateFilter', makerTab['filters'])
    // }
    //
    // setItem(tabsObj, model, key) {
    //     let keyobj = tabsObj[key]
    //
    //     keyobj['activeModelId'] = model.data.id;
    //     keyobj['activeModel'] = model;
    //
    //     // keyobj['activeModel'] = null;
    //
    //     if (keyobj['appuser'] && keyobj['appuser'].data.userType == 3) {
    //         keyobj['showAgentKeyboard'](model, keyobj['modalController'])
    //     } else {
    //         keyobj['showItemPopuup'](model, keyobj['popoverCtrl'])
    //     }
    //
    //     // setTimeout(function () {
    //     //   tabsObj[key]['activeModel'] = model;
    //     // })
    // }
    //
    // async showItemPopuup(item, popoverCtrl) {
    //     const popover = await popoverCtrl.create({
    //         component: AppItemComponent,
    //         componentProps: {
    //             'model': item
    //         },
    //         event: null,
    //         animated: true,
    //         showBackdrop: true
    //     });
    //     return await popover.present();
    // }
    //
    //
    // async showAgentKeyboard(model, modalController) {
    //     //console.logmodel)
    //     const modal = await modalController.create({
    //         component: AgentKeyboardPage,
    //         componentProps: {
    //             'model': model,
    //             'cartItem': {},
    //         }
    //     });
    //
    //     return await modal.present();
    // }

}
