// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tab-col {
  padding: 5px 2px;
}
.tab-col .list-box {
  max-height: 500px;
  overflow: scroll;
}

.model-box ion-label {
  font-size: 15px;
}
.model-box ion-label p {
  overflow: initial;
  white-space: pre-line;
  margin: 0;
}
.model-box ion-label.active {
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/core/pages/catalog-list/catalog-list.page.scss"],"names":[],"mappings":"AAAA;EAEE,gBAAA;AAAF;AAEE;EACE,iBAAA;EACA,gBAAA;AAAJ;;AAME;EACE,eAAA;AAHJ;AAKI;EACE,iBAAA;EACA,qBAAA;EACA,SAAA;AAHN;AAOE;EAEE,iBAAA;AANJ","sourcesContent":[".tab-col\n{\n  padding: 5px 2px;\n\n  .list-box {\n    max-height: 500px;\n    overflow: scroll;\n  }\n}\n\n.model-box\n{\n  ion-label{\n    font-size: 15px;\n\n    p{\n      overflow: initial;\n      white-space: pre-line;\n      margin: 0;\n    }\n  }\n\n  ion-label.active\n  {\n    font-weight: bold;\n  }\n\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
