import {Component, Input, OnInit} from '@angular/core';
import {Events} from "../../../../../../framework/providers/events";
import {OrderService} from "../../../../../../core/services/OrderService";
import {AdminService} from "../../../../../../core/services/AdminService";

@Component({
    selector: 'app-rec-sections',
    templateUrl: './rec-sections.component.html',
    styleUrls: ['./rec-sections.component.scss'],
})
export class RecSectionsComponent implements OnInit {

    currentSectionId;

    @Input()
    sections;
    @Input()
    showSectionsId;

    constructor(public event: Events, public orderService: OrderService, public adminService: AdminService) {
    }

    ngOnInit() {
        this.currentSectionId = this.sections[0];
    }

    openModel(sectionId) {
        if (this.currentSectionId == sectionId) {
            this.currentSectionId = null;
        } else {
            this.currentSectionId = sectionId;
            this.event.publish('chooseCurrentSection', {'sectionId': sectionId});
        }
    }
}
