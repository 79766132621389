import {Pipe, PipeTransform} from '@angular/core';
import * as moment from "moment";

@Pipe({
    name: 'customDate'
})
export class CustomDatePipe implements PipeTransform {
    transform(value: string | null | undefined, format: string = 'MM/dd/yy') {
        if (!value) {
            return 'no date';//null;
        }
        if (!format) {
            format = 'MM/dd/yy';
        }

        const dateValue = moment(value);
        if (!dateValue.isValid()) {
            return null;
        }

        return dateValue.format(format)
    }
}
