import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {OrderService} from "../../../../services/OrderService";
import {BusinessService} from "../../../../services/BusinessService";
import {ModelToolsProvider} from "../../../../../framework/providers/model-tools/model-tools";
import {Events} from "../../../../../framework/providers/events";
import {CartService} from "../../../../services/CartService";


@Component({
    selector: 'app-ticket-header',
    templateUrl: './ticket-header.component.html',
    styleUrls: ['./ticket-header.component.scss'],
})
export class TicketHeaderComponent implements OnInit {
    public business;
    public cart;
    public cartData;

    @Input()
    public ticket = null;

    public sub_prefix: string = 'sub_' + Math.floor(Math.random() * Math.floor(1000000));

    constructor(public orderService:OrderService,
                public businessService:BusinessService,
                public modelTools:ModelToolsProvider,
                public changeRef:ChangeDetectorRef,
                public cartService:CartService,
                public events:Events) {
    }

    ngOnInit() {

        this.business = this.businessService.getBusiness();

        this.events.subscribe('cartUpdated', (res)=>{
            this.getCart();
        }, this.sub_prefix);

        this.events.subscribe('activeTicketChanged', (res)=>{
            this.setActiveTicket();
        }, this.sub_prefix);

        this.getCart();
    }

    ngOnDestroy()
    {
        this.events.unsubscribe('cartUpdated', this.sub_prefix);
        this.events.unsubscribe('activeTicketChanged', this.sub_prefix);
    }

    getCart()
    {
        this.cart = this.orderService.getCart();
        this.cartData = this.orderService.getCartData();

        this.setActiveTicket();

        this.changeRef.detectChanges();
    }

    setActiveTicket() {


        if(!this.ticket) this.ticket = {
            'name':'all',
            'id':-1,
            'data': this.cartData
        }

        // //console.logthis.cart);
        // //console.logthis.cartData);
        // if (!this.ticket) this.ticket = this.cart[this.cartData['curTicket']];

        // this.orderService.setActiveTicket(ticket);
    }

    editTicket(ticket) {
        ticket['pre_name'] = ticket['name'];
        ticket['edit'] = true;
        this.setFocus(ticket['id']);
    }

    setTicketName(ticket)
    {
        this.cartService.setTicketName(ticket['id'], ticket['name']);
        ticket['edit'] = false;
    }

    cancelTicketName(ticket)
    {
        ticket['name'] = ticket['pre_name'];
        ticket['edit'] = false;
    }

    removeTicket(ticket)
    {
        let me = this;
        this.modelTools.yesOrNoAlert(function()
        {
            ticket['edit'] = false;
            me.cartService.removeTicket(ticket['id']);
            // me.getCart();
        })
    }


    setFocus(id) {
        let input = document.getElementById(id + '_input');

        setTimeout(function()
        {
            input.focus();
        })
    }



}
