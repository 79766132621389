// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.relative .name-box {
  font-size: 20px;
  padding: 0;
  text-align: center;
}

.noStyle ion-card {
  border-radius: 0;
  background: none;
  box-shadow: none;
}
.noStyle .name-box {
  background: none;
  position: initial;
  text-shadow: none;
  font-size: 17px;
}

.horiz-panel.name-box {
  display: table;
}
.horiz-panel.name-box div {
  text-align: start;
  vertical-align: middle;
  display: table-cell;
}

.search-box {
  border: 1px solid #ccc;
  color: #fff;
}
.search-box .name-box {
  vertical-align: middle;
}

.topsec-slider img {
  width: auto;
  max-height: 150px;
}`, "",{"version":3,"sources":["webpack://./src/core/components/panels/panel-element/panel-element.component.scss"],"names":[],"mappings":"AACA;EACE,eAAA;EACA,UAAA;EACA,kBAAA;AAAF;;AAgBE;EACE,gBAAA;EACA,gBAAA;EACA,gBAAA;AAbJ;AAgBE;EACE,gBAAA;EACA,iBAAA;EACA,iBAAA;EACA,eAAA;AAdJ;;AAkBA;EACI,cAAA;AAfJ;AAiBI;EACE,iBAAA;EACA,sBAAA;EACA,mBAAA;AAfN;;AAmBA;EACE,sBAAA;EACA,WAAA;AAhBF;AAkBE;EAEE,sBAAA;AAjBJ;;AAyBE;EACE,WAAA;EACA,iBAAA;AAtBJ","sourcesContent":["\n.relative .name-box {\n  font-size: 20px;\n  padding: 0;\n  text-align: center;\n  //padding-bottom: 0;\n}\n\n//this is acting bad in ios/iphone\n.nav-btn\n{\n  //height: -webkit-fill-available;\n}\n\n//.nav-btn:active\n//{\n//  opacity: 0.6;\n//}\n\n.noStyle {\n  ion-card {\n    border-radius: 0;\n    background: none;\n    box-shadow: none;\n  }\n\n  .name-box {\n    background: none;\n    position: initial;\n    text-shadow: none;\n    font-size: 17px;\n  }\n}\n\n.horiz-panel.name-box {\n    display: table;\n\n    div{\n      text-align: start;\n      vertical-align: middle;\n      display: table-cell;\n    }\n}\n\n.search-box {\n  border: 1px solid #ccc;\n  color: #fff;\n\n  .name-box\n  {\n    vertical-align: middle;\n  }\n}\n\n.topsec-slider\n{\n  //max-height: 150px;\n\n  img{\n    width: auto;\n    max-height: 150px;\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
