import {Component, OnInit} from '@angular/core';
import {Business} from "../../../framework/model/core/business/business";
import {BusinessImages} from "../../../framework/model/core/business/businessImages";
import {BusinessService} from "../../services/BusinessService";
import {PopoverController} from "@ionic/angular";
import {GlobalSettings} from "../../../framework/model/core/business/globalSettings";

@Component({
    selector: 'app-platforms-download',
    templateUrl: './platforms-download.component.html',
    styleUrls: ['./platforms-download.component.scss'],
})
export class PlatformsDownloadComponent implements OnInit {
    public business: Business;
    public businessImages: BusinessImages;

    constructor(public businessService: BusinessService, public popoverctrl:PopoverController) {
    }

    ngOnInit() {
        this.business = this.businessService.getBusiness();
        this.businessImages = this.businessService.getImages();
    }

    download(field){
        let url = this.business.data.GlobalSettingsData[field+'Link']
        window.open(url, "_blank");
    }

    close() {
        this.popoverctrl.dismiss();
    }

}
