import {Component, Input, OnInit} from '@angular/core';
import {AppDataService} from "../../../../services/AppDataService";
import {ModelToolsProvider} from "../../../../../framework/providers/model-tools/model-tools";
import {BusinessService} from "../../../../services/BusinessService";
import {OrderService} from "../../../../services/OrderService";

@Component({
    selector: 'app-menu-nav',
    templateUrl: './menu-nav.component.html',
    styleUrls: ['./menu-nav.component.scss'],
})
export class MenuNavComponent implements OnInit {

    public models;
    public currentSection;
    public subSections;

    @Input()
    public sectionId;

    public options = {};

    constructor(public businessService: BusinessService, public orderService: OrderService,
                public modelTools: ModelToolsProvider,
                public appDataService: AppDataService) {
    }

    ngOnInit() {
        if (!this.sectionId) {
            let data = this.appDataService.getModelsByKey('topSections', 'section');
            this.models = data['models'];
        } else {
            this.models = this.appDataService.getSectionSubSections(this.sectionId);
            this.subSections = this.appDataService.getSectionSubSections(this.sectionId);
        }

        this.modelTools.getKey('sectionOptions').then((res: object) => {
            if (!res)
                return

            for (let key in res) {
                this.options[key] = res[key];
            }
        });
    }

    goTo(page, params = {}) {
        this.modelTools.goTo(page, params);
    }

    chooseSection(model) {
        if (this.currentSection == model) {
            this.currentSection = null;
            return;
        }
        this.goTo('section/' + model.data['id']);
        this.currentSection = model;

        this.subSections = this.appDataService.getSectionSubSections(this.currentSection.data.id);
    }

}

