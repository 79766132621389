// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.model-box {
  margin: 0;
  width: 100%;
  background: #fff;
}
.model-box .img-box {
  width: 100%;
  vertical-align: middle;
  display: table;
}
.model-box .img-box app-img {
  vertical-align: middle;
  display: table-cell;
  width: 100%;
}

ion-slides {
  --bullet-background: var(--button-b-background);
  --bullet-background-active: var(--button-a-background);
}`, "",{"version":3,"sources":["webpack://./src/core/components/sliders/images-slider/images-slider.component.scss"],"names":[],"mappings":"AAAA;EAEE,SAAA;EACA,WAAA;EACA,gBAAA;AAAF;AAGE;EAEE,WAAA;EAGA,sBAAA;EACA,cAAA;AAJJ;AAME;EACE,sBAAA;EACA,mBAAA;EACA,WAAA;AAJJ;;AAQA;EAEE,+CAAA;EACA,sDAAA;AANF","sourcesContent":[".model-box\n{\n  margin: 0;\n  width: 100%;\n  background: #fff;\n  //padding: 10px;\n\n  .img-box\n  {\n    width: 100%;\n    //height: 90px;\n    //max-height: 90px;\n    vertical-align: middle;\n    display: table;\n  }\n  .img-box app-img {\n    vertical-align: middle;\n    display: table-cell;\n    width: 100%;\n  }\n}\n\nion-slides\n{\n  --bullet-background: var(--button-b-background);\n  --bullet-background-active: var(--button-a-background);\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
