import {Component, Input, OnInit} from '@angular/core';
import {Item} from "../../../../../framework/model/core/items/item";
import {AppUser} from "../../../../../framework/model/core/appuser/appuser";
import {ModelToolsProvider} from "../../../../../framework/providers/model-tools/model-tools";
import {ModelProvider} from "../../../../../framework/providers/modelProvider/ModelProvider";
import {Events} from "../../../../../framework/providers/events";
import {PostComment} from "../../../../../framework/model/core/post/postComment";

@Component({
    selector: 'app-item-reviews',
    templateUrl: './item-reviews.component.html',
    styleUrls: ['./item-reviews.component.scss'],
})
export class ItemReviewsComponent implements OnInit {

    @Input()
    public appuser: AppUser;

    @Input()
    public model: Item;

    public postComment: PostComment;
    public models:Array<PostComment> = [];

    constructor(public modelToolsProvider: ModelToolsProvider, public modelProvider: ModelProvider, public events: Events) {
    }

    ngOnInit() {
    }


    setFavorite() {
        if (!this.appuser) {
            this.modelToolsProvider.goTo('login', {});
            return
        }

        this.modelProvider.customPost('itemComment', this.postComment.data, {'setPrefix': true})

            .then((res:any) => {
                // this.model.data.favorite = res['res'];
                if (res['res'] == -1) return
                this.model.data['rate_data'] = res.data;
                this.postComment = null;

                // if (this.model.data.favorite)
                  this.modelProvider.presentToast(this.modelToolsProvider.getTranslate('sendSuccess'));
                // this.events.publish('favorites_updated');
            });
    }

    setRate(k) {
        this.postComment = new PostComment({
            'rate': k,
            'item': this.model.data.id,
        })
        // this.setFavorite(k)
    }

}
