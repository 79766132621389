export class AppUser {
    data = {
        id: null,
        name: null,
        username: null,
        cellphone: null,
        email: null,
        companyId: null,
        confirmPassword: null,
        userType: null,
        companyName: null,
        companyFileNumber: null,
        internalCompanyId: null,
        companyApproved:null,
        companyPriceApproved: null,
        companyOurId: null,
        companyStatus: null,
        pushId: null,
        companyAgent: null,
        images: null,
        qrImage: null,
        qrImageData: null,
        bisType: null,
        bisSubType: null,
        businessCode: null,
        paymentVerified: null,
        licenseApproved: null,
        exLink: null,
        notfication: null,
        approved: null,
        validate: null,
        companyGroup: null,
        prefix: null,
        company_emails: null,
        group_id: null,

    };

    constructor(public data1: any) {
        this.data = data1;
    }

    //shortHeaders = [];
    prefix = 'appuser';
    title = 'User';
    dataFields = {
        id: {'title': 'ID', 'value': null, 'type': 'number', 'readonly': true},
        created: {'title': 'Created', 'value': null, 'type': 'date', 'readonly': true},
        updated: {'title': 'Updated', 'value': null, 'type': 'date', 'readonly': true},
        name: {'title': 'name', 'value': null, 'type': 'text','maxlength':30, 'required':true},
        area: {'title': 'area', 'value': null, 'type': 'text'},
        username: {'title': 'email', 'value': null, 'type': 'email','maxlength':30, 'required':true},
        email: {'title': 'email', 'value': null, 'type': 'email','maxlength':40},
        password: {'title': 'password', 'value': null, 'type': 'password','maxlength':30, 'required':true},
        confirmPassword: {'title': 'confirmPassword', 'value': null, 'type': 'password','maxlength':50, 'required':true},
        cellphone: {'title': 'cellphone', 'value': null, 'type': 'tel','maxlength':50, 'required':true},
        birthdate: {'title': 'birthdate', 'value': null, 'type': 'date', 'readonly': true},
        notfication: {'title': 'notfication', 'value': null, 'type': 'boolean', 'readonly': true},
        notfToEmail: {'title': 'notfToEmail', 'value': null, 'type': 'boolean'},
        companyId: {'showDefault': true, 'value': null, 'title':'company',
            'filters': {},
            'displayField': 'companyName', 'type': 'modelscroll',
            'modelscroll': 'Company', 'keyField': 'id', 'valueField': 'companyName',},

        companyName: {'title': 'companyName', 'value': null, 'type': 'text','maxlength':60, 'required':true},
        companyApproved: {'title': 'companyApproved', 'value': null, 'type': 'boolean'},
        companyPriceApproved: {'title': 'companyPriceApproved', 'value': null, 'type': 'boolean'},
        companyFileNumber: {'title': 'companyFileNumber', 'value': null, 'type': 'tel','maxlength':9, 'minlength':9, 'forceLength':true, 'required':true},
        companyStatus: {'title': 'companyStatus', 'value': null, 'type': 'text'},
        internalCompanyId: {'title': 'internalCompanyNumber', 'value': null, 'type': 'number','maxlength':30, 'readonly': true},
        'clientType': {
            'title': 'clientType',
            'showDefault': true, 'value': null,
            'displayField': 'clientType', 'type': 'optionsscroll', 'optionsscroll': 'CLIENT_TYPE',
        },

        bisType: {'showDefault': true, 'value': null, 'title':'bisType',
            'filters': {},
            'displayField': 'typeName', 'type': 'modelscroll',
            'modelscroll': 'Type', 'keyField': 'id', 'valueField': 'name',},

        bisSubType: {'showDefault': true, 'value': null, 'title':'subType',
            'filters': {},
            'displayField': 'subtypeName', 'type': 'modelscroll',
            'modelscroll': 'SubType', 'keyField': 'id', 'valueField': 'name',},

        bisTypeOther: {'title': 'bisTypeOther', 'value': null, 'type': 'text', 'required':true},
        businessCode: {'title': 'businessCode', 'value': null, 'type': 'text', 'required':true},
        exLink: {'title': 'exLink', 'value': null, 'type': 'text', 'readonly':false, 'maxlength':400},
        qrCode: {'title': 'qrCode', 'value': null, 'type': 'text', 'readonly':true},
        clientsDiscount: {'title': 'clientsDiscount', 'value': null, 'type': 'number', 'min':10, 'maxlength':50, 'required':true},
        joinUs: {'title': 'iInterested', 'value': null, 'type': 'boolean'},
        paymentVerified: {'title': 'paymentVerified', 'value': null, 'type': 'boolean', 'readonly': true},

        licenseExpiration: {'title': 'licenseExpiration', 'value': null, 'type': 'date', 'readonly': true},
        licenseApproved: {'title': 'licenseApproved', 'value': null, 'type': 'boolean', 'readonly': true},
        validate: {'title': 'validate', 'value': null, 'type': 'boolean', 'readonly': true},

        comments: {'title': 'description', 'value': null, 'type': 'textarea','maxlength':1000},

    };
}
