export class PostComment {
    data = {
        "id": null,
        "description": null,
        "post": null,
        "rate": null,
        "created": null,
        "appuser": null,
        "appuser__name": null,
    };

    constructor(public data1: any) {
        this.data = data1;
    }

    //shortHeaders = [];
    prefix = "postComment";
    title = "Post Comments";
    dataFields = {
        id: {'title': 'ID', 'value': null, 'type': 'number', 'readonly': true},
        description: {'title': 'description', 'value': null, 'type': 'text'},
        rate: {'title': 'rate', 'value': null, 'type': 'text'},
        item: {'title': 'item', 'value': null, 'type': 'text'},
        post: {'title': 'post', 'value': null, 'type': 'text'},
        appuser__name: {'title': 'post', 'value': null, 'type': 'text', 'readonly':true},
        appuser__mainImageUrl: {'title': 'post', 'value': null, 'type': 'text', 'readonly':true},
    }
}
