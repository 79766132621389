import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {AuthService} from "../../../../framework/providers/auth-service/auth-service";
import {ModelProvider} from "../../../../framework/providers/modelProvider/ModelProvider";
import * as moment from "moment";
import {ModalController, NavController, PopoverController} from "@ionic/angular";
import {AdminService} from "../../../../core/services/AdminService";
import {Events} from "../../../../framework/providers/events";
import {NavigationExtras} from "@angular/router";
import {AppConfig} from "../../../../core/conf/main";
import {ModelToolsProvider} from "../../../../framework/providers/model-tools/model-tools";

@Component({
    selector: 'app-calendar',
    templateUrl: './calendar.component.html',
    styleUrls: ['./calendar.component.scss'],
})
export class CalendarComponent implements OnInit, OnDestroy {
    appConfig = AppConfig;

    @Input()
    onMainPage = false;
    @Input()
    modelSectionName;
    @Input()
    baseFilter;
    myBaseFilter;
    subModelFiter;
    @Input()
    modeleventsType;
    @Input()
    flat = false;
    @Input()
    extableData;
    @Input()
    miniCalendar = true;
    showRightSide = true;

    @Input()
    avoidSave;
    @Input()
    topPrefix;
    @Input()
    tableData;
    calendarModels;
    calendarModelsObj;

    @Input()
    dateFieldType;
    @Input()
    dateFieldData;

    @Input()
    dateModelInfoData;

    dateFieldDataIndex = 'from';

    @Input()
    dateModel;
    filterDateModelEvents = true;
    @Input()
    dateModelSectionName;

    modeleventsTypeOpen = false;

    //cols in page - show/hide
    showDisplayCol: any = {
        'modelTypes': false,
        'main': false,
        'mini': true,
        'day': true,
        'mainMain': true,
        'eventList': true
    }

    filters = {'ordering': '-id', 'type': 'moving', 'name': 'taarif', 'modelOffset': 0, 'modelLimit': 20};

    calendarReady: boolean = false;
    ready: boolean = true;
    orderTimes: Array<Object>;
    weekTitles: Array<any>;
    weekDays: Array<any>;
    currentDateHours: Array<string>;
    currentDateMonths: Array<string>;
    currentYearMonths: Array<any>;
    monthDayDates: Array<any>;
    monthDayDates2: Array<any>;
    thisMonthName: string;
    thisDay: number;
    thisMonthNumber: string;
    thisTime: string;
    markData;
    qucikEditModel;

    nextDays: Array<string>;
    choseDays: Array<string>;
    dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wedensday', 'Thusday', 'Friday', 'Saturday'];
    montheNames: Array<string> = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    eventsBy = {};

    today;
    choose: Object = {
        'time': '',
        'day': '',
        'month': null
    };
    currentDate;
    currentDate2;
    sectionName = 'appEvent';
    eventKey = 'appEvent';

    products;
    displayType = 'month';

    // startDateIndex;
    // endDateIndex;
    needSave = false;

    eventsBaseFilter;
    mouseY;
    chooseEvent;
    chooseEventDateType;
    onHourOverTimeoutRef;

    //event over year
    onOverEvent;

    prefix = "calendarPrefix3" + Math.floor(Math.random() * Math.floor(1000000));
    presentingElement = null;

    openSide = false;
    subTopicEvent = "selectTablePrefix3" + Math.floor(Math.random() * Math.floor(1000000));

    tooltips = {};

    constructor(public navCtrl: NavController,
                public tools: ModelToolsProvider,
                public auth: AuthService,
                public events: Events,
                private modalController: ModalController,
                public popoverCtrl: PopoverController,
                public adminService: AdminService,
                public authService: AuthService,
                public modelProvider: ModelProvider) {

        this.markData = {'removedRow': [], 'markedRow': []};
        this.extableData = this.adminService.manageModels;

        this.currentDate = moment(new Date());

        this.thisTime = moment().format('h') + ":00";
        // this.thisMonthName = moment().startOf("month").format('MMMM');
        this.thisMonthNumber = moment().startOf("month").format('MM');
        this.thisDay = parseInt(moment().format("DD"));
        this.today = moment();

        this.choose['month'] = parseInt(moment().format("MM"));
        this.choose['day'] = moment(new Date());

        // this.chooseDay(this.choose['day']);
        // this.chooseMonth();
        this.currentDateMonths = this.getMonthes();


        // this.choseDays = [];
        // let dayDate = moment();
        // for (let i = 0; i < 6; i++) {
        //     this.choseDays.push(dayDate.format('DD'));
        //     dayDate.add(1, 'day');
        // }

    }

    ngOnInit(): void {
        this.extableData = this.adminService.manageModels;

        //console.log'init calendar!!!');
        // this.searchDateFieldsIndex(this.tableData[this.eventKey]);
        if (this.dateModelSectionName) {
            this.calendarModels = [{'check': true, 'name': this.dateModelSectionName}];
        }

        let me = this;
        if (!me.calendarModels) me.calendarModels = this.extableData['calendarModels'];
        me.calendarModelsObj = {}
        for (let k in me.calendarModels) {
            me.calendarModelsObj[me.calendarModels[k]['name']] = me.calendarModels[k];
        }

        this.events.subscribe('globalSaveClick', () => {
            this.saveCurrentChanges();
        }, this.subTopicEvent);

        this.events.subscribe('onClickEvent' + this.topPrefix, (data) => {
            //console.logthis.topPrefix);
            //console.logdata);

            this.qucikEditModel = data['rowModel'];
            // 'dateModel': this.period,
            //     'dateModelSectionName': rowModel['contentTypeName'],
            //     'dateFieldData': this.fieldData,
            //     'dateFieldType': this.fieldType,

            this.modeleventsTypeOpen = true;
        });

        if (this.dateModel) {
            this.eventsBaseFilter = {
                'searchFieldValue1': this.dateModel['id']['value'],
                'searchField1': 'objectId',
                'search_in_field': true,
                'searchFieldValue': this.dateModelSectionName,
                'searchField': 'contentType__model'
            }

            if (this.dateFieldData) {
                if (this.dateFieldData['value']) {
                    this.currentDate = moment(this.dateFieldData['value']);
                    if (!this.currentDate.isValid()) {
                        this.currentDate = moment(new Date());
                    }
                }
            }
        }

        if (this.dateFieldType && this.dateFieldType['init']) {
            this.dateFieldType = this.adminService.getFieldsType(this.dateModelSectionName, this.extableData)[this.dateFieldType['name']];
        }

        this.miniCalendar = false;

        if (this.miniCalendar) {
            this.showDisplayCol = {
                'modelTypes': false,
                'main': false,
                'mini': true,
                'day': false,
                'mainMain': false
            }
        } else if (!this.miniCalendar) {
            this.showDisplayCol = {'modelTypes': false, 'main': true, 'mini': false, 'day': false, 'mainMain': true}
            // if (!this.dateModelSectionName) {
            //     this.showDisplayCol['modelTypes'] = true;
            // }
            this.showDisplayCol['eventList'] = false;
        }

        // this.chooseYear();
        setTimeout(function () {
            me.refresh({}, true, true);
        }, 1);

        this.events.subscribe('chooseDay' + this.prefix, (data) => {
            // this.dateFieldDataIndex = data['type'];
            this.chooseDay(data['newDate'], true, false, true);

        });
        this.events.subscribe('calendarRefresh', (data) => {
            this.refresh();
        });

        // console.log("dateFieldType:")
        // console.log(this.dateFieldType)
    }

    afterDataLoading() {
        this.myBaseFilter = {
            'search_in_field': true,
            'searchFieldValue': this.dateModelSectionName,
            'searchField': 'contentType__model',
            'subBasefilter': this.baseFilter
        }
        if (this.dateModel) {
            this.myBaseFilter['searchFieldValue1'] = this.dateModel ? this.dateModel['id']['value'] : null;
            this.myBaseFilter['searchField1'] = 'objectId';
        }
    }

    ngOnDestroy() {
        this.events.unsubscribe('onClickEvent' + this.topPrefix);
        this.events.unsubscribe('chooseDay' + this.prefix);
        this.events.unsubscribe('calendarRefresh');
    }

    chooseYear(year = null) {
        this.showDisplayCol['day'] = false;
        this.showDisplayCol['mainMain'] = true;
        this.showDisplayCol['main'] = true;
        this.showDisplayCol['mini'] = false;

        if (year) {
            this.currentDate = year;
        }
        let currentDate = moment(this.currentDate);
        currentDate.startOf('month');
        currentDate.startOf('year');

        let months = [];
        let month;
        for (let i = 0; i < 12; i++) {
            month = this.loadMonthDates(currentDate);
            months.push(month);
            currentDate.add(1, 'months');
        }
        this.currentYearMonths = months;

        let me = this;
        // this.displayType = 'none';
        // setTimeout(function () {
        me.displayType = 'year';
        // });
    }

    getMonthes() {
        let thisdatem = moment(this.currentDate);
        let months = [];

        thisdatem = thisdatem.startOf('year');
        for (let i = 0; i < 12; i++) {
            months.push(moment(thisdatem));
            thisdatem.add(1, 'month');
        }

        return months;
    }

    getDate(date, color = '', hidden = false) {
        let cantChoose = false;
        if (this.dateFieldData && this.dateFieldData['startDateRange']) {
            let start = moment(this.dateFieldData['startDateRange'], "MM-DD-YYYY")
            let end = moment(this.dateFieldData['endDateRange'], "MM-DD-YYYY")
            let date1 = moment(date);
            let a = date1.isBefore(start) && !date1.isSame(start);
            let b = date1.isAfter(end.add(1, 'days')) && !date1.isSame(start);

            console.log(start.format("MM-DD-YYYY"))
            console.log(date.format("MM-DD-YYYY"))

            if (a || b) {
                console.log('cant choose!!')
                cantChoose = true;
            }
        }

        let res = {
            'monthName': date.format('MMMM'),
            'month': date.format('MM'),
            'year': date.format('yy'),
            'day': date.format('DD'),
            'date': moment(date),
            'hidden': hidden,
            'cantChoose': cantChoose,
            'color': color
        };
        // //console.logres);
        return res;
    }

    loadFollowDays() {
        //
        //last and next dates

        if (this.choose['month']) {
            this.nextDays = [];
            let startDay = moment(this.choose['month'], 'MM');

            if (this.choose['day']) {
                startDay = moment(this.choose['month'] + '/' + this.choose['day'], 'MM/DD');
                startDay.subtract(2, 'day');
            }

            for (let i = 0; i < 5; i++) {
                this.nextDays.push(startDay.format('DD'));
                startDay.add(1, 'day');
            }
        }
    }

    ///*???? depricated*/
    markEventClose(model) {
        //console.logthis.qucikEditModel);
        // if (this.qucikEditModel && this.qucikEditModel['id']['value']) {
        //     //console.log'here!');
        //     this.refresh();
        // } else {
        //     //console.log'here! - 1111');
        //     this.markEvent(model);
        //     this.arrangeHourEvents();
        // }

        this.chooseEvent = false;
        this.qucikEditModel = false;
    }

    //depricated?
    markEvent(model) {
        if (!model['id']['value']) return;

        let markTypeKey = 'markedRow';
        model['marked'] = !!!model['marked'];

        let id = model['id']['value'].toString();

        if (!this.markData[markTypeKey]) {
            this.markData[markTypeKey] = [];
        }

        if (this.markData[markTypeKey].indexOf(id) !== -1) {
            this.markData[markTypeKey].splice(this.markData[markTypeKey].indexOf(id), 1);
        } else {
            this.markData[markTypeKey].push(id);
        }
    }

    removeModel(eventModel) {

        let me = this;
        this.tools.yesOrNoAlert(function () {
            me.adminService.removeModels(me.sectionName, [eventModel['id']['value']], null, false);

            let startDate = moment(eventModel['start']['value']).format('DDMMyyyy');
            let startTime = moment(eventModel['startTime']['value'], 'HH:mm:ss').format('HH:mm');
            me.eventsBy['hour'][startDate][startTime].splice(me.eventsBy['hour'][startDate][startTime].indexOf(eventModel), 1);
            me.eventsBy['day'][startDate].splice(me.eventsBy['day'][startDate].indexOf(eventModel), 1);

            eventModel['startTime']['value'] = null;
            eventModel['startHourDisplay'] = null;
        });
    }

    chooseMonth(month = null, refresh = false) {
        this.showDisplayCol['day'] = false;
        if (!this.miniCalendar) {
            this.showDisplayCol['mainMain'] = true;
            this.showDisplayCol['main'] = true;
        } else {
            this.showDisplayCol['mainMain'] = false;
            this.showDisplayCol['main'] = false;
            this.showDisplayCol['mini'] = true;
        }

        // this.calendarReady = false;
        // this.thisMonthName = this.currentDate.format('MM');
        if (month) {
            this.currentDate = moment(month);
        } else {
        }
        this.monthDayDates = this.loadMonthDates();

        // this.monthDayDates = this.currentYearMonths[parseInt(this.currentDate.format('MM'))];
        this.currentDate2 = moment(this.currentDate).add(1, 'months');
        this.monthDayDates2 = this.loadMonthDates(this.currentDate2);//this.currentYearMonths[parseInt(this.currentDate.format('MM'))];

        let me = this;
        me.displayType = 'month';

        if (refresh) {
            // this.refresh({}, month ? false : true);
        } else {
            // setTimeout(function () {
            //     me.calendarReady = true;
            // }, 1);
        }


    }

    chooseDay(currentDate = null, getInDay = true, closeWindow = false, manualChoose = false) {
        // if (!this.miniCalendar) {
        //     this.showDisplayCol['mainMain'] = true;
        //     this.showDisplayCol['mini'] = true;
        //     this.showDisplayCol['main'] = true;
        // } else {
        //     this.showDisplayCol['mainMain'] = false;
        //     this.showDisplayCol['main'] = false;
        //     this.showDisplayCol['mini'] = true;
        // }

        if (currentDate) {
            this.currentDate = currentDate;
        }

        if (this.dateFieldType && this.dateFieldType['type'] == 'date' && manualChoose) {
            if (this.dateFieldType['rangeField']) {
                console.log('type:', this.dateFieldDataIndex)
                //need to fix here mismatch value and value 1 on to field edit.
                if (this.dateFieldDataIndex == 'from') {
                    this.dateModel[this.dateFieldType['fromField']]['displayName'] = this.currentDate.format(this.appConfig.dateFormatServer);
                    this.dateModel[this.dateFieldType['fromField']]['value'] = this.currentDate.format(this.appConfig.dateFormatServer);
                    if (this.dateModel[this.dateFieldType['toField']]['value']) {
                        this.dateModel[this.dateFieldType['fromField']]['value1'] = moment(this.dateModel[this.dateFieldType['toField']]['value']).format(this.appConfig.dateFormatServer);
                    }
                    this.dateFieldData['date'] = moment(this.currentDate);
                    this.adminService.markUpdated(this.dateModelInfoData,
                        this.dateModelSectionName, this.dateModel[this.dateFieldType['fromField']], this.dateModel);

                    this.adminService.saveToMarkedRows(this.dateModelSectionName, this.dateFieldType, this.dateModel[this.dateFieldType['fromField']]);

                } else {
                    this.dateModel[this.dateFieldType['toField']]['value'] = this.currentDate.format(this.appConfig.dateFormatServer);
                    this.dateModel[this.dateFieldType['toField']]['displayName'] = this.currentDate.format(this.appConfig.dateFormatServer);
                    this.dateFieldData['date1'] = moment(this.currentDate);
                    this.dateModel[this.dateFieldType['toField']]['value1'] =
                        moment(this.dateModel[this.dateFieldType['fromField']]['value']).format(this.appConfig.dateFormatServer);
                    this.adminService.markUpdated(this.dateModelInfoData,
                        this.dateModelSectionName, this.dateModel[this.dateFieldType['toField']], this.dateModel);
                    // let fieldTypeTo = this.adminService.getFieldsType(this.dateModelSectionName, this.adminService.manageModels)[this.dateFieldType['toField']]
                    // this.adminService.saveToMarkedRows(this.dateModelSectionName, fieldTypeTo, this.dateModel[this.dateFieldType['toField']]);
                }
                if (this.qucikEditModel) {
                    this.qucikEditModel['start']['value'] = moment(this.dateModel[this.dateFieldType['fromField']]['value']).format(this.appConfig.dateFormatServer);
                    this.qucikEditModel['end']['value'] = moment(this.dateModel[this.dateFieldType['toField']]['value']).format(this.appConfig.dateFormatServer);
                }
                this.dateFieldData['editMode'] = false;

                if (this.dateFieldDataIndex == 'from') {
                    this.dateFieldDataIndex = 'to';
                    return;
                }

                this.dateFieldDataIndex = 'from';

                // this.dateFieldData[''] = this.currentDate.format('YYYY-MM-DD');
                // this.markDateRangeDays();

                // this.adminService.saveField(this.dateModelSectionName, this.dateModel, this.dateFieldData, this.dateFieldType);
                // this.closeWindow();

                // this.adminService.markUpdated(this.dateModelInfoData, this.sectionName, this.qucikEditModel['start'], this.qucikEditModel);
                // this.adminService.markUpdated(this.dateModelInfoData, this.sectionName, this.qucikEditModel['end'], this.qucikEditModel);
                this.adminService.checkSave([this.dateModel], this.dateModelSectionName, {}, this.extableData)
                    // this.adminService.checkSave([this.qucikEditModel], this.sectionName, this.filters, this.extableData)
                    //     .then((data: any) => {
                    //     });
                    .then((data: any) => {
                        this.arrangeHourEvents();
                        this.markDateRangeDays();
                    });

                // this.toggleCalendarReady();
                // this.arrangeHourEvents();

                return;
            } else {
                this.showDisplayCol['day'] = true;
                this.dateFieldData['displayName'] = this.currentDate.format(this.appConfig.dateFormatServer);
                this.dateFieldData['value'] = this.currentDate.format(this.appConfig.dateFormatServer);

                this.adminService.markUpdated(this.dateModelInfoData, this.dateModelSectionName, this.dateFieldData, this.dateModel);
                this.closeWindow(true);
                return;
            }

            // if (this.qucikEditModel) {
            //     this.qucikEditModel['start']['value'] = moment(this.dateFieldData['value']).format('YYYY-MM-DD');
            //     this.qucikEditModel['end']['value'] = this.qucikEditModel['start']['value'];
            //     this.qucikEditModel['startTime']['value'] = '06:00';
            //     this.qucikEditModel['endTime']['value'] = '06:00';
            // }

            if (!this.avoidSave) {
                this.adminService.saveField(this.dateModelSectionName, this.dateModel, this.dateFieldData, this.dateFieldType);
            }

            if (this.dateFieldData && this.dateFieldType['type'] == 'date' || closeWindow) {
                this.closeWindow();
                return;
            }
        }

        //all day

        let me = this;
        if (this.dateFieldData && this.dateFieldType['type'] == 'datetime' && manualChoose) {
            this.tools.yesOrNoAlert(function () {
                //tableInfoData, sectionName, fieldData, model
                me.adminService.markUpdated(me.dateModelInfoData, me.dateModelSectionName, me.dateFieldData, me.dateModel);
                me.currentDate.set({hour: 6, minute: 0, second: 0, millisecond: 0});
                me.dateFieldData['displayName'] = me.currentDate.format(me.appConfig.datetimeFormatDisplay);
                me.dateFieldData['value'] = me.currentDate.format(me.appConfig.datetimeFormatServer);
                me.closeWindow(true);

                me.adminService.saveToMarkedRows(me.dateModelSectionName, me.dateFieldType, me.dateFieldData);

                return
            }, {
                'title': this.tools.getTranslate('All Day Event ?')
            }, this.tools.getTranslate('All Day Event ?'), function () {
                me.setDayView();
                return;
            });
        }
        // me.arrangeHourEvents();
        // me.refresh();

        //else...
        this.setDayView();
    }

    setDayView() {

        if (this.dateFieldType && this.dateFieldType['type'] == 'date') {
            this.chooseMonth();
            return;
        }

        this.monthDayDates = this.loadMonthDates();
        this.currentDateHours = this.listDayHours(this.currentDate);

        let me = this;
        me.displayType = 'day';
        me.showDisplayCol['day'] = true;
        me.showDisplayCol['mainMain'] = false;
        me.showDisplayCol['main'] = true;
        if (me.miniCalendar) {
            me.showDisplayCol['mini'] = false;
        }
    }

    saveModelDateField() {
        if (this.avoidSave) return;
        this.adminService.saveField(this.dateModelSectionName, this.dateModel, this.dateFieldData, this.dateFieldType)
    }

    //minicalendar day range choose.
    //value1 - end date.
    markDateRangeDays() {
        if (!this.dateFieldType || !this.dateFieldType['rangeField']) return;

        if (!this.dateModel[this.dateFieldType['fromField']]['value'] ||
            !this.dateModel[this.dateFieldType['toField']]['value']) return;

        let startDate = moment(this.dateModel[this.dateFieldType['fromField']]['value']);
        let endDate = moment(this.dateModel[this.dateFieldType['toField']]['value']);

        let eventsBy = this.eventsBy;
        let nhour = moment(startDate);
        eventsBy['dayMark'] = {};
        while (endDate.isAfter(nhour)) {
            let daydate = nhour.format('DDMMyyyy');
            if (!eventsBy['dayMark'][daydate]) eventsBy['dayMark'][daydate] = [];
            eventsBy['dayMark'][daydate].push({'placeHolder': 1});
            nhour.add(1, 'day');
        }
        //last day..
        let daydate = nhour.format('DDMMyyyy');
        if (!eventsBy['dayMark'][daydate]) eventsBy['dayMark'][daydate] = [];
        eventsBy['dayMark'][daydate].push({'placeHolder': 1});
    }

    toggleCalendarReady() {
        let me = this;
        me.calendarReady = false;
        setTimeout(function () {
            me.calendarReady = true;
        });
    }

    showMonths() {
        document.getElementById("open-modalselectMonth" + this.prefix).click();
    }

    showEvent() {
        document.getElementById("open-modalevent" + this.prefix).click();
    }

    chooseWeek() {
        this.showDisplayCol['day'] = false;
        this.showDisplayCol['mainMain'] = true;
        this.showDisplayCol['main'] = true;
        this.showDisplayCol['mini'] = this.miniCalendar ? false : true;

        let day = this.currentDate;
        let week = [];
        let weekTitles = [];
        let thisdatem = moment(day);
        thisdatem.startOf('week');

        for (let i = 0; i < 7; i++) {
            // let day = this.listHours(dateit['year'], dateit['month'], dateit['day']);
            thisdatem.set({hour: 6, minute: 0, second: 0, millisecond: 0});
            weekTitles.push(moment(thisdatem));
            week.push(this.listDayHours(thisdatem));
            thisdatem = thisdatem.add(1, 'days');
        }

        this.displayType = 'week';
        this.weekTitles = weekTitles;
        this.weekDays = week;
        console.log(this.weekDays);

        // this.loadFollowDays();


        let me = this;
        me.displayType = 'week';
    }

    listDayHours(dateit) {
        let thisdatem = moment(dateit);
        let dayHours = [];

        thisdatem.set({hour: 6, minute: 0, second: 0, millisecond: 0});
        dayHours.push(thisdatem);
        for (let i = 0; i < 48; i++) {
            thisdatem.add(30, 'minutes');
            dayHours.push(moment(thisdatem));
        }

        return dayHours;
    }

    loadDay() {
    }

    getRandomInt(max) {
        return Math.floor(Math.random() * Math.floor(max));
    }

    loadMonthDates(cuDate = null, loadNextMonth = true) {
        let dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        let d = moment(cuDate ? cuDate : this.currentDate);
        let firstDay = d.startOf('month');
        let date = moment(firstDay);
        let day1Name = moment(date.startOf('month')).format('ddd');
        let endOfMonth = moment(date.endOf('month'));
        let dayIndex = dayNames.indexOf(day1Name);
        let startDay = date.subtract(1, 'months').endOf('month').subtract(dayIndex, 'days');

        let weeks = [[], [], [], [], [], []];
        let x;
        let total = 0;

        //end of last month
        for (let i = 0; i < dayIndex; i++) {
            x = (startDay.add(1, 'days'));
            weeks[Math.floor(total / 7)].push('');
            // weeks[Math.floor(total / 7)].push(this.getDate(x, 'background-disable', true));
            total++;
        }

        // all montehs dates
        for (let i = 0; i < parseInt(endOfMonth.format('DD')); i++) {
            startDay.add(1, 'days');
            x = startDay;
            weeks[Math.floor(total / 7)].push(this.getDate(x));
            total++;
        }

        // start of next month
        let day2Name = moment(startDay).format('ddd');
        let lastDayIndex = dayNames.indexOf(day2Name) + 1;
        for (let i = 0; i < 7 - lastDayIndex; i++) {
            x = startDay.add(1, 'days');
            // weeks[Math.floor(total / 7)].push(this.getDate(x, 'background-disable', true));
            weeks[Math.floor(total / 7)].push('');
            total++;
        }

        /*set in rows*/
        // let days = result;
        // let weeks = [];
        // for (let i = 0; i < 5; i++) {
        // let weekDays = [];
        // for (let j = (i * 7); j < (i + 1) * 7; j++) {
        //     weekDays.push(days[j]);
        // console.log(days[j])
        // console.log(1)
        // }
        // weeks.push(days.slice(i * 7, (i + 1) * 7));
        // }
        //
        return weeks;
    }

    prev(type = null) {
        console.log(this.displayType);

        if (this.displayType == 'day' || type == 'day') {
            this.currentDate = moment(this.currentDate.subtract(1, 'day'));
            this.chooseDay();
        } else if (this.displayType == 'week' || type == 'day') {
            this.currentDate = moment(this.currentDate.subtract(1, 'week'));
            this.chooseWeek();
        } else if (this.displayType == 'month' || type == 'day') {
            this.currentDate = moment(this.currentDate.subtract(1, 'month'));
            this.chooseMonth();
        } else if (this.displayType == 'year') {
            this.currentDate = moment(this.currentDate.subtract(1, 'years'));
            this.chooseYear();
        }

        // this.currentDateMonths = this.getMonthes();
        // let me = this;
        // me.calendarReady = false;
        // setTimeout(function () {
        //     me.calendarReady = true;
        // });
        this.refresh();
    }

    next(type = null) {
        if (this.displayType == 'day' || type == 'day') {
            this.currentDate = moment(this.currentDate.add(1, 'day'));
            this.chooseDay();
        } else if (this.displayType == 'week' || type == 'week') {
            this.currentDate = moment(this.currentDate.add(1, 'week'));
            this.chooseWeek();
        } else if (this.displayType == 'month' || type == 'month') {
            this.currentDate = moment(this.currentDate.add(1, 'month'));
            // let year = moment(this.currentDate).year();
            //
            this.currentDate2 = moment(this.currentDate).add(1, 'month');
            this.chooseMonth(null, true);

            //
            // let year1 = this.currentDate.year();
            // console.log(year, ' - ', year1);
            //
            // this.chooseMonth();
        } else if (this.displayType == 'year' || type == 'year') {
            this.currentDate = moment(this.currentDate.add(1, 'years'));
            this.chooseYear();
        }
        this.currentDateMonths = this.getMonthes();

        // this.refresh();

        // let me = this;
        // me.calendarReady = false;
        // setTimeout(function () {
        //     me.calendarReady = true;
        // });
        this.refresh();
    }

    showRowModel(event, dateModel, dateModelSectionName) {
        // this.qucikEditModel['main']['objectId'] = dateModel['id']['value'];
        // this.qucikEditModel['main']['contentTypeName'] = dateModelSectionName;
        // this.qucikEditModel['main']['dateModelFieldName'] = dateFieldData['name'];

        this.adminService.autoPopup(event, dateModelSectionName, dateModel, this.prefix, this.extableData,
            dateModel.id.value, {}, true);
    }

    showRow(event, row) {
        if (!this.onMainPage) {
            this.adminService.autoPopup(event, this.sectionName, row, this.prefix, this.extableData, row.id.value)
        } else {
            this.events.publish('showMenu',
                {
                    'menu': 'menuAdminBox',
                    'id': row.id.value,
                    'rowModel': row,
                    'prefix': this.prefix,
                    'boxMenu': true,
                    'sectionName': this.sectionName,
                    'width': 500
                });
        }
        return;
        //console.log"row:");
        //console.logrow);

        if (this.miniCalendar) return;

        this.openSide = true;

        // this.arrangeHourEvents();

        this.needSave = true;
        row['main']['updated'] = true;

        //when click on event
        // if (row['objectId']) {
        //     this.dateModel = [{}];
        //     this.dateModel['main']['value'] = row['objectId']['value'];
        //     // this.dateModel[this.dateFieldDataIndex]['value'] = row[this.startDateIndex]['value'];
        //     this.dateModelSectionName = row['contentTypeName']['value'];
        //
        //     this.dateFieldData = {};
        //     // this.dateFieldData['value'] = row[this.startDateIndex]['value'];
        //     this.dateFieldData['name'] = row['main']['displayName'];
        //     this.dateFieldData['type'] = 'datetime';
        //     //console.log'set this.dateFieldData:');
        //     //console.logthis.dateFieldData);
        // }

        let me = this;
        this.qucikEditModel = null;
        setTimeout(function () {
            me.qucikEditModel = row;
            // me.markEvent(me.qucikEditModel);

            //console.log"me.qucikEditModel:");
            //console.logme.qucikEditModel);

            //active related model display
            if (me.dateModel) {
                me.qucikEditModel['main']['objectId'] = me.dateModel['id']['value'];
                me.qucikEditModel['main']['contentTypeName'] = me.dateModelSectionName;
                me.qucikEditModel['main']['dateModelFieldName'] = me.dateFieldData['name'];
            }

        });

        // this.events.publish('qucikEditModelCall', {
        //     'qucikEditModel': row,
        //     'qucikEditModelKey': this.eventKey
        // });
    }

    chooseHour(hourt) {
        let t = hourt.format();

        //console.logt);

        let hour = moment(t);

        //save event
        // if (this.qucikEditModel) {
        //     this.qucikEditModel['start']['value'] = t;
        //     this.adminService.saveField(this.sectionName,
        //         this.qucikEditModel,
        //         this.qucikEditModel['start'],
        //         this.adminService.getFieldsType(this.sectionName, this.extableData)['start']);
        // }

        //save external model
        //if entered from other model with field - set field value
        if (this.dateFieldData) {

            //or - if type=forienkey
            if (this.dateFieldType['type'] == 'datetime') {
                this.dateFieldData['value'] = hour.format(this.appConfig.datetimeFormatServer);
            } else if (this.dateFieldType['type'] == 'date') {
                this.dateFieldData['value'] = hour.format(this.appConfig.dateFormatServer);
            }
            // else {
            //     //event model forienkey
            //     this.dateFieldData['value'] = newModel['id']['value'];
            //     this.dateFieldData['displayName'] = newModel['main']['displayName'];
            // }

            if (this.dateFieldType['type'] == 'datetime') {
                this.dateFieldData['displayName'] = hour.format('HH:mm DD-MM-yyyy');
            } else if (this.dateFieldType['type'] == 'date') {
                this.dateFieldData['displayName'] = hour.format('DD-MM-yyyy');
            }

            if (!this.avoidSave) {
                this.adminService.saveField(this.dateModelSectionName, this.dateModel, this.dateFieldData, this.dateFieldType);
            }

            this.closeWindow(true);
            return
            // if (this.miniCalendar) {
            //     this.closeWindow();
            // }
            // return;
        }

        this.refresh();
        // this.addEvent(hour);
    }

    addEvent(hourt = null, forceCreateEvent = false) {
        if (this.dateModel && this.dateFieldData) {
            this.chooseHour(hourt);
            if (!forceCreateEvent) {
                this.closeWindow();
                return;

            }
        }

        //console.log'add event!');
        let me = this;

        /**/
        // if (this.dateFieldData && this.dateFieldData['value']) {
        //     hourt = moment(this.dateFieldData['value']);
        // } else
        if (!hourt) {
            hourt = moment();
        }

        let t = hourt.format();
        let hour = moment(t);
        let newModel;
        newModel = this.getNewModel();

        newModel['start']['value'] = hour.format(this.appConfig.dateFormatServer);
        newModel['end']['value'] = hour.format(this.appConfig.dateFormatServer);
        newModel['startTime']['value'] = hour.format('HH:mm:SS');
        newModel['endTime']['value'] = hour.format('HH:mm:SS');
        newModel['name']['value'] = 'New Event';

        //set new event model data
        if (this.dateModel) {
            newModel['contentType']['value'] = this.dateModelSectionName;
            newModel['objectId']['value'] = this.dateModel['id']['value'];
            // newModel['fieldName']['value'] = me.dateFieldType['name'];
        }
        //
        //     //came from choose field - event forienkey
        //     //dateFieldData['value'] is the [event id]
        //     if (this.dateFieldType['type'] == 'selectTable' && this.dateFieldData['value']) {
        //         newModel['id']['value'] = this.dateFieldData['value'];
        //         me.dateFieldData['startDate'] = newModel['start']['value'];
        //         if (newModel['removed']['value']) me.dateFieldData['value'] = null;
        //     }
        //
        //     //search exisiting event- dont add new one if first exists.
        //     for (let mevent of this.tableData['appEvent']) {
        //         if (mevent['objectId']['value'] == this.dateModel['id']['value'] && mevent['fieldName']['value'] == this.dateFieldType['name']) {
        //             newModel['id']['value'] = mevent['id']['value'];
        //         }
        //     }
        // }

        this.adminService.saveModel(newModel, this.sectionName, this.tableData, 'asdas').subscribe((data) => {
            console.log('after save model!')
            let newModel = data['data']['models'][0];
            // me.qucikEditModel = data['data']['models'][0];
            // me.openEventModal(null, newModel);

            // me.adminService.popupEditBox(null, 'appEvent', me.tableData, 'aaa', me.qucikEditModel);

            // let hour = hourt.format('HHDDMMyyyy');
            // console.log('after save model!', hour)
            // me.eventsBy['hour'][hour] = me.eventsBy['hour'][hour] ? me.eventsBy['hour'][hour] : [];
            // me.eventsBy['hour'][hour].push(me.qucikEditModel);

            // me.chooseHour(hourt, newModel);

            me.refresh({}, false);
        });
    }

    getNewModel() {
        let newModel = {'main': {'value': -1, 'updated': true, 'name': '', 'type': 'none'}};
        let refmodel = this.tableData[this.sectionName + 'Ref'];
        for (let fieldData of refmodel) {
            ////console.logfieldData);
            newModel[fieldData['name']] = {
                'value': null,
                'name': fieldData['name'],
                'edited': true,
                'type': fieldData['type']
            }
            if (fieldData['optionsActive']) newModel[fieldData['name']]['optionsActive'] = [];
        }
        newModel['id']['value'] = null;
        return newModel;
    }

    //on close new event window
    //depricated
    newEventOnAdd(newModel) {
        if (newModel['id']['value'] == null && !newModel['main']['added']) {
            // //console.log'add model:', newModel[0]['value']);
            newModel['main']['added'] = true;
            // this.tableData[this.eventKey].push(newModel);
            // //console.logthis.tableData[this.eventKey]);
        }
        this.markEventClose(newModel);
    }


    // **********************
    //main hours data set
    //set array of all dates and types for presenting in boxes
    //day / month / year
    arrangeHourEvents() {
        let models = this.tableData[this.eventKey];
        let eventsBy = {'hour': {}, 'day': {}, 'month': {}};

        //add today
        eventsBy['hour'][moment(this.currentDate).format('DDMMyyyy')] = [];

        // let startDateIndex = this.startDateIndex;
        // let endDateIndex = this.endDateIndex;

        if (!models) return

        // add events for specific hour / start of date hour
        models = models.sort(function (a, b) {
            let sa = moment(a['startTime']['value'], 'HH:mm:ss');
            let sb = moment(b['startTime']['value'], 'HH:mm:ss');

            if (!sa.isValid()) sa = moment('06:00:00', 'HH:mm:ss');
            if (!sb.isValid()) sb = moment('06:00:00', 'HH:mm:ss');

            if (a['start']['value'] != b['start']['value']) {
                return (moment(a['start']['value'], 'yyyy-MM-DD').isAfter(moment(b['start']['value'], 'yyyy-MM-DD')) ? 1 : -1);
            }

            return (sa.isAfter(sb) ? 1 : a['id']['value'] > b['id']['value'] ? 1 : -1);
        })

        // hour
        console.log(models);

        for (let key in models) {
            // console.log(models[key]['startTime']['value']);
            // if (models[key]['id']['value'] == -1) continue;

            let startDate = moment(models[key]['start']['value']);
            if (models[key]['startTime']['value'] == 'None') models[key]['startTime']['value'] = '06:00:00';
            let hour = moment(models[key]['startTime']['value'], 'HH:mm:ss');
            let endHour = moment(models[key]['endTime']['value'], 'HH:mm:ss');
            let endDate = moment(models[key]['end']['value']);
            let mdiff = endHour.diff(hour, 'minutes');
            let dayDiff;

            let day = startDate.format('DDMMyyyy');

            if (hour && hour.isValid()) {
                // hour = moment(new Date());
            } else {
                hour = moment(new Date());
            }
            if (this.dateModel && models[key]['main']['relatedModel'] &&
                models[key]['main']['relatedModel']['id']['value'] == this.dateModel['id']['value']) {
                this.qucikEditModel = models[key];
                this.openSide = false;
            }

            hour.minutes(parseInt(hour.format('mm')) < 30 ? 0 : 30);

            // let mm = parseInt(hour.format('mm')) < 30 ? '00' : '30';
            let hourt = hour.format('HH:mm');

            models[key]['mdiff'] = mdiff <= 30 ? 30 : mdiff;
            models[key]['startHourDisplay'] = hour;
            models[key]['endHourDisplay'] = endHour && endHour.isValid() ? endHour : hour;

            if (models[key]['start']['value'] != models[key]['end']['value']) {
                //would be added on month for.

                // if (!eventsBy['hour'][day]) eventsBy['hour'][day] = [];
                // if (!eventsBy['hour'][day]['allDay']) eventsBy['hour'][day]['allDay'] = [];
                // eventsBy['hour'][day]['allDay'].push(models[key]);
            } else {
                //     hour = startTime.split(':')[0] + hour
                // }
                // console.log(hour + '-' + startTime);
                //first hour
                if (!eventsBy['hour'][day]) eventsBy['hour'][day] = [];
                if (!eventsBy['hour'][day][hourt]) eventsBy['hour'][day][hourt] = [];

                eventsBy['hour'][day][hourt].push(models[key]);
                let len = eventsBy['hour'][day][hourt].length;

                //fill place holdes before this event.
                let nhour = moment(hour);
                while (endHour.isAfter(nhour)) {
                    nhour.add(30, 'minutes');
                    hourt = nhour.format('HH:mm');
                    if (!eventsBy['hour'][day][hourt]) eventsBy['hour'][day][hourt] = [];

                    while (eventsBy['hour'][day][hourt].length < len) {
                        eventsBy['hour'][day][hourt].push({'placeHolder': 1});
                    }
                }
            }
            // maxExists-=eventsBy['hour'][day][hourt].length;
            // for (let i=0; i<maxExists; i++)
            // {
            //     eventsBy['hour'][day][hourt].push({'placeHolder':true});
            // }

        }

        // day - add all start day and first hour for every next day until end date
        for (let key in models) {
            // ----
            let startDate = moment(models[key]['start']['value']);
            let endDate = moment(models[key]['end']['value']);

            let dayDiff;

            // fix for date end sooner than date start
            // //console.logmodels[key][1]['value'], startDate.format('DDMMyyyy'), "- ", endDate.format('DDMMyyyy'));
            if (models[key]['start']['value'] == models[key]['end']['value'])
                dayDiff = 0;
            else if (endDate.isAfter(startDate)) {
                dayDiff = endDate.diff(startDate, 'days');
                // //console.logdayDiff);
                if (dayDiff <= 0) dayDiff = 0;
            } else dayDiff = 0;

            // dates range
            // add event to all days range
            for (let i = 0; i <= dayDiff; i++) {
                //for day display
                let daydate = startDate.format('DDMMyyyy');
                if (!eventsBy['day'][daydate]) {
                    eventsBy['day'][daydate] = [];
                    eventsBy['day'][daydate + 'Total'] = 0;
                }

                //
                //event type counter
                if (!eventsBy['day'][daydate + models[key]['eventType']['name']]) {
                    eventsBy['day'][daydate + models[key]['eventType']['name']] = 0;
                }
                eventsBy['day'][daydate + models[key]['eventType']['name']] += 1;

                //all day?
                // if (models[key]['allDay'] && models[key]['allDay']['value']) {
                //     if (!eventsBy['day'][daydate + 'AllDay']) eventsBy['day'][daydate + 'AllDay'] = [];
                //     eventsBy['day'][daydate + 'AllDay'].push(models[key]);
                // } else {
                // }

                eventsBy['day'][daydate + 'Total']++;

                //if specific days - set on them only. else on all range.
                let dayDate = startDate.format('dddd');
                if (models[key]['days']['value'] && models[key]['days']['value'].length > 0) {
                    if (models[key]['days']['value'].includes(dayDate)) {
                        eventsBy['day'][daydate].push(models[key]);
                    }
                } else {
                    eventsBy['day'][daydate].push(models[key]);

                    //add to all days by hour.
                    if (!eventsBy['hour'][daydate]) eventsBy['hour'][daydate] = {};

                    if ((models[key]['start']['value'] != models[key]['end']['value']) ||
                        !models[key]['startTime']['value'] || models[key]['allDay']['value']) {
                        if (!eventsBy['hour'][daydate]['allDay']) eventsBy['hour'][daydate]['allDay'] = [];
                        // eventsBy['hour'][daydate]['allDay'].push(models[key]);
                    }
                }

                //
                startDate.add(1, 'days');


                //in day
                //if its not the first day - set hour to 06:00
                // add all middle days - first hour event of all day.
                // if (i > 0) {
                //     let hour = moment(startDate).set({'hour': 6, 'minutes': 0}).format('HHDDMMyyyy');
                //     eventsBy['hour'][hour] = eventsBy['hour'][hour] ? eventsBy['hour'][hour] : [];
                //     eventsBy['hour'][hour].push(models[key]);
                // }
            }

            this.repeatEventSet(models[key], eventsBy, dayDiff);
        }

        //month
        for (let key in models) {
            // if (models[key]['value'] == -1) continue;

            // -----
            let hour = moment(models[key]['name']['value']).format('MMyyyy');
            eventsBy['month'][hour] = eventsBy['month'][hour] ? eventsBy['month'][hour] : [];
            eventsBy['month'][hour].push(models[key]);
        }

        this.eventsBy = eventsBy;

        console.log('events:!');
        console.log(this.eventsBy);
    }

    //add repeat events to all relvant days as model event defined.
    repeatEventSet(model, eventsBy, startEndDiff) {
        //    //    loop every day/week/month/year.
        //         //    add the event to date.
        //         //    add date range to end field on every loop.
        //['No Loop', 'Every Day', 'Every Month', 'Every Year', 'Every week']
        if (model['loopType']['value'] == 'Every Year') {
            //50 years ahead
            for (let i = 0; i <= 50; i++) {
                let loopStart = moment(model['start']['value']);
                loopStart.add(-10, 'year');
                loopStart.add(i + 1, 'year');
                let startDay = moment(loopStart);

                for (let i = 0; i <= startEndDiff; i++) {
                    // let endDate = moment(model['end']['value']);
                    let daydate = startDay.format('DDMMyyyy');
                    if (!eventsBy['day'][daydate]) eventsBy['day'][daydate] = [];
                    let exists = false;
                    for (let cmodel of eventsBy['day'][daydate]) {
                        if (cmodel.id.value == model.id.value) {
                            exists = true;
                            break;
                        }
                    }
                    if (!exists) {
                        eventsBy['day'][daydate].push(model);
                    }

                    if (!eventsBy['hour'][daydate]) eventsBy['hour'][daydate] = {};
                    if (!eventsBy['hour'][daydate]['allDay']) eventsBy['hour'][daydate]['allDay'] = [];
                    eventsBy['hour'][daydate]['allDay'].push(model);

                    //for day display
                    startDay.add(1, 'days');
                }
            }
        }
        //month
        if (model['loopType']['value'] == 'Every Month') {
            //50 years ahead
            for (let i = 0; i <= 50; i++) {
                let loopStart = moment(model['start']['value']);
                loopStart.add(i + 1, 'month');
                let startDay = moment(loopStart);

                for (let i = 0; i <= startEndDiff; i++) {
                    // let endDate = moment(model['end']['value']);
                    let daydate = startDay.format('DDMMyyyy');
                    if (!eventsBy['day'][daydate]) eventsBy['day'][daydate] = [];
                    let exists = false;
                    for (let cmodel of eventsBy['day'][daydate]) {
                        if (cmodel.id.value == model.id.value) {
                            exists = true;
                            break;
                        }
                    }
                    if (!exists) {
                        eventsBy['day'][daydate].push(model);
                    }

                    if (!eventsBy['hour'][daydate]) eventsBy['hour'][daydate] = {};
                    if (!eventsBy['hour'][daydate]['allDay']) eventsBy['hour'][daydate]['allDay'] = [];
                    eventsBy['hour'][daydate]['allDay'].push(model);

                    //for day display
                    startDay.add(1, 'days');
                }
            }
        }
        //month
        if (model['loopType']['value'] == 'Every week') {
            let loopStart = moment(model['start']['value']);

            //50 years ahead
            for (let i = 0; i <= 50; i++) {
                loopStart.add(7, 'days');
                let startDay = moment(loopStart);

                for (let i = 0; i <= startEndDiff; i++) {
                    // let endDate = moment(model['end']['value']);
                    let daydate = startDay.format('DDMMyyyy');
                    if (!eventsBy['day'][daydate]) eventsBy['day'][daydate] = [];
                    eventsBy['day'][daydate].push(model);

                    if (!eventsBy['hour'][daydate]) eventsBy['hour'][daydate] = {};
                    if (!eventsBy['hour'][daydate]['allDay']) eventsBy['hour'][daydate]['allDay'] = [];
                    eventsBy['hour'][daydate]['allDay'].push(model);

                    //for day display
                    startDay.add(1, 'days');
                }
            }
        }
        //day
        if (model['loopType']['value'] == 'Every Day') {
            let loopStart = moment(model['start']['value']);

            //50 years ahead
            for (let i = 0; i <= 550; i++) {
                loopStart.add(1, 'days');

                // let endDate = moment(model['end']['value']);
                let daydate = loopStart.format('DDMMyyyy');
                if (!eventsBy['day'][daydate]) eventsBy['day'][daydate] = [];
                eventsBy['day'][daydate].push(model);

                if (!eventsBy['hour'][daydate]) eventsBy['hour'][daydate] = {};
                if (!eventsBy['hour'][daydate]['allDay']) eventsBy['hour'][daydate]['allDay'] = [];
                eventsBy['hour'][daydate]['allDay'].push(model);
            }
        }
    }

    refresh(mfilters = {}, setCurrentDate = false, firstInit = false) {
        this.markData['removedRow'] = [];
        this.markData['markedRow'] = [];

        let filters = {};
        for (let key in this.filters) {
            filters[key] = this.filters[key];
        }

        for (let key in mfilters) {
            filters[key] = mfilters[key];
        }

        if (this.baseFilter) {
            for (let key in this.baseFilter) {
                filters[key] = this.baseFilter[key];
            }
            filters['contentModelFilter'] = JSON.stringify(this.baseFilter);
        }

        filters['sectionName'] = this.sectionName;
        filters['getEvents'] = true;
        filters['getEvents'] = true;

        filters[this.sectionName + 'modelOffset'] = 0;
        filters[this.sectionName + 'modelLimit'] = 1000;

        let l = moment(this.currentDate.format());
        let d = moment(this.currentDate.format());

        //extra filters
        //extra filters
        let exFilter;
        let contentTypes = [];
        if (this.displayType == 'year') {
            exFilter = {
                'end__gte': l.startOf('month').startOf('year').format('yyyy-MM-DD'),
                'start__lte': d.endOf('month').endOf('year').format('yyyy-MM-DD')
            }
        } else if (this.displayType == 'month') {
            exFilter = {
                'start__gte': d.startOf('month').subtract(7, 'day').format('yyyy-MM-DD'),
                'end__lte': l.endOf('month').add(7, 'day').format('yyyy-MM-DD')
            }
            exFilter = {
                'start__gte': '2023-03-20',
                'end__lte': '2023-03-22',
            }
        } else if (this.displayType == 'day') {
            exFilter = {
                'end__gte': l.startOf('month').subtract(7, 'day').format('yyyy-MM-DD'),
                'start__lte': d.endOf('month').add(7, 'day').format('yyyy-MM-DD')
            }
        } else {
            exFilter = {
                'end__gte': l.startOf('month').subtract(7, 'day').format('yyyy-MM-DD'),
                'start__lte': d.endOf('month').add(7, 'day').format('yyyy-MM-DD')
            }
        }

        if (this.calendarModels) {
            for (let m of this.calendarModels) {
                if (m['check']) contentTypes.push(m['name'])
            }
            exFilter['contentType__model__in'] = contentTypes
            if (contentTypes.length == 1) {
                filters['subSectionName'] = contentTypes[0]
            }
        }
        if (this.dateModel && this.dateModelSectionName) {
            if (this.filterDateModelEvents) {
                exFilter['objectId'] = this.dateModel['id']['value'];
            }
            exFilter['dateModel'] = this.dateModelSectionName
        }

        filters['extraFilter'] = JSON.stringify(exFilter);
        this.subModelFiter = filters['extraFilter'];
        //
        //
        //
        // this.calendarReady = false;
        // this.events.publish('refresh_request_' + this.prefix, filters);
        let me = this;
        this.modelProvider.list('report', filters)
            .then(function (data: any) {
                let extableData = data['models'][0].data['finalResReport'];
                //console.logextableData);
                // let models = extableData[me.sectionName];
                // if (!me.tableData) {
                me.tableData = me.adminService.manageModels;
                me.tableData['appEventDays'] = {};
                // }

                if (!me.calendarModels) me.calendarModels = extableData['calendarModels'];
                me.calendarModelsObj = {}
                for (let k in me.calendarModels) {
                    me.calendarModelsObj[me.calendarModels[k]['name']] = me.calendarModels[k];
                }

                for (let key in extableData) {
                    me.tableData[key] = extableData[key];
                }
                me.arrangeHourEvents();

                if (me.modelSectionName && !me.dateModelInfoData) me.dateModelInfoData = me.extableData[me.modelSectionName + 'Data'];

                //display type = date range / choose day / ..
                if (setCurrentDate) {
                    if (me.dateModel && me.dateFieldData) {
                        // me.addEvent();
                        if (me.dateFieldType['rangeField']) {
                            me.chooseMonth(me.dateFieldData['value'] ? me.dateFieldData['value'] : me.dateFieldData['startDateRange']);
                        } else if (me.dateFieldType['type'] == 'selectTable') {
                            me.chooseDay(moment(me.dateFieldData['startDate']), false);
                        } else if (me.dateFieldData['value'] && me.dateFieldData['value'] != 'None') {
                            if (me.dateFieldType['type'] == 'datetime') {
                                me.chooseMonth(me.dateFieldData['value'], true);
                                // me.chooseDay(moment(me.dateFieldData['value']), false)
                                me.refreshDispaly();
                            } else {
                                me.chooseMonth();
                            }
                        } else {
                            me.chooseMonth();
                            me.refreshDispaly();
                        }
                        // me.markDateRangeDays(); - first init show current.
                    } else {
                        me.chooseMonth();
                        me.refreshDispaly();
                    }

                    //on regular calendar - show default view of table.
                    //!me.dateModel &&
                    if (firstInit) {
                        if (me.dateModelInfoData && me.dateModelInfoData['calendarDisplayType']) {
                            if (me.dateModelInfoData['calendarDisplayType'] == 'year') {
                                me.chooseYear();
                            }
                        }
                        if (me.modelSectionName && me.tableData[me.modelSectionName + 'Data']['calendarDisplayType']) {
                            if (me.tableData[me.modelSectionName + 'Data']['calendarDisplayType'] == 'year') {
                                me.chooseYear();
                            }
                        }
                    }
                }

                //mark choosen dates.
                me.markDateRangeDays();
                me.afterDataLoading();

                me.calendarReady = true;
            });
    }

    refreshDispaly() {
        if (this.displayType == 'year') {
            this.chooseYear();
        } else if (this.displayType == 'month') {
            this.chooseMonth();
        } else if (this.displayType == 'day') {
            this.chooseDay();
        }
    }

    searchDateFieldsIndex(models) {

        // //console.log'check dateFieldDataIndex');
        // //console.logthis.dateFieldData);
        // for (let key in this.dateModel) {
        //     if (this.dateFieldData && this.dateModel[key]['name'] == this.dateFieldData['name']) {
        //         this.dateFieldDataIndex = key;
        //     }
        // }
    }

    save(models = null) {
        // let tableData = {};
        // tableData[this.sectionName] = models;
        // this.filters['editedSections'] = [this.sectionName];
        // this.filters['sectionName'] = this.sectionName;
        // this.adminService.globalSave();
        this.adminService.checkSave(models, this.sectionName, this.filters, this.extableData)
            .then((data: any) => {
                this.arrangeHourEvents();
                this.markDateRangeDays();
            });
    }

    //dep
    removeModelId(model) {
        return
        model['main']['removed'] = !!!model['main']['removed'];

        let id = model['main']['value'];
        if (this.markData['removedRow'].indexOf(id) == -1)
            this.markData['removedRow'].push(id);
        else
            this.markData['removedRow'].splice(this.markData['removedRow'].indexOf(id), 1);

        this.qucikEditModel = null;
    }

    //open specific model page
    openAdminModel(sectionName) {
        let params = {
            'sectionName': 'calendar',
            'dateFieldType': this.dateFieldType,
            'dateModel': this.dateModel,
            'dateModelSectionName': this.dateModelSectionName,
            'dateFieldData': this.dateFieldData,
        };
        let navigationExtras: NavigationExtras = {
            queryParams: params
        };
        this.navCtrl.navigateForward('admin-model/' + sectionName, navigationExtras);

        // await this.modalController.dismiss();
    }

    async closeWindow(force = false) {
        if (this.miniCalendar || force) {
            await this.modalController.dismiss();
        }
    }

    setAllCheck(checkAll) {
        for (let cmodel of this.calendarModels) {
            cmodel['check'] = checkAll;
        }
    }

    openEventModal(event, model) {
        // this.adminService.popupEditBox(event, this.sectionName, this.extableData, 'asdasd',
        //     model, 'modelTableEdit', false);
        this.qucikEditModel = model;
        this.showDisplayCol['mini'] = true;
    }

    onMouseMoveOnWeek($event, day, startHour) {
        console.log(day);
        console.log(startHour);

        if (!this.chooseEvent) return;
        this.chooseEvent['start']['value'] = day.format(this.appConfig.dateFormatServer);
        this.chooseEvent['startTime']['value'] = startHour.format('HH:mm:SS');

        console.log(this.chooseEvent['start']['value']);
    }

    onMouseMoveOnDay($event, day) {
        if (!this.chooseEvent) return;
        this.chooseEvent[this.chooseEventDateType]['value'] = day['date'].format(this.appConfig.dateFormatServer);
    }

    //move event if on move mode
    onMouseMove($event, hour) {
        if (this.chooseEvent) {
            console.log($event['screenY'], ' - ', (this.mouseY - 20))

            let field = this.chooseEventDateType; //start hour or end hour
            let displayField = field == 'startTime' ? 'startHourDisplay' : 'endHourDisplay';

            console.log('mouse move!!', hour.format('HH:mm'), ' - ', this.chooseEvent[displayField].format('HH:mm'));

            //go up / go down / stay
            // let addAmount = (!this.mouseY || $event['screenY'] < (this.mouseY - 30)) ? 30 : ($event['screenY'] > (this.mouseY + 30)) ? -30 : 0;
            // if (addAmount == 0) return;
            if (hour.format('HH:mm') == this.chooseEvent[displayField].format('HH:mm')) return;

            // this.arrangeHourEvents();

            //current date
            let startDate = moment(this.chooseEvent['start']['value']).format('DDMMyyyy');

            if (this.chooseEvent[field]['value'] == 'None') this.chooseEvent[field]['value'] = '06:00:00';
            let startTime = moment(this.chooseEvent[field]['value'], 'HH:mm:ss');
            startTime.minutes(parseInt(startTime.format('mm')) < 30 ? 0 : 30);

            //move on array data
            if (field == 'startTime') {
                /* remove last not relavent*/
                let thisHour = startTime.format('HH:mm');
                if (this.eventsBy['hour'][startDate][thisHour]) {
                    this.eventsBy['hour'][startDate][thisHour].splice(this.eventsBy['hour'][startDate][thisHour].indexOf(this.chooseEvent), 1);

                    //new entry
                    let nextHourf = hour.format('HH:mm')
                    if (!this.eventsBy['hour'][startDate][nextHourf]) this.eventsBy['hour'][startDate][nextHourf] = [];
                    this.eventsBy['hour'][startDate][nextHourf].unshift(this.chooseEvent);

                    //update end time move as well - old/new start time diff- change endTime
                    let mdiff1 = Math.abs(moment(this.chooseEvent['startHourDisplay'].format('HH:mm'), 'HH:mm')
                        .diff(moment(hour.format('HH:mm'), 'HH:mm'), 'm'));
                    // let mdiff1 = 30;
                    console.log('update as well: ', mdiff1);
                    if (this.chooseEvent['startHourDisplay'].isAfter(hour)) {
                        console.log('subtract!')
                        this.chooseEvent['endHourDisplay'].subtract(mdiff1, 'm');
                    } else {
                        console.log('add!')
                        this.chooseEvent['endHourDisplay'].add(mdiff1, 'm');
                    }
                    this.chooseEvent['endTime']['value'] = this.chooseEvent['endHourDisplay'].format('HH:mm:ss');
                }
            }

            //display and diff
            this.chooseEvent[displayField] = hour;
            this.chooseEvent[field]['value'] = this.chooseEvent[displayField].format('HH:mm:ss');

            //diff
            let mdiff = moment(this.chooseEvent['endHourDisplay'].format('HH:mm'), 'HH:mm')
                .diff(moment(this.chooseEvent['startHourDisplay'].format('HH:mm'), 'HH:mm'), 'm');
            this.chooseEvent['mdiff'] = Math.abs(mdiff);

            this.mouseY = $event['screenY'];
        }
    }

    //start time!
    onMouseDown() {
        if (!this.chooseEvent) return;

        this.adminService.markUpdated(this.adminService.manageModels['appEventData'], 'appEvent', this.chooseEvent['endTime'], this.chooseEvent);
        this.adminService.markUpdated(this.adminService.manageModels['appEventData'], 'appEvent', this.chooseEvent['startTime'], this.chooseEvent);
        this.chooseEvent = null;
    }

    saveEventHour() {
        console.log('changeEventHour!');
        console.log(this.chooseEvent);
        if (this.chooseEvent) {
            let newModel = {
                'main': {'value': this.chooseEvent['id']['value'], 'updated': true, 'name': '', 'type': 'none'},
                'id': this.chooseEvent['id']
            };

            newModel['endTime'] = {
                'value': this.chooseEvent['endTime']['value'], 'name': 'endTime', 'edited': true, 'type': 'time'
            };
            newModel['startTime'] = {
                'value': this.chooseEvent['startTime']['value'], 'name': 'startTime', 'edited': true, 'type': 'time'
            };
            newModel['start'] = {
                'value': moment(this.chooseEvent['start']['value']).format(this.appConfig.dateFormatServer),
                'name': 'start',
                'edited': true,
                'type': 'date'
            };
            newModel['end'] = {
                'value': moment(this.chooseEvent['end']['value']).format(this.appConfig.dateFormatServer),
                'name': 'end',
                'edited': true,
                'type': 'date'
            };

            if (this.dateModel) {
                this.dateModel[this.dateFieldType['fromField']]['value'] = this.chooseEvent['start']['value'];
                this.dateModel[this.dateFieldType['toField']]['value'] = this.chooseEvent['end']['value'];
            }

            this.save([newModel]);
            this.chooseEvent = null;
        }
    }

    addHalfAnHour($event, event, minutes = 30) {
        let field = 'endTime';
        let displayField = 'endHourDisplay';
        event[displayField] = event[displayField].add(minutes, 'm');
        event[field]['value'] = event[displayField].format('HH:mm:ss');
        let mdiff = moment(event['endHourDisplay'].format('HH:mm'), 'HH:mm')
            .diff(moment(event['startHourDisplay'].format('HH:mm'), 'HH:mm'), 'm');
        event['mdiff'] = Math.abs(mdiff);
        this.adminService.markUpdated(this.adminService.manageModels['appEventData'], 'appEvent', event[field], event);
    }


    //run all over models and update what's needed.
    saveCurrentChanges() {
        if (!this.tableData) return
        this.adminService.checkSave(this.tableData[this.eventKey], this.sectionName, this.filters, this.adminService.manageModels,
            false, this.markData, null, {}, this.baseFilter);
    }

    //stop following event click if new event choose - changeEventHour
    chooseEventClick($event, event, type) {
        console.log(event);
        console.log(type);
        let me = this;
        this.chooseEvent = null;
        setTimeout(function () {
            me.mouseY = null;
            $event.stopPropagation();
            me.chooseEvent = event;
            console.log('set drag event:')
            console.log(me.chooseEvent);
            me.chooseEventDateType = type;
            me.showEvent();
        });
    }

    async eventOnOver(prefix, modelEvent) {
        this.tooltips[prefix] = modelEvent;

        let me = this;
        // this.onOverEvent = {'event': e, 'eventModel': modelEvent};
        setTimeout(function () {
            me.tooltips[prefix] = null;
        }, 3500);
    }
}
