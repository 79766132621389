import {Component, OnInit} from '@angular/core';

export class BusinessImages {
    data = {
        'logo_image':{
            'imagefullurl': null,
        },
        'prefix':'',
        'id':null,
    };

    constructor(public data1: any) {
        this.data = data1;
    }

    //shortHeaders = [];
    prefix = "businessImages";
    title = "Business Images";
    dataFields = {
        id: {'title': 'ID', 'value': null, 'type': 'number', 'readonly': true},
        created: {'title': 'Created', 'value': null, 'type': 'date', 'readonly': true},
        updated: {'title': 'Updated', 'value': null, 'type': 'date', 'readonly': true},
    }
}
