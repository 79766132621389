import {Injectable} from '@angular/core';
import {ModelProvider} from "../../framework/providers/modelProvider/ModelProvider";
import {BusinessService} from "./BusinessService";
import {Events} from "../../framework/providers/events";
import {SelectBarComponent} from "../../app/shaps/components/select-bar/select-bar.component";
import {ModalController, PopoverController} from "@ionic/angular";
import {Observable} from "rxjs";
import {AppDataService} from "./AppDataService";
import {NavigationExtras, Router} from "@angular/router";
import {ModelToolsProvider} from "../../framework/providers/model-tools/model-tools";
import {
    CalendarWrapperComponent
} from "../../app/shaps/components/calendar/calendar-wrapper/calendar-wrapper.component";
import {AppConfig} from "../conf/main";
import {PickAddressComponent} from "../../app/shaps/components/tools/pick-address/pick-address.component";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
// import {Capacitor, Plugins} from "@capacitor/core";
//
// const isPushNotificationsAvailable = Capacitor.isPluginAvailable('PushNotifications');


@Injectable()
export class AdminService {
    mainFilters = {'ordering': '-id', 'type': 'moving', 'name': 'taarif', 'modelOffset': 0, 'modelLimit': 21};

    public appConfig = AppConfig;
    public copyModel;

    private timeoutInt;

    public dashboardData = {};
    public calendarModelsObj = {};
    public manageModels = null;
    public markData = {};
    public markDataById = {};
    public editedSections = [];
    public openModels = {};
    public rowModels = [];
    cacheResults = {};
    public fieldTypeByName = {};

    public manageModelsLowerCase;
    public history = [];
    public openUserAlerts = 0;
    public nextPopupZIndex = 99999;
    public appUser;
    public needSave: boolean = false;

    public checkedData = {};

    public markFields = {};

    // private push: Push;
    constructor(private modelProvider: ModelProvider, public businessService: BusinessService,
                public modelTools: ModelToolsProvider,
                public sanitizer: DomSanitizer,
                public appData: AppDataService, public tools: ModelToolsProvider,
                public router: Router,
                private modalController: ModalController,
                public events: Events, public popoverCtrl: PopoverController) {

        this.events.subscribe('appuserChanged', (data) => {
            //console.log('user changedd!! 1111!');
            this.appUser = data['appuser'];
            //console.log(this.appUser);
        });
    }

    ngOnInit() {
    }

    public setNeedSave(needSave = true) {
        this.needSave = needSave;
    }

    public globalSave() {
        this.events.publish('globalSaveClick');
        this.needSave = false;
    }

    public getServerModel(sectionName, mfilters = {}, id = null) {
        return new Promise((resolve, reject) => {
            let me = this;
            let filters = {
                'ordering': '-id', 'type': 'moving',
                'name': 'taarif', 'modelOffset': 0, 'modelLimit': 20
            };
            filters['sectionName'] = sectionName;
            for (let k in mfilters) {
                filters[k] = mfilters[k];
            }

            if (id) {
                filters['getId'] = id;
            }

            this.modelProvider.list('report', filters).then(function (data: any) {
                let models = data.models;
                let tableData;
                if (models && models.length > 0) //if error occured models len is 0.
                {
                    tableData = models[0].data.finalResReport;
                }
                resolve(tableData);
            });
        });
    }

    public loadAdmin(mfilters = null) {
        let storageData: any = {'version': 1};
        let me = this;
        if (this.manageModels && !mfilters) {
            return new Promise((resolve, reject) => {
                resolve(this.manageModels);
            });
        }

        return this.modelTools.getKey('adminData').then((res: object) => {
            if (res) {
                storageData = res;
            }

            return new Promise((resolve, reject) => {
                let me = this;
                let filters = {
                    'ordering': '-id', 'type': 'moving', 'version': storageData['version'],
                    'name': 'taarif', 'modelOffset': 0, 'modelLimit': 20
                };

                if (mfilters) {
                    for (let k in mfilters) {
                        filters[k] = mfilters[k];
                    }

                }
                this.modelProvider.list('report', filters).then(function (data: any) {
                    let models = data.models;
                    let tableData;
                    if (models && models.length > 0) //if error occured models len is 0.
                    {
                        tableData = models[0].data.finalResReport;
                        if (!tableData) {
                            resolve({});
                            return
                        }

                        //
                        Object.assign(storageData, tableData);//copy added keys.
                        //
                        me.modelTools.setKey('adminData', storageData);

                        me.setManageModels(storageData);
                        me.businessService.setAdminData(storageData);
                        me.dashboardData = storageData;
                        me.loadCalendar(storageData);
                        if (tableData['appAlertTop']) {
                            me.openUserAlerts = tableData['appAlertTop']['count'];
                        }
                    }
                    resolve(storageData);
                });
            });
        })
    }

    loadCalendar(tableData) {
        if (tableData['calendarModels']) {
            let calendarModels = tableData['calendarModels'];
            let calendarModelsObj = {}
            for (let k in calendarModels) {
                calendarModelsObj[calendarModels[k]['name']] = calendarModels[k];
            }
            this.manageModels['calendarModelsObj'] = calendarModelsObj;
            this.calendarModelsObj = calendarModelsObj;
        }
    }

    setManageModels(manageModels) {
        //never remove manage models

        // this.addManageModels(manageModels);
        this.manageModels = manageModels;
        this.manageModelsLowerCase = {};
        //
        for (let key in this.manageModels) {
            this.manageModelsLowerCase[key.toLowerCase()] = key;
        }
    }

    addManageModels(manageModels) {
        this.manageModelsLowerCase = {};

        for (let key in manageModels) {
            this.manageModels[key.toLowerCase()] = manageModels[key];
            this.manageModelsLowerCase[key.toLowerCase()] = key;
        }
    }

    getModelByLower(lowerKey) {
        return this.manageModels[this.manageModelsLowerCase[lowerKey]];
    }

    getFieldsTypeByLower(lowerKey, exTableData) {
        let secName = this.manageModelsLowerCase[lowerKey + 'ref'];

        if (!this.fieldTypeByName[secName]) {
            let d = {};
            for (let fieldData of exTableData[secName]) {
                d[fieldData['name']] = fieldData;
            }
            this.fieldTypeByName[secName] = d;
        }

        return this.fieldTypeByName[secName];
    }

    //return model fields by field name - save for later requests.
    getFieldsType(secName, exTableData) {
        if (!exTableData || !exTableData[secName + 'Ref']) {
            return {}
        }

        if (!this.fieldTypeByName[secName] || this.fieldTypeByName[secName].length == 0) {
            let d = {};
            for (let fieldData of exTableData[secName + 'Ref']) {
                d[fieldData['name']] = fieldData;
            }
            this.fieldTypeByName[secName] = d;
        }
        return this.fieldTypeByName[secName];
    }

    addEditedSection(key) {
        if (this.editedSections.indexOf(key) == -1) {
            this.editedSections.push(key);
        }
    }

    setRowModel(rowModel) {
        //console.log'set row model:');
        this.rowModels.push(rowModel);
        //console.logthis.rowModels);
    }

    getRowModel() {
        let rowModel = this.rowModels.pop();
        return rowModel;
    }


    setResult(key, data) {
        this.fieldTypeByName[key] = null;
        this.cacheResults[key] = data;
    }

    getResult(key) {
        return this.cacheResults[key];
    }

    getResultById(key, id) {
        if (!this.cacheResults[key + 'ByIds']) {
            return null;
        }

        return this.cacheResults[key + 'ByIds'][id];
    }

    setResultById(key, id, data) {
        if (!this.cacheResults[key + 'ByIds']) {
            this.cacheResults[key + 'ByIds'] = {};
        }
        this.cacheResults[key + 'ByIds'][id] = data;
    }

    //new admin save field
    saveField(sectionName, model, fieldData, fieldType = null, callback = null, fieldName = null) {
        let me = this;
        let filters = {'type': 'moving', 'name': 'taarif'};

        if (!fieldType && fieldName) {
            fieldType = this.getFieldsType(sectionName, this.manageModels)[fieldName];
        }

        let newModel = {
            'main': {'value': model['id']['value'], 'updated': true, 'name': '', 'type': 'none'},
            'id': model['id']
        };

        newModel[fieldType['name']] = {
            'value': fieldData['value'],
            'value1': fieldData['value1'],//extra option to save more data.
            'extraData': fieldData['extraData'],
            'name': fieldType['name'],
            'edited': true,
            'type': fieldType['type'],
            'typeData': fieldType
        };
        //console.lognewModel);

        let tableData = {};
        tableData[sectionName] = [newModel];

        filters['editedSections'] = [sectionName];
        filters['sectionName'] = sectionName;

        fieldData['fieldSaveWait'] = true;
        this.saveToMarkedRows(sectionName, fieldType, fieldData);

        return this.modelProvider.customPost('saveDataReport', [tableData], filters, true).then((data: any) => {

            setTimeout(function () {
                fieldData['fieldSaveWait'] = false;
            }, 2000);

            let fieldKey = fieldType['name'];
            let newModel = data['data']['models'][0][fieldKey];
            if (newModel) {
                fieldData['afterText'] = newModel['afterText']
                fieldData['beforeText'] = newModel['beforeText']
                fieldData['value'] = newModel['value']
                if (!fieldData['value1']) {
                    fieldData['displayName'] = newModel['displayName']
                } else {
                    fieldData['displayName'] = newModel['displayName'] + ' - ' + fieldData['value1'];
                }
            }

            if (callback) callback(data);

            //update calendar
            if (['date', 'datetime'].includes(fieldType['type'])) {
                me.events.publish('calendarRefresh', {'fieldData': fieldData});
            }
        });
    }

    //new admin save field
    saveFields(sectionName, model, fields, callback = null) {
        console.log('save fields');
        console.log(fields);

        let me = this;
        let filters = {'type': 'moving', 'name': 'taarif'};

        let newModel = {
            'main': {'value': model['id']['value'], 'updated': true, 'name': '', 'type': 'none'},
            'id': model['id']
        };
        for (let field of fields) {
            if (!field['fieldType']) continue;
            newModel[field['fieldType']['name']] = {
                'value': field['fieldData']['value'],
                'name': field['fieldType']['name'],
                'edited': true,
                'type': field['fieldType']['type'],
                'fieldSaveWait': true,
                'typeData': field['fieldType']
            };
        }
        //console.lognewModel);

        let tableData = {};
        tableData[sectionName] = [newModel];

        filters['editedSections'] = [sectionName];
        filters['sectionName'] = sectionName;

        return this.modelProvider.customPost('saveDataReport', [tableData], filters, true).then((data: any) => {

            setTimeout(function () {
                for (let field of fields) {
                    field['fieldSaveWait'] = false;
                }
            }, 2000);

            if (callback) callback(data);
        });
    }

    saveModel(newModel, sectionName, exTableData, prefix = "aiitmp", callback = null, filters = {}): Observable<any> {
        return new Observable((observer) => {
            // let fieldTypeByName = this.getFieldsType(sectionName, exTableData);
            let newModels = [newModel];

            // let filters = {};
            filters['editedSections'] = [sectionName];
            filters['sectionName'] = sectionName;

            let tableData = {};
            tableData[sectionName] = newModels;
            this.modelProvider.customPost('saveDataReport', [tableData], filters, true).then((data: any) => {
                //table prefix - table type section
                this.events.publish("btnclick_" + prefix, {'key': 'customFilter', 'data': {}});

                if (callback) callback(data);
                // console.log('saved!!!!');

                observer.next(data);
                observer.complete();
            });
        });
    }

    //add relation table - between model and relationTableData['sideFilterKey1'] - defined on model.py
    addRelationModelConnection(sectionName, prefix, relationTableData, exTableData, filters) {
        let fieldTypeByName = this.getFieldsType(sectionName, exTableData);

        let newModels = [];

        let newModel = {
            'id': {'value': null},
            'main': {'updated': true},
        };
        newModel[relationTableData['sideFilterKey1']] = {
            'name': relationTableData['sideFilterKey1'],
            'edited': true,
            'value': relationTableData['sideFilterTable1Id'],
            'type': fieldTypeByName[relationTableData['sideFilterKey1']]['type'],
            'typeData': fieldTypeByName[relationTableData['sideFilterKey1']]
        };

        //fieldData['modelId']
        if (relationTableData['sideFilterKey2']) {
            newModel[relationTableData['sideFilterKey2']] = {
                'name': relationTableData['sideFilterKey2'],
                'edited': true,
                'value': relationTableData['sideFilterTable2Id'],
                'type': fieldTypeByName[relationTableData['sideFilterKey2']]['type'],
                'typeData': fieldTypeByName[relationTableData['sideFilterKey2']]
            };
        }

        newModels.push(newModel);
        filters['editedSections'] = [sectionName];
        filters['sectionName'] = sectionName;

        let tableData = {};
        tableData[sectionName] = newModels;
        this.modelProvider.customPost('saveDataReport', [tableData], filters, true).then((data: any) => {
            //table prefix - table type section
            this.events.publish("btnclick_" + prefix, {'key': 'customFilter', 'data': {}});
        });
    }


    //add relation table - between model and relationTableData['sideFilterKey1'] - defined on model.py
    createRelationModels(sectionName, modelId, fieldName2, modelsId2, exTableData) {
        return new Promise((resolve, reject) => {
            let fieldTypeByName = this.getFieldsType(sectionName, exTableData);
            let filters = {};

            let newModel = {
                'id': {'value': null},
                'main': {'updated': true},
            };
            newModel['autoAddField'] = {
                'name': fieldName2,
                'value': modelId,
                'addIds': modelsId2
            };
            let newModels = [newModel];

            filters['editedSections'] = [sectionName];
            filters['sectionName'] = sectionName;

            let tableData = {};
            tableData[sectionName] = newModels;
            this.modelProvider.customPost('saveDataReport', [tableData], filters, true).then((data: any) => {
                //table prefix - table type section
                // this.events.publish("btnclick_" + prefix, {'key': 'customFilter', 'data': {}});
                resolve(true);
            });
        });
    }

    //popups
    //popups

    // popups popups popups
    //popups

    //depricated
    async popupEditBoxByData(myEvent, data, bigPopup = true, component = null, cssText = null, maxWidth = null) {
        console.log('popup Edit Box By Data');

        // const modal = await this.modalController.create({
        //     component: component ? component : SelectBarComponent,
        //     componentProps: data,
        //     cssClass: bigPopup ? 'bigger-popover' : 'smaller-popover'
        // });
        // return await modal.present();
        //
        // return
        // return
        // return
        // return
        //
        //     event: myEvent,
        //
        //
        //     reference: "trigger",
        //     side: 'top',
        //     cssClass: 'bigger-popover'
        let data1 = {
            component: component ? component : SelectBarComponent,
            componentProps: data,
            showBackdrop: true,
            animated: false,
        }
        let popover;
        if (myEvent) {
            data1['event'] = myEvent;
            console.log('popover!')
            popover = await this.popoverCtrl.create(data1);
        } else {
            data['isModalCntrl'] = true;
            console.log('modalController! ', bigPopup)
            popover = await this.modalController.create(data1);
        }
        if (!maxWidth) {
            maxWidth = this.businessService.screenSize[0] - 30;
        }

        this.nextPopupZIndex += 50;

        //when opening one alert and than the next one - z-index is important. open fab buttons on taarif page for example.
        let w: number = 1250;
        if (maxWidth < 1250) w = maxWidth;

        if (bigPopup) {
            popover.style.cssText = 'border: 1px solid;left: 10px;--min-width: ' + w + 'px; --max-width:' + maxWidth + 'px;' +
                'top:60px;--offset-y:40px;z-index:' + this.nextPopupZIndex + ';';
        } else {
            w = 650;
            if (maxWidth < 650) w = maxWidth;
            popover.style.cssText = '--min-width: ' + w + 'px; --max-width: ' + w + 'px;--max-height:550px;';
        }
        if (data['z-index']) {
            // popover.style.cssText += 'z-index:' + data['z-index'] + ';';
            popover.style.cssText += 'z-index:' + this.nextPopupZIndex + ';';
        } else {
            popover.style.cssText += 'z-index:' + this.nextPopupZIndex + ';';
        }
        if (cssText) {
            popover.style.cssText = cssText;
        }

        return await popover.present();
    }

    //popoverCtrl
    async popupEditBox(myEvent, sectionName, exTableData, prefix,
                       model, type = 'modelTableEdit', bigPopup = true,
                       fieldData = null, fieldType = null, getModelById = null, baseFilter = null, filters = null, extraData = null) {
        // if (!sectionName)
        // {
        //     console.log(sectionName, ' - not found.')
        //     return;
        // }

        if (!exTableData) {
            exTableData = this.manageModels;
        }
        // if (fieldData && fieldData['type'] != 'selectTable' && fieldData['type'] != 'table') {
        //     type = 'editField';
        // }
        let d = {
            'isModalCntrl': bigPopup ? true : false,
            'getModelById': getModelById,
            'model': model,
            'prefix': prefix,
            'sectionName': sectionName,
            'fieldData': fieldData,
            'fieldType': fieldType,
            'exTableData': exTableData,
            'avoidSave': false,
            'type': type,
            'baseFilter': baseFilter,
            'filters': filters,
            'extraData': extraData, 'z-index': 14,
        };
        console.log(d);
        this.addToHistory({'sectionName': sectionName, 'model': model});
        return this.popupEditBoxByData(myEvent, d, bigPopup);
        //
        // let popover;
        // if (bigPopup) {
        //
        //     popover = await this.modalController.create({
        //         component: SelectBarComponent,
        //         componentProps: d,
        //         animated: true,
        //         showBackdrop: true,
        //         cssClass: 'note-popover icenter-popover ' + (bigPopup ? 'bigger-popover right-popup' : 'smaller-popover')
        //     });
        //     // popover.style.cssText = '--min-width: 1250px; --max-width: 1270px;--top:30px;';
        // } else {
        //     popover = await this.modalController.create({
        //         component: SelectBarComponent,
        //         componentProps: d,
        //         animated: true,
        //         showBackdrop: true,
        //         cssClass: 'note-popover icenter-popover ' + (bigPopup ? 'bigger-popover right-popup' : 'smaller-popover')
        //     });
        //     // popover.style.cssText = '--min-width: 450px; --max-width: 470px;--max-height:400px;--top:30px;';
        // }
        //
        // let maxWidth = this.businessService.screenSize[0] - 30;
        // if (bigPopup) {
        //     let w: number = 1250;
        //     if (maxWidth < 1250) w = maxWidth;
        //     popover.style.cssText = 'border: 1px solid;left: 10px;--min-width: ' + w + 'px; --max-width:' + maxWidth + 'px;top:60px;--offset-y:40px;';
        // } else
        //     popover.style.cssText = '--min-width: 150px; --max-width: 345px;--max-height:400px;';

        // this.addToHistory({'sectionName': sectionName, 'model': model});
        // return await popover.present();
    }

    async showModal(data, bigPopup = false) {
        console.log('showModal')
        const popover = await this.popoverCtrl.create({
            component: SelectBarComponent,
            componentProps: data,
            event: null,
            animated: true,
            showBackdrop: true,
            cssClass: 'note-popover icenter-popover' + (bigPopup ? ' bigger-popover right-popup' : ' smaller-popover')
        });
        this.addToHistory({'sectionName': data['sectionName'], 'model': null});
        return await popover.present();
    }

    //genric model
    //section name = name of the original model
    //fieldKey - name of the generic model
    async showGenericModal(sectionName, model, prefix, fieldKey, exTableData, bigPopup = true, myEvent = null) {
        // this.events.publish('showGenericModel', {
        //     'modelName': this.sectionName,
        //     'fieldKey': fieldKey,
        //     'model': this.model
        // });
        console.log('showGenericModal')

        let d = {
            'model': model,
            'extraData': {'extSectionName': sectionName},
            'prefix': prefix,
            'fieldData': {'name': fieldKey, 'objectModel': sectionName},
            'exTableData': exTableData,
            'sectionName': fieldKey,
            'genericModelTable': true,
            'type': 'modelTableEdit',
            'isModalCntrl': true
        };

        this.addToHistory({'sectionName': sectionName, 'model': model});

        // let data = {
        //     component: SelectBarComponent,
        //     componentProps: d,
        //     event: myEvent,
        //     cssClass: 'note-popover icenter-popover ' + (bigPopup ? 'bigger-popover right-popup' : 'smaller-popover')
        // }
        this.popupEditBoxByData(myEvent, d, bigPopup);
        // const popover = await this.popoverCtrl.create(data);
        // let w = this.businessService.screenSize[0];
        // // popover.style.cssText = 'top:-0px !important;'
        // // popover.style.cssText = '--min-width: ' + (w > 1250 ? 1220 : w - 30) + 'px; --max-width: ' + (w > 1250 ? 1220 : w - 30) + 'px;' +
        // //     'top:100px;--offset-y:40px;';
        // return await popover.present();
    }

    async autoPopup(nav, sectionName, model, prefix, exTableData, modelId = null,
                    extraData = {}, bigPopup = false) {
        if (model || modelId) {
            this.openCustomModel(nav, prefix, exTableData, sectionName, null, null,
                modelId, model, null, null,
                null, bigPopup, null, 'modelTableEdit', extraData);
        } else {
            this.openCustomTable(nav, prefix, exTableData, sectionName);
        }
    }

    async popupModelTable(sectionName, exTableData, prefix, baseFilter = null) {
        this.popupEditBox(null, sectionName, exTableData, prefix,
            null, 'simpleTable', true, null, null,
            null, baseFilter, null);

    }

    //open new page
    async popupEditBoxNav(myEvent, sectionName, exTableData, prefix,
                          model = null, navCtrl = null, forceNewWindow = false, extraData = {}) {
        let params = {
            'sectionName': sectionName,
        };
        for (let k in extraData) {
            params[k] = extraData[k];
        }

        let prefx = '';
        if (model) {
            if (model['main']['extId'] && model['main']['sectionName']) {
                params['rowId'] = model['main']['extId'];
                prefx = model['main']['sectionName'];
                params['sectionName'] = model['main']['sectionName'];
            } else {
                params['rowId'] = model['id']['value'];
                prefx = model['id']['value'];
            }
        }
        console.log(model);
        let navigationExtras: NavigationExtras = {
            queryParams: params
        };
        navCtrl.navigateForward('admin-model/' + sectionName + prefx, navigationExtras);
        // } else {
        // return this.popupEditBox(myEvent, sectionName, exTableData, prefix,
        //     model, 'modelTableEdit', true,
        //     null, null, null, null, null)
        // let d = {
        //     'prefix': prefix,
        //     'forceDisplay': forceDisplay,
        //     'specificFields': specificFields,
        //     'sectionName': sectionName,
        //     'exTableData': exTableData,
        //     'avoidSave': false,
        //     'type': 'simpleTable',
        //     'baseFilter': baseFilter,
        // };
        // return await this.popupEditBoxByData(null, d, true);
        // }

    }

    async toolTip(event, tableInfo, sectionName = '', extraData = null) {
        let d = {
            'event': 'event',
            'prefix': 'toolTip',
            'sectionName': sectionName,
            'tableInfo': tableInfo,
            'extraData': extraData,
            'exTableData': this.manageModels,
            'avoidSave': false,
            'type': 'toolTip'
        };

        let cssText = 'border: 1px solid;left: 10px;--min-width:400px;--max-width:400px;' +
            '--min-height: ' + 560 + 'px;--max-height:' + 560 + 'px;z-index:' + this.nextPopupZIndex + ';';

        return await this.popupEditBoxByData(event, d, false, null, cssText);
    }

    async openCustomTable(navCtrl, prefix, exTableData, sectionName,
                          baseFilter = null, specificFields = null, forceDisplay = null, forceBig = false, bigPopup = true) {
        if (this.businessService.screenSize[0] > 1000 && !forceBig) {
            let d = {
                'prefix': prefix,
                'forceDisplay': forceDisplay,
                'specificFields': specificFields,
                'sectionName': sectionName,
                'tableInfo': {'exFieldData': {'hideTableDisplayName': !bigPopup}},
                'exTableData': exTableData,
                'avoidSave': false,
                'type': 'simpleTable',
                'baseFilter': baseFilter,
            };
            return await this.popupEditBoxByData(null, d, bigPopup);
        } else {
            this.popupEditBoxNav(null, sectionName, exTableData, prefix,
                null, navCtrl, false, {})
        }
    }

    async openCustomModel(nav, prefix, exTableData, sectionName, rowIndex = null, baseFilter = null,
                          rowId = null, showRowModel = null, displayType = null,
                          popup = false, extraParams = {}, bigPopup = true, event = null,
                          type = 'modelTableEdit', extraData: any = {}) {

        if (!this.manageModels) {
            console.log('no data.');
            return
        }

        let d = {
            'prefix': prefix,
            'sectionName': sectionName,
            'exTableData': exTableData,
            'avoidSave': false,
            'type': type,
            'getModelById': rowId,
            'model': showRowModel,
            'baseFilter': baseFilter,
        };
        for (let key in extraData) {
            d[key] = extraData[key];
        }
        console.log(d)
        return await this.popupEditBoxByData(null, d, bigPopup);
        return

        //'filters': filters,
        //
        //             'type': type,
        // 'fieldData': fieldData,
        //             'fieldType': fieldType,
    }

    goToAdmin(nav, sectionName, model, rowId = null, baseFilter = null, extraParams = null, forceRefresh = false) {
        let params = {
            'sectionName': sectionName,
            'showRowModel': model,
            'animated': true,
            'showBackdrop': true,
            'rowId': rowId,
            'baseFilter': baseFilter,
            'forceRefresh': forceRefresh,
        };
        if (extraParams) {
            for (let k in extraParams) {
                params[k] = extraParams[k];
            }
        }

        let navigationExtras: NavigationExtras = {
            queryParams: params
        };
        nav.navigateForward('admin-model/' + sectionName + rowId, navigationExtras);
    }

    // end popups
    // end popups
    // end popups

    //select field options
    async selectEditBox(myEvent, model, prefix, sectionName, exTableData, type = 'modelTableEdit',
                        bigPopup = true, fieldData = null, fieldType = null, baseFilter = null, zindex = null, extraData = {}, maxWidth = null) {
        // if (fieldData && fieldData['type'] != 'selectTable' && fieldData['type'] != 'table') {
        //     type = 'editField';
        // }

        //for sending to server if needed in select table.
        fieldData['modelId'] = model['id']['value'];
        fieldData['topModelSection'] = sectionName;
        let d = {
            'model': model,
            'prefix': prefix,
            'sectionName': sectionName,
            'extraData': extraData,
            'fieldData': fieldData,
            'fieldName': fieldType['name'],
            'fieldType': fieldType,
            'exTableData': exTableData,
            'avoidSave': false,
            'type': type,
            'z-index': zindex
        };
        console.log('d:', d)
        this.popupEditBoxByData(myEvent, d, bigPopup, null, null, maxWidth);
    }

    openTableExtraFilters(navCtrl, sectionName, rowIndex = null, baseFilter = null, extraFilters = null, extraSaveFilters = null) {
        console.log('open custom model: ', sectionName);
        console.log(baseFilter)
        let params = {
            'sectionName': sectionName,
            'rowIndex': rowIndex,
            'baseFilter': baseFilter,
            'extraFilters': extraFilters,
            'extraSaveFilters': extraSaveFilters,
        };

        let navigationExtras: NavigationExtras = {
            queryParams: params
        };

        navCtrl.navigateForward('admin-model/' + sectionName + rowIndex, navigationExtras);
    }

    simpleTable() {

    }

    //
    //
    //
    //

    removeModels(sectionName, ids, callback = null, askbefore = true) {

        //console.log'save!!');

        let tableData = {};

        tableData[sectionName] = [];

        tableData[sectionName + '_markedRow'] = ids;
        let filters = {'type': 'moving', 'name': 'taarif'};
        filters['removeMarkRows'] = sectionName;

        filters['editedSections'] = [sectionName];
        filters['sectionName'] = sectionName;

        let me = this;
        if (askbefore) {
            this.tools.yesOrNoAlert(function () {
                me.modelProvider.customPost('saveDataReport', [tableData], filters, true).then((data: any) => {
                    if (callback) callback(data);
                });
            })
        } else {
            me.modelProvider.customPost('saveDataReport', [tableData], filters, true).then((data: any) => {
                if (callback) callback(data);
            });
        }
    }

    // anqData => {'sectionName': data['sectionName'], 'data': data, 'model': null}
    removeHistoryAnq(index) {
        this.history.splice(index, 1);
    }

    addToHistory(anqData) {
        let isFound = false;
        for (let anq of this.history) {
            if (anq['sectionName'] == anqData['sectionName'] && !anqData['model'])
                isFound = true;
            else if (anq['sectionName'] == anqData['sectionName'] && anqData['model']
                && anqData['model'] == anq['model'])
                isFound = true;
        }
        if (!isFound) this.history.unshift(anqData);
    }

    getNewModel(sectionName, exTableData) {
        let newModel = {'main': {'value': -1, 'updated': true, 'name': '', 'type': 'none'}, 'id': {'value': null}};
        let refmodel = exTableData[sectionName + 'Ref'];
        for (let fieldData of refmodel) {
            ////console.logfieldData);
            newModel[fieldData['name']] = {
                'value': null,
                'name': fieldData['name'],
                'edited': true,
                'type': fieldData['type']
            }
            if (fieldData['options']) newModel[fieldData['name']]['optionsActive'] = [];
            if (fieldData['optionsActive']) newModel[fieldData['name']]['optionsActive'] = [];
        }
        newModel['id']['value'] = null;
        return newModel;
    }


    updateAppData() {
        let filters = {'ordering': '-id', 'type': 'moving', 'name': 'taarif', 'modelOffset': 0, 'modelLimit': 20};
        filters['editedSections'] = [];
        filters['sectionName'] = '';
        filters['updateData'] = true;
        filters['updateModelClasses'] = true;
        this.modelProvider.customPost('saveDataReport', [], filters, true).then((data: any) => {
            // this.events.publish('refresh_request_' + this.prefix, this.filters);
            //console.log(data);
            this.appData.getAppData(data['data']['version']);
        });
    }

    //create and save new model
    addNewModel(sectionName, data) {
        let m = this.getNewModel(sectionName, this.manageModels);
        for (let k in data) {
            m[k] = data[k];
            m[k]['type'] = this.getFieldsType(sectionName, this.manageModels)[k]['type'];
            m[k]['edited'] = true;
        }
        let me = this;
        this.saveModel(m, sectionName, this.manageModels).subscribe((data) => {
            //console.log('back here!');
            // this.adminService.updateAppData();
        })
    }

    isStaff() {
        return (this.appUser && this.appUser.data['is_staff']);
    }

    async openGenericModelTable(sectionName, modelId, subSectionName = null, fieldKey) {
        if (!this.isStaff()) {
            //console.log('no permission');
            return;
        }

        let f = {};

        f[sectionName] = {
            'searchFieldValue': modelId,
            'searchField': 'objectId',
            'search_in_field': true
        }

        if (subSectionName) {
            f[sectionName]['searchFieldValue1'] = subSectionName;
            f[sectionName]['searchField1'] = 'contentType__model';
        }

        let m = {'id': {}, 'main': {}};
        m['id']['value'] = modelId;
        m['main'] = {'genericFilters': f};

        this.manageModels[sectionName + 'Data'] = {};
        this.manageModels[sectionName + 'Data']['tableDesc'] = "-";
        this.manageModels[sectionName + 'Data']['totalCount'] = "-";
        return await this.showGenericModal(subSectionName, m, 'prefix', sectionName, this.manageModels);
    }

    getMainFilters() {
        return JSON.parse(JSON.stringify(this.mainFilters));
    }

    saveAll() {
        return
        for (let k in this.manageModels) {
            if (this.manageModels[k]['updated']) {
                // console.log(this.manageModels[k])
                let sectionName = this.manageModels[k]['modelPrefix'];
                console.log('found save model: ', sectionName);
                this.checkSave(this.manageModels[sectionName], sectionName, {}, this.manageModels);
            }
        }
        this.needSave = false;
    }

    checkSave(tableRows, sectionName, filters, exTableData,
              remove = false, markData = {}, markDataById = null, extraSaveFilters = null, baseFilter = null, callback = null) {
        let newModels = [];
        let fieldType;
        let fieldData;
        let model;
        //console.log"saveCurrentChanges:");
        //console.logthis.tableData);
        let fieldsType = this.getFieldsType(sectionName, exTableData);
        for (let index in tableRows) {
            if (tableRows[index]['main']['updated']) {
                model = tableRows[index];

                let newModel = {
                    'main': {'value': model['id']['value'], 'updated': true, 'name': '', 'type': 'none'},
                    'id': model['id']
                };

                for (let fieldKey in tableRows[index]) {
                    if (tableRows[index][fieldKey]['edited'] && tableRows[index][fieldKey]['validate']) {
                        //console.log'#', this.tableRows[index]['id']['value'], ' - ', fieldKey);
                        fieldData = tableRows[index][fieldKey];
                        fieldType = fieldsType[fieldKey];

                        newModel[fieldType['name']] = {
                            'value': fieldData['value'],
                            'markbox': fieldData['markbox'],
                            'extraSelect': fieldData['extraSelect'],
                            'value1': fieldData['value1'],
                            'value2': fieldData['value2'],
                            'name': fieldType['name'],
                            'multiChooseValue': fieldData['multiChooseValue'],
                            'optionsActive': fieldData['optionsActive'],
                            'edited': true,
                            'type': fieldType['type'],
                            'typeData': fieldType
                        };
                    }
                }

                newModels.push(newModel);
            }
        }

        if (newModels.length > 0) {
            return this.saveModels(tableRows, sectionName, filters, newModels, exTableData,
                remove, markData, markDataById, extraSaveFilters, baseFilter, callback)
        }
    }

    saveModels(tableRows, sectionName, filters, newModels, exTableData,
               remove, markData, markDataById, extraSaveFilters = null, baseFilter = null, callback = null) {
        //console.log'save!!');
        //console.lognewModels);
        console.log(this.manageModels['perms'])
        if (!this.manageModels['perms'].includes(sectionName))
            return;

        if (!filters) filters = {};

        let tablesData = {};

        //auto set field from field type.
        for (let field of exTableData[sectionName + 'Ref']) {
            if (field['autoSet']) {
                for (let model of newModels) {
                    model[field['name']] = {
                        'value': field['autoSet'],
                        'edited': true,
                        'type': field['type'],
                        'name': field['name']
                    }
                }
            }
        }

        tablesData[sectionName] = newModels;

        tablesData[sectionName + '_markedRow'] = markData['markedRow'];
        if (remove) {
            filters['removeMarkRows'] = sectionName;

            //mark it
            let ids = [];
            for (let id of markData['markedRow']) {
                let i = tableRows.indexOf(markDataById[id]);
                if (i != -1) {
                    tableRows[i]['modelRemoved'] = true;
                    ids.push(id);
                }
            }
            //then remove it
            let me = this;
            setTimeout(function () {
                for (let id of ids) {
                    tableRows.splice(tableRows.indexOf(markDataById[id]), 1);
                }

                markDataById = [];
            }, 1000);
        } else {
            filters['removeMarkRows'] = null;
        }
        // this.filters['extraSaveFilters'] = {};

        filters['editedSections'] = [sectionName];
        filters['sectionName'] = sectionName;

        if (extraSaveFilters) {
            filters['extraSaveFields'] = JSON.stringify(extraSaveFilters);
        }

        if (baseFilter) {
            filters['extraSaveFilters'] = JSON.stringify(baseFilter);
        }

        return this.modelProvider.customPost('saveDataReport', [tablesData], filters, true).then((data: any) => {
            if (callback) callback(data);

            if (!data['data']['models']) return;

            let models = data['data']['models'];

            exTableData[sectionName + 'Data']['edited'] = false;
            exTableData[sectionName + 'Data']['updated'] = false;

            //reset update flags.
            for (let index in tableRows) {
                if (tableRows[index]['main']['updated']) {
                    tableRows[index]['main']['updated'] = false;
                    let id = tableRows[index]['id']['value'];
                    let newModel = null;

                    //get match model from callback
                    for (let k in models) {
                        if (models[k]['id']['value'] == id) {
                            newModel = models[k];
                        }
                    }

                    for (let fieldKey in newModel) {
                        if (!tableRows[index][fieldKey]) continue;

                        if (tableRows[index][fieldKey]['edited'] && tableRows[index][fieldKey]['validate']) {
                            tableRows[index][fieldKey]['edited'] = false;
                        }
                        tableRows[index][fieldKey]['fieldSaveWait'] = false;
                        // tableRows[index][fieldKey]['editMode'] = false;

                        tableRows[index][fieldKey]['afterText'] = newModel[fieldKey]['afterText']
                        tableRows[index][fieldKey]['beforeText'] = newModel[fieldKey]['beforeText']
                        tableRows[index][fieldKey]['value'] = newModel[fieldKey]['value']
                        tableRows[index][fieldKey]['displayName'] = newModel[fieldKey]['displayName']
                    }
                    if (newModel['main'] && newModel['main']['alerts']) {
                        tableRows[index]['main']['alerts'] = newModel['main']['alerts'];
                    }
                    if (newModel['main'] && newModel['main']['options']) {
                        tableRows[index]['main']['options'] = newModel['main']['options'];
                    }

                    // if (newModel) {
                    //     tableRows[index]['main']['displayName'] = newModel['main']['displayName']
                    //     tableRows[index]['main']['color'] = newModel['main']['color']
                    //     tableRows[index]['main']['icon'] = newModel['main']['icon']
                    // }
                }
            }
        });
    }

    changeOrder(sectionName, model, dir, tableData, tableRows, rowIndex) {
        let orderField = tableData['canChangeOrder'];
        if (!model[orderField]['value']) model[orderField]['value'] = 0;
        if (dir == 'up') {
            model[orderField]['value'] += 1;
        } else {
            model[orderField]['value'] -= 1;
        }
        let me = this;
        clearTimeout(this.timeoutInt);
        this.timeoutInt = setTimeout(function () {
            me.saveField(sectionName, model, model[orderField], null, null, orderField);
        }, 2000);
        tableRows.sort(function (a, b) {
            return b[orderField]['value'] - a[orderField]['value'];
        });
        return

        let needSave = false;
        if (dir == 'up') {
            if (rowIndex > 0 && tableRows[rowIndex - 1]) {
                let oldIndex = model[orderField]['value'];
                let newIndex = tableRows[rowIndex - 1][orderField]['value'];

                if (oldIndex == newIndex) newIndex += 1;
                needSave = true;
                model[orderField]['value'] = newIndex;
                tableRows[rowIndex - 1][orderField]['value'] = oldIndex;

                this.saveField(sectionName, model, model[orderField], null, null, orderField);
                this.saveField(sectionName, tableRows[rowIndex - 1], tableRows[rowIndex - 1][orderField], null, null, orderField);

            }
        } else if (tableRows[rowIndex + 1]) {
            let oldIndex = model[orderField]['value'];
            let newIndex = tableRows[rowIndex + 1][orderField]['value'];
            needSave = true;
            if (oldIndex == newIndex) newIndex -= 1;
            model[orderField]['value'] = newIndex;
            tableRows[rowIndex + 1][orderField]['value'] = oldIndex;

            this.saveField(sectionName, model, model[orderField], null, null, orderField);
            this.saveField(sectionName, tableRows[rowIndex + 1],
                tableRows[rowIndex + 1][orderField], null, null, orderField);
        }
        if (needSave) {
            tableRows.sort(function (a, b) {
                return b[orderField]['value'] - a[orderField]['value'];
            });
        }
    }

    markUpdated(tableInfoData, sectionName, fieldData, model) {
        tableInfoData['updated'] = true;
        this.manageModels[sectionName + 'Data']['updated'] = true;
        fieldData['edited'] = true;
        model['main']['updated'] = true;
        fieldData['validate'] = true;
        this.setNeedSave();
    }

    //copy to other marked rows.
    saveToMarkedRows(sectionName, fieldType, fieldData) {
        let markData = this.manageModels[sectionName + 'MarkData'];

        if (markData) {
            for (let id of markData['markedRow']) {
                let model = this.manageModels[sectionName + 'MarkDataById'][id];
                // model['displayName']['value'] = me.fieldData['displayName'];
                for (let k of ['validate', 'edited', 'fieldSaveWait', 'displayName', 'value', 'icon', 'color']) {
                    model[fieldType['name']][k] = fieldData[k];
                }
                model[fieldType['name']]['edited'] = true;
                model['main']['updated'] = true;
            }
        }

        let markFields = this.markFields[sectionName];
        if (markFields) {
            //            this.markFields[sectionName][modelId + '_' + fieldName] = {'fieldData': fieldData, 'model': model};
            for (let key in markFields) {
                let fieldData1 = markFields[key]['fieldData'];
                let model = markFields[key]['model'];
                let exValue = fieldData['value'];

                // fieldData1['displayName'] = exValue;
                // fieldData1['edited'] = true;
                // fieldData1['value'] = exValue;
                for (let k of ['validate', 'edited', 'fieldSaveWait', 'displayName', 'value', 'icon', 'color']) {
                    fieldData1[k] = fieldData[k];
                }

                this.markUpdated(this.manageModels[sectionName + 'Data'], sectionName, fieldData1, model);
            }
        }
    }

    async openCalendar(myEvent, tableInfoData, sectionName, prefix, exTableData, model, fieldData,
                       fieldType, mini = true, modeleventsType = false, force = false,
                       baseFilter = {}, avoidSave = false, navCtrl = null) {
        //'miniCalendar': mini,

        let data = {
            'modeleventsType': modeleventsType,
            'dateModel': model,
            'dateModelInfoData': tableInfoData,
            'dateModelSectionName': sectionName,
            'dateFieldData': fieldData,
            'dateFieldType': fieldType,
            'prefix': prefix,
            'avoidSave': avoidSave,
            'baseFilter': baseFilter,
            'exTableData': exTableData
        }
        let navigationExtras: NavigationExtras = {
            queryParams: {'data': data}
        };
        // navCtrl.navigateForward('calendarpage', navigationExtras);

        this.popupEditBoxByData(null, data, true, CalendarWrapperComponent);
    }

    async openCalendarPopup(myEvent, dateModel, fieldType, mini = true, modeleventsType = false, force = false) {
        let fieldData = null;
        if (dateModel && fieldType && fieldType['name']) {
            fieldData = dateModel[fieldType['name']];
        }

        this.popupEditBoxByData(null, {
            'miniCalendar': mini,
            // 'avoidSave': this.avoidSave,
            'modeleventsType': modeleventsType,
            'dateModel': dateModel,
            // 'dateModelSectionName': this.sectionName,
            'dateFieldData': fieldData,
            'dateFieldType': fieldType,
            // 'prefix': this.prefix,
            'exTableData': this.manageModels
        }, true, CalendarWrapperComponent);
    }

    //
    //
    //

    setCheckModel(sectionName) {
        if (!this.checkedData[sectionName + 'ModelCheck']) {
            this.checkedData[sectionName + 'ModelCheck'] = {'markedRow': []};
            this.checkedData[sectionName + 'ModelCheckById'] = {};
        }
    }

    //open generated template pdf
    openModelPrintView(sectionName, model) {
        window.open(model.main.modelUrl)
        //    this.appConfig.http + this.appConfig.dataServerIp + '/templateg/?one=' + model.main.prefix
        //             + '&templateId=main&sectionName=' + sectionName + '&p=' + this.businessService.business.data.prefix
    }

    trustSrc(url) {
        let iframeSrc: SafeResourceUrl;
        iframeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(url);
        return iframeSrc;
    }


    async mapSelect(myEvent, prefix, exTableData, model = null, addressId = null,
                    latLng = null, fieldType = null, sectionName = null, addressFieldKey = null) {
        const popover = await this.popoverCtrl.create({
            component: PickAddressComponent,
            componentProps: {
                myprefix: prefix,
                exTableData: exTableData,
                addressId: addressId,
                sectionName: sectionName,
                fieldType: fieldType,
                latLng: latLng,
                extraData: {},
                model: model,
                addressFieldKey: addressFieldKey
            },
            event: myEvent,
            animated: true,
            showBackdrop: true,
            cssClass: 'note-popover smaller-popover topZindex'
        });
        return await popover.present();
    }

    searchModel(sectionName, searchTxt) {
        let filters = this.mainFilters;
        filters['filters'] = {}
        filters['filters'] = JSON.stringify({'searchText': searchTxt});
        filters['sectionName'] = sectionName;
        return new Promise((resolve, reject) => {
            return this.modelProvider.list('report', filters).then(function (data: any) {
                let extableData = data['models'][0].data['finalResReport'];
                let tableData = extableData[sectionName];
                resolve(tableData);
            });
        })
    }

    isValidateField(fieldData, fieldType) {
        let inError = false;

        //check text length
        if (fieldType['required'] && !fieldData['value']) {
            fieldData.inError = true;
            inError = true;
        }

        //check text length
        if (fieldType['type'] == 'text' || fieldType['type'] == 'textarea') {
            if (fieldData['value'] && fieldData['value'].length >= fieldType['maxLength']) {
                fieldData.inError = true;
                inError = true;
            }
        }

        //check is number
        if (fieldType['name'] == 'id') {

        } else if (fieldType['type'] == 'number' || fieldType['type'] == 'float') {
            if (fieldData['value'] && !this.isNumeric(fieldData['value'])) {
                fieldData.inError = true;
                inError = true;
            }
        }

        return !inError;
    }

    isNumeric(str: any) {
        // if (typeof str != "string") return false; // we only process strings!
        //
        return (!isNaN(str) && !isNaN(parseFloat(str))) // ...and ensure strings of whitespace fail
        // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    }

    fieldClick(myEvent, fieldData, fieldType, model, sectionName, tableInfoData, prefix, navCtrl, exTableData, fieldName = null) {
        if (!fieldType) {
            let fieldTypeByName = this.getFieldsType(sectionName, exTableData);
            fieldType = fieldTypeByName[fieldName];
        }
        //?? external field table from other model? - external model field - read only just open.
        if ((fieldType.rmodelNameFieldExt && fieldType.rmodelNameFieldExt != '')) {
            this.openCustomModel(navCtrl, prefix, exTableData, fieldType['model'],
                null, {}, model[fieldType['name']]['value'], null, null,
                true, {}, true, null);
            myEvent.stopPropagation();
            return;
        }
        //

        if (fieldType && fieldType['readOnly'] || fieldData['readOnly']) {
            return;
        }
        this.setNeedSave();

        if (fieldType['type'] == 'table') {
            this.popupEditBox(null, sectionName, exTableData, prefix, model,
                'table', true, fieldData, fieldType, null, fieldData['baseFilter']);
            return;
        }

        // if (fieldType['type'] == 'textarea') {
        //     this.adminService.popupEditBox(myEvent, this.sectionName, this.exTableData,
        //         this.prefix, model, 'textArea', false, fieldData, fieldType);
        //     myEvent.stopPropagation();
        //     return;
        // }
        if (fieldType['type'] == 'date' || fieldType['type'] == 'datetime') {
            // this.openCalendar(null, model, fieldData, fieldType, true, true, true);
            this.openCalendar(myEvent, tableInfoData, sectionName, prefix, exTableData,
                model, fieldData, fieldType, fieldType['rangeField'] ? true : false,
                true, true, {}, false, navCtrl);
            myEvent.stopPropagation();
            return;
        }

        if (fieldType['name'] == 'icon') {
            this.popupEditBox(myEvent, sectionName, exTableData,
                prefix, model, 'selectIcon', false, fieldData);
            myEvent.stopPropagation();
            return;
        }

        //fieldChooser
        if (fieldType['type'] == 'fieldChooser') {
            this.popupEditBox(myEvent, fieldData['modelDataName'], exTableData,
                prefix, model, 'fieldChooser', false, fieldData);
            // markFieldEdit(model, fieldData);
            this.markUpdated(tableInfoData, sectionName, fieldData, model);
            myEvent.stopPropagation();
            return;
        } else if (fieldType['name'] == 'color' && fieldType['type'] != 'colorPicker') {
            this.popupEditBox(myEvent, sectionName, exTableData,
                prefix, model, 'selectColor', false, fieldData);
            myEvent.stopPropagation();
            return;
        } else if (fieldType['type'] == 'colorPicker') {
            this.popupEditBox(myEvent, sectionName, exTableData,
                'mainPaaagePrefixevent', model, 'colorPicker',
                false, fieldData, fieldType);
            return;
        } else if (fieldType['type'] == 'time') {
            this.popupEditBox(myEvent, sectionName, exTableData,
                'mainPagePrefixevent', model, 'timePicker',
                false, fieldData, fieldType);
            return;
        } else if (fieldType['type'] == 'boolean') {
            this.markUpdated(tableInfoData, sectionName, fieldData, model);
            fieldData['value'] = !!!fieldData['value'];
            fieldData['displayName'] = fieldData['value'];
            // markFieldEdit(model, fieldData);
            fieldData['icon'] = fieldData['value'] ? 'checkmark' : 'close';
            fieldData['color'] = fieldData['value'] ? 'success' : 'danger';
            myEvent.stopPropagation();
            this.saveToMarkedRows(sectionName, fieldType, fieldData);
            return;
        }

        this.saveToMarkedRows(sectionName, fieldType, fieldData);

        // tableInfoData['updated'] = true;
        // fieldData['edited'] = true;
        // model['main']['updated'] = true;
        // this.markFieldEdit(model, fieldData);
        if (fieldType.type == 'selectOptions' ||
            (fieldType.type == 'selectOptionFull') || fieldType.selectPopup) {
            //myEvent, model, type = 'modelTableEdit', bigPopup = true, fieldData = null, fieldType = null
            // popupEditBox(myEvent, model, 'selectOptions', true, fieldData, fieldType);
            this.selectEditBox(myEvent, model, prefix, sectionName, exTableData, 'selectOptions',
                false, fieldData, fieldType);
            return;
            //    , null, baseFilter, extraSaveFilters
        } else if (fieldType.type == 'selectTable') {
            this.selectEditBox(myEvent, model, prefix, sectionName, exTableData, 'selectTable',
                false, fieldData, fieldType, null, null, {'autoQuickAdd': fieldType['autoQuickAdd']}, 450);

            return;
            // popupEditBox(myEvent, sectionName, exTableData, prefix,
            //     model, 'selectTable', false, fieldData, fieldType, null, baseFilter, extraSaveFilters);
        }
            // else if (fieldType.extype == 'selectTable') {
            //     console.log("fieldType.sectionName:")
            //     console.log(fieldType.sectionName)
            //     fieldType['model'] = fieldType.model;
            //     adminService.selectEditBox(myEvent, model, prefix, fieldType.sectionName, exTableData, 'selectTable',
            //         false, fieldData, fieldType);
            //     return;
            //     // popupEditBox(myEvent, sectionName, exTableData, prefix,
            //     //     model, 'selectTable', false, fieldData, fieldType, null, baseFilter, extraSaveFilters);
        // }
        else {
            //if field is table.
            //buttonShow - table count box - skip
            // if (fieldType && fieldType['type'] == 'table' && !fieldType['buttonShow']) {
            //     return;
            // }
            // let me = this;
            // me.currentChosenModel = null;
            // me.chooseFieldType = null;
            // me.chooseFieldData = null;
            // setTimeout(function () {
            //     me.currentChosenModel = model;
            //     me.chooseFieldType = fieldType;
            //     me.chooseFieldData = fieldData;
            // });
            // myEvent.stopPropagation();
            // fieldData['editMode'] = true;

            let bigPopup = false;
            if (fieldType['type'] == 'equation') {
                bigPopup = true;
            }

            this.selectEditBox(myEvent, model, prefix, sectionName, exTableData, 'editField',
                bigPopup, fieldData, fieldType, null, 100);
        }
    }

    openLeftModelBox(sectionName, model, modelId, prefix, navCtrl = null, minipopup = false) {
        if (this.businessService.screenSize[0] < 1000) {
            this.goToAdmin(navCtrl, sectionName, model,
                modelId, null, null);
        } else {
            if (model) {
                this.addToHistory({'sectionName': sectionName, 'model': model});
            }
            this.events.publish('showMenu',
                {
                    'minipopup': minipopup, //tmp
                    'menu': 'menuAdminBox',
                    'id': modelId,
                    'rowModel': model,
                    'prefix': prefix,
                    'boxMenu': true,
                    'sectionName': sectionName
                });
        }
    }

    checkModel(sectionName, markTypeKey, model) {
        model['main']['isMarked'] = !!!model['main']['isMarked'];
        let id = model['id']['value'];

        if (!this.checkedData[sectionName + 'ModelCheck']) {
            this.checkedData[sectionName + 'ModelCheck'] = {};
            this.checkedData[sectionName + 'ModelCheckById'] = {};
        }

        let modelCheckData = this.checkedData[sectionName + 'ModelCheck'];
        let modelCheckDataById = this.checkedData[sectionName + 'ModelCheckById'];

        if (!modelCheckData[markTypeKey]) modelCheckData[markTypeKey] = [];

        if (modelCheckData[markTypeKey].indexOf(id) !== -1) {
            modelCheckData[markTypeKey].splice(modelCheckData[markTypeKey].indexOf(id), 1);
        } else {
            modelCheckData[markTypeKey].push(model['id'].value);
            modelCheckDataById[id] = model;
        }
    }

    markCol(sectionName, markTypeKey, id) {
        if (!this.markDataById[sectionName]) {
            this.markData[sectionName] = {'removedRow': [], 'markedRow': []};
            this.markDataById[sectionName] = {};
        }

        if (!this.markData[sectionName][markTypeKey]) {
            this.markData[sectionName][markTypeKey] = [];
        }
        if (this.markData[sectionName][markTypeKey].indexOf(id) !== -1) {
            this.markData[sectionName][markTypeKey].splice(this.markData[sectionName][markTypeKey].indexOf(id), 1);
        } else {
            this.markData[sectionName][markTypeKey].push(id);
            this.markDataById[sectionName][id] = id;
        }
        console.log(this.markData);
        this.manageModels[sectionName + 'MarkData'] = this.markData[sectionName];
    }

    markField(sectionName, model, fieldName, fieldData) {
        if (!this.markFields[sectionName]) this.markFields[sectionName] = {};
        let modelId = model.id.value;

        if (this.markFields[sectionName][modelId + '_' + fieldName]) {
            this.markFields[sectionName][modelId + '_' + fieldName] = null;
            delete this.markFields[sectionName][modelId + '_' + fieldName];
        } else {
            this.markFields[sectionName][modelId + '_' + fieldName] = {'fieldData': fieldData, 'model': model};
        }
        console.log(this.markFields[sectionName])
    }

    clearMarkedFields(sectionName) {
        let fieldData;
        for (let key in this.markFields[sectionName]) {
            fieldData = this.markFields[sectionName][key]['fieldData'];
            fieldData['fieldOver'] = false;
            this.markFields[sectionName][key] = null;
            delete this.markFields[sectionName][key];
        }
    }
}









