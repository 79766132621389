import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ModelProvider} from "../../../../framework/providers/modelProvider/ModelProvider";

import {IonReorderGroup, ModalController, NavController, PopoverController} from "@ionic/angular";
import {ItemReorderEventDetail} from '@ionic/core';
import {Events} from "../../../../framework/providers/events";
import {NavigationExtras} from "@angular/router";
import {AppConfig} from "../../../../core/conf/main";
import {AdminService} from "../../../../core/services/AdminService";
import {MakeReportComponent} from "../make-report/make-report.component";

declare var ICONS;

@Component({
    selector: 'app-select-bar',
    templateUrl: './select-bar.component.html',
    styleUrls: ['./select-bar.component.scss'],
})
export class SelectBarComponent implements OnInit, OnDestroy {
    public colorpick: string = '#2889e9';
    icons = ICONS;

    // @ViewChildren('tableTop') tableTop: QueryList<ElementRef>;

    // @ViewChild('table', {static: false}) table: ElementRef;
    // @ViewChildren('table', {read: ElementRef}) table: QueryList<ElementRef>;
    // onTableOverInterval;

    // get Table(): ElementRef {
    //     return this.table;
    // }
    // set Table(elem: ElementRef) {
    //     this.table = elem;
    //     //console.log'haaaaa');
    //     //console.logthis.table);
    //     if (this.table)
    //         this.table.nativeElement.className = 'red'
    // }

    //for select
    selectmyprefix = "selectTablePrefix3" + Math.floor(Math.random() * Math.floor(1000000));
    selectmyprefix2 = "selectTablePrefix3" + Math.floor(Math.random() * Math.floor(1000000));

    @Input()
    extraData = {};

    @Input()
    isModalCntrl = false;

    @Input()
    filters = {};
    resValue;
    ready;

    appConfig = AppConfig;

    //global search
    @Input()
    searchResults;

    @Input()
    avoidSave;

    @Input()
    sectionName;
    @Input()
    getModelById;
    @Input()
    specificFields;

    @Input()
    fieldData;
    @Input()
    fieldType;
    @Input()
    tableInfo;
    @Input()
    exTableData = {};
    relationTableData;

    @Input()
    tableOptions;

    tableData;
    @Input()
    tableInfoData;
    @Input()
    horizontal = false;

    @Input()
    prefix;
    @Input()
    tabsData;
    @Input()
    model;
    @Input()
    genericModelTable;

    //if waiting for callback event
    @Input()
    callbackPrefix;

    @Input()
    baseFilter;
    @Input()
    onTable;

    //forceDisplay - show this buttons automatically
    @Input()
    forceDisplay = null;

    //aa

    @Input()
    type = 'operation'; //operation / filters / search / extraOperations /

    @ViewChild(IonReorderGroup, {'static': true}) reorderGroup: IonReorderGroup;

    saveTimeout;
    searchModel;

    //checkedRows of table
    checkedRows = [];

    modelFields;//modelFields
    fieldTypeIcons = {
        'text': 'baseball',
        'number': '',
        'float': '',
        'selectTable': '',
        'table': '',
        'textarea': '',
        'boolean': ''
    };

    //field chooser
    chooseColumnSectionName = null;
    chooseColumnData = '';

    markedRow;
    workflowTable = 'modelLevel';

    @Input()
    extraTableFilters = {};


    constructor(public modelProvider: ModelProvider,
                public events: Events,
                public admin: AdminService,
                public adminService: AdminService,
                public navCtrl: NavController,
                private modalController: ModalController,
                public popoverCtrl: PopoverController) {

    }


    ngOnInit() {
        if (this.fieldData && this.type == 'colorPicker') {
            this.colorpick = '#' + this.fieldData['value'];
        }
        // console.log('new select bar');
        // console.log(this.specificFields);
        // //console.logthis.fieldType);
        // //console.logthis.fieldData);
        // //console.logthis.fieldData);
        // //console.logthis.fieldType);

        if (this.exTableData) {
            this.tableInfoData = this.exTableData[this.sectionName + 'Data'];
            this.tableData = this.exTableData[this.sectionName];
            this.onSelectTable();
        }

        // //console.logthis.exTableData);
        // //console.logthis.exTableData[this.sectionName + 'Ref']);
        // }


        let me = this;
        // setTimeout(function () {
        me.ready = true;
        // }, 1000);
        if (this.getModelById) {
            // //console.log'get model by id!!!!!!');
            this.loadModelById(this.getModelById);
        }

        this.events.subscribe('checkRowChange' + this.prefix, (data) => {
            // //console.log'set checked rows!');
            // //console.logthis.checkedRows)
            this.checkedRows = data['checkedRows'];
        });

        this.events.subscribe('newTableFilters' + this.prefix, (filters) => {
            //console.log'new filters update!!');
            //console.logfilters);
            me.filters = filters;
        });

        // this.events.subscribe('onModelsUpdate' + this.prefix, (tableData) => {
        //     me.exTableData = tableData;
        //     //console.log'select bar update extable:');
        //     //console.logme.exTableData);
        // });

        if (this.type == 'columns') {
            this.getColumns();
        }

        if (this.type == 'fieldChooser') {
            this.chooseColumnSectionName = this.sectionName;
            this.chooseColumnData = this.sectionName;
        }

        if (this.type == 'selectTable') {
            if (!this.extraTableFilters) this.extraTableFilters = {};
            this.extraTableFilters = Object.assign(this.extraTableFilters, {
                'exists': false,
                'topModelId': this.fieldData['modelId'],
                'fieldName': this.fieldData['fieldName'] ? this.fieldData['fieldName'] : this.fieldType['name'],
                'topModelSection': this.fieldData['topModelSection']
            });
        }

        if (this.type == 'selectOptions') {
            if (this.model.main.options && this.model.main.options[this.fieldType['name']]) {
                this.tableOptions = this.model.main['options'][this.fieldType['name']];
            } else {
                this.tableOptions = this.tableInfoData['optionsData'][this.fieldType['name']];
            }
        }
    }

    setWorkflowTable(workflowTable) {
        let me = this;
        this.workflowTable = null;
        setTimeout(function () {
            me.workflowTable = workflowTable;
        })
    }

    //drag && drop
    doReorder(ev: CustomEvent<ItemReorderEventDetail>) {
        // The `from` and `to` properties contain the index of the item
        // when the drag started and ended, respectively
        console.log('Dragged from index', ev.detail.from, 'to', ev.detail.to);

        let fromField = this.modelFields[ev.detail.from];
        let toField = this.modelFields[ev.detail.to];

        //minus or plus ?
        let add = 0.1;
        if (toField['order']['value'] < fromField['order']['value']) add = -0.1

        fromField['order']['value'] = parseInt(toField['order']['value']) + add;

        this.modelFields.sort(function (a, b) {
            if (!b['order'] || !b['order']) return -1;
            return b['order']['value'] - a['order']['value'];
        });

        let i = 200;
        let field;
        for (let k in this.modelFields) {
            field = this.modelFields[k];
            field['order']['value'] = i;
            field['order']['type'] = 'number';
            i--;

            this.adminService.markUpdated(this.tableInfoData, this.sectionName, field['order'], field);
        }

        // toField['order']['value']--;
        // let fieldTypes = this.admin.getFieldsType('modelField', this.exTableData);
        // this.admin.saveField('modelField', fromField, fromField['order'], fieldTypes['order']);
        this.adminService.saveModels(this.tableData, 'modelField', {}, this.modelFields, this.exTableData,
            false, [], null);

        // Finish the reorder and position the item in the DOM based on
        // where the gesture ended. This method can also be called directly
        // by the reorder group
        ev.detail.complete();

        let me = this;
        setTimeout(function () {
            me.events.publish("refresh_request_" + me.prefix, {'loadColumns': true});
        }, 1000);
    }

    onOff() {
        let me = this;
        me.ready = false;
        setTimeout(function () {
            me.ready = true;
        }, 10);
    }

    toggleReorderGroup() {
        this.reorderGroup.disabled = !this.reorderGroup.disabled;
    }

    //

    ngOnDestroy() {

        this.events.unsubscribe('onModelsUpdate' + this.prefix);

        this.events.unsubscribe('newTableFilters' + this.prefix);
        // this.events.unsubscribe('onModelsUpdate' + this.prefix);

        if (this.type == 'selectTable') {
            //console.log'remove select listener!!!', this.selectmyprefix);
            //'quickFilterData': d,
            //                 'rowModel': rowModel,
            //                 'name': this.selectTablePrefix['name']
            this.events.unsubscribe(this.selectmyprefix);
        }

        this.events.unsubscribe('checkRowChange' + this.prefix);
    }

    getColumns() {
        let me = this;
        me.modelFields = me.exTableData[me.sectionName + 'ModelFields'];
        if (me.modelFields) {
            me.modelFields.sort(function (a, b) {
                if (!b['order'] || !b['order']) return -1;
                return b['order']['value'] - a['order']['value'];
            });

        }
    }

    openBoxPage() {
        this.closeWindow();
        let id = this.model ? this.model['id']['value'] : null;

        if (this.model) {
            this.admin.setRowModel(this.model);
        }

        this.openAdminModel(this.extraData && this.extraData['extSectionName'] ?
            this.extraData['extSectionName'] : this.sectionName, id);

        //
        // this.closeWindow();
        // let params = {
        //     'sectionName': this.sectionName,
        //     'rowId': id,
        // };
        // let navigationExtras: NavigationExtras = {
        //     queryParams: params
        // };
        // this.navCtrl.navigateForward('admin-model/' + this.sectionName + id, navigationExtras);
    }

    //open specific model page
    openAdminModel(sectionName, id) {
        ////////console.logsectionName, id);
        //     'baseFilter': {
        //         'searchFieldValue': id,
        //         'searchField': 'id',
        //         'search_in_field': true
        //     },

        let params = {
            'sectionName': sectionName,
            'rowId': id,
            // 'rowIndex': 1 //skip first row -1
        };

        let navigationExtras: NavigationExtras = {
            queryParams: params
        };
        this.navCtrl.navigateForward('admin-model/' + sectionName + id, navigationExtras);
    }


    //choose option on table of options
    onSelectTable() {
        let me = this;
        //select table listen to choose and send save event to table.
        if (this.type == 'selectTable') {
            if (me.fieldData['multiple'] || me.fieldType['multiple']) {
                if (!me.fieldData['multiChoose']) {
                    me.fieldData['multiChoose'] = {};
                }
            }

            let markData = me.adminService.manageModels[me.sectionName + 'MarkData'];
            if (!markData) markData = {'markedRow': []};
            this.markedRow = JSON.parse(JSON.stringify(markData['markedRow']));

            this.events.subscribe(this.selectmyprefix, function (data) {
                if (!(me.fieldData['multiple'] || me.fieldType['multiple'])) {
                    // //console.log'save on select bar');
                    me.fieldData['value'] = data['rowModel']['id']['value'];
                    me.fieldData['displayName'] = data['rowModel']['main']['displayName'];
                    me.fieldData['color'] = data['rowModel']['main']['color'];
                    me.fieldData['icon'] = data['rowModel']['main']['icon'];
                }
                me.fieldData['validate'] = true;
                me.fieldData['edited'] = true;
                me.fieldData['fieldSaveWait'] = true;
                me.model['main']['updated'] = true;

                //copy to other marked rows.
                // me.copyValueToMarked();
                me.adminService.saveToMarkedRows(me.sectionName, me.fieldType['name'], me.fieldData);

                //if choose multiple - if exists remove it, else add it.
                if (me.fieldData['multiple'] || me.fieldType['multiple']) {
                    if (me.fieldData['multiChoose'][data['rowModel']['id']['value']]) {
                        me.fieldData['multiChoose'][data['rowModel']['id']['value']] = null;
                        delete (me.fieldData['multiChoose'][data['rowModel']['id']['value']])
                    } else {
                        let m = {};
                        m['value'] = data['rowModel']['id']['value'];
                        m['displayName'] = data['rowModel']['main']['displayName'];
                        m['color'] = data['rowModel']['main']['color'];
                        m['icon'] = data['rowModel']['main']['icon'];

                        me.fieldData['multiChoose'][data['rowModel']['id']['value']] = m;
                    }

                    let multichoose = me.fieldData['multiChoose'];
                    let multichooseArr = [];
                    let ids = []
                    for (let k in multichoose) {
                        //check not null.
                        if (multichoose[k]) multichooseArr.push(multichoose[k])
                        ids.push(multichoose[k]['value'])
                    }

                    me.fieldData['multiChooseArr'] = multichooseArr;
                    me.fieldData['multiChooseValue'] = JSON.stringify(ids);
                    console.log(me.fieldData['multiChooseValue']);
                } else {
                    me.closeWindow();
                }

                //for select filters - no need to save.
                // if (!me.fieldData['avoidSave'] && !me.avoidSave) {
                //     me.saveField(me.model, me.fieldData);
                // }
            });
        }

    }

    copyValueToMarked() {
        let me = this;
        let markedRow = this.markedRow;
        if (markedRow) {
            for (let id of markedRow) {
                let model = me.adminService.manageModels[me.sectionName + 'MarkDataById'][id];
                if (!model['displayName']) model['displayName'] = {};

                if (me.fieldData['displayName'])
                    model['displayName']['value'] = me.fieldData['displayName'];
                else
                    model['displayName']['value'] = me.fieldData['value']

                for (let k of ['validate', 'edited', 'fieldSaveWait', 'displayName', 'value', 'icon', 'color']) {
                    model[me.fieldType['name']][k] = me.fieldData[k];
                }
                model[me.fieldType['name']]['edited'] = true;
                model['main']['updated'] = true;
            }
        }
    }


    refresh(mfilters = {}) {

        let filters = {};
        for (let key in this.filters) {
            filters[key] = this.filters[key];
        }
        for (let key in mfilters) {
            filters[key] = mfilters[key];
            if (this.baseFilter) {
                for (let key in this.baseFilter) {
                    filters[key] = this.baseFilter[key];
                }
            }
        }
        filters['sectionName'] = this.sectionName;

        // this.setShowLoading();

        let me = this;
        this.modelProvider.list('report', filters)
            .then(function (data: any) {
                // me.adminService.setResult(me.sectionName, data);
                me.onResultList(data);
            });
    }

    loadModelById(modelId) {
        let filters = {'ordering': '-id', 'type': 'moving', 'name': 'taarif', 'modelOffset': 0, 'modelLimit': 20};
        filters['sectionName'] = this.sectionName;
        filters['getId'] = modelId;

        let me = this;
        this.ready = false;
        this.modelProvider.list('report', filters)
            .then(function (data: any) {

                let extableData = data['models'][0].data['finalResReport'];
                let tableData = extableData[me.sectionName];
                //console.log"get model by id:");
                //console.logtableData);
                if (tableData) {
                    me.model = tableData[0];

                    for (let key in extableData) {
                        me.exTableData[key] = extableData[key];
                    }
                    me.ready = true;
                }
            });
    }

    onResultList(data) {
        let me = this;
        let extableData = data['models'][0].data['finalResReport'];
        me.exTableData = extableData;
        let tableData = extableData[me.sectionName];

        me.tableData = tableData;
    }

    buttonClick(key, data = {}) {
        this.events.publish("btnclick_" + this.prefix, {'key': key, 'data': data});
    }

    addFilter(filters) {
        this.events.publish("btnclick_" + this.prefix, {
            'key': 'addFilter',
            'data': {'fieldFilters': filters}
        });
    }

    chooseReport(key) {
        let filters = {};
        if (key == -1) {
            filters = {
                'modelFilterId': -1,
                'filterOptionsData': JSON.stringify({}),
                'sectionName': this.sectionName
            };

        } else if (key == 'go') {
            filters = {
                'modelFilterId': -1,
                'filterOptionsData': JSON.stringify(this.exTableData[this.sectionName + 'Data']['filterOptionsData']),
                'sectionName': this.sectionName
            };
        } else {
            filters = {
                'modelFilterId': key,
                'sectionName': this.sectionName
            };
        }

        this.buttonClick('customFilter', filters);
        // this.events.publish('refresh_request_' + this.prefix, filters);
    }

    // async showGenericModal(fieldKey) {
    //     // this.events.publish('showGenericModel', {
    //     //     'modelName': this.sectionName,
    //     //     'fieldKey': fieldKey,
    //     //     'model': this.model
    //     // });
    //
    //     const popover = await this.popoverCtrl.create({
    //         component: SelectBarComponent,
    //         componentProps: {
    //             'model': this.model,
    //             'prefix': this.prefix,
    //             'fieldData': {'name': fieldKey, 'objectModel': this.sectionName},
    //             'exTableData': this.exTableData,
    //             'sectionName': fieldKey,
    //             'genericModelTable': true,
    //             'type': 'modelTableEdit'
    //         },
    //         event: null,
    //         animated: true,
    //         showBackdrop: true,
    //         cssClass: 'note-popover icenter-popover right-popup'
    //     });
    //     return await popover.present();
    // }

    async showFilterModal(event) {

        this.presentSelectBar(null, 'simpleTable', null, 'appFilter', {
            'searchField': 'modelData__modelName',
            'searchFieldValue': this.sectionName
        }, 'smaller-popover');

        // this.events.publish('showGenericModel', {
        //     'modelName': this.sectionName,
        //     'fieldKey': fieldKey,
        //     'model': this.model
        // });
        //
        //         'type': 'modelTableEdit'

        // const popover = await this.popoverCtrl.create({
        //     component: AdminFilterComponent,
        //     componentProps: {
        //         'prefix': this.prefix,
        //         'exTableData': this.exTableData,
        //         'tableInfoData': this.tableInfoData,
        //         'sectionName': this.sectionName
        //     },
        //     event: null,
        //     animated: true,
        //     showBackdrop: true,
        //     cssClass: 'note-popover icenter-popover bigger-popover right-popup'
        // });
        // return await popover.present();
    }

    async presentSelectBar(myEvent, type, model = null, sectionName = null, baseFilter = null, popupType = 'smaller-popover') {
        let bigPopup = popupType != 'smaller-popover'
        this.adminService.popupEditBox(myEvent, sectionName, this.exTableData, this.prefix,
            model, type, bigPopup, null, null, null, baseFilter, this.filters);
        this.closeWindow();
        // const popover = await this.popoverCtrl.create({
        //     component: SelectBarComponent,
        //     componentProps: {
        //         'prefix': this.prefix,
        //         'model': model,
        //         'baseFilter': baseFilter,
        //         'filters': this.filters,
        //         'exTableData': this.exTableData,
        //         'sectionName': sectionName ? sectionName : this.sectionName,
        //         'type': type
        //     },
        //     event: myEvent,
        //     animated: true,
        //     showBackdrop: true,
        //     cssClass: 'note-popover icenter-popover ' + popupType
        // });
        // return await popover.present();
    }

    async closeWindow() {
        if (this.isModalCntrl) {
            await this.modalController.dismiss();
        } else {
            await this.popoverCtrl.dismiss();
        }
    }

    onTableScroll(event, prefix) {
        console.log(event.target.scrollHeight);
        console.log(event.target.scrollTop);
        if (event.target.scrollHeight - 300 < event.target.scrollTop) {
            this.events.publish('nextPage' + prefix, {});
        }
    }

    saveColorField(model, fieldData) {
        fieldData['value'] = fieldData['value'].replace('#', '');
        this.markEditField();
        // this.waitSaveField(model, fieldData);
    }

    markEditField() {
        this.tableInfoData['updated'] = true;
        this.fieldData['edited'] = true;
        this.fieldData['validate'] = true;
        // this.fieldData['editMode'] = true;
        this.fieldData['fieldSaveWait'] = true;
        this.model['main']['updated'] = true;
    }

    waitSaveField(model, fieldData) {
        console.log('waitSaveField change!!')
        // let me = this;
        // clearTimeout(this.saveTimeout);
        // this.saveTimeout = setTimeout(function () {
        this.saveField(model, fieldData);
        // }, 2000);
    }


    saveModel() {
        this.model['main']['updated'] = true;
        this.adminService.checkSave([this.model], this.sectionName, this.filters, this.exTableData,
            false, {}, {}, null, null);
    }

    saveField(model, fieldData) {
        this.saveModel();
        // let filters = {'ordering': '-id', 'type': 'moving', 'name': 'taarif', 'modelOffset': 0, 'modelLimit': 20};
        // if (this.avoidSave) {
        //     return;
        // }
        // console.log('haaaaa 2')
        //
        // fieldData['edited'] = true;
        // fieldData['fieldSaveWait'] = true;
        // fieldData['validate'] = true;
        // this.fieldData['edited'] = true;
        // this.model['main']['updated'] = true;
        // return;
        //
        // if (this.onTable) {
        //     this.model['main']['updated'] = true;
        //     console.log('model change!!!');
        //
        //     let d = {'fieldData': fieldData, 'model': model, 'fieldType': this.fieldType};
        //     fieldData['validate'] = true;
        //     this.events.publish('save' + this.prefix, d);
        // } else {
        //     let fieldType = this.fieldType;
        //     let me = this;
        //
        //     let newModel = {
        //         'main': {'value': model['id']['value'], 'updated': true, 'name': '', 'type': 'none'},
        //         'id': model['id']
        //     };
        //     newModel[fieldType['name']] = {
        //         'value': fieldData['value'],
        //         'name': fieldType['name'],
        //         'edited': true,
        //         'type': fieldType['type'],
        //         'typeData': fieldType
        //     };
        //     //console.lognewModel);
        //
        //
        //     let tableData = {};
        //     tableData[this.sectionName] = [newModel];
        //     filters['editedSections'] = [this.sectionName];
        //     filters['sectionName'] = this.sectionName;
        //
        //     this.modelProvider.customPost('saveDataReport', [tableData], filters, true).then((data: any) => {
        //         me.exTableData[me.sectionName + 'Data']['edited'] = false;
        //         // model['main']['updated'] = false;
        //         model['main'] = data['data']['models'][0]['main'];
        //         fieldData['edited'] = false;
        //
        //         setTimeout(function () {
        //             fieldData['fieldSaveWait'] = false;
        //         }, 2000);
        //     });
        // }
    }

    searchWait() {
        let me = this;
        clearTimeout(this.saveTimeout);
        this.saveTimeout = setTimeout(function () {
            me.search();
        }, 1000);
    }

    search() {
        let filters = {};
        filters['searchText'] = this.searchModel;
        this.buttonClick('search', filters);
    }

    //select options field
    changeOptionsField(fieldData, option, options) {
        console.log(option)
        console.log(fieldData)
        let id = option['id'].toString();

        if (!fieldData['optionsActive']) {
            fieldData['optionsActive'] = []
        }
        if (!this.fieldType['multiple']) {
            fieldData['optionsActive'].splice(0);
        }

        if (id == '-1') {
            fieldData['optionsActive'] = [];
        } else if (fieldData['optionsActive'].indexOf(id) !== -1) {
            fieldData['optionsActive'].splice(fieldData['optionsActive'].indexOf(id), 1);
        } else {
            fieldData['optionsActive'].push(id);
        }

        fieldData['value'] = fieldData['optionsActive'].join(',');
        fieldData['displayName'] = fieldData['optionsActive'].join(',');
        fieldData['optionsActiveKeys'] = fieldData['optionsActive'].join(',');

        this.markEditField();
        this.adminService.saveToMarkedRows(this.sectionName, this.fieldType, fieldData)
        // fieldData['color'] = fieldData['optionsActive'][0];
        // fieldData['icon'] = fieldData['optionsActive'].join(',');
        // this.saveModel();

        // && this.fieldType.selectPopup - for tag field.
        if (!this.fieldType['multiple']) {
            // this.closeWindow();
            this.popoverCtrl.dismiss();
        }
    }

    async openGenericFormPage() {
        this.presentSelectBar(null, 'genericForm', null, this.sectionName);
    }

    async openReportPage() {
        //console.logthis.filters);

        this.adminService.popupEditBoxByData(null, {
            'prefix': this.prefix,
            'exTableData': this.exTableData,
            'tableInfoData': this.tableInfoData,
            'filters': this.filters,
            'sectionName': this.sectionName
        }, true, MakeReportComponent);

        // const popover = await this.popoverCtrl.create({
        //     component: MakeReportComponent,
        //     componentProps: {
        //         'prefix': this.prefix,
        //         'exTableData': this.exTableData,
        //         'tableInfoData': this.tableInfoData,
        //         'filters': this.filters,
        //         'sectionName': this.sectionName
        //     },
        //     event: null,
        //     animated: true,
        //     showBackdrop: true,
        //     cssClass: 'note-popover icenter-popover bigger-popover right-popup'
        // });
        // return await popover.present();
    }

    // onTableScrollStop(px) {
    //     clearInterval(this.onTableOverInterval);
    // }
    //
    // onTableScroll(px) {
    //     let container = this.table.first.nativeElement;
    //     this.onTableOverInterval = setInterval(function () {
    //         container.scrollLeft = container.scrollLeft + px;
    //     }, 100);
    //
    // }

    openExtenralTemplate(template) {
        window.open(this.exTableData['externalTemplateLink'] + '?modelId=' +
            this.model['id']['value'] + '&templateId=' + template['id'] + '&pr=asdkdknmqwldknqwlkdnmwq', '_blank');
    }


    //appalerts
    markDoneAlert(appAlert) {
        appAlert['active']['value'] = false;
        appAlert['active']['name'] = 'active';
        this.adminService.saveField('appAlert', appAlert, appAlert['active'], this.adminService.getFieldsType('appAlert', this.exTableData)['active']);
    }

    customTableAction(tableInfoData) {
        if (tableInfoData['type'] == 'callServer') {
            this.modelProvider.custom(tableInfoData['requestPrefix']).then((data: any) => {
                //console.log'its back!');
            });
        }
    }

    callbackEvent(data) {
        this.events.publish(this.callbackPrefix, data);
    }

    chooseColor(color) {
        this.fieldData['value'] = color;
        this.fieldData['color'] = color;
        this.model['main']['color'] = color;
        this.markEditField();
        this.closeWindow();
    }

    onChooseColumnField(field) {
        if (field['model']) {
            this.chooseColumnData += '|' + field['name'];
            this.chooseColumnSectionName = field['model'];
        } else {
            this.chooseColumnData += '|' + field['name'];
        }

        this.fieldData['value'] = this.chooseColumnData;
        this.fieldData['diaplayName'] = this.chooseColumnData;

        if (!field['model']) {
            this.closeWindow();
        }
    }

    //custom copy zone
    copyZone(newModel) {
        this.adminService.saveModel(newModel, 'rate',
            this.exTableData, "aiisstmp", null,
            {
                'fromZoneId': newModel['zoneFrom']['value'],
                'toZoneId': newModel['zoneTo']['value'],
                'copyZone': 1
            })
            .subscribe((d) => {
            })
    }
}
