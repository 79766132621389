import {ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import {OrderService} from "../../services/OrderService";
import {BusinessService} from "../../services/BusinessService";
import {IonContent, IonInfiniteScroll, NavController, PopoverController} from "@ionic/angular";
import {Business} from "../../../framework/model/core/business/business";
import {ActivatedRoute, NavigationExtras} from "@angular/router";
import {ModelToolsProvider} from "../../../framework/providers/model-tools/model-tools";
import {AppUser} from "../../../framework/model/core/appuser/appuser";
import {ItemProvider} from "../item/providers/item-provider/item-provider.service";
import {ModelProvider} from "../../../framework/providers/modelProvider/ModelProvider";
import {AppSettings} from "../../conf/appSettings";
import {AppConfig} from "../../conf/main";
import {TranslateService} from "@ngx-translate/core";
import {Events} from "../../../framework/providers/events";
import {CartService} from "../../services/CartService";
import {SectionsSalesComponent} from "../sales/components/sections-sales/sections-sales.component";

@Component({
    selector: 'app-cart',
    templateUrl: './cart.page.html',
    styleUrls: [ '../item/components/item-list/item-list.component.scss', './cart.page.scss'],
})
export class CartPage implements OnInit {
    public appuser: AppUser;
    public cartData: object;
    public cart: object;
    public business: Business;
    public catalog: boolean = this.businessService.isCatalog();
    public cartStatus;

    public itemSettings;
    public settings;
    public appsettings;
    public openStatus = {};

    public cartOptions = {};

    public options = {'limit':10, 'offset':0};

    public showAppUserId;
    public company;
    public order;

    public ready = false;

    itemView;
    itemViewPrefix = 'itemsListView';

    prefix = 'cartItems'

    scrollTop = 0;
    scrollHeight = 0;

    @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;
    @ViewChild(IonContent) content: IonContent;
    isPriceVisible: boolean;

    constructor(public orderService: OrderService,
                public businessService: BusinessService,
                public events: Events,
                public cartService:CartService,
                public translate: TranslateService,
                private cdr: ChangeDetectorRef,
                public modelProvider: ModelProvider,
                public modelTools:ModelToolsProvider,
                public route: ActivatedRoute,
                private changeRef: ChangeDetectorRef,
                public navCtrl: NavController, public modelToolsProvider: ModelToolsProvider, public itemProvider: ItemProvider,
                public appSettings: AppSettings, public popoverCtrl: PopoverController) {

        this.business = this.businessService.getBusiness();
        this.appuser = this.orderService.getAppUser();

        this.itemSettings = this.appSettings.getSettings('items');
        this.settings = this.appSettings.getSettings('cart');
        this.appsettings = this.appSettings.getSettings('app');

        this.scrollHeight = window.innerHeight

    }

    ngOnInit() {
        this.cartData = this.cartService.getCartData();


        this.getCartStatus();
        this.getOrder();
        this.getCartCalc();

        this.getCartOptions()

        console.log(this.cartOptions)

        this.modelTools.getKey(this.itemViewPrefix).then((res:string) => {
            if (!res) {
                res = 'small';

                if(this.cartOptions['agentTechnicalTool'])
                {
                    res = 'big';
                }
            }

            this.itemView = res;

        });

        this.events.subscribe('cartUpdated', (params) => {
            this.getcart();
        });


        this.events.subscribe('cartStatusChanged', (params) => {
            this.getCartStatus();
        });

        //can change company id if requested.
        let me = this;
        this.route.queryParams.subscribe(params => {
            if (params['id'] && params['manager']) {
                me.showAppUserId = params['id'];
                this.appuser.data.companyId = me.showAppUserId;
                // this.appuser.data.companyName = this.company.data.companyName;
                this.modelProvider.update(this.appuser);
            } else {
                me.showAppUserId = this.appuser.data.companyId;
            }
        });
    }


    onScroll(event) {
        // this.events.publish('sectionOnScroll', e)
        // this.cartOptions['scrollTop'] = e.detail.scrollTop
        this.scrollTop = event.detail.scrollTop;
    }

    scrollToBottom()
    {
        this.content.scrollToBottom(1000);
    }


    // ngDoCheck()
    // {
    //     this.getCartCalc();
    // }

    // setCartStatus() {
    //     this.orderService.setCartStatus();
    // }

    ngAfterViewInit()
    {
        let time = 100
        if(this.cartData['amount'] > 10)
        {
            time = 500;
        }

        let me = this;
        setTimeout(function (){
            me.getcart();

            me.ready = true;
        }, time)
    }

    setItemView(value)
    {
        this.itemView = value;
        this.modelTools.setKey(this.itemViewPrefix, value);

    }

    getOrder() {
        this.order = this.orderService.getOrder();
    }

    updateCartCalc()
    {
        this.cartService.updateCart();
    }


    getCartCalc()
    {
        this.orderService.getCartCalc();
    }

    getCartStatus() {
        this.cartStatus = this.orderService.getCartStatus();
    }

    getcart() {
        this.cartData = this.cartService.getCartData();
        this.cart = this.cartService.getCart();
        this.openStatus[Object.keys(this.cart)[0]] = true;
        console.log(this.cart)
        this.renderCurrentPage();
    }

      @ViewChild(IonContent, { static: false }) con: IonContent;

      renderCurrentPage() {
        this.cdr.detectChanges();
      }

    getCartOptions()
    {
        this.cartOptions = this.orderService.getCartOptions();
    }

    /* ???? */
    proceed() {
        if (this.cartStatus['url']) {
            this.goTo(this.cartStatus['url']);
            return;
        }

        if(this.cartData['cart_sales'] && this.cartData['cart_sales'].length) {
            let prefix = 'proceedOrder_'+this.modelToolsProvider.getRandomize();

            this.events.subscribe(prefix, (res)=>{
                this.events.unsubscribe(prefix);
                this.proceedOrder({'checkMakers':true});
            })

            this.modelTools.showModal(SectionsSalesComponent, {
                'sections_sales': this.cartData['cart_sales'],
                "sale_key": "rest_to_sale",
                "prefix": prefix,
                'mode': 'slider',
                'view': 'big',
                'isModal': true
            })
            return;
        }

        this.proceedOrder({'checkMakers':true});
    }

    proceedOrder(params = {'checkMakers':false}) {

        let me = this;
        if(params['this'])
            me = params['this'];

        //
        // let nopricesitems = [];
        // for (var ticket in this.cart) {
        //     for (var k in this.cart[ticket]['products']) {
        //         if (!this.itemProvider.checkDelivery(this.cart[ticket]['products'][k]['product'])) {
        //             this.modelProvider.presentToast('cantDeliverItem', true, null, null, true);
        //             return;
        //         }
        //
        //         // //console.logthis.cart[ticket]['products'][k], k, this.cart[ticket]['products'][k].price, !this.cart[ticket]['products'][k].price)
        //         if (!this.cart[ticket]['products'][k].price || !this.cart[ticket]['products'][k]['stock'])
        //             nopricesitems.push(this.cart[ticket]['products'][k]['product'])
        //     }
        // }
        //
        // if (nopricesitems.length) {
        //     this.showCustomItems(nopricesitems)
        //     return
        // }
        //
        // let order = this.orderService.getOrder();
        //
        // if (this.settings['chooseType']) {
        //     if (!this.orderService.getOrderType()) {
        //         return this.modelProvider.presentToast('chooseOrderType', true, null, null, true)
        //     }
        //     if (this.orderService.getOrderType() == 'delivery' && !order.data['address']['id']) {
        //         this.modelProvider.presentToast('noShipmentAddress', true, null, null, true);
        //         return;
        //     }
        // }
        //
        // if (this.cartData['needBranch'] && this.business.data.GlobalSettingsData.branches && !this.cartData['pickupBranch']) {
        //     this.modelProvider.presentToast('noPickupBranch', true, null, null, true);
        //     return;
        // }

        let makers = {'count':0};

        for (var ticket in me.cart) {
            // ticketRes['counter'] = 1;
            for (var k in me.cart[ticket]['products']) {

                let item = me.cart[ticket]['products'][k]['product'];
                if(item.data.itemMaker)
                {
                    if(!makers[item.data.makerName])
                    {
                        makers[item.data.makerName] = 0;
                        makers['count']++;
                    }

                    makers[item.data.makerName]++;
                }
            }
        }


        let oneflow = AppConfig.mode == 'oneflow';

        if(params['checkMakers'] && makers['count'] > 1 && oneflow)
        {
            me.makersPopup(makers);
            return
        }

        me.navCtrl.navigateForward('cart-details', {});
    }


    makersPopup(makers) {
        let title = this.translate.instant('fewMakersOrder');
        title += ' '+makers['count']+' ';
        title += this.translate.instant('suppliers');

        this.modelToolsProvider.yesOrNoAlert(this.proceedOrder, {
            'title':title,
            'yes':this.translate.instant('proceedOrder'),
            'no':this.translate.instant('cancel'),
            'this':this
        })
    }



    actionDataTypeChanged(e, key = 'type') {
        let value = e['detail']['value'];
        this.cartData['actionData'][key] = value;
    }

    emptyCart() {
        let me = this;
        this.modelToolsProvider.yesOrNoAlert(function () {
            // me.cartData['amount'] = 0;
            me.orderService.clearCart();
            me.changeRef.detectChanges();
        })
    }

    cancelAction() {
        let me = this;
        this.modelToolsProvider.yesOrNoAlert(function () {
            me.orderService.cancelAction();
            me.changeRef.detectChanges();
        })
    }

    changeAgentToolVisible()
    {
        this.orderService.changeAgentToolVisible();
    }

    goTo(page) {

        let navigationExtras: NavigationExtras = {};

        this.navCtrl.navigateForward(page, navigationExtras);
    }

    nextPage(e)
    {
        console.log(this.options, this.cartData['amount'])
        this.options['limit']+=5
        e.target.complete();
        e.target.disabled = this.options['limit'] >= this.cartData['amount'];
    }

}
