import {Pipe, PipeTransform} from '@angular/core';


import * as moment from "moment";

@Pipe({
    name: 'cut'
})
export class CutPipe implements PipeTransform {

    transform(str: string, max: number, noDots = false) {
        if (!str) return;
        if (max == -1)
            return str;
        if (str.length > max)
            str = str.substring(0, max) + (!noDots ? '...' : '');
        return str;
    }

}

@Pipe({
    name: 'range'
})
export class RangePipe implements PipeTransform {

    transform(max: number) {
        return new Array(max);
    }

}

@Pipe({
    name: 'isLately'
})
export class IsLast5MinutesPipe implements PipeTransform {

    transform(date: string) {
        if (moment(date).add(185, 'minutes').isAfter(moment(new Date())))
            return true;

        return false;
    }

}

@Pipe({
    name: 'splitCapital'
})
export class SplitCapitalPipe implements PipeTransform {

    transform(txt: string) {
        if (!txt) return '';

        if (typeof txt != 'string') return txt;
        if (txt.indexOf(' ') != -1) return txt;

        txt = txt.replace('_', '')

        txt = (txt).toString();
        txt = txt.charAt(0).toUpperCase() + txt.slice(1);

        let res = txt.match(/[0-9A-Z-][a-z0-9]+/g);//[' ']
        let resTxt;

        if (res) {
            resTxt = res.join(" ");
        }

        if (!resTxt)
            resTxt = txt;

        return resTxt;
    }

}


@Pipe({
    name: 'numberit'
})
export class NumberItPipe implements PipeTransform {

    transform(num: number) {
        if (!num) return 0;

        //style: 'currency',
        // currency: 'USD',
        var formatter = new Intl.NumberFormat('en-US', {


            // These options are needed to round to whole numbers if that's what you want.
            //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
            //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
        });

        let resTxt = formatter.format(num);

        return resTxt;
    }


}

@Pipe({
    name: 'phoneit'
})
export class PhoneitPipe implements PipeTransform {

    transform(num: string) {
        if (!num) return '';

        let resTxt = phoneit(num);

        return resTxt;
    }
}


@Pipe({name: 'round'})
export class RoundPipe {
    transform(input: number) {
        return Math.floor(input);
    }
}

@Pipe({name: 'lowerCase'})
export class LowerCasePipe {
    transform(input: string) {
        return input.toLocaleLowerCase();
    }
}


function phoneit(localNumber) {
    if (!localNumber) {
        return '';
    }

    let newArray = localNumber.match(/^(91|)?(\d{3})(\d{3})(\d{4})$/);

    if (!newArray || newArray.length <= 3)
        return '';

    // Checking the international code
    let intlCountryCode = (newArray[1] ? '+91' : '');
    // Resolving the above array we get
    // the international number
    let internationalNumber = intlCountryCode + ' ('
        + newArray[2] + ') ' + newArray[3]
        + '-' + newArray[4];
    return internationalNumber;
}