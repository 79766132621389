import { Component, OnInit } from '@angular/core';
import {Business} from "../../../framework/model/core/business/business";
import {Ticket} from "../../../framework/model/core/ticket/ticket";
import {Book} from "../../../framework/model/core/book/book";
import {BusinessService} from "../../services/BusinessService";
import {NavController} from "@ionic/angular";

@Component({
  selector: 'app-technical-literature',
  templateUrl: './technical-literature.page.html',
  styleUrls: ['./technical-literature.page.scss'],
})
export class TechnicalLiteraturePage implements OnInit {

   constructor() {
    }

  ngOnInit() {
  }

}
