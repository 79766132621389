import {Component, OnInit, ViewChild} from '@angular/core';
import {MiniItem} from "../../../framework/model/core/items/miniitem";
import {ModelProvider} from "../../../framework/providers/modelProvider/ModelProvider";
import {IonSearchbar} from "@ionic/angular";
import {Events} from "../../../framework/providers/events";
import {BusinessService} from "../../services/BusinessService";
import {AppDataService} from "../../services/AppDataService";
import {OrderService} from "../../services/OrderService";

@Component({
    selector: 'app-search',
    templateUrl: './search.page.html',
    styleUrls: ['./search.page.scss'],
})
export class SearchPage implements OnInit {
    public searchData = {'txt':''};
    public business;
    public items;

    //for item component:
    public cartOptions;
    public appuser;

    public searchText: string;
    public models: Array<MiniItem> = [];
    public options: object = {'search_auto_focus': false, 'hide_toolbar': false, 'hide_search':true};
    public filters: object = {'noSubItems':true, 'search':' '};

    footerOptions = {'hide-footer':false};


    constructor(public modelProvider: ModelProvider,
                public appDataService:AppDataService,
                public orderService:OrderService,
                public events: Events,
                public businessService: BusinessService) {
    }

    @ViewChild('searchbar') searchBar: IonSearchbar;

    ionViewDidEnter() {
        setTimeout(() => {
            this.searchBar.setFocus();
        }, 150);
    }

    ngOnInit() {
        // this.options['hide_search'] = this.businessService.screenSize[0] < 1400
        this.events.subscribe('keyboard_click_itempref', (data)=>{
            if (data['fieldText']=='---')
            {

            }
        });

        this.events.subscribe('keyboard_letter_itempref', (data)=>{
            this.search(data['fieldText']);
        })
        this.appuser = this.orderService.getAppUser();
        this.cartOptions = this.orderService.getCartOptions();
    }

    // ionViewDidEnter() {
    //     // setTimeout(() => {
    //     //     this.searchbar.setFocus();
    //     // });
    //     // this.search('');
    // }

    search(searchText) {
        if (!searchText || searchText == '') searchText = ' ';
        // this.modelProvider.list('miniItem', {'search':this.searchText}).then((res)=>{
        //   this.models = res['models'];
        // })
        this.filters['search'] = searchText
        this.events.publish(this.options['prefix'] + '_updateItems', this.filters);
        // this.events.publish(this.options['prefix'] + '_updateItemsFilter', filters);

        // let data = this.appDataService.searchItems('search', filters);
        // console.log(data);
        // this.items = data['models'];

    }


}
