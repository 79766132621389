import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {AdminService} from "../../../../../core/services/AdminService";
import {Events} from "../../../../../framework/providers/events";
import {BusinessService} from "../../../../../core/services/BusinessService";

@Component({
    selector: 'app-admin-footer',
    templateUrl: './admin-footer.component.html',
    styleUrls: ['./admin-footer.component.scss'],
})
export class AdminFooterComponent implements OnInit, OnDestroy {

    @Input()
    sectionName;
    @Input()
    exTableData;
    @Input()
    model;
    @Input()
    navCtrl;
    @Input()
    prefix;
    @Input()
    showAdminMenu = false;

    extraFilters = {};
    sectionNameClick;
    ready = true;
    onLoad = false;
    showMainMenu = false;

    constructor(public adminService: AdminService, public events: Events, public businessService:BusinessService) {
    }

    ngOnInit() {
        this.events.subscribe('rowClick' + this.prefix, data => {
            this.sectionName = data['sectionName'];
            this.exTableData = data['exTableData'];
            let me = this;
            me.model = null;
            setTimeout(function () {
                me.model = data['model'];
            }, 100);
        });

        this.events.subscribe('rowClose', data => {
            this.model = null;
        })
    }

    ngOnDestroy() {
        this.events.unsubscribe('rowClick');
        this.events.unsubscribe('rowClose');
    }

    showProfile() {

    }

    loadDashboard(type) {
        this.extraFilters['userTypeId'] = type['id']

        let me = this;
        // let filters = {
        //     'ordering': '-id', 'type': 'moving',
        //     'name': 'taarif', 'modelOffset': 0, 'modelLimit': 20
        // };
        let filters = {'getDashboard': 1}
        Object.assign(filters, this.extraFilters)

        this.onLoad = true;
        me.adminService.loadAdmin(filters).then(function (tableData: any) {
            me.onLoad = false;
            me.showMainMenu = false;
            me.events.publish('resetHome' + me.prefix, {'tableData': tableData, 'type': type});
        });
    }

    chooseOpenBox(sectionName) {

        this.sectionNameClick = sectionName;

        this.events.publish('chooseOpenBox' + this.prefix, {'sectionName': sectionName});
    }
}
