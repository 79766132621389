import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from "@ionic/angular";
import {Company} from "../../../../../../framework/model/core/company/company";
import {AppUser} from "../../../../../../framework/model/core/appuser/appuser";
import {OrderService} from "../../../../../services/OrderService";
import {ModelProvider} from "../../../../../../framework/providers/modelProvider/ModelProvider";
import {Events} from "../../../../../../framework/providers/events";
import {ModelToolsProvider} from "../../../../../../framework/providers/model-tools/model-tools";
import {AppSettings} from "../../../../../conf/appSettings";

@Component({
    selector: 'app-agent-companies',
    templateUrl: './agent-companies.page.html',
    styleUrls: ['./agent-companies.page.scss'],
})
export class AgentCompaniesPage implements OnInit {

    public appuser: AppUser;
    public models: Array<Company> = [];
    public prefix: string = 'companiesList_' + Math.floor(Math.random() * Math.floor(1000000));

    @Input()
    public options: object = {'ready':false};

    public filters: object = {'limit':20};
    public fields = ['companyFileNumber', 'internalCompanyId', 'city', 'street'];

    public modelId;
    public settings;

    constructor(public modalController: ModalController, public orderService: OrderService, public modelProvider:ModelProvider,
                public events:Events, public modelTools:ModelToolsProvider,public appSettings: AppSettings) {
        this.appuser = this.orderService.getAppUser();
        this.settings = this.appSettings.getSettings('app');
        this.modelId = this.appuser.data.companyId;

        if(this.settings['clients_page_fields'])
            this.fields = this.settings['clients_page_fields']
    }

    ngOnInit() {
    }

    changeCompany(e) {
        let companyId = e['detail']['value'];
        ////console.loge);
        ////console.logcompanyId);
        let company = this.modelProvider.getModelById(this.models, companyId);
        //console.logcompany);

        this.modelId = company.data.id;
        this.orderService.changeAgentCompany(company);

        // this.appuser.data.companyId = comapny.data.id;
        // this.appuser.data.companyName = comapny.data.companyName;
        // this.appuser.data.companyFileNumber = comapny.data.companyFileNumber;
        // this.appuser.data.companyStatus = comapny.data.status;
        // this.appuser.data.internalCompanyId = comapny.data.internalCompanyId;
        // this.appuser.data.companyApproved = comapny.data.approved;
        // this.appuser.data.companyPriceApproved = comapny.data.priceApproved;
        //
        // this.modelProvider.update(this.appuser, {}, null, true).then((res) => {
        //     this.events.publish('agentCompanyChanged');
        // })
    }

    viewClient(model) {
        if (this.appuser.data.companyId == model.data.id) {
            this.modelTools.goTo('client-page/' + model.data.id + '/1')
            return;
        }

        let me = this;
        this.modelTools.yesOrNoAlert(function () {
            me.changeClient(model)
        });
    }

    changeClient(model){
        let prefix: string = 'company_' + Math.floor(Math.random() * Math.floor(1000000));

        this.events.subscribe(prefix, (res)=>{
            this.modelTools.goTo('client-page/'+model.data.id+'/1')
            this.events.unsubscribe(prefix)
        })
        this.orderService.changeAgentCompany(model, null, false, prefix);

    }

    close() {
        this.modalController.dismiss();
    }

}
