// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.field-label {
  max-width: 25%;
  font-size: 15px;
  white-space: initial;
}

ion-list ion-item ion-label {
  max-width: initial;
}

.box-header {
  background: var(--button-a-background);
  color: var(--button-a-text);
}

.user-approved-field {
  font-size: 25px;
}

.user-approved-field.success {
  color: #4CAF50;
}

.user-approved-field.warning {
  color: #f44336;
}

.field-box {
  overflow: auto;
  white-space: pre-line;
}`, "",{"version":3,"sources":["webpack://./src/core/pages/users-manager/users-manager.page.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,eAAA;EACA,oBAAA;AACF;;AAII;EACE,kBAAA;AADN;;AAMA;EACE,sCAAA;EACA,2BAAA;AAHF;;AAMA;EACE,eAAA;AAHF;;AAMA;EACE,cAAA;AAHF;;AAMA;EACE,cAAA;AAHF;;AAOA;EACE,cAAA;EACA,qBAAA;AAJF","sourcesContent":[".field-label {\n  max-width: 25%;\n  font-size: 15px;\n  white-space: initial;\n}\n\nion-list {\n  ion-item {\n    ion-label {\n      max-width: initial;\n    }\n  }\n}\n\n.box-header {\n  background: var(--button-a-background);\n  color: var(--button-a-text);\n}\n\n.user-approved-field {\n  font-size: 25px;\n}\n\n.user-approved-field.success {\n  color: #4CAF50;\n}\n\n.user-approved-field.warning {\n  color: #f44336;\n}\n\n\n.field-box {\n  overflow: auto;\n  white-space: pre-line;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
