export class Stock {
    data = {
        "id": null,
        "stock": null,
        "item": null,
        "company": null,
    };

    constructor(public data1: any) {
        this.data = data1;
    }

    //shortHeaders = [];
    prefix = "stock";
    title = "stock";
    dataFields = {
        id: {'title': 'ID', 'value': null, 'type': 'number', 'readonly': true},
        created: {'title': 'Created', 'value': null, 'type': 'date', 'readonly': true},
        updated: {'title': 'Updated', 'value': null, 'type': 'date', 'readonly': true},
        stock: {'title': 'finalStock', 'value': null, 'type': 'text'},
        item: {'title': 'item', 'value': null, 'type': 'text'},
        company: {'title': 'company', 'value': null, 'type': 'text'},
    }
}