export class BandObligoChecks {
    data = {
        "cardNum": null,
        "paymentName": null,
        "code2": null,
        "code": null,
        "ivcode": null,
        "payDate": null,
        "ivdate": null,
        "custdes": null,
        "qprice": null,
        "iv": null,
        "ivnum": null,
        "payAccount": null,
        "payment": null,
        "details": null,
        "business": null,
        "company": null,

    };

    constructor(public data1: any) {
        this.data = data1;
    }

//shortHeaders = [];
    prefix = 'bandObligoChecks';
    title = 'User';
    dataFields = {
        cardNum: {'title': 'cardNum', 'value': null, 'type': 'number', 'readonly': true},
        paymentName: {'title': 'paymentName', 'value': null, 'type': 'text'},
        code2: {'title': 'code2', 'value': null, 'type': 'text'},
        code: {'title': 'currency', 'value': null, 'type': 'text'},
        ivcode: {'title': 'ivcode', 'value': null, 'type': 'text'},
        payDate: {'title': 'payDate', 'value': null, 'type': 'text'},
        ivdate: {'title': 'ivdate', 'value': null, 'type': 'text'},
        custdes: {'title': 'custdes', 'value': null, 'type': 'text'},
        qprice: {'title': 'qprice', 'value': null, 'type': 'text'},
        iv: {'title': 'iv', 'value': null, 'type': 'text'},
        ivnum: {'title': 'ivnum', 'value': null, 'type': 'text'},
        payAccount: {'title': 'payAccount', 'value': null, 'type': 'text'},
        payment: {'title': 'payment', 'value': null, 'type': 'text'},
        details: {'title': 'details', 'value': null, 'type': 'text'},
        business: {'title': 'business', 'value': null, 'type': 'text'},
        company: {'title': 'company', 'value': null, 'type': 'text'},
        created: {'title': 'Created', 'value': null, 'type': 'date', 'readonly': true},
        updated: {'title': 'Updated', 'value': null, 'type': 'date', 'readonly': true},
    };
}
