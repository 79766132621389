// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menurows > div:nth-of-type(odd) {
  background: #f2f2f2;
}

.menurows > div:nth-of-type(even) {
  background: #ffffff;
}`, "",{"version":3,"sources":["webpack://./src/app/shaps/components/tools/admin-page-menu/admin-page-menu.component.scss"],"names":[],"mappings":"AAIA;EACE,mBAAA;AAHF;;AAMA;EACE,mBAAA;AAHF","sourcesContent":[".menurows\n{\n\n}\n.menurows > div:nth-of-type(odd) {\n  background: #f2f2f2;\n}\n\n.menurows > div:nth-of-type(even) {\n  background: #ffffff;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
