// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.note-label {
  white-space: pre-line;
}

.payment-template {
  height: 1000px;
}`, "",{"version":3,"sources":["webpack://./src/core/pages/forms/components/app-form/app-form.component.scss"],"names":[],"mappings":"AAAA;EAEE,qBAAA;AAAF;;AAGA;EAEE,cAAA;AADF","sourcesContent":[".note-label\n{\n  white-space: pre-line;\n}\n\n.payment-template\n{\n  height:1000px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
