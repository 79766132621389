// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-label {
  white-space: pre-wrap !important;
}`, "",{"version":3,"sources":["webpack://./src/core/pages/item/components/item-sale-data/item-sale-data.component.scss"],"names":[],"mappings":"AAAA;EACE,gCAAA;AACF","sourcesContent":["ion-label{\n  white-space: pre-wrap !important;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
