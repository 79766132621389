import {Component, Input, OnInit} from '@angular/core';
import {ModelProvider} from "../../../framework/providers/modelProvider/ModelProvider";
import {FormBuilder} from "@angular/forms";
import {ModelToolsProvider} from "../../../framework/providers/model-tools/model-tools";
import {Events} from "../../../framework/providers/events";
import {AppConfig} from "../../conf/main";

@Component({
    selector: 'app-generic-form',
    templateUrl: './generic-form.component.html',
    styleUrls: ['./generic-form.component.scss'],
})
export class GenericFormComponent implements OnInit {

    @Input()
    public key;

    public formData;

    public appForm: any;

    @Input()
    public filters: object = {};

    public initialParams: object;

    constructor(public modelProvider: ModelProvider, public formBuilder: FormBuilder, public tools: ModelToolsProvider, public events: Events) {
    }

    ngOnInit() {
        this.appForm = this.formBuilder.group({});
        this.getFormData();
    }

    ngOnDestroy() {
        this.events.unsubscribe('viewTextAlert')
    }

    getFormData(loading = true) {
        this.resetForm();
        if (loading) this.modelProvider.loading();
        let filters = {'key': this.key, 'setPrefix': true};
        let key = 'initialParams_' + AppConfig.bisPrefix;

        this.tools.getKey(key).then((res:object) => {
            console.log("GET KEY ", key, res);
            if (res && res[this.key]) {
                for (let p in res[this.key]) {
                    filters[p] = res[this.key][p];
                }

                this.initialParams = res[this.key];
            }

            if(this.filters)
            {
                for (let key in this.filters) {
                    filters[key] = this.filters[key];
                }
            }

            this.modelProvider.custom('getAppForm', filters).then((res) => {
                this.formData = res;
                if (loading) this.modelProvider.loadingOff();
            })
        })
    }

    proceed(button) {
        let filters = this.formData['filters'];
        filters['setPrefix'] = true;
        filters['key'] = this.key;

        if (button['filters']) {
            for (let key in button['filters']) {
                filters[key] = button['filters'][key];
            }
        }

        if(this.initialParams)
        {
            for(let key in this.initialParams) {
                filters[key] = this.initialParams[key]
            }
        }

        if(this.filters)
        {
            for (let key in this.filters) {
                filters[key] = this.filters[key];
            }
        }

        this.modelProvider.loading();
        this.modelProvider.customPost(button['url'], this.formData, filters).then((res) => {
            let data = res['data'];
            if (!data['valid']) {
                let fields = data['fields'];
                for (let k in fields) {
                    if (!fields[k]['valid']) {
                        this.appForm['controls'][k].setErrors({
                            'incorrect': !fields[k]['valid'],
                            'msg': fields[k]['msg']
                        });
                        this.appForm['controls'][k].markAsTouched();
                    }
                }
            }

            if (data['next_step']) {
                for (let k in data['next_step']) {
                    this.formData[k] = data['next_step'][k];
                }

                if (data['next_step']['filters']['step'] == -1) {
                    this.publishEvents(data['next_step']['filters']['events']);

                    if(!data['next_step']['filters']['disable_reset_form']){
                        let me = this;
                        setTimeout(function () {
                            me.getFormData(false); // init form;
                        }, 2000);
                    }

                    // ** need to replace all with publishEvents().

                    // let event_key = data['next_step']['filters']['event_key'];
                    // let keys = data['next_step']['filters']['keys'];
                    // console.log(keys)
                    // let params = {}
                    // for (let k in keys) {
                    //     console.log(k, keys[k], this.formData['fieldsData'], this.formData['fieldsData'][keys[k]])
                    //     params[k] = this.formData['fieldsData'][keys[k]]['value'];
                    // }
                    // if (data['next_step']['filters']['params']) {
                    //     let add_params = data['next_step']['filters']['params'];
                    //     for (let k in add_params)
                    //         params[k] = add_params[k];
                    // }
                    //
                    // console.log(event_key, params)
                    // this.events.publish(event_key, params);


                    // *****

                    // this.tools.goTo(data['next_step']['filters']['page'], {});
                }
            }

            if(data['valid'])
            {
                this.resetForm();
            }

            this.modelProvider.loadingOff();
        });
    }

    publishEvents(obj) {
        if (!obj)
            return;
        for (let event of obj) {
            this.publishEvent(event)
        }

    }

    publishEvent(obj) {
        let event_key = obj['event_key'];
        let keys = obj['keys'];
        let params = {}
        for (let k in keys) {
            console.log(k, keys[k], this.formData['fieldsData'], this.formData['fieldsData'][keys[k]])
            params[k] = this.formData['fieldsData'][keys[k]]['value'];
        }
        if (obj['params']) {
            let add_params = obj['params'];
            for (let k in add_params)
                params[k] = add_params[k];
        }

        console.log(event_key, params);
        this.events.publish(event_key, params);
    }

    resetForm() {
        console.log("RESET FORM!!")
        this.appForm.reset();

        let controls = this.appForm['controls'];
        for (let key in controls) {
            if (this.formData['fields'].includes(key)) continue;
            this.appForm.removeControl(key)
        }

    }

}
