import {Component, ElementRef, Input, OnDestroy, OnInit, QueryList, ViewChildren} from '@angular/core';
import {NavController, PopoverController} from "@ionic/angular";
import {ModelProvider} from "../../../../framework/providers/modelProvider/ModelProvider";
import {ActivatedRoute, NavigationExtras} from "@angular/router";
import {ModelToolsProvider} from "../../../../framework/providers/model-tools/model-tools";
import {BusinessService} from "../../../../core/services/BusinessService";
import {AdminService} from "../../../../core/services/AdminService";
import {Events} from "../../../../framework/providers/events";

import {
    BarController,
    BarElement,
    CategoryScale,
    Chart,
    Decimation,
    Filler,
    Legend,
    LinearScale,
    LineController,
    LineElement,
    PointElement,
    Title,
    Tooltip
} from 'chart.js';
import {SelectBarComponent} from "../../components/select-bar/select-bar.component";
import {AppDataService} from 'src/core/services/AppDataService';
import {OrderService} from "../../../../core/services/OrderService";


@Component({
    selector: 'app-admin-box',
    templateUrl: './admin-box.component.html',
    styleUrls: ['./admin-box.component.scss'],
})
export class AdminBoxComponent implements OnInit, OnDestroy {
    @ViewChildren("graphs", {read: ElementRef}) graphs: QueryList<ElementRef>;

    presentingElement = null;

    ready = false;
    loading = false;
    tableData;
    menu = {};
    currentOpenSection;
    exTableData;
    exTableData2;
    calendarModelsObj = {};

    @Input()
    colSize;
    @Input()
    prefix;
    @Input()
    extraFilters = {};
    @Input()
    mainPageOnly = false;
    @Input()
    showPanelsType = null;
    @Input()
    type = null;
    @Input()
    homePages = null;
    @Input()
    homeTableName = null;
    @Input()
    homeModelId = null;

    graphTypeIcons = {'bar': 'bar-chart-sharp', 'line': 'trending-down-outline', 'pie': 'pie-chart-outline'};

    myprefix = "selectTablePrefix3" + Math.floor(Math.random() * Math.floor(1000));

    constructor(public events: Events,
                public popoverCtrl: PopoverController,
                public business: BusinessService,
                public navCtrl: NavController,
                public orderService: OrderService,
                public modelProvider: ModelProvider,
                public appData: AppDataService,
                public modelTools: ModelToolsProvider,
                public businessService: BusinessService,
                public adminService: AdminService,
                public route: ActivatedRoute) {
        Chart.register(PointElement, LineController, LineElement, LinearScale, BarElement, BarController, CategoryScale, Decimation, Filler, Legend, Title, Tooltip);
    }

    ngOnInit(): void {
        let me = this;

        if (!this.homePages) {
            setTimeout(function () {
                me.refresh();
            }, 100);
        } else {
            // this.exTableData = this.adminService.manageModels;
            this.start(this.adminService.manageModels, this.homePages);
        }

        // me.type = {'name': 'mainData'};
        this.events.subscribe('resetHome' + this.prefix, (data) => {
            // me.type = data['type'];
            me.start(data['tableData']);
            if (data['type']) {
                me.type = data['type'];
            }
        });
    }

    ngAfterViewInit() {
        this.presentingElement = document.querySelector('.ion-page2');
    }

    start(tableData, homePages = null) {
        let me = this;
        this.tableData = tableData;
        this.exTableData = tableData;
        this.homePages = homePages ? homePages : this.exTableData['homePage'];
        me.menu[me.exTableData['showBoxes'][0]] = true;
        // me.currentOpenSection = me.tableData['homePage'][0]['main']['homeData'];
        // me.businessService.setAdminData(tableData);
        me.calendarModelsObj = me.adminService.manageModels['calendarModelsObj'];

        me.ready = true;
        me.loading = false;
        me.type = {'name': me.exTableData['appUserHomePageName']};
    }

    refresh() {
        this.loading = true;
        // let filters = {'ordering': '-id', 'type': 'moving', 'name': 'taarif', 'modelOffset': 0, 'modelLimit': 20};
        // for (let p in res) {
        //     storageParmas[p] = res[p]
        // }
        this.loadDashboard();
        // let me = this;
        // this.modelProvider.list('report', filters).then(function (data: any) {
        //     me.loading = false;
        //     me.exTableData = data.models[0].data.finalResReport;
        //     me.exTableData2 = JSON.parse(JSON.stringify(data.models[0].data.finalResReport));
        //     me.loadDashboard();
        //
        //     me.menu[me.exTableData['showBoxes'][0]] = true;
        //     me.menu[me.exTableData['showBoxes'][1]] = true;
        // });
    }

    ngOnDestroy(): void {
        this.events.unsubscribe('resetHome' + this.prefix);
    }

    setCurrentSection(current) {
        // this.currentOpenSection = null;
        //
        // let me = this;
        // setTimeout(function () {
        //     me.currentOpenSection = current;
        // }, 1);
    }

    loadDashboard() {
        let me = this;
        // let filters = {
        //     'ordering': '-id', 'type': 'moving',
        //     'name': 'taarif', 'modelOffset': 0, 'modelLimit': 20
        // }
        let filters = {'getDashboard': 1}
        Object.assign(filters, this.extraFilters);

        if (this.homeTableName) filters['homeTableName'] = this.homeTableName;
        if (this.homeModelId) filters['homeModelId'] = this.homeModelId;

        me.adminService.loadAdmin(filters).then(function (tableData: any) {
            if (tableData && tableData['showBoxes']) //if error occured on server models len is 0.
            {
                me.exTableData2 = JSON.parse(JSON.stringify(tableData));
                me.start(tableData);



            } else {
                me.ready = true;
            }
        });
    }

    //lables = ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"]
    //data = [12, 19, 3, 5, 2, 3]
    setChart(barElement, labales, data, type, dcolors = null) {
        // if (this.barChart) {
        //     this.barChart.clear();
        // }

        let colors = [];
        let colorsData = {
            "primary": "rgba(56, 128, 255, 0.2)",
            "danger": "rgba(245, 61, 61, 0.2)",
            "warning": "rgba(255, 206, 0, 0.2)",
            "secondary": "rgba(12, 209, 232, 0.2)",
            "dark": "rgba(34, 34, 34, 0.2)",
            "success": "rgba(16, 220, 96, 0.2)"
        };

        for (let key in colorsData) {
            colors.push(colorsData[key]);
        }

        if (dcolors) {
            for (let key in dcolors) {
                if (dcolors[key] != -1) {
                    colors[key] = colorsData[dcolors[key]];
                }
            }
        }

        let datasets = [];
        if (type == 'radar') {
            for (let i in data) {
                datasets.push({
                    'label': i,
                    'data': data[i]
                })
            }
        } else {
            datasets = [
                {
                    label: "",
                    data: data,
                    backgroundColor: colors,
                    borderColor: colors,
                    borderWidth: 1
                }
            ];
        }

        //
        //         type: 'horizontalBar',
        new Chart(barElement.nativeElement, {
            type: type,
            data: {
                labels: labales,
                datasets: datasets
            },
            options: {
                scales: {
                    yAxes: [
                        {
                            ticks: {
                                beginAtZero: true
                            }
                        }
                    ]
                }
            }
        });
    }

    async presentSelectBar(myEvent, type, isbig = false) {
        const popover = await this.popoverCtrl.create({
            component: SelectBarComponent,
            componentProps: {
                'prefix': 'aaaa',
                'exTableData': this.exTableData,
                'sectionName': null,
                'type': type
            },
            event: myEvent,
            animated: true,
            showBackdrop: true,
            cssClass: 'note-popover icenter-popover ' + (isbig ? 'bigger-popover' : 'smaller-popover')
        });
        return await popover.present();
    }

    openCustomModel(sectionName, menuIndex = 0, boxModel = null, event = null, filter = null) {

        //model box ?
        if (boxModel && boxModel['button']) {
            let page = boxModel['page'];
            sectionName = boxModel['sectionName'];
            let data = boxModel['data'];

            let params = {
                'menuIndex': menuIndex,
                'sectionName': sectionName,
                'filter': filter
            };
            for (let k in data) {
                params[k] = data[k];
            }
            let navigationExtras: NavigationExtras = {
                queryParams: params
            };
            this.navCtrl.navigateForward(page, navigationExtras);
            if (event) {
                event.stopPropagation();
            }
        }
        // table:
        else {
            ////console.log'open custom model: ', sectionName);
            let params = {
                'menuIndex': menuIndex,
                'sectionName': sectionName,
                'filter': filter
            };
            let navigationExtras: NavigationExtras = {
                queryParams: params
            };
            this.navCtrl.navigateForward('admin-model/' + sectionName, navigationExtras);
        }
    }

    //update server it needs to update changes.
    updateData() {
        let filters = {'ordering': '-id', 'type': 'moving', 'name': 'taarif', 'modelOffset': 0, 'modelLimit': 20};
        filters['editedSections'] = [];
        filters['sectionName'] = '';
        filters['updateData'] = true;
        filters['updateModelClasses'] = true;
        this.modelProvider.customPost('saveDataReport', [], filters, true).then((data: any) => {
            // this.events.publish('refresh_request_' + this.prefix, this.filters);
            this.appData.getAppData(data['data']['version']);
        });
    }

    onTableScroll(event, prefix) {
        if (event.target.scrollHeight - 500 < event.target.scrollTop) {
            // this.events.publish('nextPage' + prefix, {});
        }
    }

    tableButtonClick(tablePrefix, key, data) {
        this.events.publish("btnclick_" + tablePrefix, {'key': key, 'data': data});
//     'addFilter'
// }, {'quickFieldFilter':[{'field':'status', 'value':counter['status__id']}]})
    }

    saveField(sectionName, model, fieldData) {
        let filters = {'type': 'moving', 'name': 'taarif'};

        let newModel = {
            'main': {'value': model['id']['value'], 'updated': true, 'name': '', 'type': 'none'},
            'id': model['id']
        };

        newModel[fieldData['name']] = {
            'value': fieldData['value'],
            'name': fieldData['name'],
            'edited': true
        };
        //console.lognewModel);

        let tableData = {};
        tableData[sectionName] = [newModel];

        filters['editedSections'] = [sectionName];
        filters['sectionName'] = sectionName;

        let me = this;
        this.modelProvider.customPost('saveDataReport', [tableData], filters, true).then((data: any) => {
            // me.exTableData[me.sectionName + 'Data']['edited'] = false;
            // me.period['main']['updated'] = false;
            // me.fieldData['edited'] = false;
        });
    }

    changeGraph(homePage) {
        console.log(homePage['main']['homeData']['graphType']);
        let types = ['bar', 'line', 'pie'];

        let nextIndex = types.indexOf(homePage['main']['homeData']['graphType']) + 1;

        if (nextIndex > 2) nextIndex = 0;
        homePage['main']['homeData']['graphType'] = types[nextIndex];

        homePage['off'] = true;
        setTimeout(function () {
            homePage['off'] = false;
        }, 100);
    }

    // let me = this;
    //
    // this.refresh();
    //
    // this.events.subscribe('updatetaarif_' + this.prefix, (data) => {
    //     me.showMiniLoading = false;
    //     me.showLoading = false;
    //     //console.log'data!!');
    //     //console.logdata);
    //     me.onUpdate(data);
    //
    //     // if (!me.initialized) {
    //     me.initialized = true;
    //
    //     //     setTimeout(function () {
    //     //         me.refresh('all')
    //     //     }, 1);
    //     // } else {
    //     me.initializedFull = true;
    //     // }
    // });
    // this.events.subscribe('saveall' + this.prefix, (data) => {
    //     me.save();
    // });
    // this.events.subscribe('openMap', (data) => {
    //     //console.log'map opening');
    //     this.showOnMap(data);
    // });
    // this.events.subscribe('addEditedSections' + this.prefix, (data) => {
    //     if (this.editedSections.indexOf(data['key']) == -1) {
    //         this.editedSections.push(data['key']);
    //     }
    // });
    // this.events.subscribe('addEditedSections', (data) => {
    //     if (this.editedSections.indexOf(data['key']) == -1) {
    //         this.editedSections.push(data['key']);
    //     }
    // });
    // this.events.subscribe('qucikEditModelCall', (data) => {
    //     this.qucikEditModels.unshift(data['qucikEditModel']);
    //     this.qucikEditReady = false;
    //
    //     if (this.editedSections.indexOf(data['qucikEditModelKey']) == -1) {
    //         this.editedSections.push(data['qucikEditModelKey']);
    //     }
    //
    //     this.qucikEditModelKeys.unshift(data['qucikEditModelKey']);
    //
    //     let me = this;
    //     setTimeout(function () {
    //         me.qucikEditReady = true;
    //     }, 1);
    // });


    // this.events.subscribe(this.myprefix + '_updateFilter', (data) => {
    //     //console.logdata);
    //     this.searchFilter = data['search'];
    //     //console.logthis.searchFilter);
    // });

    // }

    // ngOnInit() {
    // this.events.subscribe('clickTableRow', (data) => {
    //     this.showRowModel = data['rowModel'];
    //     this.menuBoxesType = 3;
    // });
    //
    // this.events.subscribe('refresh_request_' + this.prefix, (filters) => {
    //     //add filters from sub models.
    //     for (let key in filters) {
    //         this.filters[key] = filters[key];
    //     }
    //     this.ready = true;
    // });

    // //from pages button
    // this.events.subscribe("markmodel", (data) => {
    //     this.removeSubModel(data.sectionKey, data.markType, data.id);
    // });
    //
    // this.events.subscribe("btnclick_" + this.prefix, (data) => {
    //     if (data == 'showTable') {
    //         this.showTable = !this.showTable;
    //     }
    // });
    // }

    // ngOnDestroy(): void {
    //     this.events.unsubscribe("btnclick_" + this.prefix);
    //     this.events.unsubscribe("clickrow");
    //     this.events.unsubscribe('updatetaarif_' + this.prefix);
    // }
    //
    // // back from update
    // onUpdate(data) {
    //     let me = this;
    //     me.tableDataFKeys = {};
    //     me.models = data.models;
    //     if (me.models && me.models.length > 0) //if error occured models len is 0.
    //     {
    //         if (!me.tableData) {
    //             me.tableData = me.models[0].data.finalResReport; //{'title': '', 'enableAdd':[]...}
    //             me.businessService.setAdminData(me.tableData);
    //         } else {
    //             for (let key in me.models[0].data.finalResReport) {
    //                 me.tableData[key] = me.models[0].data.finalResReport[key];
    //             }
    //         }
    //
    //         me.adminService.manageModels = me.tableData;
    //         // for (let key in me.models[0].data.finalResReport) {
    //         //     me.tableDataFKeys[key] = me.getValueKeys(key);
    //         // }
    //
    //         // if (!this.currentSectionKey) {
    //         //     this.currentSectionKey = me.tableData['showBoxes'][0][0];
    //         //     me.showSpecificRowIndex = 0;
    //         // }
    //
    //         // let currentSectionKey = this.currentSectionKey;
    //         // this.currentSectionKey = null;
    //         // me.showLoading = true;
    //         // setTimeout(function () {
    //         //     me.currentSectionKey = currentSectionKey;
    //         //     // setTimeout(function () {
    //         //         me.showLoading = false;
    //         //     // }, 1000);
    //         // });
    //         this.ready = true;
    //     }
    //
    //     //first init
    //     if (!this.menuBoxes) {
    //         this.menuBoxes = this.tableData['showBoxes'];
    //         //console.logthis.menuBoxes);
    //         //console.log"this.menuBoxes!!")
    //         this.menuBoxesType = 1;
    //     }
    //
    //     // this.ready = true;
    //     // this.ready = false;
    //     // setTimeout(function () {
    //     //     me.ready = true;
    //     // });
    //     this.events.publish("onTaarifUpdate", this.tableData);
    // }
    //
    // // get keys by object with field name as the key;
    // getValueKeys(tableKey) {
    //     let models = [];
    //     for (let i in this.tableData[tableKey]) {
    //
    //         if (tableKey.indexOf('Data') != -1) {
    //             continue;
    //         }
    //
    //         //model fields loop
    //         let model = {};
    //         for (let fieldKey in this.tableData[tableKey][i]) {
    //             //create tables data with keys of fields.
    //             //table row by fields names
    //             if (this.tableData[tableKey][i][fieldKey].type == 'table') {
    //
    //                 let keyFields = [];
    //
    //                 for (let rowIndex in this.tableData[tableKey][i][fieldKey].data) {
    //                     let rowKeyFields = {};
    //
    //                     for (let sfield in this.tableData[tableKey][i][fieldKey].data[rowIndex]) {
    //                         rowKeyFields[this.tableData[tableKey][i][fieldKey].data[rowIndex][sfield].name] = this.tableData[tableKey][i][fieldKey].data[rowIndex][sfield];
    //                     }
    //                     keyFields.push(rowKeyFields);
    //                 }
    //
    //                 this.tableData[tableKey][i][fieldKey].dataFkeys = keyFields;
    //             }
    //
    //             //field in object by name
    //             model[this.tableData[tableKey][i][fieldKey].name] = this.tableData[tableKey][i][fieldKey];
    //
    //         }
    //         models.push(model);
    //     }
    //     return models;
    // }
    //
    // showEdit(sectionKey) {
    //     if (!this.initializedFull) {
    //         return;
    //     }
    //
    //     // //console.logsectionKey);
    //     if (this.tableData[sectionKey + 'Data'] && this.tableData[sectionKey + 'Data']['type'] == 'calendar') {
    //         this.showCalendar = true;
    //     } else if (this.tableData[sectionKey].length > 1) {
    //         // skip -1 show real model data
    //         this.showSpecificRowIndex = 1;
    //         // this.tableData[sectionKey][1][0]['updated'] = true;
    //
    //     } else {
    //         //only if it is empty
    //         //show -1 - first row to add
    //         this.showSpecificRowIndex = 0;
    //         this.tableData[sectionKey][0][0]['value'] = null;
    //     }
    //
    //     this.currentSectionKey = sectionKey;
    //
    //     if (this.editedSections.indexOf(sectionKey) == -1) {
    //         this.editedSections.push(sectionKey);
    //     }
    //     // //console.logthis.tableData);
    //     //
    //     // //console.logthis.tableData[this.currentSectionKey][0][0]['value']);
    //
    //     //
    //     // get new data?
    //     // if (!this.tableData[this.currentSectionKey] ||
    //     //     (this.tableData[this.currentSectionKey].length == 1 && (this.tableData[this.currentSectionKey][0][0]['value'] == -1 || !this.tableData[this.currentSectionKey][0][0]['value'])) ||
    //     //     this.tableData[this.currentSectionKey].length == 0) {
    //     //     this.refresh(this.currentSectionKey);
    //     // }
    //     //
    //     //
    //
    //     // this.ionContent.scrollToTop(300);
    //
    //     //add all rows if no tables fields
    //     // if (!this.tableData[sectionKey + 'TableFieldsOrder']) {
    //     //     this.showSpecificRows = [];
    //     //     for (let i in this.tableData[sectionKey]) {
    //     //         this.showSpecificRows.push(this.tableData[sectionKey][i]);
    //     //     }
    //     // }
    // }
    //
    //
    // save() {
    //     let me = this;
    //
    //     //console.log'this.editedSections!');
    //     //console.logthis.editedSections);
    //     //console.logthis.tableData);
    //
    //     this.filters = {'ordering': '-id', 'type': 'moving', 'name': 'taarif', 'modelOffset': 0, 'modelLimit': 20};
    //
    //     this.filters['editedSections'] = this.editedSections;
    //     let tableData = {};
    //     for (let key of this.editedSections) {
    //         tableData[key] = this.tableData[key];
    //         tableData[key + '_removeIds'] = this.tableData[key + '_removeIds'];
    //     }
    //
    //     this.modelProvider.customPost('saveDataReport', [tableData], this.filters, true).then((data: any) => {
    //         // this.events.publish('refresh_request_' + this.prefix, this.filters);
    //         data = data['data'];
    //
    //         // for (let key in data) {
    //         //     this.tableData[key] = data[key];
    //         // }
    //
    //         //remove remove ids
    //         for (let key in this.tableData) {
    //             if (key.indexOf('removeIds') != -1)
    //                 this.tableData[key] = [];
    //         }
    //         //
    //         // let currentSectionKey = this.currentSectionKey;
    //         // this.currentSectionKey = null;
    //         // let me = this;
    //         // setTimeout(function () {
    //         //     me.currentSectionKey = currentSectionKey;
    //         //     // setTimeout(function () {
    //         //         me.showLoading = false;
    //         //     // }, 1000);
    //         // });
    //         // this.qucikEditModelKeys = [];
    //         // this.editedSections = [];
    //         // this.qucikEditReady = false;
    //         // this.showRowModel = null;
    //         // this.menuBoxesType = 2;
    //
    //         // this.ready = false;
    //         // setTimeout(function () {
    //         //     me.ready = true;
    //         // });
    //     });
    // }
    //
    // updateData() {
    //     this.tableData['extraData']['updatedData'] = false;
    //
    //     this.filters['editedSections'] = [];
    //     this.filters['sectionName'] = '';
    //     this.filters['updateData'] = true;
    //     this.filters['updateModelClasses'] = true;
    //     this.modelProvider.customPost('saveDataReport', [this.tableData], this.filters, true).then((data: any) => {
    //         this.events.publish('refresh_request_' + this.prefix, this.filters);
    //     });
    // }
    //
    // //simple remove
    // removeRModel(sectionKey, model) {
    //     this.removeSubModel(sectionKey, 'removeIds', model[0]['value']);
    // }
    //
    // removeSubModel(sectionKey, markType, id) {
    //     if (!this.tableData[sectionKey + '_' + markType])
    //         this.tableData[sectionKey + '_' + markType] = [];
    //
    //     if (this.tableData[sectionKey + '_' + markType].indexOf(id) == -1) {
    //         this.tableData[sectionKey + '_' + markType].push(id);
    //     } else {
    //         this.tableData[sectionKey + '_' + markType].splice(this.tableData[sectionKey + '_' + markType].indexOf(id), 1);
    //     }
    //     this.editedSections.push(sectionKey);
    // }
    //
    // removeModel(model, secKey, markTypeKey, id) {
    //
    //     //it array by we can set it with key as well..
    //     model.removed = !!!model.removed;
    //
    //     // for (let key in model) {
    //     //     if (model[key]['name'] == 'id')
    //     //         model[key]['removed'] = !!!model[key]['removed'];
    //     // }
    //
    //     this.markSubModel(secKey, 'removed', id);
    // }
    //
    //
    // markSubModel(seckey, markTypeKey, id) {
    //     if (!this.tableData[seckey + "_" + markTypeKey]) {
    //         this.tableData[seckey + "_" + markTypeKey] = [];
    //     }
    //
    //     if (this.tableData[seckey + "_" + markTypeKey].indexOf(id) !== -1) {
    //         this.tableData[seckey + "_" + markTypeKey].splice(this.tableData[seckey][markTypeKey].indexOf(id), 1);
    //     } else {
    //         this.tableData[seckey + "_" + markTypeKey].push(id);
    //     }
    // }
    //
    // addModel(sectionKey, fieldDataName = null) {
    //     let newModel = JSON.parse(JSON.stringify(this.tableData[sectionKey][0]));
    //
    //     //remove id for new model copied.
    //     for (let key in newModel) {
    //         if (newModel[key]['name'] == 'id')
    //             newModel[key]['value'] = null;
    //     }
    //
    //     this.tableData[sectionKey].unshift(newModel);
    //     this.tableDataFKeys[sectionKey] = this.getValueKeys(sectionKey);
    // }
    //
    // addSubModel(fieldData) {
    //     let newModel = JSON.parse(JSON.stringify(fieldData.data[0]));
    //     newModel[0] = null;
    //
    //     fieldData.data.unshift(newModel);
    // }
    //
    // saveMiniField(sectionKey, fieldDataName, modelIndex, rowIndex, y, colVal) {
    //     //table field - data is set
    //     this.tableDataFKeys[sectionKey][modelIndex][fieldDataName].data[rowIndex][y] = colVal;
    // }
    //
    // filterit(modeldata) {
    //     this.filters[modeldata['filterkey']] = modeldata['value'];
    //     this.events.publish('refresh_request_' + this.prefix, this.filters);
    // }
    //
    // refresh(sectionKey = null) {
    //     this.showMiniLoading = true;
    //     this.tableData = null;
    //
    //     // let filters = {
    //     //     'clearFilters': true,
    //     //     'keepFilters': ['taarif']
    //     // };
    //     //
    //     // if (sectionKey) {
    //     //     filters['sectionName'] = sectionKey;
    //     // }
    //
    //     this.ready = false;
    //     // this.events.publish('refresh_request_' + this.prefix,
    //     //     filters);
    //     let me = this;
    //     this.modelProvider.list('report', this.filters).then(function (data: any) {
    //         ////console.logres);
    //         me.onUpdate(data);
    //     });
    // }
    //
    // back() {
    //     this.showCalendar = false;
    //
    //     this.menuBoxesType--;
    //     if (this.menuBoxesType == 1) {
    //         this.menuBoxes = this.tableData['showBoxes'];
    //     }
    //
    //     if (this.showRowModel) {
    //         this.events.publish('clickTableRowBack', {});
    //         this.showRowModel = null;
    //     } else {
    //         this.currentSectionKey = null;
    //     }
    // }
    //
    // showCategory(menuIndex, subIndex = 0) {
    //     let params = {
    //         'sectionName': this.tableData['showBoxes'][menuIndex][subIndex],
    //         'menuIndex': menuIndex,
    //         'subIndex': subIndex,
    //     };
    //
    //     let navigationExtras: NavigationExtras = {
    //         queryParams: params
    //     };
    //
    //     this.navCtrl.navigateForward('admin-model/' + this.tableData['showBoxes'][menuIndex][subIndex], navigationExtras);
    // }
    //
    // getImageBacground(imgName) {
    //     if (this.tableData['showBoxesImagesBase']) {
    //         return "url('" + this.tableData['showBoxesImagesBase'] + imgName + "')";
    //     }
    //     return '';
    // }
    //
    // customEvent(key) {
    //     this.navCtrl.navigateForward(key);
    // }
    //
    // showOnMap(data) {
    //
    //     this.mapOpen = true;
    // }
    //
    // closePopup() {
    //     this.qucikEditModelKeys.shift();
    //     this.qucikEditModels.shift();
    //     this.qucikEditReady = false;
    //
    //     if (this.qucikEditModelKeys.length > 0) {
    //         let me = this;
    //         setTimeout(function () {
    //             me.qucikEditReady = true;
    //         });
    //     }
    // }

    addTargetRow(model1Id, model2Id, homePage, row) {
        let newModel = this.adminService.getNewModel('saleTarget', this.exTableData);
        if (row['targetData'] && row['targetData']['id'])
            newModel['id']['value'] = row['targetData']['id'];

        newModel['modelId2']['value'] = model2Id;

        newModel['modelId1']['value'] = model1Id;
        newModel['target']['value'] = row['targetValue'];
        newModel['homePageType']['value'] = homePage.homePageType.value;
        // debugger
        this.adminService.saveModel(newModel, 'saleTarget', this.exTableData).subscribe((data) => {
            console.log('after save model!')
        });
    }
}
