import {Injectable} from '@angular/core';
import {NgModule} from '@angular/core';

import {Type} from '@angular/core';


@NgModule({})
@Injectable()
export class ModelComponents {

    components = {
    }

    constructor() {
    }

    getModelComponent(modelName)
    {
        return this.components[modelName]
    }

}