import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ModelProvider} from "../../../../framework/providers/modelProvider/ModelProvider";


import {ModelClasses} from "../../../../core/model/modelClasses";
import {ModelToolsProvider} from "../../../providers/model-tools/model-tools";
import {BusinessService} from "../../../../core/services/BusinessService";
import {ActivatedRoute} from "@angular/router";
import {Events} from "../../../providers/events";

@Component({
    selector: 'app-appfilter',
    templateUrl: './appfilter.component.html',
    styleUrls: ['./appfilter.component.scss'],
})
export class AppfilterComponent implements OnInit, OnDestroy {

    @Input()
    models = [];

    @Input()
    prefix;

    @Input()
    extData: any = {showEdit: false, displayType: 'table'};

    @Input()
    modelName;

    @Input()
    filterFields;

    @Input()
    filterPref = '';

    @Input()
    filterFieldsData = {};
    filterFieldsDataBackup = {};

    isOpen = false;

    showPages = false;
    showSearch = false;
    countRange;
    onloading = true;

    showFilterOn = false;
    choosedFilterData;
    choosedFilter;
    selectFilterOptions;
    model;

    reports;
    page = 1;
    totalCount;
    rowsPerPage = 20;
    totalPages;

    constructor(public modelClass: ModelClasses,
                public events: Events,
                public modelTools: ModelToolsProvider,
                public modelProvider: ModelProvider,
                public route: ActivatedRoute, public businessService: BusinessService) {
    }

    ngOnDestroy() {
        this.events.unsubscribe(this.prefix + '_updateFilter');
        this.events.unsubscribe('refresh_request_' + this.prefix);
    }

    ngOnInit() {
        ////console.logthis.extData);

        if (!this.extData.showEdit) this.extData.showEdit = false;
        if (!this.extData.displayType) this.extData.displayType = 'table';

        let cls = this.modelClass.getClass(this.modelName);
        this.model = new cls({});
        for (let key in this.filterFields) {
            if (!this.filterFieldsData[this.filterFields[key]])
                this.filterFieldsData[this.filterFields[key]] = [];
        }
        this.filterFieldsDataBackup = JSON.parse(JSON.stringify(this.filterFieldsData));

        // if (!this.avoidInitRefresh) {
        this.refresh();
        // }

        let me = this;

        //for search
        this.events.subscribe(this.prefix + '_updateFilter', function (filters) {
            if (filters['search']) {
                me.filterFieldsData['search'] = filters['search'];
            }
            if (filters['ordering'] && me.filterFieldsData['ordering'] == filters['ordering']) {
                me.filterFieldsData['ordering'] = "-" + filters['ordering'];
            } else if (filters['ordering']) {
                me.filterFieldsData['ordering'] = filters['ordering'];
            }

            me.page = 1;

            me.refresh();
        });

        this.events.subscribe('exprt_report_' + this.prefix, function (filters) {
            ////console.log'export!');
            me.exportReport();
        });

        this.events.subscribe('refresh_request_' + this.prefix, function (filters) {

            me.refresh();
            return;
            me.modelTools.presentToast("Loading..");

            let keepFilters = filters['keepFilters'];
            if (!keepFilters) keepFilters = [];

            ////console.logfilters);

            if (filters['clearFilters']) {
                let oldFilters = me.filterFieldsData;
                me.filterFieldsData = {};
                let backupFilters = JSON.parse(JSON.stringify(me.filterFieldsDataBackup));
                for (let key in backupFilters) {
                    if (keepFilters.indexOf(key) == -1) {
                        me.filterFieldsData[key] = backupFilters[key];
                    } else {
                        me.filterFieldsData[key] = oldFilters[key];
                    }
                }
            }

            if (filters['ordering'] && me.filterFieldsData['ordering'] == filters['ordering']) {
                filters['ordering'] = '-' + filters['ordering'];
            }
            //console.logfilters);

            for (let key in filters) {
                me.filterFieldsData[key] = filters[key];
            }

            me.page = 1;

            me.refresh();
        });
    }

    exportReport() {

        let filters = {};
        for (let key in this.filterFieldsData) {
            if (typeof this.filterFieldsData[key] == 'object') {
                filters[key] = this.filterFieldsData[key].join(',')
            } else {
                filters[key] = this.filterFieldsData[key];
            }
        }
        this.modelProvider.download('export', filters);
    }

    refresh(page = null) {
        if (page) {
            this.page = page;
        }

        this.onloading = true;

        let filters = {};
        for (let key in this.filterFieldsData) {
            if (typeof this.filterFieldsData[key] == 'object') {
                filters[key] = this.filterFieldsData[key].join(',')
            } else {
                filters[key] = this.filterFieldsData[key];
            }
        }
        filters['limit'] = this.rowsPerPage;
        filters['offset'] = this.rowsPerPage * (this.page - 1);

        let me = this;
        this.modelProvider.list(this.modelName, filters)
            .then(function (res: any) {
                ////console.log'result of request:', me.modelName);
                ////console.logres);
                me.setModels(res);
            });
    }

    setModels(res) {
        let me = this;
        me.totalCount = res.count;
        me.models = res.models;

        me.goUpdeted();
        me.onloading = false;

        //paging
        me.totalPages = Math.ceil(me.totalCount / me.rowsPerPage);

        me.countRange = [];
        for (let i = 1; i <= me.totalPages; i++) {
            me.countRange.push(i);
        }

        if (me.countRange.length > 7) {
            if (me.page > 7) {
                me.countRange.splice(0, me.countRange.indexOf(me.page) - 2)
            }
            me.countRange.splice(me.countRange.indexOf(me.page) + 4, me.countRange.length);
        }
        me.countRange = me.countRange.reverse();

        me.modelTools.presentToast("Done..");
    }

    showFilter(filterField = null) {
        this.showFilterOn = !this.showFilterOn;

        if (this.showFilterOn) {
            let me = this;
            this.modelProvider.list('report', {'type': this.filterPref}).then(function (res: any) {
                ////console.logres);
                me.reports = res.models;
            })
        }
    }

    // refresh() {
    //     this.events.publish('refresh_' + this.modelName);
    // }

    pageGo(page) {
        if (page > 0 && page <= this.totalPages) {
            this.page = page;
        }
        this.refresh();
    }

    setFilterData(filterKey, val) {
        ////console.logthis.filterFieldsData);
        ////console.logfilterKey);

        if (this.filterFieldsData[filterKey].indexOf(val) != -1) {
            this.filterFieldsData[filterKey].splice(this.filterFieldsData[filterKey].indexOf(val), 1);
        } else {
            this.filterFieldsData[filterKey].push(val);
        }
    }

    goUpdeted() {
        ////console.log'TO TARRIF - dispatch update_' + this.prefix);
        this.events.publish('update_' + this.prefix, {'data': this.filterFieldsData, 'models': this.models});
        this.events.publish('updatetaarif_' + this.prefix, {'data': this.filterFieldsData, 'models': this.models});
    }

    chooseReport(id) {
        if (id == -1) {
            this.filterFieldsData = JSON.parse(JSON.stringify(this.filterFieldsDataBackup));
        }

        this.filterFieldsData['report'] = id;
        this.refresh(0);
    }

}
