import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {NavController, PopoverController} from "@ionic/angular";
import {ModelProvider} from "../../providers/modelProvider/ModelProvider";
import {NavigationExtras} from "@angular/router";
import {BusinessService} from "../../../core/services/BusinessService";
import {Events} from "../../providers/events";
import {ModelToolsProvider} from "../../providers/model-tools/model-tools";
import {AdminService} from "../../../core/services/AdminService";
import {OrderService} from "../../../core/services/OrderService";
import {MainService} from "../../../core/services/MainService";

@Component({
    selector: 'app-pages',
    templateUrl: './pages.component.html',
    styleUrls: ['./pages.component.scss'],
})
export class PagesComponent implements OnInit, OnDestroy {
    @Input()
    reportModels;

    @Input()
    vertical;

    @Input()
    miniDisplay = true;
    displaySize = 'small';

    @Input()
    sideBoxMode;
    @Input()
    exTableData;

    //forceDisplay - show this buttons automatically
    @Input()
    forceDisplay;
    @Input()
    buttonsType;

    @Input()
    showTitle = true;
    @Input()
    title;
    @Input()
    topSection;
    @Input()
    topModelId;
    @Input()
    sectionName;
    @Input()
    tableParams: any;
    @Input()
    prefix;
    @Input()
    report;
    @Input()
    extraData = {};
    @Input()
    baseFilter = {};
    @Input()
    extraSaveFilters = {};
    @Input()
    filters;
    @Input()
    inEditMode;

    @Input()
    sideShowModel;

    //on extenral field from other model
    @Input()
    fieldType;
    @Input()
    fieldData;

    searchWaitTimeout;

    ready = true;

    @Input()
    type;

    searchText = '';

    totalCount;

    totalPages;
    countRange;
    page = 1;
    rowsPerPage = 50;

    @Input()
    showSettingsButton = false;

    @Input()
    showPages = false;

    showSearch = false;
    showSettings = true;
    showFilters = true;

    showFilterOn = false;
    filterOptionsData;
    quickAddModel;
    customFilter = {};

    @Input()
    hideFilters = false;
    @Input()
    tableFilters;

    //'map', 'calendar', 'calculator',
    @Input()
    showButtons = ['title', 'save', 'trash', 'search', 'add', 'refresh', 'settings', 'edit', 'adminData',
        'refresh', 'cube', 'expand',
        'funnel', 'sideFilterTable', 'search',
        'paste', 'copy', 'tableDesc', 'customTableMarkAction', 'question'
    ];

    displayType = 'table';

    onloading = false;
    tableData;
    models;
    reports;
    needSave;
    filterTab;

    //show filters
    funnelFilters = false;
    showCalc = false;
    showAdd = false;
    showTabInfo = false;

    //select on side table
    lastSelectedModel;

    //if split field is selected. will split table by this selected field.
    splitField;
    adminData;
    selectedFilter;

    //if on relation table - set the name of the field to add from external check
    relationTableAutoAddFields = [];

    staticFilterFields;

    constructor(public events: Events,
                public adminService: AdminService,
                public mainService: MainService,
                public modelTools: ModelToolsProvider,
                public modelProvider: ModelProvider,
                public orderService: OrderService,
                public businessService: BusinessService,
                public navCtrl: NavController, public popoverCtrl: PopoverController) {
    }

    ngOnInit() {

        if (this.sideShowModel) {
            this.loadSideBoxModel(this.sideShowModel)
        }

        // //console.log'pages!');
        // //console.logthis.sectionName);
        // //console.logthis.exTableData);
        // this.models = this.exTableData[this.sectionName];
        ////console.logthis.models);
        if (!this.miniDisplay) {
            this.displaySize = 'large';
        }

        if (this.showSettingsButton) {
            this.showSettings = false;
        } else if (this.businessService.screenSize[0] > 1000) {
            this.showSettings = true;
        }

        this.tableData = this.exTableData[this.sectionName + 'Data'];


        if (this.tableData['staticFilterFields']) {
            this.staticFilterFields = this.tableData['staticFilterFields'];
            //'field':extraData['field'], 'selected':option});closeWindow();">)
            this.events.subscribe('chooseFilter_' + this.prefix, (data) => {
                let field = data['field'];
                let selected = data['selected'];
                this.staticFilter(field, selected);
            });
        }
        if (!this.tableData) {
            return;
        }
        if (!this.tableParams) {
            this.tableParams = this.tableData;
        }

        //auto open filter
        // if (this.tableData['autoOpenFilter'] && this.businessService.screenSize[0] > 500) {
        //     this.filterTab = this.tableData['autoOpenFilter'];
        //     this.buttonClick('sideFilter', {'name': this.filterTab, 'filterTab': this.filterTab});
        // }

        // this.totalCount = this.reportModels['totalCount'];

        this.events.subscribe('showloading_' + this.prefix, (data) => {
            this.onloading = true;
        });
        this.events.subscribe('hideloading_' + this.prefix, (data) => {
            this.onloading = false;
        });

        this.events.subscribe('update_' + this.prefix, (data) => {
            me.onloading = false;
            this.needSave = false;

            //////console.log'update!!!');

            // this.showFilters = false;
            // setTimeout(function () {
            //     me.showFilters = true;
            // });
        });
        // this.events.subscribe('onTaarifUpdate', (tableData) => {
        //
        //     this.needSave = false;
        //
        //     ////console.log'ot pages update!');
        //     ////console.logtableData);
        //
        //     this.onloading = false;
        //     this.exTableData = tableData;
        //     ////console.logthis.exTableData[this.sectionName + 'Data']);
        //
        //     //////console.log'update!!!', this.sectionName);
        //     if (this.exTableData[this.sectionName + 'Data']['filterOptionsData']) {
        //         this.filterOptionsData = this.exTableData[this.sectionName + 'Data']['filterOptionsData'];
        //     }
        //
        //     this.totalCount = this.exTableData[this.sectionName + 'Data']['totalCount'];
        //     this.totalPages = Math.ceil(this.totalCount / this.rowsPerPage);
        //     this.setPages();
        //
        //     this.showFilters = false;
        //     setTimeout(function () {
        //         me.showFilters = true;
        //     });
        // });


        this.events.subscribe('toSideFilterMode' + this.prefix, (active) => {
            this.filterTab = active;

            //close settings.
            // if (active) {
            //     this.showSettings = false;
            //     this.sideBoxMode = true;
            // } else {
            //     this.sideBoxMode = false;
            // }

        });

        this.filterOptionsData = {};
        // for (let fieldKey in this.tableParams['filterOptions']) {
        //     this.filterOptionsData[fieldKey] = this.tableParams['filterOptions'][fieldKey];
        // }
        if (this.exTableData[this.sectionName + 'Data']['filterOptionsData']) {
            this.filterOptionsData = this.exTableData[this.sectionName + 'Data']['filterOptionsData'];
        }

        let me = this;
        this.events.subscribe('onModelsUpdate' + this.prefix, (tableData) => {
            this.onModelsUpdate(tableData[me.sectionName + 'Data']);
        });

        this.events.subscribe('rowCLick' + this.prefix, (tableData) => {
            this.needSave = true;
        });

        if (!this.forceDisplay) {
            if (this.tableData['forceDisplay']) {
                this.forceDisplay = this.tableData['forceDisplay'];
            }
            if (this.tableData['showMap']) {
                this.showButtons.push('map');
            }
            if (this.tableData['showCalendarButton']) {
                this.showButtons.push('calendar');
            }
        }

        if (this.forceDisplay) {
            this.showButtons = [];
            for (let k of this.forceDisplay) {
                this.showButtons.push(k);
            }
        }

        if (!this.adminService.manageModels['permissionTablesAdd']) {
            this.showButtons = [];
        } else if (this.adminService.manageModels['permissionTablesAdd'].indexOf(this.sectionName) == -1) {
            this.showButtons.splice(this.showButtons.indexOf('add'), 1);
        }
        // else if (this.adminService.manageModels['permissionTablesRemove'].indexOf(this.sectionName) == -1) {
        //     this.showButtons.splice(this.showButtons.indexOf('remove'), 1); //not working yet..
        // }

        //
        // {{topSection}}
        // {{tableData['relationTable']['sideFilterTable1']}}
        // <ion-button *ngIf="tableData['relationTable'] && topSection && adminService.manageModels[sectionName + 'MarkData']">
        // console.log('this.topSection:', this.topSection)
        // console.log(this.sectionName)
        // console.log(this.tableData)
        // console.log('this.topSection:', this.tableData['relationTable'])
        if (this.tableData['externalAutoAddTable']) {
            this.relationTableAutoAddFields = this.tableData['externalAutoAddTable'];
            // else if (this.tableData['relationTable']['sideFilterTable1'] == this.topSection) {
            //     this.relationTableAutoAddField = this.tableData['relationTable']['sideFilterTable2']
            // } else {
            //     this.relationTableAutoAddField = this.tableData['relationTable']['sideFilterTable1']
            // }
            // console.log('this.relationTableAutoAddField')
            // console.log(this.relationTableAutoAddField)
        }

        this.totalCount = this.reportModels && this.reportModels['totalCount'] ? this.reportModels['totalCount'] : this.exTableData[this.sectionName + 'Data']['totalCount'];

    }

    ngAfterViewInit() {
    }

    addRelation(tableKey) {
        let me = this;
        let filters = {}
        me.adminService.createRelationModels(this.sectionName,
            this.topModelId,
            tableKey,
            this.adminService.manageModels[tableKey + 'MarkData']['markedRow'], this.exTableData).then(res => {
            this.refresh();
            // this.adminService.manageModels[tableKey + 'MarkData']['markedRow'] = [];
        })
    }

    //
    save() {
        // this.buttonClick('save');
        this.adminService.globalSave();
    }

    getAdminData() {
        let me = this;

        if (me.adminData) {
            me.adminData = null;
            return
        }

        let filters = this.adminService.getMainFilters();
        filters['getModelTopReport'] = this.sectionName;
        this.modelProvider.list('report', filters)
            .then(function (data: any) {
                me.adminData = data['models'][0].data['finalResReport']['reportData'];
            });
    }

    async presentSelectBar(myEvent, type, bigPopup = false) {
        this.adminService.popupEditBoxByData(myEvent, {
            'prefix': this.prefix,
            'exTableData': this.exTableData,
            'sectionName': this.sectionName,
            'type': type
        }, bigPopup);

        // this.buttonClick(type);
        // const popover = await this.popoverCtrl.create({
        //     component: SelectBarComponent,
        //     componentProps: {
        //         'prefix': this.prefix,
        //         'exTableData': this.exTableData,
        //         'sectionName': this.sectionName,
        //         'type': type
        //     },
        //     event: myEvent,
        //     animated: true,
        //     showBackdrop: true,
        //     cssClass: 'note-popover icenter-popover smaller-popover'
        // });
        // return await popover.present();
    }

    ngOnDestroy(): void {
        this.events.unsubscribe('onModelsUpdate' + this.prefix);
        this.events.unsubscribe("onTaarifUpdate");
        this.events.unsubscribe('update_' + this.prefix);
        this.events.unsubscribe('showloading_' + this.prefix);
        this.events.unsubscribe('hideloading_' + this.prefix);
        if (this.tableData['staticFilterFields']) {
            this.events.unsubscribe('chooseFilter_' + this.prefix);
        }
    }

    onModelsUpdate(tableData) {
        let me = this;
        this.totalCount = tableData['totalCount'];
        me.totalPages = Math.ceil(me.totalCount / me.rowsPerPage);
        this.setPages();

        this.tableData = tableData;
        if (!this.quickAddModel) this.quickAddModel = this.adminService.getNewModel(this.sectionName, this.exTableData);

        if (this.tableData['autoOpenFilters']) {
            this.funnelFilters = true;
        }

        // this.ready = false;
        // setTimeout(function () {
        me.ready = true;
        // });
    }

    setPages() {
        let me = this;
        me.countRange = [1];
        let i = this.page > 2 ? this.page - 1 : 2;
        while (i < this.totalPages - 1) {
            me.countRange.push(i);
            i++;
            if (me.countRange.length > 4) {
                break;
            }
        }
        me.countRange.push(this.totalPages);


        // for (let i = 1; i <= me.totalPages; i++) {
        //     me.countRange.push(i);
        // }
        //
        // if (me.countRange.length > 7) {
        //     if (me.page > 7) {
        //         me.countRange.splice(0, me.countRange.indexOf(me.page) - 2)
        //     }
        //     me.countRange.splice(me.countRange.indexOf(me.page) + 4, me.countRange.length);
        // }
        // me.countRange = me.countRange.reverse();
    }

    refresh() {
        this.onloading = true;
        this.events.publish('refresh_request_' + this.prefix, {});
    }

    pageGo(i) {
        //console.log'change page: '+ i);

        // this.onloading = true;
        this.page = i;

        // let filters = {};
        // filters['sectionName'] = this.tableParams['modelPrefix'];
        // filters[this.sectionName + 'modelOffset'] = (this.page - 1) * this.rowsPerPage;
        // filters[this.sectionName + 'modelLimit'] = this.rowsPerPage;

        // this.events.publish('refresh_request_' + this.prefix, filters);
        this.events.publish("btnclick_" + this.prefix, {'key': 'addFilter', 'data': {'page': this.page}});

        this.setPages();
    }

    showFilter() {
        this.showFilterOn = !this.showFilterOn;

        // if (this.showFilterOn) {
        //     let me = this;
        //     this.modelProvider.list('report', {'type': this.filterPref}).then(function (res: any) {
        //         //////console.logres);
        //         me.reports = res.models;
        //     })
        // }
    }

    chooseReport(key) {
        let filters = {};
        if (key == -1) {
            filters = {
                'modelFilterId': -1,
                'filterOptionsData': JSON.stringify({}),
                'sectionName': this.tableParams['modelPrefix']
            };

        } else if (key == 'go') {
            filters = {
                'modelFilterId': -1,
                'filterOptionsData': JSON.stringify(this.filterOptionsData),
                'sectionName': this.tableParams['modelPrefix']
            };
        } else {
            filters = {
                'modelFilterId': key,
                'sectionName': this.tableParams['modelPrefix']
            };
        }

        this.onloading = true;

        this.buttonClick('customFilter', filters);
        // this.events.publish('refresh_request_' + this.prefix, filters);
    }

    buttonClick(key, data = null, areYouSureAlert = false) {
        let me = this;
        if (areYouSureAlert && (this.sectionName == 'appUser' || this.sectionName == 'company')) {
            this.modelTools.yesOrNoAlert(function () {
                setTimeout(function () {
                    me.events.publish("btnclick_" + me.prefix, {'key': key, 'data': data});
                    me.events.publish("btnclickAdmin_" + me.prefix, {'key': key, 'data': data});
                });
            });
        } else {
            me.events.publish("btnclick_" + me.prefix, {'key': key, 'data': data});
            me.events.publish("btnclickAdmin_" + me.prefix, {'key': key, 'data': data});
        }
    }

    chooseFilter(selectedFilter) {
        if (selectedFilter['clear']) {
            this.events.publish("btnclick_" + this.prefix, {
                'key': 'addFilter',
                'data': {'clear': true}
            });
            this.selectedFilter = null;
        } else {
            let filters = {};
            filters['filterId'] = selectedFilter['id']['value'];
            this.events.publish("btnclick_" + this.prefix, {
                'key': 'addFilter',
                'data': {'fieldFilters': filters}
            });
        }
    }

    staticFilter(field, option) {
        let f = {}
        f[field['name']] = option['id'];
        field['selected'] = option;
        this.customFilter[field['name']] = option['id'];
        let d = {'custom': f}
        this.buttonClick('addFilter', d);
    }

    searchWait() {
        console.trace();
        if (!this.searchText || this.searchText.length <= 1) {
            return;
        }

        clearTimeout(this.searchWaitTimeout);
        console.log('start search 11s')

        let me = this;
        this.searchWaitTimeout = setTimeout(function () {
            me.search();
        }, 3000);
    }

    search() {
        let filters = {};
        this.page = 1;
        // filters[this.sectionName + 'modelOffset'] = 0;
        // filters[this.sectionName + 'modelLimit'] = this.rowsPerPage;
        // filters['sectionName'] = this.tableParams['modelPrefix'];
        // filters['modelPrefix'] = this.tableParams['modelPrefix'];
        filters['searchText'] = this.searchText;
        // filters['offset'] = 0;

        //////console.logthis.searchText);
        //////console.logthis.prefix);
        //////console.logfilters);
        this.buttonClick('search', filters);

        // this.setPages();
        // this.events.publish('refresh_request_' + this.prefix, filters);
    }

    openCustomModel(sectionName, rowIndex = null, baseFilter = null, extraFilters = null) {
        console.log('open custom model: ', sectionName);
        console.log(baseFilter)
        let params = {
            'sectionName': sectionName,
            'rowIndex': rowIndex,
            'baseFilter': baseFilter,
            'extraFilters': extraFilters,
            'extraSaveFilters': this.extraSaveFilters,
        };

        let navigationExtras: NavigationExtras = {
            queryParams: params
        };

        this.navCtrl.navigateForward('admin-model/' + this.sectionName + rowIndex, navigationExtras);
    }

    //??? not done here..
    customTableAction(tableData) {
        if (tableData['type'] == 'searchDevice') {
            this.navCtrl.navigateForward('searchDevice');
        }
        if (tableData['type'] == 'callServer') {
            this.modelProvider.custom(tableData['requestPrefix']).then((data: any) => {
                //console.log'its back!');
            });
        }
    }

    getSelectTableFields(fields) {
        return fields.filter(field => field['type'] == 'selectTable');
    }

    loadSideBoxModel(sideShowModel) {
        let keys = ['menu', 'prefix', 'menu', 'prefix', 'fieldData', 'fieldType', 'forceDisplay', 'hideFilters',
            'topSection', 'topModelId', 'showSettingsButton',
            'extraSaveFilters', 'sectionName', 'baseFilter',
            'exTableData', 'filters', 'tableParams', 'reportModels']
        for (let k of keys) {
            this[k] = sideShowModel[k];
        }

    }

    getSideBoxModel() {
        return {
            'menu': 'tableMenu',
            'prefix': this.prefix,
            'fieldData': this.fieldData,
            'fieldType': this.fieldType,
            'forceDisplay': this.forceDisplay,
            'hideFilters': this.hideFilters,
            'topSection': this.topSection,
            'topModelId': this.topModelId,
            'showSettingsButton': this.showSettingsButton,
            'extraSaveFilters': this.extraSaveFilters,
            'sectionName': this.sectionName,
            'baseFilter': this.baseFilter,
            'exTableData': this.exTableData,
            'filters': this.filters,
            'tableParams': this.tableParams,
            'reportModels': this.reportModels
        }
    }

}