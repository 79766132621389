import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {Routes, RouterModule} from '@angular/router';

import {IonicModule} from '@ionic/angular';

import {OrderPage} from './order.page';
import {GlobalModule} from "../../../app/global-module/global-module.module";
import {OrderContentComponent} from "./components/order-content/order-content.component";
import {OrdersPage} from "../orders/orders.page";

const routes: Routes = [
    {
        path: '',
        component: OrderPage
    }
];

@NgModule({
    imports: [
        GlobalModule,
        CommonModule,
        FormsModule,
        IonicModule,
        RouterModule.forChild(routes)
    ],
    declarations: [OrderPage]
})
export class OrderPageModule {
}
