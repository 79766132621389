import {Component, Input, OnInit} from '@angular/core';
import {ModelToolsProvider} from "../../../../framework/providers/model-tools/model-tools";
import {AppDataService} from "../../../services/AppDataService";
import {ItemProvider} from "../../../pages/item/providers/item-provider/item-provider.service";
import {MainService} from "../../../services/MainService";
import {AppUser} from "../../../../framework/model/core/appuser/appuser";
import {BusinessService} from "../../../services/BusinessService";
import {OrderService} from "../../../services/OrderService";
import {Events} from "../../../../framework/providers/events";
import * as moment from "moment";

@Component({
    selector: 'app-panel-element',
    templateUrl: './panel-element.component.html',
    styleUrls: ['./panel-element.component.scss'],
})
export class PanelElementComponent implements OnInit {

    @Input()
    public model;

    @Input()
    public appuser: AppUser;

    counterBack;

    constructor(public modelTools: ModelToolsProvider, public appDataService: AppDataService, public itemProvider: ItemProvider,
                public mainService: MainService, public events: Events, public business: BusinessService) {
    }

    ngOnInit() {
        if (this.model.data.type == 'countDown') {
            this.setCountDownTimer();
        }

        //when getting data from server. options is not a json.
        if (this.model.data.options && typeof (this.model.data.options) != 'object')
        {
            this.model.data.options = JSON.parse(this.model.data.options);
        }
    }

    setCountDownTimer() {
        if (this.model.data.options && this.model.data.options['time']) {
            let me = this;
            //"20/2/2022 10:00"
            console.log(this.model.data.options['time'])
            let dueDate = moment(this.model.data.options['time'], "DD/MM/YYYY HH:mm");
            setInterval(function () {
                let now = moment()
                let counterBack = dueDate.diff(now, 'seconds');
                console.log(counterBack)
                me.counterBack = moment(counterBack * 1000);
                console.log(me.counterBack.format("DD/MM/YYYY HH:mm"));
            }, 1000);
        }
    }

    agentKeyboard(model) {
        this.modelTools.goTo('main-agents');
    }

    openPanel(model) {
        let type = model.data.type;

        let options = model.data.options ? model.data.options : {}
        let params =  options['params'] ? options['params'] : {}
        let eventKey =  options['eventKey'] ? options['eventKey'] : null

        if (eventKey) {
            this.events.publish(eventKey, params);
            return
        }

        if(options['switchToApp']){
            this.mainService.switchApp(options['switchToApp'])
            return;
        }

        if (model.data.page) {

            if (model.data.contentTypeName == 'item' || model.data.page == 'item') {
                this.openItem(model.data.objectId);
                return;
            }

            let url = model.data.page;

            if (url == 'register') {
                this.mainService.goToRegister();
                return
            }
            else if (url == 'taarif') {
                this.events.publish('showAdminMenu');
                return;
            }
            else if (url == 'new-item') {
                this.newItem();
                return
            }

            if (model.data.objectId)
                url += '/' + model.data.objectId;


            this.modelTools.goTo(url, params);
        }

        if (type == 'search-box') {
            this.modelTools.goTo('search', {});
            return
        }

    }

    openItem(itemId) {
        let item = this.appDataService.getItemModel(itemId);
        this.itemProvider.openItem(item);
    }

    newItem() {
        if (!this.appuser) {
            this.mainService.handelLogin();
            return
        }

        this.mainService.showFormsModal({
            'keys': ['new-item', 'item-image', 'new-item-note'],
        })
    }

}
