import { Component, OnInit } from '@angular/core';

export class Report {
    data = {'title': null, 'objectId': null, 'contentTypeName': null};

    constructor(public data1:any)
    {
        this.data = data1;
    }

    frameworkModel = true;
    prefix = "report";
    icon = "paper";
    title = "Report";
    editType = 'window';
    defaultHeaders =
        ['name', 'queryStr'];

    dataFields = {
        id: {'title': 'ID', 'value': null, 'type': 'number', 'readonly': true},
        created: {'title': 'Created', 'value': null, 'type': 'date', 'readonly': true},
        updated: {'title': 'Updated', 'value': null, 'type': 'date', 'readonly': true},
        prefix: {'title': 'Prefix', 'value': null, 'type': 'text', 'required': true},
        name: {'title': 'Name', 'value': null, 'type': 'text', 'required': true},
        description: {'title': 'Description', 'value': null, 'type': 'textarea'},
    }
}