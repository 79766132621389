import {AppConfig} from "./main";

export const BisFields = {
    'store': {
        'view': {'key': 'menuType', 'value': 'cards'},
        'quick-nav': {'key': 'quickNav', 'value': false},
    },
    'cart': {
        'toDate': {'key': 'ordersToDate'}
        // 'paymentTypes:': {'key': 'paymentTypes', 'value': null},
    },
    'items': {
        'properties_icon': {'key': 'propertiesIcon', 'skipNone': true}
        // 'paymentTypes:': {'key': 'paymentTypes', 'value': null},
    },
    'login':
        {
            'type': {
                'key': 'registerType',
                'value': 'page', // default
                'options': {'b2b': 'page', 'b2c': 'modal', 'forms': 'forms'}
            }
        },
    'register':
        {
            'type': {
                'key': 'registerType',
                'value': 'page', // default
                'options': {'b2b': 'page', 'b2c': 'modal', 'forms': 'forms'}
            }
        },
}

export const bis_settings:any = {}

export class AppSettings {

    settings = {
        app: {
            'regular':
                {
                    'cart-icon': 'cart',
                    'background-pages': ['main']

                },
            'licenes':
                {
                    'cart-icon': 'cart',
                    'background-pages': ['main'],

                    'after_login_page': 'profile'

                },
            'moving':
                {
                    'cart-icon': 'car',
                    'agentsPage': true,
                    'init-login': true,
                    'background-pages': ['main']
                },
            'rest':
                {
                    'cart-icon': 'cart',
                    'background-pages': ['main', 'store', 'section', 'cart', 'cart-details']
                },
            // 'licenes':
            //     {
            // 'cart-icon': 'cart',
            // 'background-pages': ['main']
            // },
            'oneflow':
                {
                    'cart-icon': 'cart',
                    'init-login': false,
                    'background-pages': ['main']
                }
        },

        'side-menu': {
            'regular':
                {
                    'pages': ['all'],
                },
            'moving':
                {
                    'pages': ['all'],
                },
            'rest':
                {
                    'pages': ['mainPage', 'store', 'taarif', 'dashboard', 'reports', 'cart', 'myProfile', 'myOrders', 'usersManager', 'sales', 'about', 'businessManager', 'contact']
                },
            'oneflow':
                {
                    'pages': ['mainPage', 'store', 'taarif', 'dashboard', 'reports', 'cart', 'myProfile', 'myOrders']
                },
            'licenes':
                {
                    'pages': ['all']
                }
        },

        'main': {
            'regular': {
                'navButtons': ['store', 'sales'],
                'makers': true
            },
            'moving': {
                'navButtons': ['store', 'sales'],
                'makers': true
            },
            'rest': {
                'navButtons': ['store', 'logo', 'about'],
                'makers': true
            },
            // 'licenes': {
            // 'navButtons': ['store', 'logo', 'about'],
            // 'makers': true
            // },
            'oneflow': {
                'makers': false
            }
        },

        'items': {
            'regular': {
                'showOptions': true,
                'details': 'page',
                'keyboard': true,
                'properties_icon': 'options',
                'footer-options': false,
                'small_view_str': '_regular',
            },
            'moving': {
                'showOptions': true,
                'details': 'page',
                'keyboard': true,
                'hide_prices':true,
                'hide_stock':true,
                'cartItemImages': true,
                'properties_icon': 'options',
                'footer-options': false,
            },
            'rest': {
                'showOptions': true,
                'view': 'rest',
                'details': 'modal',
                'description': 'true',
                'properties_icon': 'restaurant',
                'footer-options': false,
            },
            'licenes': {
                'showOptions': true,
                'details': 'page',
                'keyboard': true,
                'properties_icon': 'options',
                'footer-options': false,
                'small_view_str': '_regular',
                'coupon_generator': true,
                'hide_sort':true
            },
            // 'licenes': {
            // 'showOptions': true,
            // 'details': 'page',
            // }
        },

        'store': {
            'regular': {
                'quick-nav': true,
                'view': 'cards',
            },
            'moving': {
                'quick-nav': true,
                'view': 'cards',
            },
            'rest': {
                'quick-nav': false,
                'view': 'list',
            },
            // 'licenes': {
            //     'quick-nav': true,
            //     'view': 'cards',
            // }
        },

        'cart': {
            'regular': {
                'chooseType': false,
                'shipping-address': true,
                'useTerms': true,
                'paymentTypes': [],
                'fields': ['contactMan', 'email', 'cellphone', 'pickup', 'doc_id', 'carrier_code', 'comments'],
                'agentFields': ['driverComments', 'discount']

            },
            'oneflow': {
                'chooseType': false,
                'shipping-address': false,
                'useTerms': false,
                'paymentTypes': [],
                'fields': ['contactMan', 'email', 'cellphone', 'comments'],
                'btnTitleOnAction': true,
                'shippingReportPriceEdit': true

            },
            'moving': {
                'chooseType': false,
                'useTerms': false,
                'forms': ['from', 'fromDetails', 'to', 'toDetails', 'orderDetails'],
                'fields': ['contactMan', 'email', 'cellphone', 'comments', 'pickup'],
                'secContactMan': ['secContactMan', 'secCellphone', 'secEmail', 'secClose'],
                'paymentTypes': ['cash', 'creditCard', 'check'],
                'toolbar-images-upload': true,
                'showCartActionOptions': true,
                'skipSummaryPage': true
            },
            'rest': {
                'chooseType': true,
                'useTerms': true,
                'paymentTypes': ['cash', 'creditCard'],
                'fields': ['contactMan', 'email', 'cellphone', 'comments'],
                'hide-keyboard': true,
            },
            // 'licenes': {
            //     'chooseType': false,
            //     'useTerms': true,
            //     'fields': ['contactMan', 'email', 'cellphone', 'comments'],
            // },
            'shifts': {
                'chooseType': false,
                'useTerms': true,
                'allowNoPrice': true,
                'fields': ['contactMan', 'email', 'cellphone', 'comments'],
            }
        },

        //'birthdate',
        'profile': {
            'regular': {
                'client-invoice': true,
                'stock': true,
                'fields': ['name', 'cellphone', 'username'],
                'companyFields': ['companyName', 'internalCompanyId', 'companyFileNumber', 'email'],
                'company': true,

            },
            'moving': {
                'client-invoice': false,
                'fields': ['name', 'cellphone', 'birthdate', 'username'],
                'companyFields': ['companyName', 'companyFileNumber', 'email'],
                'company': true,
            },

            'rest': {
                'client-invoice': false,
                'fields': ['name', 'cellphone', 'notfToEmail'],
                'company': false,
            },

            'oneflow': {
                'addCompanyMember': true,
                'fields': ['name', 'cellphone', 'username'],
                'companyFields': ['companyName', 'companyFileNumber', 'email'],
                'company': true,
            },

            'licenes': {
                'client-invoice': true,
                'stock': false,
                'fields': ['name', 'cellphone', 'username'],
                'companyFields': ['companyName', 'internalCompanyId', 'companyFileNumber', 'userCode', 'email'],
                'company': true,
                // 'client-invoice': false,
                // 'fields': ['name', 'cellphone', 'username', 'qrCode', 'bisType', 'bisSubType', 'bisTypeOther', 'exLink', 'clientsDiscount',
                //     'paymentVerified', 'licenseExpiration', 'licenseApproved', 'comments'],
                // 'companyFields': ['companyName', 'companyFileNumber'],
                // 'company': true,
                'checkPayment': true,
                'imageRequired': true,
                //
                // 'note': {
                //     'modelKey': 'user',
                //     'fields': ['joinUs'],
                //     'title': 'pickizSite',
                //     'noteKey': 'register-form',
                // },

            }
        },

        'orders': {
            'regular': {
                'fields': ['all'],
            },
            'oneflow': {
                'fields': ['companyName', 'userName', 'orderExtId', 'extBusinessName', 'orderAdress', 'extorder', 'itemCount', 'finalPrice']
            },
            'moving': {
                'fields': ['companyName', 'userName', 'orderExtId', 'orderAdress', 'extorder', 'itemCount', 'finalPrice'],
            },
            'rest': {
                'fields': ['userName', 'orderAdress', 'itemCount', 'finalPrice'],
            },
            'licenes': {
                'fields': ['all'],
            },
            'shifts': {
                'fields': ['all'],
                'shift': true,
                'includeItems': true,
                'includeStatuses': true,
            }

        },

        'order': {
            'regular': {
                'fields': ['all'],
                'loadOrder': ['orderEdit', 'bid', 'pickup', 'delivery', 'storagePickup', 'storageDelivery'],
            },
            'oneflow': {
                'fields': ['id', 'created', 'companyName', 'userName', 'cellphone', 'orderAdress', 'toDate',
                    'userEmail', 'status', 'orderExtId', 'extBusinessName', 'internalCompanyId', 'paymentVerified', 'paymentId', 'action', 'extorder', 'comments'],
                'loadOrder': ['shippingReport'],

                'shippingReportOptions': ['askForPromotion', 'orderRestItems'],
                'lastActions': ['promotionRequest'],
                'loadOrderAlsoNotStaff': true,
            },
            'moving': {
                'fields': ['id', 'created', 'companyName', 'userName', 'cellphone', 'orderAdress', 'toDate',
                    'userEmail', 'status', 'internalCompanyId', 'paymentVerified', 'paymentId', 'action', 'extorder',
                    'secContactMan', 'secCellphone', 'secEmail', 'secClose', 'comments'],
                'forms': ['from', 'fromDetails', 'to', 'toDetails', 'orderDetails'],
                'loadOrder': ['bid', 'pickup', 'delivery', 'storagePickup', 'storageDelivery'],
            },
            'rest': {
                'fields': ['id', 'created', 'userName', 'cellphone', 'type', 'pickupBranchName', 'orderAdress', 'status', 'toDate', 'paymentVerified', "paymentMethod", 'paymentId', 'comments'],

            },
            'licenes': {
                'fields': ['all'],

            }
        },

        'login':
            {
                'regular': {
                    'type': 'page'
                },
                'oneflow': {
                    'type': 'page',
                    'block': true,
                },
                'moving': {
                    'type': 'page',
                    'block': true,
                },
                'rest': {
                    'type': 'modal'
                },
                'licenes': {
                    'type': 'page'
                }
            },

        'register':
            {
                'regular': {
                    'type': 'page',
                    'appuserKeys': ['name', 'cellphone', 'username', 'password', 'confirmPassword'],
                    'companyKeys': ['companyName', 'companyFileNumber'],
                },
                'oneflow': {
                    'type': 'page',
                    'appuserKeys': ['name', 'cellphone', 'username', 'password', 'confirmPassword', 'businessCode'],
                    'companyKeys': ['companyName', 'companyFileNumber'],
                },
                'moving': {
                    'type': 'page',
                    'formKeys': ['user', 'company', 'from', 'fromDetails', 'to', 'toDetails', 'orderDetails'],
                },
                'rest': {
                    'type': 'modal'
                },
                // 'licenes': {
                //     'type': 'forms',
                //     'formKeys': ['user', 'company', 'from', 'profileImage', 'note']
                // }
            },
        'forms':
            {
                'regular': {
                    'user': {
                        'fields': ['companyName', 'companyFileNumber', 'name', 'cellphone', 'username', 'password', 'confirmPassword']
                    },
                },
                'moving': {
                    'user': {
                        'fields': ['name', 'cellphone', 'username', 'password', 'confirmPassword', 'clientType'],
                    },
                    'company': {
                        'fields': ['companyName', 'companyFileNumber'],
                    },
                    'from': {
                        'fields': ['state', 'cityId', 'street', 'homeNumber', 'apt', 'floor'],
                        'title': 'whereFrom',
                    },
                    'to': {
                        'fields': ['state', 'cityId', 'street', 'homeNumber', 'apt', 'floor'],
                    }
                },
                // 'rest': {
                //     'type': 'modal'
                // },
                'licenes': {
                    'user': {
                        'fields': ['name', 'cellphone', 'username', 'password', 'confirmPassword'],
                    },
                    'company': {
                        'fields': ['companyName', 'companyFileNumber', 'bisType', 'bisSubType', 'bisTypeOther', 'exLink', 'clientsDiscount', 'comments'],
                    },
                    'from': {
                        'fields': ['cityId', 'street', 'homeNumber', 'apt', 'floor'],
                        'title': 'address',
                    },

                    'note': {
                        'modelKey': 'user',
                        'fields': ['joinUs'],
                        'title': 'pickizSite',
                        'noteKey': 'register-form',
                    },

                    'finishForm': {
                        'noteKey': 'register-popup',
                    }
                }
            },

        'initApp': { // app.components.ts
            'rest': 'modal',
            'regular': null,
        }
    };

    getSettings(key) {
        let mode = AppConfig.mode;

        // //console.log'settings', key, mode)

        // if(mode != 'rest') mode = 'regular';
        if(!this.settings[key])
            this.settings[key] = {}

        let data = this.settings[key][mode];
        // //console.log'sett', data)

        if (!data)
            data = this.settings[key]['regular'];

        if (!data) {
            this.settings[key]['regular'] = {};
            data = this.settings[key]['regular'];
        }

        let modelsettings = bis_settings[key]
        if(modelsettings) {
            for (let key in modelsettings) {
                data[key] = modelsettings[key]
            }
        }

        if (!BisFields[key])
            return data ? data : {};

        // //console.log'APSETTINGS', key, data, mode)

        for (let k in BisFields[key]) {
            if (BisFields[key][k]['skipNone'] && !BisFields[key][k]['value'])
                continue;
            data[k] = BisFields[key][k]['value'];
        }


        return data ? data : {};
    }

    setBusinessDetails(business) {
        for (let p in BisFields) {
            for (let k in BisFields[p]) {
                if (BisFields[p][k]['options']) {
                    // //console.logp, k, BisFields[p][k]['key'], business.data.GlobalSettingsData[BisFields[p][k]['key']], BisFields[p][k]['options'][business.data.GlobalSettingsData[BisFields[p][k]['key']]])
                    BisFields[p][k]['value'] = BisFields[p][k]['options'][business.data.GlobalSettingsData[BisFields[p][k]['key']]];
                } else
                    BisFields[p][k]['value'] = business.data.GlobalSettingsData[BisFields[p][k]['key']];
            }
        }
    }

    setAppDataSettings(data, globalsettings) {
        if(globalsettings.data.settings)
        {
            for(let k in globalsettings.data.settings)
                bis_settings[k] = globalsettings.data.settings[k]
        }

        if (!data) return;
        for (let p in data) {
            for (let k in data[p]) {
                if (!BisFields[p]) BisFields[p] = {};
                if (!BisFields[p][k]) BisFields[p][k] = {};
                BisFields[p][k]['value'] = data[p][k];
            }
        }
    }

}