// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-item {
  --highlight-color-focused: var(--text-a);
}

.item-has-focus ion-label {
  color: #000;
}

.clear-button {
  margin-top: 5px;
  padding: 0;
  width: 40%;
}
.clear-button ion-button {
  margin: 0;
  width: 100%;
}

ion-button, .clear-button ion-button {
  font-size: 20px;
}

.text-forgot {
  text-align: center;
  color: #3880ff;
  font-size: 16px;
  font-weight: bold;
}

.text-new {
  text-align: center;
  font-size: 16px;
  padding-top: 16px;
  font-weight: bold;
}

.text-badLogin {
  text-align: center;
  color: #d33939;
}`, "",{"version":3,"sources":["webpack://./src/core/pages/login/login.page.scss"],"names":[],"mappings":"AAAA;EACE,wCAAA;AACF;;AAEA;EAEE,WAAA;AAAF;;AAGA;EACE,eAAA;EACA,UAAA;EACA,UAAA;AAAF;AAGE;EACE,SAAA;EACA,WAAA;AADJ;;AAQA;EAEE,eAAA;AANF;;AASA;EACE,kBAAA;EACA,cAAA;EACA,eAAA;EACA,iBAAA;AANF;;AASA;EACE,kBAAA;EACA,eAAA;EACA,iBAAA;EACA,iBAAA;AANF;;AASA;EACE,kBAAA;EACA,cAAA;AANF","sourcesContent":["ion-item {\n  --highlight-color-focused: var(--text-a);\n}\n\n.item-has-focus ion-label {\n  //color: var(--text-a);\n  color: #000;\n}\n\n.clear-button {\n  margin-top: 5px;\n  padding: 0;\n  width: 40%;\n\n\n  ion-button {\n    margin: 0;\n    width: 100%;\n    //width: 40%;\n\n    //  font-size: 20px;\n  }\n}\n\nion-button, .clear-button ion-button {\n  //width: 130px;\n  font-size: 20px;\n}\n\n.text-forgot {\n  text-align: center;\n  color: #3880ff;\n  font-size: 16px;\n  font-weight: bold;\n}\n\n.text-new {\n  text-align: center;\n  font-size: 16px;\n  padding-top: 16px;\n  font-weight: bold;\n}\n\n.text-badLogin {\n  text-align: center;\n  color: #d33939;\n  //padding-top: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
