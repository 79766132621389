import {Component, Input, OnInit} from '@angular/core';
import {PopoverController} from "@ionic/angular";
import {OrderService} from "../../../../services/OrderService";

@Component({
  selector: 'app-new-cart-tickets',
  templateUrl: './new-cart-tickets.component.html',
  styleUrls: ['./new-cart-tickets.component.scss'],
})
export class NewCartTicketsComponent implements OnInit {

  @Input()
  public name:string;

  constructor(public popoverCtrl:PopoverController, public orderService:OrderService) { }

  ngOnInit() {}

  add()
  {
    this.orderService.newTicket(this.name);
    this.close()
  }

  close()
  {
    this.popoverCtrl.dismiss();
  }
}
