// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mybackground {
  width: 100%;
  overflow: auto;
  background-repeat: repeat-x;
  background-position: center;
}

.img1 {
  max-height: 100%;
}

.img-box {
  display: flex;
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/framework/components/general/appimg/app-img.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,cAAA;EAGA,2BAAA;EACA,2BAAA;AADF;;AAcA;EACE,gBAAA;AAXF;;AAoBA;EACE,aAAA;EACA,uBAAA;AAjBF","sourcesContent":[".mybackground {\n  width: 100%;\n  overflow: auto;\n  //background-size: cover;\n  //background-repeat: no-repeat;\n  background-repeat: repeat-x;\n  background-position: center;\n  //background-size: 100% 100%;\n  //background-size: cover;\n  //min-height: 130px;\n}\n\n\n@media screen and (max-width: 990px) {\n  .mybackground {\n    //min-height: 110px;\n  }\n}\n\n.img1 {\n  max-height: 100%;\n}\n\n//\n//@media screen and (min-width: 767px) {\n//  .mybackground {\n//    height: 420px;\n//  }\n//}\n.img-box {\n  display: flex;\n  justify-content: center;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
