import {Injectable} from '@angular/core';
import {NgModule} from '@angular/core';

import {Type} from '@angular/core';

export class AdItem {
    constructor(public component: Type<any>) {
    }
}

@NgModule({})
@Injectable()
export class PageClasses {

    pageClasses = {
        //'addreport': new AdItem(AddReprtPage),
        //'addTask': new AdItem(AddTaskPage),
        //'addmodelTemplate': new AdItem(ModelTemplatePage),
        // 'addtrip': new AdItem(AddTripPage),
        //'addemailsent': new AdItem(AddEmailSentPage),
    };

    constructor() {

    }

    public getClass(modelPrefix) {
        // var pref = modelPrefix.charAt(0).toLowerCase() + modelPrefix.slice(1);
        // ////console.log'get class: '+pref)
        // return this.classes[pref].component;
    }

    public getPageClass(modelPrefix) {
        //console.logmodelPrefix)
        return this.pageClasses[modelPrefix] ? this.pageClasses[modelPrefix].component : null;
    }
}
