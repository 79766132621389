import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-model-details',
  templateUrl: './model-details.page.html',
  styleUrls: ['./model-details.page.scss'],
})
export class ModelDetailsPage implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
