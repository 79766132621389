import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from "@ionic/angular";
import {ItemProvider} from "../../providers/item-provider/item-provider.service";
import {AppDataService} from "../../../../services/AppDataService";
import {Item} from "../../../../../framework/model/core/items/item";
import {OrderService} from "../../../../services/OrderService";
import {BusinessService} from "../../../../services/BusinessService";
import {PropsProvider} from "../../providers/props-provider/props-provider.service";
import {Events} from "../../../../../framework/providers/events";
import {CartModel} from "../../../../../framework/model/core/items/cartModel";
import {CartService} from "../../../../services/CartService";

@Component({
    selector: 'item-deal-props',
    templateUrl: './item-deal-props.page.html',
    styleUrls: ['./item-deal-props.page.scss'],
})
export class ItemDealPropsPage implements OnInit {

    @Input()
    public model: any;

    @Input()
    public checkedProps: object = {};

    @Input()
    public cartItem: object = {};

    @Input()
    public cart_model:CartModel;

    @Input()
    public options: object = {};

    @Input()
    public propsEvent: string;

    @Input()
    public btnTitle: string;

    @Input()
    public isItemDetails:boolean=true;

    @Input()
    public comments:string;


    public catalog:boolean= this.businessService.isCatalog();

    public typesCheck:object = {}

    public cartData;
    public props;


    constructor(public modalController: ModalController, public events:Events, public appDataService:AppDataService,
                public orderService:OrderService, public propsProvider:PropsProvider,
                public businessService:BusinessService, public cartService:CartService) {
        this.cartData = this.orderService.getCartData();
    }

    ngOnInit() {
        this.options['slidesPerView'] = 4;

        // if(this.isItemDetails)
        this.model = this.appDataService.getItemModel(this.model.data.id); // to get core item.
        this.props = this.model.data.dealprops;

        console.log('props:')
        console.log(this.model.data.dealprops);
        console.log(this.model);

        if(this.cartItem)
            this.comments = this.cartItem['comments'];

        for(let k in this.props)
        {
            this.typesCheck[this.props[k].id] = {'required':this.props[k].required, 'count':0};

            this.propsProvider.initType(this.checkedProps, this.props[k]);
            this.checkInCase(this.props[k]);
        }

        console.log(this.cart_model)

        // this.initItem();

        // //console.logthis.model)
        // //console.logthis.props)
        // //console.logthis.checkedProps);

    }


    initItem(){
        // this.cartItem = this.orderService.getCartItem(this.model.data.id);

        // this.events.subscribe(this.model.data.id+'_cartItemChanged', (res) => {
        //     this.cartItem = res;
        // });
    }


    ngOnDestroy()
    {
        this.events.unsubscribe(this.model.data.id+'_cartItemChanged');
    }

    save()
    {
        //console.logthis.checkedProps)
        let props = this.propsProvider.getPropsArr(this.checkedProps, this.typesCheck);

        // return;
        // for(let k in this.checkedProps){
        //     let typeres = {
        //         'data':this.checkedProps[k]['data'],
        //         'checked':{},
        //         'checkedValue':{},
        //         'checkedData':{},
        //         'count':this.checkedProps[k]['count']};
        //
        //     for(let c in this.checkedProps[k]['checked'])
        //     {
        //         if(this.checkedProps[k]['checked'][c])
        //         {
        //             this.typesCheck[this.checkedProps[k].data['id']]['count']++;
        //             // let value = true;
        //             // if(this.checkedProps[k]['checkedData'][c]['inputType'] != 'checkbox')
        //             //     value = this.checkedProps[k][c+'_value'];
        //             typeres['checked'][c] = true;
        //             typeres['checkedValue'][c] = this.checkedProps[k]['checkedValue'][c];
        //             typeres['checkedData'][c] = this.checkedProps[k]['checkedData'][c];
        //         }
        //     }
        //
        //     if(typeres['count'])
        //         props.push(typeres)
        // }

        for(let k in this.typesCheck)
        {
            if(this.typesCheck[k]['required'] && !this.typesCheck[k]['count'])
            {
                // //console.logthis.typesCheck[k]['required']);
                // //console.logthis.typesCheck[k]['count']);
                // //console.logk)
                this.focusType(k)
                return
            }
        }

        // //console.logprops)

        // this.events.publish(this.propsEvent, {'props':props, 'checkedProps':this.checkedProps, 'comments':this.comments});
        // this.events.publish(this.model.data.id+"_addedToCartWithProps", {});

        this.cart_model.setValue('props', props)

        this.cartService.updateCart();

        this.close(false);
    }

    focusType(typeId)
    {
        let el = document.getElementById('typeBox_'+typeId);
        el.scrollIntoView();
        this.events.publish('typeFocus_'+typeId)
    }

    checkInCase(type)
    {
        if(type.typeIncase)
        {
            // //console.log'find incase!', type)
            let incaseRes = this.findPropType(type.typeIncase)
            // //console.log'incaseObj', incaseObj)
            type['incaseObj'] = incaseRes['obj']
            type['incasePropId'] = incaseRes['propId']
        }
    }

    findPropType(prop)
    {
        for(let t in this.props)
        {
            let type = this.props[t];
            // //console.logthis.props[t])
            for(let p in this.props[t]['props'])
            {
                // //console.logt, this.props[t]['props'][p], prop)
                if(this.props[t]['props'][p]['generalProperty'] == prop)
                {
                    if(!this.checkedProps[type['id']]) this.propsProvider.initType(this.checkedProps, type);
                    // //console.logtype['id'], this.checkedProps, this.checkedProps[type['id']])
                    return {'obj':this.checkedProps[type['id']], 'propId':this.props[t]['props'][p]['id']};
                }
            }
        }

        return {};
    }

    close(event = true) {
        // //console.log'### EVENT', event)
        if(event)
            this.events.publish(this.propsEvent+'_closed');
        this.modalController.dismiss();
    }
}
