import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {AppUser} from "../../../../../framework/model/core/appuser/appuser";
import {Company} from "../../../../../framework/model/core/company/company";
import {ModelProvider} from "../../../../../framework/providers/modelProvider/ModelProvider";
import {OrderService} from "../../../../services/OrderService";
import {Events} from "../../../../../framework/providers/events";


@Component({
    selector: 'app-user-info',
    templateUrl: './user-info.page.html',
    styleUrls: ['./user-info.page.scss'],
})
export class UserInfoPage implements OnInit {
    public model: AppUser;
    public companyId;
    public appuserId;
    public appuser: AppUser;
    public company: Company;

    constructor(public route: ActivatedRoute, public modelProvider: ModelProvider, public orderService: OrderService,
                public events: Events) {
        // this.appuser = this.orderService.getAppUser();
        let me = this;

        this.route.queryParams.subscribe(params => {
            this.model = params['model'];
            // this.appuserId = params['appuserId'];
            // this.companyId = params['companyId'];
            //
            //     //console.logthis.model);
            //
            //     this.company = new Company({
            //         'id': this.model.data.companyId,
            //         'companyName': this.model.data.companyName,
            //         'approved': this.model.data.companyApproved,
            //         'priceApproved': this.model.data.companyPriceApproved,
            //         'companyFileNumber': this.model.data.companyFileNumber,
            //     })
            this.modelProvider.get('company', this.model.data.companyId).then((res: any) => {
                me.company = res.models[0];
                //console.logme.company)
            });

            // this.modelProvider.get('appuser', this.appuserId).then((res: any) => {
            //
            //     me.model = me.appuser = res.models[0];
            //     //console.logres);
            // });
        });

    }

    ngOnInit() {
    }

    updateAppUser() {
        this.modelProvider.update(this.model, {}, null, true).then((res) => {
            if (this.model.data.companyId == this.company.data.id) {
                this.events.publish('agentCompanyChanged'); // make AppDataService get new prices.
            }
        });
    }

    updatefield(field) {
        // //console.log'field change!!!');
        // //console.logfield);

        let keys = {
            'approved': 'companyApproved',
            'priceApproved': 'companyPriceApproved',
        }

        // this.company.data[field] = !this.company.data[field];
        this.model.data[keys[field]] = this.company.data[field];
        this.modelProvider.update(this.company, {}, null, true).then((res) => {
            if (this.model.data.companyId == this.company.data.id) {
                this.events.publish('agentCompanyChanged'); // make AppDataService get new prices.
            }
        });

    }

}
