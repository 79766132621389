import { Component, OnInit } from '@angular/core';
import {Business} from "../../../../../framework/model/core/business/business";
import {GlobalSettings} from "../../../../../framework/model/core/business/globalSettings";
import {BusinessService} from "../../../../services/BusinessService";

@Component({
  selector: 'business-manager',
  templateUrl: './business-manager.page.html',
  styleUrls: ['./business-manager.page.scss'],
})
export class BusinessManagerPage implements OnInit {

  public business:Business;
  public globalsettings:GlobalSettings;

  constructor(public businessService:BusinessService) {
    this.business = this.businessService.getBusiness();
    this.globalsettings = new GlobalSettings(this.business.data.GlobalSettingsData);
  }

  ngOnInit() {
  }

}
