// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-content {
  min-height: 400px;
}

.box {
  max-height: 370px;
  overflow: scroll;
}

.title-box {
  background: var(--main-top);
  color: var(--main-top-text);
  margin: 5px;
  padding: 2px;
  border-radius: 4px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}`, "",{"version":3,"sources":["webpack://./src/core/pages/item/components/bonus-items/bonus-items.component.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;AACF;;AAEA;EAEE,iBAAA;EACA,gBAAA;AAAF;;AAGA;EACE,2BAAA;EACA,2BAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,+GAAA;AAAF","sourcesContent":["ion-content {\n  min-height: 400px;\n}\n\n.box\n{\n  max-height: 370px;\n  overflow: scroll;\n}\n\n.title-box {\n  background: var(--main-top);\n  color: var(--main-top-text);\n  margin: 5px;\n  padding: 2px;\n  border-radius: 4px;\n  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12);\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
