import {Component, Input, OnInit} from '@angular/core';

import {Book} from "../../../../../framework/model/core/book/book";
import {fileBuffer} from "@angular-devkit/core/src/virtual-fs/host";
// import { FileTransfer, FileUploadOptions, FileTransferObject } from '@awesome-cordova-plugins/file-transfer/ngx';
// import { File } from '@awesome-cordova-plugins/file';
import {NavigationExtras} from "@angular/router";
import {NavController} from "@ionic/angular";
import {ModelProvider} from "../../../../../framework/providers/modelProvider/ModelProvider";
import {Events} from "../../../../../framework/providers/events";
declare let cordova: any;

@Component({
    selector: 'book-list',
    templateUrl: './book-list.component.html',
    styleUrls: ['./book-list.component.scss'],
})
export class BookListComponent implements OnInit {
    public models: Array<Book> = [];
    public prefix: string = 'booksList_' + Math.floor((Math.random() * 1000) + 1);

    @Input()
    public options: object = {};

    @Input()
    public filters: object = {"limit":7};

    @Input()
    public showItem: boolean = true;

    // private fileTransfer: FileTransferObject;

    constructor(public navCtrl: NavController, public events: Events) {
        // this.fileTransfer = transfer.create();

        this.events.subscribe(this.prefix+'_modelUpdated', (res)=>{
            this.options['modelsCount'] = res['modelsCount'];
        })
    }

    downloadfile(book) {
        let file = book.data.fileName;
        console.log(book)
        console.log(file)
        window.open(file, '_blank')
        return
        // let name = this.getFileName(file);
        // let path = this.file['dataDirectory'] + name;
        // let target = encodeURI(path);
        // this.fileTransfer.download(file, target).then((entry) => {
        //     cordova.plugins['fileOpener2'].open(
        //         entry.toURL(), // You can also use a Cordova-style file uri: cdvfile://localhost/persistent/Downloads/starwars.pdf
        //         'application/pdf',
        //         {
        //             error: function (e) {
        //                 //console.log'Error status: ' + e.status + ' - Error message: ' + e.message);
        //             },
        //             success: function () {
        //                 //console.log'file opened successfully');
        //             }
        //         }
        //     );
        //
        // }, (error) => {
        //     // handle error
        //     //console.log"ERROR !!")
        // });
    }

    getFileName(url) {
        let arr = url.split('/');
        return arr[arr.length - 1];
    }

    ngOnInit() {

    }

    goTo(page, params) {
        let navigationExtras: NavigationExtras = {
            queryParams: params
        };
        this.navCtrl.navigateForward(page, navigationExtras);
    }

}
