import {Component, OnInit} from '@angular/core';

export class StockReport {
    data = {
        "id": null,
        "currentStock": null,
        "stock": null,
        "amount": null,
        "type": null,
        "firstReport": false,
        "comments": "",
        "business": null,
        "itemToUser": null,
        "appuser": null
    };

    constructor(public data1: any) {
        this.data = data1;
    }

    //shortHeaders = [];
    prefix = "stockReport";
    title = "stockReport";
    dataFields = {
        id: {'title': 'ID', 'value': null, 'type': 'number', 'readonly': true},
        created: {'title': 'Created', 'value': null, 'type': 'date', 'readonly': true},
        updated: {'title': 'Updated', 'value': null, 'type': 'date', 'readonly': true},
    }
}