import {Component, Input, OnInit} from '@angular/core';
import {AdminService} from "../../../../../core/services/AdminService";

@Component({
    selector: 'app-model-template',
    templateUrl: './model-template.component.html',
    styleUrls: ['./model-template.component.scss'],
})
export class ModelTemplateComponent implements OnInit {
    @Input()
    templateKey;
    @Input()
    templateId;
    @Input()
    topModelId;
    @Input()
    templateParam;

    tableData;
    forms;
    templateHtml;

    constructor(public adminService: AdminService) {
    }

    ngOnInit() {
        this.adminService.getServerModel('form',
            {
                'templateParam': this.templateParam,
                'topModelId': this.topModelId,
                'templateKey': this.templateKey
            }, this.templateId).then(tableData => {
            console.log(tableData);
            this.tableData = tableData;
            this.forms = tableData['form'];
            this.templateHtml = this.forms[0]['html']['value'];
        })
    }

}
