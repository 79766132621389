import {Component, Input, OnInit} from '@angular/core';
import {ModalController, NavController} from '@ionic/angular';
import {ActivatedRoute, NavigationExtras} from '@angular/router';
import {Item} from "../../../framework/model/core/items/item";
import {OrderService} from "../../services/OrderService";
import {ItemProvider} from "./providers/item-provider/item-provider.service";
import {BusinessService} from "../../services/BusinessService";
import {ModelProvider} from "../../../framework/providers/modelProvider/ModelProvider";
import {AppUser} from "../../../framework/model/core/appuser/appuser";
import {ModelToolsProvider} from "../../../framework/providers/model-tools/model-tools";
import {TranslateService} from "@ngx-translate/core";
import {SocialSharing} from '@awesome-cordova-plugins/social-sharing/ngx';
import {AppDataService} from "../../services/AppDataService";
import {AppSettings} from "../../conf/appSettings";
import {Events} from "../../../framework/providers/events";
import {MainService} from "../../services/MainService";
import {CartModel} from "../../../framework/model/core/items/cartModel";
import {AdminService} from "../../services/AdminService";

@Component({
    selector: 'item',
    templateUrl: './item.page.html',
    styleUrls: ['./item.page.scss'],
})
export class ItemPage implements OnInit {

    public appuser: AppUser;
    public model: Item;

    @Input()
    public modelId: any;

    @Input()
    public isModal: boolean;

    @Input()
    public cartModel: CartModel;

    @Input()
    public listPrefix:string;

    @Input()
    public listIndex:number;

    public cartItem: object;

    public tabs: object = {'pages': [], 'active': '', 'readmore': false, 'data': {}};

    public prefix = 'itemList_' + +Math.floor(Math.random() * Math.floor(1000000));
    public sub_prefix: string = 'sub_' + Math.floor(Math.random() * Math.floor(1000000));

    public catalog: boolean = this.businessService.isCatalog();

    public allowAddToCart: boolean = true;
    public isprops: boolean = false;

    public options: object = {'amountInput': 0};

    @Input()
    public currentProp: object = {};

    public booksOptions: object = {};

    public cartData: object = {};

    public cartOptions: object;
    public shareOptions: object = {};
    public settings: object;

    ready = false;

    // public price;

    constructor(public navCtrl: NavController, public route: ActivatedRoute,
                public events: Events, public orderService: OrderService,
                public socialSharing: SocialSharing,
                public itemProvider: ItemProvider,
                public translate: TranslateService,
                public adminService:AdminService,
                public modelProvider: ModelProvider,
                public appSettings: AppSettings,
                public mainService:MainService,
                public appDataService: AppDataService,
                public modelToolsProvider: ModelToolsProvider,
                public modalController: ModalController,
                public businessService: BusinessService) {

        this.modelId = this.route.snapshot.paramMap.get("id");

        // this.cartItem = this.orderService.getCartItem(this.modelId);
        this.appuser = this.orderService.getAppUser();
        this.cartData = this.orderService.getCartData();

        this.settings = this.appSettings.getSettings('items');
        this.cartOptions = this.orderService.getCartOptions();
    }

    ngOnInit() {
        //console.log(this.modelId);
        let me = this;
        setTimeout(function () {
            me.ready = true;
        }, 200);

        // this.events.subscribe(this.modelId + '_cartItemChanged', (res) => {
        //     //console.log"CHANGED!!")
        //     this.cartItem = res;
        //     this.initAmountInput();
        // });
        this.getCartItem();
        this.model = this.appDataService.getItemModel(this.modelId);

        if(!this.cartModel) this.cartModel = this.model.cart_model;

        this.events.subscribe('activeTicketChanged', (params) => {
            this.getCartItem();
        }, this.sub_prefix);

        this.events.subscribe(this.modelId + "_addedToCartWithProps", (params) => {
            this.getCartItem();
        }, this.sub_prefix);

        this.events.subscribe(this.modelId + '_cartItemChanged', (params) => {
            this.getCartItem();
        }, this.sub_prefix);


        //console.log('ITEM', this.model)

        this.initAmountInput();

        this.shareOptions['data'] = this.getShareDetails();

        // if (this.model) {
        //     this.price = this.model.getPrice()['price'];
        //     //console.logthis.price)
        // }

        // this.events.subscribe(this.prefix + '_modelUpdated', (res) => {
        //     let listmodel = res['models'][0];
        //     let listmodel = res['models'][0];

        // this.price = this.model.getPrice()['price'];
        // //console.logthis.price);

        // this.model.data.description = listmodel.data.description;
        // this.model.data.topItemPropertyData = listmodel.data.topItemPropertyData;
        // this.model.data.sectionData = listmodel.data.sectionData;
        // this.model.data.makerImages = listmodel.data.makerImages;

        if (this.model.data.description) this.addTab('description');
        if (this.model.data.spec && this.model.data.spec.length) this.addTab('spec', this.model.data.spec);
        if (this.model.data.topItemPropertyData.length) this.addTab('properties');

        if(this.model.data['books']) {
            this.addTab('books');
        }
        this.getItemOrders();

        // });

        this.allowAddToCart = this.itemProvider.checkAllowAddToCart(this.model);
        //console.log(this.currentProp)


        console.log("this.model.data.stockData:")
        console.log(this.model.data.stockData)

    }

    ngOnDestroy() {
        // //console.log("DESTTTT", this.modelId)
        this.events.unsubscribe(this.modelId + '_cartItemChanged', this.sub_prefix);
        this.events.unsubscribe('activeTicketChanged', this.sub_prefix);
        this.events.unsubscribe(this.modelId + "_addedToCartWithProps", this.sub_prefix);
    }

    getCartItem() {
        this.cartItem = this.orderService.getCartItem(this.modelId);
        //console.log(this.options);
        this.initAmountInput();
        // //console.logthis.model.data.name, this.cartItem);
    }

    initAmountInput() {
        if(parseInt(this.options['amountInput']) == parseInt(this.cartItem['amount'])) return;
        this.options['amountInput'] = parseInt(this.cartItem['amount']) ? parseInt(this.cartItem['amount']) : null;
        // this.options['amountInput'] = parseInt(this.cartItem['amount']);
        //console.logthis.cartItem);
        //console.logthis.options);
        return this.options['amountInput']
    }

    addToCartInputAmount() {
        if (this.options['amountInput'] > 0) {
            this.options['amountInput'] = parseInt(this.options['amountInput'] + '');
            this.addToCart(this.options['amountInput']);//, true, false
            return this.options['amountInput'];
        } else {
            this.options['amountInput'] = 0;
        }
    }


    addToCart(agentKeyboard = false, amount=1, addToAmount=true) {
        // if(amount == this.cartItem['amount']) return;

        //console.log(amount)

        let value = Math.abs(parseInt(amount+''));
        if(!value)
        {
            this.removeFromCart();
            return
        }

        //console.log(this.cartItem, value)

        this.itemProvider.addToCart(this.model,value, !this.isModal, true, addToAmount, [],
            null, null, this.cartItem['price'], agentKeyboard, null,
            false, this.cartItem['agentPrice'], 0, {}, this.isModal ? {'agentToolbar': false} : {});

        // this.events.publish(this.model.data.id+'_agentToolbarFieldChanged', {'field':'amount'});

        this.getCartItem();
    }

    // addToCart_old(amount = 1, showPopups = true, addToAmount = true) {
    //     if (amount + '' == '0' || !amount) {
    //         this.removeFromCart();
    //         return;
    //     }
    //
    //     let addeditem = this.itemProvider.addToCart(this.model, amount, showPopups, false, addToAmount);
    //     if (addeditem)
    //         this.cartItem = addeditem;
    //
    //     this.initAmountInput();
    // }

    remove1FromCart() {
        this.itemProvider.addToCart(this.model, -1);
    }

    removeFromCart() {
        this.itemProvider.removeFromCart(this.model);
        this.initAmountInput();
    }

    getItemOrders()
    {
        this.modelProvider.custom('getOrdersByItem', {'item':this.modelId, 'setPrefix':true}).then((res)=>{
            this.model.data['coupon_data'] = res['coupon_data'];
            this.model.data['rate_data'] = res['rate_data'];
            if(res['data'].length)
            {
                this.addTab('lastOrders', res['data']);
            }
        })
    }

    generateCoupon(){
        this.modelProvider.customPost('generateItemCoupon', {}, {'item':this.modelId, 'setPrefix':true}).then((res:any)=>{
            this.model.data['coupon_data'] = res.data['coupon_data'];
        })
    }

    addTab(tab, data = null)
    {
        if(!this.tabs['pages'].length)
            this.tabs['active'] = tab;
        this.tabs['pages'].push(tab);
        this.tabs['data'][tab] = data;
    }


    setFavorite(rate = 0) {
        if (!this.appuser) {
            this.modelToolsProvider.goTo('login', {});
            return
        }
        this.modelProvider.custom('setFavorite', {
            'setPrefix': true,
            'item': this.modelId,
            'appuser': this.appuser.data.id,
            'rate': rate,
        }).then((res) => {
            this.model.data.favorite = res['res'];
            this.model.data['rate_data']['appuser_rate'] = res['appuser_rate'];
            this.model.data['rate_data']['rate_avg'] = res['rate_avg'];
            if (this.model.data.favorite)
                this.modelProvider.presentToast(this.translate.instant('addedToFavorites'));
            this.events.publish('favorites_updated');
        });
    }

    closePopup() {
        this.modalController.dismiss();
    }

    goTo(page) {
        let navigationExtras: NavigationExtras = {};
        this.navCtrl.navigateForward(page, navigationExtras);
    }

    checkAllowAddToCart() {
        this.isprops = false;
        let data = this.model.data.itemPropertyData;
        for (let k in data) {
            if (data[k]['data'].length > 1)
                this.isprops = true;
        }

        this.allowAddToCart = (!this.model.data.isTopItem || !this.isprops) && !this.catalog;
    }

    getShareDetails()
    {

        let name = this.translate.instant('code') + ': ' + this.model.data.code + '\r\n';
        name += this.model.data.name + '\r\n';
        let img = this.model.data.images.length ? this.model.data.images[0].data.imagefullurl : null;
        let url = this.mainService.getAppUrl();
        url += '?inititem=' + this.model.data.id;
        //console.log('share url', url)

        let description =  this.model.data.description;
        return [url, name]
    }


    whatsappShare() {
        let data = this.getShareDetails();

        this.events.publish('shareViaWhatsapp', {'data': data});
        // this.socialSharing.shareViaWhatsApp(name, img, url)
        //     .then(() => {
        //         //console.log"WhatsApp share successful");
        //     }).catch((err) => {
        //     //console.log"An error occurred ", err);
        //     //console.log"navigator ", navigator['share']);
        //
        //     this.whatsappShare1(name, img, url);
        //
        //
        //     // if (navigator.share) {
        //     //     navigator.share({
        //     //       title: this.model.data.name,
        //     //       text: name,
        //     //       url: url,
        //     //       img: img
        //     //     }).then(() => {
        //     //       //console.log'Thanks for sharing!');
        //     //     })
        //     //     .catch(console.error);
        //     //   } else {
        //     //     //console.log"NO NAVIGATION SHARE.")
        //     //     // shareDialog.classList.add('is-open');
        //     //   }
        //     //
        // });

        // window['plugins'].socialsharing.share(name, name, img, url);
        // //console.lognavigator)
//         // //console.logwindow)
//         //console.lognavigator)

    }

    whatsappShare1(name, img, purl) {
        let url = 'http://api.whatsapp.com/send?';

        // data = [purl, name, this.model.data.description]

        // let purl = 'http://' + AppConfig.bisPrefix + '.shaps.co.il/';
        // let img = this.model.data.images[0].data.imagefullurl;

        let details = '';
        // purl += '?inititem=' + this.model.data.id;
        // url += 'image='+img;
        details += this.getMessageLine(purl);
        // details += this.getMessageLine(this.model.data.code);
        details += this.getMessageLine(name);

        //console.logthis.model)
        details += this.getMessageLine(this.model.data.description);

        url += '&text=' + encodeURIComponent(details);
        window.open(url, '_system', 'location=yes');
        //console.logurl)

    }

    openLink(link) {
        let parts = link.split('.');
        if (!parts[0].includes('http'))
            parts[0] = 'http://' + parts[0];
        // parts.unshift('http://')
        link = parts.join('.');
        //console.loglink)
        window.open(link, "_blank");
    }

    getMessageLine(str) {
        if (!str) return '';
        // str = str.replace('\r\n', '%0a');
        // str = str.replace("<br>", '\r\n');
        str = str.split('<br>').join('\r\n');
        // str = str.split(' ').join('%20');
        // str = str.split("'").join('%27');
        return str + '\r\n'
    }

    // doint(e)
    // {
    //     let me = this;
    //     setTimeout(function()
    //     {
    //         me.options['amountInput'] = (''+me.options['amountInput']).replace('.', '');
    //     });
    //     // //console.loge)
    //     // //console.logthis.amountInput)
    //     // let n = (this.amountInput ? this.amountInput : 0) + e['key']
    //     // return parseInt(n);
    // }

}
