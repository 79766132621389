import {Component, Input, OnInit} from '@angular/core';
import {ModelClasses} from "../../../../core/model/modelClasses";
import {FieldDataObject} from "../../../../app/global-module/global-module.module";
import * as so from "../../../../core/const/selectOptions";
import {Events} from "../../../providers/events";


@Component({
  selector: 'modeloptionscroll',
  templateUrl: './modeloptionscroll.component.html',
  styleUrls: ['./modeloptionscroll.component.scss'],
})
export class ModeloptionscrollComponent implements OnInit {

    @Input()
    model:any;

    @Input()
    fieldkey:string;

    @Input()
    hideTitle:boolean;

    @Input()
    editable:boolean = true;

    @Input()
    changeCallback:Function;

    @Input()
    optionsConditions:Object = {};

    @Input()
    showFirstOption:boolean = true;

    @Input()
    fieldOptions:Object = {};

    @Input()
    prefix:string;

    options:Array<string>;

    public fieldData:FieldDataObject;
    public modelOptions:string;
    public icons:string;
    public colors:string;

    constructor(private modelClasses:ModelClasses, public events:Events) {
        ////console.log'Hello SelectOptionViewComponent Component');
    }

    ngOnInit() {
        this.setOptions();

        this.events.subscribe(this.prefix+'_optionsChanged', (params)=>{
            this.setOptions(params);
        })
    }

    setOptions(params = null){
        var selectOptionsKey = this.model.dataFields[this.fieldkey].optionsscroll;
        this.fieldData = this.model.dataFields[this.fieldkey];

        if(this.fieldData.defaultValue)
        {
          this.model.data[this.fieldkey] = this.fieldData.defaultValue
        }

        //selectMultiple
        var modelOptions;

        if (this.fieldData['options']) {
            modelOptions = this.fieldData['options'];
        }
        //    number fields keys
        else if (this.fieldData['modelKeys'])
        {
            modelOptions = this.getNumberKeysOptions(this.fieldData['modelKeys'])
        }
        else {
            if (so.SelectOptions[selectOptionsKey]) {
                modelOptions = this.copy(so.SelectOptions[selectOptionsKey]);
                this.icons = so.SelectOptions[selectOptionsKey + "_ICON"] ? so.SelectOptions[selectOptionsKey + "_ICON"] : null;
                this.colors = so.SelectOptions[selectOptionsKey + "_COLOR"] ? so.SelectOptions[selectOptionsKey + "_COLOR"] : null;
            }
            // else if (fso.FrameworkSelectOptions[selectOptionsKey]) {
            //     modelOptions = fso.FrameworkSelectOptions[selectOptionsKey];
            //     this.icons = fso.FrameworkSelectOptions[selectOptionsKey + "_ICON"] ? fso.FrameworkSelectOptions[selectOptionsKey + "_ICON"] : null;
            //     this.colors = fso.FrameworkSelectOptions[selectOptionsKey + "_COLOR"] ? fso.FrameworkSelectOptions[selectOptionsKey + "_COLOR"] : null;
            // }
        }

        // if(params)
        // {
        //     modelOptions = this.copy(params['options']);
        // }

        console.log(this.fieldOptions, modelOptions)
        if(this.fieldOptions['forceOptions'])
        {
            modelOptions = {};
            for (let k of this.fieldOptions['options']) {
              modelOptions[k] = k;
            }
        }
        else {
            //
            if (this.fieldOptions['options']) {
                for (let k in modelOptions) {
                    if (this.fieldOptions['options'].indexOf(modelOptions[k]) == -1)
                        delete modelOptions[k]
                }
            }
        }

        this.options = Object.keys(modelOptions);
        if (!this.model.data[this.fieldkey])
            this.model.data[this.fieldkey] = this.options[0];
        this.modelOptions = modelOptions;
    }

    // public onChange($event) {
    //     if (this.changeCallback) {
    //         this.changeCallback(this.model);
    //     }
    // }

    public onChange(opt) {
        this.model.data[this.fieldkey] = opt;
        if (this.prefix) {
            ////console.logthis.prefix)
            this.events.publish(this.prefix);
        }

    }

    private getNumberKeysOptions(clsName) {
        var cls = this.modelClasses.getClass(clsName);
        var model:any = new cls();

        //create options for field key model.
        var keys = {};
        for (var fieldKey in model.dataFields) {
            if (model.dataFields[fieldKey].type == 'number')
                keys[fieldKey] = fieldKey;
        }

        return keys;
    }

    checkCondition(key)
    {
      if(this.optionsConditions)
        return this.optionsConditions[key] === false;
      return false;
    }


    copy(data) {
        return JSON.parse(JSON.stringify(data))
    }

}
