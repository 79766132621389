import {Component, ElementRef, Input, OnInit} from '@angular/core';
import {Item} from "../../../../../framework/model/core/items/item";

@Component({
  selector: 'app-item-bounds',
  templateUrl: './item-bounds.component.html',
  styleUrls: ['./item-bounds.component.scss'],
})
export class ItemBoundsComponent implements OnInit {

  @Input()
  public prefix;

  @Input()
  public el:ElementRef;

  @Input()
  public model:Item;

  @Input()
  public key;

  constructor() { }

  ngOnInit() {}

  ngAfterViewInit() {
    let me = this;

    delete this.model['bounds_data'][this.prefix];
    setTimeout(function (){
      me.setBounding()
    }, 100);
  }

  setBounding() {
    if (!this.prefix) return;
    if (!this.el) return;

    // if (!this.model['bounds_data'][this.prefix]) {
      this.model.setBounds(this.el, this.prefix, this.key);
    // }
  }

}
