import {Injectable} from '@angular/core';
import {OrderService} from "../../../../services/OrderService";
import {ActionSheetController, ModalController, PopoverController} from "@ionic/angular";
import {BonusItemsComponent} from "../../components/bonus-items/bonus-items.component";
import {Item} from "../../../../../framework/model/core/items/item";
import {AgentKeyboardPage} from "../../../agent/agent-keyboard/agent-keyboard.page";
import {BusinessService} from "../../../../services/BusinessService";
import {AppSettings} from "../../../../conf/appSettings";
import {ModelToolsProvider} from "../../../../../framework/providers/model-tools/model-tools";
import {ModelProvider} from "../../../../../framework/providers/modelProvider/ModelProvider";
import {MainService} from "../../../../services/MainService";
import {AppDataService} from "../../../../services/AppDataService";
import {PropsProvider} from "../props-provider/props-provider.service";
import {Events} from "../../../../../framework/providers/events";
import {ItemPage} from "../../item.page";
import {CartModel} from "../../../../../framework/model/core/items/cartModel";
import {CartService} from "../../../../services/CartService";
import {ItemArticlePage} from "../../pages/item-article/item-article.page";

@Injectable({
    providedIn: 'root'
})
export class ItemProvider {

    private active: boolean = false;

    constructor(public orderService: OrderService, public popoverCtrl: PopoverController, public appSettings: AppSettings,
                public modelTools: ModelToolsProvider, public modelProvider: ModelProvider, public mainService: MainService,
                public actionSheetController: ActionSheetController,
                public appDataService: AppDataService, public propsProvider: PropsProvider, public cartService: CartService,
                public modalController: ModalController, public events: Events, public businessService: BusinessService) {
        this.events.subscribe('keyboardClosed', (res) => {
            this.active = false;
        })

    }

    add_to_cart(model: Item, value = null, field = 'amount', options: any = {
        'add_to_value': true,
        'force_add_to_cart': false
    }, cart_model: CartModel = null) {
        if (!options['disable-allow-check'] && !this.orderService.allowAddToCart()) {
            return cart_model;
        }

        if (options['outside_item_page'] && model.data['section_sales'] && model.data['section_sales'].length) {
            this.openItem(model, cart_model);
            return cart_model
        }

        let autoProps = model.data.autoProps > 0 && !options['in_cart'];

        if (!cart_model || autoProps) cart_model = this.getTicketCartModel(model, autoProps);
        let ticketId = cart_model.data['ticketId'];
        if (!ticketId) ticketId = options['ticketId']
        if (!ticketId || (!options['in_cart'] && !options['ticketId'])) ticketId = this.cartService.getCurTicket();

        let ticket = this.cartService.getTicket(ticketId)

        if (value === null) {
            cart_model.updateModel(options['force_add_to_cart']);
        } else {
            if ((value == 1 || value == -1) && model.data['packageUnits'] && field == 'amount')
                value = model.data['packageUnits'] * value

            cart_model.addToCart(field, value, options);
        }

        if (cart_model.data['in_cart']) {
            this.cartService.addToCart(ticketId, model, cart_model);
        } else {
            this.cartService.removeFromCart(ticketId, cart_model, model);
            autoProps = false;
        }

        if (autoProps) {
            // let props = this.propsProvider.getItemDefProps(model)
            // console.log(props)
            this.openItem(model, cart_model, true);
        }

        return cart_model
    }

    getTicketCartModel(model, forceNewModel = false, ticketId = null) {
        if (!ticketId) ticketId = this.cartService.getCurTicket();

        if (forceNewModel) {
            let cart_model = new CartModel(model);
            cart_model.data['ticketId'] = ticketId;
            return cart_model;
        }

        let cart_model = model.cart_model;
        let business = this.businessService.getBusiness();
        if (!business.data.GlobalSettingsData.tickets) return cart_model

        let cur_ticket = this.cartService.getTicket(ticketId);

        if (!model.cart_tickets_models['count']) {
            return cart_model;
        }

        if (model.cart_tickets_models['tickets'][cur_ticket['prefix']] && model.cart_tickets_models['tickets'][cur_ticket['prefix']].length) {
            cart_model = this.getCartModelByPrefix(cur_ticket, model.cart_tickets_models['tickets'][cur_ticket['prefix']][0])
            console.log(cart_model)

            if (cart_model) return cart_model
            delete model.cart_tickets_models['tickets'][cur_ticket['prefix']];
            model.cart_tickets_models['count']--;
        }

        cart_model = new CartModel(model);
        this.cartService.updateItemTickets(model, cart_model);
        cart_model.data['ticketId'] = ticketId;
        return cart_model;
    }

    setAgentToolEditField(cartModel, model, field, options, closeToolbar = this.businessService.screenSize[0] > 1200) {
        cartModel.data['data']['discount']['pressed'] = false;
        options['active_model'] = cartModel.data['prefix'];

        if (!cartModel.data['data'][field]['input'] || cartModel.data['data'][field]['select'] || closeToolbar) {
            this.events.publish('closeItemToolbar');
            cartModel['active'] = null;
            return;
        }

        this.showItemKeyboard({
            'cart_model': cartModel,
            'item': model,
            'agent-toolbar': true,
            'options': {
                'field': field
            }
        });

        cartModel['active'] = field;
        cartModel.data['data'][field]['edit_prefix'] = this.modelTools.getRandomize();
    }


    getCartModelByPrefix(ticket, prefix, ticket_id = null) {
        if (!ticket && ticket_id) {
            ticket = this.cartService.getCart()[ticket_id];
        }

        console.log('----', ticket['models'], prefix);

        if (!ticket['models'][prefix]) return
        return ticket['models'][prefix]['cartModel'];
    }

    addToCart(model, amount = 1, showPopups = true, cartItemEdit = false, addToAmount = true, props = [],
              dealData = null, ticket = null, price = null, agentKeyboard = true, index = null, skipProps = false, agentPrice = null,
              actionAmount = 0, data = {}, options = {}) {
        // console.log('ADD TO CART', index, showPopups)

        let business = this.businessService.getBusiness();
        let appuser = this.orderService.getAppUser();

        if (!appuser) this.mainService.handelLogin();

        let iniindex = index == null;
        if (!index) index = model.data.id;

        let cartItem = this.orderService.getCartTicketItem(index);

        //?
        if (!this.checkDelivery(model)) {
            this.modelProvider.presentToast('cantDeliverItem', true, null, null, true);
            return
        }

        if (!options['disable-allow-check'] && !this.orderService.allowAddToCart())
            return;

        let skip_add_to_cart = false;


        model.data.autoProps = model.data.dealprops.length > 0;
        // //console.logmodel)

        // let item = new Item(model.data);
        let item = model;


        // addToAmount = addToAmount && (!agentKeyboard && (!this.orderService.isAgent() || !showPopups));
        // if (this.orderService.isAgent() && !options['agentToolbar'] && !this.orderService.cartData['hide-agent-toolbar'] && showPopups) {
        //     this.showItemToolbar(item, index)
        //     return cartItem;
        //     // addToAmount = false;
        //     // amount = cartItem['amount'];
        //     // skip_add_to_cart = true;
        // }

        // //console.logcartItem, amount, showPopups && ((!cartItem['amount'] && amount) || cartItemEdit) && agentKeyboard, !cartItem['amount'] && amount);

        // if (appuser) {

        // } else {
        //     if (business.data.GlobalSettingsData.registerForCart) {
        //         this.mainService.handelLogin();
        //         return;
        //     }
        // }

        if (!price) {
            price = item.data.price;

            if (dealData) {
                price = dealData['price'];
            }
        }

        if (showPopups && !skipProps) {
            props = this.propsProvider.getItemDefProps(item, amount, cartItem)
        }
        // //console.logprops);

        // if (AppConfig.mode == 'rest') // duplicate the item when its a rest type.

        // //console.logamount, index, actionAmount);
        let viewAgentKeyboard = showPopups && ((!cartItem['amount'] && amount) || cartItemEdit) && agentKeyboard;

        // if(this.orderService.isAgent() && cartItem['amount']) {
        //     skip_add_to_cart = true;
        //     options['agentToolbar'] = true;
        // }
        //props
        let openprops = model.data.dealprops && model.data.dealprops.length && !skipProps && showPopups;
        if (openprops && model.data.autoProps && amount > 0 && !viewAgentKeyboard) {
            console.log('open props!!')
            this.openItem(model, cartItem, true);
            return
        }

        // //console.log'before index', index, props.length, skipProps)
        if (!options['inCart'] && (props && props.length) || dealData) // duplicate the item when added with props.
        {
            //console.log'SET INDEX ! ')
            index = ('' + index).split('_')[0];
            index += '_' + this.getPropsStr(props);
            // index += '_' + +Math.floor(Math.random() * Math.floor(1000000));
        }
        if (options['separateCartItem']) {
            index = ('' + index).split('_')[0];
            index += '_' + Math.floor(Math.random() * Math.floor(1000000));
        }

        // //console.logprops);

        // console.log('ADDED--', item.data.id, item.data.code, index, skip_add_to_cart)
        //console.log'index', index)
        // //console.logprops)
        // //console.logprops.length)
        // //console.logdealData)
        // //console.log'----')

        if (!skip_add_to_cart) {
            cartItem = this.orderService.addToBasket(ticket, item, amount, null, null, price, agentPrice, index,
                addToAmount, data['comments'], props, dealData, true, actionAmount, data);
            if (!cartItem['data']) cartItem['data'] = {};
            // console.log("----", cartItem)
        }

        this.initCartPrices(item, cartItem);

        //this is regular user keyboard!
        //this is regular user keyboard!
        //this is regular user keyboard!
        if (viewAgentKeyboard && !this.orderService.isAgent()) {
            // if(!this.orderService.allowAddToCart())
            //     return;

            // if (this.active) return;
            // this.active = true;
            // console.log('open agent keyboard');
            this.showAgentKeyboard(model, cartItem, props);
            return cartItem;
        }

        // //console.log'itemprov', cartItem, model.data.dealprops && model.data.dealprops.length && !skipProps && showPopups)
        // //console.logthis.orderService.getCartData())
        // //console.logthis.orderService.getCart())

        // //console.logcartItem)

        // if (options['agentToolbar'] === undefined && this.orderService.isAgent()) {
        //     this.modelTools.getKey('agentToolbar').then((res) => {
        //         if (res) {
        //             options['agentToolbar'] = res;
        //         }
        //         this.showItemToolbar(item, index, null, options['agentToolbar'])
        //     })
        //     return cartItem
        // }
        if (showPopups) {
            this.showItemToolbar(item, index, null, false)
        }


        // if (openprops && !model.data.autoProps && amount > 0) {
        //     this.carItemBar(item, amount, index);
        // this.showPropsModal(model, cartItem, {}, null, null, true, cartItemEdit);
        // return cartItem;
        // }

        // if(showPopups)
        //     this.showBonusItems(model, cartItem);
        return cartItem;
    }

    addPropsToCartItem(cartItem, props) {
        let index = cartItem['index'];
        if (index == cartItem['product'].data.id)
            cartItem['index'] += '_' + +Math.floor(Math.random() * Math.floor(1000000));

        cartItem['props'] = props;

        this.orderService.updateTotalCharge();
        this.orderService.saveCartOnStorage();
    }

    showItemKeyboard(params) {
        // this.modelTools.setKey('agentToolbar', agenttoolbar);
        this.events.publish('showItemToolbar', params);
    }

    showItemToolbar(item, index, field = null, agenttoolbar = false, options = {}) {
        // console.log(options);
        this.modelTools.setKey('agentToolbar', agenttoolbar);
        this.events.publish('showItemToolbar', {
            'model': item,
            'index': index,
            'stay': true,
            'field': field,
            'agent-toolbar': agenttoolbar,
            'options': options
        });
    }

    removeFromCart(model) {
        this.orderService.removeFromCart(null, model.data.id, null);
        model.agentToolData['visible'] = false;
    }

    removeCartItemFromCart(cartItem) {
        this.orderService.removeFromCart(cartItem['ticket'], cartItem['index'], null, true);
    }

    async showBonusItems(model, cartItem) {
        if (!model.data.sale) return;
        if (!model.data.sale['bonusItems']) return;
        if (cartItem['amount'] > 1) return;

        const popover = await this.popoverCtrl.create({
            component: BonusItemsComponent,
            componentProps: {'model': model},
            event: null,
            animated: true,
            showBackdrop: true,
            cssClass: 'center-popover'
        });
        return await popover.present();
    }

    async showAgentKeyboard(model, cartItem, props) {
        this.modalController.dismiss();
        const modal = await this.modalController.create({
            component: AgentKeyboardPage,
            componentProps: {
                'model': model,
                'cartItem': cartItem,
                'props': props,
            },
            showBackdrop: false,
            swipeToClose: true,
        });

        return await modal.present();
    }


    carItemBar(item, amount, index) {
        this.events.publish('showCartItemBar', {'item': item, 'amount': amount, 'index': index});
    }

    openItem(model: Item, cart_model = null, byprops = false, params = {}) {
        // //console.logmodel.data.link)
        if (model.data.link) {
            window.open(model.data.link, "_blank");
            return
        }

        if (!cart_model) cart_model = model.cart_model;

        let settings = this.appSettings.getSettings('items');
        if (settings['details'] == 'modal' || (byprops && model.data.dealprops.length)) {

            let props = {};
            if (cart_model.data['in_cart']) {
                props = this.propsProvider.getCheckedProps(cart_model.getValue('props'))
            }

            let propsEvent = 'propsEvent' + '_' + +Math.floor(Math.random() * Math.floor(1000000));
            this.propsProvider.showPropsModal(model, cart_model, props, propsEvent, null, true);
            this.events.subscribe(propsEvent, (res) => {
                // if (!cartItem['index']) {
                //     this.addToCart(model, 1, true, false, true, res['props'], null,
                //         null, null, false, cartItem['index'], true, null, 0, res, params);
                // } else {
                // let props = this.propsProvider.getPropsArr(res['checkedProps']);
                // cartItem['comments'] = res['comments'];
                // this.addPropsToCartItem(cartItem, props);
                // }
                this.events.unsubscribe(propsEvent);

            })
        } else {
            params['cartModel'] = cart_model;
            this.showItemModal(model.data.id, params, model)
            // this.modelTools.goTo('item/' + model.data.id);
        }
    }

    async showItemModal(modelId, params = {}, model = null) {
        const popover = await this.modalController.getTop();
        if (popover)
            await popover.dismiss(null);

        params['modelId'] = modelId
        params['isModal'] = true

        let page: any = ItemPage;
        if (model && model.data.type == 'article') {
            page = ItemArticlePage;
        }

        const modal = await this.modalController.create({
            component: page,
            componentProps: params,
            // 'enterAnimation': enterAnimation,
            cssClass: 'animated-popover big-modal',
        });

        return await modal.present();
    }

    openItemPropsModal(model, cartItem = null) {
        let propsEvent = 'propsEvent' + '_' + +Math.floor(Math.random() * Math.floor(1000000));
        let checked = cartItem ? this.propsProvider.getCheckedProps(cartItem['props']) : {};
        this.propsProvider.showPropsModal(model, cartItem, checked, propsEvent, null, true);
        this.events.subscribe(propsEvent, (res) => {
            this.addToCart(model, 1, true, false, true, res['props'], null, null, null, true, cartItem['index'], true);
            this.events.unsubscribe(propsEvent);

        })
    }

    checkDelivery(model) {
        let ordertype = this.orderService.getOrderType()
        if (ordertype == 'delivery' && !model.data.shippingable)
            return false;
        return true;
    }

    checkAllowAddToCart(model) {
        let isprops = false;
        let allowAddToCart = false;
        let catalog: boolean = this.businessService.isCatalog();

        let data = model.data.itemPropertyData;
        for (let k in data) {
            if (data[k]['data'].length > 1)
                isprops = true;
        }

        allowAddToCart = !model.data.isTopItem && !catalog && (!model.data.view_type || !model.data.view_type.includes('business_card')) && model.data.price > 0;

        // //console.log"allowAddToCart", model, allowAddToCart)
        return allowAddToCart
    }

    getItemById(modelId) {
        return this.appDataService.getItemModel(modelId);
    }

    getOrderItemProps(model) {
        let item = this.appDataService.getItemModel(model.data.itemId);
        if (!item) return []
        // console.log(item)
        let obj = {};
        let res = [];
        let props = item.data.dealprops;
        for (let k in props) {
            obj[props[k]['id']] = {'data': props[k], 'checked': {}, 'checkedValue': {}, 'checkedData': {}, 'count': 0};
        }

        for (let k in model.data.dealProperties) {
            let prop = model.data.dealProperties[k];
            obj[prop['type']]['checked'][prop.property] = true;
            obj[prop['type']]['checkedData'][prop.property] = prop;
            obj[prop['type']]['count']++;

            if (!obj[prop['type']]['added']) {
                res.push(obj[prop['type']])
                obj[prop['type']]['added'] = true;
            }
        }
        return res;
    }

    getPropsStr(props) {
        let str = ''
        for (let k in props) {
            for (let p in props[k]['checked']) {
                str += p;
            }
        }
        return str;
    }

    setAgentTool(item, cartItem, coupons = null) {
        if (item.agentToolData['init']) return;
        let cartModel = new CartModel(item, coupons);
    }

    setAgentTool1(item, cartItem, coupons = null) {
        // console.log("SET AGENT TOOL!", item.data.id, item.data.code)

        if (!cartItem) {
            cartItem = {'amount': 0, 'data': {}};
        }
        let discount_options = []

        if (cartItem['features'] && cartItem['features']['cart_prefix'] && item.agentToolData && item.agentToolData['cart_prefix']) {
            // console.log(cartItem['features']['cart_prefix'], item.agentToolData['cart_prefix'], cartItem['features']['cart_prefix'] != item.agentToolData['cart_prefix'])
            if (cartItem['features']['cart_prefix'] != item.agentToolData['cart_prefix'])
                return
        }

        // console.log("ITEM TOOL!!", cartItem, item.agentToolData['init'])
        // console.trace()

        if (!coupons) coupons = [];
        // coupons.unshift({'name': 'without', 'code': 0});

        let values = {
            'code': item.data.code,
            'stock': cartItem['stock'] >= 0 ? cartItem['stock'] : item.data.stock,
            'barcode': item.data.barcode,
            'corePrice': item.data.price,
            'lastPrice': item.data['ordered_data'] ? item.data['ordered_data']['last_price'] : '0',
            'amount': cartItem['amount'],
            'price': cartItem['price'] + '',
            'final_unit_price': cartItem['final_unit_price'],
            'totalPrice': cartItem['totalPrice'],
            'discount': cartItem['data']['discount'],
            'coupon_amount': cartItem['data']['coupon_amount'],
            'coupon_code': cartItem['data']['coupon_code'],
            'comments': cartItem['data']['comments'],
        }

        if (!item.agentToolData['init']) {
            item.agentToolData = {
                'fields': ['amount', 'price', 'discount', 'corePrice', 'final_unit_price',
                    'stock', 'totalPrice', 'coupon_amount', 'coupon_code', 'comments'],
                'editFields': ['amount', 'price', 'discount'],
                // 'cartItem': cartItem,
                'expand': false,
                'loading': false,
                'visible': values['amount'] > 0,
                'init': true,
                'data': {
                    'amount': {
                        'title': 'amount',
                        'value': values['amount'],
                        'input': true,
                        'type': 'number',
                        'numberButtons': true,
                        'style': {'border': '1px solid #8fb3b3', 'max-width': '120px'}
                    },
                    'code': {
                        'title': 'code',
                        'value': values['code']
                    },
                    'stock': {
                        'title': 'stock',
                        'value': values['stock'],
                        'style': {'max-width': '120px'}
                        // 'style': {'flex': '0 0 23%'}
                    },
                    'price': {
                        'title': 'price',
                        'value': values['price'],
                        'price': true,
                        'input': true,
                        'type': 'number',
                        'style': {'border': '1px solid #8fb3b3', 'max-width': '120px'}
                        // 'style': {'flex': '0 0 20%'}
                    },
                    'discount': {
                        'title': '%discount',
                        'value': values['discount'],
                        'input': true,
                        'type': 'number',
                        'addTxt': '%',
                        'max': 100,
                        'focus': false,
                        'options': discount_options,
                        'style': {'border': '1px solid #8fb3b3'}
                        // 'style': {'flex': '0 0 16%'}
                    },
                    'final_unit_price': {
                        'title': 'neto',
                        'value': 'final_unit_price',
                        // 'cartItemField': true,
                        'price': true,
                        // 'hideOnEmpty':true,
                        'type': 'price',
                        'cssClass': 'italic',
                        'style': {'max-width': '120px'}
                        // 'style': {'flex': '0 0 27%'}
                    },
                    'totalPrice': {
                        'title': 'total',
                        'value': 'totalPrice',
                        'type': 'price',
                        // 'cartItemField': true,
                        'price': true,
                        // 'hideOnEmpty':true,
                        'cssClass': 'bold italic',
                        // 'style': {'max-width': '120px'}
                        'style': {'max-width': 'inherit'}
                    },
                    'coupon_amount': {
                        'title': 'bonus_amount',
                        'value': values['coupon_amount'],
                        'input': true,
                        'isExpand': true,
                        'type': 'number'
                    },
                    'coupon_code': {
                        'title': 'bonusReason',
                        'value': values['coupon_code'],
                        'select': coupons,
                        'hidden': !coupons.length,
                        'input': true,
                        'isExpand': true,
                        'type': 'text'
                    },
                    'barcode': {
                        'title': 'barcode', 'value': values['barcode'],
                        'isExpand': true,
                    },
                    'comments': {
                        'title': 'comments',
                        'value': values['comments'],
                        'type': 'text',
                        'input': true,
                        'style': {'flex': '1 1 100%'},
                        'isExpand': true,
                        'forceInput': true,
                    },
                    'corePrice': {
                        'title': 'corePrice',
                        'value': values['corePrice'],
                        'price': true,
                        'cssClass': 'italic',
                        'style': {'max-width': '120px'}
                    },

                    'lastPrice': {
                        'title': 'lastOrderedPrice', 'value': values['lastPrice'],
                        'price': true,
                        'isExpand': true,
                    },
                },
            };

            if (cartItem['data']['coupon_code'] || cartItem['data']['coupon_amount']) {
                item.agentToolData['expand'] = true;
            }
        }

        for (let key in values) {
            item.agentToolData['data'][key]['value'] = values[key];
        }

        item.agentToolData['visible'] = values['amount'] > 0;
    }

    initCartPrices(item, cartItem = null) {
        if (!item.agentToolData['init'] && cartItem) {
            this.setAgentTool(item, cartItem);
        }

        let price = item.agentToolData['data']['price']['value']
        let amount = item.agentToolData['data']['amount']['value']
        let discount_price = item.agentToolData['data']['discount']['value'] ? (price * parseFloat(item.agentToolData['data']['discount']['value']) / 100) : 0
        item.agentToolData['data']['final_unit_price']['value'] = price - discount_price;
        item.agentToolData['data']['totalPrice']['value'] = item.agentToolData['data']['final_unit_price']['value'] * amount;
    }


    async moveToTicket(model, cartModel: CartModel) {
        let buttons = [];
        let cart = this.cartService.getCart();

        for (let ticket in cart) {
            buttons.push({
                text: this.modelTools.getTranslate(cart[ticket]['name']),
                // icon: action['icon'],
                handler: () => {
                    let values = cartModel.getValues();
                    this.add_to_cart(model, 0, 'amount', {'add_to_value': false}, cartModel);

                    let me = this;
                    setTimeout(function () {
                        me.add_to_cart(model, values['amount'], 'amount', {
                            'add_to_value': false,
                            'ticketId': cart[ticket]['id']
                        }, cartModel);

                        me.events.publish('activeTicketChanged_' + model.data.id, {'ticketId': cart[ticket]['id']});
                    }, 300);
                }
            });
        }
        buttons.push({
            text: this.modelTools.getTranslate('close'),
            role: 'destructive',
            icon: 'close',
            handler: () => {

            }
        });

        const actionSheet = await this.actionSheetController.create({
            header: this.modelTools.getTranslate('moveToTicket'),
            cssClass: 'my-custom-class',
            buttons: buttons
        });
        await actionSheet.present();

        const {role, data} = await actionSheet.onDidDismiss();
    }


    addToFavorite(model, value)
    {
        let its = this.appDataService.getPricesListKey('itemToUser')
        const index = its.indexOf(model.data.id);

        if(value && index == -1)
        {
            its.push(model.data.id)
        }
        else if(!value && index !== -1){
            its.splice(index, 1);
        }
    }

    setFavorite(model, rate = 0) {
        let appuser = this.orderService.getAppUser();
        if (!appuser) {
            this.modelTools.goTo('login', {});
            return
        }
        this.modelProvider.custom('setFavorite', {
            'setPrefix': true,
            'item': model.data.id,
            'appuser': appuser.data.id,
            'rate': rate,
        }).then((res) => {
            model.data.favorite = res['res'];
            model.data['rate_data']['appuser_rate'] = res['appuser_rate'];
            model.data['rate_data']['rate_avg'] = res['rate_avg'];
            if (model.data.favorite) {
                this.modelProvider.presentToast(this.modelTools.getTranslate('addedToFavorites'));
            }
            this.addToFavorite(model, model.data.favorite)

            this.events.publish('favorites_updated');
        });
    }

}
