import { Component, OnInit } from '@angular/core';
import {Ticket} from "../../../../framework/model/core/ticket/ticket";
import {Business} from "../../../../framework/model/core/business/business";
import {TicketMessage} from "../../../../framework/model/core/ticketMessage/ticketMessage";
import {BusinessService} from "../../../services/BusinessService";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-ticket',
  templateUrl: './ticket.page.html',
  styleUrls: ['./ticket.page.scss'],
})
export class TicketPage implements OnInit {
    public ticketMessage : TicketMessage;
    public business: Business;
    public models: Array<TicketMessage> = [];
    public ticketId: any;

      constructor(public businessService: BusinessService, public route: ActivatedRoute) {
        this.ticketMessage = new TicketMessage({});
        this.business = this.businessService.getBusiness();
        this.ticketId = this.route.snapshot.paramMap.get("id")
    }

  ngOnInit() {
  }

}
