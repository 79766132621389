import {Component, OnInit, Renderer2} from '@angular/core';
import {OrderService} from "../../../../services/OrderService";
import {ModelToolsProvider} from "../../../../../framework/providers/model-tools/model-tools";
import {Events} from "../../../../../framework/providers/events";


@Component({
    selector: 'app-tickets-list',
    templateUrl: './tickets-list.page.html',
    styleUrls: ['./tickets-list.page.scss'],
})
export class TicketsListPage implements OnInit {

    public tickets: Array<any> = [];
    public options: object = {};
    public cartData;

    constructor(public orderService: OrderService, private rd: Renderer2, public modelTools:ModelToolsProvider, public events:Events) {
        this.cartData = this.orderService.getCartData();

    }


    ngOnInit() {
        this.setTickets();
        this.events.subscribe('cartUpdated', (params) => {
            this.setTickets();
        })
    }

    // setOptions(params = {}) {
    //     this.options.length = 0;
    //     for (let k in this.cart) {
    //         this.options.push({'ticket': this.cart[k]['ticket'], 'value': this.cart[k]['name']})
    //     }
    //
    //     // if((!this.cartData['curTicket'] || params['newTicket']) && this.options.length) {
    //     //   this.orderService.setActiveTicket(this.options[this.options.length - 1]['ticket'])
    //     // }
    // }

    setTickets() {
        let cart = this.orderService.getCart();
        this.tickets = [];
        for (let k in cart) {
            this.options[k] = {'edit': false}
            this.tickets.push(cart[k]);
        }
    }

    setTicket(ticket)
    {
        this.orderService.setActiveTicket(ticket['ticket']);
    }


    newTicket() {
        this.orderService.showTicketsPopup();
    }

}
