// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.option-box {
  border-top: 1px solid #ccc;
}

.option-box:hover {
  cursor: pointer;
  background: #fbfbfb;
}

.option-box .icon {
  color: #1f323c;
  font-size: 25px;
  text-align: center;
  padding: 6px 5px;
}

.option-box .text {
  padding: 16px 10px;
}

.search-box {
  border-bottom: 1px solid #ccc;
}

.no-scroll-y .scroll-content {
  overflow-y: hidden;
}

.scroll-list {
  min-height: 50px;
  max-height: 250px;
  overflow: scroll;
  overflow-x: hidden;
}

.no-border {
  border: 0;
}`, "",{"version":3,"sources":["webpack://./src/framework/components/field/modelscroll/app-model-scroll/app-model-scroll.component.scss"],"names":[],"mappings":"AAAA;EACE,0BAAA;AACF;;AAEA;EACE,eAAA;EACA,mBAAA;AACF;;AAEA;EACE,cAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;AACF;;AAGA;EACE,kBAAA;AAAF;;AAGA;EACE,6BAAA;AAAF;;AAGA;EACE,kBAAA;AAAF;;AAGA;EACE,gBAAA;EACA,iBAAA;EACA,gBAAA;EACA,kBAAA;AAAF;;AAKA;EACE,SAAA;AAFF","sourcesContent":[".option-box {\n  border-top: 1px solid #ccc;\n}\n\n.option-box:hover {\n  cursor: pointer;\n  background: #fbfbfb;\n}\n\n.option-box .icon {\n  color: #1f323c;\n  font-size: 25px;\n  text-align: center;\n  padding: 6px 5px;\n\n}\n\n.option-box .text {\n  padding: 16px 10px;\n}\n\n.search-box {\n  border-bottom: 1px solid #ccc;\n}\n\n.no-scroll-y .scroll-content {\n  overflow-y: hidden;\n}\n\n.scroll-list {\n  min-height: 50px;\n  max-height: 250px;\n  overflow: scroll;\n  overflow-x: hidden;\n}\n\n;\n\n.no-border {\n  border: 0;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
