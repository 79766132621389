import {Component, Input, OnInit} from '@angular/core';
import {ActionSheetController, NavController} from "@ionic/angular";
import {ActivatedRoute, NavigationExtras} from "@angular/router";
import {Events} from "../../../framework/providers/events";

@Component({
    selector: 'app-order',
    templateUrl: './order.page.html',
    styleUrls: ['./order.page.scss'],
})
export class OrderPage implements OnInit {
    orderId;

    public backToRoot;
    public orderUpdatePrefix;
    public neworder:boolean;

    public backButtonEvent: string = 'orderPageBack';

    constructor(public events: Events,
                public navCtrl:NavController,
                public route: ActivatedRoute) {
    }

    ngOnInit() {
        if (!this.orderId) {
            this.orderId = this.route.snapshot.paramMap.get("id");
        }

        //console.log"orderId:" + this.orderId, this.neworder);

        this.route.queryParams.subscribe(params => {
            this.backToRoot = params['backToRoot']
            this.orderUpdatePrefix = params['updatePrefix'];
            // this.neworder = params['neworder'];
        });

        this.events.subscribe(this.backButtonEvent, (params) => {
            this.navCtrl.navigateRoot('main');
            this.events.unsubscribe(this.backButtonEvent)
        });
    }

    save() {
        // //console.logthis.order);

        //need this???

        // this.modelProvider.update(this.order, {}, null, true).then((res) => {
        //     if (this.orderUpdatePrefix)
        //         this.events.publish(this.orderUpdatePrefix);
        // });
    }

    ngOnDestroy()
    {
        // this.neworder = false;
    }


}
