// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.img-box {
  width: 100%;
}

ion-toolbar {
  --background: var(--footer-background);
  --color: var(--footer-text);
  box-shadow: 0px 0px 3px #3e3e3e;
  --min-height: 56px;
}
ion-toolbar ion-buttons ion-button.button-clear {
  --color: var(--main-top-text);
  --color-activated: var(--main-top-text);
}
ion-toolbar ion-buttons ion-button {
  width: 100%;
  height: 100%;
  --border-radius: 0;
}
ion-toolbar ion-buttons ion-button .btn-body {
  font-size: 15px;
}
ion-toolbar ion-buttons ion-button .btn-body ion-label {
  font-size: 12px;
}
ion-toolbar ion-buttons ion-button .btn-body div {
  width: 100%;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/core/components/gallery/pages/gallery-slider/gallery-slider.page.scss"],"names":[],"mappings":"AAAA;EAEE,WAAA;AAAF;;AAGA;EACE,sCAAA;EACA,2BAAA;EAEA,+BAAA;EA4BA,kBAAA;AA5BF;AAEE;EACE,6BAAA;EACA,uCAAA;AAAJ;AAGE;EAGE,WAAA;EACA,YAAA;EACA,kBAAA;AAHJ;AAKI;EACE,eAAA;AAHN;AAKM;EACE,eAAA;AAHR;AAMM;EACE,WAAA;EACA,kBAAA;AAJR","sourcesContent":[".img-box\n{\n  width: 100%;\n}\n\nion-toolbar {\n  --background: var(--footer-background);\n  --color: var(--footer-text);\n\n  box-shadow: 0px 0px 3px #3e3e3e;\n\n  ion-buttons ion-button.button-clear {\n    --color: var(--main-top-text);\n    --color-activated: var(--main-top-text);\n  }\n\n  ion-buttons ion-button {\n    //width: fit-content;\n    //  font-size: 13px;\n    width: 100%;\n    height: 100%;\n    --border-radius: 0;\n\n    .btn-body {\n      font-size: 15px;\n\n      ion-label {\n        font-size: 12px;\n      }\n\n      div {\n        width: 100%;\n        text-align: center;\n      }\n    }\n  }\n\n  --min-height: 56px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
