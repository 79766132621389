// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-label span {
  margin: 0 3px;
}`, "",{"version":3,"sources":["webpack://./src/core/pages/admin/pages/sections-manager/pages/section-items-manager/section-items-manager.page.scss"],"names":[],"mappings":"AAEE;EAEE,aAAA;AAFJ","sourcesContent":["ion-label\n{\n  span\n  {\n    margin: 0 3px;\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
