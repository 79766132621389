import {Component, Input, OnInit} from '@angular/core';

import {AppConfig} from 'src/core/conf/main';
import {Events} from "../../../../../framework/providers/events";
import {AdminService} from "../../../../../core/services/AdminService";

@Component({
    selector: 'app-quick-filter-field',
    templateUrl: './quick-filter-field.component.html',
    styleUrls: ['./quick-filter-field.component.scss'],
})
export class QuickFilterFieldComponent implements OnInit {
    public AppConfig = AppConfig;

    @Input()
    fieldTypes;
    @Input()
    chooseFilters;
    @Input()
    field;
    @Input()
    model;
    @Input()
    advancedFiltersByName;
    @Input()
    tableData;
    @Input()
    prefix;
    @Input()
    sectionName;
    @Input()
    exTableData;

    fieldData = {}

    presentingElement = null;

    popupPrefix = "selectTablePrefixrowClickEvent" + Math.floor(Math.random() * Math.floor(1000000));
    searchPrefix = "selectTvent" + Math.floor(Math.random() * Math.floor(1000000));

    constructor(public events: Events, public adminService: AdminService) {
    }

    ngOnInit() {
        if (!this.fieldTypes) {
            this.fieldTypes = this.adminService.getFieldsType(this.sectionName, this.exTableData);
        }
    }


    ngAfterViewInit() {
        this.presentingElement = document.querySelector('.ion-page');
        // setTimeout(function () {
        //     document.getElementById("open-modal").click();
        // }, 4000);
    }

    addSelectOption(field, optionId) {
        if (this.fieldTypes[field['name']]['filterSingle']) {
            field['options'] = [];
        }

        if (!field['options']) field['options'] = [];
        if (field['options'].indexOf(optionId) != -1) {
            field['options'].splice(field['options'].indexOf(optionId), 1);
        } else {
            field['options'].push(optionId);
        }
    }

    fieldSelect(field, choosedType) {
        field['chooseType'] = choosedType;

        field['open'] = true;
        if (field['chooseType'] == 'DateRange') {
            let me = this;
            setTimeout(function () {
                document.getElementById("open-modal1" + me.popupPrefix).click();
            }, 400);
        }
    }

    buttonClick() {
        this.events.publish(this.prefix + "_buttonclickfilterfield", this.field)
    }

    showField() {
        console.log(this.field);
    }
}
