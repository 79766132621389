import {Component, Input, OnInit} from '@angular/core';
import {hebrewColors} from "../../../../../../conf/main";

@Component({
  selector: 'app-filter-accordion',
  templateUrl: './filter-accordion.component.html',
  styleUrls: ['./filter-accordion.component.scss'],
})
export class FilterAccordionComponent implements OnInit {

  @Input()
  public filter:object;

  public activeProps:Array<any> = [];

  constructor() { }

  ngOnInit() {
    this.initActiveProps()
  }

  initActiveProps()
  {
    this.activeProps.length = 0;
    for(let prop of this.filter['data'])
    {
      let key = 'value'
      if(!prop[key]) key = 'name'

      // prop['active'] = this.filter['res'][prop[key]];
      if(prop['active'])
        this.activeProps.push(prop[key])
    }
  }

  filterChanged(filter) {
    // console.log("FILTER CHANGED", filter)
    // filter['active'] = !filter['active'];
    // this.updateActiveProps()
    this.initActiveProps();
  }

  getColor(prop) {
    return hebrewColors[prop['value']];
  }

}
