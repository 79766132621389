// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile-icon {
  font-size: 40px;
  color: #787878;
  height: 40px;
}`, "",{"version":3,"sources":["webpack://./src/core/pages/profile/components/profile-image/profile-image.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,cAAA;EACA,YAAA;AACF","sourcesContent":[".profile-icon {\n  font-size: 40px;\n  color: #787878;\n  height: 40px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
