// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.type-box {
  margin: 2px 0;
  padding-bottom: 5px;
  border-bottom: 3px solid var(--button-a-background);
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}`, "",{"version":3,"sources":["webpack://./src/core/pages/deals/pages/deal/deal.page.scss"],"names":[],"mappings":"AAAA;EAGE,aAAA;EAEA,mBAAA;EACA,mDAAA;EACA,+GAAA;AAFF","sourcesContent":[".type-box\n{\n  //background: #fff;\n  margin: 2px 0;\n  //padding: 10px;\n  padding-bottom: 5px;\n  border-bottom: 3px solid var(--button-a-background);\n  box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);\n}\n.type-box.no-padding\n{\n  //padding: 0px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
