import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, NavigationExtras} from "@angular/router";
import {Item} from "../../../framework/model/core/items/item";
import {Section} from '../../../framework/model/core/sections/section';
import {ActionSheetController, IonContent, NavController} from "@ionic/angular";
import {ModelProvider} from "../../../framework/providers/modelProvider/ModelProvider";
import {OrderService} from "../../services/OrderService";
import {BusinessService} from "../../services/BusinessService";
import {AppDataService} from "../../services/AppDataService";
import {Events} from "../../../framework/providers/events";
import {ModelToolsProvider} from "../../../framework/providers/model-tools/model-tools";
import {AppSettings} from "../../conf/appSettings";

@Component({
    selector: 'app-section',
    templateUrl: './section.page.html',
    styleUrls: ['./section.page.scss'],
})
export class SectionPage implements OnInit, OnDestroy {

    public sections: Array<Section> = [];
    public section: Section;
    public sectionId: any;
    public models: Array<Item> = [];
    public secmodels: Array<Item> = [];
    public options: object = {};

    public subsOptions: object = {};
    public itemsOptions: object = {};
    public cartToolbarOptions: object = {};

    public filters: object;
    public showItems: boolean = false; //wait until view is ready.
    public initData: boolean = true;
    public ready: boolean = false;

    public imageReady: boolean = true;

    public itemsPrefix: string = 'itemList_' + Math.floor(Math.random() * Math.floor(1000000));
    public advsPrefix: string = 'advSlider_' + +Math.floor(Math.random() * Math.floor(1000000));

    public catalog: boolean;
    public queue: Array<object> = [];
    public sub_sections: Array<Section> = [];

    public sectionsView;
    public settings;
    // public openFilterButtonOn = {'on': false};


    cart: any;
    sideSectionBarOpen: boolean = false;

    itemListOptions = {'hideToolBar': true, 'containerScrollHide': true};

    @ViewChild(IonContent, {read: IonContent}) mainContent: IonContent;

    topSections: Array<Section> = [];
    subSections: Array<Section> = [];

    constructor(
        public navCtrl: NavController,
        public events: Events,
        public modelTools: ModelToolsProvider,
        public route: ActivatedRoute,
        public businessService: BusinessService,
        public appDataService: AppDataService,
        public actionSheetController: ActionSheetController,
        public orderService: OrderService,
        public appSettings: AppSettings,
        public modelProvider: ModelProvider) {

        this.catalog = this.businessService.isCatalog();

        this.sectionId = this.route.snapshot.paramMap.get("id");
        this.route.queryParams.subscribe(params => {
            if (this.filters) return;
            if (params['filters'] && params['filters'] != '[object Object]') {
                this.filters = JSON.parse(JSON.stringify(params['filters']));
            }

            if (!this.filters) this.filters = {};
            this.filters['sectionId'] = this.sectionId;
            //console.log("this.filters:")
            //console.log(this.filters)

            if (params['disableInitData'])
                this.initData = false;

            // } else {
            this.section = params['model'];

            //console.log(this.section, this.sectionId)

            if (!this.section || !this.section.data) {
                this.listSection();
            }

            if (this.section) {
                this.events.publish('setHeaderSection', {'section': this.section});
            }

            //
            // if (!this.sectionId)
            //     this.sectionId = this.section.data.id;
        });


        this.events.subscribe('sectionClicked', (params) => {
            // this.section.data.images = [];
            // this.imageReady = false;
            let me = this;
            setTimeout(function () {
                me.setSection(params);
            })
        });

        this.events.subscribe('backQueue', (params) => {
            this.backQueue();
        });

        // this.sectionsView = 'scroll';
        this.sectionsView = this.businessService.screenSize[0] > 1000 ? 'cards' : 'scroll';

        //sectionPage_sideSectionBarOpen is open?
        if (this.orderService.sectionPage_sideSectionBarOpen) {
            this.sideSectionBarOpen = true;
        }

        this.settings = this.appSettings.getSettings('items');
        this.subsOptions['hide_section_counter'] = this.settings['hide_section_counter'];
        //console.log("SECTIN SET", this.settings, this.section)
    }

    ngOnInit() {
        this.cart = this.orderService.getCart();
        this.addToQueue();

        let me = this;
        me.ready = true;

        this.modelTools.getKey('sectionOptions').then((res: object) => {
            if (!res)
                return

            for (let key in res) {
                this.options[key] = res[key];
            }

            this.itemListOptions['big-screen'] = this.options['big-screen']
        });

        this.options['itemsView'] = 'big';

        if (this.section) {
            this.events.publish('setHeaderSection', {'section': this.section});
        }

        //sub and top sections
        this.topSections = this.appDataService.getSectionSubSections(this.section.data.sectionId);
        this.subSections = this.appDataService.getSectionSubSections(this.section.data.id);
    }

    setOptionsKey(key, value) {
        this.options[key] = value;
        this.modelTools.setKey('sectionOptions', this.options);

        this.itemListOptions['big-screen'] = this.options['big-screen']
    }

    addToQueue() {
        this.queue.push({'sectionId': this.sectionId, 'model': this.section});
    }

    backQueue() {
        this.queue.splice(-1, 1);
        this.setSection(this.queue[this.queue.length - 1], true);
        //console.logthis.queue)
    }

    listSection() {
        let models = this.appDataService.getSections([this.sectionId], true);

        if (!models.length) return;

        this.section = models[0];

        //console.log(this.section);

        if (this.section.data['is_kit']) {
            this.sub_sections = this.appDataService.getSectionSubSections(this.sectionId);
        }


        // this.modelProvider.list('section', {'id': this.sectionId}).then((res) => {
        //     this.section = res['models'][0];
        //     this.showItems = true;
        // })
    }

    setSection(params, skipQueue = false) {
        //console.logparams)
        this.section = this.orderService.copy(params['model']);
        this.sectionId = this.section.data.id;
        this.filters['sectionId'] = this.sectionId;
        this.filters['src'] = this.section.data.images[0];
        // //console.logthis.filters)
        this.events.publish(this.itemsPrefix + '_updateItemsFilter', this.filters);
        this.events.publish(this.itemsPrefix + '_updateSectionsFilter', this.filters);
        this.events.publish(this.advsPrefix + '_updateFilter', this.filters);

        if (!skipQueue)
            this.addToQueue()


        // return
        //
        // this.showItems = false;
        // let me = this;
        // // this.section = null;
        // setTimeout(function () {
        //     me.showItems = true;
        //     me.filters['sectionId'] = section.data.id;
        //     me.sectionId = section.data.id;
        //     me.listSection();
        // });
        // me.section = section;
        // });


    }

    // ionViewDidLoad() {//console.log("ionViewDidLoad")}
    // ionViewWillEnter() {//console.log("ionViewWillEnter")}
    // ionViewDidEnter() {//console.log("ionViewDidEnter")}
    // ionViewWillLeave() {//console.log("ionViewWillLeave")}
    // ionViewDidLeave() {//console.log("ionViewDidLeave")}
    // ionViewWillUnload() {//console.log("ionViewWillUnload")}

    ngAfterViewInit() {
        let me = this;
        setTimeout(function () {
            me.showItems = true;
        })
    }

    ngOnDestroy() {
        this.events.unsubscribe('sectionClicked');
        this.events.unsubscribe('backQueue');
    }

    onscroll(e, content) {
        this.mainContent.getScrollElement().then((res) => {
            e.detail['contentHeight'] = res.clientHeight
            // //console.log(res.scrollHeight, res.clientHeight, res.offsetHeight);
            this.events.publish('sectionOnScroll', e);
        })
        // this.events.publish('onscroll_innerpage', {on: on});
        // e.detail['content'] = content
    }

    async viewStaffSettings(model) {
        let buttons = this.orderService.viewStaffSettings(false, null, model, 'section');
        const actionSheet = await this.actionSheetController.create({
            header: this.modelTools.getTranslate('sectionSettings'),
            cssClass: 'my-custom-class',
            buttons: buttons
        });
        await actionSheet.present();

        const {role, data} = await actionSheet.onDidDismiss();
        //console.log('onDidDismiss resolved with role and data', role, data);
    }

    setSideSectionBarStatus(status) {
        this.sideSectionBarOpen = status;
        this.orderService.sectionPage_sideSectionBarOpen = status;
    }


    onScroll(e) {
        this.events.publish('sectionOnScroll', e)
    }

    changeView(key) {
        this.options['itemsView'] = key;
        this.events.publish(this.itemsPrefix + '_changeView', {'view': key});
    }


    goTo(page, params) {
        params['filters'] = JSON.parse(JSON.stringify(this.filters));
        params['disableInitData'] = !this.initData;
        ////console.log'PRARMS', params)

        let navigationExtras: NavigationExtras = {
            queryParams: params,
        };

        this.navCtrl.navigateForward(page, navigationExtras)
    }
}
