import { Component, OnInit } from '@angular/core';
import {BusinessService} from "../../services/BusinessService";
import {Panel} from "../../../framework/model/core/panel/panel";

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss'],
})
export class SideBarComponent implements OnInit {

  public panels:Array<Panel> = [];

  constructor(public businessService: BusinessService) { }

  ngOnInit() {}

}
