import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {BusinessService} from "../../../../core/services/BusinessService";
import {IonContent, NavController} from "@ionic/angular";
import {ModelToolsProvider} from "../../../../framework/providers/model-tools/model-tools";
import {ModelProvider} from "../../../../framework/providers/modelProvider/ModelProvider";
import {AppConfig} from "../../../../core/conf/main";
import {Events} from "../../../../framework/providers/events";

//forms fill

@Component({
    selector: 'app-profile-form',
    templateUrl: './profile-form.component.html',
    styleUrls: ['./profile-form.component.scss'],
})
export class ProfileFormComponent implements OnInit {
    @ViewChild(IonContent, {'static': true}) ionContent: IonContent;

    appConfig = AppConfig;
    models = [];
    prefix = 'taarif';
    tablExtData = {'showEdit': false, 'isOpen': false};

    @Input()
    viewOnly = false;

    @Input()
    filters = {
        'setPrefix': true,
        'formKey': 'signup',
        'modelId': null
    };

    currentSectionKey = null;
    currentSectionIndex = 0;
    tabCondition;

    ready = false;

    showTable = true;
    sectionKeys;
    errors = {};
    formResults;
    tableData;
    tabs;

    constructor(public businessService: BusinessService,
                public navCtrl: NavController,
                public events: Events,
                public modelTools: ModelToolsProvider,
                public modelProvider: ModelProvider) {
        this.tableData = {};
    }

    ngOnInit() {
        let me = this;
        this.modelProvider.custom("formData", this.filters).then(function (res) {
            //console.log"res:");
            //console.logres);
            me.formResults = res;
            me.tableData = res['tableData'];
            me.tabs = res['tabs'];
            me.tabCondition = res['tabCondition'] ? res['tabCondition'] : {};
            //console.logme.tabs);

            me.currentSectionIndex = 0;

            me.sectionKeys = [me.currentSectionIndex];
            me.currentSectionKey = me.tabs[me.currentSectionIndex];

            //console.logme.tableData);
            me.ready = true;
        });

        this.events.subscribe("formFillIsDon_" + this.prefix, (data) => {
            this.nextTab();
        });
    }


    ngOnDestroy(): void {
        this.events.unsubscribe("formFillIsDon_" + this.prefix);
    }

    prevTab() {
        this.setTab(this.currentSectionIndex - 1)
    }

    nextTab() {
        let me = this;
        //console.logme.tabs.length);
        //console.logme.currentSectionIndex);

        // if (me.currentSectionIndex < me.tabs.length - 1) {
        //     this.setTab(me.currentSectionIndex + 1)
        // } else {
        this.filters['formPrefix'] = me.formResults['formPrefix'];
        this.filters['tabIndex'] = me.currentSectionIndex;

        me.modelProvider.customPost("formData", {'formData': me.tableData}, me.filters).then(function (results) {
            let res = results['data'];
            //console.logme.tableData);
            //console.logres);

            if (res['error'])
            {
                me.modelTools.presentToast(res['error']);
                return;
            }
            if (res['isDone'])
            {
                me.modelTools.presentToast(res['resText']);
                me.back();
                return;
            }

            if (res['nextTab']) {
                for (let k in res['nextTab']) {
                    me.tableData[k] = res['nextTab'][k];
                }
            }
            //console.logme.tableData);
            me.currentSectionIndex++;
            me.currentSectionKey = me.tabs[me.currentSectionIndex];

            me.ready = false;
            setTimeout(function () {
                me.ready = true;
            });

            // for (let key in me.errors) {
            //     delete (me.errors[key]);
            // }
            //
            // if (res['data']['result']) {
            //     me.modelTools.presentToast(res['data']['resText']);
            //     me.back();
            // } else {
            //     me.modelTools.presentToast(res['data']['resText']);
            //     for (let key in res['data']['errors']) {
            //         me.errors[key] = res['data']['errors'][key];
            //     }
            // }
        });
        // }
    }

    setTab(currentSectionIndex) {
        let me = this;
        me.currentSectionIndex = currentSectionIndex;
        me.sectionKeys = [me.currentSectionIndex];
        me.ready = false;
        me.currentSectionKey = me.tabs[me.currentSectionIndex];
        setTimeout(function () {
            me.ready = true;
        });
    }

    back() {
        //console.log'formIsDone1!!!SADSADASD')
        this.events.publish('formIsDone');
        // this.navCtrl.navigateBack('login');
    }

}
