import { Component, OnInit } from '@angular/core';
import {SectionsManagerService} from "../../providers/sectionManagerService";

@Component({
  selector: 'sections-manager-summary',
  templateUrl: './sections-manager-summary.page.html',
  styleUrls: ['./sections-manager-summary.page.scss'],
})
export class SectionsManagerSummaryPage implements OnInit {

  public sectionsData:object;
  public itemsData:object;
  public transferData:object;

  constructor(public sectionsManagerService:SectionsManagerService) {
    this.sectionsData = this.sectionsManagerService.getSectionsData();
    this.itemsData = this.sectionsManagerService.getItemsData();
    this.transferData = this.sectionsManagerService.getTransferData();
    //console.logthis.transferData)
  }

  ngOnInit() {
  }

}