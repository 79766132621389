// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bar {
  padding: 5px;
}

.box {
  padding: 10px 0;
}

.title {
  font-size: 15px;
}`, "",{"version":3,"sources":["webpack://./src/core/pages/order/components/new-order/new-order.component.scss"],"names":[],"mappings":"AAAA;EAEE,YAAA;AAAF;;AAGA;EAEE,eAAA;AADF;;AAIA;EAEE,eAAA;AAFF","sourcesContent":[".bar\n{\n  padding: 5px;\n}\n\n.box\n{\n  padding: 10px 0;\n}\n\n.title\n{\n  font-size: 15px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
