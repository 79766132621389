import {Injectable} from '@angular/core';
import {ModelProvider} from "../../framework/providers/modelProvider/ModelProvider";
import {TranslateService} from "@ngx-translate/core";

import {AppConfig} from "../conf/main";
import {OrderService} from "./OrderService";
import {BusinessService} from "./BusinessService";
import {Item} from "../../framework/model/core/items/item";
import {ModelToImage} from "../../framework/model/core/general/modelToImage";
import {Section} from "../../framework/model/core/sections/section";
import {DatePipe} from "@angular/common";
import {ModelClasses} from "../model/modelClasses";
import {ModelToolsProvider} from "../../framework/providers/model-tools/model-tools";
import {Events} from "../../framework/providers/events";
import {AppSettings} from "../conf/appSettings";

@Injectable()
export class AppDataService {

    public business;
    public appdata;
    public pricesdata;
    public pricesList;
    public rates;
    public userpricesdata = {};

    public items = {};
    public sections = {};

    public appuser;
    public storageKey;
    public settings;

    private pricesDataListPrefix = 'pricesDataListPrefix';

    //update when cart is chosen
    public currentCartId;

    constructor(private modelProvider: ModelProvider,
                public translate: TranslateService,
                private datePipe: DatePipe,
                public appSettings:AppSettings,
                private modelTools: ModelToolsProvider,
                private modelClasses: ModelClasses,
                public events: Events) {
    }

    ngOnInit() {
        this.settings = this.appSettings.getSettings('items')
    }

    start() {
        this.events.subscribe('appuserChanged', (res) => {
            this.appuser = res['appuser'];
            this.setPrices(res['skip_storage']);
        });

        this.events.subscribe('agentCompanyChanged', (res) => {
            this.setPrices(true);
        });

        this.events.subscribe('businessSets', (res) => {
            this.business = res['data'];
            this.setStorageKey(this.business.data.prefix)
            // this.storageKey =  'appdata_' + this.business.data.prefix;
            this.setAppData();
            // this.setPrices(true);
            this.settings = this.appSettings.getSettings('items')
        });

        this.events.subscribe('noUserInStorage', (res) => {
            this.setPrices()
        });

        this.events.subscribe('getDataPrices', (res) => {
            this.setPrices(res['skipStorage'], res['extra_filters']);
        });

        this.events.subscribe('pricesDataListPrefix', (res) => {
            this.initialPricesData(res)
        });

        this.setPricesInterval();
    }

    setPricesInterval() {
        let me = this;
        let timer = 3600000;
        setInterval(function () {
            // ////console.log'GET PRICES INTERVAL!');
            me.setPrices();
        }, timer)
    }

    setStorageKey(prefix) {
        this.storageKey = 'appdata_' + prefix;
    }


    setAppData() {

        // let key = 'appdata_' + this.business.data.prefix;
        this.modelTools.getKey(this.storageKey).then((res) => {
            // ////console.logres);
            // ////console.logthis.business);

            if (res) {
                this.appdata = res;

                // ////console.log'check version of data:');
                // ////console.logthis.appdata['version']);
                // ////console.logthis.business.data.GlobalSettingsData['dataVersion']);

                if (this.appdata['version'] != this.business.data.GlobalSettingsData['dataVersion']) {
                    ////console.log"UPDATE DATA - new version !!");
                    this.getAppData();
                    return;
                }

                //console.log(this.appdata)
                ////console.log'EXS DATA !');
                this.getUpdatedData(res['date']);
                this.pusblishAppData(false);
            } else {
                //console.log(this.appdata)
                ////console.log"NEW DATA !!");
                this.getAppData();
            }
        });

        this.modelTools.getKey(this.storageKey + '_prices_data').then((res) => {
            if (res) {
                this.initialPricesData(res)
                // this.pricesdata = res;
                // this.setItemsPrices();
            }
        });
    }

    // getAppData() {
    //     this.modelProvider.custom('getAppData', {'setPrefix': true}).then((res) => {
    //         this.appdata = res;
    //         this.saveAppData();
    //         this.pusblishAppData()
    //         // this.setPrices()
    // http://testserver-a872.kxcdn.com/static/appdata/citycell_appdata.txt
    getAppData(version = null) {
        let key = this.modelTools.getRandomize();
        let ver = version ? version : this.business.data.GlobalSettingsData.dataVersion;
        let domain = AppConfig.CDN_URL_DOMAIN;
        // let url = AppConfig.CDN_URL_DOMAIN_PREFIX + AppConfig.bisPrefix + '_appdata' + ver + '.txt';
        let url = AppConfig.CDN_URL_DOMAIN_PREFIX + AppConfig.bisPrefix + '_appdatap' + (ver) + '.txt';
        let filters = {'setPrefix': true, 'a':key}
        if (!AppConfig.cdnData) {
            url = 'getAppData';
            domain = ''
        }

        // ////console.log'###### LIST APP DATA')

        // this.modelProvider.custom(AppConfig.CDN_URL_DOMAIN_PREFIX + AppConfig.bisPrefix + '_appdata.txt', {'setPrefix': true}, AppConfig.CDN_URL_DOMAIN).then((res: any) => {
        this.modelProvider.custom(url, filters , domain).then((res: any) => {
            // //////console.logres);
            // this.modelProvider.custom(AppConfig.CDN_URL_DOMAIN_PREFIX + AppConfig.bisPrefix + '_appdata.txt', {'setPrefix': true}, AppConfig.CDN_URL_DOMAIN).then((res: any) => {
            // this.modelProvider.custom('getAppData', {'setPrefix': true}).then((res: any) => {
            // ////////console.logres);

            // ////////console.logres.e.error.text);
            // ////////console.logatob(res.e.error.text));
            if (res.e) {
                let appdataj = atob(res.e.error.text);
                this.appdata = JSON.parse(appdataj);
                // this.saveAppData();
                // this.pusblishAppData()
                // this.setPrices();
            } else {
                this.appdata = res;
                this.items = {};
                // this.appdata = JSON.parse(res);
                // this.setPrices();
            }

            // this.updateModels();
            this.saveAppData();
            this.pusblishAppData()
            ////console.log('PUBLISH - appDataSets', this.appdata);

        }, (res) => {
            // //////console.log"#REJETED !!!")
            // //////console.logres)
        });
    }

    pusblishAppData(new_data = true) {
        let me = this;
        setTimeout(function () {
            me.events.publish('appDataSets');
            me.events.publish('appDataSets_mainservice');
            me.events.publish('businessAppDataSets');

            if (new_data) {
                ////console.log("NEW APP DATA.")
                me.events.publish('new_appDataSets');
            }

        });
    }

    getUpdatedData(date) {
        return
        this.modelProvider.custom('getUpdateAppData', {'date': date, 'setPrefix': true}).then((res) => {
            for (let k in res['items']) {
                if (this.appdata['items']) {
                    this.setUpdatedItem(res['items'][k])
                }
            }

            for (let k in res['sections']) {
                if (this.appdata['sections'] && this.appdata['sections'][k]) {
                    this.appdata['sections'][k]['data'] = res['sections'][k]['data'];

                    // for(let i in res['sections'][k]['items']) // merge arrays.
                    // {
                    //     let id = res['sections'][k]['items'][i];
                    //     if(this.appdata['sections'][k]['items'].indexOf(id) != -1) continue; // if already exist - skip.
                    //     this.appdata['sections'][k]['items'].push(id);
                    // }
                    if (res['sections'][k]['items'])
                        this.appdata['sections'][k]['items'] = res['sections'][k]['items'];

                }

            }

            let keysToUpdate = ['mainAdvs', 'subAdvs', 'deals'];
            for (let k in keysToUpdate) {
                this.setUpdateModelKey(keysToUpdate[k], res[keysToUpdate[k]])
            }

            this.saveAppData(true);

            // this.appdata = res;
            // this.appdata['date'] = new Date();
            //
            // this.saveInStorage(this.storageKey, this.appdata);
            // this.setPrices()
        });
    }

    private setUpdatedItem(itemdata) {
        if (!this.appdata['items'])
            return;

        let k = itemdata.id;
        let exitem = this.appdata['items'][k];
        if (!exitem) {
            this.setItem(itemdata)
            return;
        }

        for (let k in itemdata) {
            if (k == 'price')
                continue;
            exitem[k] = itemdata[k];
        }

        // if(this.appdata['items'] && this.appdata['items'][k]) {
        // exitem['data'] = itemdata;
        // }

    }

    private setPrices(skipStorage = false, extra_filters = null) {
        let username = this.appuser ? this.appuser.data.username : null;
        // console.trace()

        let filters = {
            'listPrefix': this.pricesDataListPrefix,
            'skipStorage': skipStorage,
            'setPrefix': true,
            'includeRates': true,
            'username': username
        }

        if(extra_filters){
            for(let key in extra_filters){
                filters[key] = extra_filters[key]
            }
        }

        this.modelProvider.storageCustom('getAppPricesData', filters,'',false, (res) => {
            this.events.publish('setCart', res['db_saved_carts']);

            this.initialPricesData(res);

            //to orderService - will show on profile page the open carts.

            // this.setUserPrices();
        });
    }

    initialPricesData(res) {
        if(!res['sales'])
            return
        this.pricesdata = res['items'] ? res['items'] : {};
        this.rates = res['rates'] ? res['rates'] : {};
        this.pricesList = res;
        this.saveAppPricesData();
        this.setItemsPrices();
        this.events.publish('appPriceSets');
        this.events.publish('activeTicketChanged');
    }

    getPricesListKey(key) {
        // ////console.log("getPricesListKey", this.pricesList[key]);
        return this.pricesList[key]
    }

    private setUserPrices() {
        this.modelProvider.custom('getUserPrices', {'setPrefix': true}).then((res) => {
            this.userpricesdata = res;

            this.setItemsPrices();
        });
    }

    private setItemsPrices() {
        for (let k in this.items) {
            let item = this.items[k];
            // if(!this.pricesdata[item.data.id])
            //     item.data.price = 0;
            // else {
            item.data.price = this.getItemPrice(item);

            if (!item.cart_model.data['in_cart']) {
                item.cart_model.initValues(item);
            }
            // }


            // this.events.publish('itemPriceChanged_' + item.data.id);

        }
        //console.log"UPDATED");
        //console.logthis.items)
    }


    getSectionSubSections(sectionId) {
        let sections = this.appdata['sections'];
        for (let k in sections) {
            if (k == sectionId) {
                return this.getSections(sections[k]['sections'])
            }
        }
        return [];
    }

    getSectionItems(filters) {
        // ////console.logfilters)
        let sectionId = filters['sectionId'];
        let res = {'models': [], 'modelsCount': 0};
        let sections = this.appdata['sections'];
        for (let k in sections) {
            if (k == sectionId) {
                // ////console.logk, sections[k]['items']);
                // ////console.logk, sections);
                filters['skipSubs'] = filters['skipSubs'] !== false ? true : false;

                // if(!filters['skipped'])
                //     filters['skipped'] = 0;
                // filters['offset']+=filters['skipped'];

                let itemsres = this.getItems(sections[k]['items'], filters)

                // filters['skipped']+=itemsres['skipped'];
                // ////console.logsectionId, sections[k]['items'], itemsres)
                res['models'] = itemsres['res'];
                if (!filters['skipped']) filters['skipped'] = 0;
                filters['skipped'] += itemsres['skipped'];
                res['modelsCount'] = sections[k]['items'].length - filters['skipped'];
                // ////console.log("SEC ITEMS LENGTH", sections[k]['items'].length, res['modelsCount'], 'skip-', itemsres['skipped'], filters['skipped'])

                return res
            }
        }
        return res;
    }

    getItemsByKey(key, filters) {
        let res = {'models': [], 'modelsCount': 0};
        if (!this.pricesList[key]) return res;
        let itemsres = this.getItems(this.pricesList[key], filters);
        res['models'] = itemsres['res'];
        res['modelsCount'] = this.pricesList[key].length - itemsres['skipped'];

        // ////console.log"###");
        // ////console.logres);
        return res
    }

    searchItems(key, filters) {
        let res = {'models': [], 'modelsCount': 0};
        let items = this.appdata['items'];

        let keyitems = this.appdata[key];
        if (!keyitems)
            keyitems = this.pricesList[key];

        let itemsarr = [];
        let search = filters['search'];
        if (!search) return res;

        // ////console.log"SECTV ITEMS", items)

        //on search!!!
        //search on ly from here!!!
        //search!!!

        let filteredData = []

        for (let work of this.possibleCombinations(search)) {
            //advanced only if not found at least 10.
            if (itemsarr.length > 10) break;

            for (let k in items) {
                if (itemsarr.length > 100)
                    break;
                if (keyitems) {
                    if (!keyitems.includes(items[k]['id'])) continue;
                }
                if (!items[k]['stock'] && this.business.data.GlobalSettingsData.onStockOnly)
                    continue

                if(items[k].topItem && filters['noSubItems']){
                    continue
                }

                if (this.searchInItem(items[k], work, itemsarr, false)) {
                    itemsarr.push(items[k]['id'])
                    filteredData.push(items[k])
                }
            }
        }

        filteredData.sort((a, b) => {
            if (a['stock'] > b['stock']) {
                return -1;
            } else {
                return 1;
            }
        });

        itemsarr = filteredData.map(item => item.id);

        // filters['limit'] = items.length;
        // //console.log('itemsarr', itemsarr.length, filters)

        let itemsres = this.getItems(itemsarr, filters, {'skipSearch': true});
        res['models'] = itemsres['res']
        res['modelsCount'] = itemsarr.length - itemsres['skipped'];
        return res
    }


    possibleCombinations(str){
        if(this.settings['disable_item_name_combinations'])
            return [str]

        let combinations = [];
        let arr = str.split(' ')
        let k = arr.length

        while(k > 0)
        {
            for(let p=0; p<(arr.length/k + arr.length%k); p++) {
                let options = []
                for (let i = 0; i < k; i++) {
                    if(i+p > arr.length-1)
                        continue
                    options.push(arr[i+p])
                }
                if(options.length == k)
                    combinations.push(options.join(' '))

            }

            k-=1
        }
        return combinations;
    }

    searchInItem(item, search, itemsarr, advanced = false) {
        let fields = ['code', 'name', 'makerName', 'barcode', 'size_value', 'finishing_value'];

        search = search.toLowerCase();
        if (itemsarr.includes(item['id']))
            return

        for (let i in fields) {
            let value = item[fields[i]] + '';
            let words = [search];
            if (advanced) {
                words = search.split(' ')
            }
            for (let word of words) {
                if (value.toLowerCase().includes(word)) {
                    return true
                }
            }
            // ////console.logitems[k], itemsarr)
        }
        return false
    }

    getModelsByKey(key, modelName, filters = {}) {
        let res = {'models': [], 'modelsCount': 0};
        let data = this.appdata[key];
        // //////console.logkey, modelName, data)
        // //////console.logthis.appdata)
        if (!data)
            return -1;
        // ////console.logkey, modelName, data.length)
        if (modelName == 'section') {
            res['models'] = this.getSections(data);
            res['modelsCount'] = data.length;
        } else {
            res['models'] = this.getModelsData(data, modelName, filters);
            res['modelsCount'] = data.length;
        }
        return res
    }

    getModelsByArr(data, modelName, filters = {}) {
        let res = {'models': [], 'modelsCount': 0};

        if (modelName == 'section') {
            res['models'] = this.getSections(data);
            res['modelsCount'] = data.length;
        } else if (modelName == 'item') {
            let itemsres = this.getItems(data, filters);
            res['models'] = itemsres['res'];
            res['modelsCount'] = data.length - itemsres['skipped'];
        } else {
            res['models'] = this.getModelsData(data, modelName, filters);
            res['modelsCount'] = data.length;
        }
        return res
    }

    getModelById(key, modelName, id) {
        ////console.logthis.appdata, key)
        let data = this.appdata[key];
        if (!data)
            return -1;
        for (let k in data) {
            if (data[k]['id'] == id) {
                return this.getModelData(data[k], modelName);
            }
        }
    }

    getModelsData(data, modelName, filters) {

        let offset = filters['offset'] ? filters['offset'] : 0;
        let limit = filters['limit'] > data.length || !filters['limit'] ? data.length : filters['limit'];

        if(filters.hasOwnProperty('group_id')){
            data = data.filter((x) =>
                x.group_id == filters['group_id'] ||
                    (x.group_id && x.group_id.split(',').includes(filters['group_id'] || (x.group_id == 'groups_only' && filters['group_id']))))
        }

        // //////console.logdata, modelName, filters, offset, limit)
        // ////////console.logdata, modelName, filters)
        let res = [];
        for (let k = offset; res.length < limit; k++) {
            // ////////console.log'OFFSET K', k, limit, data, data[k])
            if (!data[k]) return res;
            let model = this.getModelData(data[k], modelName);
            res.push(model);
        }

        // ////////console.log'MODEL KEY DATA', modelName, res);

        return res
    }

    getModelData(data, modelName) {
        let cls = this.modelClasses.getClass(modelName)
        let model = new cls(data);
        // ////////console.logdata[k], model)
        if (data.images)
            this.setImages(model);

        // ////////console.log'modelData', model)

        return model
    }

    setUpdateModelKey(key, updatedarr) {
        if (!this.appdata[key]) return;
        for (let u in updatedarr) {
            let found = false;
            let modeldata = updatedarr[u];
            for (let k in this.appdata[key]) {
                if (this.appdata[key][k].id == modeldata.id) {
                    this.appdata[key][k] = modeldata;
                    found = true;
                }
            }

            if (!found) {
                this.appdata[key].push(modeldata);
            }
        }

        if (updatedarr && updatedarr.length) {
            this.events.publish(key + '_appDataUpdated')
        }


        // ////////console.log'updated '+key, this.appdata[key]);
    }

    getRates() {
        return this.rates;
    }

    public getDataByKey(key) {
        return this.appdata[key];
    }


    public getSections(secssarr, force=false) {
        let sections = this.appdata['sections'];
        let res = [];
        let user_sections = this.getPricesListKey('user_sections');
        ////console.log('GET SECTIONS', user_sections, secssarr)
        for (let k in secssarr) {
            if (!sections[secssarr[k]])
                continue;

            if (this.getPricesListKey('user_items_only') && !user_sections.includes(secssarr[k]))
                continue;

            let section = this.getSection(sections[secssarr[k]]['data']);

            if(section.data.includeInMenu === false && !force) continue

            res.push(section)
        }

        return res
    }

    private getSection(data) {
        if (this.sections[data.id])
            return this.sections[data.id];
        else
            return this.setSection(data);
    }

    private setSection(data) {
        let section = new Section(data);

        this.setImages(section);

        this.sections[section.data.id] = section;

        return section
    }

    public addItems(items) {
        // ////////console.log'items', items)
        // ////////console.log'this.items', this.items)
        for (let k in items) {
            let oritem;
            let item = items[k];

            if (!this.items[item.data.id]) {
                oritem = this.setItem(this.appdata['items'][item.data.id]);
                if (!oritem) continue;
                this.addItemFileds(oritem, items[k])
            } else {
                oritem = this.items[item.data.id];
                this.addItemFileds(oritem, items[k])
                // items[k].data = item.data;
            }

            // ////////console.logitem);

            item.data.price = this.getItemPrice(item);
        }
    }

    private addItemFileds(oritem, toitem) {
        // for(let key in toitem.data)
        // {
        // if(key == 'currencyCode') continue;

        toitem.data = oritem.data;
        // }
    }

    getItems(itemsarr, filters, options = {}) {
        // ////console.logitemsarr)
        let items = this.appdata['items'];
        let res = [];

        let ids = []

        // //console.log(itemsarr.length, filters['offset'], filters['limit'])

        let k = filters['offset'] ? filters['offset'] : 0; // offset
        if (filters['skipped'])
            k += filters['skipped'];
        let i = 0; // limit counter;
        let skipped = 0; // skipped counter;
        while (k < itemsarr.length) {
            // ////console.log('k', k, itemsarr[k]);
            if (!items[itemsarr[k]]) {
                k++;
                continue;
            }

            let item = this.getItem(items[itemsarr[k]]);
            // ////console.logres.length, filters['limit'], item)
            if (item.data.topItem && filters['skipSubs']) {
                k++;
                skipped++;
                continue;
            }

            if (this.pricesList['hidden_items']) {
                let hideItem = this.pricesList['hidden_items'].includes(item.data.id);
                if (hideItem) {
                    k++;
                    skipped++;
                    continue;
                }
            }

            if (item.data.hidden || this.pricesList['user_items_only']) {
                let itemToUser = false;
                if (this.pricesList['itemToUser']) {
                    itemToUser = this.pricesList['itemToUser'].includes(item.data.id)
                }

                if (!itemToUser) {
                    k++;
                    skipped++;
                    continue;
                }
            }

            if (filters['selfSale'] && item.data.sale && !item.data.sale['selfSale']) {
                k++;
                skipped++;
                continue;
            }

            if (filters['itemMaker'] && item.data.itemMaker != filters['itemMaker']) {
                k++;
                skipped++;
                continue;
            }

            if (filters['search'] && filters['search'].length && !options['skipSearch'] && !this.searchInItem(item.data, filters['search'], ids)) {
                k++;
                skipped++;
                continue;
            }

            // ////console.logitem)
            res.push(item);
            ids.push(itemsarr[k]);
            i++;
            if (i >= filters['limit']) {
                // filters['offset'] = k;
                // ////console.log{'res':res, 'skipped':skipped})
                return {'res': res, 'skipped': skipped};
            }
            k++;
        }

        // ////console.log{'res':res, 'skipped':skipped})
        return {'res': res, 'skipped': skipped}
    }

    public getItemModel(id) {
        if (this.items[id]) {
            // ////console.log('return item:', id);
            return this.items[id];
        } else {
            if (!this.appdata['items']) return;
            // //console.log('set new item:')
            // ////console.log(id);
            // //console.log(id, this.appdata['items']);
            return this.setItem(this.appdata['items'][id]);
        }
    }

    private getItem(data) {
        if (this.items[data.id]) {
            let item = this.items[data.id];
            item.data.price = this.getItemPrice(item);

            // ////console.log'***', data.id, this.items[data.id], item.data.price)
            return item;
        } else
            return this.setItem(data);
    }

    private setItem(data) {
        if (!data) {
            ////console.log("APP DATA ITEM SKIPPED");
            return;
        }

        let item = new Item(data, this.getPricesListKey('coupons'), this.getPricesListKey('currency_data'));

        // ////////console.log'###'+ item.data.id, JSON.stringify(this.items[item.data.id]));

        item.data.price = this.getItemPrice(item);
        item.cart_model.initValues(item);

        this.setImages(item);

        if (!this.appdata['items'][item.data.id])
            this.appdata['items'][item.data.id] = item.data;
        this.items[item.data.id] = item;

        return item
    }

    getItemPrice(item) {
        let price;
        let oldprice;

        let pricesdata = this.pricesdata;
        let sales_allow = this.getPricesListKey('company_settings')['sales_allow']

        // if(this.userpricesdata[item.data.id]) {
        //     price = this.userpricesdata[item.data.id];
        // }

        if (pricesdata[item.data.id]) {
            let price_res = pricesdata[item.data.id]
            // else {
            price = price_res['price'];
            item.data.sale = price_res['sale'];
            item.data.section_sales = price_res['section_sales'];
            if (item.data.sale) {
                // ////console.logitem.data.sale['expirationDate'], new Date(), this.compareDate(item.data.sale['expirationDate'], new Date()), item)
                if (this.compareDate(item.data.sale['expirationDate'], new Date()) == -1) {
                    item.data.sale = null;
                }
            }

            item.data.discount = price_res['discount'];
            item.data.basePrice = price_res['basePrice'];
            item.data.extraData = price_res['extraData'];
            item.data.baseDistributorPrice = price_res['baseDistributorPrice'];

            if(price_res['currencyCode']){
                item.data.currencyCode = price_res['currencyCode']
                item.data.item_currency = price_res['currencyCode'][0]
                item.agentToolData.setValues({'currency_code': item.data.item_currency, 'amount': item.agentToolData.getValue('amount')});
            }

            // item.data.rate_data = pricesdata[item.data.id]['rate_data'];

            item.data.ordered_data = pricesdata[item.data.id]['ordered_data'];
            if (this.pricesList['itemToUser'])
                item.data.favorite = this.pricesList['itemToUser'].includes(item.data.id);

            if (pricesdata[item.data.id].hasOwnProperty('stock'))
                item.data.stock = pricesdata[item.data.id]['stock'];

            if(pricesdata[item.data.id]['extra_title']){
                item.data['extra_title'] = pricesdata[item.data.id]['extra_title'];
            }

            //
            // ////////console.log"#SALE")
            // ////////console.logitem.data.price)
            // ////////console.logprice)
            // ////////console.logitem.data.id)
            // ////////console.logitem.data.sale)

            if (item.data.sale && sales_allow) {
                if (!item.data.sale['amountToBuy'] || item.data.sale['bonus']) {
                    if (price != item.data.sale['priceAfterDiscount']) {
                        oldprice = price;
                        price = item.data.sale['priceAfterDiscount'];
                    }
                }
            }
            else if(!sales_allow)
            {
                item.data.sale = null;
            }
            else if(item.data.discount){
                oldprice = price;
                price -= price * item.data.discount / 100
            }


        } else {
            price = 0;
        }

        // }

        item.data.oldprice = oldprice;
        return price;
    }

    compareDate(date1: Date, date2: Date) {
        let d1 = new Date(date1);
        let d2 = new Date(date2);

        let same = d1.getTime() === d2.getTime();
        if (same) return 0;

        if (d1 > d2) return 1;

        if (d1 < d2) return -1;
    }

    updateModels() {
        for (let k in this.items) {
            let item = this.appdata['items'][k]
            this.updateModelFields(item, this.items[k], ['price']);
        }

        for (let k in this.sections) {
            let model = this.appdata['sections'][k]
            this.updateModelFields(model, this.sections[k]);
        }


    }

    updateModelFields(from, to, except = []) {
        for (let key in from) {
            if (except.includes(key)) continue;
            to.data[key] = from[key];
        }
    }

    private setImages(model) {
        // ////////console.log'setImages', JSON.stringify(model));
        for (let k in model.data['images']) {
            if (model.data['images'][k].data) continue;
            model.data['images'][k] = new ModelToImage({'imagefullurl': model.data['images'][k]});
        }
    }

    private saveAppData(setDate = false) {
        if (setDate)
            this.appdata['date'] = this.getCurrentDate();
        this.saveInStorage(this.storageKey, this.appdata);
    }

    private saveAppPricesData() {
        this.pricesdata['date'] = this.getCurrentDate();
        this.saveInStorage(this.storageKey + '_prices_data', this.pricesList);
    }

    private saveInStorage(key, data) {
        this.modelTools.setKey(key, data);
    }

    private getCurrentDate() {
        let date = this.datePipe.transform(new Date(), 'yyyy-MM-dd H:mm:ss');
        return date;
    }
}










