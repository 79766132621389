import {Component, Input, OnInit} from '@angular/core';
import {Item} from "../../../../../framework/model/core/items/item";
import { NavController} from "@ionic/angular";
import {ModelToolsProvider} from "../../../../../framework/providers/model-tools/model-tools";
import {hebrewColors} from "../../../../conf/main";
import {Events} from "../../../../../framework/providers/events";
import {ItemProvider} from "../../providers/item-provider/item-provider.service";

@Component({
  selector: 'item-props-view',
  templateUrl: './item-props-view.component.html',
  styleUrls: ['./item-props-view.component.scss'],
})
export class ItemPropsViewComponent implements OnInit {

  @Input()
  public model:Item;

  @Input()
  public chooseAble:boolean = false;

  @Input()
  public lables:boolean = true;

  @Input()
  public title:string;

  @Input()
  public small:boolean = false;

  @Input()
  public openItem:boolean = true;

  @Input()
  public setAutoProp:boolean = false;

  @Input()
  public changePrefix:string;

  @Input()
  public currentProp:object = {};

  public propertiesData:Array<any>;

  constructor(public navCtrl:NavController, public modelToolsProvider:ModelToolsProvider, public events:Events, public itemProvider:ItemProvider) { }

  ngOnInit() {

    this.propertiesData = this.model.data.itemPropertyData;

    // if(this.setAutoProp)
    // {
    //   for(let k in this.propertiesData)
    //   {
    //     this.openPropItem(this.propertiesData[k]['data'][0])
    //     return;
    //   }
    // }
  }

  openPropItem(prop)
  {
    if(!this.chooseAble) return;
    if(prop['stock']<=0) return;
    if(this.model.data.itemPropertyData.length < 2) return;

    this.currentProp['id'] = prop['id'];
    this.currentProp['propId'] = prop['id'];
    this.currentProp['prop'] = prop;
    this.currentProp['modelId'] = prop['item_id'];

    this.events.publish(this.changePrefix, this.currentProp);

    if(!this.openItem)
    {
      return;
    }

    let item = this.itemProvider.getItemById(prop['item_id']);

    this.itemProvider.openItem(item, null, false,{'currentProp':this.currentProp})


    // this.modelToolsProvider.goTo('item/'+prop['item_id'], {'prop':prop})

  }

  getColor(prop)
  {
    return hebrewColors[prop['value']];
  }

}
