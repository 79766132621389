import {Component, Input, OnInit} from '@angular/core';
import {Order} from "../../../../../framework/model/core/order/order";
import {ModelToolsProvider} from "../../../../../framework/providers/model-tools/model-tools";
import {AppSettings} from "../../../../conf/appSettings";
import {OrderService} from "../../../../services/OrderService";

@Component({
  selector: 'order-box',
  templateUrl: './order-box.component.html',
  styleUrls: ['./order-box.component.scss'],
})
export class OrderBoxComponent implements OnInit {

  @Input()
  public model:Order;

  public fields = ['companyName', 'userName', 'orderExtId', 'orderAdress', 'itemCount', 'finalPrice'];

  public settings:object;

  constructor(public modelTools:ModelToolsProvider, public appSettings:AppSettings, public orderService:OrderService) { }

  ngOnInit() {
      this.settings = this.appSettings.getSettings('orders');
      if (this.settings['fields'][0] != 'all')
          this.fields = this.settings['fields'];

      if(this.model.data.error && this.orderService.isStaffOrAgent() && !this.fields.includes('error'))
      {
        this.fields.push('error');
        this.fields.push('error_msg');
      }
  }

  goTo(page, params) {
    this.modelTools.goTo(page, params);
  }

}
