import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {AlertController, IonItemSliding, IonSlides, ModalController, NavController} from "@ionic/angular";
import {ModelAddPage} from "../../../../../../../framework/pages/model-add/model-add.page";
import {Section} from "../../../../../../../framework/model/core/sections/section";
import {ModelProvider} from "../../../../../../../framework/providers/modelProvider/ModelProvider";
import {NavigationExtras, Router} from "@angular/router";
import {SectionsManagerService} from "../../providers/sectionManagerService";
import {TranslateService} from "@ngx-translate/core";
import {Events} from "../../../../../../../framework/providers/events";

@Component({
    selector: 'sections-manager-tree',
    templateUrl: './sections-manager-tree.component.html',
    styleUrls: ['./sections-manager-tree.component.scss'],
})
export class SectionsManagerTreeComponent implements OnInit {

    @Input()
    public sections: Array<object> = [];

    @Input()
    public step: number = 1;

    // @Input()
    // public checkedItems: object;
    //
    // // @Input()
    // public checkedItemsData: object;

    // @Input()
    public sectionsData: object;
    public transferData: object;

    public listOptions: object = {};

    @Input()
    public filters: object = {};

    @Input()
    public readonly: boolean;

    @Input()
    public modelId: any;

    public showSubs: boolean = true;

    // public prefix = 'options_' + +Math.floor(Math.random() * Math.floor(1000000));

    // @ViewChild('slidingItem', null) slidingItem: IonItemSliding;


    constructor(public modalController: ModalController, public modelProvider: ModelProvider, public events: Events, private router: Router,
                public alertCtrl: AlertController, public translate:TranslateService,
                public sectionsManagerService: SectionsManagerService) {
        this.sectionsData = sectionsManagerService.getSectionsData();
        this.transferData = sectionsManagerService.getTransferData();
        // this.modelId = this.router['browserUrlTree']['queryParams']['modelId'];
            }

    ngOnInit() {
        if(!this.modelId)
        {
            this.modelId = -1
        }

        this.filters['limit'] = 100;
    }

    sectionChecked(section) {

        this.sectionsData['checkedData'][section.id] = section;
        this.sectionsData['checked'][section.id] = !this.sectionsData['checked'][section.id];
        this.sectionsManagerService.refreshCheckedArr(this.sectionsData);
    }

    toSectionSelected(section)
    {
        this.transferData['tosection'] = section.id;
        this.transferData['tosectiondata'] = section;
        this.transferData['toSectionChanged'] = true;
    }


    getItemsParams(section) {
        let prefix = section['id'] + '_' + Math.floor(Math.random() * Math.floor(1000000));
        let queryParams = {
            'modelId': section['id'],
            'modelName': 'section',
            'objectsPrefix': prefix
        };


        this.events.subscribe(prefix, (res) => {
            let params = {
                'model': section,
                'sectionsData': this.sectionsData,
                'readonly': this.readonly,


            }

            this.events.publish(prefix + '_params', params)

            // this.events.unsubscribe(prefix);
        });


        return queryParams
    }

    setTabPageChanged() {
        let me = this;
        setTimeout(function () {
            me.events.publish('tabPageChanged');
        }, 100)
    }

    // async showItems(section) {
    //
    //     let navigationExtras: NavigationExtras = {
    //         queryParams: {
    //             'modelId': section['id'],
    //             'modelName': 'section',
    //         }
    //     };
    //
    //
    //     this.router.navigate(['section-items-manager'], navigationExtras);
    //
    //     // this.navCtrl.navigateForward('section-items-manager', navigationExtras)
    //
    //     // const modal = await this.modalController.create({
    //     //     component: SectionItemsManagerPage,
    //     //     componentProps: data
    //     // });
    //     //
    //     // return await modal.present();
    //
    // }

    async editModel(section) {
        let me = this;
        const modal = await this.modalController.create({
            component: ModelAddPage,
            componentProps: {
                'modelId': section['id'],
                'model': new Section({'id':section['id'], 'name':section['name']}),
                'callback': function (res) {
                    //console.logres)
                    section['name'] = res.name;
                    me.events.publish('refreshSectionsTree');
                }
            }
        });

        return await modal.present();
    }


    async removeModel(sectionId, index) {
        const alert = await this.alertCtrl.create({
            header: this.translate.instant('areYouSure?'),
            buttons: [
                {
                    text: this.translate.instant('no'),
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: (blah) => {
                        //  return new Promise((resolve, reject) => {
                        // });
                    }
                }, {
                    text: this.translate.instant('yes'),
                    handler: () => {
                        let me = this;
                        this.modelProvider.removeById(sectionId, 'section').then(function () {
                            me.sections.splice(index, 1)
                            // me.events.publish('refreshSectionsTree');
                        });
                    }
                }
            ]
        });

        await alert.present();
    }

    //
    // closeSlidingItem(slidingItem) {
    //     slidingItem.close();
    // }
}

