export class BandObligoFncitems {
    data = {
        "fncnum": null,
        "balDate": null,
        "ivnum": null,
        "fncPatname": null,
        "details": null,
        "price": null,
        "currencyCode": null,
        "fncRef2": null,
        "fncDate": null,
        "business": null,
        "company": null,

    };

    constructor(public data1: any) {
        this.data = data1;
    }

//shortHeaders = [];
    prefix = 'bandObligoFncitems';
    title = 'User';
    dataFields = {

        fncnum: {'title': 'fncnum', 'value': null, 'type': 'text'},
        balDate: {'title': 'balDate', 'value': null, 'type': 'text'},
        ivnum: {'title': 'ivnum', 'value': null, 'type': 'text'},
        fncPatname: {'title': 'fncPatname', 'value': null, 'type': 'text'},
        price: {'title': 'price', 'value': null, 'type': 'text'},
        currencyCode: {'title': 'currencyCode', 'value': null, 'type': 'text'},
        fncRef2: {'title': 'fncRef2', 'value': null, 'type': 'text'},
        fncDate: {'title': 'fncDate', 'value': null, 'type': 'text'},
        details: {'title': 'details', 'value': null, 'type': 'text'},
        business: {'title': 'business', 'value': null, 'type': 'text'},
        company: {'title': 'company', 'value': null, 'type': 'text'},
        created: {'title': 'Created', 'value': null, 'type': 'date', 'readonly': true},
        updated: {'title': 'Updated', 'value': null, 'type': 'date', 'readonly': true},
    };
}
