import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {AdminService} from "../../../../../core/services/AdminService";
import {PopoverController, ToastController} from "@ionic/angular";
import {SelectBarComponent} from "../../select-bar/select-bar.component";
import {AppConfig} from "../../../../../core/conf/main";
import {ModelProvider} from "../../../../../framework/providers/modelProvider/ModelProvider";
import {Events} from "../../../../../framework/providers/events";

@Component({
    selector: 'app-html-editor',
    templateUrl: './html-editor.component.html',
    styleUrls: ['./html-editor.component.scss'],
})
export class HtmlEditorComponent implements OnInit, OnDestroy {
    appConfig = AppConfig;

    prefix = "fdsfeee";

    @Input()
    exTableData;
    @Input()
    sectionName;
    @Input()
    model;
    @Input()
    fieldData;
    @Input()
    fieldType;

    ready;
    fieldsType;
    saveInterval;

    //selectTable templte/field choose
    selectTableType = 'field';
    templateModel;

    //current field to be choose / copy
    currentField;

    constructor(public events: Events, private adminService: AdminService, public modelProvider: ModelProvider,
                private toastCtrl: ToastController, private popoverCtrl: PopoverController) {
    }

    ngOnInit() {
        this.fieldsType = this.adminService.getFieldsType(this.sectionName, this.exTableData);

        //only way in!
        this.events.subscribe('addFieldToTemplate' + this.prefix, (data) => {
            console.log(data);
            // this.fieldData['value'] += '{' + data['topPref'] + '|' + data['field'] + '}';
            this.copyToCliporad('{' + data['topPref'] + '|' + data['field'] + '}')
        });

        let me = this;
        setTimeout(function (){
            me.ready = true;
        }, 1000);
    }

    ngOnDestroy() {
        this.events.unsubscribe('addFieldToTemplate' + this.prefix);
    }

    save() {
        console.log('baboo!!!')
        console.log('baboo!!!')
        console.log('baboo!!!')
        this.adminService.markUpdated(this.exTableData[this.sectionName + 'Data'], this.sectionName, this.fieldData, this.model);
        this.adminService.setNeedSave();
    }

    addField(fieldType, extraData = []) {
        this.currentField = fieldType;

        //this types require more data.
        if (['table', 'selectTable'].indexOf(this.currentField.type) != -1 && extraData.length == 0) {
            return;
        }

        let val;

        // if (fieldType['type'] == 'table') {
        //     val = "{" + fieldType['name'];// + "|template|filter|sort|num";
        // } else if (fieldType['type'] == 'selectTable') {
        //     val = "{" + fieldType['name']; //|type|fieldName // type-template/field
        // } else {
        val = fieldType['name'];
        // }

        ////console.logextraData);

        for (let key of extraData) {
            if (key) {
                val += '/' + key;
            }
        }
        val = "{" + val + "}";

        this.copyToCliporad(val);

        //write to editor
        this.fieldData['value'] += val;
    }

    async copyToCliporad(val) {

        // this.fieldData.value += val;
        // this.save();
        // return;

        let selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = val;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);

        //     showCloseButton: true,
        //     closeButtonText: 'OK',
        let toast = await this.toastCtrl.create({
            message: val + " - Copied to clipboard. Now paste it to editor.",
            duration: 1000,
            position: 'bottom',
        });
        toast.present();


        // toast.present();
    }

    //select table
    //select table template
    async presentSelectBar(myEvent, modelName, fieldName) {
        this.fieldData['modelId'] = 1;
        this.fieldData['mainDisplayName'] = 'Template';

        let sectionName = 'chooseTemplate';

        this.exTableData[sectionName + 'Ref'] = [
            {'type': 'number', 'name': 'id'},
            {'type': 'selectTable', 'name': 'template', 'model': 'form'},
            {'type': 'selectTable', 'name': 'filter', 'model': 'appFilter'}
        ];
        this.exTableData[sectionName + 'Data'] = {'title': sectionName};

        //to global
        this.adminService.manageModels['chooseTemplateData'] = this.exTableData[sectionName + 'Data'];
        this.adminService.manageModels[sectionName + 'Ref'] = this.exTableData[sectionName + 'Ref'];

        if (!this.templateModel) {
            this.templateModel = {'template': {'value': null}, 'filter': {'value': null}, 'main': {}};
        }

        let fieldType = {'type': 'selectTable', 'model': 'form'}
        fieldType['baseFiler'] = this.templateModel['filter']['baseFilter'] = this.templateModel['template']['baseFilter'] = {
            'searchFieldValue': this.model['main']['templateSection'],
            'searchField': 'modelData__modelName',
            'search_in_field': true
        };

        const popover = await this.popoverCtrl.create({
            component: SelectBarComponent,
            componentProps: {
                'model': this.templateModel, // if search by id so dont send model - it will query it from server
                'prefix': "Aaa",
                'avoidSave': true,
                'fieldData': this.templateModel[fieldName],
                'fieldType': fieldType,
                'exTableData': this.exTableData,
                'sectionName': sectionName,
                'type': 'selectTable'
            },
            event: myEvent,
            animated: true,
            showBackdrop: true,
            cssClass: 'note-popover icenter-popover smaller-popover'
        });

        //right-popup
        await popover.present();
    }

    test() {
        let filters = {
            'templateId': this.model['id']['value'],
            'modelId': this.model['model']['value']
        }
        this.modelProvider.list('report', filters).then(() => {

        })
    }
}
