export class BandObligoTrans {
    data = {
        "curDate": "",
        "docno": "",
        "price": null,
        "currencyCode": "",
        "business": null,
        "company": null

    };

    constructor(public data1: any) {
        this.data = data1;
    }
//shortHeaders = [];
    prefix = 'bandObligoTrans';
    title = 'User';
    dataFields = {

        price: {'title': 'price', 'value': null, 'type': 'text'},
        currencyCode: {'title': 'currency', 'value': null, 'type': 'text'},
        docno: {'title': 'docno', 'value': null, 'type': 'text'},
        curDate: {'title': 'curDate', 'value': null, 'type': 'date'},
        business: {'title': 'business', 'value': null, 'type': 'text'},
        company: {'title': 'company', 'value': null, 'type': 'text'},
        created: {'title': 'Created', 'value': null, 'type': 'date', 'readonly': true},
        updated: {'title': 'Updated', 'value': null, 'type': 'date', 'readonly': true},
    };
}
