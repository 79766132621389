import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {Routes, RouterModule} from '@angular/router';

import {IonicModule} from '@ionic/angular';

import {SalesPage} from './sales.page';
import {GlobalModule} from '../../../app/global-module/global-module.module';

const routes: Routes = [
    {
        path: '',
        component: SalesPage
    }
];

@NgModule({
    imports: [
        GlobalModule,
        CommonModule,
        FormsModule,
        IonicModule,
        RouterModule.forChild(routes)
    ],
    declarations: [SalesPage]
})
export class SalesPageModule {
}

