import {Component, Input, OnInit} from '@angular/core';
import {AdminService} from "../../../../../core/services/AdminService";

@Component({
    selector: 'app-trip-box',
    templateUrl: './trip-box.component.html',
    styleUrls: ['./trip-box.component.scss'],
})
export class TripBoxComponent implements OnInit {

    @Input()
    sectionName;
    @Input()
    model;
    @Input()
    exTableData;
    @Input()
    tableInfoData;

    ready;
    fieldTypesByName;

    constructor(public admin: AdminService) {
    }

    ngOnInit() {
        this.fieldTypesByName = this.admin.getFieldsType(this.sectionName, this.exTableData);
        this.ready = true;
    }

}
