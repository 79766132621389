import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {RouterModule, Routes} from '@angular/router';

import {IonicModule} from '@ionic/angular';

import {TaarifPage} from './taarif.page';
import {GlobalModule} from "../../global-module/global-module.module";
import {InsuranceCalcComponent} from "../components/general/insurance-calc/insurance-calc.component";
import {SwiperModule} from "swiper/angular";
import {AdminPageMenuComponent} from "../components/tools/admin-page-menu/admin-page-menu.component";

const routes: Routes = [
    {
        path: '',
        component: TaarifPage
    }
];

@NgModule({
    imports: [
        CommonModule,
        GlobalModule,
        FormsModule,
        SwiperModule,
        IonicModule,
        RouterModule.forChild(routes),
    ],
    declarations: [TaarifPage, AdminPageMenuComponent],
    exports: [InsuranceCalcComponent]
})
export class TaarifPageModule {
}
