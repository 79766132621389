import {Component, Input, OnInit} from '@angular/core';
import {Post} from "../../../../../framework/model/core/post/post";
import {ModelProvider} from "../../../../../framework/providers/modelProvider/ModelProvider";
import {PostComment} from "../../../../../framework/model/core/post/postComment";
import {AppUser} from "../../../../../framework/model/core/appuser/appuser";
import {OrderService} from "../../../../services/OrderService";

import {ResObject} from "../../../../../app/global-module/global-module.module";
import {Events} from "../../../../../framework/providers/events";

@Component({
  selector: 'app-feed-post',
  templateUrl: './feed-post.component.html',
  styleUrls: ['./feed-post.component.scss'],
})
export class FeedPostComponent implements OnInit {

  @Input()
  public model:Post;

  public appuser:AppUser;
  public comment:PostComment;

  public options:object = {
    'show': false,
    'rlimit': 3,
    'limit': 3,
  }

  constructor(public modelProvider:ModelProvider, public orderService:OrderService, public events:Events) {
    this.appuser = this.orderService.getAppUser();

    this.events.subscribe('appuserChanged', (res)=>{
      this.appuser = res;
      this.initComment();
    })
  }

  ngOnInit() {
    this.initComment()
  }

  initComment()
  {
    this.comment = new PostComment({
      'post':this.model.data.id,
    });

    if(this.appuser)
    {
      this.comment.data.appuser = this.appuser.data.id;
    }
  }

  createComment()
  {
    if(!this.comment.data.description || this.comment.data.description == '')
      return;

    let me = this;
    this.modelProvider.create(this.comment).then((res:ResObject)=>{
      //console.logres);
      me.model.data.comments.unshift(res.data);
      me.initComment();
    });
  }

  showAll()
  {
    this.options['show'] = !this.options['show'];
    this.options['limit'] = this.options['show'] ? this.model.data.comments.length : this.options['rlimit'];
  }

}
