// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-item {
  --highlight-color-focused: var(--text-a);
}

.item-has-focus ion-label {
  color: #000;
}

.error {
  color: red;
  margin: 5px 0;
}`, "",{"version":3,"sources":["webpack://./src/core/pages/login/pages/reset-password/reset-password.page.scss"],"names":[],"mappings":"AAAA;EAEE,wCAAA;AAAF;;AAGA;EAEE,WAAA;AADF;;AAGA;EACE,UAAA;EACA,aAAA;AAAF","sourcesContent":["ion-item\n{\n  --highlight-color-focused: var(--text-a);\n}\n\n.item-has-focus ion-label {\n  //color: var(--text-a);\n  color:#000;\n}\n.error {\n  color: red;\n  margin: 5px 0;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
