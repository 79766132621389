import {Component, OnInit} from '@angular/core';
import {NavParams} from "@ionic/angular";
import {ModelProvider} from "../../../../../framework/providers/modelProvider/ModelProvider";
import {hebrewColors} from "../../../../conf/main";
import {TranslateService} from "@ngx-translate/core";
import {Events} from "../../../../../framework/providers/events";
import {ModalController} from "@ionic/angular";

@Component({
    selector: 'app-items-filter',
    templateUrl: './items-filter.page.html',
    styleUrls: ['./items-filter.page.scss'],
})
export class ItemsFilterPage implements OnInit {

    public prefix: string;
    public listFilters: object;
    public currentListFilters: object;
    public filters: object;
    public originalFilters: object;
    public activeFilters: Array<any>;

    public sort:boolean;
    public ordering:string;

    constructor(public modalController: ModalController, public modelProvider: ModelProvider,
                public navParams: NavParams, public events: Events, public translate:TranslateService) {
        this.prefix = navParams.get('prefix');
        this.listFilters = navParams.get('listFilters');
        this.currentListFilters = navParams.get('currentListFilters');
        this.sort = navParams.get('sort');

        this.activeFilters = navParams.get('activeFilters');
        this.originalFilters = navParams.get('filters');
        this.filters = JSON.parse(JSON.stringify(this.originalFilters));
    }

    ngOnInit() {
        console.log(this.filters)
    }

    confirmFilters(skipAll = false) {
        this.activeFilters.length = 0;
        let prodata = '';

        if(!skipAll)
        {
            // if(!this.sort)
            // {
            for (let k in this.filters) {
                let filter = this.filters[k];

                if (filter['type'] == 'number') {
                    if (!filter['active']) continue;
                    this.currentListFilters['min_' + filter['title']] = filter['res']['lower'];
                    this.currentListFilters['max_' + filter['title']] = filter['res']['upper'];
                    this.activeFilters.push(filter['res']['lower'] + '-' + filter['res']['upper'])
                } else {
                    for (let prop of filter['data']) {
                        if (prop['active']) {
                            let key = 'value'
                            if(!prop[key]) key = 'name'
                            if (prodata.length) prodata += '|';
                            prodata += filter['code'] + ',' + prop[key];
                            this.activeFilters.push(prop[key])
                        }
                    }
                }
            }

            if(this.listFilters['ordering'])
            {
                let sortName = this.translate.instant('ordering')+'- '+this.translate.instant(this.listFilters['ordering'].replace('-', ''));
                this.activeFilters.push(sortName);
                this.currentListFilters['ordering'] = this.listFilters['ordering'];
            }

            // }
            // else
            // {

                // this.modelProvider.custom('getItemsSort',this.listFilters).then((res)=>{
                //     this.currentListFilters['itemsarr'] = res['res'];
                //     this.confirmFilters(true);
                // });
                // return
            // }
            this.currentListFilters['prodata'] = prodata;
        }
        this.currentListFilters['offset'] = 0;


        this.events.publish(this.prefix + '_updateItemsFilter', this.currentListFilters);
        this.close();
    }

    resetFilters() {
        for (let k in this.filters) {
            this.filters[k] = JSON.parse(JSON.stringify(this.originalFilters[k]));
            if (this.filters[k]['type'] == 'number')
                this.filters[k]['res'] = {
                    'lower': this.filters[k]['data']['min'],
                    'upper': this.filters[k]['data']['max']
                };
            if (this.filters[k]['type'] != 'number')
                this.filters[k]['res'] = {};
            this.filters[k]['active'] = false;
        }

        delete this.filters['ordering'];
        delete this.listFilters['ordering'];
    }

    close() {
        this.modalController.dismiss();
    }
}