import {Component, Input, OnInit} from '@angular/core';
import {Item} from "../../../model/core/items/item";
import {Events} from "../../../providers/events";


@Component({
    selector: 'price',
    templateUrl: './price.component.html',
    styleUrls: ['./price.component.scss'],
})
export class PriceComponent implements OnInit {

    @Input()
    item: Item;

    // @Input()
    currencyCode: string = '₪';
    oldprice_currencyCode: string = '₪';

    @Input()
    currencyData: any;
    @Input()
    oldprice_currencyData: any;

    @Input()
    addTxt: string = '';

    @Input()
    value: any;

    @Input()
    showValue: any;

    @Input()
    oldprice: any;

    @Input()
    options: any = {};

    @Input()
    cssClass: string;

    itemPrices: object = {'active': false, 'price': 0, 'oldprice': 0};

    constructor(public events: Events) {
    }

    ngOnInit(): void {
        // this.value = parseFloat(this.value);
        // this.price = this.getPrice();
        //
        if (this.item)
            this.currencyData = this.item.data.currencyCode;

        if (this.currencyData && this.currencyData[0])
            this.currencyCode = this.currencyData[0];

        if (this.currencyData && this.currencyData[0] && this.currencyData[0].includes('$'))
            this.currencyCode = '$';

        if(this.oldprice_currencyData && this.oldprice_currencyData[0])
        {
            if (this.oldprice_currencyData[0].includes('$'))
                this.oldprice_currencyCode = '$';
        }
        else{
            this.oldprice_currencyCode = this.currencyCode;
        }

        this.setItemDetails();
        //   this.events.subscribe('itemPriceChanged_' + this.item.data.id, (res) => {
        //     this.price = this.getPrice();
        //   })
    }

    onDestroy() {
    }

    setItemDetails() {
        if (this.oldprice)
            this.itemPrices['oldprice'] = this.oldprice;

        if (!this.options['setItemPrices']) return;
        if (!this.item) return;
        if (!this.item.data.sale) return;
        if (this.item.data.oldprice) return;

        this.itemPrices['active'] = true;
        if (!this.itemPrices['oldprice'])
            this.itemPrices['oldprice'] = this.item.data.price;
        this.itemPrices['price'] = this.item.data.sale['priceAfterDiscount'];
    }

    // getPrice()
    // {
    //   if(this.showValue) return this.value;
    //   let itemres = this.item.getPrice();
    //   this.oldprice = itemres['oldprice'];
    //   return itemres['price'];
    // }
}
