// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title {
  text-align: center;
  font-size: 25px;
  font-weight: bold;
}

.sub-title {
  text-align: center;
  font-size: 20px;
}

.color-toggle {
  --background: #f44336;
  --background-checked: #0ec254;
  --handle-background: #fff;
  --handle-background-checked: #fff;
}`, "",{"version":3,"sources":["webpack://./src/core/pages/users-manager/pages/user-info/user-info.page.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,eAAA;EACA,iBAAA;AACF;;AAEA;EACE,kBAAA;EACA,eAAA;AACF;;AAGA;EACE,qBAAA;EACA,6BAAA;EACA,yBAAA;EACA,iCAAA;AAAF","sourcesContent":[".title {\n  text-align: center;\n  font-size: 25px;\n  font-weight: bold;\n}\n\n.sub-title {\n  text-align: center;\n  font-size: 20px;\n  //font-weight: bold;\n}\n\n.color-toggle {\n  --background: #f44336;\n  --background-checked: #0ec254;\n  --handle-background: #fff;\n  --handle-background-checked: #fff;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
