// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.progress-bar {
  --progress-background: var(--button-a-background);
}

ion-toolbar {
  border-bottom: 1px solid #d8d8d8;
}

.props-box {
  line-height: 1.2;
  font-size: 14px;
  white-space: pre-line;
}
.props-box .title {
  font-weight: bold;
}
.props-box .props span {
  font-style: italic;
}

.footer-box {
  position: absolute;
  z-index: 1500;
  bottom: 0px;
  width: 200px;
  border-top: 1px solid rgba(204, 204, 204, 0.8);
  left: 0;
}`, "",{"version":3,"sources":["webpack://./src/core/pages/cart/components/cart-item-toolbar/cart-item-toolbar.component.scss"],"names":[],"mappings":"AAAA;EACE,iDAAA;AACF;;AAEA;EACE,gCAAA;AACF;;AAEA;EACE,gBAAA;EACA,eAAA;EACA,qBAAA;AACF;AACE;EACE,iBAAA;AACJ;AAKI;EACE,kBAAA;AAHN;;AAQA;EACE,kBAAA;EACA,aAAA;EACA,WAAA;EACA,YAAA;EACA,8CAAA;EACA,OAAA;AALF","sourcesContent":[".progress-bar {\n  --progress-background: var(--button-a-background);\n}\n\nion-toolbar {\n  border-bottom: 1px solid #d8d8d8;\n}\n\n.props-box {\n  line-height: 1.2;\n  font-size: 14px;\n  white-space: pre-line;\n\n  .title {\n    font-weight: bold;\n  }\n\n  .props {\n    //padding: 0 5px;\n\n    span {\n      font-style: italic;\n    }\n  }\n}\n\n.footer-box {\n  position: absolute;\n  z-index: 1500;\n  bottom: 0px;\n  width: 200px;\n  border-top: 1px solid #cccc;\n  left: 0;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
