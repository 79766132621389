import {Component, OnInit} from '@angular/core';
import {AppConfig} from "../../../../conf/main";
import {NavController} from "@ionic/angular";
import {ModelProvider} from "../../../../../framework/providers/modelProvider/ModelProvider";
import {NavigationExtras} from "@angular/router";
import {BusinessService} from "../../../../services/BusinessService";
import {Events} from "../../../../../framework/providers/events";

@Component({
    selector: 'app-stock',
    templateUrl: './stock.page.html',
    styleUrls: ['./stock.page.scss'],
})
export class StockPage implements OnInit {
    appConfig = AppConfig;
    models = [];
    prefix = 'mystock';
    tablExtData = {'showEdit': false};

    keys = ['itemMakerImage', 'itemName', 'code', 'stock'];

    filterMaker;

    constructor(public events: Events,
                public navCtrl: NavController,
                public businessService: BusinessService,
                public modelProvider: ModelProvider) {
    }

    ngOnInit() {
        let me = this;
        this.events.subscribe('update_' + this.prefix, (data) => {
            me.models = data.models;
        });

        this.events.subscribe('makerClickEvent', (data) => {
            this.filterMaker = data.model;

            this.events.publish('refresh_request_' + this.prefix, {'item__itemMaker': data.model.data.id});
        });
    }

    removeFilterMaker() {
        this.filterMaker = null;
        this.events.publish('refresh_request_' + this.prefix, {'item__itemMaker': ''});
    }

    save(model) {
        this.modelProvider.update(model, {}, null, true);
    }

    openItem(model) {
        let navigationExtras: NavigationExtras = {
            queryParams: {
                'filters': {'itemId': model.data.item},
                'modelName': 'orderItem'
            }
        };
        this.navCtrl.navigateForward('reports', navigationExtras);
    }
}
