import {Component, OnInit, ViewChild} from '@angular/core';
import {IonTabs, ModalController, NavController} from "@ionic/angular";
import {NavigationEnd, Router} from "@angular/router";
import {SectionsManagerService} from "../../providers/sectionManagerService";
import {ModelAddPage} from "../../../../../../../framework/pages/model-add/model-add.page";
import {Section} from "../../../../../../../framework/model/core/sections/section";
import {Events} from "../../../../../../../framework/providers/events";

@Component({
    selector: 'sections-manager-tabs',
    templateUrl: './sections-manager-tabs.page.html',
    styleUrls: ['./sections-manager-tabs.page.scss'],
})
export class SectionsManagerTabsPage implements OnInit {

    public tabs: object;
    public tabsOptions: object = {};
    @ViewChild('tabsEl', null) tabRef: IonTabs;

    public backEvent: string = 'tabsBackEvent';

    constructor(public events: Events, private router: Router, public navCtrl: NavController, public sectionsManagerService: SectionsManagerService, public modalController:ModalController) {
        this.tabs = {
            '1': {
                'title': '1',
                'icon': 'return-right',
                'url': 'sections-list/:1',
                'pages': []
            },
            '2': {
                'title': '2',
                'icon': 'return-left',
                'url': 'sections-list/:2',
                'pages': []
            },
            '3': {
                'title': 'summary',
                'icon': 'checkbox-outline',
                'url': 'sections-manager-summary',
                'pages': []
            }
        };

        router.events.subscribe((val) => {
            this.updateTabUrl()
        });

        this.events.subscribe('tabPageChanged', (params) => {
            // this.updateTabUrl()
            this.tabsOptions['disablePagesAdd'] = false;
        });

        this.events.subscribe('goToTab', (params) => {
            this.goToTab(params['tab']);
        });

        // this.events.subscribe('moveToDestTab', (params) => {
        //     this.goToTab('2');
        // });
        //
        // this.events.subscribe('moveToOriginTab', (params) => {
        //     this.goToTab('1');
        // });

        this.events.subscribe(this.backEvent, (params) => {
            this.goBack()
        });
    }

    ngOnInit() {
        // setTimeout(function () {
        //   this.tabRef.select(this.tabs['1']['url']);
        // }, 1000)
        this.initTabsOptions();
        this.goToTab('1')
    }

    initTabsOptions() {
        this.tabsOptions['active'] = '1';
        this.tabsOptions['queue'] = ['1'];
        this.tabsOptions['disablePagesAdd'] = false;
    }

    goToTab(tab) {
        // this.tabsOptions['queueProfit'] = 1;
        this.tabRef.select(this.tabs[tab]['url']);
        this.tabsOptions[tab] = tab;
    }

    tabsChanged() {
        this.tabsOptions['disablePagesAdd'] = false;
        // let me = this;
        // setTimeout(function () {
        //     me.updateTabUrl();
        // }, 100)
    }

    updateTabUrl() {
        let url = this.router.url;
        if (!url) return;

        let key = 'sections-list/:';
        let index = url.indexOf(key);
        if (index == -1 || url.indexOf('sections-manager-summary') != -1) {
            return;
        }

        let tab = url[index + key.length];
        this.tabs[tab]['url'] = key + url.split(key)[1];
        this.tabsOptions['active'] = tab;

        if (this.tabsOptions['disablePagesAdd']) return;

        if (!this.tabs[tab]['pages'].length || this.tabs[tab]['pages'][this.tabs[tab]['pages'].length - 1] != this.tabs[tab]['url']) {
            this.tabs[tab]['pages'].push(this.tabs[tab]['url']);
        }

        if (this.tabsOptions['queue'][this.tabsOptions['queue'].length - 1] != this.tabsOptions['active'] + '') {
            this.tabsOptions['queue'].push(this.tabsOptions['active'])
        }

    }

    goBack() {
        // //console.log'go back!');
        let activeTab = this.tabsOptions['active'];
        let pages = this.tabs[activeTab]['pages'];

        this.tabsOptions['disablePagesAdd'] = true;

        if (pages.length == 1 || !pages.length) {
            if (this.tabsOptions['queue'].length > 1) {
                this.tabsOptions['queue'].splice(-1, 1);
                this.goToTab(this.tabsOptions['queue'][this.tabsOptions['queue'].length - 1])
            } else {
                // this.initTabsOptions();
                this.navCtrl.pop();
            }
            return;
        }

        pages.splice(-1, 1);
        this.tabs[activeTab]['url'] = pages[pages.length - 1];
        this.goToTab(activeTab)
    }



    async addModel() {
        let modelId = this.router['browserUrlTree']['queryParams']['modelId'];
        //console.logmodelId)
        let me = this;
        const modal = await this.modalController.create({
            component: ModelAddPage,
            componentProps: {
                'model': new Section({}),
                'addParams':{'sectionId':modelId},
                'callback': function (res) {
                    if(!modelId) modelId = '-1';
                    me.events.publish(modelId+'_newSection', res);
                    me.events.publish('moveToDestTab');
                    // me.getSections();
                }
            }
        });

        return await modal.present();
    }



}
