import {Component, Input, OnInit} from '@angular/core';
import {Section} from "../../../../../framework/model/core/sections/section";
import {AppDataService} from "../../../../services/AppDataService";
import {Item} from "../../../../../framework/model/core/items/item";
import {NavigationExtras} from "@angular/router";
import {NavController} from "@ionic/angular";

@Component({
    selector: 'section-tree',
    templateUrl: './section-tree.component.html',
    styleUrls: ['./section-tree.component.scss'],
})
export class SectionTreeComponent implements OnInit {

    @Input()
    public filters: object = {};

    @Input()
    public maxItemShow: number = 100;

    @Input()
    public viewSize: string = 'small';

    data: any;
    models: Array<Section> = [];
    items: object = {};

    offset = 0;
    limit = 5;

    constructor(public appDataService: AppDataService, public navCtrl: NavController) {
    }

    ngOnInit() {
        this.listSections();

        // //console.logthis.items);
    }

    openSectionPage(model) {
        this.goTo('section/' + model.data.id, {'model': model});
    }

    listSections() {
        this.data = this.appDataService.getModelsByKey('topSections', 'section');

        // this.limit = this.limit > this.data['models'].length ? this.data['models'].length : this.limit;
        this.limit = this.data['models'].length;

        this.setSections(this.data)
    }

    nextPage() {
        if (this.models.length == this.data.length) {
            return
        }

        this.offset += this.limit;
        this.setSections(this.data);
    }

    setSections(data) {
        let k = this.offset;

        for (let i = 0; k < this.limit; k++) {
            this.models.push(data['models'][i + k]);
        }


        // for(let k in this.models)
        // {
        //   if(!this.items[this.models[k].data.id])
        //   {
        //     this.items[this.models[k].data.id] = {};
        //   }
        //
        //   this.items[this.models[k].data.id] = this.appDataService.getSectionItems({'sectionId':this.models[k].data.id, 'limit':100})['models']
        // }

        // this.items = this.appDataService.getSectionItems(this.filters);
    }

    goTo(page, params) {

        let navigationExtras: NavigationExtras = {
            queryParams: params
        };

        this.navCtrl.navigateForward(page, navigationExtras)
    }

}
