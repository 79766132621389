import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {Chart} from "chart.js";
import * as moment from "moment";
import ChartDataLabels from "chartjs-plugin-datalabels";

@Component({
    selector: 'app-app-graph',
    templateUrl: './app-graph.component.html',
    styleUrls: ['./app-graph.component.scss'],
})
export class AppGraphComponent implements OnInit {
    @ViewChild("graph", {'static': true}) graph: ElementRef;

    @Input()
    graphType;
    @Input()
    graphData;
    @Input()
    graphsArray;
    @Input()
    topKeys;
    @Input()
    graphData2;
    @Input()
    bottomKeysTitle;
    @Input()
    bottomKeys;
    @Input()
    graphKeys;
    @Input()
    graphkeyType;

    constructor() {
    }

    ngOnInit(): void {
        let me = this;
        //graphs
        setTimeout(function () {
            // let lables = ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"]
            // let data = [12, 19, 3, 5, 2, 3]
            // me.setChart(me.graph, lables, data, 'bar', ['#53f554', '#ff35a4', '#126fa1', '#6516f3']);
            me.getGraph(me.graph, me.graphData, me.graphData2, me.graphType ? me.graphType : 'bar')
        }, 1);
    }

    getGraph(barCanvas, graphModels, graphModels2 = null, graphType = 'bar') {
        // graphVarId
        let keys = [];
        let values = [];
        let values2;
        let colors = [];

        if (graphModels2) {
            values2 = [];
        }

        if (this.graphsArray && this.bottomKeys) {
            if (this.bottomKeysTitle)
            {
                for (let k in this.bottomKeysTitle) {
                    keys.push(this.bottomKeysTitle[k][this.graphKeys[0]]);
                }
            }
            else
            {
                for (let k in this.bottomKeys) {
                    keys.push(this.bottomKeys[k][this.graphKeys[0]]);
                }
            }
        } else {
            let labelkey;
            for (let key in graphModels) {
                labelkey = graphModels[key][this.graphKeys[0] + '__name'] ? graphModels[key][this.graphKeys[0] + '__name'] :
                    graphModels[key][this.graphKeys[0]];

                if (this.graphkeyType[0] == 'date') {
                    // //console.loglabelkey);
                    let dt = moment(labelkey);
                    // //console.logdt);
                    keys.push(dt.format('HH DD/MM'));
                } else {
                    keys.push(labelkey);
                }

                values.push(graphModels[key][this.graphKeys[1]]);
                if (graphModels2) values2.push(graphModels2[key][this.graphKeys[1]]);
                // if (resReportData['graph'][2]) {
                //     values2.push(models[key][resReportData['graph'][2]]);
                // }

                // if (models[key][resReportData['graph'][0] + '__color']) {
                //     colors.push(models[key][resReportData['graph'][0] + '__color']);
                // } else {
                //     colors.push(-1);
                // }
            }
        }
        // //console.logkeys);
        // //console.logvalues);
        // let lables = ["קבלנים", "..."];
        // let data = [1,52,13,,43];


        let me = this;
        setTimeout(function () {
            me.setChart(barCanvas, keys, values, graphType, values2);
        });
    }

    //lables = ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"]
    //data = [12, 19, 3, 5, 2, 3]
    setChart(barElement, labales, data, type, data2 = null, dcolors = null) {
        // if (this.barChart) {
        //     this.barChart.clear();
        // }

        let colors = [];
        let colorsData = {
            "primary": "rgb(56, 128, 255)",
            "danger": "rgb(245, 61, 61)",
            "warning": "rgb(255, 206, 0)",
            "secondary": "rgb(12, 209, 232)",
            "dark": "rgb(34, 34, 34)",
            "success": "rgb(16, 220, 96)",
            "primary1": "rgb(56, 128, 255)",
            "danger2": "rgb(245, 61, 61)",
            "warning3": "rgb(255, 206, 0)",
            "secondary4": "rgb(12, 209, 232)",
            "dark5": "rgb(34, 34, 34)",
            "success6": "rgb(16, 220, 96)"
        };

        for (let key in colorsData) {
            colors.push(colorsData[key]);
        }

        if (dcolors) {
            for (let key in dcolors) {
                if (dcolors[key] != -1) {
                    colors[key] = colorsData[dcolors[key]];
                }
            }
        }

        let datasets = [];
        if (type == 'radar') {
            for (let i in data) {
                datasets.push({
                    'label': i,
                    'data': data[i]
                })
            }
        } else {
            if (!this.graphsArray) {
                datasets = [
                    {
                        label: '',
                        data: data,
                        backgroundColor: colors,
                        borderColor: colors,
                        borderWidth: 1
                    }
                ];
            } else {
                // data2 = [1,2,3,4,5,6,7,78,8]
                datasets = [];
                for (let i = 0; i < this.graphsArray.length; i++) {
                    let data = this.graphsArray[i];
                    let values = [];
                    let key;

                    for (let j in this.bottomKeys) {
                        key = this.bottomKeys[j][this.graphKeys[2]];
                        if (data[key]) {
                            console.log(data[key][this.graphKeys[1]])
                            values.push(data[key][this.graphKeys[1]]);
                        } else {
                            values.push(0);
                        }
                    }

                    datasets.push({
                        label: this.topKeys[i]['title'],
                        data: values,
                        backgroundColor: colors[i],
                        borderColor: colors[i],
                        borderWidth: 1
                    })

                }
            }
        }

        // line.
        // bar.
        // radar.
        // doughnut and pie.
        // polar area.
        // bubble.
        // scatter.

        //targets.. red point.
        const afterDatasetsDrawplugin = {
            id: 'custom_canvas_background_color',
            afterDraw: (chart, easing) => {
                let ctx = chart.ctx;

                if (!this.bottomKeys)
                    return;

                let xAxis = chart.scales['x'];
                let yAxis = chart.scales['y'];
                for (let d = 0; d < datasets.length; d++) {
                    let meta = chart.getDatasetMeta(d);
                    let scale = meta;

                    for (let index = 0; index < this.bottomKeys.length; index++) {
                        let key = this.bottomKeys[index];

                        if (!this.graphsArray[d][key[this.graphKeys[2]]]) {
                            continue;
                        }
                        // for (let index = 0; index < datasets[d].data.length; index++) {

                        let x = scale.data[index].x;
                        //height y
                        let y = yAxis.getPixelForValue(this.graphsArray[d][key[this.graphKeys[2]]]['targetValue']);

                        // ctx.beginPath();
                        // ctx.moveTo(x, yAxis.top);
                        // ctx.lineTo(x, yAxis.bottom);
                        // ctx.opacity = 0.5;
                        // ctx.strokeStyle = 'rgba(0, 0, 0, 0.5)';
                        // ctx.stroke();

                        ctx.beginPath();
                        ctx.arc(x, y, 2, 0, 2 * Math.PI);
                        // ctx.fillStyle = colors[d];//'rgb(255,0,0)';
                        ctx.fill();
                        // ctx.stroke();
                    }
                }
            },
            defaults: {
                color: 'lightGreen'
            }
        }
        //
        //         type: 'horizontalBar',
        //ChartDataLabels
        new Chart(barElement.nativeElement, {
            plugins: [afterDatasetsDrawplugin, ChartDataLabels],
            type: type,
            data: {
                labels: labales,
                datasets: datasets
            },
            options: {
                plugins: {
                    // Change options for ALL labels of THIS CHART
                    datalabels: {
                        color: '#323232',
                        offset: 0,
                        align: 'right',
                        anchor: 'top',
                        rotation: 50,
                        labels: {
                            title: {
                                font: {
                                    size: 0,
                                    tooltip: {
                                        enabled: true
                                    },
                                    weight: 'bold'//
                                }
                            },
                            value: {
                                font: {
                                    size: 15,
                                    weight: 'bold',
                                },
                                color: '#000000'
                            }
                        },
                        formatter: (value: number) => {
                            return this.numberFormat(value);
                        }
                    },
                },
                scales: {
                    yAxes: [
                        {
                            ticks: {
                                beginAtZero: false
                            }
                        }
                    ]
                }
            }
        });
    }

    numberFormat(value)
    {
        if ((value.toString()) == '0')
            return ''
        let valueText;

        if (value > 1000000) {
            valueText = ((value / 1000000).toFixed(2)) + 'M'
            return valueText
        } else if (value > 1000) {
            valueText = ((value / 1000).toFixed(2)) + 'K'
            return valueText
        }

        // Add commas to the number
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
}
