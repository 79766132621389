export class Area {
    data = {
        'id': null,
        'name': null,
        'address': null,
        'fulladdress': null,
        'prefix': null,
    };

    constructor(public data1: any) {
        this.data = data1;
    }

    //shortHeaders = [];
    prefix = "area";
    title = "Advertise";
    dataFields = {
        id: {'title': 'ID', 'value': null, 'type': 'number', 'readonly': true},
        created: {'title': 'Created', 'value': null, 'type': 'date', 'readonly': true},
        updated: {'title': 'Updated', 'value': null, 'type': 'date', 'readonly': true},
        name: {'title': 'Item', 'value': null, 'type': 'text'},
        directions: {'title': 'directions', 'value': null, 'type': 'textarea'},
        prefix: {'title': 'prefix', 'value': null, 'type': 'text'},
    }
}
