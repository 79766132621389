import {
    ChangeDetectorRef,
    Component, ElementRef,
    Input,
    OnDestroy,
    OnInit,
    QueryList,
    ViewChild,
    ViewChildren
} from '@angular/core';
import {Item} from "../../../../../framework/model/core/items/item";
import {OrderService} from "../../../../services/OrderService";
import {NavigationExtras, Router} from '@angular/router';
import {ActionSheetController, IonItemSliding, ModalController, NavController} from '@ionic/angular';
import {ItemProvider} from "../../providers/item-provider/item-provider.service";
import {BusinessService} from "../../../../services/BusinessService";
import {ModelProvider} from "../../../../../framework/providers/modelProvider/ModelProvider";
import {AppDataService} from "../../../../services/AppDataService";
import {AppSettings} from "../../../../conf/appSettings";
import {Events} from "../../../../../framework/providers/events";
import {AdminService} from "../../../../services/AdminService";
import {ModelToolsProvider} from "../../../../../framework/providers/model-tools/model-tools";

@Component({
    selector: 'app-item',
    templateUrl: './app-item.component.html',
    styleUrls: ['./app-item.component.scss'],
})
export class AppItemComponent implements OnInit, OnDestroy {

    @Input()
    public model: Item;

    public activeModel: Item;

    @Input()
    public modelId: boolean = false;

    @Input()
    public options: object = {};

    //smallerList / big / small
    @Input()
    public view: string = 'big';

    @Input()
    public cssClass: string = '';

    @Input()
    public empty: boolean = false;

    @Input()
    public showAgentData: boolean = false;

    @Input()
    public inCart: boolean = false;
    @Input()
    public skipProps: boolean = false;

    @Input()
    public innerItem: boolean = false;
    public ready: boolean = false;

    @Input()
    public cartItem: object;
    public addAmount = 0; //count amount to add after timeout of click
    public addToCartTimeOut;

    public amount: number = 0;

    public ticket: number = 1;

    public price: number = 1;

    public settings: object;
    public appsettings: object;

    public catalog: boolean = this.businessService.isCatalog();

    public allowAddToCart: boolean;
    public disableAddToCartDetails: string;

    public propsChangePrefix: string = 'props_' + Math.floor(Math.random() * Math.floor(1000000));

    public sub_prefix: string = 'sub_' + Math.floor(Math.random() * Math.floor(1000000));

    public currentProp: object = {};

    public openChecked: object = {};
    public viewData;
    public backgroundData;

    public cartData: object;
    public cart: object;


    @Input()
    public agentTechnicalTool: boolean;
    public agentToolData;

    itemHeight = 0;
    // @ViewChild('itemcontent') itemContent!: ElementRef;

    @ViewChildren('sliding') itemSlidingList: QueryList<IonItemSliding>;

    constructor(public orderService: OrderService, public navCtrl: NavController, public events: Events,
                public itemProvider: ItemProvider, public modalController: ModalController, public appDataService: AppDataService,
                public businessService: BusinessService, public modelProvider: ModelProvider, public appSettings: AppSettings,
                public actionSheetController: ActionSheetController, public router: Router,
                private changeRef: ChangeDetectorRef, public adminService: AdminService, public modelTools: ModelToolsProvider) {
        this.settings = this.appSettings.getSettings('items');
        this.appsettings = this.appSettings.getSettings('app');

    }

    ngOnInit() {
        if (this.empty) return;

        if (!this.model) {
            this.listItem();
            return;
        }

        // this.subscribes()

        // this.getCart();

        // this.events.subscribe('catalogChanged', (params)=>{
        //     this.catalog = params['catalog'];
        // })
        this.initItem();
        this.setViewData();

        this.allowAddToCart = this.itemProvider.checkAllowAddToCart(this.model);

        this.checkOrderType();

        // if (this.agentTechnicalTool) {
        //     this.view = 'big';
        // }

    }

    subscribes() {
        // this.unsubscribe();
        // console.log("SUBSSS", this.model.data.id)

        this.events.subscribe('orderTypeChanged', (params) => {
            this.checkOrderType();
        }, this.sub_prefix);

        this.events.subscribe('activeTicketChanged', (params) => {
            this.getCartItem();
        }, this.sub_prefix);

        this.events.subscribe(this.model.data.id + "_addedToCartWithProps", (params) => {
            this.getCartItem();
        }, this.sub_prefix);

        this.events.subscribe(this.model.data.id + '_cartItemChanged', (params) => {
            // console.log(this.model.data.id + '_cartItemChanged SUBB', this.sub_prefix)
            // //console.logthis.cartData);
            this.agentToolData['loading'] = false;
            this.getCartItem();
            this.setAgentTool(this.cartItem)
        }, this.sub_prefix);

        this.events.subscribe('listViewChanged', (res)=>{
            this.initItemHeight();
        }, this.sub_prefix)
        this.events.subscribe('listViewChanged_'+this.model.data.id, (res)=>{
            this.initItemHeight();
        }, this.sub_prefix)

        // this.events.subscribe('cartUpdated', (params) => {
        //     console.log(this.model.data.id ,'cartUpdated SUBB')
        //     // //console.logthis.cartData);
        //     this.agentToolData['loading'] = false;
        //     if (this.inCart) {
        //         this.getCart();
        //     }
        //     // this.setAgentTool(this.cartItem);
        // });

        // this.events.subscribe('cartUpdated', (params) => {
        //     this.agentToolData['loading'] = false;
        //     // //console.logthis.cartData);
        //     this.getCart();
        // }, this.sub_prefix);

        this.events.subscribe(this.propsChangePrefix, (params) => {
            let item = this.appDataService.getItemModel(this.currentProp['modelId']);
            this.activeModel = item;
            this.getCartItem();
        });

        // this.router.events.subscribe((data: any) => {
        //     this.unsubscribe()
        // });
    }

    unsubscribe() {
        // console.log("DESTROY ITEM", this.model.data.id)
        this.events.unsubscribe(this.model.data.id + '_cartItemChanged', this.sub_prefix);
        this.events.unsubscribe(this.model.data.id + "_addedToCartWithProps", this.sub_prefix);
        this.events.unsubscribe('activeTicketChanged', this.sub_prefix);
        this.events.unsubscribe('orderTypeChanged', this.sub_prefix);

        this.events.unsubscribe('listViewChanged', this.sub_prefix);
        this.events.unsubscribe('listViewChanged_'+this.model.data.id, this.sub_prefix);
        // this.events.unsubscribe('cartUpdated', this.sub_prefix);
        this.events.unsubscribe(this.propsChangePrefix);
    }

    ngAfterViewInit() {
        this.subscribes();
        let me = this;
        me.ready = true;

        this.initItemHeight();
    }

    initItemHeight(){
        // this.itemHeight = this.itemContent.nativeElement.offsetHeight;
    }

    // ionViewDidLoad() {console.log("A")}
    // ionViewWillEnter() {console.log("B")}
    // ionViewDidEnter() {console.log("C")}
    // ionViewWillLeave() {console.log("D")}
    // ionViewDidLeave() {console.log("E")}
    // ionViewWillUnload() {console.log("F")}

    // ionViewWillLeave() {
    //     console.log("Looks like I'm about to leave :(");
    //     console.log("-------------------------------------")
    //     console.log("-------------------------------------")
    //     console.log("-------------------------------------")
    //     console.log("-------------------------------------")
    //     console.log("-------------------------------------")
    // }
    ngOnDestroy() {

        this.setItemChecked(false);
        this.unsubscribe()
    }


    getCart() {
        this.cart = this.orderService.getCart();
        this.cartData = this.orderService.getCartData();
    }

    setViewData() {
        this.viewData = {
            'image_big': '12',
            'desc_big': '12',
            'options_big': '12',
            // 'add_btn_big': null,
            // 'trash_btn_big': '4',

            'image_small': '5',
            'desc_small': '7',
            'options_small_sm': '2',
            'options_small_lg': '2',


            'image_smallerList': '2',
            'desc_smallerList': '10',
            'options_smallerList_sm': '4',
            'options_smallerList_lg': '4',
            // 'add_btn_small': null,
            // 'trash_btn_small': '5'
        }
    }


    checkOrderType() {
        this.disableAddToCartDetails = null;
        let ordertype = this.orderService.getOrderType();
        if (ordertype == 'delivery' && !this.model.data.shippingable) {
            this.disableAddToCartDetails = 'cantDeliver';
        }
    }

    initItem() {
        // this.model = new Item(this.model.data);

        this.activeModel = this.model;

        // //console.logthis.model.data.id+'_cartItemChanged')
        this.getCartItem();
        this.setAgentTool(this.cartItem)

        this.agentToolData = this.model.agentToolData;
        // this.price = this.model.getPrice()['price'];

        // this.events.subscribe(this.model.data.id+'_cartItemChanged', (res) => {
        //     // this.cartItem = res;
        //     // //console.log"ITEM CHANCHED", this.model.data.id);
        //     this.getCartItem();
        // });

        let backg = this.model.data.background;
        this.backgroundData = {
            'visible': backg != null,
            'url': backg ? backg[0] : '',
        };


        if (!this.openChecked[this.model.data.id]) {
            this.openChecked[this.model.data.id] = {
                'value': false,
                'anim': false,
            };
        }
    }

    getCartItem() {
        // if (this.inCart)
        let indexKey = this.activeModel.data.id;
        if (this.inCart && this.cartItem)
            indexKey = this.cartItem['index'];


        let cartItem;
        // if (this.cartItem && this.cartItem['index']) {
        cartItem = this.orderService.getCartItemByIndex(indexKey);

        // if(!this.model['cartItem'])
        //     this.model['cartItem'] = cartItem

        // console.log("!@!!!", cartItem)
        // }
        // else
        //     cartItem = this.orderService.getCartItem(indexKey, !this.inCart);
        if (!cartItem['price']) cartItem['price'] = this.appDataService.getItemPrice(this.model);


        if (this.inCart && cartItem && this.cartItem) {
            this.cartItem['amount'] = cartItem['amount'];
            this.cartItem['price'] = cartItem['price'];
            this.cartItem['totalPrice'] = cartItem['totalPrice'];
            // //console.log'inCart', cartItem['amount'])

            return;
        }

        this.cartItem = cartItem;
        // console.log('not Cart', this.cartItem)
        // console.log("GET CART ITEM!", cartItem, this.inCart)
        // console.trace()
    }

    setAgentTool(cartItem) {
        let coupons = this.orderService.copy(this.appDataService.getPricesListKey('coupons'));
        // console.log(this.model);
        this.itemProvider.setAgentTool(this.model, cartItem, coupons);
        if(!this.agentToolData)
            this.agentToolData = this.model.agentToolData;
    }

    setEditField(field, closeToolbar = false) {
        if (!this.agentToolData['data'][field]['input'] || this.agentToolData['data'][field]['select'] || closeToolbar) {
            this.events.publish('closeItemToolbar');
            this.agentToolData['active'] = null;
            return;
        }
        let index = this.cartItem ? this.cartItem['index'] : this.model.data.id;
        this.itemProvider.showItemToolbar(this.model, index, field, true, this.agentToolData);
        this.agentToolData['active'] = field;
    }

    addToValue(field, value)
    {
        let field_value = parseFloat(this.agentToolData['data'][field]['value']);
        field_value += value
        if(field_value < 0) return;
        this.agentToolData['data'][field]['value'] = field_value;
    }

    viewFocusOptions(field, el) {
        console.log('FOCUS!', field, el)
    }

    listItem() {
        this.modelProvider.list('miniitem', {'id': this.modelId}).then((res) => {
            this.model = res['models'][0];
            this.initItem();
        })
    }

    openItem(force = false) {
        // if (this.orderService.isAgent() && this.model.data.stock && !force) {
        //     let index = this.cartItem ? this.cartItem['index'] : this.model.data.id;
        //
        //     this.itemProvider.showItemToolbar(this.model, index);
        //     // this.addToCart(false, this.skipProps)
        //     // this.itemProvider.showItemToolbar(this.model, this.cartItem['index'])
        //     // this.itemProvider.showAgentKeyboard(this.model, this.cartItem, null);
        //     return;
        // }

        if(!force && this.agentTechnicalTool && !this.agentToolData['visible'])
        {
            this.agentToolData['visible'] = true;
            let index = this.cartItem ? this.cartItem['index'] : this.model.data.id;
            let field = 'amount';
            if(!this.cartItem['amount']) {
                this.addToCart(false, false, false, false);
            }
            this.itemProvider.showItemToolbar(this.model, index, field, true, this.model.agentToolData);
            this.agentToolData['active'] = field;

            // this.addToCart(true)
            return
        }

        if (this.settings['page'])
            this.goTo('item/' + this.model.data.id, {});
        else
            this.openItemModal({'inCart':this.inCart});
    }

    goTo(page, params = {}) {

        let navigationExtras: NavigationExtras = {
            queryParams: params
        };

        this.navCtrl.navigateForward(page, navigationExtras)
    }

    addToCart(agentKeyboard = false, skipProps: boolean = false, separateCartItem = false, showPopups=true, ticket = null) {
        // this.cartItem['amount']++;
        // this.addAmount++;
        this.addAmount = 1;
        let me = this;
        clearTimeout(me.addToCartTimeOut);

        // me.addToCartTimeOut = setTimeout(function () {

        let index = me.cartItem && !separateCartItem ? me.cartItem['index'] : null;
        let props = me.inCart ? me.cartItem['props'] : [];

        let data = !separateCartItem ? me.cartItem['data'] : {};
        let options = {'separateCartItem': separateCartItem, 'inCart':this.inCart};

        // //console.logme.cartItem)
        // if(me.inCart && me.cartItem['product'].data['dealprops'].length)
        // {
        //     me.showItemToolbar();
        //     return;
        // }

        // console.log("ADD TO CART", index, this.cartItem);

        // //console.log'APPITE', me.model.data.id, this.cartItem['amount'])
        // //console.log'APPITE2', me.activeModel.data.id, me.activeModel.data.code)

        let cartItem = me.itemProvider.addToCart(me.activeModel, 1, showPopups, true, true, props,
            null, ticket, null, agentKeyboard, index, me.inCart || skipProps, me.cartItem['agentPrice'], me.cartItem['actionAmount'], data, options);

        // console.log(cartItem);
        if (cartItem && cartItem['index'] == me.activeModel.data.id) this.cartItem = cartItem;
        // console.log('ADDED RES', this.cartItem)
        //?
        // me.getCartItem();

        //?
        me.setItemChecked(true);
        me.addAmount = 0;
        // }, 1000);

        // let addeditem = this.itemProvider.addToCart(this.model);
        // if(addeditem)
        //     this.cartItem = addeditem;
    }

    openItemModal(params) {

        this.itemProvider.openItem(this.model, this.cartItem, this.activeModel.data.dealprops && this.activeModel.data.dealprops.length, params);
    }

    setItemChecked(key) {
        let ifFlag = key ? 'value' : 'anim';
        let animFlag = key ? 'anim' : 'value';
        let timeout = key ? 0 : 500;

        this.openChecked[this.model.data.id][ifFlag] = key;

        let me = this;
        setTimeout(function () {
            me.openChecked[me.model.data.id][animFlag] = key;
            me.changeRef.detectChanges();
        }, timeout)
    }

    showItemToolbar() {
        this.itemProvider.showItemToolbar(this.model, this.cartItem['index'], null, true)
    }

    openSlidingOptions(itemSlide, item) {
        // itemSlide.setElementClass("active-sliding", true);
        // itemSlide.setElementClass("active-slide", true);
        // itemSlide.setElementClass("active-options-right", true);
        // item.setElementStyle("transform", "translate3d(-241px, 0px, 0px)");

        itemSlide['el'].classList.add('item-sliding-active-slide');
        itemSlide['el'].classList.add('item-sliding-active-options-end');
        itemSlide['el'].classList.add('item-sliding-active-options-start');
        item['el'].style.transitionDuration = '.2s';
        item['el'].style.transform = 'translate3d(285px,0,0)';

        // itemSlide.open('start')
        // itemSlide.close();
        // item.setOpenAmount(300)
        // itemSlide.setOpenAmount(300)

        // slidingItem.close();
        // slidingItem.getSlidingRatio((res)=>{
        //     //console.logres);
        // });
        // //console.logitemSlide.open);
        // //console.logitemSlide.open('end'));
        // //console.logthis.itemSlidingList);

        // this.itemSlidingList.forEach((itemSlide) => {
        //     itemSlide.open('start');
        // });

        // itemSlide.startSliding(360); //any number can do
        // itemSlide.moveSliding(330); // any number small than the previous one
        // setTimeout(()=>{
        //     itemSlide.moveSliding(320); // any number smaller than the previous one
        //     itemSlide.endSliding(0.3); // you can mock anything less than 0.3
        // }, 100);
        // the reason why i delay 0.1s, that is because i need to give sometime for the logic inside moveSliding to set a flag to false, in order to make it automatically slide.
    }

    remove1FromCart(skipProps: boolean = false) {
        if (!this.cartItem['amount']) return;
        let props = this.cartItem['props'] ? this.cartItem['props'] : []
        this.itemProvider.addToCart(this.activeModel, -1, false, false, true, props,
            null, null, null, false, this.cartItem['index'], skipProps, this.cartItem['agentPrice'], this.cartItem['actionAmount'], this.cartItem['data']);
        this.getCartItem();
    }

    removeFromCart() {
        if (this.inCart) {
            this.orderService.removeFromCart(this.cartItem['ticket'], this.cartItem['index'], null, true);
            return
        }


        this.itemProvider.removeFromCart(this.activeModel);

        this.getCartItem();
        this.closeItem();
    }

    closeItem() {
        this.setItemChecked(false);
    }

    printMe(activeModel) {
        console.log(activeModel);
    }

    openGenericModelTable(event = null) {
        if (this.orderService.isStaff()) {
            this.adminService.openGenericModelTable('image', this.model.data.id, 'item', 'imageUrl');
            if (event) event.stopPropagation();
        }
    }

    async viewStaffSettings() {

        let buttons = this.orderService.viewStaffSettings(this.inCart, this.cartItem, this.model)
        const actionSheet = await this.actionSheetController.create({
            header: this.modelTools.getTranslate('itemSettings'),
            cssClass: 'my-custom-class',
            buttons: buttons
        });
        await actionSheet.present();

        const {role, data} = await actionSheet.onDidDismiss();
        console.log('onDidDismiss resolved with role and data', role, data);
    }

    async changeItemTicket() {

        let options = this.orderService.getCartTickets()
        let buttons = []
        for(let option of options)
        {
            buttons.push({
                text: option['value'],
                role: 'destructive',
                icon: 'create',
                handler: () => {
                    console.log(option);
                    this.addToCart(false, true, false, false, option['ticket'])
                }
            });
        }

        const actionSheet = await this.actionSheetController.create({
            header: this.modelTools.getTranslate('changeTicket'),
            cssClass: 'my-custom-class',
            buttons: buttons
        });
        await actionSheet.present();

        const {role, data} = await actionSheet.onDidDismiss();
        console.log('onDidDismiss resolved with role and data', role, data);
    }

    log(value) {
        console.log(value);
    }
}
