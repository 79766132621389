import {Component, Input, OnInit} from '@angular/core';
import {Item} from "../../../../../framework/model/core/items/item";
import {PopoverController} from "@ionic/angular";

@Component({
  selector: 'app-custom-items-list',
  templateUrl: './custom-items-list.component.html',
  styleUrls: ['./custom-items-list.component.scss'],
})
export class CustomItemsListComponent implements OnInit {

  @Input()
  public models:Array<Item> = [];

  constructor(public popoverCtrl:PopoverController) { }

  ngOnInit() {}

  close()
  {
    this.popoverCtrl.dismiss();
  }

}
