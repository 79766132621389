import {Component, Input, OnInit} from '@angular/core';
import {AgentKeyboardPage} from "../../pages/agent/agent-keyboard/agent-keyboard.page";
import {ModalController} from "@ionic/angular";
import {GallerySliderPage} from "./pages/gallery-slider/gallery-slider.page";

@Component({
    selector: 'app-gallery',
    templateUrl: './gallery.component.html',
    styleUrls: ['./gallery.component.scss'],
})
export class GalleryComponent implements OnInit {

    @Input()
    public images: Array<object> = [];

    @Input()
    public allowChanges: boolean = true;

    @Input()
    public addButton: boolean = false;

    @Input()
    public model: any;

    constructor(public modalController:ModalController) {
    }

    ngOnInit() {
        if(this.model)
        {
            this.images = this.model.data.images;
        }

        if(!this.images)
            this.images = []

        ////console.log"IMAGES", this.images)
    }

    async openImage(index) {
        const modal = await this.modalController.create({
            component: GallerySliderPage,
            componentProps: {
                'images': this.images,
                'allowChanges': this.allowChanges,
                'index':index,
            }
        });

        return await modal.present();
    }

}
