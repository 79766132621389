import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Item} from "../../../../../framework/model/core/items/item";
import {IonInfiniteScroll, IonSearchbar, IonVirtualScroll, ModalController} from "@ionic/angular";
import {ModelAddPage} from "../../../../../framework/pages/model-add/model-add.page";
import {Section} from "../../../../../framework/model/core/sections/section";
import {ItemsFilterPage} from "../../pages/items-filter/items-filter.page";
import {Storage} from "@ionic/storage";
import {BusinessService} from "../../../../services/BusinessService";
import {AppDataService} from "../../../../services/AppDataService";
import {Business} from "../../../../../framework/model/core/business/business";
import {AppSettings} from "../../../../conf/appSettings";
import {AppConfig} from "../../../../conf/main";
import {MainService} from "../../../../services/MainService";
import {ModelToolsProvider} from "../../../../../framework/providers/model-tools/model-tools";
import {Events} from "../../../../../framework/providers/events";
import {Order} from "../../../../../framework/model/core/order/order";
import {OrderService} from "../../../../services/OrderService";
import {ModelProvider} from "../../../../../framework/providers/modelProvider/ModelProvider";
import {ItemProvider} from "../../providers/item-provider/item-provider.service";

// import {VirtualScrollerComponent} from "ngx-virtual-scroller";

@Component({
    selector: 'item-list',
    templateUrl: './item-list.component.html',
    styleUrls: ['./item-list.component.scss'],
})
export class ItemListComponent implements OnInit, OnDestroy {

    public initmodels;

    @Input()
    public modelName: string = 'item';

    // @Input()
    // public openFilterButtonOn: object = {'on':true};

    @Input()
    public filters: object = {};

    @Input()
    public prefix = 'itemList_' + Math.floor(Math.random() * Math.floor(1000000));

    @Input()
    public models: Array<Item> = [];

    @Input()
    public options: object = {};

    public cartOptions: object = {};

    @Input()
    public mode = 'list';

    @Input()
    public view;

    @Input()
    public listItems: boolean = true;

    @Input()
    public showFilters: boolean = true;

    @Input()
    public showOptions: boolean = true;

    @Input()
    public infiniteScroll: boolean = true;

    @Input()
    public initData: boolean = true;

    @Input()
    public avoidAppData: boolean = false;

    @Input()
    public autoplay: boolean = true;

    @Input()
    public pager: boolean = true;
    public cuurentPage = 1;

    @Input()
    public listKey: string;

    @Input()
    private itemsViewPrefix = 'itemsListView';

    public activeFilters: Array<any> = [];

    private firstItemsSets: boolean = false;
    private _listItems: boolean = false;
    private limit: number = 20;
    private offset: number = 0;
    private modelsCount: number;

    public business: Business;

    itemReady = false;
    appuser;

    startShowIndex = 0;
    scrollTop = 0;
    scrollHeight = 0;
    // public restMode: boolean = false;

    @ViewChild(IonInfiniteScroll, null) ioninfiniteScroll: IonInfiniteScroll;
    @ViewChild(IonSearchbar, null) searchbar: IonSearchbar;

    @ViewChild('listedLoadedPlacesScroll') listedLoadedPlacesScroll: IonVirtualScroll;

    // @ViewChild('scroll') scroller: VirtualScrollerComponent;

    constructor(public events: Events, public modalController: ModalController, public orderService: OrderService,
                private cd: ChangeDetectorRef, public itemProvider:ItemProvider,
                public mainService: MainService, public modelTools: ModelToolsProvider, public modelProvider: ModelProvider,
                public businessService: BusinessService, public appDataService: AppDataService, public appSettings: AppSettings) {

    }

    ngOnInit() {

        this.appuser = this.orderService.getAppUser();

        if (!AppConfig.useAppData)
            this.listItems = true;
        // }
        //
        // ngAfterViewInit() {
        this.filters = JSON.parse(JSON.stringify(this.filters));
        // this.showFilters = false; //
        this._listItems = this.listItems;

        this.options['prefix'] = this.prefix;

        if (this.mode == 'slider')
            this.showOptions = false;

        this.itemsViewPrefix += this.mode;


        if (!this.filters['limit']) this.filters['limit'] = this.limit;
        if (!this.filters['offset']) this.filters['offset'] = this.offset;

        this.business = this.businessService.getBusiness();

        this.setItemsView();

        // this.restMode = this.business.data.GlobalSettingsData.bisType == 'rest';
        // if(this.restMode) {

        let settings = this.appSettings.getSettings('items');
        if (this.showOptions)
            this.showOptions = settings['showOptions'] !== null ? settings['showOptions'] : this.showOptions;

        // }
        this.events.subscribe('sectionOnScroll', (e) => {
            this.scrollTop = e.detail.scrollTop;
            let startIndex = Math.round(e.detail.scrollTop / this.itemHeight);
            // console.log(startIndex);
            this.startShowIndex = startIndex * 3;

            if (e.detail['contentHeight'] && this.scrollHeight != e.detail['contentHeight'])
                this.scrollHeight = e.detail['contentHeight'];
        }, this.prefix);


        this.scrollHeight = 1000;

    }

    ngAfterViewInit() {
        let me = this;
        setTimeout(function () {
            me.itemReady = true;
            me.refreshScroll();

            me.cartOptions = me.orderService.getCartOptions();
        }, 1);


        if (this.options['search_auto_focus']) {
            setTimeout(() => {
                me.searchbar.setFocus();
            }, 300);
        }

        // ////console.logthis.limit)
        // ////console.logthis.filters)
        // ////console.log'------')

        // if(this.filters['sectionId'] && this.initData) {
        //     this.initmodels = this.businessService.getAppData(this.filters['sectionId']);
        // }

        if (this.listItems) {
            this.initItems();
        } else {
            this.getAppDataItems()
        }

        this.events.subscribe(this.prefix + '_modelUpdated', (res) => {
            this.addItems(res);
            if (!this.avoidAppData)
                this.appDataService.addItems(res['models']);
        });

        this.events.subscribe(this.prefix + '_changeView', (res) => {
            this.changeView(res['view'], true)
        });

        this.events.subscribe(this.prefix + '_updateItemsFilter', (params) => {
            let filters = {}
            for (var k in params) {
                filters[k] = params[k];
            }
            for (var k in this.filters) {
                filters[k] = this.filters[k];
            }
            //console.logparams)
            //console.logthis.filters)

            // if (!this._listItems)
            //     this.listItems = this.activeFilters.length > 0;

            this.getItemsByFilters(filters);
        });


        this.events.subscribe(this.prefix + '_updateItems', (params) => {
            for (var k in params) {
                this.filters[k] = params[k];
            }

            // console.log(this.filters)
            this.getItems();
        })

        this.events.subscribe('new_appDataSets', (res) => {
            this.getItems();
        }, this.prefix);

        this.events.subscribe('app_switched', (res) => {
            this.getItems();
        }, this.prefix);

        this.events.subscribe(this.prefix + '_nextItem', (params) => {
            let model = this.models[params['index']];
            if(!model) return
            this.itemProvider.openItem(model, model.cart_model, model.data.autoProps, {'listIndex':params['index'], 'listPrefix':this.prefix})
        })


        this.options['autoplay'] = this.autoplay;

        if (!this.mainService.sliderAutoPlay) {

            ////console.log'cancel auto play!!!');
            this.options['autoplay'] = false;
        }
    }

    ngOnDestroy() {
        // console.log("SECTION DESSSTTTT")
        this.events.unsubscribe('sectionOnScroll', this.prefix);
        this.events.unsubscribe(this.prefix + '_modelUpdated');
        this.events.unsubscribe(this.prefix + '_updateItemsFilter');
        this.events.unsubscribe(this.prefix + '_updateItems');
        this.events.unsubscribe(this.prefix + '_changeView');
        this.events.unsubscribe('new_appDataSets', this.prefix);
        this.events.unsubscribe('app_switched', this.prefix);
    }


    getItemsByFilters(filters) {
        console.log(filters)
        this.modelProvider.custom('getItemsByFilters', filters).then((res) => {
            console.log(res);
            this.filters['itemsarr'] = res['items']
            this.getItems()
            // this.setFilters(res['res'])
        })
    }

    getItems() {
        // console.log('getItems', this.filters);
        // console.trace()
        if (!this.listItems) {
            if(this.ioninfiniteScroll)
                this.ioninfiniteScroll.disabled = true;
            this.models.length = 0;
            this.filters['skipped'] = 0;
            this.filters['offset'] = 0;
            this.filters['limit'] = this.limit;
            this.getAppDataItems()
        } else {
            this.initItems();
            this.events.publish(this.prefix + '_updateFilter', this.filters)
        }
    }


    getAppDataItems() {
        // console.log("getAppDataItems", this.filters)
        if (this.filters['itemsarr']) {
            let data = this.appDataService.getItems(this.filters['itemsarr'], this.filters);

            this.addItems({
                'models': data['res'],
                'modelsCount': this.filters['itemsarr'].length,
                'skipped': data['skipped']
            });
            ////console.log'length', data['models'].length)
            //console.log'data', data)
        } else if (this.filters['sectionId']) {
            let data = this.appDataService.getSectionItems(this.filters);
            this.addItems(data);
            ////console.log'data', data)
        } else if (this.filters['search']){
            let data = this.appDataService.searchItems(this.listKey, this.filters);
            this.addItems(data);
        } else if (this.listKey) {
            //     console.log("LISTEY")
            //     if (this.listKey == 'search' || this.filters['search']) {
            //         this.firstItemsSets = false;
            //         this.options['showLoadAnim'] = true;
            //
            //         let data = this.appDataService.searchItems(this.listKey, this.filters);
            //         let me = this;
            //         // ////console.logdata)
            //
            //         setTimeout(function () {
            //             me.addItems(data);
            //
            //             // me.options['showLoadAnim'] = false;
            //         });
            //     } else {
            let data = this.appDataService.getItemsByKey(this.listKey, this.filters);
            this.addItems(data);
            //     }
        }

        // this.firstItemsSets = true;

        // console.log('modelsCount', this.modelsCount)
        // console.log('length', this.models.length)
        // ////console.logthis.ioninfiniteScroll)

        if (this.ioninfiniteScroll) {
            this.ioninfiniteScroll.disabled = this.modelsCount == this.models.length;
        }
    }

    initItems() {
        this.models = [];
        this.filters['limit'] = this.limit;
        this.filters['offset'] = this.offset;
        this.filters['ordering'] = !this.filters['ordering'] ? '-issale' : this.filters['ordering'];
        this.firstItemsSets = false;
        // this.ioninfiniteScroll.disabled = false;

    }

    // public modelsRows = [];
    addItems(res) {
        let models = res['models'];
        this.modelsCount = res['modelsCount'];

        // let rowLength = 2;

        // if(this.models.length > 20)
        // {
        //     this.models.splice(0, 10)
        // }

        // let i=0;
        // let arr = []

        for (var k in res['models']) {
            // console.log(models[k]);
            // arr.push(models[k])
            let model = this.appDataService.getItemModel(models[k].data.id);
            this.models.push(model);
            // if(arr.length == rowLength){
            //     this.modelsRows.push(arr);
            //     arr = [];
            // }
        }
        // if(arr.length)
        // {
        //     this.modelsRows.push(arr);
        // }

        // console.log(this.modelsRows)

        // let me = this;
        // setTimeout(function () {
        this.firstItemsSets = true;
        // }, 1200)

        // console.log("-----",  res['models'])
        // console.log("ADD ITEMS", this.modelsCount, this.models.length, this.models)


        // console.log('modelsCount', this.modelsCount)
        // console.log('length', this.models.length)

        // if (this.modelsCount == this.models.length)
        if (this.ioninfiniteScroll) {
            this.ioninfiniteScroll.disabled = this.modelsCount == this.models.length;
        }


        this.refreshScroll()

        // console.log(this.models.length)
        // //console.logthis.models)
        // //console.logthis.filters)
    }

    itemHeight = 410;

    refreshScroll() {
        this.itemHeight = this.view == 'big' ? 400 : 120;
        // console.log("REFSREH SCROLL!!", this.listedLoadedPlacesScroll)
        if (this.listedLoadedPlacesScroll)
            this.listedLoadedPlacesScroll.checkEnd();

        this.cd.markForCheck();
        this.cd.detectChanges();

        // if(this.scroller)
        //     this.scroller.scrollToIndex(this.models.length);
        // this.listedLoadedPlacesScroll.checkEnd();
    }

    nextPage(e) {
        console.log("NEXT PAGE!")
        // //console.log'NEXT PAGE!')
        if (this.options['showLoadAnim']) {
            this.stopInfinite(e);
            return;
        }


        this.filters['offset'] += this.limit;


        if (this.listItems) {
            this.events.publish(this.prefix + '_updateFilter', this.filters);
        } else {
            let data;
            if (this.filters['search']) {
                data = this.appDataService.searchItems(this.listKey, this.filters);
            } else if (this.filters['itemsarr']) {
                // console.log(this.filters)
                let itemsres = this.appDataService.getItems(this.filters['itemsarr'], this.filters);
                data = {
                    'models': itemsres['res'],
                    'modelsCount': this.filters['itemsarr'].length,
                    'skipped': itemsres['skipped']
                }
            } else if (this.filters['sectionId'])
                data = this.appDataService.getSectionItems(this.filters);
            else
                data = this.appDataService.getItemsByKey(this.listKey, this.filters);

            this.addItems(data);
        }
        this.stopInfinite(e)
    }

    stopInfinite(e) {
        if (!e) return;
        // this.ioninfiniteScroll.disabled = true;

        // let me = this;
        setTimeout(function () {
            e.target.complete();
            // me.ioninfiniteScroll.disabled = !me.ioninfiniteScroll.disabled;
        }, 200)
    }


    setItemsView() {
        if(this.view){
            this.changeView(this.view, true);
            return;
        }
        this.modelTools.getKey(this.itemsViewPrefix).then((res) => {
            if (!res)
                res = this.business.data.GlobalSettingsData.itemsView ? this.business.data.GlobalSettingsData.itemsView : 'big';

            this.changeView(res);
        });

        // console.log(this.cartOptions)
    }


    setItemsView1() {
        // this.modelTools.getKey('agentToolActive').then((res) => {
        //     this.cartOptions['agentTechnicalTool'] = this.orderService.isAgentTool() && this.orderService.getCartOptionKey('agentTechnicalTool');
        // });


        if (!this.view) {
            // if (this.view)
            //     this.options['itemsView'] = this.view;
            // else {
            this.modelTools.getKey(this.itemsViewPrefix).then((res) => {
                if (!this.showOptions) {
                    this.view = this.mode == 'slider' ? 'small' : 'big';
                    this.changeView(this.view);
                    return;
                }

                if (!res) {
                    res = this.business.data.GlobalSettingsData.itemsView;
                }

                if (!res)
                    res = 'big';

                this.changeView(res);

                return;
            });

            return
            // }
        }

        // this.options['itemsView'] = this.view;
        this.changeView(this.view);


    }

    changeView(key, saveOnData = false) {
        this.view = key;
        this.options['itemsView'] = this.view;

        if (saveOnData)
            this.saveOnStorage(this.itemsViewPrefix, key);

        if (!this.options['slidesPerView'])
            this.options['slidesPerView'] = this.options['itemsView'] == 'small' ? 1 : 2;
    }

    changeView1(key, saveOnData = false) {

        let settings = this.appSettings.getSettings('items');
        // this.options['agentTechnicalTool'] = this.orderService.isAgentTool();


        // console.log('CHANGE VIEW', key, this.itemsViewPrefix)
        // console.trace()

        // key = 'big'

        if (!['big', 'small'].includes(key)) key = 'big';


        this.options['itemsView'] = key;
        this.view = this.options['itemsView'];
        // this.options['slidesPerView'] = 2;


        this.options['view_class'] = settings['view'] ? settings['view'] : '';
        // console.log(this.options);

        // if (settings['view'])
        //     this.view = this.options['itemsView'];
        // else
        //     this.view = this.options['itemsView'];
        //
        // if (key == 'small' && settings['small_view_str']) {
        //     this.view += settings['small_view_str'];
        // }

        this.events.publish('listViewChanged', {});


        if (!this.options['slidesPerView'])
            this.options['slidesPerView'] = this.options['itemsView'] == 'small' ? 1 : 2;

        ////console.log'viewview', this.view)

        if (saveOnData)
            this.saveOnStorage(this.itemsViewPrefix, key);

        this.refreshScroll()

    }

    changeAgentToolVisible() {
        // this.cartOptions['agentTechnicalTool'] = !this.cartOptions['agentTechnicalTool'];
        this.orderService.changeAgentToolVisible();
        let cartOptions = this.orderService.getCartOptions();
        console.log(cartOptions)
        console.log(this.cartOptions)
        // for(let key in cartOptions) {
        //     this.cartOptions[key] = cartOptions[key];
        // }

        // this.options['agentTechnicalTool'] = !this.options['agentTechnicalTool'];
        // this.saveOnStorage('agentToolActive', this.options['agentTechnicalTool']);
    }

    saveOnStorage(key, value) {
        this.modelTools.setKey(key, value);
    }

    search() {
        delete this.filters['itemsarr']
        this.getItems();
    }


}
