import {Injectable} from '@angular/core';
import {ItemDealPropsPage} from "../../pages/item-deal-props/item-deal-props.page";
import {ModalController} from "@ionic/angular";
import {createAnimation, Animation} from '@ionic/core';
import {Events} from "../../../../../framework/providers/events";

@Injectable({
    providedIn: 'root'
})
export class PropsProvider {

    constructor(public modalController:ModalController, public events:Events) {
    }


    getItemDefProps(item, amou=null, cartItem=null)
    {
        // //console.logitem, amount, cartItem)

        let checkedProps = {};
        // let props = [];

        for(let t in item.data.dealprops)
        {
            let typeobj = item.data.dealprops[t];
            let typeprops = typeobj['props'];
            for(let p in typeprops)
            {
                this.initType(checkedProps, typeobj)
                if(typeprops[p].default)
                {
                    this.setCheckedProp(checkedProps, typeobj, typeprops[p])
                    // //console.log'NEW PROP!!!', typeprops[p])
                }
            }
        }

        return this.getPropsArr(checkedProps, {});
    }

    getCheckedProps(props)
    {
        let checkedProps = {};

        for(let t in props) {
            let typeobj = props[t]['data']
            this.initType(checkedProps, typeobj)
            checkedProps[typeobj['id']]['checked'] = props[t]['checked'];
            checkedProps[typeobj['id']]['checkedValue'] = props[t]['checkedValue'];
            checkedProps[typeobj['id']]['checkedData'] = props[t]['checkedData'];
            checkedProps[typeobj['id']]['count'] = props[t]['count'];
        }
        return checkedProps
    }


    initType(checkedProps, type) {
        if (checkedProps[type['id']]) return;
        checkedProps[type['id']] = {
            'data': type,
            'checked': {},
            'checkedValue': {},
            'checkedData': {},
            'count': 0
        };
    }


    setCheckedProp(checkedProps, type, prop) {
        let typeres = checkedProps[type['id']];
        let alreadycheckedprop;
        if(!type['multiple']) {
            alreadycheckedprop = typeres['checked'][prop['id']];
            typeres['checked'] = {};
            typeres['count'] = 0;
        }

        if(type['multiple'] && type['amount'] &&   // if its multiple type.
            typeres['count'] == type['amount'] &&   // if limit is full.
            !typeres['checked'][prop['id']])  // if adding another one.
        {
            return;  // skip.
        }

        if(alreadycheckedprop)
            return;
        typeres['checked'][prop['id']] = !typeres['checked'][prop['id']];
        typeres['checked'][prop['id']] ? typeres['count']++ : typeres['count']--;
        typeres['checkedData'][prop['id']] = prop;

    }

    getPropsArr(checkedProps, typesCheck = {})
    {
        let props = []
        for(let k in checkedProps){
            let typeres = {
                'data':checkedProps[k]['data'],
                'checked':{},
                'checkedValue':{},
                'checkedData':{},
                'count':checkedProps[k]['count']};

            for(let c in checkedProps[k]['checked'])
            {
                if(checkedProps[k]['checked'][c])
                {
                    if(!typesCheck[checkedProps[k].data['id']])
                        typesCheck[checkedProps[k].data['id']] = {'required':checkedProps[k].data.required, 'count':0};

                    typesCheck[checkedProps[k].data['id']]['count']++;
                    // let value = true;
                    // if(this.checkedProps[k]['checkedData'][c]['inputType'] != 'checkbox')
                    //     value = this.checkedProps[k][c+'_value'];
                    typeres['checked'][c] = true;
                    typeres['checkedValue'][c] = checkedProps[k]['checkedValue'][c];
                    typeres['checkedData'][c] = checkedProps[k]['checkedData'][c];
                }
            }

            if(typeres['count'])
                props.push(typeres)
        }

        return props;
    }


  async showPropsModal(model, cart_model, checkedProps = {}, propsEvent = null, btnTitle = null, isItemDetails=true, cartItemEdit = false) {
      if(!btnTitle) btnTitle = 'addToCart';

      if (!propsEvent) {
          propsEvent = 'addToCartPrefix_' + Math.floor(Math.random() * Math.floor(1000000));

          // this.events.subscribe(propsEvent, (res) => {
            // //console.logres);
            //   if(isItemDetails)
            //     this.itemProvider.addToCart(model, 1, true, cartItemEdit, true, res['props'], null, null, null, true, cartItem['index'], true);
            //   this.events.unsubscribe(propsEvent);
          // })
      }

      // const enterAnimation = (baseEl: any) => {
      //     const backdropAnimation = createAnimation()
      //         .addElement(baseEl.querySelector('ion-backdrop')!)
      //         .fromTo('opacity', '0.0', '0.4');
      //
      //     const wrapperAnimation = createAnimation()
      //         .addElement(baseEl.querySelector('.modal-wrapper')!)
      //         .keyframes([
      //             {offset: 0, opacity: '0', transform: 'scale(0)'},
      //             {offset: 1, opacity: '0.99', transform: 'scale(1)'}
      //         ]);
      //
      //     return createAnimation()
      //         .addElement(baseEl)
      //         .easing('ease-out')
      //         .duration(500)
      //         .addAnimation([backdropAnimation, wrapperAnimation]);
      // }

      const modal = await this.modalController.create({
          component: ItemDealPropsPage,
          componentProps: {
              'model': model,
              'cart_model': cart_model,
              'propsEvent': propsEvent,
              'btnTitle': btnTitle,
              'checkedProps': checkedProps,
              'isItemDetails': isItemDetails,
          },
          // 'enterAnimation': enterAnimation,
          cssClass: 'animated-popover',
      });

      return await modal.present();
  }

}
