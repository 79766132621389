import {Component, Input, OnInit} from '@angular/core';
import {AppConfig} from "../../../../../core/conf/main";

@Component({
    selector: 'app-custom-table',
    templateUrl: './custom-table.component.html',
    styleUrls: ['./custom-table.component.scss'],
})
export class CustomTableComponent implements OnInit {

    appConfig = AppConfig;
  
    @Input()
    data;
    @Input()
    models;
    @Input()
    fields;
    @Input()
    fieldColors;
    @Input()
    fieldTypes;

    constructor() {
    }

    ngOnInit() {
    }

}
