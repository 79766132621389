// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.model-box {
  margin: 5px;
  width: 100%;
}
.model-box ion-title {
  background: #fff;
  padding: 0 3px;
  font-size: 16px;
}
.model-box .img-box {
  width: 100%;
  height: 90px;
  max-height: 90px;
  vertical-align: middle;
  display: table;
}
.model-box .img-box app-img {
  vertical-align: middle;
  display: table-cell;
  width: 100%;
}

.title {
  min-height: 20px;
  max-height: 20px;
  font-weight: bold;
  text-align: center;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/core/pages/deals/components/deals-slider/deals-slider.component.scss"],"names":[],"mappings":"AAAA;EAEE,WAAA;EACA,WAAA;AAAF;AAEE;EAEE,gBAAA;EACA,cAAA;EACA,eAAA;AADJ;AAIE;EAEE,WAAA;EACA,YAAA;EACA,gBAAA;EACA,sBAAA;EACA,cAAA;AAHJ;AAKE;EACE,sBAAA;EACA,mBAAA;EACA,WAAA;AAHJ;;AAOA;EAEE,gBAAA;EACA,gBAAA;EACA,iBAAA;EACA,kBAAA;EACA,WAAA;AALF","sourcesContent":[".model-box\n{\n  margin: 5px;\n  width: 100%;\n\n  ion-title\n  {\n    background: #fff;\n    padding: 0 3px;\n    font-size: 16px;\n  }\n\n  .img-box\n  {\n    width: 100%;\n    height: 90px;\n    max-height: 90px;\n    vertical-align: middle;\n    display: table;\n  }\n  .img-box app-img {\n    vertical-align: middle;\n    display: table-cell;\n    width: 100%;\n  }\n}\n\n.title\n{\n  min-height: 20px;\n  max-height: 20px;\n  font-weight: bold;\n  text-align: center;\n  width: 100%;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
