import {Component, ComponentFactoryResolver, Input, OnInit} from '@angular/core';
import {ModelComponents} from "../../../model/modelComponents";
import {NavController} from "@ionic/angular";
import {NavigationExtras} from "@angular/router";
import {MainService} from "../../../services/MainService";
import {ModelToolsProvider} from "../../../../framework/providers/model-tools/model-tools";
import {Address} from "../../../../framework/model/core/address/address";
import {AppDataService} from "../../../services/AppDataService";
import {ItemProvider} from "../../../pages/item/providers/item-provider/item-provider.service";
import {Events} from "../../../../framework/providers/events";
import {AppSettings} from "../../../conf/appSettings";

@Component({
    selector: 'adv-slider',
    templateUrl: './adv-slider.component.html',
    styleUrls: ['./adv-slider.component.scss'],
})
export class AdvSliderComponent implements OnInit {

    @Input()
    public modelName: string = 'mainadvertise';

    @Input()
    public filters: object = {};

    @Input()
    public options: object = {};

    @Input()
    public slidesPerView: number = 1;

    @Input()
    public style: object = {'width': '100%'};

    @Input()
    public height: number = 200;
    // public height:number = 200;

    @Input()
    public margin: number = 0;

    @Input()
    public background: boolean = true;

    @Input()
    public autoplay: boolean = false;

    @Input()
    public appDataKey: string;

    @Input()
    public dataModels: Array<any>;

    public models: Array<any> = [];
    public afterRandom: boolean = false;

    public ready: boolean = false;

    @Input()
    public closeable: boolean = false;

    @Input()
    public prefix = 'advSlider_' + +Math.floor(Math.random() * Math.floor(1000000));

    constructor(public modelComponents: ModelComponents, private navCtrl: NavController, public appDataService: AppDataService, public itemProvider:ItemProvider,
                public mainService: MainService, public modelTools: ModelToolsProvider, public appevents:Events, public appsettings:AppSettings) {

    }

    ngOnInit() {
        if (!this.options['prefix'])
            this.options['prefix'] = this.prefix;
        this.options['autoplay'] = this.autoplay;

        if (!this.mainService.sliderAutoPlay) {

            this.options['autoplay'] = false;
        }

        // //console.log'autoplay adv slider!!!!! ********************************');

        this.options['slidesPerView'] = this.slidesPerView;

        if (this.dataModels) {
            for (let k in this.dataModels) {
                this.models.push(this.appDataService.getModelData(this.dataModels[k], 'address'))
            }

            let me = this;
            setTimeout(function () {
                me.ready = true;
            }, 500)
        } else {
            this.ready = true;
        }

        let settings = this.appsettings.getSettings('advs')
        this.appevents.subscribe(this.prefix + '_modelUpdated', (res)=>{
            this.checkTimer()
            if(!settings['disable_advs_shuffle'])
                this.shuffle(this.models);
        })

    }

    checkTimer()
    {
        let me = this;
        let models = this.models.filter(p => p.data.start_time != null || p.data.end_time != null)
        for(let model of models)
        {
            if(model['interval']) continue;
            this.checkAdvTimer(model);
            model['interval'] = setInterval(function (){
                me.checkAdvTimer(model)
            }, 10000)
        }
    }

    checkAdvTimer(model)
    {
        let now = new Date()
        model['hidden'] = false;

        if(model.data.start_time){
            let start_time = new Date(model.data.start_time)
            model['hidden'] = start_time > now;
        }
        if(model.data.end_time){
            let end_time = new Date(model.data.end_time)
            model['hidden'] = model['hidden'] || end_time < now;
        }
    }

    openAdv(adv) {
        if (adv.data.page) {
            if (adv.data.page == 'none')
                return;

            if (adv.data.page == 'register') {
                this.mainService.goToRegister();
                return
            }

            this.goTo(adv.data.page, {});
            return
        }

        if (adv.data.itemId) {
            let model = this.appDataService.getItemModel(adv.data.itemId);
            this.itemProvider.openItem(model);
            return
        }

        if (adv.data.sectionId) {
            this.goTo('section/' + adv.data.sectionId, {});
            return
        }

        this.goTo('sales', {});
    }

    goTo(page, params) {
        // let navigationExtras: NavigationExtras = {
        //     queryParams: params
        // };
        //
        // this.navCtrl.navigateForward(page, navigationExtras)
        this.modelTools.goTo(page, params);
    }

    //make the first slide to be randomized
    getRandom(models) {
        if (!this.models || this.models.length == 0) return [];
        if (this.afterRandom) return this.models;
        this.afterRandom = true;
        this.models.unshift(this.models[Math.floor(Math.random() * (this.models.length - 1))]);
        return this.models;
    }

    shuffle(array) {
        let currentIndex = array.length,  randomIndex;

        // While there remain elements to shuffle.
        while (currentIndex != 0) {

            // Pick a remaining element.
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;

            // And swap it with the current element.
            [array[currentIndex], array[randomIndex]] = [
                array[randomIndex], array[currentIndex]];
        }

        return array;
    }
}
