import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {Events} from "../../../../../framework/providers/events";
import {ItemProvider} from "../../providers/item-provider/item-provider.service";
import {ModelToolsProvider} from "../../../../../framework/providers/model-tools/model-tools";
import {Item} from "../../../../../framework/model/core/items/item";
import {OrderService} from "../../../../services/OrderService";
import {BusinessService} from "../../../../services/BusinessService";
import {CartModel} from "../../../../../framework/model/core/items/cartModel";
import {AppSettings} from "../../../../conf/appSettings";
import {IonInput} from "@ionic/angular";

@Component({
    selector: 'app-item-agent-tool',
    templateUrl: './item-agent-tool.component.html',
    styleUrls: ['./item-agent-tool.component.scss'],
})
export class ItemAgentToolComponent implements OnInit {

    @Input()
    public model: Item;

    @Input()
    public agentToolData:CartModel;
    public cartModel:CartModel;

    @Input()
    public cartItem;

    @Input()
    public options;

    @Input()
    public fields: Array<string>;

    @Input()
    public readonly:boolean;

    public sub_prefix: string = 'sub_' + Math.floor(Math.random() * Math.floor(1000000));

    public fieldTouched: boolean = false;

    @ViewChild('textInput', { static: false }) textInput!: IonInput;
  // @ViewChild('textInput') textInput!: ElementRef<HTMLInputElement>;



    constructor(public events: Events, public itemProvider: ItemProvider, public business: BusinessService,
                public appSettings: AppSettings,
                public modelTools: ModelToolsProvider, public orderService: OrderService) {
    }


    ngAfterViewInit()
    {
        let key = this.agentToolData.data['prefix']
        let me = this;

        // setTimeout(function (){
        var openButton = document.getElementById(key+'_button');
        if(!openButton) return;
        openButton.onclick = function (e) {
            me.onClick(e, 'js');
        }
        // })
    }

    onClick(e, k='tys')
    {
        let edit_box = e.srcElement
        let field = edit_box.getAttribute('data-field')

        this.setEditField(field)
    }

    ngOnInit() {
        if (!this.agentToolData) this.agentToolData = this.model.cart_model;
        this.cartModel = this.agentToolData; //cartModel it is.

        if (!this.options) this.options = {};

        this.listenToKeyboardChanges();

        if(this.agentToolData.data['init-field'])
        {
            this.setFieldFocus(this.agentToolData.data['init-field'])
            this.agentToolData.data['init-field'] = null;
        }


        //console.log(this.agentToolData)
        //console.log(this.fields)

        let settings = this.appSettings.getSettings('items');
        let settings_fields = settings['agent_tool_fields']

        if(settings_fields)
        {
            if(!this.fields) this.fields = settings_fields;
            else{
                this.fields = this.fields.filter((value) => settings_fields.includes(value));
            }
        }

        let fields = this.fields;
        if(!fields) this.fields = fields = this.cartModel.data.fields;
        let action_values_options = this.options['action_values_options']
        if(action_values_options){
            // && !fields.includes('action_value'))
            fields.length = 0
            for(let field of action_values_options['fields'])
            {
                if (!fields.includes(field))
                    fields.push(field);
                if(!action_values_options['data']) continue
                if(!action_values_options['data'][field]) continue
                for(let key in action_values_options['data'][field])
                {
                    this.cartModel.data.data[field][key] = action_values_options['data'][field][key]
                }
            }
        }

    }

    ngOnDestroy() {
        // if (this.fieldTouched) {
        //     this.events.unsubscribe(this.model.data.id + '_agentToolbarFieldChanged', this.sub_prefix);
        // }
    }

    setFieldFocus(field)
    {
        if(this.business.screenSize[0]<=1200)
        {
            this.setEditField(field);
        }
        else {
            // this.setFieldFocus(field);
        }
    }

    agentToolChanged(field = null, force=false) {
        if (this.agentToolData.data['data'][field]['focus'] && !force) return;
        this.itemProvider.add_to_cart(this.model, null, field, this.options)

        if (field == 'discount') {
            let key = 'agentDiscountsData'
            this.modelTools.getKey(key).then((res: Array<number>) => {
                let data = {
                    'value': this.agentToolData.getValue(field),
                    'prefix': this.agentToolData.data['data'][field]['edit_prefix']
                }
                console.log(data)

                if (res && res.length && res[0]['prefix'] != data['prefix']) return;
                this.modelTools.setKey(key, [data]);
            });
        }
    }
    onInput(field)
    {
        let me = this;
        setTimeout(function (){
            me.agentToolChanged(field, true)
        }, 100)
    }

    agentToolChanged1(field = null) {
        let cartItem = this.cartItem;
        let model = this.model;
        let agentToolData = this.agentToolData.data;
        let data = cartItem['data'];


        if (agentToolData['data'][field]['value'] === null) {
            return
        }

        if (['amount', 'price'].includes(field) && cartItem[field] == agentToolData['data'][field]['value']) {
            return;
        }

        let data_fields = ['comments', 'coupon_amount', 'coupon_code', 'discount']
        if (data_fields.includes(field) && cartItem['data'][field] == agentToolData['data'][field]['value']) {
            return;
        }

        if (!agentToolData['data'][field]['input']) {
            return;
        }

        agentToolData['cart_prefix'] = data['cart_prefix'] = 'item_' + Math.floor(Math.random() * Math.floor(1000000));
        // console.log('agentToolChanged', field, agentToolData['cart_prefix'])

        // console.log("CHANGEDDDDDD!!!", field, agentToolData['data']['price']['value'], agentToolData['data'][field]['input'])

        for (let f in data_fields) {
            data[data_fields[f]] = agentToolData['data'][data_fields[f]]['value'];
        }


        let amount = agentToolData['data']['amount']['value'] && ('' + agentToolData['data']['amount']['value'].length) ? parseFloat(agentToolData['data']['amount']['value']) : 0;

        agentToolData['data']['amount']['value'] = amount;

        let price = parseFloat(agentToolData['data']['price']['value']);

        let index = cartItem['index'] ? cartItem['index'] : model.data.id;

        // console.log(amount, amount > 0, !amount)

        if (!amount) {
            agentToolData['visible'] = false;
        }
        // console.log(agentToolData['visible'])

        // setTimeout(function () {

        // field == 'price' && model.data.basePrice && agentToolData['data'][field]['value'] < model.data.basePrice

        this.itemProvider.initCartPrices(model);
        data['price_error'] = price < model.data.basePrice;

        this.orderService.setCartCalcPrefix();

        clearInterval(this.agentToolData['interval']);
        let me = this;
        this.agentToolData['interval'] = setInterval(function () {
            // console.log('section tool interval')
            clearInterval(me.agentToolData['interval']);

            if (!price) return;

            if (!amount) {
                if (!cartItem['amount']) return;
                me.itemProvider.removeCartItemFromCart(cartItem);

            } else {

                // console.log('Add to cart', price)
                me.itemProvider.addToCart(model, amount, false, false, false, cartItem['props'],
                    null, null, price, false, index, false, price, 0, data, {'agentToolbar': true});
            }


            if (data['discount']) {
                let key = 'agentDiscountsData';
                me.modelTools.getKey(key).then((res: Array<number>) => {
                    if (!res) res = [];
                    if (!res.includes(data['discount']) &&
                        (!res.length || agentToolData['data']['discount']['pressed'])) {
                        res.push(data['discount']);
                        me.modelTools.setKey(key, res.slice(-1));
                        agentToolData['data']['discount']['pressed'] = true;
                    }
                });
            }

            // me.agentToolData['loading'] = true;
        }, 400)

    }


    getFieldOptions(field) {
        if (field != 'discount') return;
        let key = 'agentDiscountsData';
        let discount_options = this.agentToolData.data['data'][field]['options']
        this.modelTools.getKey(key).then((res: Array<number>) => {
            console.log(res)

            if (!res) return;
            discount_options.length = 0;

            for (let k of res) {
                discount_options.push(k)
            }

        });
    }

    listenToKeyboardChanges() {
        // if (!this.fieldTouched) {
        //     this.fieldTouched = true;
        //     this.events.subscribe(this.model.data.id + '_agentToolbarFieldChanged', (params) => {
        //         this.agentToolChanged(params['field']);
        //     }, this.sub_prefix);
        // }

        this.agentToolData.data['data']['discount']['pressed'] = false;
    }

    setEditField(field, closeToolbar = false) {

        let me=this;

        if(this.textInput && field && field.length) {
            if( this.options['active_model'] == this.agentToolData.data['prefix'] && this.options['action_field'] == field)
                return;

            console.log("detect input!", field)
            this.textInput.value = null;
            // this.textInput.value = null;


            this.agentToolData.data['active'] = field;
            this.options['action_field'] = field;
            this.options['active_model'] = this.agentToolData.data['prefix']

            // if(this.model.data.packageUnits > 1 && field == 'amount') return

            // setTimeout(function (){
                me.detectKeyboadPress(field)
            // }, 500);
        }


        return;
        this.itemProvider.setAgentToolEditField(this.agentToolData, this.model, field, this.options, closeToolbar);

        return;
        this.listenToKeyboardChanges();

        this.options['active_model'] = this.agentToolData.data['prefix'];

        if (!this.agentToolData.data['data'][field]['input'] || this.agentToolData.data['data'][field]['select'] || closeToolbar) {
            this.events.publish('closeItemToolbar');
            this.agentToolData['active'] = null;
            return;
        }
        let index = this.cartItem ? this.cartItem['index'] : this.model.data.id;

        this.itemProvider.showItemKeyboard({
            'cart_model': this.agentToolData,
            'item': this.model,
            'agent-toolbar': true,
            'options': {
                'field': field
            }
        });
        this.agentToolData['active'] = field;
        this.agentToolData.data['data'][field]['edit_prefix'] = this.modelTools.getRandomize();


        // if (this.businessService.getScreenSize()[0] > 1400) {
        //     console.log(this.businessService.getScreenSize()[0])
        //     return
        // }
        //
        // let data = this.agentToolData.data['data'][field];
        // let prefix = 'editField' + Math.floor(Math.random() * Math.floor(1000000));
        // this.events.publish('viewEditFieldToolbar', {'data': data, 'prefix': prefix, 'currencyCode':this.model.data.currencyCode});
        // // data['active'] = true;
        // this.agentToolData['active_field'] = field;
        // this.events.subscribe(prefix, (close) => {
        //     // console.log(data['value'], value);
        //     // data['value'] = value;
        //     // data['active'] = false;
        //     if (close)
        //         this.agentToolData['active_field'] = null;
        //
        //     this.agentToolChanged();
        // })
    }

    fieldFocus(field) {
        // this.listenToKeyboardChanges();

        if (['comments'].includes(field)) {
            return
        }

        if (this.agentToolData.data['focus']) {
            let me = this;
            setTimeout(function () {
                me.fieldFocus(field)
            }, 100);
            return;
        }

        let focus = this.agentToolData.data['data'][field]['focus'];
        this.agentToolData.data['data'][field]['focus'] = true;
        this.agentToolData.data['focus'] = true;

        if (!focus) {
            this.agentToolData.data['data'][field]['placeholder'] = this.agentToolData.data['data'][field]['value'];
            this.agentToolData.data['data'][field]['value'] = null;
        }

        this.agentToolData.data['data'][field]['edit_prefix'] = this.modelTools.getRandomize();
    }

    fieldUnFocus(field) {
        let me = this;

        setTimeout(function () {


            if (me.agentToolData.data['data'][field]['value'] === null && me.agentToolData.data['data'][field]['placeholder'])
                me.agentToolData.data['data'][field]['value'] = me.agentToolData.data['data'][field]['placeholder']

            me.agentToolData.data['data'][field]['focus'] = false;
            me.agentToolData.data['focus'] = false;

            // me.agentToolChanged(field);
        }, 100)
    }


    removeOption(fiedData, option)
    {
        let me = this;
        setTimeout(function () {

            let options: Array<any> = fiedData['options'];
            const index = options.indexOf(option);
            if (index > -1) {
                options.splice(index, 1);
            }
            console.log(options);
            fiedData['options'].length = 0;
            me.modelTools.setKey('agentDiscountsData', []);
        }, 500);
    }


    addToValue(field, value) {
        // console.log("###", field, value)
        this.options['active_model'] = this.agentToolData.data['prefix'];
        let me = this;
        setTimeout(function () {
            me.itemProvider.add_to_cart(me.model, value, field, {'add_to_value':true, 'in_cart':me.options['in_cart']}, me.agentToolData);
        })
        return;

        let field_value = parseFloat(this.agentToolData.data['data'][field]['value']);
        field_value += value;
        if (field_value < 0) return;
        this.agentToolData.data['data'][field]['value'] = field_value;

        me.listenToKeyboardChanges();
        me.agentToolChanged(field);
    }

    detectKeyboadPress(field){
        let key = this.agentToolData.data['prefix']
        var searchInput = this.textInput;
        searchInput.setFocus();
    }

    onKeyDown(event: KeyboardEvent) {
    // Detecting key presses
        let value = this.textInput.value;
        console.log('**', value)
        // let value = this.textInput.value;
        if(!value) return;

        value = value+''
        //
        // const regex: RegExp = new RegExp(/^\d*\.?\d*$/g);
        // const newValue: string = value.replace(/[^0-9.]/g, '');
        //
        // let test = regex.test(newValue);
        //
        // if (!test) {
        //   value = newValue.slice(0, -1);
        // }
        // else{
        //     value = newValue
        // }


        console.log(this.options['action_field'], "to value-", value)
        if(!value.length) value = '0'
        // this.textInput.value = value;

         if(this.model.data.packageUnits > 1 && this.options['action_field'] == 'amount'){
             // let packageNumbers = this.packageNumbers(this.model.data.packageUnits, value)
             // console.log("TO CALCC-- ", packageNumbers)
             value = this.closestNumberRoundUp(this.model.data.packageUnits, value)
         }
        // else {
             this.itemProvider.add_to_cart(this.model, '' + value, this.options['action_field'], {'add_to_value': false});
         // }

        //
        // // Handle key presses here
        // // Example: Dismiss keyboard on pressing 'Enter'
        // if (event.key === 'Enter') {
        //   this.dismissKeyboard();
        //
    }

    onBlurEvent(e)
    {
        this.agentToolData.data['active'] = null;
        this.options['action_field'] = null;
        this.options['active_model'] = null;
    }

    dismissKeyboard() {
        // Programmatically blur the input to dismiss the keyboard
        this.textInput.setBlur();
        // this.textInput.setBlur();
    }

    closestNumberRoundUp(multipleValue: number, inputValueStr: string): string {
        const inputValue = parseInt(inputValueStr);
        const closestValue = Math.ceil(inputValue / multipleValue) * multipleValue;
        return closestValue.toString();
    }

}