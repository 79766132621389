import {Component, OnInit} from '@angular/core';
import {Item} from "../../../../../framework/model/core/items/item";

@Component({
    selector: 'app-new-item',
    templateUrl: './new-item.page.html',
    styleUrls: ['./new-item.page.scss'],
})
export class NewItemPage implements OnInit {

    public model: Item;

    public keys: Array<string>;
    public pages: object;
    public activePage: object;
    public options: object;

    constructor() {
    }

    ngOnInit() {
        this.model = new Item({});
        this.initPages()
    }

    initPages() {
        this.keys = ['details', 'image', 'note'];
        this.pages = {
            'details': {
                'fields': ['name', 'price', 'description', 'sectionId'],
                'callback':this.createItem,
                'this':this
            },
            'image': {
                'component': 'app-img'
            },
            'note': {
                'component': 'app-note',
                'key': 'new-item-added'
            }
        }

        this.activePage = {}
        this.setActivePage(this.keys[0]);

    }

    setActivePage(key) {
        let i = this.keys.indexOf(key);
        this.activePage = {
            'key': key,
            'index': i,
            'data':this.pages[key],
        }
    }

    nextPage()
    {
        if(this.activePage['index'] == this.keys.length-1)
        {
            // end.
            return
        }

        let key = this.keys[this.activePage['index']+1];
        this.setActivePage(key)
        if(this.activePage['data']['callback'])
            this.activePage['data']['callback'](this.activePage['data'])
    }

    createItem(activePage)
    {
        let me = activePage['this'];
        //console.logme.model)
    }

}
