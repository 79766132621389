import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {NavController} from "@ionic/angular";
import {OrderService} from "../../../../services/OrderService";
import {AppSettings} from "../../../../conf/appSettings";
import {PropsProvider} from "../../providers/props-provider/props-provider.service";
import {ItemProvider} from "../../providers/item-provider/item-provider.service";
import {AppUser} from "../../../../../framework/model/core/appuser/appuser";
import {ModelToolsProvider} from "../../../../../framework/providers/model-tools/model-tools";
import {Events} from "../../../../../framework/providers/events";

@Component({
    selector: 'app-item-footer',
    templateUrl: './item-footer.component.html',
    styleUrls: ['./item-footer.component.scss'],
})
export class ItemFooterComponent implements OnInit {

    public appuser: AppUser;

    @Input()
    public page;

    public data: object = {
        'visible': false,
    };

    public intervalData = {
        'timer': 14000,
    };

    public sub_prefix: string = 'sub_' + Math.floor(Math.random() * Math.floor(1000000));

    constructor(public events: Events, public modelTools: ModelToolsProvider,
                public orderService: OrderService, public appsettings: AppSettings,
                private changeRef: ChangeDetectorRef, public propsProvider: PropsProvider,
                public itemProvider: ItemProvider) {
        this.appuser = this.orderService.getAppUser();

    }

    ngOnInit() {
        this.data['settings'] = this.appsettings.getSettings('items');
        // //console.logthis.data['settings'])

        this.events.subscribe('showItemToolbar', (params) => {
            this.showItemToolbar(params)
        }, this.sub_prefix);
        this.events.subscribe('closeItemToolbar', (params) => {
            this.closeItem(true)
        }, this.sub_prefix);
    }

    ngOnDestroy() {
        this.unsubscribes()
    }

    unsubscribes(){
        if(this.data['options']) {
            this.data['options']['active'] = null;
        }

        // this.events.unsubscribe('showItemToolbar',  this.sub_prefix);
        // this.events.unsubscribe('closeItemToolbar', this.sub_prefix);
    }

    showItemToolbar(params) {
        this.data = params;
        this.data['visible'] = true;

        this.data['hide_keyboard'] = params['item'].data.packageUnits > 1 && params['options']['field'] == 'amount';

        // console.log(params)
        // this.setVisible(true);
    }
    showItemToolbar1(params) {
        // if (!this.data['settings']['footer-options'] && this.page == 'cart') {
        //     return;
        // }

        // if (this.data['cartItem'] && this.data['cartItem']['index'] == params['index']) {
        //     this.getCartItem();
        //     this.setVisible();
        //     return;
        // }

        let cartItem = this.orderService.getCartItemByIndex(params['index']);
        let model = params['model'];
        // console.log('item toolbar cartItem', cartItem)
        if (!cartItem) return;
        // console.log'model', model)
        this.data['cartItem'] = cartItem;
        this.data['field'] = params['field'];
        this.data['agent-toolbar'] = params['agent-toolbar'];
        this.data['showProps'] = model.data.dealprops && model.data.dealprops.length;

        if(!this.data['model'] || this.data['model'].data.id != model.data.id){
            if(this.data['options'])
                this.data['options']['active'] = null;

            this.data['model'] = params['model'];
            this.data['options'] = params['options'];
        }


        let openprops = model.data.dealprops && model.data.dealprops.length;
        this.data['props'] = openprops && !model.data.autoProps && cartItem['amount'] > 0;

        this.setVisible(params['stay']);

        // console.log('----', this.data)


        this.data['imageReady'] = false;
        let me = this;
        setTimeout(function () {
            me.data['imageReady'] = true;
        },);
    }

    setVisible(key = true, noInterval=true) {
        this.data['visible'] = key;
        if (key && !noInterval) {
            this.startInterval()
        }
    }

    startInterval(stopOnly = false) {
        if (this.intervalData['interval'])
            clearInterval(this.intervalData['interval']);

        if (stopOnly)
            return;

        let me = this;
        this.intervalData['interval'] = setInterval(function () {
            // //console.log'close!')
            me.closeItem();
            me.startInterval(true);
        }, this.intervalData['timer'])
    }


    addToCart(agentKeyboard = false) {
        if(agentKeyboard && this.orderService.isAgent())
        {
            this.orderService.hideAgentToolbar(false);
            return;
        }

        let cartItem = this.data['cartItem'];
        let props = cartItem['props'] ? cartItem['props'] : [];
        let amount = 1;

        this.itemProvider.addToCart(this.data['model'], amount, true, true, true, props,
            null, null, null, agentKeyboard, cartItem['index'], props.length, cartItem['agentPrice'], cartItem['actionAmount'], cartItem['data']);
        this.getCartItem();

        this.startInterval();
    }

    remove1FromCart() {
        this.itemProvider.addToCart(this.data['model'], -1);
        this.getCartItem();
        this.startInterval();
    }

    viewAgentToolbar()
    {
        this.itemProvider.showItemToolbar(this.data['model'], this.data['cartItem']['index'], null, true);
    }

    removeFromCart() {
        this.itemProvider.removeFromCart(this.data['model']);

        this.getCartItem();
        this.closeItem();
    }

    getCartItem() {
        let cartItem = this.orderService.getCartItem(this.data['model'].data.id, true);
        this.data['amount'] = cartItem['amount'];
        this.data['cartItem'] = cartItem;

    }

    closeItem(fromEvent = false) {
        this.setVisible(false);
        if (!fromEvent)
            this.events.publish('closeItemToolbar');

        this.unsubscribes();
    }

    goTo(page) {
        this.modelTools.goTo(page);
        this.closeItem()
    }

    showProps() {
        let propsEvent = 'propsEvent' + '_' + +Math.floor(Math.random() * Math.floor(1000000));
        let props = this.propsProvider.getCheckedProps(this.data['cartItem']['props'])
        this.propsProvider.showPropsModal(this.data['model'], this.data['cartItem'], props ? props : {}, propsEvent, 'proceed', false)
        let me = this;
        this.events.subscribe(propsEvent, (res) => {
            //console.logme.data['cartItem']);
            // //console.logthis.data['amount']);

            // let index = this.data['model'].data.id + '_' + +Math.floor(Math.random() * Math.floor(1000000));
            //
            // let amount = this.data['cartItem']['amount']
            //
            let props = this.propsProvider.getPropsArr(res['checkedProps']);
            // this.data['cartItem']['props'] = props;
            this.data['cartItem']['comments'] = res['comments'];
            // this.addPropsToCart(-amount, this.data['cartItem']['index']);
            // this.addPropsToCart(amount, index, props, res['comments']);
            //
            // this.data['cartItem'] = this.orderService.getCartTicketItem(index);
            // this.orderService.addPropsToCartItem(this.data['cartItem'], props)
            this.itemProvider.addPropsToCartItem(me.data['cartItem'], props);

            //
            // let me = this;
            // setTimeout(function () {
            //     me.close();
            // }, 2000);
            // this.addToCart(model, 1, true, cartItemEdit, true, res['props'], null, null, null, true, cartItem['index'], true);
            this.events.unsubscribe(propsEvent);
        });

        this.startInterval(true);
    }

}
