// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.details-box {
  font-size: 14px;
}

.stock-title-box {
  background: var(--button-a-background);
  color: var(--button-a-text);
  text-align: center;
  font-size: 14px;
}

ion-list.list-ios {
  margin-top: 5px;
  margin-bottom: 15px;
}`, "",{"version":3,"sources":["webpack://./src/core/pages/stock-reports/stock-reports.page.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;AACF;;AAEA;EACE,sCAAA;EACA,2BAAA;EACA,kBAAA;EACA,eAAA;AACF;;AAEA;EACE,eAAA;EACA,mBAAA;AACF","sourcesContent":[".details-box {\n  font-size: 14px;\n}\n\n.stock-title-box {\n  background: var(--button-a-background);\n  color: var(--button-a-text);\n  text-align: center;\n  font-size: 14px;\n}\n\nion-list.list-ios {\n  margin-top: 5px;\n  margin-bottom: 15px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
