import {Component, Input} from '@angular/core';
import {BusinessService} from "../../../../core/services/BusinessService";

/**
 * Generated class for the AppLoadingComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
    selector: 'app-loading',
    templateUrl: 'app-loading.html',
    styleUrls: ['./app-loading.scss'],
})
export class AppLoadingComponent {

    @Input()
    public type: string; // 'main',..

    @Input()
    public pageName: string;

    @Input()
    public global: boolean = true;

    @Input()
    public inpage: boolean = false;

    public src: string;

    constructor(public businessService: BusinessService) {
    }

    ngOnInit() {
        let images = this.businessService.getImages();
        if (!images) return;

        let key = 'flag';
        if (!images.data[key]) return;

        this.src = images.data[key + '_image']['imagefullurl'];
    }
}
