// import {Plugins} from '@capacitor/core';

import {ChangeDetectorRef, Component, enableProdMode, ViewChild} from '@angular/core';

import {IonContent, MenuController, ModalController, NavController, Platform, PopoverController} from '@ionic/angular';
import {MainService} from '../core/services/MainService';
import {ActivatedRoute, NavigationExtras, Router} from '@angular/router';
import {BusinessService} from '../core/services/BusinessService';
import {Subscription} from 'rxjs';
import {OrderService} from '../core/services/OrderService';
import {Business} from '../framework/model/core/business/business';
import {AppUser} from "../framework/model/core/appuser/appuser";
import {BusinessImages} from "../framework/model/core/business/businessImages";
import {PlatformsDownloadComponent} from "../core/components/platforms-download/platforms-download.component";
import {PushService} from "../core/services/PushService";
import {AppConfig} from "../core/conf/main";
import {AppSettings} from "../core/conf/appSettings";
import {OpeningComponent} from "../core/components/opening/opening.component";
// import {
//     StatusBarStyle,
// } from '@capacitor/core';
import {ModelProvider} from "../framework/providers/modelProvider/ModelProvider";
import {AdminService} from "../core/services/AdminService";
import {Events} from "../framework/providers/events";
import {Storage} from '@ionic/storage';
import {SwUpdate} from "@angular/service-worker";
import {ModelToolsProvider} from "../framework/providers/model-tools/model-tools";
import {CartService} from "../core/services/CartService";
import {SplashScreen} from "@capacitor/splash-screen";

declare let gtag: any;
declare let cordova: any;

// const {StatusBar} = Plugins;
// const {SplashScreen} = Plugins;

enableProdMode();

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss',]
})

export class AppComponent {
    @ViewChild(IonContent, {'static': true}) ionContent: IonContent;

    ready: boolean = false;
    menuSmall: boolean = false;

    public AppConfig = AppConfig;

    public businessImages: BusinessImages;
    public business: Business;
    public appuser: AppUser;

    public platformType: string;

    //from call event.
    public sideShowModel;
    public sideShowModel1;

    // public colorSets: boolean = false;
    // public timeoutSets: boolean = false;
    // public appDataSets: boolean = false;
    // public appPricesSets: boolean = false;
    public backgroundVisible: number = 0;
    public appinit: boolean = false;

    public appsets = {
        'business': false,
        'colors': false,
        'timeout': false,
        'appData': false,
        'appPrices': false,
        'initLoging': false,
    }

    public catalog: boolean;
    public cartActive: boolean = true;

    subscription: Subscription;

    public appPages = [];
    public selectedAdminPageIndex = null;
    public adminPages;
    public menuLoading = false;
    public onAdminMenu = false;

    public activePage = '/main';
    public activePageArr;
    // public activePage = '/taarif';

    public socialSupport: object = {};

    showHeaderFooter = true;
    showHeaderTimeOut;

    negishotkey = [];
    showHelpers = false;
    hideHeleprs = false;
    helpersSections = [
        'הגברת ניגודיות',
        'ניגודיות נמוכה',
        'מונוכרום',
        'הדגש קישורים',
        'בטל אנימציה',
        'פונט יותר קריא',
        'איפוס הגדרות',
    ];

    // private statusBar: StatusBar,
    // private splashScreen: SplashScreen,
    constructor(
        private modelProvider: ModelProvider,
        private platform: Platform,
        private mainService: MainService,
        public businessService: BusinessService,
        private orderService: OrderService,
        public cartService: CartService,
        private pushService: PushService,
        private events: Events,
        public modelTools: ModelToolsProvider,
        private route: ActivatedRoute,
        public navCtrl: NavController,
        public adminService: AdminService,
        public modalController: ModalController,
        private router: Router,
        private menuCtrl: MenuController,
        private changeRef: ChangeDetectorRef,
        public popoverCtrl: PopoverController,
        public appSettings: AppSettings,
        public storage: Storage,
        private swUpdate: SwUpdate
    ) {
        this.start();

        router.events.subscribe((val) => {
            this.setActivePage(this.router.url);
        });
        localStorage.clear();
        sessionStorage.clear();

        this.clearCache();
    }

    async clearCache(): Promise<void> {
        if ('caches' in window) {
            const cacheNames = await caches.keys();

            await Promise.all(
                cacheNames.map(async (cacheName) => {
                    const cache = await caches.open(cacheName);
                    const keys = await cache.keys();

                    await Promise.all(
                        keys.map(async (request) => {
                            await cache.delete(request);
                        })
                    );
                })
            );
        }
    }

    async ngOnInit() {
        //////console.log'storage create!!!');
        await this.storage.create();

        // let me = this;
        // this.events.subscribe('onscroll_innerpage', params => {
        //     clearTimeout(me.showHeaderTimeOut);
        //     if (!params['on']) {
        //         me.showHeaderTimeOut = setTimeout(function () {
        //             me.showHeaderFooter = true;
        //         }, 500);
        //     } else {
        //         me.showHeaderFooter = false;
        //     }
        // });
        // check for platform update
        let me = this;
        //console.log('this.swUpdate.isEnabled:', this.swUpdate.isEnabled)
        if (this.swUpdate.isEnabled) {
            setTimeout(function () {

                me.swUpdate.checkForUpdate().then(() => {
                    // checking for updates
                    //console.log('checking for updates!!')
                })
            });
        }
        this.swUpdate.available.subscribe(() => {
            // me.hasUpdate = true;
            //console.log('new updates!!!!1')
            //console.log('new updates!!!!2')
            window.location.reload();
        });

        this.platform.ready().then(async () => {
            setTimeout(() => {
                SplashScreen.hide({
                    fadeOutDuration: 1000
                });
            }, 2000);

            // await SplashScreen.show({
            //     showDuration: 2000,
            //     autoHide: true,
            // });

        })
    }


    reloadSite(): void {
        location.reload();
    }

    start() {
        let me = this;
        this.platform.ready().then(() => {

            // this.menuCtrl.enable(true, 'menu');
            this.mainService.closeMenu('menu', true);

            this.businessService.setScreenSize(this.platform.width(), this.platform.height());
            //console.log("this.platform:", this.platform);
            if (this.platform.is("mobileweb")) {
                // if (cordova) {
                this.platformType = "browser";//cordova['platformId'];
                // //////console.logthis.statusBar);
                // if (cordova['platformId'] == 'android') {
                // this.statusBar['backgroundColorByHexString']('#fafbff');
                // this.statusBar['styleLightContent']();
                // }

                // StatusBar.hide();
                // SplashScreen.hide();

                // }
            }

            this.pushService.start();

            // ////console.log"width:" + this.platform.width());
            // if (this.platform.width() > 1000) {
            //     this.menuSmall = true;
            // } else {
            //     this.menuSmall = false;
            // }

            this.changeStatusBar();

            this.mainService.start();
        });


        // let me = this;
        // setTimeout(function () {
        // this.timeoutSets = true;
        this.appsets['timeout'] = true;
        this.setAppReady();
        // }, 1500);

        this.initializeApp();

        this.events.subscribe('showAdminMenu', (params) => {
            //////console.log"!!!showAdminMenu!!!");
            this.goTo({'url': '/taarif'});
        });

        this.events.subscribe('businessSets', (params) => {
            this.business = this.businessService.getBusiness();
            this.catalog = this.businessService.isCatalog();

            this.appsets['business'] = true;

            this.setSocialSupport();

            this.setAppReady();
            this.setPages();

            let me = this;
            //no menu business - tmp fix
            //no menu business - tmp fix
            // if (AppConfig.bisPrefix == 'gettergroup') {
            //     setTimeout(function () {
            //         me.mainService.closeMenu(null, false);
            //     }, 100);
            // } else {
            //     me.mainService.closeMenu(null, false);
            //     setTimeout(function () {
            //         me.mainService.closeMenu('menuSection', true, false);
            //         me.mainService.closeMenu('cartMenu', true, false);
            //     }, 100);
            // }
        });

        this.events.subscribe('businessImagesSets', (params) => {
            this.businessImages = this.businessService.getImages();
            let key = 'flag_image'
            if (!this.businessService.getImages().data[key])
                key = 'logo_icon1_image'
            if (this.businessService.getImages().data[key]) {
                this.AppConfig.NONE_IMAGEURL = this.businessService.getImages().data[key]['imagefullurl'];
            }
        });

        this.events.subscribe('businessColorsSets', (params) => {
            // this.colorSets = true;

            this.appsets['colors'] = true;
            this.setAppReady();
        });

        this.events.subscribe('appDataSets', (params) => {
            // this.appDataSets = true;
            // //console.log('SUBS', 'appDataSets')

            this.appsets['appData'] = true;
            this.changeRef.detectChanges();
            this.setAppReady();
        });

        this.events.subscribe('appPriceSets', (params) => {
            this.appsets['appPrices'] = true;
            this.setAppReady();
        });

        this.events.subscribe('cartUpdated', (params) => {
            // ////console.log"CARE CHANGE !!!")
            // let me = this;
            // setTimeout(function () {
            //     me.changeRef.detectChanges();
            // }, 500)
        });

        //app agent?? move to dashboard
        this.events.subscribe('appuserChanged', (params) => {
            this.appuser = this.orderService.getAppUser();
            this.setSocialSupport();
        });

        //app agent?? move to dashboard
        this.events.subscribe('checkInitialPopups', (params) => {
            this.checkInitialPopups();
        });

        // this.events.subscribe('viewQuickCart', (value) => {
        //     this.viewQuickCart(value);
        // });

        this.events.subscribe('initLogingChecked', (params) => { // from AUTH SERVICE.
            // ////console.log'initLogingChecked')
            this.appsets['initLoging'] = true;
            this.setAppReady();
        });

        // from APP HEADER.
        // this.events.subscribe('showMenu', () => {
        //     this.closeMenu();
        // });

        // from APP HEADER.
        this.events.subscribe('showMenu', (data) => {
            me = this;

            //two menus together data.
            if (data.menu == 'boxHeader') {
                this.sideShowModel1 = null;
                setTimeout(function () {
                    me.sideShowModel1 = data;
                    me.mainService.showMenu(data);
                }, 150);
            } else {
                this.sideShowModel = null;
                setTimeout(function () {
                    me.sideShowModel = data;
                    me.mainService.showMenu(data);
                }, 150);
            }


        });

        // this.events.subscribe('showRegisterModal', (params) => { // from AUTH GURAD SERVICE.
        //     this.mainService.showRegisterModal();
        // })
        // this.subscription = this.route.queryParams.subscribe(params => {
        //     let passpref = params['passpref'];
        //     if (passpref) {
        //         let navigationExtras: NavigationExtras = {
        //             queryParams: {'passpref': passpref}
        //         };
        //
        //         this.navCtrl.navigateForward('reset-password', navigationExtras)
        //     }
        //
        //     //     let prefix = params['pref'];
        //     //     if (prefix) {
        //     //         this.businessService.setBisByPrefix(prefix);
        //     //
        //     //         this.orderService.setCartPrefix(params['cart']);
        //     //
        //     //         let me = this;
        //     //         setTimeout(function () {
        //     //             me.unsubscribe();
        //     //         }, 100);
        //     //     }
        // });
    }


    changeStatusBar() {
        // StatusBarCap.setStyle({
        //     style: StatusBarStyle.Light
        // });
        //
        // // Display content under transparent status bar (Android only)
        // StatusBarCap.overlaysWebView({
        //     overlay: false
        // });
    }

    setAppReady() {
        // console.trace()
        let me = this;

        if (me.ready) // if app already opened.
        {
            me.changeRef.detectChanges();
            return;
        }

        // ////console.log"#### SET APPREADY PROCEED!")

        // setTimeout(function () {
        me.ready = true;
        for (let k in me.appsets) {
            // ////console.log"#### SET APP READY", me.appsets[k])

            if (!me.appsets[k]) me.ready = false;
        }
        // //////console.log'ready!', me.ready)
        // this.ready = this.business && this.colorSets && this.appDataSets && this.timeoutSets;
        if (me.ready) {
            // let me = this;
            this.checkInitialPage();
            this.checkInitForms();

            this.cartService.start();
        }
        // }, 300)

    }

    checkOpening() {
        if (this.business.data.GlobalSettingsData.closingForOrders) {
            this.showClosingPopup(this.business.data.GlobalSettingsData.closingText, this.business.data.GlobalSettingsData.closingForOrdersText);
            return true;
        }

        if (!this.business.data.GlobalSettingsData.openingData['open']) {
            this.showClosingPopup(this.business.data.GlobalSettingsData.closingText);
            return true;
        }
        return false;
    }


    async showClosingPopup(title, text = null) {
        const popover = await this.popoverCtrl.create({
            component: OpeningComponent,
            componentProps: {
                'title': title,
                'text': text,
            },
            event: null,
            animated: true,
            showBackdrop: true,
            cssClass: 'note-popover icenter-popover'
        });
        return await popover.present();
    }

    setSocialSupport() {
        let phone = this.business.data.GlobalSettingsData['supportCellphone'];
        if (this.appuser && this.appuser.data.companyAgent)
            phone = this.appuser.data.companyAgent['cellphone'];

        this.socialSupport['visible'] = phone != null && phone != '';
        this.socialSupport['img'] = 'assets/dashboard/whatsapp.png';
        this.socialSupport['value'] = phone;
        this.socialSupport['type'] = 'whatsapp';

        // //////console.log"SOCIAL A!!", this.appuser);
        // //////console.log"SOCIAL B!!", this.business.data.GlobalSettingsData);
    }

    openAdminPageCustom(params) {
        let navigationExtras: NavigationExtras = {
            queryParams: params
        };
        this.navCtrl.navigateForward('admin-model/', navigationExtras);
    }

    openAdminPage(menuIndex, subIndex = 0) {
        // this.menu.close();
        let params = {
            'sectionName': this.adminPages['showBoxes'][menuIndex][subIndex],
            'menuIndex': menuIndex,
            'subIndex': subIndex,
        };

        let navigationExtras: NavigationExtras = {
            queryParams: params
        };

        this.viewQuickCart(false);

        //for keeping memmory home page will be gone.
        // if (this.router.url == '/taarif') {
        // ////console.log'tarif page!!')
        //     this.navCtrl.navigateRoot('admin-model/' + this.adminPages['showBoxes'][menuIndex][subIndex], navigationExtras);
        // } else {
        this.navCtrl.navigateForward('admin-model/' + this.adminPages['showBoxes'][menuIndex][subIndex], navigationExtras);
        // }
    }

    setAdminMenu(openPage = null) {

        if (this.adminPages) {
            if (openPage) {
                if (openPage == '/calendar') {
                    this.openCalendar();
                }
            }
        } else {
            // this.menuLoading = true;
            let me = this;
            this.adminService.loadAdmin().then(function (tableData: any) {
                if (tableData) {
                    me.adminPages = tableData;
                    //if not already open on cached adminMenu above
                    if (openPage && !me.onAdminMenu) {
                        if (openPage == '/calendar') {
                            me.openCalendar();
                        }
                    }
                }

                me.menuLoading = false;
            });
        }
    }

    openCalendar() {
        let sectionName = 'calendar';
        let params = {
            'sectionName': sectionName,
        };
        let navigationExtras: NavigationExtras = {
            queryParams: params
        };
        this.navCtrl.navigateForward('admin-model/' + sectionName, navigationExtras);
    }

    setPages() {

        this.appPages.length = 0;

        let nav = [
            // {
            //     'title': 'catalog',
            //     'icon': 'storefront-outline',
            //     'pages': [],
            //     'template': 'topsec',
            //     'active': true,
            // },

            {
                'title': '',
                'icon': 'basket-outline',
                'pages': [
                    {key: 'mainPage', title: 'mainPage', url: '/main', icon: 'home-outline'},
                ],
                'active': true,
            },

            //manager admin ..
            {
                'title': 'Management',
                'icon': 'basket-outline',
                'pages': [
                    {
                        key: 'dashboard', title: 'Dashboard', url: '/main-agents', icon: 'speedometer-outline',
                        'auth': true,
                    },
                    {
                        key: 'taarif', title: 'Managment', url: '/taarif', icon: 'calculator-outline',
                        'auth': true,
                        'agent': true
                    },
                    {
                        key: 'calendar', title: 'Calendar', url: '/calendar', icon: 'calendar-outline',
                        'auth': true,
                        'agent': true
                    },
                    {
                        key: 'reports', title: 'reports', url: '/reports', icon: 'newspaper-outline',
                        'auth': true,
                        'agent': true
                    },
                    {
                        key: 'businessManager',
                        title: 'businessManager',
                        url: '/business-manager',
                        icon: 'settings-outline',
                        'auth': true
                    },
                    // {title: 'sectionsManager', url: '/sections-manager', icon: 'settings', 'auth':true},
                ],
                'active': false,
                'auth': true,
                'staff': true,
            },

            {
                'title': 'Products',
                'icon': 'basket-outline',
                'pages': [
                    {
                        key: 'store',
                        title: this.business.data.GlobalSettingsData.menuText ? this.business.data.GlobalSettingsData.menuText : 'store',
                        url: '/store',
                        icon: 'basket-outline'
                    },
                    {key: 'cart', title: 'cart', url: '/cart', icon: 'cart-outline', 'catalog-hide': true},

                    {key: 'sales', title: 'sales', url: '/sales', icon: 'pricetag-outline'},
                    {
                        key: 'makers',
                        title: 'makers',
                        url: '/makers',
                        icon: 'cog-outline',
                    },
                    {
                        key: 'quickNavigation',
                        title: 'quickNavigation',
                        url: '/catalog-list',
                        icon: 'nuclear-outline'
                    },

                    // {key: 'stockReports', title: 'stockReports', url: '/stock-reports', icon: 'megaphone', 'stock': true},
                ],
                'active': true,
            },

            {
                'title': 'Private',
                'icon': 'basket-outline',
                'pages': [
                    {
                        key: 'usersManager',
                        title: 'usersManager',
                        url: '/users-manager',
                        icon: 'people-outline',
                        'auth': true,
                        'agent': true
                    },
                    {key: 'favorites', title: 'favorites', url: '/favorites', icon: 'star-outline'},
                    {key: 'myProfile', title: 'myProfile', url: '/profile', icon: 'person-outline'},
                    {
                        key: 'myOrders',
                        title: 'myOrders',
                        url: '/orders',
                        icon: 'mail-outline',
                        'catalog-hide': true,
                    },
                    {
                        key: 'agent-clients', title: 'clients', url: '/agent-clients', icon: 'calculator-outline',
                        'auth': true,
                        'agent': true
                    },
                ],
                'active': false,
            },

            {
                'title': 'Information',
                'icon': 'basket-outline',
                'pages': [
                    {key: 'about', title: 'about', url: '/about', icon: 'information-circle-outline'},
                    // {title: 'sectionsManager', url: '/sections-manager', icon: 'settings', 'auth':true},
                    // {
                    //     key: 'technicalLiterature',
                    //     title: 'technicalLiterature',
                    //     url: '/technical-literature',
                    //     icon: 'bookmarks-outline'
                    // },
                    {
                        key: 'support',
                        title: 'support',
                        url: '/support',
                        icon: 'chatbox-ellipses-outline',
                        social: this.socialSupport,
                    },
                    {key: 'contact', title: 'contact', url: '/contact', icon: 'pin-outline'},
                    {key: 'terms', title: 'terms', url: 'terms', icon: 'list-circle-outline'},
                ],
                'active': false,
            },

            // {
            //
            //     'title': 'myArea',
            //     'icon':'person-outline',
            //     'pages': [
            //     ],
            //     'active': false,
            // },

        ];

        let settings = this.appSettings.getSettings('side-menu');
        let settingsPages = settings['pages'];
        if (!settingsPages) settingsPages = ['all'];
        if (settings['app_nav']) nav = settings['app_nav'];

        for (let k in nav) {
            let pages = nav[k]['pages'];
            for (let p in pages) {
                if ((settingsPages.indexOf(pages[p]['key']) != -1 || settingsPages[0] == 'all') && !settings['hide_' + pages[p]['key']]) {
                    pages[p]['hidden'] = false;

                    if (pages[p]['title'] == 'contact' && settingsPages.indexOf('support') == -1 && settingsPages.indexOf('all') == -1) {
                        pages[p]['img'] = 'assets/dashboard/whatsapp.png';
                        pages[p]['imgField'] = 'supportCellphone';
                        pages[p]['whatsapp'] = true;
                        pages[p]['social'] = this.socialSupport
                    }
                    if (pages[p]['key'] == 'support') {
                        pages[p]['social'] = this.socialSupport
                    }
                } else {
                    pages[p]['hidden'] = true;
                }
            }
        }
        this.appPages = nav;

    }

    checkInitForms() {
        // ////console.log'this.mainService.isClosed', this.mainService.isClosed)
        // if (this.appSettings.getSettings('initApp') == 'modal' && !this.mainService.isClosed)
        //     this.mainService.showRegisterModal({});
    }

    checkDowloadPopup() {
        if (!this.business.data.GlobalSettingsData['googleplayLink'])
            return false;

        console.log(this.platformType)

        if (this.platformType != 'browser')
            return false;

        this.popUpDownload();
        return true;
    }

    //desktop application download
    //desktop application download
    async popUpDownload() {
        const popover = await this.popoverCtrl.create({
            component: PlatformsDownloadComponent,
            componentProps: {},
            event: null,
            animated: true,
            showBackdrop: true,
            cssClass: 'note-popover icenter-popover'
        });
        return await popover.present();
    }

    unsubscribe() {
        this.subscription.unsubscribe();
    }

    initializeApp() {
        let me = this;
        this.platform.ready().then((params) => {
            // SplashScreen.hide();

            // this.ga.track('create', 'UA-Y86FZTBBJE', 'auto');

        });

        gtag('event', 'enter_app',
            {
                'user': 'main page!!'
            }
        );
    }

    goTo(pageData, params = {}) {
        // //console.log('go to !!')
        // this.menuCtrl.close();
        this.mainService.closeMenu();
        ////console.log'btn click:');

        let page = pageData['url'];
        ////console.logpage);
        let navigationExtras: NavigationExtras = {
            queryParams: params
        };

        this.viewQuickCart(true);


        if (page == '/adminBack') {
            this.onAdminMenu = false;
            this.setPages();
            return;
        }
        if (page == '/taarif') {
            this.menuSmall = false;
            this.setAdminMenu();
            this.onAdminMenu = true;
            this.viewQuickCart(false);
            let me = this;
            setTimeout(function () {
                me.navCtrl.navigateRoot(page);
            });
            return;
        }

        if (page == '/calendar') {
            this.setAdminMenu(page);
            return;
        }

        if (page == 'main') {
            this.navCtrl.navigateRoot(page);
            return;
        }

        if (pageData['social'] && pageData['social']['visible']) {
            // let phone = this.business.data.GlobalSettingsData.supportCellphone;
            // let phone = this.business.data.GlobalSettingsData.supportCellphone;
            // if(this.appuser.data.companyAgent)
            //     phone = this.appuser.data.companyAgent['cellphone'];
            // if (phone) {
            this.sendWhatsappMessage(pageData['social']['value']);
            // cordova.plugins['Whatsapp'].send(phone);
            return;
            // }
        }

        if (pageData['extLink']) {
            window.open(pageData['extLink'], '_system', 'location=yes');
            return;
            // }
        }

        if (page == 'login') {
            let settings = this.appSettings.getSettings('login')
            if (settings == 'modal') {
                this.mainService.showRegisterModal();
                return;
            }
        }

        if (page == 'terms') {
            // this.mainService.notePopup('useTerms', 'useTerms', '');
            this.mainService.notePopup('useTerms', 'useTerms', this.business.data.GlobalSettingsData.useTerms);
            return
        }

        //console.log("NAVIGATE", page, navigationExtras)

        this.navCtrl.navigateForward(page, navigationExtras);
        // if(this.businessService.getScreenSize()[0] < 800)
        this.mainService.closeMenu();
        // this.menu.isOpen()
    }

    sendWhatsappMessage(phone) {
        let url = 'http://api.whatsapp.com/send?phone=' + phone;

        window.open(url, '_system', 'location=yes');

        // window.open(url, '_blank');
        //
        // const browser = this.iab.create(url);
        // browser.show()

        // this.contacts.find(["displayName", "phoneNumbers"], {multiple: true}).then((contacts) => {
        //   // this.contactsfound = contacts;
        //     //////console.logcontacts)
        //  })
    }

    async createContact() {
        // let contact = this.contacts.create();
        // contact.name = new ContactName(null, 'Smith', 'John');
        // contact.phoneNumbers = [new ContactField('mobile', '6471234567')];
        // contact.save().then(
        //     () => //////console.log'Contact saved!', contact),
        //     (error: any) => console.error('Error saving contact.', error)
        // );
    }

    setActivePage(page) {
        this.activePage = page;
        this.activePageArr = page.split('/');
    }

    checkInitialPage() {
        let params = getUrlParams()
        // this.route.queryParams.subscribe(params => {
        let storageParmas = {};
        let key = 'initialParams_' + AppConfig.bisPrefix;

        this.modelTools.getKey(key).then((res: object) => {
            if (res) {
                for (let p in res) {
                    storageParmas[p] = res[p]
                }
            }

            if (params['r'] || params['cp']) {
                storageParmas['register'] = {'r': params['r'], 'cp': params['cp']}
                this.modelTools.setKey(key, storageParmas);
                this.mainService.goToRegister();
                return;
            }

            if (params['inititem'])
                this.goTo({'url': 'item/' + params['inititem']})

            //console.log('SET KEY', key, storageParmas)

            this.modelTools.setKey(key, storageParmas);
        });

        this.checkInitialPopups();
    }

    checkInitialPopups() {

        let me = this;
        setTimeout(function () {
            let ispopup = null;

            if (!me.appinit) {
                ispopup = me.checkDowloadPopup();
                me.appinit = true;
            }

            if (!ispopup)
                me.checkOpening();

            // me.backgroundVisible = 1;
            // setTimeout(function () {
            //     me.backgroundVisible = 2;
            // }, 1000);

            me.changeRef.detectChanges();
        }, 300);


        // });
    }

    checkMenuPageAuth(page) {
        if (!this.appuser) return false;
        let staff = this.appuser.data['is_staff'];
        let agent = this.appuser.data['userType'] == 3;
        if (page['agent'])
            return staff || agent;
        return staff;
    }

    hideMenuItem(p) {
        if (p['hidden'])
            return true;

        if (p['auth'])
            return !this.checkMenuPageAuth(p);

        if (p['catalog-hide'])
            return this.catalog;

        if (p['stock'])
            return !this.business.data.GlobalSettingsData.stockReports;


        return false;
    }


    // helpersSections = [
    //     'A+',
    //     'A-',
    //     'הגברת ניגודיות',
    //     'ניגודיות נמוכה',
    //     'מונוכרום',
    //     'הדגש קישורים',
    //     'בטל אנימציה',
    //     'פונט יותר קריא',
    //     'איפוס הגדרות',
    // ];
    negishotSize(size) {
        this.negishotkey.splice(this.negishotkey.indexOf("size1"), 1);
        this.negishotkey.splice(this.negishotkey.indexOf("size2"), 1);
        this.negishotkey.splice(this.negishotkey.indexOf("size3"), 1);
        this.negishotkey.splice(this.negishotkey.indexOf("size4"), 1);
        this.negishotkey.push("size" + size);
    }

    negishot(i, removeKey = 99) {

        // if (removeKey != 99) {
        //     this.negishotkey.splice(this.negishotkey.indexOf(removeKey), 1);
        // }
        this.negishotkey = [];
        // if (i == 0) {
        if (i == 6) {
            this.negishotkey = [];
            this.mainService.sliderAutoPlay = true;
        } else if (i == 4) {
            // this.businessService.
            //////console.log'auto play off');
            this.mainService.sliderAutoPlay = false;
        } else {
            this.negishotkey.push('neg' + i);
        }

        // }

        //////console.logthis.negishotkey);
    }

    toSmall() {
        let me = this;
        if (this.platform.width() > 1000) {
            setTimeout(function () {
                me.menuSmall = true;
            }, 3000);
        }
    }

    viewQuickCart(value) {
        ////console.log'viewQuickCart', value);
        this.cartActive = value;
    }

    onScroll(e = null) {
    }
}

function getUrlParams() {
    let urlParams = window.location.search.substr(1);
    // //console.log("URL PARMAS", window.location['origin'])
    let res = {};
    let parts = urlParams.split('&');
    for (let k in parts) {
        if (parts[k] == '') continue;
        let part = parts[k].split('=');
        res[part[0]] = part[1]
    }

    return res
}