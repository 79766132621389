import {ModelProvider} from "../../framework/providers/modelProvider/ModelProvider";
import {TranslateService} from "@ngx-translate/core";
import {MenuController, ModalController, Platform, PopoverController} from "@ionic/angular";
import {AppConfig} from "../conf/main";
import {Storage} from "@ionic/storage";
import {OrderService} from "./OrderService";
import {BusinessService} from "./BusinessService";
import {AppDataService} from "./AppDataService";
import {PhoneRegisterPage} from "../pages/register/pages/phone-register/phone-register.page";
import {InitFormsPage} from "../pages/forms/pages/init-forms/init-forms.page";
import {AppSettings} from "../conf/appSettings";
import {ModelToolsProvider} from "../../framework/providers/model-tools/model-tools";
import {ActivatedRoute, Router} from "@angular/router";
import {Business} from "../../framework/model/core/business/business";
import {NoteComponent} from "../../framework/components/core/note/note.component";
import {Injectable} from "@angular/core";
import {Events} from "../../framework/providers/events";
import {AuthService} from "../../framework/providers/auth-service/auth-service";

@Injectable()
export class MainService {

    public dir;
    public sliderAutoPlay: boolean = true;

    public userLoggedChecked: boolean = false;
    public userLoggedFromStorage;

    public initialParams;
    public lastSavedState;

    public isClosed: boolean = false;

    public business: Business;

    public isbrowser;
    private initial: boolean = false;

    public currentShowModel: any;

    menuOpen = ['menu2'];
    menus = ['menu2', 'cartMenu', 'menuSection', 'appAdminBoxMenuId', 'menuAdminFilter', 'tableMenu', 'boxHeader'];


    constructor(private modelProvider: ModelProvider,
                public translate: TranslateService,
                public orderService: OrderService,
                public businessService: BusinessService,
                public appDataService: AppDataService,
                public modalController: ModalController,
                public storage: Storage,
                public appSettings: AppSettings,
                public events: Events,
                public menuCtrl: MenuController,
                public router: Router,
                public route: ActivatedRoute,
                public popoverCtrl: PopoverController,
                private auth: AuthService, public platform: Platform,
                public modelTools: ModelToolsProvider) {
        this.isbrowser = this.platform.is('mobileweb') || this.platform.is('desktop');
    }

    ngOnInit() {
    }

    start() {

        this.orderService.start();
        this.businessService.start();

        this.events.subscribe('set_login', (params) => {
            this.login(params);
        });

        this.events.subscribe('inviteToApp', (params) => {
            this.inviteToApp()
        });

        this.events.subscribe('goTo', (params) => {
            this.modelTools.goTo(params['page'], params['params'])
        });

        this.events.subscribe('shareViaWhatsapp', (params) => {
            this.shareViewWhatsapp(params);
        });

        this.events.subscribe('viewTextAlert', (res) => {
            this.modelTools.textAlert(this.translate.instant(res['title']), this.translate.instant(res['text']));
        })

        this.events.subscribe('businessSets', (res) => {
            this.business = this.businessService.getBusiness();
            this.setDefLangs();

            this.route.queryParams.subscribe(params => {
                if (this.initialParams) return;
                //////console.log'INITAL PARAMS', params)
                this.initialParams = params;
            });

            let settings = this.appSettings.getSettings('app');
            let appuser = this.orderService.getAppUser();

            this.isClosed = this.business.data.GlobalSettingsData.closingForOrders ||
                !this.business.data.GlobalSettingsData.openingData['open'];

            if (!this.initial) {
                if (settings['init-login'] && !appuser) {
                    // this.setMenu(false);
                    this.modelTools.goTo('login', {'block': true})
                    // this.openModal(LoginPage)
                    // this.modelTools.goTo('login', {});
                } else {
                    this.goInitPage(true);
                }

                this.initial = true;
            }


        });

        // this.events.subscribe('initLogingChecked', (params) => { // from AUTH SERVICE.
        //     ////console.log'main-service', 'initLogingChecked')
        // });

        this.setUserLogged();
        this.appDataService.start();

    }


    public saveLastState(stateName) {
        this.lastSavedState = {'stateName': stateName, 'url': this.router.url};
    }


    inviteToApp() {
        this.modelTools.goTo('contacts');

        return
        let url = this.getAppUrl() + '?r=' + this.orderService.getAppUserKey();
        let text = this.business.data.GlobalSettingsData.inviteText;
        if (!text) text = this.business.data.GlobalSettingsData.name;
        this.shareViewWhatsapp({'data': [text, url]});
    }

    //
    // setData() {
    //     this.appDataService.setAppData();
    // }

    switchApp(pref) {
        this.orderService.clearCart();
        if (this.orderService.getAppUser()) {
            let sub_prefix = this.modelTools.getRandomize();
            this.events.subscribe('appuserChanged', (params) => {
                this.events.unsubscribe('appuserChanged', sub_prefix)
                this.userChanged(params, this.userLoggedFromStorage);
            }, sub_prefix);

            this.orderService.listAppuser(null, pref, false)
        } else {
            this.goToApp(pref, false);
        }
        this.menuCtrl.close();
    }

    goToApp(pref, fromStorage) {
        this.businessService.setBisByPrefix(pref, false, true, false);

        // AppConfig.bisPrefix = appuser.data.prefix;
        // this.appDataService.setStorageKey(AppConfig.bisPrefix);
        // this.appDataService.setAppData();

        //console.log('>>>>> MAIN SERVICE SUBSCRIBE appDataSets')
        this.events.subscribe('appDataSets_mainservice', (res) => {
            // console.log('MASIN SERVICEEEE, appdatasets1', fromStorage)
            // this.events.publish('getDataPrices');
            this.events.publish('updateDataKeysLists');
            this.goInitPage(false, fromStorage);
            this.setMenu(true);
            this.events.publish('app_switched');

            this.modelProvider.loadingOff();
            //console.log('<<<<< MAIN SERVICE *UN* SUBSCRIBE appDataSets <<<<<')

            this.events.unsubscribe('appDataSets_mainservice')
        })
    }

    public setUserLogged() {
        this.events.subscribe('userLogged', (params) => {
            this.userLoggedFromStorage = params['fromStorage'];
            // ////console.log'userLogged', this.userLoggedFromStorage)
            this.userLoggedChecked = false; // fix for unsubscribe. cant do unsubscribe on service.
            this.events.subscribe('appuserChanged', (params) => {
                if (this.userLoggedChecked)
                    return;
                this.userLoggedChecked = true;
                // this.events.unsubscribe('appuserChanged');
                // ////console.log'appuserChanged', this.userLoggedFromStorage)

                this.userChanged(params, this.userLoggedFromStorage);

            });
        });
    }

    userChanged(params, fromStorage) {
        // this.modelProvider.loading();
        //console.log("##### MAIN SERVICE USER LOGGED!!!!")
        // //////console.logparams);
        // let settings = this.appSettings.getSettings('app');

        let appuser = params['appuser'];
        //console.log(appuser, AppConfig.mode);
        if (appuser.data.prefix != AppConfig.bisPrefix) {
            // if (AppConfig.mode == 'oneflow' || AppConfig.corePrefix == 'oneflow') {
            if (!appuser) {
                this.modelProvider.loadingOff();
                //     // //////console.log'LOGOUT!')
                //     this.businessService.removeBusiness();
                //     // this.modelTools.goTo('login', {'block':true});
                return;
            }

            ////console.log"LOADING MAIN")
            // this.modelProvider.loading();
            if (appuser.data.prefix == this.business.data.prefix) {
                this.goInitPage(false, fromStorage);
                return;
            }

            // //console.log('FINDING APPUSER BUSINESS..........')

            this.goToApp(appuser.data.prefix, fromStorage)
        } else {
            this.setMenu(true);
            this.modelProvider.loadingOff();
            this.goInitPage(false, fromStorage);
        }


        //tmp
        //testing
        // this.showAdmin(0);
        // return;
        // return;
        //testing
    }

    goInitPage(paramsOnly = false, fromStorage = false) {
        // //console.log('GO INIT PAGE MAIN SERVICE!', this.initialParams)

        let appuser = this.orderService.getAppUser();
        let settings = this.appSettings.getSettings('app');

        if (this.initialParams) {
            if (this.initialParams['order']) {
                this.modelTools.goTo('order/' + this.initialParams['order']);
                // //////console.log"INIT ORDER PAGE")
                return;
            }
            if (this.initialParams['initp']) {
                this.modelTools.goTo(this.initialParams['initp']);
                return;
            }
        }

        if (paramsOnly) return;

        let cartData = this.orderService.getCartData();
        if (cartData['amount'] && !fromStorage) {
            this.modelTools.goTo('cart');
            return
        }

        // if (appuser.data.userType == 3 && settings['agentsPage']) {
        //     this.modelTools.goTo('main-agents');
        //     return
        // }

        // //////console.log"INIT MAIN! PAGE")
        let page = 'main'
        if (settings['after_login_page']) {
            if (!appuser.data.paymentVerified) {
                page = settings['after_login_page']
            }
        }
        this.modelTools.goTo(page);
    }

    public setMenu(key) {
        //////console.log"MENU", key)
        this.menuCtrl.enable(key);
    }

    closeMenu(menuId = null, open = true, closeAll = true, forceOpen = false) {
        if (!menuId) {
            for (let menuId of this.menus) {
                this.menuCtrl.close(menuId);
                this.menuCtrl.enable(false, menuId);
            }
            this.menuCtrl.close();
            return;
        }

        if (closeAll) {
            for (let menuId2 of this.menus) {
                this.menuCtrl.close(menuId2);
                this.menuCtrl.enable(false, menuId2);
            }
        }

        // close toggle
        if (!forceOpen && (!open || (open && this.menuOpen.indexOf(menuId) != -1))) {
            this.menuCtrl.close(menuId);
            this.menuCtrl.enable(false, menuId);
            this.menuOpen.splice(this.menuOpen.indexOf(menuId), 1);
            return
        }
        // this.menu.isOpen(menuId).then((isopen) => {
        //     console.log('babboooo')
        //     // this.menu.enable(false, 'main');
        //     console.log(isopen)
        //     // this.menu.close('menu');
        //     //     //
        //

        //open requested menu
        if (closeAll && this.menuOpen.indexOf(menuId) != -1) {
            this.menuCtrl.open(menuId);
            this.menuCtrl.enable(true, menuId);
            // this.menu.enable(false, menuId).then(() => {
            // });
        } else {
            if (closeAll) {
                for (let menuId of this.menuOpen) {
                    this.menuCtrl.close(menuId);
                    this.menuCtrl.enable(false, menuId);
                }
            }
            // this.menuOpen = true;
            this.menuCtrl.enable(true, menuId).then(() => {
                this.menuCtrl.open(menuId);
            });
        }
        this.menuOpen.push(menuId);

        // });
        // this.menuOpen = !this.menuOpen;
    }


    showMenu(data) {
        //from call event.
        let sideShowModel = this.currentShowModel;

        let me = this;
        if (data.menu == 'columns') {
            sideShowModel = null;
            setTimeout(function () {
                sideShowModel = data;
                me.closeMenu('tableKeysMenu', true);
            }, 10);
        } else if (data.menu == 'adminTableFilter') {
            if (sideShowModel && sideShowModel['prefix'] == data['prefix']) {
                this.closeMenu();
                // this.menuCtrl.enable(false, 'menuAdminFilter');
                sideShowModel = null;
            } else {
                sideShowModel = null;
                setTimeout(function () {
                    sideShowModel = data;
                    me.closeMenu('menuAdminFilter', true);
                }, 10);
            }
        } else if (data.menu == 'menuAdminBox') {
            // from APP HEADER.
            if (sideShowModel && data['rowModel'] == sideShowModel['rowModel']) {
                sideShowModel['menu'] = data.menu;
                sideShowModel = null;
                this.currentShowModel = sideShowModel;
                this.closeMenu('appAdminBoxMenuId', true, false, true);
                return;
            }
            sideShowModel = null;
            let me = this;

            setTimeout(function () {
                sideShowModel = data;
                me.closeMenu('appAdminBoxMenuId', true, true, true);
            });
        } else {
            sideShowModel = null;
            setTimeout(function () {
                sideShowModel = data;
                me.closeMenu(data.menu, true, false, false);
            }, 10);
        }
    }


    public userLoggedOut() {

        if (AppConfig.mode == 'oneflow') {
            // //////console.log'LOGOUT!')
            this.businessService.removeBusiness();
            this.modelTools.goTo('login', {'block': true});
            return;
        }

        this.modelTools.goTo('main');

    }

    public setDefLangs() {
        let defaultLang = AppConfig.defaultLang;
        if (this.business.data.GlobalSettingsData.defaultLang) {
            defaultLang = this.business.data.GlobalSettingsData.defaultLang;
            AppConfig.defaultLang = defaultLang;
            AppConfig.currentLang = defaultLang;
        }
        //////console.log"DEFAULT LANG", this.business, defaultLang);

        this.translate.addLangs(AppConfig.langs);
        this.translate.setDefaultLang(defaultLang);
        this.translate.use(defaultLang);
        this.setDir(defaultLang);
    }

    public setLang(lang) {
        this.translate.use(lang);
        this.setDir(lang)
    }

    public setDir(lang) {
        var arr = AppConfig.ltrLangs;

        for (var k in arr) {
            if (arr[k] == lang) {
                this.dir = 'ltr';
                return;
            }
        }
        // AppConfig.dir = this.dir
    }

    public getDir() {
        return this.dir;
    }

    public handelLogin() {
        let settings = this.appSettings.getSettings('login');
        if (settings['type'] == 'modal') {
            this.modelTools.goTo('register');
            // this.showRegisterModal();
            return false;
        }

        this.modelTools.goTo('login');
        // this.router.navigate(["login"]);
    }

    goToRegister(params = {}) {
        let appuser = this.orderService.getAppUser();
        if (appuser) {
            this.modelTools.goTo('profile');
            return
        }

        // let settings = this.appSettings.getSettings('register')
        // //console.log('settings', settings)
        // if (settings['type'] == 'forms') {
        //     this.showFormsModal({'settingsKey': 'register'});
        //     return false;
        // }

        this.modelTools.goTo('register', params);
    }

    async showRegisterModal(params = null, props = {}) {
        props['loginData'] = params;
        const modal = await this.modalController.create({
            component: PhoneRegisterPage,
            componentProps: props,
            backdropDismiss: false
        });

        return await modal.present();
    }

    async showFormsModal(params = {}) {
        const modal = await this.modalController.create({
            component: InitFormsPage,
            componentProps: params,
            backdropDismiss: false
        });

        return await modal.present();
    }

    closeModal() {
        this.modalController.dismiss({
            'dismissed': true
        });
    }

    notePopup(key, title, text, style = {}) {
        this.setNotePopup(key, title, text, style);
    }

    async setNotePopup(key = null, title, text, style = {}) {
        const popover = await this.popoverCtrl.create({
            component: NoteComponent,
            componentProps: {
                'key': key,
                'text': text,
                'title': title,
                'style': style,
            },
            event: null,
            animated: true,
            showBackdrop: true,
            cssClass: 'note-popover'
        });
        return await popover.present();
    }

    async openModal(page, params = {}) {

        // params['ismodal'] = true;
        // this.modalController.dismiss();
        // //////console.logpage);
        // const modal = await this.modalController.create({
        //     component: page,
        //     componentProps: params,
        //     backdropDismiss: false
        // });
        //
        // return await modal.present();
    }


    //for testing only
    showAdmin(menuIndex, subIndex = 0) {
        return;
        //
        //     this.businessService.getBusiness().data.prefix == 'getterconsumer' ||
        //
        if (this.businessService.getBusiness().data.prefix == 'movingaid' ||
            this.businessService.getGlobalSettings().data.bisType == 'moving' ||
            this.businessService.getGlobalSettings().data.bisType == 'oneface') {

            let params = {sectionName: 'orderDetails'};

            ////console.log'go to taarif page ! ');
            this.events.publish('showAdminMenu');
            let me = this;
            // setTimeout(function () {
            // me.modelTools.goTo('admin-model/orderDetails', params);
            // }, 5000);
        }
    }


    public login(cren) {
        //console.log("LOGIN!", cren)
        // this.events.publish('setBackLoadingOn');
        // cren['username'] = cren['email'];
        this.auth.login(cren)
            .subscribe(allowed => {
                    this.events.publish('setBackLoadingOff');

                    // this.stopLoading();

                    if (allowed) {

                        // let registerMsg = this.business.data.GlobalSettingsData.registerDoneMessage;
                        // if (!registerMsg) {
                        //     registerMsg = this.translate.instant('welcome'), this.translate.instant('registrationSuccessfullyCompleted');
                        //     registerMsg += '\n' + this.translate.instant('accountNotValidateYet');
                        // }
                        // this.modelToolsProvider.textAlert(null, registerMsg);


                        //////console.log'user login in publish event!!');
                        // this.events.publish('user:login');
                        // this.mainService.userLogged()

                    } else {
                        this.modelTools.goTo('login', {'badLogin': true,});
                        //////console.log'false!')
                        //this.loading.dismiss();
                        // this.showError("Wrong credentials has been provided.");
                    }

                },
                error => {
                    // this.loading.dismiss();
                    // this.showError(error);
                });
    }

    getAppUrl() {
        let url = this.businessService.getGlobalSettings().data['appUrl'];
        if (!url)
            url = window.location['origin'] + '/';
        return url;
    }

    shareViewWhatsapp(params) {
        let url = 'http://api.whatsapp.com/send?';
        if(params['phone']){
            url+='phone='+params['phone'];
        }
        let data = params['data'];
        let details = '';
        for (let k in data) {
            details += this.getMessageLine(data[k]);
        }
        url += '&text=' + encodeURIComponent(details);
        window.open(url, '_system', 'location=yes');
        ////console.logurl)

    }

    getMessageLine(str) {
        if (!str) return '';
        // str = str.replace('\r\n', '%0a');
        // str = str.replace("<br>", '\r\n');
        str = str.split('<br>').join('\r\n');
        // str = str.split(' ').join('%20');
        // str = str.split("'").join('%27');
        return str + '\r\n'
    }


}









