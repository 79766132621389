import {ChangeDetectorRef, Component, Input, OnInit, ViewChild} from '@angular/core';
import {ModelProvider} from '../../../../../framework/providers/modelProvider/ModelProvider';
import {TranslateService} from "@ngx-translate/core";
import {IonSlides, ModalController, NavParams} from '@ionic/angular';
import {AppUser} from "../../../../../framework/model/core/appuser/appuser";
import {AuthService} from '../../../../../framework/providers/auth-service/auth-service';
import {BusinessService} from "../../../../services/BusinessService";
import {Business} from "../../../../../framework/model/core/business/business";
import {OrderService} from "../../../../services/OrderService";
import {BusinessImages} from "../../../../../framework/model/core/business/businessImages";
import {Events} from "../../../../../framework/providers/events";
import {NgForm} from "@angular/forms";

@Component({
    selector: 'phone-register',
    templateUrl: './phone-register.page.html',
    styleUrls: ['./phone-register.page.scss'],
})
export class PhoneRegisterPage implements OnInit {
    public ready: boolean = false;
    public inputData: object = {'phone': null, 'code': null, 'name': null};
    public inputRes: object = {};
    public userName: string;

    public loginData: object;
    public appuserData: object;
    public appuser: AppUser;

    @Input()
    public codeOnly: boolean;

    @Input()
    public prefix;

    @Input()
    public title;

    public business: Business;
    public businessImages: BusinessImages;

    public orderTypes: Array<any>;

    public pagesData: object = {
        'pages': {'phone': 0, 'code': 1, 'order-types': 2, 'address': 3, 'pickup': 4},
        'active': 'phone',
        'current': 0,
    };

    // @ViewChild('slides', null) slides: IonSlides;
    @ViewChild('form', { read: NgForm }) form: any;

    constructor(public modelProvider: ModelProvider,
                private changeRef: ChangeDetectorRef,
                public translate: TranslateService,
                private auth: AuthService, public navParams: NavParams,
                public businessService: BusinessService, public events: Events, public orderService: OrderService, public modalController: ModalController) {
        this.loginData = this.navParams.get('loginData');
    }

    ngOnInit() {
        //console.logthis.codeOnly)
        this.business = this.businessService.getBusiness();
        this.businessImages = this.businessService.getImages();

        this.events.subscribe('businessImagesSets', (res) => {
            this.businessImages = this.businessService.getImages();
        });


        this.orderTypes = this.business.data.GlobalSettingsData.orderTypes ? this.business.data.GlobalSettingsData.orderTypes.split(',') : [];
        //

        this.appuser = this.orderService.getAppUser();

        this.events.subscribe('appuserChanged', (params) => {
            this.appuser = this.orderService.getAppUser();
            // //console.logthis.business);
        });

        if(this.prefix) {
            this.events.subscribe(this.prefix + 'inputKeyExternalCheck', (params) => {
                this.inputRes[params['key']] = params['value'];
            });
        }

    }

    ngAfterViewInit() {
        let me = this;

        if (me.codeOnly) {
            me.swipTo('code');
        }

        setTimeout(function () {
            // me.changeRef.detectChanges();
            me.ready = true;
        }, 100);

        setTimeout(function () {
            me.lockSwipes(true);

            if (me.loginData) {
                me.swipTo('order-types');
            }

        }, 350);

    }

    //
    // onDestroy()
    // {
    //   //console.log"DESTTT")
    //   this.events.unsubscribe('appuserChanged');
    // }

    sendCode() {
        if (!this.inputData['phone'] || this.inputData['phone'] == '') return;

        this.inputRes['phoneError'] = this.inputData['phone'].length != 10;
        if (this.inputRes['phoneError']) return;

        let filters = {
            'phone': this.inputData['phone'],
            'text': this.translate.instant('yourCodeIs'),
            'loading': true,
            'setPrefix': true,
        }

        this.modelProvider.custom('checkUserByPhone', filters).then((res: any) => {
            // let res = {
            //     'id': 53690,
            //     'pinCode': 1234,
            //     'name': 'אדיר',
            //     'username': this.inputData['phone'],
            // }
            this.appuserData = res;
            this.appuser = new AppUser(this.appuserData);
            this.swipTo('code')
        })

    }

    checkCode() {
        // this.inputRes['codeError'] = this.inputData['code'] != this.appuserData['pinCode'] && this.inputData['code'] != AppConfig.globalPincode;
        // this.inputRes['nameError'] = (!this.inputData['name'] || this.inputData['name'] == '') && !this.appuserData['name'];
        //
        // if (this.inputRes['codeError'] || this.inputRes['nameError']) {
        //     return
        // }
        //
        // this.appuser = new AppUser(this.appuserData);
        // let updateAppUser = false;
        //
        // if (!this.appuserData['name']) {
        //     this.appuser.data['name'] = this.inputData['name'];
        //     updateAppUser = true;
        // }
        //
        // this.login({'email': this.appuser.data.username, 'password': this.appuser.data.username}, updateAppUser);
    }

    setType(type) {
        //console.logtype)
        this.orderService.setOrderType(type);
        if (type == 'delivery') {
            if (!this.appuser) {
                this.swipTo('phone');
                this.loginData = null;
                return
            }
            this.swipTo('address');
            return;
        }
        if ((type == 'takeAway' || type == 'sit') && this.business.data.GlobalSettingsData.branches) {
            if (!this.appuser) {
                this.swipTo('phone');
                this.loginData = null;
                return
            }
            this.swipTo('pickup');
            return;
        }


        this.close();
    }


    login() {
        // cren, updateAppUser = false

        this.inputRes['codeError'] = !this.inputData['code'];

        if(this.codeOnly)
        {
            if (this.inputRes['codeError']) return;

            this.events.publish(this.prefix, this.inputData['code']);
            return
        }


        this.inputRes['nameError'] = (!this.inputData['name'] || this.inputData['name'] == '') && !this.appuserData['name'];

        if (this.inputRes['codeError'] || this.inputRes['nameError']) {
            return;
        }


        let cren = {'email': this.appuser.data.username, 'password': this.inputData['code']};

        if (!cren['email'] || cren['email'] == '') return;
        if (!cren['password'] || cren['password'] == '') return;
        if(!this.form.value) return;

        cren['username'] = cren['email'];
        this.auth.login(cren)
            .subscribe(allowed => {
                    this.inputRes['codeError'] = !allowed;
                    //console.log"ALLOWED!!", allowed);
                    if (allowed) {
                        if (this.appuserData['fields'].length) {
                            for(let field of this.appuserData['fields']){
                                this.appuser.data[field] = this.inputData[field];
                            }

                            this.modelProvider.update(this.appuser).then((res) => {
                                // //console.log'RES!', res)
                                let appuser = this.orderService.getAppUser();
                                appuser.data.name = res['name'];
                            });
                        }

                        let type = this.orderService.getOrderType();
                        if (type) {
                            this.setType(type)
                        } else
                            if(!this.orderTypes.length)
                            {
                                this.close();
                                return
                            }
                            this.swipTo('order-types');
                    } else {
                    }
                },
                error => {

                    // this.loading.dismiss();
                    // this.showError(error);
                });
    }

    swipTo(key) {
        this.pagesData['active'] = key;
        // this.lockSwipes(false);
        // let me=this;
        // setTimeout(function()
        // {
        //     me.slides.slideTo(me.pagesData['pages'][key]);
        // })
        // let me=this;
        // setTimeout(function(){
        //     me.lockSwipes(true);
        // }, 100)
    }

    lockSwipes(value) {
        //console.log'lockSwipes', value)
        // this.slides.lockSwipes(value);
    }

    close() {
        this.modalController.dismiss();
    }

}