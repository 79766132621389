import {Component, Input, OnInit} from '@angular/core';
import {Events} from "../../../../../framework/providers/events";
import {ModelToolsProvider} from "../../../../../framework/providers/model-tools/model-tools";
import {ModelToImage} from "../../../../../framework/model/core/general/modelToImage";
import {AppDataService} from "../../../../services/AppDataService";
import {ItemProvider} from "../../providers/item-provider/item-provider.service";
import {OrderService} from "../../../../services/OrderService";
import {ActionSheetController} from "@ionic/angular";
import {BusinessService} from "../../../../services/BusinessService";
import {CartModel} from "../../../../../framework/model/core/items/cartModel";
import {CartService} from "../../../../services/CartService";

@Component({
    selector: 'app-agent-item-toolbar',
    templateUrl: './agent-item-toolbar.component.html',
    styleUrls: ['./agent-item-toolbar.component.scss'],
})
export class AgentItemToolbarComponent implements OnInit {

    @Input()
    public data: object;


    public keyboardRes: object = {
        step: 'amount',
        keys: ['amount', 'price', 'discount', 'coupon_amount'],
        'keypressed': false,
        'dotpressed': false,
        'dotexist': false,
        'comments': '',
        'loading': false,

        'discount_data': {
            'addTxt': '%',
        },
    };
    public coupons;
    public cartData;
    public model;
    public cartModel:CartModel;

    public agentToolData;

    public sub_prefix: string = 'sub_' + Math.floor(Math.random() * Math.floor(1000000));

    constructor(public events: Events, private actionSheetController: ActionSheetController,
                public modelTools: ModelToolsProvider, public appDataService: AppDataService,
                public itemProvider: ItemProvider, public businessService: BusinessService,
                public orderService: OrderService, public cartService:CartService) {
    }

    ngOnInit() {
        this.events.subscribe('showItemToolbar', (params) => {
            let me = this;
            setTimeout(function (){
                me.initData(true);
            })
        }, this.sub_prefix);
        this.initData(true)
    }

    ngOnDestroy(init = false) {
        this.events.unsubscribe('showItemToolbar', this.sub_prefix);
    }

    setStep(field)
    {
        let amount = this.cartModel.getValue('amount');
        if(!amount || amount == '0')
            field = 'amount';

        this.keyboardRes['step']  = field;
        this.data['field'] = field;
        this.cartModel.data['active'] = field;
    }

    initData(init = false) {
        this.cartData = this.cartService.getCartData();
        this.model = this.data['item'];
        this.cartModel = this.data['cart_model'];

        console.log(this.data);

        if(init)
            this.keyboardRes['keypressed'] = false;

        if (this.data['options']['field']) {
            this.setStep(this.data['options']['field'])
        }

        this.modelTools.getKey('agentDiscountsData').then((res) => {
            if(!res) res = [];
            this.keyboardRes['discounts'] = res;
        });
        this.getFieldOptions(this.data['options']['field'])
    }


    initData2(init = false) {
        this.cartData = this.orderService.getCartData();

        let model = this.data['model'];
        this.agentToolData = model['agent-tool'];




        if(!this.model || this.model.data.id != model.data.id){
        //     this.events.subscribe(model.data.id + '_cartItemChanged', (params) => {
        //         // console.log('-------!!!', params)
        //         this.initData();
        //     }, this.sub_prefix);
        //
        //     this.keyboardRes['keypressed'] = false;
        }
        //
        if(this.model && this.model.data.id != model.data.id) {
            // this.events.unsubscribe(this.model.data.id + '_cartItemChanged', this.sub_prefix);
        }

        if(this.model && this.model.data.id == model.data.id) {
            // this.keyboardRes['keypressed'] = this.data['field'] == this.keyboardRes['step'];
        }

        // let is_stay = this.model && this.model.data.id == model.data.id;
        // let is_stay = false;

        this.model = model;
        let cartItem = this.getCartItem();

        // if (cartItem) {
        this.keyboardRes['amount'] = cartItem['amount'] ? cartItem['amount'] + '' : '0';
        this.keyboardRes['price'] = cartItem['price'] ? cartItem['price'] + '' : model.data.price + '';
        this.keyboardRes['comments'] = cartItem['comments'];

        // this.keyboardRes['dotexist'] = this.keyboardRes['price'].includes('.');

        if(init) {
            this.keyboardRes['keypressed'] = false;
        }

        if (this.data['field']) {
            let field = this.data['field'];
            if(!this.keyboardRes['amount'] || this.keyboardRes['amount'] == '0')
                field = 'amount';

            this.keyboardRes['step'] = field;

            this.keyboardRes['step']  = field;
            this.data['field'] = field;
            this.agentToolData['active'] = field;
        }


        if (cartItem['data']) {
            this.keyboardRes['coupon_amount'] = cartItem['data']['coupon_amount'] ? cartItem['data']['coupon_amount'] : '';
            this.keyboardRes['coupon_code'] = cartItem['data']['coupon_code'] ? cartItem['data']['coupon_code'] : '';
            this.keyboardRes['discount'] = cartItem['data']['discount'] ? cartItem['data']['discount'] : '0';
        }

        this.coupons = this.appDataService.getPricesListKey('coupons');
        if(this.coupons.length && !this.keyboardRes['keys'].includes('coupon_code'))
            this.keyboardRes['keys'].push('coupon_code');


        this.modelTools.getKey('agentDiscountsData').then((res) => {
            if(!res) res = [];
            this.keyboardRes['discounts'] = res;
        });
        this.getFieldOptions(this.data['field'])

        // console.log(this.agentToolData['data'][this.data['field']]['options'])

        // this.keyboardRes['keypressed'] = true;
        // } else
        //     this.keyboardRes['price'] = model.data.price + '';
    }


    getCartItem() {
        let indexKey = this.model.data.id;
        return this.orderService.getCartItemByIndex(indexKey);
    }

    addToCart(close=true) {
        let cartItem = this.getCartItem();
        let model = this.model;

        let amount = parseInt(this.keyboardRes['amount']);
        console.log(amount)

        if (!amount)
        {
            if(cartItem['amount'])
                this.itemProvider.removeCartItemFromCart(cartItem)
            return;
        }
        let price = parseFloat(this.keyboardRes['price']);

        if(!price)
        {
            cartItem['price'] = price;
            this.agentToolData['data']['price']['value'] = price;
            return;
        }

        let index = cartItem['index'] ? cartItem['index'] : model.data.id;
        // // if(this.cartItem['amount'])
        // //   this.orderService.removeFromCart(null, this.cartItem['product'].data.id);
        // //console.logthis.cartItem)
        let me = this;
        // setTimeout(function () {

        // console.log('----', cartItem, model.data.id)

        let data = cartItem['data'];
        data['coupon_amount'] = this.keyboardRes['coupon_amount'];
        data['coupon_code'] = this.keyboardRes['coupon_code'];
        data['discount'] = parseFloat(this.keyboardRes['discount']);

        data['price_error'] = price < model.data.basePrice;

        // this.agentToolData['loading'] = true;
        // if(close && data['discount']){
        //     let key = 'agentDiscountsData';
        //     this.modelTools.getKey(key).then((res:Array<number>) => {
        //         if(!res) res = [];
        //         if(!res.includes(data['discount']))
        //         {
        //             res.push(data['discount'])
        //         }
        //         this.modelTools.setKey(key, res.slice(-1));
        //     });
        // }

        // console.log("SAVE", data)

        // me.orderService.addToBasket(null, me.model, amount, null, null, price, price, index, false, me.keyboardRes['comments'], me.cartItem['props']);

        this.data['cartItem'] = this.itemProvider.addToCart(model, amount, false, false, false, cartItem['props'],
            null, null, price, false, index, false, price, 0, data, {'agentToolbar': true, 'sub_prefix':this.sub_prefix});

        if(close) this.closeItem();
        // })
    }

    getFieldOptions(field) {
        if (!field || field != 'discount') return;
        let key = 'agentDiscountsData';
        let discount_options = this.cartModel.getValue('discount' ,'options')
        console.log(discount_options);
        this.modelTools.getKey(key).then((res: Array<number>) => {
            console.log(key, res);
            if (!res) return;
            discount_options.length = 0;

            for (let k of res) {
                discount_options.push(k)
            }
        });
    }

    hideAgentToolbar()
    {
        this.itemProvider.showItemToolbar(this.data['model'], this.data['cartItem']['index'], null, false);
    }

    removeFromCart() {
        this.itemProvider.removeCartItemFromCart(this.data['cartItem']);
        this.closeItem();
    }


    keyPressed(key, runOver = false, button = null) {
        if (this.keyboardRes['loading']) return;

        let stepKey = this.keyboardRes['step'];


        if(button && button['callback'])
        {
            switch (button['callback']) {
                case 'nextstep':
                    this.nextstep();
                    break;
                case 'backspace':
                    this.backspace(this.keyboardRes)
                    break;
            }
            // if(!['price'].includes(this.keyboardRes['step']))
            //     this.setValues();
            if(!button['skipValue'])
                this.setFieldValue(this.keyboardRes[stepKey])
            return;
        }
        key = key + '';

        // let value = this.keyboardRes[stepKey];

        // console.log(this.data['field']);
        let value = this.cartModel.getValue(this.data['field']);

        if (key == '+' || key == '-') {
            key = key + 1;

            let inputres = parseFloat(value);
            inputres += parseFloat(key);
            this.keyboardRes['keypressed'] = true;

            if (inputres < 0)
                inputres = 0;

            value = inputres + '';
        }
        else {

            if (this.keyboardRes['keypressed']) {
                // if (this.keyboardRes['dotpressed'] && !this.keyboardRes['dotexist']) {
                //     key = '.' + key;
                //     this.keyboardRes['dotpressed'] = false;
                // }

                // if (key == '.') {
                //     this.keyboardRes['dotpressed'] = true;
                // } else
                if (runOver) {
                    value = key;
                } else {
                    value = value += key;

                    // this.keyboardRes['dotexist'] = this.keyboardRes['dotpressed'] = value.includes('.');
                }

            } else {
                // if (key == '0') return;
                value = key;
                this.keyboardRes['keypressed'] = true;
            }


            if(value == '.')
                value = '0.'

        }

        this.keyboardRes['keypressed'] = value != '0';
        this.keyboardRes[stepKey] = value;

        // this.agentToolData['loading'] = true;
        // this.setValues();
        this.setFieldValue(this.keyboardRes[stepKey], key != '.')
    }

    setFieldValue(value, publish = true)
    {
        console.log(this.data['field'], value);
        this.itemProvider.add_to_cart(this.model, value, this.data['field'], {'add_to_value':false});

        if(this.data['field'] == 'discount')
        {
            let key = 'agentDiscountsData'
            this.modelTools.getKey(key).then((res: Array<number>) => {
                console.log(this.cartModel.data['data'][this.data['field']]['edit_prefix'])
                let data = {'value':value, 'prefix':this.cartModel.data['data'][this.data['field']]['edit_prefix']}
                if (res && res.length && res[0]['prefix'] != data['prefix']) return;
                this.modelTools.setKey(key, [data]);
            });
        }

        // this.agentToolData['data'][this.data['field']]['value'] = value;
        // if(publish)
        //     this.events.publish(this.model.data.id+'_agentToolbarFieldChanged', {'field':this.data['field']});
    }

    removeOption(fiedData, option)
    {
        let options:Array<any> = fiedData['options'];
        const index = options.indexOf(option);
        if (index > -1) {
            options.splice(index, 1);
        }
        this.modelTools.setKey('agentDiscountsData', options);
    }

    setValues(){
        // this.addToCart(false);

        return
        let cartItem = this.data['cartItem'];
        cartItem['amount'] = this.keyboardRes['amount'];
        cartItem['price'] = this.keyboardRes['price'];
        if (cartItem['data']) {
            cartItem['data']['coupon_amount'] = this.keyboardRes['coupon_amount'];
            cartItem['data']['coupon_code'] = this.keyboardRes['coupon_code'];
            cartItem['data']['discount'] = this.keyboardRes['discount'];
        }

    }


    resetInput() {
        let keyboardRes = this.keyboardRes;
        if (keyboardRes['loading']) return;
        keyboardRes[keyboardRes['step']] = '0';
        keyboardRes['keypressed'] = false;
    }

    backspace(keyboardRes) {
        if (keyboardRes['loading']) return;

        let stepKey = keyboardRes['step'];
        // let inputres = keyboardRes[stepKey] + '';
        // inputres = inputres.slice(0, -1);
        // if (inputres == '') inputres = '0';
        let inputres = '0';
        keyboardRes[stepKey] = inputres;

        console.log(stepKey)
        if(stepKey == 'price')
        {
            keyboardRes[stepKey] = this.cartModel.data['data']['corePrice']['value']+'';
            keyboardRes['keypressed'] = false;
        }

        // keyboardRes['dotexist'] = keyboardRes[stepKey].includes('.');
        // if (!keyboardRes['dotexist'])
        //     keyboardRes['dotpressed'] = false;
    }

    closeItem(fromEvent = false) {
        this.events.publish('closeItemToolbar');
    }

    nextstep(){
        if(!this.orderService.isAgent())
        {
            this.closeItem();
            return;
        }
        let fields = this.cartModel.data['editFields'];
        let found = false;
        for(let field of fields){
            if(found)
            {
                this.keyboardRes['keypressed'] = false;
                this.setStep(field);
                this.getFieldOptions(field);
                return
            }
            if(field == this.keyboardRes['step'])
                found = true;
        }
    }

    async viewStaffSettings() {
        let model = this.data['model'];
        let cartItem = this.data['cartItem'];
        let buttons = this.orderService.viewStaffSettings(false, cartItem, model)
        const actionSheet = await this.actionSheetController.create({
            header: this.modelTools.getTranslate('itemSettings'),
            cssClass: 'my-custom-class',
            buttons: buttons
        });
        await actionSheet.present();

        const {role, data} = await actionSheet.onDidDismiss();
        console.log('onDidDismiss resolved with role and data', role, data);
    }

}
