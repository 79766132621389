// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.type-box {
  background: #fff;
  margin: 2px 0;
  padding: 10px;
  background: var(--element-background);
  --background: var(--element-background);
  color: var(--element-color);
  --color: var(--element-color);
}

.type-box.no-padding {
  padding: 0px;
}

.desc-box {
  font-size: 13px;
}

.price-box {
  font-size: 20px;
  text-align: end;
}`, "",{"version":3,"sources":["webpack://./src/core/pages/item/pages/item-deal-props/item-deal-props.page.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,aAAA;EACA,aAAA;EAEA,qCAAA;EACA,uCAAA;EACA,2BAAA;EACA,6BAAA;AAAF;;AAGA;EACE,YAAA;AAAF;;AAGA;EAEE,eAAA;AADF;;AAIA;EACE,eAAA;EACA,eAAA;AADF","sourcesContent":[".type-box {\n  background: #fff;\n  margin: 2px 0;\n  padding: 10px;\n\n  background: var(--element-background);\n  --background: var(--element-background);\n  color: var(--element-color);\n  --color: var(--element-color);\n}\n\n.type-box.no-padding {\n  padding: 0px;\n}\n\n.desc-box {\n  //padding: 10px;\n  font-size: 13px;\n}\n\n.price-box {\n  font-size: 20px;\n  text-align: end;\n\n  //price{\n  //  span.no/*wrap {\n  //    white-space: pre-line;\n  //  }*/\n  //}\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
