// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bar {
  height: 40px;
  text-align: center;
  padding: 8px;
}

.body {
  max-height: 400px;
  padding: 5px;
}`, "",{"version":3,"sources":["webpack://./src/framework/components/core/note/note.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,kBAAA;EACA,YAAA;AACF;;AAEA;EACE,iBAAA;EACA,YAAA;AACF","sourcesContent":[".bar {\n  height: 40px;\n  text-align: center;\n  padding: 8px;\n}\n\n.body {\n  max-height: 400px;\n  padding:5px\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
