import { Component, OnInit, Input } from '@angular/core';
import {PopoverController} from "@ionic/angular";
import {ModelToolsProvider} from "../../../../../framework/providers/model-tools/model-tools";
import {Item} from "../../../../../framework/model/core/items/item";
import {Events} from "../../../../../framework/providers/events";

//Thank you for adding to cart popup

@Component({
  selector: 'app-add-to-cart',
  templateUrl: './add-to-cart.component.html',
  styleUrls: ['./add-to-cart.component.scss'],
})
export class AddToCartComponent implements OnInit {

  @Input()
  public model:Item;

  public models:Array<Item> = [];

  constructor(public modelTools:ModelToolsProvider, public popoverCtrl:PopoverController, public events:Events) {
    this.events.subscribe('addToCartComponentExist', (params)=>{
      this.models.push(params['model']);
    })
  }

  ngOnInit() {
    this.models.push(this.model);
  }

  ngOnDestroy()
  {
    this.events.unsubscribe('addToCartComponentExist');
  }

  goTo(page, params)
  {
    this.modelTools.goTo(page, params);
  }

  close()
  {
    this.popoverCtrl.dismiss();
  }
}
