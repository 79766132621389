import {Component, Input, OnInit} from '@angular/core';
import {OrderService} from "../../../../services/OrderService";
import {AppUser} from "../../../../../framework/model/core/appuser/appuser";

@Component({
  selector: 'profile-image',
  templateUrl: './profile-image.component.html',
  styleUrls: ['./profile-image.component.scss'],
})
export class ProfileImageComponent implements OnInit {

  public appuser:AppUser;

  @Input()
  public showButton:boolean = true;

  @Input()
  public buttonText:string;

  @Input()
  public icon:string;

  constructor(public orderService:OrderService) {
    this.appuser = this.orderService.getAppUser();
    // this.appuser.data.images.length = 0;
  }

  ngOnInit() {}

}
