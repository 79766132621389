import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ModalController} from "@ionic/angular";
import {OrderService} from "../../../../services/OrderService";
import {AgentKeyboardPage} from "../../../agent/agent-keyboard/agent-keyboard.page";
import {TicketsListPage} from "../../pages/tickets-list/tickets-list.page";
import {Business} from "../../../../../framework/model/core/business/business";
import {BusinessService} from "../../../../services/BusinessService";
import {Events} from "../../../../../framework/providers/events";
import {CartService} from "../../../../services/CartService";

@Component({
    selector: 'app-cart-tickets',
    templateUrl: './cart-tickets.component.html',
    styleUrls: ['./cart-tickets.component.scss'],
})
export class CartTicketsComponent implements OnInit {

    public business: Business;
    public cart;
    public cartData;
    public options: Array<object> = [];

    public selectedTicket;

    constructor(public events: Events, public orderService: OrderService, public modalController: ModalController,
                public businessService: BusinessService, public cartService:CartService,
                private changeRef: ChangeDetectorRef) {
        this.business = this.businessService.getBusiness();
    }

    ngOnInit() {
        this.setCart();

        this.events.subscribe('cartUpdated', (params) => {
            this.setCart(params);
        })

    }

    setCart(params = {}) {
        this.cart = this.cartService.getCart();
        this.cartData = this.cartService.getCartData();
        this.setOptions(params)

    }

    setOptions(params = {}) {
        this.options.length = 0;
        for (let k in this.cart) {
            this.options.push({
                'data': this.cart[k],
                'ticket': this.cart[k]['ticket'] + '',
                'value': this.cart[k]['name']
            })
        }

        // //console.logthis.options)
        // //console.logthis.cartData)

        this.changeRef.detectChanges();

        // if((!this.cartData['curTicket'] || params['newTicket']) && this.options.length) {
        //   this.orderService.setActiveTicket(this.options[this.options.length - 1]['ticket'])
        // }
    }

    setActiveTicket(ticket) {
        //console.logticket)
        this.cartService.setActiveTicket(ticket);
    }

    newTicket(name) {
        // this.orderService.showTicketsPopup();
        this.cartService.getTicket(name);

        //    setActiveTicket(ticket['ticket']);

    }

    onChange(e) {
        this.setActiveTicket(this.cartData['curTicket']);
        // //console.log'changed!')
        // //console.loge)
        // //console.logthis.cartData['curTicket']);
    }


    async showTickets() {
        if (!this.cartData['tickets']) return;
        const modal = await this.modalController.create({
            component: TicketsListPage,
            componentProps: {}
        });

        return await modal.present();
    }

}
