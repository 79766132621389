import {Component, OnInit} from '@angular/core';

export class SaleBonus {
    data = {
        id: null,
       };

    constructor(public data1: any) {
        this.data = data1;
    }

    //shortHeaders = [];
    prefix = 'saleBonus';
    title = "saleBonus";
    dataFields = {
        id: {'title': 'ID', 'value': null, 'type': 'number', 'readonly': true},
        created: {'title': 'Created', 'value': null, 'type': 'date', 'readonly': true},
        updated: {'title': 'Updated', 'value': null, 'type': 'date', 'readonly': true},
    }
}
