import {Injectable} from '@angular/core';
import {Storage} from "@ionic/storage";

import {ModelInterface, ModelProvider} from "../../framework/providers/modelProvider/ModelProvider";
import {AppConfig} from "../conf/main";
import {BusinessImages} from "../../framework/model/core/business/businessImages";
import {Business} from "../../framework/model/core/business/business";
import {ActivatedRoute} from "@angular/router";
import {Template} from "../../framework/model/core/template/template";
import {GlobalSettings} from "../../framework/model/core/business/globalSettings";
import {AuthService} from "../../framework/providers/auth-service/auth-service";
import {AppDataService} from "./AppDataService";
import {AppUser} from "../../framework/model/core/appuser/appuser";
import {AppSettings} from "../conf/appSettings";
import {ModelToolsProvider} from "../../framework/providers/model-tools/model-tools";
import {Events} from "../../framework/providers/events";

@Injectable()
export class BusinessService {
    public business: Business;
    public appuser: AppUser;
    public businessImages: BusinessImages;
    public template: Template;
    public globalsettings: GlobalSettings;
    public orderTypes;
    public appData;
    public staticPrefix: string;

    //width, heigt
    public screenSize = [0, 0];

    public oneflowData = {
        'prefix': null,
        'active': false,
    }

    adminData = {};

    constructor(private modelProvider: ModelProvider, public route: ActivatedRoute, public appDataService: AppDataService,
                public events: Events, public storage: Storage, public authService: AuthService,
                public appSettings: AppSettings,
                public modelTools: ModelToolsProvider) {
    }

    start() {
        var prefix = AppConfig.bisPrefix;
        AppConfig.corePrefix = prefix;
        // console.log('prefix!!', prefix, 'corePrefix-', AppConfig.corePrefix)

        // if (prefix) {
        //     this.setBisByPrefix(prefix);
        //     return;
        // }
        //
        // this.route.queryParams.subscribe(params => {
        //     prefix = params['pref']
        //     if (prefix) {
        //         this.setBisByPrefix(prefix);
        //     }
        // });

        ////console.log'this.window.location.hostname');
        ////console.logwindow.location.host);
        let parts = window.location.host.split('.');
        let hostname = parts[0];
        if (hostname == 'www')
            hostname = parts[1];
        ////console.loghostname);

        if (prefix) {
            this.setBisByPrefix(prefix);
        } else if (hostname != '' && hostname != 'shaps' && hostname != 'localhost:8100' && hostname != 'test') {
            this.setBisByPrefix(hostname);
        } else {
            // ////console.log"NO PREFIX")
        }

        this.route.queryParams.subscribe(params => {
            params = getUrlParams()
            // console.log('PP', params)
            //
            prefix = params['pref'];
            if (prefix) {
                this.setBisByPrefix(prefix);
            }
        });

        // this.events.subscribe('appuserChanged', (params) => {
        //     this.appuser = params['appuser'];
        // });

        this.events.subscribe('appPriceSets', (params) => {
            let settings = this.appDataService.getPricesListKey('settings')
            if(settings)
            {
                this.appSettings.setAppDataSettings(settings, this.getGlobalSettings())
            }
        })

        this.events.subscribe('businessAppDataSets', (params) => {
            let dataimages = this.appDataService.getModelsByKey('business-images', 'businessImages', {});
            if (!dataimages)
                this.listImages();
            this.setImages(dataimages['models'][0]);

            let datatemplate = this.appDataService.getModelsByKey('template', 'template', {});

            if (!datatemplate)
                this.listColors();

            let me = this;

            setTimeout(function () {
                me.setColors(datatemplate['models'][0]);
            }, 0)

            let app_settings = this.appDataService.getDataByKey('settings');
            this.appSettings.setAppDataSettings(app_settings, this.getGlobalSettings())
        })

    }

    // public setInitBisByPrefix(prefix) {
    //     this.staticPrefix = prefix;
    //     this.getBis({'pref': prefix}).then((res: ModelInterface) => {
    //         if (!res.models.length) return;
    //         this.setBis(res.models[0])
    //     })
    // }

    public setBisByPrefix(prefix, setAuth = true, skipOneFlow = false, cache = true) {
        // console.log('setBisByPrefix', prefix, setAuth, skipOneFlow)
        // console.log('prefix==\'oneflow\' && !skipOneFlow', prefix == 'oneflow', skipOneFlow)
        // //console.logprefix, setAuth, skipOneFlow)
        // //console.log'prefix==\'oneflow\' && !skipOneFlow', prefix == 'oneflow', skipOneFlow)

        if (!skipOneFlow) {
            this.modelTools.getKey('bisPrefix').then((res) => {
                //console.log("BIS PREF STI", res)
                if (res)
                    this.setBisByPrefix(res, true, true);
                else
                    this.setBisByPrefix(prefix, true, true);
            });
            return
        }

        AppConfig.bisPrefix = prefix;
        if (!this.staticPrefix)
            this.staticPrefix = prefix;

        this.getBis({'pref': prefix}, cache);

        if (setAuth)
            this.authService.setBisPrefix(prefix);
    }

    public setBis(bis) {
        //console.log("BUSINESS SETSS", bis)
        this.business = bis;

        this.globalsettings = new GlobalSettings(bis.data.GlobalSettingsData);

        AppConfig.bisPrefix = bis.data.prefix;

        if (!this.oneflowData['active'] && !AppConfig.mode) {
            AppConfig.mode = this.globalsettings.data.bisType;

            this.oneflowData['active'] = AppConfig.mode == 'oneflow';
            this.oneflowData['prefix'] = AppConfig.bisPrefix;
        }

        this.appSettings.setBusinessDetails(this.business);
        this.events.publish('businessSets', {'data': this.business})

        this.modelTools.setKey('bisPrefix', bis.data.prefix);
        // this.listImages();
        // this.listColors();
        // this.checkAppUser();
        // this.appDataService.setAppData();
    }

    public getOrderTypes() {
        return this.orderTypes;
    }

    // public setUserBusiness() {
    //     AppConfig.bisPrefix = null;
    //     this.getBis().then((res) => {
    //         this.setBis(res.models[0])
    //     });
    // }

    public checkAppVersion(version) {
        // console.log("CHECK DATA VERSION!", this.business.data.GlobalSettingsData['dataVersion'], '->', version)
        if (version != this.business.data.GlobalSettingsData['dataVersion']) {
            this.getBis({'pref': AppConfig.bisPrefix});
            this.events.publish('getDataPrices', {'skipStorage': true});
        }
    }

    getBis(filters = {}, cache = true) {
        let storageKey = 'BUSINESS_' + JSON.stringify(filters);
        let timeout = 0;

        // if(filters['prefix'] == 'oneflow')

        this.modelTools.getKey(storageKey).then((res) => {
            //console.log"BUSINESS STORGE ", res)
            if (res && cache) {
                this.setBis(res['models'][0]);
                timeout = 4000;
            }

            let me = this;
            setTimeout(function () {
                me.modelProvider.list('business', filters).then((res: ModelInterface) => {
                    if (!res.models || !res.models.length) return;
                    let prefix = res.models[0].data.prefix
                    if (prefix != AppConfig.bisPrefix) return;
                    me.setBis(res.models[0]);
                    me.modelTools.setKey(storageKey, res)
                });
            }, timeout)
        });
    }

    // getBis(filters = {}): Promise<ModelInterface> {
    //     return new Promise((resolve, reject) => {
    //         this.modelProvider.list('business', filters).then((res: ModelInterface) => {
    //             if (res) {
    //                 resolve(res);
    //             }
    //         });
    //     });
    // }

    listImages() {
        this.businessImages = null;
        this.modelProvider.list('businessImages', {'business': this.business.data.id}).then((res) => {
            this.setImages(res['models'][0])
        });
    }

    setImages(model) {
        this.businessImages = model;
        this.events.publish('businessImagesSets', {'data': this.businessImages})
    }

    public getBusiness() {
        return this.business
    }

    public getGlobalSettings() {
        return this.globalsettings
    }

    public removeBusiness() {
        // if (this.staticPrefix)
        //     return;
        AppConfig.bisPrefix = this.staticPrefix;
        this.modelTools.setKey('bisPrefix', null);
        // this.business = null;
    }

    public getImages() {
        return this.businessImages
    }

    public checkAppUser() {
        let p = {}, me = this;
        this.events.publish('getAppUser', p); // get appuser from order service;
        setTimeout(function () {
            me.appuser = p['appuser'];
            me.setCatalog()
        })

        this.events.subscribe('appuserChanged', (res) => {
            this.appuser = res['appuser'];
            this.setCatalog()

        })
    }

    public setCatalog() {
        let approved = this.appuser ? this.appuser.data.companyPriceApproved : false;
        if (!this.business.data.GlobalSettingsData.pricesVisible && !approved) {
            this.business.data.GlobalSettingsData.iscatalog = true;
        } else {
            this.business.data.GlobalSettingsData.iscatalog = false;
        }

        this.events.publish('catalogChanged', {'catalog': this.business.data.GlobalSettingsData.iscatalog})
    }

    public isCatalog() {
        return this.business.data.GlobalSettingsData.iscatalog;
    }

    listColors() {
        this.modelProvider.list('template', {'id': this.globalsettings.data.template}).then((res) => {
            this.setColors(res['models'][0])
        });
    }

    setColors(model) {
        //console.log"COLORSS !!", model)
        if (!model) {
            this.events.publish('businessColorsSets', {});
            return;
        }

        this.template = model;

        let colors = this.template.data.colors;

        const el = document.querySelector(':root');
        for (var k in colors) {

            let toValue = colors[k]['toColor']
            if (!['ion-font-family'].includes(colors[k]['name'])) // is color;
                toValue = '#' + toValue

            if (colors[k].siteColor) {
                // //console.logcolors[k]['name'], colors[k]['toColor'])
                el['style'].setProperty('--' + colors[k]['name'], toValue);
            }
        }

        this.events.publish('businessColorsSets', {})
    }

    isScreenSmall() {
        return (this.screenSize[0] < 768);
    }
    isScreenXLarge() {
        return (this.screenSize[0] > 1768);
    }

    setScreenSize(width, height) {
        this.screenSize = [width, height];
    }

    getScreenSize() {
        return this.screenSize;
    }

    setAppData() {
        // this.modelProvider.custom('getAppData', {'setPrefix': true}).then((res) => {
        //     this.appData = res;
        // });

    }

    getAppData(sectionId) {
        if (!this.appData[sectionId]) return {};
        this.appData[sectionId]['sectionsarr'] = new Array(this.appData[sectionId]['sections']);
        this.appData[sectionId]['itemsarr'] = new Array(this.appData[sectionId]['items']);
        return this.appData[sectionId];
    }

    getOneFlowData() {
        return this.oneflowData;
    }

    private parseQueryString() {

        var str = window.location.search;
        var objURL = {};

        // str.replace(
        //     new RegExp("([^?=&]+)(=([^&]*))?", "g"),
        //     function ($0, $1, $2, $3) {
        //         objURL[$1] = $3;
        //     }
        // );
        return objURL;
    }

    getAdminData() {
        return this.adminData;
    }

    setAdminData(data) {
        this.adminData = data;
    }
}

function getUrlParams() {
    let urlParams = window.location.search.substr(1);
    let res = {};
    let parts = urlParams.split('&');
    for (let k in parts) {
        if (parts[k] == '') continue;
        let part = parts[k].split('=');
        res[part[0]] = part[1]
    }

    return res
}

