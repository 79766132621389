// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style-img {
  width: 100%;
  height: 40px;
}

.maker-box {
  height: 130px;
  max-height: 130px;
}

.box {
  height: 100%;
  position: relative;
  margin: auto;
}
.box ion-card-subtitle {
  font-size: 18px;
}

.title-box {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/core/pages/makers/makers.page.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;AACF;;AAEA;EAEE,aAAA;EACA,iBAAA;AAAF;;AAGA;EACE,YAAA;EACA,kBAAA;EACA,YAAA;AAAF;AAEE;EACE,eAAA;AAAJ;;AAsBA;EACE,kBAAA;EACA,SAAA;EACA,WAAA;EACA,kBAAA;AAnBF","sourcesContent":[".style-img {\n  width: 100%;\n  height: 40px;\n}\n\n.maker-box\n{\n  height: 130px;\n  max-height: 130px;\n}\n\n.box {\n  height: 100%;\n  position: relative;\n  margin: auto;\n\n  ion-card-subtitle{\n    font-size: 18px;\n  }\n}\n\n//.title-box {\n//  position: absolute;\n//  bottom: 0px;\n//  z-index: 7;\n//  background: var(--button-a-background);\n//  width: 100%;\n//  opacity: 0.8;\n//  font-size: 17px;\n//  line-height: 1;\n//  padding: 3px;\n//  height: 20px;\n//  font-weight: 700;\n//  text-align: center;\n//  color: var(--button-a-text);\n//\n//  display: table;\n//}\n\n.title-box {\n  position: absolute;\n  bottom: 0;\n  width: 100%;\n  text-align: center;\n\n  span {\n    //vertical-align: middle;\n    //display: table-cell;\n    //width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
