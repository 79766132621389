import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {AppDataService} from "../../../../services/AppDataService";
import {OrderService} from "../../../../services/OrderService";
import {OrderItem} from "../../../../../framework/model/core/order/orderItem";
import {ItemProvider} from "../../../item/providers/item-provider/item-provider.service";
import {ModelToolsProvider} from "../../../../../framework/providers/model-tools/model-tools";

@Component({
  selector: 'order-handle',
  templateUrl: './order-handle.page.html',
  styleUrls: ['./order-handle.page.scss'],
})
export class OrderHandlePage implements OnInit {

  public itemsObj:object = {};
  public ready:boolean = false;
  public action:string;

  constructor(public route: ActivatedRoute, public appDataService:AppDataService, public orderService:OrderService,
              public itemProvider:ItemProvider, public modelTools:ModelToolsProvider) {
      this.route.queryParams.subscribe(params => {
          this.action = params['action'];

          this.setItems(params['items']);

      });
  }

  ngOnInit() {
  }

  setItems(data)
  {
    // //console.log'DATA', data)
    for(let k in data)
    {
      if(!this.itemsObj[data[k].name])
        this.itemsObj[data[k].name] = {'name':data[k].name, 'items':[]}

      // //console.logdata[k].name)
      for(let i in data[k]['items'])
      {
        let item = this.copy(data[k]['items'][i]);
        for(let c=0; c<item.data.amount; c++)
        {
          // //console.logc, item.data.id)
          let citem = this.copy(item);
          citem.data.counter = c+1;
          this.itemsObj[k]['items'].push(citem);
        }
      }
    }

    this.ready = true;

    // //console.logthis.itemsObj);
  }

  addToCart(orderItem:OrderItem)
  {
    let item = this.appDataService.getItemModel(orderItem.data.itemId);
    let props = this.itemProvider.getOrderItemProps(orderItem);

    this.orderService.newTicket(orderItem.data.ticket);
    this.itemProvider.addToCart(item, 1, false, false, true, props, null, null, orderItem.data.unitPrice);
    orderItem.data['picked'] = true;
  }

  proceed()
  {
    this.modelTools.goTo('cart');
  }

  copy(data) {
      return JSON.parse(JSON.stringify(data))
  }

}
