declare var SERVER_PATH;
declare var SERVER_PORT;
declare var APP_PREFIX;
declare var CDN_URL;
declare var CDN_URL_DOMAIN;
declare var CDN_URL_DOMAIN_PREFIX;
declare var HTTP_TYPE;
declare var BUSINESS_CONF;
declare var DIR;
declare var USE_CDN;
declare var GoogleMapsApi;
declare var MENU_VISIBLE;
declare var AVOID_THUMBNAILS;

var BUILD_VER = '12.24'

export const AppConfig = {

    // dataServerIp: 'onebutton.co.il/oneadmin0.7',
    // http: 'https://',
    // assets: 'https://onebutton.co.il/oneapp/assets/',
    assets: 'assets/',
    // httpServerIp: 'https://onebutton.co.il/oneadmin0.7',
    // startSearchIp: 'https://onebutton.co.il/oneadmin0.7',

    // dataServerIp: '192.168.1.18:220',
    // http: 'http://',
    // assets: 'assets/',
    // httpServerIp: 'http://192.168.1.18:220',
    // startSearchIp: 'http://192.168.1.18:220',

    NONE_IMAGEURL: 'assets/dashboard/none_img.png',
    CDN_URL_DOMAIN: CDN_URL_DOMAIN,
    CDN_URL_DOMAIN_PREFIX: CDN_URL_DOMAIN_PREFIX,
    CDN_URL: CDN_URL,
    dataServerIp: SERVER_PATH,
    googleMapsApi: GoogleMapsApi,
    http: HTTP_TYPE,

    httpServerIp: HTTP_TYPE + SERVER_PATH,
    startSearchIp: HTTP_TYPE + SERVER_PATH,

    // bisPrefix: null,
    // bisPrefix: 'vib',
    // bisPrefix: 'bconnect',
    // bisPrefix: 'futurecell',
    // bisPrefix: 'moniche',
    // bisPrefix: 'lechemoshe',
    // bisPrefix: 'ishtabach',
    // bisPrefix: 'vib',
    // bisPrefix: 'ido',
    // bisPrefix: 'toyss',
    bisPrefix: APP_PREFIX,
    corePrefix: APP_PREFIX,

    mode: null,
    globalPincode: '0404',

    useAppData: true,
    avoid_thumbnails: AVOID_THUMBNAILS,
    menu_visible: MENU_VISIBLE,
    // cdnData: true,
    cdnData: USE_CDN,
    dir: DIR,

    // useAppData: false,

    // dataServerIp: 'onebutton.co.il/oneadmin0.7',
    // dataServerIp: 'onebutton.co.il/oneadmin0.7',
    // dataServerIp: 'benda.shaps.co.il/oneadminbenda_testing',
    // dataServerIp: '10.0.0.21:9000',
    // httpServerIp: '10.0.0.25:9000',

    appctrl: false,
    ver: '2.5',

    build_ver: BUILD_VER,

    appName: 'Shaps',
    type: 'app',

    defaultLang: 'heb',
    currentLang: 'heb',
    // currentLang: 'movingaid',
    // defaultLang: 'movingaid',
    langs: ['heb', 'en', 'movingaid'],
    ltrLangs: ['en', 'movingaid'],

    tokens: {},

    // dateWeekFormat:'EE dd/MM',
    // dateFormat:'dd/MM/yyyy',
    // dateFormatHtml:'ddMMyyyy',
    dateWeekFormat: 'EE MM/dd',
    dateFormat: 'MM/dd/yyyy',
    dateFormat1: 'MM/dd/yy',
    dateFormat2: 'MM/dd',
    dateFormatServer: 'yyyy-MM-DD',
    datetimeFormatServer: 'yyyy-MM-DDTHH:mm:SS',
    datetimeFormatDisplay: 'yyyy-MM-DD HH:mm',
    dateFormatHtml: 'MMddyyyy',

    dateSlot1:'dd',
    dateSlot2:'MM',
    dateSlot3:'YY',

};

export const hebrewColors = {
    'שחור': '#000000',
    'לבן': '#ffffff',
    'כחול': '#0e398f',
    'ירוק': '#21de3d',
    'ורוד': '#fa44fa',
    'אפור': '#383738',
    'אפור -שחור': '#383738',
    'אפור כהה': '#868686',
    'אפור בהיר': '#b7b7b7',
    'שחור - אדום': '#de1414',
    'כסף': '#ccbecc',
    'אדום': '#e82727',
    'כתום': '#ed5223',
    'צהוב': '#fafa03',
    'סגול': '#ad0cad',
    'שחור -כסף': '#807e80',
    'שחור - זהב': '#cca122',
    'זהב': '#cca122',
    'שחור - אפור': '#6b666b',
    'לבן - אפור': '#f0f0f0',
    'אדום -אפור': '#d61717',
    'אדום - זהב': '#db0d1e',
    'שחור- אפור': '#b5afb5',
    'בורדו': '#7d092a',
    'לימון': '#e6e612',
    'צבעונית': '#cf1b4e',
    'לבן / אפור': '#bab4ba',
    'לבן / כחול': '#2020d6',
    'לבן / ורוד': '#de40de',
    'לבן / צהוב': '#f0e51d',
    'לבן /כחול': '#1922c2',
    'לבן / כתום': '#de8416',
    'טורקיז': '#00a89a',
    'שחור / כסף': '#b3b1b3',
    'שקוף': '#ebebeb',
    'שחור - צהוב': '#cae62e',
    'שחור - כחול': '#2548a1',
    'לבן - אדום': '#e61c1c',
    'שחור -ורוד': '#e615e6',
    'לבן - שחור': '#ffffff',
    'לבן-כסוף': '#e6e6e6',
    'ירוק-כחול': '#005714',
    'ירוק-זית': '#4e993b',
    'כחול ג\'ינס': '#0015ff',
    'ורוד כהה': '#ff00d9',
    'ירוק-שחור': '#60ff47',
    'תכלת': '#42f2ff',
    'צבאי': '#616161',
    'רוז': '#ffa8fc',
    'טרופי': '#a8db00',
    'חום': '#795548'
};

export const actionColors = {
    'shippingReport': '#ad0cad',
    'bid': '#000000',
    'pickup': '#7d092a',
    'delivery': '#21de3d',
    'storagePickup': '#cca122',
    'storageDelivery': '#a8db00',

}