import {Injectable} from '@angular/core';
import {Storage} from '@ionic/storage';
import {Observable} from "rxjs"
import {ModelToolsProvider} from "./model-tools/model-tools";
import {Events} from "./events";

export class User {
    puserId: string;
    data = {
        'id': 0,
        'fname': '',
        'phone': '',
        'lname': '',
        'email': '',
        'username': '',
        'token': '',
        'is_superuser': '',
        'is_staff': '',
        'puserId': null
    };

    constructor(data1: any) {
        this.data = data1;
    }
}


@Injectable()
export class UserData {
    public userData: User;
    public _userData: User;

    _favorites: string[] = [];

    USER_DATA = 'userData';
    HAS_LOGGED_IN = 'hasLoggedIn';
    HAS_SEEN_TUTORIAL = 'hasSeenTutorial';

    currentUser: User;

    constructor(public events: Events, public storage: Storage, public modelTools: ModelToolsProvider) {
        this.storage = new Storage({});
        this.events = new Events();
        this._userData = new User({});
    }

    hasFavorite(sessionName: string): boolean {
        return (this._favorites.indexOf(sessionName) > -1);
    };

    addFavorite(sessionName: string): void {
        this._favorites.push(sessionName);
    };

    removeFavorite(sessionName: string): void {
        let index = this._favorites.indexOf(sessionName);
        if (index > -1) {
            this._favorites.splice(index, 1);
        }
    };

    login(userdata: Object): void {
        this.modelTools.setKey(this.HAS_LOGGED_IN, true, false);
        this.setUserData(userdata);
    };

    signup(username: string): void {
        this.modelTools.setKey(this.HAS_LOGGED_IN, true);
        this.setUsername(username);
        this.events.publish('user:signup');
    };

    async logout(){
        await this.storage.create();
        this.storage.remove(this.USER_DATA);
        this.storage.remove(this.HAS_LOGGED_IN);

        this.modelTools.setKey(this.USER_DATA, null);
        this.modelTools.setKey(this.HAS_LOGGED_IN, true);
        // this.storage.remove(this.USER_DATA);
        // this.storage.remove(this.HAS_LOGGED_IN);

        // this.storage.remove('userdata');

        this._userData = new User({});

        this.events.publish('user:logout');

        //console.log'INFO: Log out user!!!');
    };

    setUsername(userData: Object): void {

    }

    setUserData(userData: Object): void {
        ////console.log'**set user data!~!!!')
        ////console.loguserData);

        // observable execution
        //this._userData.next();
        //this._userData.complete();

        //this._userData.next(new User(userData));
        this._userData = new User(userData);

        this.modelTools.setKey(this.USER_DATA, JSON.stringify(this._userData.data), false);
    };

    getUserdata(): Observable<User> {
        ////console.log'get user data!!!');
        ////console.logthis._userData);

        if (this._userData.data && this._userData.data.token) {
            // //console.log'we have USER DATA!!!!!1');
            return new Observable((observer) => {
                observer.next(this._userData);
                observer.complete();
            });
        }

        var me = this;
        return new Observable((observer) => {
            this.hasLoggedIn().then((hasLoggedIn) => {
                if (hasLoggedIn)
                    me.modelTools.getKey(this.USER_DATA, false).then((userData: string) => {

                        if (userData && userData != null && userData != 'null') {
                            me.setUserData(JSON.parse(userData));
                            me.modelTools.setKey(this.HAS_LOGGED_IN, true, false);
                        }
                        observer.next(this._userData);
                        observer.complete();

                    });
                else {
                    observer.next(null);
                    observer.complete();
                }
            });
        });


        //return this.modelTools.getKey('userdata').then((value) => {
        //    return JSON.parse(value).data;
        //});
    };

    // hasLoggedIn(): Promise<boolean> {
    //     ////console.log'is loged in?!123');
    //     return this.modelTools.getKey(this.HAS_LOGGED_IN).then((value) => {
    //         ////console.log'is loged in?!');
    //         ////console.logvalue)
    //         return value === true;
    //     });
    // };

    // checkHasSeenTutorial(): Promise<string> {
    //     return this.modelTools.getKey(this.HAS_SEEN_TUTORIAL).then((value) => {
    //         return value;
    //     });
    // };
    //
    // //     this.userData = new User({});
    // // }
    //
    // hasFavorite(sessionName: string): boolean {
    //     return (this._favorites.indexOf(sessionName) > -1);
    // };
    //
    // addFavorite(sessionName: string): void {
    //     this._favorites.push(sessionName);
    // };
    //
    // removeFavorite(sessionName: string): void {
    //     let index = this._favorites.indexOf(sessionName);
    //     if (index > -1) {
    //         this._favorites.splice(index, 1);
    //     }
    // };

    // login(userdata: Object): void {
    //     this.modelTools.setKey(this.HAS_LOGGED_IN, true);
    //
    //     this.setUserData(userdata);
    // };

    // signup(username: string): void {
    //     this.modelTools.setKey(this.HAS_LOGGED_IN, true);
    //     this.setUsername(username);
    //     this.events.publish('user:signup');
    // };
    //
    // logout(): void {
    //     this.storage.remove(this.HAS_LOGGED_IN);
    //
    //     this.storage.remove('userdata');
    //
    //     if(this.userData)
    //         this.userData.data = {};
    //
    //     this.events.publish('user:logout');
    // };
    //
    // setUsername(userData: Object): void {
    //
    // }
    //
    // setUserData(userData: Object): void {
    //     // //console.log'**set user data!~!!!')
    //     // //console.loguserData);
    //
    //     // observable execution
    //     //this.userData.next();
    //     //this.userData.complete();
    //
    //     //this.userData.next(new User(userData));
    //     this.userData = new User(userData);
    //
    //     this.modelTools.setKey(this.USER_DATA, JSON.stringify(this.userData.data));
    // };
    //
    // getUserdata(): Observable<User> {
    //     ////console.log'get user data!!!');
    //     ////console.logthis.userData);
    //     return new Observable((observer) => {
    //             observer.next(this.userData);
    //             observer.complete();
    //         });
    //
    //     if (this.userData.data.token) {
    //         ////console.log'we have it!1');
    //         return new Observable((observer) => {
    //             observer.next(this.userData);
    //             observer.complete();
    //         });
    //     }
    //
    //     var me = this;
    //     return new Observable((observer) => {
    //         this.hasLoggedIn().then((hasLoggedIn) => {
    //             if (hasLoggedIn)
    //                 me.modelTools.getKey(this.USER_DATA).then((userData) => {
    //                     me.setUserData(JSON.parse(userData));
    //                     observer.next(this.userData);
    //                     observer.complete();
    //
    //                 });
    //             else {
    //                 observer.next(null);
    //                 observer.complete();
    //             }
    //         });
    //     });
    //
    //
    //     //return this.modelTools.getKey('userdata').then((value) => {
    //     //    return JSON.parse(value).data;
    //     //});
    // };

    hasLoggedIn(): Promise<boolean> {
        ////console.log'is loged in?!123');
        return this.modelTools.getKey(this.HAS_LOGGED_IN, false).then((value) => {
            ////console.log'is loged in?!');
            ////console.logvalue)
            return value === true;
        });
    };

    //
    // checkHasSeenTutorial(): Promise<string> {
    //     return this.modelTools.getKey(this.HAS_SEEN_TUTORIAL).then((value) => {
    //         return value;
    //     });
    // };
}
