// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title-box {
  font-size: 18px;
  padding: 5px;
}

.text-title {
  font-size: 22px;
  font-weight: bold;
  padding: 5px;
}

.sudtitle {
  padding: 0 5px;
}

ion-item {
  font-size: 14px;
}

.flex-row.space-between * {
  flex: 1 1;
}`, "",{"version":3,"sources":["webpack://./src/core/pages/client-invoice/client-invoice.page.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,YAAA;AACF;;AAEA;EACE,eAAA;EACA,iBAAA;EACA,YAAA;AACF;;AAEA;EACE,cAAA;AACF;;AAEA;EACE,eAAA;AACF;;AAEA;EACI,SAAA;AACJ","sourcesContent":[".title-box{\n  font-size: 18px;\n  padding: 5px;\n}\n\n.text-title{\n  font-size: 22px;\n  font-weight: bold;\n  padding: 5px;\n}\n\n.sudtitle{\n  padding: 0 5px;\n}\n\nion-item{\n  font-size: 14px;\n}\n\n.flex-row.space-between * {\n    flex: 1 1;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
