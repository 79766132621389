
export class OrderDetails {
    data = {
        'directOrder':null,
    };

    constructor(public data1: any) {
        this.data = data1;
    }

    //shortHeaders = [];
    prefix = "orderDetails";
    title = "OrderDetails";
    dataFields = {
        id: {'title': 'ID', 'value': null, 'type': 'number', 'readonly': true},
        created: {'title': 'Created', 'value': null, 'type': 'date', 'readonly': true},
        pickupDate: {'title': 'pickupDate', 'value': null, 'type': 'datetime'},
        pickupStorageDate: {'title': 'pickupStorageDate', 'value': null, 'type': 'datetime'},
        flexibleDays: {'title': 'flexibleDays', 'value': null, 'type': 'number'},
        directOrder: {'title': 'directOrder', 'value': null, 'type': 'boolean'},
        needStorage: {'title': 'storage', 'value': null, 'type': 'boolean'},
        needInsurance: {'title': 'insurance', 'value': null, 'type': 'boolean'},
        insurance: {
            'title': 'insuranceType',
            'showDefault': true, 'value': null,
            'displayField': 'insurance', 'type': 'optionsscroll', 'optionsscroll': 'INSURANCE_TYPES',
        },

        insuranceValue: {'title': 'Value', 'value': null, 'type': 'text'},
        insuranceCompanyName: {'title': 'insuranceCompanyName', 'value': null, 'type': 'text'},
        insuranceCompanyFile: {'title': 'insuranceCompanyFile', 'value': null, 'type': 'text'},
    }
}
