import { Component, OnInit } from '@angular/core';

export class PuserType {
	data = {};

	constructor(public data1: any) {
		this.data = data1;
	}
	// ["fname", "lname", "username", "password", "is_staff", "is_superuser", "email", "business", "puserType"]
	prefix = "puserType";
	dataFields = {

		"id": { 'title': 'ID', 'value': null, 'type': 'number', 'readonly': true },
		"created": { 'value': null, 'type': 'date', 'readonly': true },
		"updated": { 'value': null, 'type': 'date', 'readonly': true },
		"name": { 'value': null, 'type': 'text', 'required': true },

		"puserType": { 'type': 'oneToMany', 'class': 'Puser', 'foreignKey': 'puserType' },
	}
}
