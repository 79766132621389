import {Component, Input, OnInit} from '@angular/core';
import {ModelToolsProvider} from "../../../../../framework/providers/model-tools/model-tools";

@Component({
  selector: 'app-model-list',
  templateUrl: './model-list.page.html',
  styleUrls: ['./model-list.page.scss'],
})
export class ModelListPage implements OnInit {

  @Input()
  public fieldKey:any;
  @Input()
  public fieldData:object;

  @Input()
  public form:any;

  public models:Array<any> = [];

  public prefix;

  constructor(public tools:ModelToolsProvider) { }

  ngOnInit() {
    this.prefix = this.tools.getRandomize();
  }

  clicked(model)
  {
    this.fieldData['display_value'] = model.data[this.fieldData['display_field']]

    if(this.form){
      this.form.controls[this.fieldKey].setValue(model.data[this.fieldData['fieldKey']]);
      this.form.controls[this.fieldKey].updateValueAndValidity();

      console.log('this.fieldKey', this.fieldKey, this.form.controls[this.fieldKey])
      console.log(this.form)
    }
  }

  close()
  {
    this.tools.closeModal();
  }
}
