import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {IonSlides, ModalController, NavController, PopoverController} from "@ionic/angular";
import {MainService} from "../../../../services/MainService";
import {OrderService} from "../../../../services/OrderService";
import {ModelProvider} from "../../../../../framework/providers/modelProvider/ModelProvider";
import {ActivatedRoute} from "@angular/router";
import {Address} from "../../../../../framework/model/core/address/address";
import {AppUser} from "../../../../../framework/model/core/appuser/appuser";
import {Order} from "../../../../../framework/model/core/order/order";
import {AgentKeyboardPage} from "../../../agent/agent-keyboard/agent-keyboard.page";
import {NewAddressPage} from "./pages/new-address/new-address.page";
import {Business} from "../../../../../framework/model/core/business/business";
import {BusinessService} from "../../../../services/BusinessService";
import {Events} from "../../../../../framework/providers/events";

@Component({
  selector: 'order-address',
  templateUrl: './order-address.component.html',
  styleUrls: ['./order-address.component.scss'],
})
export class OrderAddressComponent implements OnInit {

    order: Order;
    // address: Address;
    appuser: AppUser;

    addresses: Array<Address> = [];
    addressPrefix: string = 'addressPrefix'+ Math.floor(Math.random() * Math.floor(1000000));;
    choosedAddress: Address;
    choosedAddressId: any = -1;

    business:Business;
    options: object = {};
    // formOptions: object = {};

    notOnRadius: boolean = false;

    @Input()
    public companyId;

    @Input()
    public disableAdd = false;

    public filters: object;

    // @ViewChild('slides', null) slides: IonSlides;

    constructor(public events: Events, public navCtrl: NavController, public mainService: MainService, public orderService: OrderService,
                public modalController:ModalController, public businessService:BusinessService) {
        //
        // this.route.queryParams.subscribe(params => {
        //     this.order = params['order']
        // });

        this.appuser = this.orderService.getAppUser();
        this.order = this.orderService.getOrder();
        this.business = this.businessService.getBusiness();


        // this.address = new Address({});

        this.events.subscribe('appuserChanged', (res)=>{
            this.appuser = this.orderService.getAppUser();
        });

        this.events.subscribe('agentCompanyChanged', (res)=>{
            this.events.publish(this.addressPrefix+'_updateModels');
        });
    }

    ngOnDestroy()
    {
        // console.log(this.addressPrefix, 'DEST')
        // this.events.unsubscribe('agentCompanyChanged');
    }

    ngOnInit() {
        if(!this.companyId)
        {
            this.companyId = this.appuser.data.companyId;
        }

        this.filters = {'company':this.companyId, 'disableLoading':true};

        console.log(this.business)

        if(this.business.data.GlobalSettingsData.onlyStaffAddressEdit && !this.orderService.isStaffOrAgent())
        {
            this.disableAdd = true;
        }

        this.events.subscribe('restartIntervalStarts', (res) => {
            this.navCtrl.pop();
            this.events.unsubscribe('restartIntervalStarts')
        });

        this.events.subscribe(this.addressPrefix + '_modelUpdated', (res) => {
            var me = this;
            setTimeout(function () {
                var adrs = me.order.data.address;
                if (me.addresses.length == 1) {
                    for (var k in me.addresses) {
                        if (me.addresses[k].data.shippingData['active'] && !adrs['id']) {
                            adrs = me.addresses[k].data;
                            // return;
                        }
                    }
                }
                else
                    adrs = null;



                // if (adrs)
                me.chooseAddress(new Address(adrs ? adrs : {}));
                // else
                // {
                //     me.chooseAddress(null);
                // }
                // me.setFirstStep()
            }, 300)
        })
        // const mexicoCity = new google.maps.LatLng(19.432608, -99.133209.);
        // this.setFirstStep()

    }

    ngAfterViewInit() {
    }

    setFirstStep() {
        // if (this.choosedAddressId == -1)
        //     this.slideTo(1);
        // this.slides.lockSwipes(true);
    }

    // newAddress() {
    //     this.slideTo(1);
    // }

    async newAddress() {
        let me = this;
        const modal = await this.modalController.create({
            component: NewAddressPage,
            componentProps: {
                'models':this.addresses,
                'callback':function(address) {
                    me.chooseAddress(address)
                }
            }
        });

        return await modal.present();
    }

    chooseAddress(address) {
        // if (!address.data.onRadius) {
        //     this.notOnRadius = true;
        //     return;
        // }
        if(this.disableAdd) return;

        this.notOnRadius = false;
        this.orderService.setAddress(address)
        this.choosedAddress = address;
        this.choosedAddressId = address && address.data.id ? address.data.id : -1;
    }

    add() {
        // if (this.choosedAddressId == -1) {
        //     this.createAddress();
        //     return;
        // }
        //
        // this.order.data.shipmentAddress = this.choosedAddress.data.id;
        // this.order.data.address = this.choosedAddress.data;
        // this.popoverCtrl.dismiss();
        // this.events.publish('addressSets');
    }



    // resetAddress() {
    //     this.address = null;
    //     var me = this;
    //     setTimeout(function () {
    //         me.address = new Address({});
    //     })
    // }

    // slideTo(key) {
    //     // this.slides.lockSwipes(false);
    //     // this.slides.slideTo(key)
    //     // this.slides.lockSwipes(true);
    // }
}
