import {Component, OnInit} from '@angular/core';
import {ModalController, NavController, Platform, PopoverController} from "@ionic/angular";
import {ModelProvider} from "../../../../../framework/providers/modelProvider/ModelProvider";
import {OrderService} from "../../../../services/OrderService";
import {DomSanitizer} from "@angular/platform-browser";
import {TranslateService} from "@ngx-translate/core";
import {ActivatedRoute, NavigationExtras} from "@angular/router";
import {CostumRes} from "../../../../../app/global-module/global-module.module";
import {NewOrderComponent} from "../../../order/components/new-order/new-order.component";
import {ModelToolsProvider} from "../../../../../framework/providers/model-tools/model-tools";
import {BusinessService} from "../../../../services/BusinessService";
import {Business} from "../../../../../framework/model/core/business/business";
import {Events} from "../../../../../framework/providers/events";

@Component({
    selector: 'payment',
    templateUrl: './payment.page.html',
    styleUrls: ['./payment.page.scss'],
})
export class PaymentPage implements OnInit {
    loading: any;

    totalPayLeft = 0;

    public massof: any;
    public key: any;

    public order: any;
    public orderId: any;
    public totalCharge: any;
    public userName: any;

    public params: any;
    public url: any;
    public faild: boolean = false;
    public finish: boolean = false;

    public business: Business;

    public interval;
    public prefix;
    public filters;


    constructor(public navCtrl: NavController,
                public modelProvider: ModelProvider,
                public orderService: OrderService,
                public platform: Platform,
                private sanitizer: DomSanitizer,
                public translate: TranslateService,
                public route: ActivatedRoute,
                public events: Events,
                public modelToolsProvider: ModelToolsProvider,
                public businessService: BusinessService,
                public modalController:ModalController,
                public popoverCtrl: PopoverController) {

        this.business = this.businessService.getBusiness();

        this.route.queryParams.subscribe(params => {
            this.orderId = params['orderId'];
            this.order = params['order'];
            this.params = params['params'];

            if(this.params && this.params['prefix']){
                this.prefix = this.params['prefix'];
            }
            else
                this.prefix = 'orderPaymnet_'+this.orderId;


            // this.userName = params['userName'];
        });

    }




    ngOnInit() {

        this.events.subscribe(this.prefix, (res)=>{
            //console.logres);
            this.finishPayment();
        })


        this.filters = {'setPrefix':true}
        if(this.params) {
            for (let k in this.params) {
                this.filters[k] = this.params[k];
            }
        }
        else{
            this.filters['orderId'] = this.orderId;
        }


        // let cartData = this.orderService.getCartData();
        // this.totalCharge = cartData['totalCharge'];
        // this.massof = cartData['massof'];
        //
        // this.orderId = '260';
        // this.totalCharge = 1;
        // this.userName = 'אדיר';


        // this.massof = this.business.data.GlobalSettingsData.massof;
        //
        // this.massof = this.massof ? this.massof : '0010074926';
        // this.key = this.business.data.GlobalSettingsData.massofKey;
        //
        // this.massof = '0010074926';
        // this.key = 'b6589fc6ab0dc82cf12099d1c2d40ab994e8410c';

        // this.setParams();
        // this.checkCashLoop();
    }

    setParams() {


        // let params =
        //     {
        //         'action': 'APISign',
        //         'What': 'SIGN',
        //         'Sign': 'True',
        //         'BOF': 'True',
        //
        //         'Masof': this.massof,
        //         'KEY': this.key,
        //         'PassP': '123123',
        //         'Info': this.orderId,
        //         'Amount': this.totalCharge,
        //         'UTF8': 'True',
        //         'UTF8out': 'True',
        //         'SendHesh': 'True',
        //         // 'heshDesc': "[demo1~0001~1~98][demo2~0002~2~1]",
        //         'Order': this.orderId + '_shaps',
        //         'ClientName': this.userName,
        //         'tmp': 5,
        //     }

        // let str: string = "";
        // for (let k in params) {
        //     str += k + '=' + params[k];
        //     str += '&';
        // }
        //
        // let url =  str.substring(0, str.length - 1);

        // this.modelProvider.custom('p', params, 'icom.yaad.net', false).then((res)=>{
        //     //console.logres);
        // })

        this.modelProvider.custom('paymentsignature', {'orderId':this.orderId, 'setPrefix':true}).then((res)=>{
            // let sign = res['signature'];
            this.url = this.trustSrc(res['url'])
            //console.log'url', this.url)
        })


        // this.params = 'https://icom.yaad.net/p3/?action=pay&'+'Amount=57&BOF=True&ClientName=adir&Info=2206&Masof=0010074926&Order=2206_shaps&SendHesh=True&Sign=True&UTF8=True&UTF8out=True&action=pay&tmp=5&signature=84bbc20b28ddf1266e8bd1a63f878d965c6a34548e26ae0b263878e6acf477df'

        // this.trustSrc()
    }

    // setParams2() {
    //     this.massof = this.business.data.GlobalSettingsData.massof;
    //
    //     let massof = this.massof ? this.massof : '0010074926';
    //     let key = this.business.data.GlobalSettingsData.massofKey;
    //
    //     //console.log"MASSOF:", massof)
    //     let params =
    //         {
    //             'action': 'pay',
    //             'Sign': 'True',
    //             'BOF': 'True',
    //
    //             'Masof': massof,
    //             'KEY': key,
    //             'Info': this.orderId,
    //             'Amount': this.totalCharge,
    //             'UTF8': 'True',
    //             'UTF8out': 'True',
    //             'PassP': '123123',
    //             'SendHesh': 'True',
    //             // 'heshDesc': "[demo1~0001~1~98][demo2~0002~2~1]",
    //             'Pritim': "False",
    //             'Order': this.orderId + '_shaps',
    //             'ClientName': this.userName,
    //             'tmp': 5,
    //         }
    //
    //     let str: string = "";
    //     for (let k in params) {
    //         str += k + '=' + params[k];
    //         str += '&';
    //     }
    //     str = str.substring(0, str.length - 1);
    //
    //     this.params = 'https://icom.yaad.net/p/?' + str;
    //
    //     // this.params = 'https://icom.yaad.net/p3/?action=pay&'+'Amount=57&BOF=True&ClientName=adir&Info=2206&Masof=0010074926&Order=2206_shaps&SendHesh=True&Sign=True&UTF8=True&UTF8out=True&action=pay&tmp=5&signature=84bbc20b28ddf1266e8bd1a63f878d965c6a34548e26ae0b263878e6acf477df'
    //
    //     this.trustSrc()
    // }

    trustSrc(url) {
        // this.url =
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }


    ngOnDestroy()
    {
        this.events.unsubscribe(this.prefix);
        clearInterval(this.interval)
    }

    finishPayment()
    {
        let navigationExtras: NavigationExtras = {
            queryParams: {
                'orderId': this.orderId,
                'backToRoot': true
            },
            replaceUrl: true,
        };

        // this.newOrderPopup();

        this.close();

        if(this.params && this.params['prefix']){
            this.events.publish(this.params['prefix']);
        }
        else
            this.events.publish('newPayment_'+this.orderId);
        // this.navCtrl.navigateRoot('order/' + this.orderId, navigationExtras);
        this.orderService.clearCart(true);
    }

    close() {
        this.modalController.dismiss();
    }

    checkCashLoop() {
        let me = this;
        this.interval = setInterval(function () {
            me.faild = false;
            me.modelProvider.custom("paymentcheck", {'order': me.orderId}).then(function (res: CostumRes) {
                if (me.finish) return;
                if (res['res'] == 1) {
                    me.faild = false;



                    // me.navCtrl.remove(me.viewCtrl.index - 1, 2);
                    me.finish = true;
                    clearInterval(me.interval)
                }
                // } else if (res.res == -1) {
                //     // me.faild = true;
                //     me.modelProvider.presentToast(me.translate.instant('faildPaymentTryAgain'), true, 'danger')
                //     me.url = null;
                //     setTimeout(function () {
                //         me.url = me.trustSrc()
                //     })
                // }
            });
        }, 3000);
    }


    newOrderPopup() {
        let alerttxt = this.translate.instant('dearCustomerThankYouForYourOrder') + ' ' + this.business.data.GlobalSettingsData.name + '.';
        let alertext = this.translate.instant('orderNumber') + ' ' + this.orderId + '.';
        this.modelToolsProvider.textAlert(alerttxt, alertext);
    }

    async newOrderPopup1() {
        const popover = await this.popoverCtrl.create({
            component: NewOrderComponent,
            componentProps: {},
            event: null,
            animated: true,
            showBackdrop: true,
            cssClass: 'center-popover'
        });
        return await popover.present();
    }
}
