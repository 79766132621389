import {Component, Input, OnInit} from '@angular/core';
import {AppDataService} from "../../services/AppDataService";
import {Panel} from "../../../framework/model/core/panel/panel";
import {Events} from "../../../framework/providers/events";
import {ModelToolsProvider} from "../../../framework/providers/model-tools/model-tools";

@Component({
  selector: 'app-data-value',
  templateUrl: './data-value.component.html',
  styleUrls: ['./data-value.component.scss'],
})
export class DataValueComponent implements OnInit {

  @Input()
  valueKey:string;

  @Input()
  panel:Panel;

  prefix = this.tools.getRandomize();

  valueData:object;

  constructor(public appdata:AppDataService, public appevents:Events, public tools:ModelToolsProvider) { }

  ngOnInit() {
    this.setData();

    this.appevents.subscribe('appPriceSets', (res)=>{
      this.setData()
    }, this.prefix)
  }

  ngOnDestroy()
  {
    this.appevents.unsubscribe('appPriceSets', this.prefix)
  }

  setData(){
    this.valueData = {'value':null};
    let data = this.appdata.getPricesListKey(this.valueKey)
    // console.log("SETTING DATA", data, this.valueKey);

    if(data)
      this.valueData = data;

  }

}
