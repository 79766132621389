import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {MenuController, NavController} from '@ionic/angular';
import {AuthService} from '../../../framework/providers/auth-service/auth-service';
import {ActivatedRoute} from '@angular/router';
import {ModelToolsProvider} from "../../../framework/providers/model-tools/model-tools";
import {AppSettings} from "../../conf/appSettings";
import {MainService} from "../../services/MainService";
import {BusinessService} from "../../services/BusinessService";
import {ModelProvider} from "../../../framework/providers/modelProvider/ModelProvider";
import {Events} from "../../../framework/providers/events";

@Component({
    selector: 'app-login',
    templateUrl: './login.page.html',
    styleUrls: ['./login.page.scss'],
})
export class LoginPage implements OnInit {
    public credentials = {'email': '', 'password': ''};
    public badLogin: boolean = false;

    public settings;

    @Input()
    block: boolean = false;

    @ViewChild('input', null) input;

    constructor(public events: Events, private auth: AuthService, public navCtrl: NavController, public route: ActivatedRoute,
                public businessService: BusinessService,
                public modelProvider: ModelProvider,
                public modelTools: ModelToolsProvider, public appSettings: AppSettings, public mainService: MainService,
                public menuCtrl: MenuController) {
    }

    ionViewWillEnter() {
        this.route.queryParams.subscribe(params => {
            this.badLogin = params['badLogin'];
            this.mainService.setMenu(!this.block)
        });
    }

    ngOnInit() {
        this.settings = this.appSettings.getSettings('login')
        this.block = this.settings['block'];
    }

    ngAfterViewInit() {
        // let me = this;
        // setTimeout(function () {
        //     me.input.setFocus();
        // }, 200)
    }

    public login(cren) {
        if (!cren['email'] || cren['email'] == '') return;
        if (!cren['password'] || cren['password'] == '') return;

        this.badLogin = false;
        // this.events.publish('setBackLoadingOn');
        this.modelProvider.loading();
        cren['username'] = cren['email'];
        let me = this;
        this.auth.login(cren)
            .subscribe(allowed => {
                    if (allowed) {
                        ////console.log'user login in publish event!!');
                        // this.events.publish('user:login');
                        // this.events.publish('setBackLoadingOff');
                        this.modelProvider.loadingOff();
                        // this.navCtrl.navigateRoot('main');


                    } else {
                        this.badLogin = true;
                        this.modelProvider.loadingOff();
                        // this.events.publish('setBackLoadingOff');
                        ////console.log'false!')
                        // this.loading.dismiss();
                        // this.showError("Wrong credentials has been provided.");
                    }
                },
                error => {

                    // me.loading.dismiss();
                    // this.showError(error);
                });
    }

    register() {
        // if (this.businessService.getBusiness().data.prefix == 'movingaid' ||
        //     this.businessService.getGlobalSettings().data.name == 'oneflow1' || this.businessService.getGlobalSettings().data.bisType == 'moving') {
        //     this.navCtrl.navigateForward('signup');
        // } else {
        this.mainService.goToRegister({'block': this.block});
        // }
    }

    // showRegisterForms(settingsKey)
    // {
    //     this.mainService.showFormsModal({'settingsKey':settingsKey});
    // }

    goTo(page, params = {}) {
        params['block'] = this.block;
        this.modelTools.goTo(page, params)
        // let navigationExtras: NavigationExtras = {
        //     queryParams: params
        // };
        // this.navCtrl.navigateForward(page, navigationExtras);
    }

}