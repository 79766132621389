export class Ticket {
    data = {
        "id": null,
        "subject": "",
        "status": false,
        "readed": false,
        "adminReminder": false,
        "visibleToAll": false,
        "message": "",
        "emailSent": false,
        "business": null,
        "user": null,
        "item": null,
        "created": null,
    };

    constructor(public data1: any) {
        this.data = data1;
    }

    prefix = "ticket";
    title = "TicketMessage";
    dataFields = {
        id: {'title': 'id', 'value': null, 'type': 'number', 'readonly': true},
        subject: {'title': 'subject', 'value': null, 'type': 'text'},
        created: {'title': 'Created', 'value': null, 'type': 'date', 'readonly': true},
        status: {'title': 'status', 'value': null, 'type': 'text'},
        readed: {'title': 'readed', 'value': null, 'type': 'boolean'},
        adminReminder: {'title': 'adminReminder', 'value': null, 'type': 'text'},
        visibleToAll: {'title': 'visibleToAll', 'value': null, 'type': 'text'},
        message: {'title': 'message', 'value': null, 'type': 'textarea', 'maxlength': 800},
        emailSent: {'title': 'emailSent', 'value': null, 'type': 'text'},
        business: {'title': 'business', 'value': null, 'type': 'text'},
        user: {'title': 'user', 'value': null, 'type': 'text'},
        item: {'title': 'item', 'value': null, 'type': 'text'},
    }
}
