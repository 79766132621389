import {Component, Input, OnInit} from '@angular/core';
import {Events} from "../../../../../framework/providers/events";

@Component({
    selector: 'app-box-header',
    templateUrl: './box-header.component.html',
    styleUrls: ['./box-header.component.scss'],
})
export class BoxHeaderComponent implements OnInit {
    @Input()
    tabIndex = 0;
    @Input()
    miniViewOpen = true;
    @Input()
    exTableData;
    @Input()
    sectionName;
    @Input()
    filterModel;
    @Input()
    errors = {};
    @Input()
    editMode = false;
    @Input()
    prefix;
    @Input()
    topPrefix;//top page modeldata for open select options choose
    @Input()
    initRefresh = false;
    @Input()
    model;
    modelType;
    @Input()
    tableParams;
    @Input()
    viewOnly = false;
    @Input()
    miniView = false;
    @Input()
    colsize;
    @Input()
    onMainPage = false;
    @Input()
    showOkButtons = true;
    @Input()
    tabs;
    @Input()
    tabsOpen;
    @Input()
    rowClickEvent;
    @Input()
    maxFields = 1000;

    //hide top header - on top of viewbox
    @Input()
    hideHeader = false;
    @Input()
    hideModelOptions = false;

    @Input()
    specificFields;
    @Input()
    specificTab;

    buttonFields;
    @Input()
    tabSize;
    @Input()
    tableData;
    buttonTabs;

    @Input()
    activeTab;
    tabsData;
    tabKeys;

    selectTablePrefix;

    ready = false;

    constructor(public events: Events) {
    }

    ngOnInit() {
        if (!this.tabs) {
            let tabKeys;
            tabKeys = this.exTableData[this.sectionName + 'Data']['tabs'];
            if (!tabKeys) tabKeys = ['-'];
            this.tabKeys = tabKeys;
        } else {
            this.tabKeys = this.tabs;
        }

        let t = [];
        for (let tabkey of this.tabKeys) {
            if (tabkey != '0') t.push(tabkey);
        }
        this.tabKeys = t;

        let tabsData = {};
        for (let tabkey of this.tabKeys) {
            tabsData[tabkey.toString()] = [];
        }

        // insert fields in to their tab
        for (let fieldKey in this.modelType) {
            if (!this.modelType[fieldKey]['tabIndex']) continue;
            let tabName = this.modelType[fieldKey]['tabIndex'].toString();
            if (!tabsData[tabName]) continue;
            tabsData[tabName].push(this.modelType[fieldKey]);
        }

        let tabsArr = [];
        for (let key of this.tabKeys) {
            tabsData[key].sort(function (a, b) {
                return b['order'] - a['order'];
            });

            tabsArr.push(tabsData[key]);
        }

        this.tabsData = tabsArr;
        if (!this.tabsOpen && !this.activeTab) {
            this.activeTab = this.tabKeys[0];
        }

        if (!this.tableData['buttonTabs']) {
            this.buttonTabs = this.tabKeys;
        } else {
            this.buttonTabs = this.tableData['buttonTabs'];
        }
    }

    changeTab(key) {
        this.activeTab = key;
        this.events.publish('changeTab' + this.prefix, {'key': key});
    }

}
