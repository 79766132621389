// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading-icon ion-spinner {
  width: 100px;
  height: 100px;
}`, "",{"version":3,"sources":["webpack://./src/framework/components/core/app-loading/app-loading.scss"],"names":[],"mappings":"AAEE;EAEE,YAAA;EACA,aAAA;AAFJ","sourcesContent":["//app-loading {\n\n  .loading-icon ion-spinner\n  {\n    width: 100px;\n    height: 100px;\n    //color:#ac1f25;\n  }\n\n  .loading-icon .spinner-md-crescent circle , .spinner-ios-crescent circle\n  {\n    //stroke: #ac1f25;\n  }\n//}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
