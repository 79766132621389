import {Component, OnInit} from '@angular/core';
import {NavParams} from "@ionic/angular";
import {MiniItem} from "../../../../framework/model/core/items/miniitem";
import {OrderService} from "../../../services/OrderService";
import {OrderItem} from "../../../../framework/model/core/order/orderItem";
import {ModelProvider} from "../../../../framework/providers/modelProvider/ModelProvider";
import {TranslateService} from "@ngx-translate/core";
import {ModelToImage} from "../../../../framework/model/core/general/modelToImage";
import {ItemProvider} from "../../item/providers/item-provider/item-provider.service";
import {AppUser} from "../../../../framework/model/core/appuser/appuser";
import {AppDataService} from "../../../services/AppDataService";
import {AppSettings} from "../../../conf/appSettings";
import {ModalController} from "@ionic/angular";
import {Events} from "../../../../framework/providers/events";
import {Business} from "../../../../framework/model/core/business/business";
import {BusinessService} from "../../../services/BusinessService";

@Component({
    selector: 'agent-keyboard',
    templateUrl: './agent-keyboard.page.html',
    styleUrls: ['./agent-keyboard.page.scss'],
})
export class AgentKeyboardPage implements OnInit {

    public model: MiniItem;
    public cartItem: object;
    public orderItem: OrderItem;
    public props: Array<any> = [];

    public prefix: string;

    public appuser: AppUser;
    public business: Business;

    public keyboardRes: object = {
        amount: '1',
        price: '0',
        step: 'price',
        'keypressed': false,
        'dotpressed': false,
        'dotexist': false,
        'comments': '',
        'loading': false
    };

    constructor(public modalController: ModalController, public navParams: NavParams,
                public events: Events,
                public businessService:BusinessService,
                public itemProvider:ItemProvider,
                public orderService: OrderService, public appDataService:AppDataService,
                public appSettings:AppSettings,
                public modelProvider: ModelProvider, public tranlate: TranslateService) {
        this.model = navParams.get('model');
        this.cartItem = navParams.get('cartItem');
        this.orderItem = navParams.get('orderItem');
        this.props = navParams.get('props');

        console.log('keyboardd', this.cartItem);
    }

    ngOnInit() {
        this.appuser = this.orderService.getAppUser();
        this.business = this.businessService.getBusiness();
        let cartData = this.orderService.getCartData();
        let settings = this.appSettings.getSettings('cart');

        let actionPriceEdit = cartData['action'] == 'shippingReport' && settings['shippingReportPriceEdit'];

        let skipPrices = !this.appuser || (this.appuser.data.userType != 3 && !actionPriceEdit);
        if(skipPrices)
        {
            this.keyboardRes['step'] = 'amount';
        }

        if (this.orderItem) {
            this.model = this.appDataService.getItemModel(this.orderItem.data.itemId);
            // this.model = new MiniItem(this.orderItem.data.itemData.data);

            for (let k in this.model.data['images']) {
                this.model.data['images'][k] = new ModelToImage(this.model.data['images'][k]);
            }

            this.keyboardRes['amount'] = this.orderItem.data.amount + '';
            this.keyboardRes['price'] = this.orderItem.data.unitPrice + '';
            this.keyboardRes['comments'] = this.orderItem.data.comments;
            this.keyboardRes['dotexist'] = this.keyboardRes['price'].includes('.');
            // this.keyboardRes['keypressed'] = true;
        } else if (this.cartItem['amount']) {
            this.keyboardRes['amount'] = this.cartItem['amount'] + '';
            this.keyboardRes['price'] = this.cartItem['corePrice'] + '';
            this.keyboardRes['comments'] = this.cartItem['comments'];
            this.keyboardRes['dotexist'] = this.keyboardRes['price'].includes('.');
            // this.keyboardRes['keypressed'] = true;
        } else
            this.keyboardRes['price'] = this.model.data.price + '';
    }

    keyPressed(key) {
        if (this.keyboardRes['loading']) return;

        key = key + '';

        let stepKey = this.keyboardRes['step'];

        if (key == '+' || key == '-') {
            key = key + 1;
            let inputres = parseFloat(this.keyboardRes[stepKey]);
            inputres += parseFloat(key);
            this.keyboardRes['keypressed'] = true;

            if (inputres < 0)
                inputres = 0;

            this.keyboardRes[stepKey] = inputres + '';

            return;
        }

        if (this.keyboardRes[stepKey] == '0')
            this.keyboardRes['keypressed'] = false;

        if (this.keyboardRes['keypressed']) {
            if (this.keyboardRes['dotpressed'] && !this.keyboardRes['dotexist']) {
                key = '.' + key;
                this.keyboardRes['dotpressed'] = false;
            }

            if (key == '.') {
                this.keyboardRes['dotpressed'] = true;
            } else {
                this.keyboardRes[stepKey] = this.keyboardRes[stepKey] += key;

                this.keyboardRes['dotexist'] = this.keyboardRes['dotpressed'] = this.keyboardRes[stepKey].includes('.');
            }

        } else {
            if (key == '0' || key == '.') return;
            this.keyboardRes[stepKey] = key;
            this.keyboardRes['keypressed'] = true;
        }
    }

    nextStep() {
        if (this.keyboardRes['step'] == 'amount') {
            if (!parseInt(this.keyboardRes['amount'])) return;
            // this.keyboardRes['step'] = 'price';

            // if(!this.orderItem && !this.cartItem['amount'])
            // this.keyboardRes['keypressed'] = false;

            if (this.orderItem)
                this.updateOrderItem();
            else
                this.addToCart();
        } else {
            let minPrice = this.model.data.baseDistributorPrice;
            if (this.model.data['sale']) {
                minPrice = minPrice < this.model.data.price ? minPrice : this.model.data.price;
            }

            if (this.orderItem) {
                minPrice = minPrice < this.orderItem.data.unitPrice ? minPrice : this.orderItem.data.unitPrice;
            }

            console.log(this.business.data.GlobalSettingsData)

            if(this.business.data.GlobalSettingsData['underBasePriceAllow'])
            {
                minPrice = 0;
            }

            if (this.keyboardRes['price'] < minPrice && minPrice > 0) {
                this.modelProvider.presentToast(this.tranlate.instant('priceTooLow'));
                return
            }

            // if(this.props.length)
            // {
            //     this.keyboardRes['amount'] = 1;
            //     this.addToCart();
            //     return
            // }

            this.keyboardRes['step'] = 'amount';

            this.keyboardRes['keypressed'] = false;
        }
    }

    resetInput() {
        let keyboardRes = this.keyboardRes;
        if (keyboardRes['loading']) return;
        keyboardRes[keyboardRes['step']] = '0';
        keyboardRes['keypressed'] = false;
    }

    backspace(keyboardRes) {
        if (keyboardRes['loading']) return;

        let stepKey = keyboardRes['step'];
        let inputres = keyboardRes[stepKey] + '';
        inputres = inputres.slice(0, -1);
        if (inputres == '') inputres = '0';
        keyboardRes[stepKey] = inputres;

        keyboardRes['dotexist'] = keyboardRes[stepKey].includes('.');
        if (!keyboardRes['dotexist'])
            keyboardRes['dotpressed'] = false;
    }

    updateOrderItem() {
        if (this.keyboardRes['loading']) return;

        let unitPrice = this.orderService.getPriceCurrencyCalc(this.model, this.keyboardRes['price']);
        this.orderItem.data.amount = parseInt(this.keyboardRes['amount']);
        this.orderItem.data.unitPrice = parseFloat(this.keyboardRes['price']);
        this.orderItem.data.price = unitPrice;
        this.orderItem.data.totalPrice = this.orderItem.data.amount * unitPrice;
        this.orderItem.data.comments = this.keyboardRes['comments'];
        this.keyboardRes['loading'] = true;
        this.modelProvider.update(this.orderItem).then((res) => {
            this.events.publish(this.prefix + '_updateModels', {});
            this.keyboardRes['loading'] = false;
            this.close();
        })
    }

    addToCart() {
        let amount = parseInt(this.keyboardRes['amount']);
        let price = parseFloat(this.keyboardRes['price']);

        let index = this.cartItem['index'] ? this.cartItem['index'] : this.model.data.id
        // // if(this.cartItem['amount'])
        // //   this.orderService.removeFromCart(null, this.cartItem['product'].data.id);
        // //console.logthis.cartItem)
        let me = this;
        // setTimeout(function () {

        let data = this.cartItem['data'];
        data['comments'] = this.keyboardRes['comments'];

        // me.orderService.addToBasket(null, me.model, amount, null, null, price, price, index, false, me.keyboardRes['comments'], me.cartItem['props']);

        this.itemProvider.addToCart(this.model, amount, true, false, false, this.props,
              null, null, price, false, index, false, price, 0, data);

        me.close();
        // })
    }

    ngOnDestroy()
    {
        this.events.publish('keyboardClosed');
    }

    close() {
        this.modalController.dismiss();
        this.events.publish('keyboardClosed');
    }

}
