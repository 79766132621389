import {Component, OnInit} from '@angular/core';
import {Order} from "../../../../../framework/model/core/order/order";
import {OrderService} from "../../../../services/OrderService";
import {ResObject} from "../../../../../app/global-module/global-module.module";
import {NavigationExtras} from "@angular/router";
import {OrderItem} from "../../../../../framework/model/core/order/orderItem";
import {ModelProvider} from "../../../../../framework/providers/modelProvider/ModelProvider";
import {NavController, Platform, PopoverController} from "@ionic/angular";
import {CouponsComponent} from "../../components/coupons/coupons.component";
import {Address} from "../../../../../framework/model/core/address/address";
import {NoteComponent} from "../../../../../framework/components/core/note/note.component";
import {Business} from "../../../../../framework/model/core/business/business";
import {BusinessService} from "../../../../services/BusinessService";
import {TranslateService} from "@ngx-translate/core";
import {ModelToolsProvider} from "../../../../../framework/providers/model-tools/model-tools";
import {GlobalSettings} from "../../../../../framework/model/core/business/globalSettings";
import {Events} from "../../../../../framework/providers/events";

@Component({
    selector: 'app-cart-summary',
    templateUrl: './cart-summary.page.html',
    styleUrls: ['./cart-summary.page.scss', '../../../order/order.page.scss'],
})
export class CartSummaryPage implements OnInit {
    public cartData: object;
    public cart: object;
    public order: Order;
    public address: Address;

    public business: Business;

    constructor(public orderService: OrderService, public modelProvider: ModelProvider,
                public navCtrl: NavController, public popoverCtrl: PopoverController,
                public events: Events, public businessService: BusinessService,
                public translate: TranslateService, public platform: Platform,
                public modelToolsProvider:ModelToolsProvider,){
    }

    ngOnInit() {
        this.business = this.businessService.getBusiness();

        this.getCart();
        this.getOrder();
        this.getAddress();
    }

    ionViewWillEnter() {
        if (!this.cartData['amount'])
            this.navCtrl.navigateBack('main')
    }

    getOrder() {
        this.order = this.orderService.getOrder();
    }

    getCart() {
        this.cartData = this.orderService.getCartData()
        this.cart = this.orderService.getCart()
    }

    getAddress() {
        this.address = this.orderService.getAddress()
    }

    back() {
        this.navCtrl.pop();
    }

}
