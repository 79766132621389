import {Component, Input, OnInit} from '@angular/core';
import {AdminService} from "../../../../../core/services/AdminService";
import {ModelProvider} from "../../../../../framework/providers/modelProvider/ModelProvider";

@Component({
    selector: 'app-additional-services',
    templateUrl: './additional-services.component.html',
    styleUrls: ['./additional-services.component.scss'],
})
export class AdditionalServicesComponent implements OnInit {
    @Input()
    prefix;
    @Input()
    model;

    @Input()
    tableData;

    @Input()
    sectionName;

    @Input()
    exTableData;

    fieldTypes;

    customVars;

    constructor(public adminService: AdminService, public modelProvider: ModelProvider) {

    }

    ngOnInit() {
        this.fieldTypes = this.adminService.getFieldsType(this.sectionName, this.exTableData);
        this.customVars = JSON.parse(this.model['vars']['value']);
        console.log(this.customVars);
        // console.log(this.fieldTypes);
    }

    saveVars() {
        let me = this;

        this.model['vars']['value'] = JSON.stringify(this.customVars);

        this.adminService.saveField(this.sectionName, this.model, this.model['vars'], this.fieldTypes['vars'], function (data) {
            //console.logdata);
            let tableData = data['data']['models'][0];
            me.model['finalPrice']['value'] = me.model['finalPrice']['displayName'] = tableData['finalPrice']['value'];
            me.model['finalEquation']['value'] = me.model['finalEquation']['displayName'] = tableData['finalEquation']['value'];
            me.customVars = JSON.parse(tableData['vars']['value']);
        });
    }

}
