import {Component, Input, OnInit} from '@angular/core';
import {Events} from "../../../providers/events";


@Component({
  selector: 'auto-complete',
  templateUrl: './auto-complete.component.html',
  styleUrls: ['./auto-complete.component.scss'],
})
export class AutoCompleteComponent implements OnInit {

  @Input()
  public model:any;

  @Input()
  public fieldkey:any;

  public searchTxt:string;
  public showOptions:boolean = false;

  public fieldData:any;
  public prefix:any;
  public models:Array<any> = [];
  public filters:object = {};

  constructor(public events:Events) { }

  ngOnInit() {
    this.fieldData = this.model.dataFields[this.fieldkey];
    this.prefix=this.fieldData['modelName'] + '_autoComplete_' + + Math.floor(Math.random() * Math.floor(1000000));
  }

  search()
  {
    ////console.log"aaaa")
    this.filters['search'] = this.searchTxt;
    this.events.publish(this.prefix+'_updateFilter', this.filters);
    this.showOptions=true;
  }

  select(option)
  {
    this.model.data[this.fieldkey+'Id'] = option.data.id;
    this.model.data[this.fieldkey] = option.data[this.fieldData['displayField']];

    this.showOptions = false;
    ////console.logthis.model)
  }

}
