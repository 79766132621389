// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sliding-row {
  white-space: nowrap;
  overflow: scroll;
  background: #fff;
  border-width: 0;
  border-color: #ccc;
  /* border-style: solid; */
  border-style: solid;
  border-top-width: 2px;
  border-bottom-width: 2px;
}

.sliding-col {
  display: inline-block;
  width: 70px;
  padding: 0 5px;
  white-space: pre-line;
  vertical-align: top;
  text-align: center;
  max-height: 42px;
}`, "",{"version":3,"sources":["webpack://./src/core/components/sliders/model-slider-headers/model-slider-headers.component.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,gBAAA;EACA,gBAAA;EACA,eAAA;EACA,kBAAA;EACA,yBAAA;EACA,mBAAA;EACA,qBAAA;EACA,wBAAA;AACF;;AAEA;EACE,qBAAA;EACA,WAAA;EACA,cAAA;EACA,qBAAA;EACA,mBAAA;EACA,kBAAA;EACA,gBAAA;AACF","sourcesContent":[".sliding-row {\n  white-space: nowrap;\n  overflow: scroll;\n  background: #fff;\n  border-width: 0;\n  border-color: #ccc;\n  /* border-style: solid; */\n  border-style: solid;\n  border-top-width: 2px;\n  border-bottom-width: 2px;\n}\n\n.sliding-col {\n  display: inline-block;\n  width: 70px;\n  padding: 0 5px;\n  white-space: pre-line;\n  vertical-align: top;\n  text-align: center;\n  max-height: 42px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
