// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filters-box {
  margin-top: 5px;
  max-height: 300px;
  overflow-y: auto;
}

.filter-box {
  padding: 15px;
}`, "",{"version":3,"sources":["webpack://./src/framework/components/list/list-filter/list-filter.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EAEA,iBAAA;EACA,gBAAA;AAAF;;AAGA;EACE,aAAA;AAAF","sourcesContent":[".filters-box {\n  margin-top: 5px;\n\n  max-height: 300px;\n  overflow-y: auto;\n}\n\n.filter-box {\n  padding: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
