import {Component, Input, OnInit} from '@angular/core';
import {Business} from "../../../../../framework/model/core/business/business";
import {Branch} from "../../../../../framework/model/core/branch/branch";
import {BusinessService} from "../../../../services/BusinessService";
import {MainService} from "../../../../services/MainService";
import {OrderService} from "../../../../services/OrderService";
import {Events} from "../../../../../framework/providers/events";

@Component({
    selector: 'branches',
    templateUrl: './branches.component.html',
    styleUrls: ['./branches.component.scss'],
})
export class BranchesComponent implements OnInit {
    public business: Business;
    public models: Array<Branch> = [];

    @Input()
    public view:string = 'cards';

    @Input()
    public appDataKey:string;

    public prefix:string = 'branch_' + +Math.floor(Math.random() * Math.floor(1000000));

    @Input()
    public filters:object = {};

    @Input()
    public options:object = {};

    public cartData;

    constructor(public businessService: BusinessService, public mainService:MainService, public orderService:OrderService, public events:Events) {
        this.business = this.businessService.getBusiness();
    }

    ngOnInit() {
        this.getCartData();

        this.events.subscribe(this.prefix+'_modelUpdated', (res) => {
            this.initData()
        });
        this.events.subscribe('agentCompanyChanged', (res) => {
            this.initData()
        });
    }

    initData()
    {
        if(this.options['initByCompanyGroup'])
        {
            let company = this.orderService.getCompany();
            if(!company.data.group_id) return
            for(let k in this.models)
            {
                if(this.models[k].data['ext_id'] == company.data.group_id)
                {
                    this.orderService.setPickupBranch(this.models[k]);
                }
            }
        }
    }

    getCartData() {
        this.cartData = this.orderService.getCartData();
        ////console.logthis.cartData);
    }

    viewComments(model)
    {
        this.mainService.notePopup(null, model.data.name, model.data.comments, {'text-align':'center'});
    }

    setBranch(e)
    {
        let modelId = e['detail']['value'];
        if(!modelId) return;
        let model;
        for(let k in this.models) {
            if (this.models[k].data.id == modelId)
                model = this.models[k];
        }
        ////console.logmodel)
        if(!model) return;
        this.orderService.setPickupBranch(model);
    }

}
