import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {AppUser} from "../../../../../framework/model/core/appuser/appuser";
import {Business} from "../../../../../framework/model/core/business/business";
import {Order} from "../../../../../framework/model/core/order/order";
import {OrderItem} from "../../../../../framework/model/core/order/orderItem";
import {ActionSheetController, NavController, Platform} from "@ionic/angular";
import {ActivatedRoute, NavigationExtras} from "@angular/router";
import {ModelProvider} from "../../../../../framework/providers/modelProvider/ModelProvider";
import {ModelToolsProvider} from "../../../../../framework/providers/model-tools/model-tools";
import {ItemProvider} from "../../../item/providers/item-provider/item-provider.service";
import {AppSettings} from "../../../../conf/appSettings";
import {TranslateService} from "@ngx-translate/core";
import {OrderService} from "../../../../services/OrderService";
import {AppDataService} from "../../../../services/AppDataService";
import {BusinessService} from "../../../../services/BusinessService";
import {ModelToImage} from "../../../../../framework/model/core/general/modelToImage";
import {AgentKeyboardPage} from "../../../agent/agent-keyboard/agent-keyboard.page";
import {actionColors} from "../../../../conf/main";
import {CurrencyPipe, DatePipe} from "@angular/common";
import {PaymentPage} from "../../../cart/pages/payment/payment.page";
// import {FileTransfer, FileTransferObject} from "@ionic-native/file-transfer/ngx";
// import {File} from "@ionic-native/file/ngx";
import {ModelStatus} from "../../../../../framework/model/modelStatus";
import {Events} from "../../../../../framework/providers/events";
import {ModalController} from "@ionic/angular";
import {AdminService} from "../../../../services/AdminService";
import {MainService} from "../../../../services/MainService";
import {Item} from "../../../../../framework/model/core/items/item";

@Component({
    selector: 'app-order-content',
    templateUrl: './order-content.component.html',
    styleUrls: ['./order-content.component.scss'],
})
export class OrderContentComponent implements OnInit {

    public appuser: AppUser;
    public business: Business;
    public order: Order;
    public showComments = false;
    public showCoi = false;
    public statusModels = [];

    @Input()
    orderId;

    @Input()
    noCols: boolean = false;

    neworder: boolean = false;

    public prefix: string = 'OrderList' + +Math.floor(Math.random() * Math.floor(1000000));
    public itemsPrefix: string = 'OrderItemsList' + +Math.floor(Math.random() * Math.floor(1000000));

    public loadOrderText: string = 'loadOrder';

    public items: Array<OrderItem> = [];
    public totalItems: number;
    public openStatus: object = {};
    public itemsObj: object = {};
    public ready: boolean;

    public fields: Array<string> = ['id', 'companyName',
        'internalCompanyId', 'userName', 'cellphone', 'orderAdress', 'pickupBranchName', 'doc_id',
        'email', 'action', 'due_date', 'updated', 'status', 'toDate', 'nextDeliveryDate', 'paymentVerified', 'paymentId', 'comments'];

    public settings: object = {};

    public formsOptions: object = {};

    public vatPrice;
    public beforeVatPrice;

    public statusChangedPrefix;

    public modelStatuses;

    public whatsapShareParams = {'ordercheck': false, 'itemscheck': false};

    private isbrowser: boolean = false;

    // private fileTransfer: FileTransferObject;

    constructor(public adminService: AdminService,
                public events: Events, public route: ActivatedRoute, public modelProvider: ModelProvider,
                public modelTools: ModelToolsProvider,
                public navCtrl: NavController, public itemProvider: ItemProvider, public appSettings: AppSettings,
                public actionSheetController: ActionSheetController, public translate: TranslateService,
                private datePipe: DatePipe,
                public mainService:MainService,
                private changeRef: ChangeDetectorRef,
                public modalController: ModalController, public orderService: OrderService, public appDataService: AppDataService,
                public businessService: BusinessService, public platform: Platform) {
        // this.fileTransfer = transfer.create();

        this.platform.ready().then(() => {
            this.isbrowser = this.platform.is('mobileweb') || this.platform.is('desktop');
        });

        this.route.queryParams.subscribe(params => {
            this.neworder = params['neworder'];
            // this.whatsapShareParams = {'ordercheck':false, 'itemscheck':false};
            this.whatsapShareParams['neworder'] = this.neworder

        });
    }

    ngOnInit() {
        this.prefix += '_' + this.orderId;

        this.appuser = this.orderService.getAppUser();
        this.business = this.businessService.getBusiness();

        this.statusChangedPrefix = 'orderStatusField_' + this.orderId;

        this.settings = this.appSettings.getSettings('order');
        console.log(this.settings)
        if (this.settings['fields'][0] != 'all')
            this.fields = this.settings['fields'];

        this.getOrder();


        //
        // this.events.subscribe(this.prefix + '_modelUpdated', (params) => {
        //     this.order = params['models'][0];
        //     //console.log'GER ORDER', params, this.order, this.neworder)
        //     if (!this.order) {
        //         this.navCtrl.navigateRoot('main');
        //         return;
        //     }
        //
        //     if(this.neworder && this.order.data.balance)
        //     {
        //         this.payOrder();
        //     }
        // });


        this.events.subscribe(this.prefix + '_updateModels', (params) => {
            this.getOrder();
        });

        this.events.subscribe('newPayment_' + this.orderId, (params) => {
            this.getOrder();
        });

        this.events.subscribe(this.itemsPrefix + '_modelUpdated', (params) => {
            this.items = params['models'];
            this.setItemsObj();
            if (this.neworder) {
                this.sendOrder('items');
            }

            if(!this.order.data.total_items) {
                this.totalItems = 0;
                for (let item of this.items) {
                    this.totalItems += item['data']['amount'];
                }
            }
        });

        this.events.subscribe(this.statusChangedPrefix, (params) => {
            let me = this;
            setTimeout(function () {
                me.save();
            })
        });


    }

    ngAfterViewInit() {
    }

    getOrder() {
        this.modelProvider.list('order', {'id': this.orderId}).then((params) => {
            this.order = params['models'][0];
            //console.log'GER ORDER', params, this.order, this.neworder)
            if (!this.order) {
                this.navCtrl.navigateRoot('main');
                return;
            }

            if (this.neworder) {
                if (this.order.data.balance && this.business.data.GlobalSettingsData.appPayment && this.order.data.paymentMethod == 'creditCard') {
                    this.payOrder();
                } else {
                    if (this.business.data.GlobalSettingsData.whatsappOrderShare) {
                        this.sendOrder('order');
                    }

                    if (!(this.business.data.GlobalSettingsData.appPayment
                        && this.order.data.paymentMethod == 'creditCard') || this.order.data.paymentVerified) {
                        this.orderService.newOrderPopup(this.order);
                    }
                    //
                }
            }
            this.getLoadOrderOptions();
            this.neworder = false;
        })
    }

    getLoadOrderOptions() {
        let keys = this.settings['loadOrder'];

        if (this.order.data.action == 'shippingReport') {
            let srkeys = this.settings['shippingReportOptions']; // shipping Reports Options;
            keys = [];
            if (srkeys) {
                keys = srkeys;
            }
        }
        //
        if (keys && keys.length == 1)
            this.loadOrderText = keys[0];

        return keys

    }

    setItemsObj() {
        // //console.logthis.items)
        // //console.logthis.itemsObj)
        this.itemsObj = {};
        for (let k in this.items) {
            if (!this.itemsObj[this.items[k].data.ticket])
                this.itemsObj[this.items[k].data.ticket] = {
                    'name': this.items[k].data.ticket,
                    'items': [],
                    'properties': {}
                }

            let ticketRes = this.itemsObj[this.items[k].data.ticket];
            // this.items[k].data['amountArr'] = new Array(this.items[k].data['amount'])
            ticketRes['items'].push(this.items[k])

            // this.items[k].data.itemData = this.appDataService.getItemModel(this.items[k].data.itemId);

            let item = this.items[k].data.itemData;

            // //console.logitem)
            let props = item.data.topItemPropertyData;

            // //console.log'addTicketPropeties', props, item);
            for (let p in props) {
                if (!props[p]['itemTypeProperty__calcAble'])
                    continue
                let key = props[p]['itemTypeProperty__name'];
                let value = props[p]['value'];

                // //console.logkey, value, this.items[k].data.amount);
                // //console.logparseFloat(value)*parseFloat(this.items[k].data.amount));
                if (!ticketRes['properties'][key])
                    ticketRes['properties'][key] = 0;
                ticketRes['properties'][key] += (parseFloat(value) * parseFloat(this.items[k].data.amount));
                // //console.logticketRes['properties'][key])
            }
        }

        this.ready = true;
        this.changeRef.detectChanges();
        // //console.logthis.itemsObj);
    }

    payOrder1() {
        this.modelTools.goTo('cart-payment', {'orderId': this.orderId});
    }

    async payOrder() {
        const modal = await this.modalController.create({
            component: PaymentPage,
            componentProps: {
                'orderId': this.orderId
            }
        });

        return await modal.present();
    }


    duplicateOrder(action = null, page = null) {
        this.modelProvider.loading();
        let me = this;
        setTimeout(function () {
            me.initDuplicateOrder(action, page)
        });
    }

    initDuplicateOrder(action, page = null)
    {
        if (action || this.orderService.isAgent()) {
            if (this.appuser.data.companyId != this.order.data.companyId) {
                let prefix = this.modelTools.getRandomize();

                this.modelProvider.loading();
                this.orderService.changeAgentCompany(null, this.order.data.companyId, true, prefix);

                this.events.subscribe('appPriceSets', (res)=>{
                    this.modelProvider.loading();

                    let me = this;
                    // setTimeout(function (){
                        me.addOrderItemsToCart(action);
                        me.modelProvider.loadingOff();

                        if(page) me.modelTools.goTo(page);

                        me.events.unsubscribe('appPriceSets', prefix)
                    // }, 100)
                }, prefix)

                return
            }
        }

        this.addOrderItemsToCart(action);
        if(page) this.modelTools.goTo(page);
        this.modelProvider.loadingOff();

    }

    addOrderItemsToCart(action)
    {

        let force_add_to_cart = false;
        if(action) force_add_to_cart = true;

        for (let k in this.items) {
            let orderItem = this.items[k];
            let itemdata = orderItem.data.itemData;
            let appdataitem:Item = this.appDataService.getItemModel(orderItem.data.itemId)
            if(!appdataitem) continue

            // console.log(orderItem.data.itemId, appdataitem);

            for (let m in itemdata.data.images) {
                itemdata.data.images[m] = new ModelToImage(itemdata.data.images[0])
            }

            if (appdataitem)
                itemdata = appdataitem;

            // //console.logorderItem)

            let amount = orderItem.data.amount;
            let actionAmount = 0;
            if (action) {
                actionAmount = amount;

                if (!this.settings['actionsOptions'] || !this.settings['actionsOptions'][action] || !this.settings['actionsOptions'][action]['set-amount']) {
                    amount = 0
                }
            }

            let agentPrice = null;

            if (action == 'promotionRequest') {
                amount = orderItem.data['amount'];

                agentPrice = orderItem.data['unitPrice'] - orderItem.data['originalItemPrice'];

                if (agentPrice <= 0)
                    continue;
            }

            if (this.orderService.isAgent()) {
                agentPrice = orderItem.data.finalUnitPrice;
            }

            if (action == 'orderRestItems') {
                actionAmount = 0;
                amount = orderItem.data['actionAmount'] - orderItem.data['amount'];

                if (amount <= 0)
                    continue;
            }

            //console.logamount, agentPrice);

            let images = []
            for (let i in orderItem.data.images) {
                let imgdata = new ModelToImage(orderItem.data.images[i].data);
                images.push(imgdata);
            }

            let data = {
                'images': images,
            };

            if (orderItem.data['features']) {
                // console.log(orderItem.data['features'])
                for (let k in orderItem.data['features']) {
                    data[k] = orderItem.data['features'][k];
                }
            }

            let values:any = {
                'amount': amount,
                'action_amount': actionAmount,
                'price': orderItem.data.finalUnitPrice,
                'discount': orderItem.data['discount'],
                'currency_code': orderItem.data['currencyCode'],
                'comments': orderItem.data.comments ? orderItem.data.comments : '',
                // 'coupon_amount':  appdataitem.cart_model.getValue('coupon_amount'),
                // 'coupon_code':  appdataitem.cart_model.getValue('coupon_code'),
            }

            if (data['coupon_amount']) {
                values = {
                    'coupon_amount': data['coupon_amount'] ? data['coupon_amount']+'': null,
                    'coupon_code': data['coupon_code'],
                    'skip_amount': true,
                    'amount': amount,
                };
            }

            appdataitem.cart_model.setValues(values, [], force_add_to_cart);
            this.itemProvider.add_to_cart(appdataitem, null, 'amount', {'force_add_to_cart':force_add_to_cart});

            // this.orderService.newTicket(orderItem.data.ticket);
            // let props = this.itemProvider.getOrderItemProps(orderItem);
            // this.itemProvider.addToCart(itemdata, amount, false, false, true, props,
            //     null, null, orderItem.data.originalItemPrice, false, null, false, agentPrice,
            //     actionAmount, data, {'disable-allow-check':true, 'agentToolbar':null});
        }

    }

    async editItem(model) {
        const modal = await this.modalController.create({
            component: AgentKeyboardPage,
            componentProps: {
                'orderItem': model,
                'prefix': this.prefix,
            }
        });

        return await modal.present();
    }

    removeItem(model, active = false) {
        let me = this;
        // this.modelTools.yesOrNoAlert(function () {
        // setTimeout(function () {
        //     me.changeRef.detectChanges();
        model.data.active = active;
        me.modelProvider.update(model, {'loading': true}).then((res) => {
            me.events.publish(me.prefix + '_updateModels');
        });
        // })
        // })
    }

    goTo(page) {
        let navigationExtras: NavigationExtras = {};
        this.modelTools.goTo(page);
    }

    sendToERP() {
        let key = 'crm/makeOrder';
        let filters = {'orderId': this.order.data.id, 'loading': true, 'setPrefix': true};
        this.modelProvider.custom(key, filters).then((res: object) => {
            if (res['status'] != -1) {
                this.modelTools.textAlert('orderSent', res['orderExtId'], null, true);
                this.order.data.orderExtId = res['orderExtId'];
            } else {
                this.modelTools.textAlert(res['res']);
            }

            this.events.publish(this.itemsPrefix + '_updateModels');
        })

        // this.order.data.erpCheck = false;
        // this.modelProvider.update(this.order).then((res: object) => {
        // })

    }

    cancelOrder() {
        let me = this;
        this.modelTools.yesOrNoAlert(function () {
            me.order.data.status = me.order.data.cancelOrderStatus;
            me.save()
        })
    }


    updateOrderAction(key){
        console.log(key)
        this.order.data.action = key;
        this.save();
    }

    save() {
        // //console.logthis.order);

        //need this???

        this.modelProvider.update(this.order, {}, null, true).then((res: any) => {
            // if (this.orderUpdatePrefix)
            //     this.events.publish(this.orderUpdatePrefix);

            if (this.settings['forms']) {
                this.events.publish(this.formsOptions['prefix'] + '_updateForms', {
                    'params': {'order': this.order.data.id},
                    'filters': {'company': this.order.data.companyId}
                });
            }

            this.order.data['statusData'] = new ModelStatus(res.statusData);

            this.changeRef.detectChanges();
        });
    }

    openItem(model) {
        let item = this.appDataService.getItemModel(model.data.id);
        this.itemProvider.openItem(item);
    }


    loadOrder() {

        // if (keys.length > 1)
            this.showHandleOptions();
        // else {
        //     this.orderService.clearCart();
        //     this.orderService.setOrderAction(keys[0], this.order);
        //     this.duplicateOrder(keys[0], 'cart');
        // }
        // this.orderService.clearCart();
        // this.duplicateOrder();
        // this.goTo('cart');
    }

    async showHandleOptions() {
        let buttonsData = {
            'orderEdit': {
                text: this.translate.instant('orderEdit'),
                role: 'destructive',
                icon: 'list-box',
                handler: () => {
                    this.orderService.clearCart();
                    this.duplicateOrder(null, 'cart');
                }
            },
            'bid': {
                text: this.translate.instant('bid'),
                role: 'destructive',
                icon: 'list-box',
                handler: () => {
                    this.orderService.clearCart();
                    this.duplicateOrder(null, 'cart');
                }
            },
            'pickup': {
                text: this.translate.instant('pickuporder'),
                icon: 'arrow-round-up',
                handler: () => {
                    this.orderService.clearCart();
                    this.orderService.setOrderAction('pickup', this.order);
                    this.duplicateOrder('pickup', 'cart');
                }
            },
            'delivery': {
                text: this.translate.instant('deliveryorder'),
                icon: 'arrow-round-down',
                handler: () => {
                    this.orderService.clearCart();
                    this.orderService.setOrderAction('delivery', this.order);
                    this.duplicateOrder('delivery', 'cart');
                }
            },
            'storagePickup': {
                text: this.translate.instant('storagePickuporder'),
                icon: 'arrow-round-up',
                handler: () => {
                    this.orderService.clearCart();
                    this.orderService.setOrderAction('storagePickup', this.order);
                    this.duplicateOrder('storagePickup', 'cart');
                }
            },
            'storageDelivery': {
                text: this.translate.instant('storageDeliveryorder'),
                icon: 'arrow-round-down',
                handler: () => {
                    this.orderService.clearCart();
                    this.orderService.setOrderAction('storageDelivery', this.order);
                    this.duplicateOrder('storageDelivery', 'cart');
                }
            },
            'askForPromotion': {
                text: this.translate.instant('askForPromotion'),
                icon: 'wallet',
                handler: () => {
                    this.orderService.clearCart();
                    this.orderService.setOrderAction('promotionRequest', this.order);
                    this.duplicateOrder('promotionRequest', 'cart');
                }
            },
            'orderRestItems': {
                text: this.translate.instant('orderRestItems'),
                icon: 'card',
                handler: () => {
                    this.orderService.clearCart();
                    this.duplicateOrder('orderRestItems', 'cart');
                }
            },
            'close': {
                text: this.translate.instant('close'),
                icon: 'close',
                role: 'cancel',
                handler: () => {
                    //console.log'Cancel clicked');
                }
            },
        };

        let keys = this.getLoadOrderOptions();

        if(this.order.data['order_actions'])
        {
            keys = []
            for(let order_action of this.order.data['order_actions'])
            {
                keys.push(order_action['key']);
                buttonsData[order_action['key']] = {
                    text: this.translate.instant(order_action['title']),
                    icon: order_action['icon'],
                    handler: () => {
                        this.orderService.clearCart();
                        this.orderService.setOrderAction(order_action['name'], this.order, order_action['id']);
                        let item_action = order_action['item_action'] ? order_action['key'] : null
                        this.duplicateOrder(item_action, 'cart');
                    }
                }
            }
        }

        if(keys.length == 1){
            buttonsData[keys[0]].handler();
            return;
        }

        let buttons = [];
        keys.push('close');

        for (let key of keys) {
            buttons.push(buttonsData[key]);
        }

        const actionSheet = await this.actionSheetController.create({
            header: this.translate.instant('loadOrder'),
            cssClass: 'my-custom-class',
            buttons: buttons
        });
        await actionSheet.present();
    }

    handleOrder(action) {
        this.modelTools.goTo('order-handle', {'action': action, 'items': this.itemsObj});
    }

    getColor(prop) {
        return actionColors[prop];
    }

    updateStatus(status) {
        //console.log1)
        //console.logstatus)
        if (this.appuser.data.userType == 3 || this.appuser.data['is_staff']) {
            //console.log2)
            this.order.data.status = status.data.id;
            // this.order.data['statusData'] = status;
            this.modelProvider.update(this.order, {}, '', true).then((res) => {
            });
        }
    }

    updateStatusApi(status)
    {
        this.modelProvider.customPost('updateOrderStatus', {'order':this.order.data.id, 'status':status['id']}, {'setPrefix':true}).then((res)=>{
            this.getOrder();
        });
    }


    sendOrder(key) {
        this.whatsapShareParams[key + 'check'] = true;
        for (let k in this.whatsapShareParams) {
            if (!this.whatsapShareParams[k] && key != 'all') return;
        }


        if (key == 'all') {
            this.openWhatsappShare();
            return
        }

        let me = this;
        this.modelTools.yesOrNoAlert(function () {
            me.openWhatsappShare();
        }, {
            'title': this.translate.instant('whatsappShareText'),
            'no': this.translate.instant('close'),
            'yes': this.translate.instant('proceed'),
        })

    }

    openWhatsappShare() {
        let phone = this.setPhone(this.business.data.GlobalSettingsData.supportCellphone);
        let url = 'http://api.whatsapp.com/send?' + phone;

        let date = this.datePipe.transform(this.order.data.created, 'd/M/yy, HH:mm');

        let details = this.getMessageLine('*' + this.translate.instant('orderNo') + '- ' + this.order.data.id + '*');
        details += this.getMessageLine(this.translate.instant('name') + '- ' + this.order.data.contactMan);
        details += this.getMessageLine(this.translate.instant('cellphone') + '- ' + this.order.data.cellphone);
        details += this.getMessageLine(this.translate.instant('date') + '- ' + date);
        details += this.getMessageLine(this.translate.instant('price') + '- ' + this.getPriceFormat(this.order.data.finalPrice));
        details += this.getMessageLine(this.translate.instant('items') + ':');
        for (let k in this.items) {
            details += this.getMessageLine(
                (parseInt(k) + 1) + '. ' +
                this.items[k].data.itemData.data.name + ' -- '
                + this.items[k].data.amount + " יח' -- "
                + this.getPriceFormat(this.items[k].data.price)
            );
        }


        url += '&text=' + details;
        window.open(url, '_system', 'location=yes');
    }

    setPhone(phone) {
        phone = '972' + phone.substring(1);
        return phone
    }

    getMessageLine(str) {
        return str + '%0a'
    }

    getPriceFormat(price) {
        return (price + '₪')
        // return this.currencyPipe.transform(price, 'ש"ח', 'symbol');
    }

    downloadOptions(via='download')
    {
        let files = JSON.parse(this.order.data.pdf)
        let options = [
            {
                'text':'pdf',
                'key':this.appuser.data.userType == 3 && files['detailsagent'] ? 'detailsagent' : 'details'
            }
        ]
        if(files['excel'])
        {
            options.push({
                'text':'excel',
                'key':'excel'
            })
        }

        let buttons = []
        for(let option of options)
        {
            buttons.push({
                'text':option['text'].toUpperCase(),
                'icon':via,
                handler: () => {
                    if(via == 'download')
                        this.download(files[option['key']]);
                    else(via == 'share')
                    {
                        this.mainService.shareViewWhatsapp({
                            'data':[
                                this.modelTools.getTranslate('orderNo')+'- '+this.order.data.id,
                                this.order.data.companyName,
                                files[option['key']]
                            ]
                        })
                    }
                }
            })
        }

        this.viewDownloadSheet(buttons, via);
    }

    async viewDownloadSheet(buttons, via)
    {
        const actionSheet = await this.actionSheetController.create({
            header: this.modelTools.getTranslate(via+'Options'),
            cssClass: 'my-custom-class',
            buttons: buttons
        });
        await actionSheet.present();

        const {role, data} = await actionSheet.onDidDismiss();
    }


    downloadPdf() {
        let pdf = JSON.parse(this.order.data.pdf);
        console.log(pdf)
        let url = pdf['details'];
        if (this.appuser.data.userType == 3 && pdf['detailsagent'])
            url = pdf['detailsagent'];

        //console.log'this.isbrowser', this.isbrowser)
    }

    copyToClipbaord(value)
    {
        this.modelTools.clipoard(value)
    }

    download(url){

        if (this.isbrowser) {
            window.open(url, "_blank");
            return

        }

        window.open(url, "_blank");
        // return
        //
        // let file = url;
        // let name = this.getFileName(file);
        // let path = this.file['dataDirectory'] + name;
        // let target = encodeURI(path);
        // this.fileTransfer.download(file, target).then((entry) => {
        //     cordova.plugins['fileOpener2'].open(
        //         entry.toURL(), // You can also use a Cordova-style file uri: cdvfile://localhost/persistent/Downloads/starwars.pdf
        //         'application/pdf',
        //         {
        //             error: function (e) {
        //                 //console.log'Error status: ' + e.status + ' - Error message: ' + e.message);
        //             },
        //             success: function () {
        //                 //console.log'file opened successfully');
        //             }
        //         }
        //     );
        //
        // }, (error) => {
        //     // handle error
        //     //console.log"ERROR !!")
        // });
    }


    getFileName(url) {
        let arr = url.split('/');
        return arr[arr.length - 1];
    }
}


