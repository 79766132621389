import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {IonContent, MenuController, NavController, PopoverController} from "@ionic/angular";
import {AppConfig} from "../../../../core/conf/main";
import {ModelProvider} from "../../../../framework/providers/modelProvider/ModelProvider";
import {ModelToolsProvider} from "../../../../framework/providers/model-tools/model-tools";
import {BusinessService} from "../../../../core/services/BusinessService";
import {ActivatedRoute, NavigationExtras} from "@angular/router";
import {AdminService} from "../../../../core/services/AdminService";
import {SelectBarComponent} from "../../components/select-bar/select-bar.component";
import {Events} from "../../../../framework/providers/events";
import {OrderService} from "../../../../core/services/OrderService";
import {MainService} from "../../../../core/services/MainService";

@Component({
    selector: 'app-admin-model',
    templateUrl: './admin-model.page.html',
    styleUrls: ['./admin-model.page.scss'],
})
export class AdminModelPage implements OnInit, OnDestroy {
    @ViewChild(IonContent, {'static': true}) ionContent: IonContent;

    showMenu = false;

    @Input()
    forceRefresh = false;

    @Input()
    params;

    adminData;
    //on route to show specific model it gos to false;
    showTable = true;

    appConfig = AppConfig;
    refreshReady = true;

    onScrollPx = 0;
    scrollLeft = 0;
    scrollTop;

    menuCategory;
    models = [];
    prefix = 'taarif' + Math.floor(Math.random() * Math.floor(1000000));
    tablExtData = {'showEdit': false, 'isOpen': false};
    filters = {'ordering': '-id', 'type': 'moving', 'name': 'taarif', 'modelOffset': 0, 'modelLimit': 9};
    modelName;
    currentSectionKey = null;
    currentSubSectionKey = null;
    sectionKey = null;
    mdenuSize = 0;

    showRowModelId;
    ready = false;
    showLoading = false;

    tabsOpen = false;
    currentTableData;
    activeTab = '';

    tableData;
    showRowModel;
    saveInterval;

    displayType;

    showCalendar = false;
    menuBoxes = [];
    openMenuKey;
    mapMode;

    //Show map component
    mapOpen = false;
    initialized = false;
    baseFilter;
    extraSaveFilters;
    extraFilters;

    //on event - show select options of this field
    selectFieldCallbackPrefix;
    selectFieldData;

    //calendar
    dateModel;
    dateFieldData;
    dateFieldType;
    dateModelSectionName;

    myprefix = "selectTablePrefix3" + Math.floor(Math.random() * Math.floor(1000000));

    //right popup - model data and more
    sideTable;
    refModelType;

    //when getting specific data for this page.
    symblinkData;

    onMoveScrollSide;
    onMoveScrollTimeout;

    showMainImageShow;
    cardDashboardKey;

    draggableFooter;
    isResizing = false;
    initialHeight = 0;

    constructor(public events: Events,
                public popoverCtrl: PopoverController,
                public navCtrl: NavController,
                public modelProvider: ModelProvider,
                public menuCtrl: MenuController,
                public mainService: MainService,
                public modelTools: ModelToolsProvider,
                public businessService: BusinessService,
                public orderService: OrderService,
                public adminService: AdminService,
                public route: ActivatedRoute) {
    }

    ngOnInit() {
        this.tableData = this.adminService.manageModels;
        // if (this.tableData && this.adminService.manageModels[this.currentSectionKey]) {
        //     this.onInit();
        // } else {
        let me = this;
        this.adminService.loadAdmin().then(function (tableData: any) {
            me.tableData = tableData;
            me.onInit();
        });
        // }

        this.events.subscribe('globalSaveClick', () => {
            if (this.showRowModel) {
                this.adminService.checkSave([this.showRowModel], this.currentSectionKey, this.filters, this.tableData,
                    false, {}, null, null, null);
            } else {
                // this.adminService.checkSave(this.tableData[this.currentSectionKey], this.currentSectionKey, this.filters, this.tableData,
                //     false, {}, null, null, null);
            }
        }, this.prefix);
    }

    onInit() {
        // //console.log"table data: **************");
        // //console.logthis.adminService.manageModels);
        // //console.logthis.sectionKey);

        // if (this.sectionKey && this.tableData[this.sectionKey + 'Data']['symblink']) {
        //     this.symblinkData = this.tableData[this.sectionKey + 'Data'];
        //     this.currentSectionKey = this.symblinkData['model'];
        // }
        // this.refModelType = this.tableData[this.currentSectionKey + 'Ref'];

        this.menuBoxes = this.tableData['showBoxes'];

        //////console.logthis.tableData);
        //////console.logthis.menuBoxes);
        let me = this;

        this.listen();

        //open in popup?
        if (this.params) {
            this.onLoad(this.params);
        } else {
            this.route.queryParams.subscribe(params => {
                this.onLoad(params);
            });
        }

        this.events.subscribe('showMainImageShow', params => {
            this.showMainImageShow = params['imgUrl'];
        });
        this.events.subscribe('hideMainImageShow', params => {
            this.showMainImageShow = null;
        });

        //is table.
        // if (!this.showRowModel)
        // {
        //     this.adminService);
        // }
    }


    onLoad(params) {
        if (this.initialized) {
            return;
        }
        this.initialized = true;
        console.log(params);

        if (params['displayType']) {
            console.log('inventory!!!');
            this.displayType = params['displayType'];
            this.ready = true;
        }

        //////console.log"admin model page route!!!");
        //////console.log"queryParams:");
        //////console.logparams);

        // if (params['sss']) {
        this.currentSectionKey = params['sectionName'];
        if (params['tableData']) {
            this.tableData = params['tableData'];
        }

        if (this.tableData[this.currentSectionKey + 'Data']['symblink']) {
            this.symblinkData = this.tableData[this.currentSectionKey + 'Data'];
            //console.log'this.symblinkData')
            //console.logthis.symblinkData)
            this.currentSectionKey = this.symblinkData['model'];
        }

        this.sectionKey = this.currentSectionKey;//backwards comptability

        this.forceRefresh = params['forceRefresh'];
        this.openMenuKey = params['openMenuKey'];
        let subIndex = params['subIndex'];
        let rowIndex = params['rowIndex'];
        this.prefix = params['prefix'] ? params['prefix'] : this.prefix;
        this.showRowModel = params['showRowModel'] ? params['showRowModel'] : null;
        let rowId = params['rowId'];
        let baseFilter = params['baseFilter'];
        this.extraSaveFilters = params['extraSaveFilters'];
        this.mapMode = params['mapMode'];
        this.baseFilter = baseFilter;
        this.currentTableData = this.tableData[this.sectionKey + 'Data'];
        //////console.logthis.currentTableData);
        this.activeTab = this.currentTableData['tabs'].indexOf(this.sectionKey);

        // this.models = this.tableData[this.currentSectionKey];

        //calendar
        this.dateModel = params['dateModel'];
        this.dateFieldData = params['dateFieldData'];
        this.dateFieldType = params['dateFieldType'];
        this.dateModelSectionName = params['dateModelSectionName'];

        if (params['extraFilters']) {
            this.extraFilters = params['extraFilters'];
        }
        if (params['filter']) {
            let filters = {};
            filters['search_in_field'] = true;
            filters['searchField'] = params['filter']['fieldName'];
            filters['searchFieldValue'] = params['filter']['value'];
            this.extraFilters = {
                'baseFilter': JSON.stringify(filters),
                'filters': {"fieldFilters": {"filterId": params['filter']['filterId']}}
            }
        }

        this.start(baseFilter, rowIndex, rowId);

        let me = this;
        //for footer
        me.events.publish('rowClick', {
            'sectionName': me.sectionKey,
            'exTableData': me.tableData,
            'model': me.showRowModel
        });

        console.log(this.showRowModel);

        if (params['openMenu']) {
            this.showMenu = true;
        }
    }

    listen() {
        let me = this;

        this.events.subscribe('showGlobalLoading', (d) => {
            me.showLoading = true;
        });
        this.events.subscribe('hideGlobalLoading', (d) => {
            me.showLoading = false;
        });

        this.events.subscribe('onModelsUpdate' + this.prefix, (tablExtData) => {
            this.tablExtData = tablExtData;
            this.models = tablExtData[me.sectionKey];

            //side symblink!!
            //side symblink!!
            //side symblink!!
            //on split screen for this model - settings oriented
            if (this.symblinkData && this.symblinkData['sideTable']) {
                setTimeout(function () {
                    me.showRowModelId = me.models[0]['id']['value'];
                    me.showRowModel = me.models[0];
                }, 2000);

                me.setSideTable(me.sectionKey);

                //set new box model show - on table row click
                this.events.subscribe('clickSideBox' + this.prefix, (data) => {
                    me.showRowModelId = null;
                    me.showRowModel = null;
                    setTimeout(function () {
                        me.showRowModelId = data['rowModel']['id']['value'];
                        me.showRowModel = data['rowModel'];
                    })

                });
            }
        });


        //??
        this.events.subscribe("openAdminModel" + this.prefix, (data) => {
            let params = data['params'];
            let secName = data['secName'];

            this.showRowModelId = data.id;
            this.showRowModel = data.rowModel;
        });

        this.events.subscribe("btnclickAdmin_" + this.myprefix, (keyData) => {
            this.setSideFilter(keyData['key'], keyData['data']);
        });

        this.events.subscribe('update_showRowModel_' + this.prefix, (data) => {
            this.showRowModel = data;
        });

        // this.events.subscribe('showGenericModel', (data) => {
        //     //console.log'showGenericModel:');
        //     //console.logdata);
        //     if (this.showGenericModalData) {
        //         this.showGenericModalData = null;
        //         let me = this;
        //         setTimeout(function () {
        //             me.showGenericModalData = data
        //         }, 100);
        //     } else {
        //         this.showGenericModalData = data;
        //     }
        // });
        this.events.subscribe('refreshMainModelPage' + this.prefix, (data) => {
            // let me = this;
            // this.showTable = false;
            // setTimeout(function () {
            //     me.showTable = true;
            // })
        })

        ////console.log'listen: ', 'openPage' + this.prefix)
        this.events.subscribe('openPage' + this.prefix, (data) => {
            let navigationExtras: NavigationExtras = {
                queryParams: data
            };
            this.navCtrl.navigateForward(data['page'], navigationExtras);
        });

        this.events.subscribe('saveAndOpenPage' + this.prefix, (data) => {
            //////console.log'saveAndOpenPage');
            //////console.log'saveAndOpenPag1111e');
            clearTimeout(me.saveInterval);
            me.saveInterval = setTimeout(function () {
                me.save(data);
            }, 3000);
        });

    }

    setSideTable(sideTable, fieldData = null) {
        let me = this;
        //tell table to shrink/be wider
        me.events.publish('toSideFilterMode' + me.myprefix, !!!me.sideTable);

        if (me.sideTable == sideTable) {
            me.sideTable = null;
        } else {
            me.sideTable = sideTable;
        }
        me.ready = false;
        //notify table to change display to boxes.
        setTimeout(function () {
            me.ready = true;
        }, 1);
    }

    ngOnDestroy() {
        console.log('admin-model.page destroy.' + "btnclick_" + this.myprefix)

        this.events.unsubscribe("openAdminModel" + this.prefix);
        this.events.unsubscribe('selectFieldPopup' + this.prefix);

        this.events.unsubscribe('globalSaveClick', this.prefix);
        this.events.unsubscribe('showGlobalLoading');
        this.events.unsubscribe('hideGlobalLoading');
        this.events.unsubscribe('onModelsUpdate' + this.prefix);
        this.events.unsubscribe('btnclickAdmin_' + this.myprefix);
        this.events.unsubscribe('update_showRowModel_' + this.prefix);
        this.events.unsubscribe('refreshMainModelPage' + this.prefix);
        this.events.unsubscribe('openPage' + this.prefix);
        this.events.unsubscribe('saveAndOpenPage' + this.prefix);
    }

    openBoxPage() {
        let params = {
            'sectionName': this.sectionKey,
            'rowId': this.showRowModelId,
        };
        let navigationExtras: NavigationExtras = {
            queryParams: params
        };
        this.navCtrl.navigateForward('admin-model/' + this.sectionKey + this.showRowModelId, navigationExtras);
    }

    start(baseFilter, rowIndex, rowId) {
        let me = this;
        let filters = {'type': 'moving', 'name': 'taarif'};
        filters['sectionName'] = this.currentSectionKey;

        //need to refresh data?
        if (baseFilter) {
            for (let key in baseFilter) {
                filters[key] = baseFilter[key];
            }
        }

        //////console.log'rowId: ', rowId);

        if (this.showRowModel && !this.forceRefresh) {
            this.adminService.addToHistory({'sectionName': this.currentSectionKey, 'model': this.showRowModel});

            this.showTable = false;
            this.displayType = 'model';
            me.ready = true;
        } else if (rowId) {//will refresh in view-box
            this.showRowModelId = rowId;
            rowIndex = 0;
            me.ready = true;
            me.showLoading = false;
            // me.tableData = me.adminService.manageModels;


            let t = this.adminService.getRowModel();
            //console.logt['id']['value']);
            if (t && t['id']['value'] == rowId && !this.forceRefresh) {
                me.showRowModel = t;
                // me.showRowModel['main']['updated'] = true;
                me.models = [me.showRowModel];
            }
            else {
                // this.showRowModel = [{'id':{'value': this.showRowModelId}}]; //one field with id value
                filters['getId'] = this.showRowModelId;
                me.refresh(filters, baseFilter, null, this.showRowModelId)
            }

            this.showTable = false;
            this.displayType = 'model';

            //from here - view-box will filter for this model - new request to server in view-box.
            //from here - view-box will filter for this model - new request to server in view-box.
            //from here - view-box will filter for this model - new request to server in view-box.

        } else {
            // me.refresh(filters, baseFilter, rowIndex, rowId);
            me.ready = true;
            me.showLoading = false;
            this.adminService.addToHistory({'sectionName': this.currentSectionKey, 'model': null});
        }

        if (this.currentTableData['showCalendar']) {
            this.showCalendar = true;
            this.displayType = 'calendar';
        } else if (!this.displayType) {
            this.displayType = 'table';
        }

        //on click select field - popup go
        this.events.subscribe('selectFieldPopup' + this.prefix, (data) => {
            this.selectFieldCallbackPrefix = data['selectTablePrefix'];
            this.selectFieldData = data['fieldData'];
            this.currentTableData = data['tableData'];
            this.selectFieldData['edited'] = true;
            ////console.logthis.selectFieldData);
            ////console.logthis.currentTableData);

            this.events.subscribe(this.selectFieldCallbackPrefix, (data) => {
                ////console.log'callback1!!@!#@!#!@');
                ////console.logdata);

                this.events.unsubscribe(this.selectFieldCallbackPrefix);

                //all over fields - save chosed field data
                // if (this.selectFieldData['name'] == data['name']) {
                this.selectFieldData['value'] = data.rowModel[0]['value'];//id
                this.selectFieldData['color'] = data.rowModel[0]['color'];//id
                this.selectFieldData['displayName'] = data.rowModel[0]['displayName'] ? data.rowModel[0]['displayName'] : data.rowModel[1]['value'];

                // }
                ////console.logthis.selectFieldData);
                // this.events.publish('closeSelectPopup' + this.prefix);
                //remove popup:
                this.selectFieldData = null;
            });
        });

    }

    refreshById(rowId) {
        let filters = {'type': 'moving', 'name': 'taarif'};
        filters['sectionName'] = this.currentSectionKey;
        filters['getId'] = this.showRowModelId;
        this.refresh(filters, null, null, rowId);
    }

    refresh(filters, baseFilter, rowIndex, rowId) {
        let me = this;

        //////console.logfilters);
        this.ready = false;
        this.modelProvider.list('report', filters)
            .then(function (data: any) {

                //////console.logdata);
                let tableData = data['models'][0].data['finalResReport'];
                for (let key in tableData) {
                    //if (me.currentSectionKey == key) continue;
                    me.tableData[key] = tableData[key];
                }

                if (!me.models) me.models = [];
                for (let model of me.tableData[me.currentSectionKey]) {
                    me.models = model;
                }

                me.currentTableData = tableData[me.currentSectionKey + 'Data'];

                //specific row?
                if (rowIndex === 0 || rowIndex) {
                    me.showRowModel = me.models[rowIndex];
                    me.showRowModel[0]['updated'] = true;
                    me.showRowModelId = me.showRowModel[0]['value'];
                } else if (rowId) {
                    // me.showRowModel = me.adminService.getRowModel();
                    me.showRowModel = me.tableData[me.currentSectionKey][0];
                }
                me.ready = true;
                me.showLoading = false;

                //for footer
                me.events.publish('rowClick', {
                    'sectionName': me.sectionKey,
                    'exTableData': me.tableData,
                    'model': me.showRowModel
                });

            });
    }

    home() {
        this.navCtrl.navigateRoot('taarif');
    }

    back() {
        this.navCtrl.pop();
    }

    showCategory(subIndex = 0, menuIndex = null) {
        return
        // if (menuIndex) {
        //
        // } else {
        //     menuIndex = this.menuIndex;
        // }
        //
        // let params = {
        //     'sectionName': this.tableData['showBoxes'][menuIndex][subIndex],
        //     'menuIndex': this.menuIndex,
        //     'subIndex': subIndex,
        // };
        //
        // let navigationExtras: NavigationExtras = {
        //     queryParams: params
        // };
        //
        // this.navCtrl.navigateForward('admin-model/' + this.tableData['showBoxes'][menuIndex][subIndex], navigationExtras);
    }

    //depricated
    save(ex = null) {
        this.adminService.globalSave();
    }

    showEdit(sectionKey) {
        // if (!this.initializedFull) {
        //     return;
        // }
        //
        // //////console.logsectionKey);
        // if (this.tableData[sectionKey + 'Data'] && this.tableData[sectionKey + 'Data']['type'] == 'calendar') {
        //     this.showCalendar = true;
        // } else if (this.tableData[sectionKey].length > 1) {
        //     // skip -1 show real model data
        //     this.showSpecificRowIndex = 1;
        //     // this.tableData[sectionKey][1][0]['updated'] = true;
        //
        // } else {
        //     //only if it is empty
        //     //show -1 - first row to add
        //     this.showSpecificRowIndex = 0;
        //     this.tableData[sectionKey][0][0]['value'] = null;
        // }
        //
        // this.currentSectionKey = sectionKey;
        // //////console.logthis.tableData);
        //
        // //////console.logthis.tableData[this.currentSectionKey][0][0]['value']);

    }


    //update server it needs to update changes.
    updateData() {
        this.tableData['extraData']['updatedData'] = false;

        this.filters['editedSections'] = [];
        this.filters['sectionName'] = '';
        this.filters['updateData'] = true;
        this.filters['updateModelClasses'] = true;
        this.modelProvider.customPost('saveDataReport', [], this.filters, true).then((data: any) => {
            // this.events.publish('refresh_request_' + this.prefix, this.filters);
        });
    }


    removeSubModel(sectionKey, markType, id) {
        // if (!this.tableData[sectionKey + '_' + markType])
        //     this.tableData[sectionKey + '_' + markType] = [];
        //
        // if (this.tableData[sectionKey + '_' + markType].indexOf(id) == -1) {
        //     this.tableData[sectionKey + '_' + markType].push(id);
        // } else {
        //     this.tableData[sectionKey + '_' + markType].splice(this.tableData[sectionKey + '_' + markType].indexOf(id), 1);
        // }
        // this.editedSections.push(sectionKey);
    }

    removeModel(model, secKey, markTypeKey, id) {

        // //it array by we can set it with key as well..
        // model.removed = !!!model.removed;
        //
        // // for (let key in model) {
        // //     if (model[key]['name'] == 'id')
        // //         model[key]['removed'] = !!!model[key]['removed'];
        // // }
        //
        // this.markSubModel(secKey, 'removed', id);
    }


    markSubModel(seckey, markTypeKey, id) {
        // if (!this.tableData[seckey + "_" + markTypeKey]) {
        //     this.tableData[seckey + "_" + markTypeKey] = [];
        // }
        //
        // if (this.tableData[seckey + "_" + markTypeKey].indexOf(id) !== -1) {
        //     this.tableData[seckey + "_" + markTypeKey].splice(this.tableData[seckey][markTypeKey].indexOf(id), 1);
        // } else {
        //     this.tableData[seckey + "_" + markTypeKey].push(id);
        // }
    }

    addModel(sectionKey, fieldDataName = null) {
        // let newModel = JSON.parse(JSON.stringify(this.tableData[sectionKey][0]));
        //
        // //remove id for new model copied.
        // for (let key in newModel) {
        //     if (newModel[key]['name'] == 'id')
        //         newModel[key]['value'] = null;
        // }
        //
        // this.tableData[sectionKey].unshift(newModel);
        // // this.tableDataFKeys[sectionKey] = this.getValueKeys(sectionKey);
    }


    getImageBacground(imgName) {
        if (this.tableData['showBoxesImagesBase']) {
            return "url('" + this.tableData['showBoxesImagesBase'] + imgName + "')";
        }
        return '';
    }

    customEvent(key) {
        this.navCtrl.navigateForward(key);
    }

    closePopup() {
        // this.qucikEditModelKeys.shift();
        // this.qucikEditModels.shift();
        // this.qucikEditReady = false;
        //
        // if (this.qucikEditModelKeys.length > 0) {
        //     let me = this;
        //     setTimeout(function () {
        //         me.qucikEditReady = true;
        //     });
        // }
    }


    changeOptionsField(fieldData, option) {

        //////console.logoption);
        let val = option['id'];
        let name = option['name'];
        if (!fieldData['optionsActive']) {
            fieldData['optionsActive'] = [];
            fieldData['optionsActiveKeys'] = [];
        }

        fieldData['edited'] = true;

        // if (fieldData['multiple']) {
        // fieldData['optionsActive'].push(val);
        // fieldData['optionsActiveKeys'].push(name);

        // return;
        // }

        if (!fieldData['multiple']) {
            fieldData['optionsActive'] = [val];
            fieldData['optionsActiveKeys'] = [name];
            fieldData['color'] = option['color'];
            fieldData['icon'] = option['icon'];
            fieldData['edited'] = true;
            //////console.logfieldData['optionsActive']);
            this.selectFieldData = null;
            return
        }

        if (fieldData['chooseOneOnly']) {
            fieldData['optionsActive'].splice(0);
        }

        if (fieldData['optionsActive'].indexOf(val) !== -1) {
            fieldData['optionsActive'].splice(fieldData['optionsActive'].indexOf(val), 1);
        } else {
            fieldData['optionsActive'].push(val);
        }
        fieldData['value'] = fieldData['optionsActive'].join(',')

        //////console.logfieldData['optionsActive']);

        if (!fieldData['multiple']) {
            this.selectFieldData = null;
        }
    }


    openAdminModel(sectionName, fieldData = null) {
        ////console.logsectionName);
        ////console.logfieldData);
        let params = {
            'sectionName': sectionName,
        };
        if (fieldData) {
            params['baseFilter'] = fieldData['baseFilter'];
        }
        ////console.logparams);

        let navigationExtras: NavigationExtras = {
            queryParams: params
        };
        this.navCtrl.navigateForward('admin-model/' + sectionName, navigationExtras);
    }

    logScrolling(event) {
        this.events.publish('onScroll', [event.detail.scrollLeft, event.detail.scrollTop, event]);

        this.scrollLeft = event.detail.scrollLeft;
        this.scrollTop = event.detail.scrollTop;
    }

    async presentSelectBar(myEvent, type, isbig = false) {
        const popover = await this.popoverCtrl.create({
            component: SelectBarComponent,
            componentProps: {
                'prefix': this.prefix,
                'model': this.showRowModel,
                'exTableData': this.tableData,
                'sectionName': this.currentSectionKey,
                'type': type
            },
            event: myEvent,
            animated: true,
            showBackdrop: true,
            cssClass: 'note-popover icenter-popover ' + (isbig ? 'bigger-popover' : 'smaller-popover')
        });
        return await popover.present();
    }

    nextPage(e, prefix = null, prev = false) {
        let me = this;
        me.events.publish((prev ? 'prevPage' : 'nextPage') + (prefix ? prefix : me.prefix));
        // this.events.subscribe()
        // setTimeout(function () {
        if (e) e.target.complete();
        // if (this.dataItems.length >= 50) {
        //     event.target.disabled = true;
        // }
        // }, 2000);
    }

    mouseLeftLeave(event) {
        clearTimeout(this.onMoveScrollTimeout);
        console.log('leave!');
    }

    mouseEnter(event, sideLeft = true) {
        let onMoveScrollSide = 0
        if (sideLeft) {
            onMoveScrollSide = -6;
        } else {
            onMoveScrollSide = 6;
        }
        this.onMoveScrollSide = onMoveScrollSide;
        console.log(onMoveScrollSide);

        // if (onMoveScrollSide != this.onMoveScrollSide) {
        let me = this;
        //     if (onMoveScrollSide != 0) {
        this.onMoveScrollTimeout = setInterval(function () {
            me.ionContent.scrollToPoint(me.scrollLeft + me.onMoveScrollSide, 0);
        }, 10);
        // }
        //
    }

    //scroll window buttons
    //on up - will set 0 to scroll left.
    scrollWindow(scrollTo) {
        let me = this;
        me.onScrollPx = me.appConfig.dir == 'rtl' ? me.onScrollPx - scrollTo : me.onScrollPx + scrollTo;

        clearInterval(this.onMoveScrollTimeout);
        this.onMoveScrollTimeout = setInterval(function () {
            if (me.scrollLeft == me.onScrollPx) {
                clearInterval(me.onMoveScrollTimeout);
                return;
            }

            console.log(me.onScrollPx);
            // if (me.onScrollPx == 0) clearInterval(inte);
            //
            if (me.onScrollPx > me.scrollLeft) {
                me.scrollLeft += 10;
            } else {
                me.scrollLeft -= 10;
            }
            console.log(me.scrollLeft)
            me.ionContent.scrollToPoint(me.scrollLeft, 0);
        }, 1);
    }

    setSideFilter(key, data) {
        if (key == 'sideFilter') {
            //side table filters open from pages / relation table - sideFilterTable true
            if (data['sideFilterTable']) {
                this.setSideTable(data['sideFilterTable']);
            } else {
                this.setSideTable(data['name'], data['filterTab']);
            }
        }
    }

    buttonClick(key, data) {
        this.events.publish("btnclick_" + this.myprefix, {
            'key': key,
            'data': data
        });
    }


    changeModelSubSection(key) {
        // if (this.currentSubSectionKey == key) {
        //     this.currentSubSectionKey = null;
        //     return;
        // }
        //
        // let me = this;
        // this.currentSubSectionKey = null;
        // setTimeout(function () {
        //     me.currentSubSectionKey = key;
        // })

        let baseFilter = {
            'searchField': 'modelData__modelName',
            'searchFieldValue': this.currentSectionKey
        }
        this.adminService.popupEditBox(null, key, this.tableData, this.prefix,
            this.showRowModel, 'simpleTable', true, null, null, null, baseFilter, {});

    }

    getAdminData() {
        let me = this;

        if (me.adminData) {
            me.adminData = null;
            return
        }

        let filters = this.adminService.getMainFilters();
        filters['getModelTopReport'] = this.currentSectionKey;
        this.modelProvider.list('report', filters)
            .then(function (data: any) {
                me.adminData = data['models'][0].data['finalResReport']['reportData'];
            });
    }

    openMenu() {
        // this.mainService.closeMenu(menu == 'adminTableFilter')
        // this.menuCtrl.enable(true, 'menuAdmin');
        this.mainService.closeMenu('menuAdmin', true);
    }

    chooseCardDashboard(cardDashboardKey) {
        let me = this;
        me.cardDashboardKey = null;
        setTimeout(function () {
            me.cardDashboardKey = cardDashboardKey;
        });
    }

}
