import {Injectable} from '@angular/core';
import {AlertController, ModalController, NavController, Platform, ToastController} from '@ionic/angular';
import {TranslateService} from "@ngx-translate/core";
import {Router} from "@angular/router";
import {Storage} from "@ionic/storage";
import {AppConfig} from "../../../core/conf/main";
import {Events} from "../events";
import {Location} from "@angular/common";
import { Clipboard } from '@capacitor/clipboard';

@Injectable()
export class ModelToolsProvider {

    constructor(public events: Events,
                public toastCtrl: ToastController,
                public alertController: AlertController,
                public translate: TranslateService,
                public navCtrl: NavController,
                private platform: Platform,
                public storage: Storage,
                public modalController: ModalController,
                private location: Location,
                private router: Router
                // public pageClasses: PageClasses
    ) {
    }

    async closeModal() {
        const popover = await this.modalController.getTop();
        if (popover)
            await popover.dismiss(null);
    }

    goTo(page, params = {}, root = false, is_nav_extars = false) {
        this.closeModal();

        if (page == 'back') {
            this.goBack()
            // this.navCtrl.pop();
            return;
        }

        let navigationExtras = {
            queryParams: params,
            animated: true,
            replaceUrl: is_nav_extars,
        };

        if (page == 'main')
            root = true;

        if (root) {
            // this.navCtrl.navigateRoot(page, navigationExtras)
            this.router.navigate(['/'])
            return;
        }

        // console.log(page, navigationExtras)

        this.router.navigate(['/' + page], navigationExtras);
        // this.navCtrl.navigateForward(page, navigationExtras)
    }

    goBack() {
        // console.log("BACK!")
        // console.log(this.navCtrl)
        // this.navCtrl.back();
        this.location.back()
    }

    async showModal(component, params = {}) {
        const modal = await this.modalController.create({
            component: component,
            componentProps: params,
            // 'enterAnimation': enterAnimation,
            cssClass: 'animated-popover',
        });

        return await modal.present();
    }


    async yesOrNoAlert(callback, tparams = null, title = null, rejectCallback = null) {
        let params = {
            'title': title ? title : this.translate.instant('areYouSure'),
            'no': this.translate.instant('no'),
            'yes': this.translate.instant('yes')
        }
        if (tparams) {
            for (let k in tparams) {
                params[k] = tparams[k];
            }
        }

        console.log(params);

        const alert = await this.alertController.create({
            header: params['title'],
            subHeader: params['subTitle'],
            message: params['message'],
            cssClass: 'topIndex',
            buttons: [
                {
                    text: params['no'],
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: (blah) => {
                        if (rejectCallback) rejectCallback(params)
                    }
                }, {
                    text: params['yes'],
                    handler: () => {
                        callback(params);
                    }
                }
            ]
        });

        await alert.present();
    }

    dismissAlert() {
        this.alertController.dismiss();
    }

    async inputAlert(title, txt = '', callback = null, translate = false, setinputs = null) {
        if (translate) {
            if (title) title = this.translate.instant(title);
            if (txt) txt = this.translate.instant(txt);
        }

        let inputs;
        if (!setinputs) {
            inputs = [
                {
                    name: 'data',
                    type: 'text',
                    placeholder: ''
                },
            ]
        } else {
            inputs = setinputs;
        }

        const alert = await this.alertController.create({
            header: title,
            message: txt,
            inputs: inputs,
            buttons: [{
                text: this.translate.instant('cancel'),
                role: 'cancel',
                cssClass: 'secondary',
                handler: (blah) => {
                }
            }, {
                cssClass: 'button-a',
                text: this.translate.instant('ok'),
                handler: (res) => {
                    if (callback) {
                        if (setinputs) {
                            callback(res);
                        } else {
                            callback(res['data']);
                        }
                    }
                }
            }]
        });

        await alert.present();
    }

    async textAlert(title, txt = '', callback = null, translate = false) {
        if (translate) {
            title = this.translate.instant(title);
            try {
                txt = this.translate.instant(txt);
            } catch (e) {
            }
        }
        const alert = await this.alertController.create({
            header: title,
            message: txt,
            buttons: [{
                text: this.translate.instant('close'),
                handler: () => {
                    if (callback) callback();
                }
            }
            ]
        });

        await alert.present();
    }

    // removeById(id, prefix, alertCtrl = null, tablePrefix = null) {
    //     if (alertCtrl) {
    //         let alert = alertCtrl.create({
    //             title: 'Delete ' + prefix,
    //             buttons: [
    //                 {
    //                     text: 'No',
    //                     role: 'cancel',
    //                     handler: () => {
    //                     }
    //                 },
    //                 {
    //                     text: 'Yes',
    //                     handler: () => {
    //                         return this.modelProvider.removeById(id, prefix, {}, tablePrefix);
    //                     }
    //                 }
    //             ]
    //         });
    //         alert.present();
    //     } else {
    //         return this.modelProvider.removeById(id, prefix, {}, tablePrefix);
    //     }
    // }

    //
    // removeModel(model, alertCtrl = null, tablePrefix = null, title = 'Remove') {
    //     if (!tablePrefix) tablePrefix = model.prefix
    //     if (alertCtrl) {
    //         let alert = alertCtrl.create({
    //             title: title,
    //             buttons: [
    //                 {
    //                     text: 'No',
    //                     role: 'cancel',
    //                     handler: () => {
    //                     }
    //                 },
    //                 {
    //                     text: 'Yes',
    //                     handler: () => {
    //                         return this.removeById(model.data.id, model.prefix, null, tablePrefix);
    //                     }
    //                 }
    //             ]
    //         });
    //         alert.present();
    //     } else
    //         return this.removeById(model.data.id, model.prefix, null, tablePrefix);
    // }

    // async removeModel(model, alertCtrl = null, tablePrefix = null, title = 'Remove') {
    //     if (!tablePrefix) tablePrefix = model.prefix
    //     const alert = await this.alertController.create({
    //         message: title,
    //         buttons: [
    //             {
    //                 text: 'No',
    //                 role: 'cancel',
    //                 cssClass: 'secondary',
    //                 handler: (blah) => {
    //                 }
    //             }, {
    //                 text: 'Yes',
    //                 handler: () => {
    //                     return this.removeById(model.data.id, model.prefix, null, tablePrefix);
    //                 }
    //             }
    //         ]
    //     });
    //
    //     await alert.present();
    // }
    //
    // removeMultiple(prefix, arr = [], tablePrefix = null, alertCtrl = null, title = 'Remove', doObjcetFor = true) {
    //     if (!tablePrefix) tablePrefix = prefix
    //     var ids = []
    //
    //     for (var k = 0; k < arr.length; k++) {
    //         ids.push(arr[k].data.id)
    //     }
    //
    //     if (alertCtrl) {
    //         let alert = alertCtrl.create({
    //             title: title,
    //             buttons: [
    //                 {
    //                     text: 'No',
    //                     role: 'cancel',
    //                     handler: () => {
    //                     }
    //                 },
    //                 {
    //                     text: 'Yes',
    //                     handler: () => {
    //                         return this.modelProvider.removeMultiple(prefix, ids, doObjcetFor);
    //                     }
    //                 }
    //             ]
    //         });
    //         alert.present();
    //     } else
    //         return this.modelProvider.removeMultiple(prefix, ids, doObjcetFor);
    // }

    // updateField(model, key, value, alertCtrl = null, title = 'Are you sure?') {
    //
    //     if (alertCtrl) {
    //         let alert = alertCtrl.create({
    //             title: title,
    //             buttons: [
    //                 {
    //                     text: 'No',
    //                     role: 'cancel',
    //                     handler: () => {
    //                     }
    //                 },
    //                 {
    //                     text: 'Yes',
    //                     handler: () => {
    //                         model.data[key] = value
    //                         return this.update(model);
    //                     }
    //                 }
    //             ]
    //         });
    //         alert.present();
    //     } else {
    //         model.data[key] = value
    //         return this.update(model);
    //     }
    // }

    // update(model, perfix = '') {
    //     return this.modelProvider.update(model, {}, perfix);
    // }
    //
    // createManyToManyModel(modelName, destKey1, destKey2, srcKey1, srcKey2, models1, models2) {
    //     var models = [];
    //     var cls, model;
    //
    //     for (var k1 in models1) {
    //         for (var k2 in models2) {
    //             cls = this.modelClasses.getClass(modelName);
    //             model = new cls({});
    //             model.data[destKey1] = models1[k1].data[srcKey1];
    //             model.data[destKey2] = models2[k2].data[srcKey2];
    //
    //             models.push(model);
    //         }
    //     }
    //
    //     var cls = this.modelClasses.getClass(modelName);
    //     var model = new cls({});
    //     this.modelProvider.createMultiple(model, models);
    // }

    openModelList(navCtrl, modelName, defaultFilters, addFilters, addParams, popoverCtrl, partsDisplay, pages = null, addPageClass = null, fieldsFilters = null, header = true, css: object = {'cssClass': 'xxlg-popover'}, defaultHeaders = null, showCheckBoxes = true, cantEdit = false) {
        var data = {
            'modelName': modelName,
            'defaultFilters': defaultFilters,
            'addFilters': addFilters,
            'addParams': addParams,
            'partsDisplay': partsDisplay,
            'addPageClass': addPageClass,
            'fieldsFilters': fieldsFilters,
            'header': header,
            'defaultHeaders': defaultHeaders,
            'showCheckBoxes': showCheckBoxes,
            'cantEdit': cantEdit,
        }

        if (popoverCtrl) {
            data['header'] = false
            let popover = popoverCtrl.create('ModelGenericPage', data, css);
            popover.present();
        } else {
            navCtrl.push('ModelGenericPage', data);
        }

    }

    public openModel(modelId, model, navCtrl = null) {
        //var params = {
        //    modelId: modelId,
        //    model: model,
        //    modelName: this.modelIns.prefix
        //};
        //params['navCtrl'] = this.navCtrl;
        //
        //if (!this.pageClasses.getPageClass(this.modelIns.prefix)) {
        //    params['pageClass'] = ModelDetailsPage;
        //}
        //else {
        //    params['pageClass'] = this.pageClasses.getPageClass(this.modelIns.prefix);
        //}
        //
        //navCtrl.push(params['pageClass'], params);
    }

    editModel(navCtrl, popoverCtrl, model, addFilters = null, addParams = null, tablePrefix = null, lazy = false, shortHeaders = null, addInRow = true, placeholder = false, addPageClass = null, fields = null) {
        this.addModel(navCtrl, model.prefix, addFilters, addParams, tablePrefix, popoverCtrl, shortHeaders, addInRow, addPageClass, lazy, model, placeholder, null, null, fields)
    }

    addModel(navCtrl, modelName, addFilters, addParams, tablePrefix,
             popoverCtrl, shortHeaders = null, addInRow = true, addPageClass = null,
             lazy = null, _model = null, placeholder = false, pageClasses = null, fieldsFilters = null, fields = null) {
        // var cls = this.modelClass.getModelClass(modelName);
        // var model = new cls(_model ? _model.data : {});
        //
        // if (addInRow && (!model.editType || model.editType == 'inrow')) {
        //     this.events.publish(tablePrefix + '_addInRow', {});
        // } else {
        //     var data = {
        //         'modelName': modelName,
        //         'tablePrefix': tablePrefix,
        //         'addFilters': addFilters,
        //         'addParams': addParams,
        //         'shortHeaders': shortHeaders,
        //         'lazy': lazy,
        //         'model': _model ? model : null,
        //         'placeholder': placeholder,
        //         'fieldsFilters': fieldsFilters,
        //         'fields': fields,
        //     };
        //
        //     //custom edit page?
        //
        //     var pageClass = ModeladdPage;
        //     if (addPageClass) {
        //         if (pageClasses)
        //             pageClass = pageClasses.getPageClass(addPageClass)
        //         else
        //             pageClass = addPageClass;
        //     }
        //
        //     //popup or window?
        //     if (!popoverCtrl || model.editType == 'window') {
        //         navCtrl.push(pageClass, data);
        //     } else {
        //         let popover = popoverCtrl.create(pageClass, data, {'cssClass': 'lg-popover'});
        //         popover.present();
        //     }
        // }
    }

    openMap(navCtrl, model) {
        // var params = {};
        // navCtrl.push(ModelMapPage, params);
    }

    // lazymodel - wont update model on save click.
    public openEditPopup(popoverCtrl, model, fields, lazyModel = null, onEditEventPrefix = null, title = null) {
        // let popover = popoverCtrl.create(FieldEditPage,
        //     {
        //         title: title,
        //         fieldkeys: fields,
        //         model: model,
        //         onEditEventPrefix: onEditEventPrefix,
        //         lazyModel: lazyModel,
        //     });
        // popover.present();
    }


    public openModelDetails(navCtrl, modelPrefix, modelId, model, popoverCtrl = null, shortHeaders = null, page = null) {
        // if (!page) {
        //     page = ModelDetailsPage;
        // }
        //
        // var params = {
        //     modelId: modelId,
        //     model: model,
        //     modelName: modelPrefix,
        //     shortHeaders: shortHeaders,
        // };
        //
        // if (popoverCtrl) {
        //     let popover = popoverCtrl.create(page, params, {'cssClass': 'xxlg-popover'});
        //
        //     let ev = {
        //         target: {
        //             getBoundingClientRect: () => {
        //                 return {
        //                     top: '200'
        //                 };
        //             }
        //         }
        //     };
        //     popover.present({ev});
        // } else {
        //     params['navCtrl'] = navCtrl;
        //     navCtrl.push(page, params);
        // }
    }

    public openMapPage(navCtrl, models) {
        // navCtrl.push(ModelMapPage, {'models': models});
    }

    public editAddressBook(popoverCtrl, addressBook, prefix, ownerModel) {
        // let popover = popoverCtrl.create(AppAddressPage,
        //     {
        //         model: addressBook,
        //         keys: ['zip', 'state', 'city', 'address'],
        //         prefix: prefix,
        //         ownerModel:ownerModel,
        //     });
        // popover.present();
    }
    async capacitorClipboard(text: string) {
        try {
            await Clipboard.write({ string: '' + text });
            console.log('Text copied to clipboard');
        } catch (error) {
            console.error('Copying to clipboard failed:', error);
        }
    }

    clipoard(value) {
        if(this.platform.is('capacitor')){
            this.capacitorClipboard(value)
        }
        else {
            navigator.clipboard.writeText(value);
        }
        this.presentToast(this.getTranslate('copied'))
    }

    copyToCliporad(val) {
        console.log(val)
        let selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = val;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);

        //     showCloseButton: true,
        //     closeButtonText: 'OK',
        let toast = this.toastCtrl.create({
            message: val + " - Copied to clipboard.",
            duration: 3000,
            position: 'bottom',
        });
        // toast.present();
        return;
    }


    listChoose(popoverCtrl, model, fieldkey, fieldData, onEditEventPrefix) {
        // model.dataFields[fieldkey] = fieldData;
        // model.data[fieldkey] = {};
        // model.data[fieldkey + 'Data'] = {};
        //
        // let popover = popoverCtrl.create(FieldEditPage, {
        //     'model': model,
        //     'fieldData': fieldData,
        //     'fieldType': fieldData.type,
        //     'fieldkey': fieldkey,
        //     'onEditEventPrefix': onEditEventPrefix,
        //     'lazyModel': true,
        //     'hideTitle': true,
        // }, {'cssClass': 'lg-popover'});
        // popover.present();
    }

    setKey(key, data, includeVer = true) {
        if (includeVer)
            key += AppConfig.ver;
        // try {
        this.storage.set(key, data);
        // } catch (e) {
        //   //console.log'SET STORAGE DISABLE', key)
        // }
        // localStorage.setItem(key, JSON.stringify(data));
    }

    getKey(key, includeVer = true) {
        if (includeVer)
            key += AppConfig.ver;
        // return this.storage.ready().then((res) => {
        //     // //console.log'#!!', res.ready())
        //     return res.ready((eres) => {
        //         // //console.log"###!!#!##!", key, this.storage.driver)
        //         // if(eres)
        return new Promise((resolve, reject) => {
            //
            //             try {
            this.storage.get(key).then((res) => {
                resolve(res);
            })
                .catch(err => {
                    resolve(null);
                });
            // } catch (e) {
            //   resolve(null);
            // }
        });
        //     });
        // }).catch(err => {
        //
        // });
    }

    getTranslate(key) {
        return this.translate.instant(key);
    }

    getRandomize() {
        return 'rnd_' + Math.floor(Math.random() * Math.floor(1000000))
    }

    async presentToast(str, allowed = true, color = 'dark', buttons = null, translate = false) {
        if (!allowed)
            return;

        if (translate)
            str = this.translate.instant(str);

        const toast = await this.toastCtrl.create({
            message: str,
            duration: 3000,
            color: color,
            buttons: buttons,
            cssClass: 'toast-position-on-footer'
        });
        toast.present();
    }

}
