import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {Panel} from "../../../../framework/model/core/panel/panel";
import {ModelToolsProvider} from "../../../../framework/providers/model-tools/model-tools";
import {AppDataService} from "../../../services/AppDataService";
import {ItemProvider} from "../../../pages/item/providers/item-provider/item-provider.service";
import {MainService} from "../../../services/MainService";
import {OrderService} from "../../../services/OrderService";
import {AppUser} from "../../../../framework/model/core/appuser/appuser";
import {BusinessService} from "../../../services/BusinessService";
import {Events} from "../../../../framework/providers/events";
import {AdminService} from "../../../services/AdminService";

@Component({
    selector: 'app-panel',
    templateUrl: './app-panel.component.html',
    styleUrls: ['./app-panel.component.scss'],
})
export class AppPanelComponent implements OnInit {
    @Input()
    public models: Array<Panel> = [];

    @Input()
    public filters: object = {'ordering': 'order', 'removed': false, 'active': true};

    @Input()
    public key;

    public prefix;

    ready = true;
    public appuser: AppUser;

    constructor(public events: Events, public orderService: OrderService, public adminService: AdminService, public tools:ModelToolsProvider, public appdata:AppDataService) {
    }

    ngOnInit() {
        this.prefix = this.key+'_panels_'+this.tools.getRandomize()
        this.filters['key'] = this.key;
        this.filters['limit'] = 100;

        this.setAppUser()

        this.events.subscribe('appuserChanged', (res) => {
            this.setAppUser()
        }, this.prefix);

        this.events.subscribe('appPriceSets', (res) => {
            this.setFilter('group_id', this.appdata.getPricesListKey('group_id'))
        }, this.prefix);

        this.events.subscribe(this.prefix + '_modelUpdated', (res) => {
            if(this.filters['type'])
                this.models = this.models.filter((x)=> x.data.type == this.filters['type'])
        }, this.prefix);

    }

    setAppUser()
    {
        this.appuser = this.orderService.getAppUser();
        this.setFilter('group_id', this.appdata.getPricesListKey('group_id'))
    }

    setFilter(key, value)
    {
        this.filters[key] = value;
        this.events.publish(this.prefix + '_updateFilter', this.filters)
    }

    refresh() {
        let me = this;
        this.ready = false;
        setTimeout(function () {
            me.ready = true;
        })
    }
}
