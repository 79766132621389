import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {NavController} from "@ionic/angular";
import {NavigationExtras, Router} from "@angular/router";
import {Events} from "../../../../framework/providers/events";
import {ModelToolsProvider} from "../../../../framework/providers/model-tools/model-tools";

@Component({
    selector: 'model-slider',
    templateUrl: './model-slider.component.html',
    styleUrls: ['./model-slider.component.scss'],
})
export class ModelSliderComponent implements OnInit {

    @Input()
    public modelName: string;

    @Input()
    public filters: object = {};

    @Input()
    public options: object = {};

    @Input()
    public slidesPerView: number = 1;

    public models: Array<any> = [];

    @Input()
    public fields: Array<string> = [];

    @Input()
    public background: boolean = false;

    @Input()
    public addParms: Array<string> = [];

    @Input()
    public autoplay: boolean = false;

    @Input()
    public showImages: boolean = true;

    @Input()
    public appDataKey: string;

    @Input()
    public clickEvent: string;

    @Input()
    public view: string = 'cards';

    @Input()
    public style: object = {};

    @Input()
    public prefix = 'modelSlider_' + +Math.floor(Math.random() * Math.floor(1000000));

    public ready: boolean = true;


    constructor(public events: Events, public navCtrl: NavController, public router:Router,
                private changeRef: ChangeDetectorRef, public tools:ModelToolsProvider,
    ) {

    }

    ngOnInit() {
        if (!this.options['prefix'])
            this.options['prefix'] = this.prefix;
        this.options['slidesPerView'] = this.slidesPerView;
        this.options['autoplay'] = this.autoplay;

        this.events.subscribe(this.prefix + '_modelUpdated', (res) => {
            this.changeRef.detectChanges();

            let me = this;
            setTimeout(function () {
                me.ready = true;
            }, 4000)
        })
    }

    ngOnDestroy()
    {
        this.events.unsubscribe(this.prefix + '_modelUpdated')
    }

    ngAfterViewInit() {
        if (this.view != 'scroll')
            return;

        let me = this;
        setTimeout(function () {
            me.clickSegment(me.options['checked']);
        }, 500)

        //
    }

    setModel(model) {
        if (!this.clickEvent) {
            let modelPages = {
                'section': 'section',
                'itemMaker': 'maker',
            }

            let data = {
                'model': model,
            }

            // let navigationExtras: NavigationExtras = {
            //     queryParams: data,
            //     replaceUrl: true
            // };

            let page = modelPages[this.modelName];
            if (this.addParms.length) page += '/';
            for (var k in this.addParms)
                page += model.data[this.addParms[k]];

            this.tools.goTo(page, data, false, true);
            // this.navCtrl.navigateForward(page, navigationExtras);
        } else {
            this.events.publish(this.clickEvent, {model: model})
        }
    }

    clickSegment(id) {
        // document.getElementById(id).scrollIntoView({
        //   behavior: 'smooth',
        //   block: 'nearest',
        //   inline: 'start'
        // });

        var target = document.getElementById(this.options['checked'] + '_' + id);
        var seg = document.getElementById("main-segment_" + this.options['checked']);

        if (target) {
            seg.scrollTo({
                top: 0,
                left: target.offsetLeft,
                behavior: 'smooth'
            });
        }

        // while(seg.scrollLeft != target.offsetLeft)
        // {
        //     seg.scrollLeft+=1;
        // }
        // seg.scrollLeft = target.offsetLeft
    }

    segmentChanged(event) {
        //console.log'event', event)
    }

    scrollNext(key) {
        let k = this.getActiveIndex();
        let ind = parseInt(k) + parseInt(key)

        if (ind < 0 || ind >= this.models.length)
            return;

        this.clickSegment(this.models[ind].data[this.options['checkedField']]);
        this.setModel(this.models[ind])
        // let seg = document.getElementById('main-segment').;
        // //console.logseg)
    }

    getActiveIndex() {
        for (let k in this.models) {
            if (this.models[k].data[this.options['checkedField']] == this.options['checked']) {
                return k;
            }
        }
    }

    scrollToolbar(el) {
        // let value = 1;
        // let offset = 20;
        // // el.addEventListener("mouseon", function( event ) {
        //     console.log(el.clientX, el.offsetLeft, el.offsetWidth)
        //
        //     let mousePos = el.clientX;
        //     if(mousePos < offset)
        //     {
        //         el.scrollLeft -= value;
        //     }
        //     if(mousePos > el.offsetWidth-offset)
        //     {
        //         el.scrollLeft += value;
        //     }
        // }, false);

        // console.log("#####", el.offsetX)

        // const button = document.getElementById('toolbar');

        // el.scrollLeft += value;
        // };
    }
}
