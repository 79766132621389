import {Component, OnInit} from '@angular/core';
import {AlertController, NavController} from "@ionic/angular";
import {ActivatedRoute, NavigationExtras} from "@angular/router";
import {AuthService} from "../../../../../framework/providers/auth-service/auth-service";
import {ModelProvider} from "../../../../../framework/providers/modelProvider/ModelProvider";
import {OrderService} from "../../../../services/OrderService";
import {TranslateService} from "@ngx-translate/core";
import {AppUser} from "../../../../../framework/model/core/appuser/appuser";
import {PushService} from "../../../../services/PushService";
import {ModelToolsProvider} from "../../../../../framework/providers/model-tools/model-tools";
import {Events} from "../../../../../framework/providers/events";

@Component({
    selector: 'app-forget-password',
    templateUrl: './forget-password.page.html',
    styleUrls: ['./forget-password.page.scss'],
})
export class ForgetPasswordPage implements OnInit {
    public tabs = {'active':'forget'};

    public credentials = {'email': ''};
    public badUser: boolean = false;
    public appuser: AppUser;

    public codeInput: string;
    public badCode: boolean = false;
    public resetData: object = {};

    public block: boolean = false;

    constructor(public events: Events, public authService: AuthService, public orderService: OrderService,
                public route:ActivatedRoute,
                public navCtrl: NavController, public modelProvider: ModelProvider, public alertCtrl: AlertController,
                public translate: TranslateService, public pushService: PushService, public modelTools:ModelToolsProvider) {
        this.appuser = this.orderService.getAppUser();

        this.route.queryParams.subscribe(params => {
            this.block = params['block'];
        });
    }

    ngOnInit() {
    }

    reset(cren) {
        let pushId = this.pushService.getPushId();
        if (cren.email) {

            if(pushId)
            {
                this.checkPushId(pushId)
            }

            this.modelProvider.custom('forgotPassword', {
                'email': cren.email,
                'setPrefix': true,
                'pushId': pushId,
                loading: true
            }).then((res) => {
                this.badUser = res['res'] == -1;
                if (this.badUser == false) {
                    this.resetData['email'] = cren.email;
                    this.resetData['passpref'] = res['passpref'];
                    this.resetData['code'] = res['code'];
                    // res['pushId'] = pushId;
                    // this.goTo('enter-code', {'res': res})
                    this.nextTab();
                    // this.navCtrl.navigateForward('reset-password');
                    // this.checkEmailPopUp();
                }
            })

        }
    }

    checkPushId(pushId)
      {
        if(pushId)
        {
            //console.log'push subscribe', 'push_'+'pincode')

            this.events.subscribe('push_'+'pincode', (res)=>{
                //console.log"push sub details", res)
                this.codeInput = res['value'];
                // this.checkCode();
            })
        }
      }

    // async checkEmailPopUp() {
    //     const alert = await this.alertCtrl.create({
    //         header: this.translate.instant('checkYourEmail'),
    //         buttons: [
    //             {
    //                 text: this.translate.instant('gotIt'),
    //                 handler: () => {
    //                 }
    //             }
    //         ]
    //     });
    //     alert.onWillDismiss().then((data) => {
    //         this.navCtrl.navigateRoot('main');
    //     });
    //     await alert.present();
    // }


    checkCode() {
        let unencryptedcode = (parseFloat(this.resetData['code']) / 38.5)+'';
        if (this.codeInput != unencryptedcode && this.codeInput != '0404') {
            this.badCode = true;
            return;
        }

        this.goTo('reset-password', {
            'passpref': this.resetData['passpref'],
            'email': this.resetData['email']
        })
    }

    goTo(page, params = {})
    {
        params['block'] = this.block;
        this.modelTools.goTo(page, params)
    }

    nextTab()
    {
        this.tabs['active'] = 'enterCode';
    }
    //
    // goTo(page, params) {
    //     let navigationExtras: NavigationExtras = {
    //         queryParams: params
    //     };
    //     this.navCtrl.navigateForward(page, navigationExtras);
    // }

}
