// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-avatar {
  --border-radius: 0;
  width: auto;
  height: 25px;
  margin: 10px 0;
}

ion-card {
  border-radius: 5px;
  border: 1px solid #e3e3e2;
  margin: 0;
}

.delivery_time {
  --background: initial;
}`, "",{"version":3,"sources":["webpack://./src/core/components/data-value/data-value.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,WAAA;EACA,YAAA;EACA,cAAA;AACF;;AAEA;EACE,kBAAA;EACA,yBAAA;EACA,SAAA;AACF;;AAEA;EAEE,qBAAA;AAAF","sourcesContent":["ion-avatar{\n  --border-radius: 0;\n  width: auto;\n  height: 25px;\n  margin: 10px 0;\n}\n\nion-card{\n  border-radius: 5px;\n  border: 1px solid #e3e3e2;\n  margin: 0;\n}\n\n.delivery_time{\n  //background: #0b3e6f;\n  --background: initial;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
