import {Component, Input, OnInit} from '@angular/core';
import {AdminService} from "../../../../../core/services/AdminService";
import {NavController} from "@ionic/angular";
import {BusinessService} from "../../../../../core/services/BusinessService";
import {Events} from "../../../../../framework/providers/events";

@Component({
    selector: 'app-minibox',
    templateUrl: './minibox.component.html',
    styleUrls: ['./minibox.component.scss'],
})
export class MiniboxComponent implements OnInit {
    @Input()
    tableRows;
    @Input()
    initRefresh = false;
    @Input()
    hideTags = false;
    @Input()
    exTableData;
    @Input()
    exFieldData;
    @Input()
    sectionName;
    @Input()
    prefix;
    @Input()
    tableParams = {};
    @Input()
    model;

    secTimerOn = false;

    //for display index
    @Input()
    rowIndex;

    @Input()
    hideHeader = false;

    @Input()
    isEditBox = false;
    @Input()
    showOpen = false;
    showOpenButtons = false;
    @Input()
    hideTitle = false;

    //extra data and genric models buttons
    @Input()
    boxSmall = true;

    @Input()
    showEditFields = false;
    editFields;

    @Input()
    openOnSide = false;

    @Input()
    specificBoxFields;
    @Input()
    displayOptions = ['openExpand', 'openTableWindow', 'openExtrental'];
    @Input()
    avoidClick = false;
    @Input()
    selectTablePrefix;
    @Input()
    forceNewWindow = false;

    //need to open model view on click?
    @Input()
    clickEvent: boolean = true;

    tableData;
    modelType;
    subTableOpen;
    modelTypeByName;

    constructor(public adminService: AdminService, public navCtrl: NavController,
                public events: Events,
                public businessService: BusinessService) {
    }

    ngOnInit() {
        this.tableData = this.exTableData[this.sectionName + 'Data'];
        this.modelType = this.exTableData[this.sectionName + 'Ref'];
        this.modelTypeByName = this.adminService.getFieldsType(this.sectionName, this.exTableData);

        if (!this.editFields) {
            this.editFields = [];
            for (let i in this.modelType) {
                if (this.specificBoxFields) {
                    if (this.specificBoxFields.includes(this.modelType[i]['name'])) {
                        if (this.editFields.indexOf(this.modelType[i]) == -1 && this.modelType[i]['name']) {
                            this.editFields.push(this.modelType[i]);
                        }
                    }
                } else if (this.modelType[i]['showInBox']) {
                    //no hides defined or not in hidden fields
                    if (this.editFields.indexOf(this.modelType[i]) == -1 && this.modelType[i]['name']) {
                        this.editFields.push(this.modelType[i]);
                    }
                }
                // if (this.editFields.length > 5) break;
            }

            // if (!this.specificBoxFields) {
            //     if (this.editFields.length < 5) {
            //         for (let i in this.modelType) {
            //             if (this.modelType[i]['showTable']) {
            //                 if (this.editFields.indexOf(this.modelType[i]) == -1) {
            //                     this.editFields.push(this.modelType[i]);
            //                 }
            //             }
            //             if (this.editFields.length >= 5) {
            //                 return
            //             }
            //         }
            //     }
            // }
        } else {
        }

        this.tableParams['modelPrefix'] = this.sectionName;
    }

    setUpIndex() {
        let me = this;
        this.model['index']['value'] = this.model['index']['value'] + 1;
        this.adminService.saveField(this.sectionName, this.model, this.model['index'], null, null, 'index');

        //show index lable
        me.secTimerOn = true;
        setTimeout(function () {
            me.secTimerOn = false;
        }, 3000);
    }

    //, this.model['id']['value']
    onSelectEvent(event, resize = false) {


        // if (this.displayOptions.includes('openExpand')) {
        //     this.showOpen = !!!this.showOpen;
        //     this.model['main']['openBox'] = !!!this.model['main']['openBox'];
        // }
        this.showOpenButtons = !!!this.showOpenButtons;
    }

    onClick(event, resize = false) {
        if (resize) {
            let scWidth = this.businessService.screenSize[0];

            if (this.clickEvent || scWidth < 600) {
                // if (this.tableData['fullWindow']) {
                // this.adminService.openCustomModel(this.navCtrl, this.sectionName, null, null, null, this.model);
                // } else {
                this.adminService.openCustomModel(this.navCtrl, this.prefix, this.exTableData, this.sectionName,
                    null, null,
                    null, this.model, null,
                    true, {}, this.exTableData[this.sectionName + 'Data']['smallerPopup'] ? false : true, event)
                // this.adminService.popupEditBox(event, this.sectionName, this.exTableData, 'dadad',
                //     this.model, 'modelTableEdit', this.exTableData[this.sectionName + 'Data']['smallerPopup'] ? false : true, null, null);
                //     event.stopPropagation();
                // }
            } else {
                this.showOpen = !!!this.showOpen;
            }
        } else {
            this.showOpen = !!!this.showOpen;
        }
    }

    showOnMap(model) {

    }

    //onClick($event, true);..
    clickRow(event = null) {
        if (this.avoidClick) {
            console.log('clickEvent disabled.')
            return;
        }
        if (event) event.stopPropagation();

        if (!this.selectTablePrefix) {
            if (this.businessService.screenSize[0] < 1000) {
                this.adminService.goToAdmin(this.navCtrl, this.sectionName, this.model,
                    this.model.id.value, null, null, this.initRefresh);
            } else if (this.openOnSide) {
                this.adminService.openLeftModelBox(this.sectionName, this.model, this.model.id.value, this.prefix, null, true);
            } else {
                //
                // this.adminService.popupEditBoxNav(null, this.sectionName, this.exTableData, this.prefix, this.model, this.navCtrl);
                this.adminService.popupEditBox(null, this.sectionName, this.exTableData, this.prefix, this.model);
            }
        } else {

            this.events.publish(this.selectTablePrefix['event'], {
                'rowModel': this.model,
                'name': this.selectTablePrefix['name']
            });
            this.checkRow();
            // this.onSelectEvent(event);
            // this.events.publish('btnclick_' + this.prefix, {
            //     'key': 'clickRow',
            //     'data': {
            //         'rowModel': rowModel,
            //         'rowIndex': rowIndex,
            //         'forceEdit': forceEdit,
            //         'forceBigPopup': forceBigPopup,
            //         'event': event
            //     }
            // })
        }

    }

    checkRow() {
        // this.model['main']['isMarked'] = !!!this.model['main']['isMarked']; // in table component!
        this.events.publish('btnclick_' + this.prefix, {'key': 'checkRow', 'data': {'model': this.model}});
    }

    openWindow(event) {
        if (this.avoidClick) return;

        event.stopPropagation();
        if (this.displayOptions.includes('openExpand')) {
            this.showOpen = !!!this.showOpen;
            this.model['main']['openBox'] = !!!this.model['main']['openBox'];
        } else if (this.businessService.screenSize[0] < 1300) {
            this.adminService.popupEditBoxNav(null, this.sectionName, this.exTableData, this.prefix, this.model, this.navCtrl);
        } else {
            this.model['main']['openBox'] = !!!this.model['main']['openBox'];
            this.clickRow();
        }
    }

    openPageAction() {
        let fieldType = this.adminService.getFieldsType(this.sectionName, this.exTableData)[this.tableData['filterField']];
        let me = this;
        let filter = {};
        filter['baseFilter'] = JSON.stringify({
            'searchField': fieldType['filterkey'],
            'searchFieldValue': this.model[this.tableData['filterField']]['value']
        });
        filter['clear'] = true;
        filter['extradata'] = fieldType['extradata'];
        this.events.publish('refresh_request_' + this.prefix, filter);
        return;
    }

    activeSubTable(field) {
        let me = this;
        if (field == me.subTableOpen) {
            me.subTableOpen = false;
            me.model[field['name']]['showOpen'] = false;
        } else {
            if (me.subTableOpen) {
                me.model[me.subTableOpen['name']]['showOpen'] = false;
            }
            me.subTableOpen = null;
            me.model[field['name']]['showOpen'] = false;
            setTimeout(function () {
                me.subTableOpen = field;
                me.model[field['name']]['showOpen'] = !!!me.model[field['name']]['showOpen'];
            })
        }
    }


}
