// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.model-box {
  margin: 0;
  width: 100%;
}
.model-box .img-box {
  width: 100%;
  vertical-align: middle;
  display: table;
}
.model-box .img-box app-img {
  vertical-align: middle;
  display: table-cell;
  width: 100%;
}

.title-box {
  position: absolute;
  color: #fff;
  z-index: 25;
  bottom: 0;
  width: 100%;
  text-shadow: 1px 1px 3px #000;
  padding: 0 35px;
  /* font-size: 29px; */
  font-size: 6vw;
  padding-bottom: 10px;
  background-color: rgba(0, 0, 0, 0.4);
}

@media screen and (min-width: 700px) {
  .title-box {
    font-size: 30px;
  }
}
.main-box {
  padding: 5px;
  position: relative;
}

.hide-button {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 50;
}`, "",{"version":3,"sources":["webpack://./src/core/components/sliders/adv-slider/adv-slider.component.scss"],"names":[],"mappings":"AAAA;EACE,SAAA;EACA,WAAA;AACF;AAEE;EACE,WAAA;EAGA,sBAAA;EACA,cAAA;AAFJ;AAKE;EACE,sBAAA;EACA,mBAAA;EACA,WAAA;AAHJ;;AAOA;EACE,kBAAA;EACA,WAAA;EACA,WAAA;EACA,SAAA;EACA,WAAA;EACA,6BAAA;EACA,eAAA;EACA,qBAAA;EACA,cAAA;EACA,oBAAA;EACA,oCAAA;AAJF;;AAOA;EACE;IACE,eAAA;EAJF;AACF;AAOA;EACE,YAAA;EACA,kBAAA;AALF;;AAuBA;EACE,kBAAA;EACA,OAAA;EACA,MAAA;EACA,WAAA;AApBF","sourcesContent":[".model-box {\n  margin: 0;\n  width: 100%;\n  //background: #fff;\n\n  .img-box {\n    width: 100%;\n    //height: 90px;\n    //max-height: 90px;\n    vertical-align: middle;\n    display: table;\n  }\n\n  .img-box app-img {\n    vertical-align: middle;\n    display: table-cell;\n    width: 100%;\n  }\n}\n\n.title-box {\n  position: absolute;\n  color: #fff;\n  z-index: 25;\n  bottom: 0;\n  width: 100%;\n  text-shadow: 1px 1px 3px #000;\n  padding: 0 35px;\n  /* font-size: 29px; */\n  font-size: 6vw;\n  padding-bottom: 10px;\n  background-color: rgba(0, 0, 0, 0.4);\n}\n\n@media screen and (min-width: 700px) {\n  .title-box {\n    font-size: 30px;\n  }\n}\n\n.main-box {\n  padding: 5px;\n  position: relative;\n}\n\n//.anim-height-hidden {\n//  -webkit-transition: height 0.5s ease-in-out;\n//  -moz-transition: height 0.5s ease-in-out;\n//  -ms-transition: height 0.5s ease-in-out;\n//  -o-transition: height 0.5s ease-in-out;\n//  transition: height 0.5s ease-in-out;\n//}\n//\n//.anim-height-hidden.hidden {\n//  height: 0;\n//  overflow: hidden;\n//  padding-top: 0;\n//  padding-bottom: 0;\n//}\n\n.hide-button {\n  position: absolute;\n  left: 0;\n  top: 0;\n  z-index: 50;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
