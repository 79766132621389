import { Component, OnInit } from '@angular/core';

export class FilterField {
    data = {
        'filter':null,
        'filterType':null,
        'value':null,
        'fieldName':null,
    };


    constructor(public data1:any) {
        this.data = data1;
    }

    frameworkModel = true;
    prefix = "filterField";
    title = "FilterField";

    showDefaultsCols = true;
    defaultHeaders = ['fieldName', 'value', 'filterType', 'filter'];

    dataFields = {
        "created": {'value': null, 'type': 'date'},

        "fieldName": {'value': null, 'type': 'text'},
        "subFilterValue": {'value': null, 'type': 'textarea'},


        "value": {'value': null, 'type': 'text'},
        "fromNumber": {'value': null, 'type': 'number'},
        "toNumber": {'value': null, 'type': 'number'},

        "fromDate": {'value': null, 'type': 'date'},
        "toDate": {'value': null, 'type': 'date'},

        "filterType": {
            'title': 'type', 'value': null, 'type': 'optionsscroll',
            'optionsscroll': 'FILTER_TYPES'
        },

        //only numbers related
        "filterTypeNumber": {
            'title': 'type', 'value': null, 'type': 'optionsscroll',
            'optionsscroll': 'FILTER_TYPES_NUBMER'
        },

        // modelKeys - change modelKeys dynamicly
        "forigenKey": {'title': 'foreignKey', 'value': null, 'type': 'optionsscroll', 'modelKeys': 'lead'},

        //other table avg/sum ...
        "filterMethod": {
            'title': 'Method', 'value': null, 'type': 'optionsscroll',
            'optionsscroll': 'DATA_METHODS'
        },

        "lastDays": {
            'title': 'From Now', 'value': null, 'type': 'optionsscroll',
            'optionsscroll': 'LAST_DAYS_OPTION'
        },

        "filter": {
            'title': 'Filter', 'displayField': 'filtername', 'value': null, 'type': 'modelscroll',
            'modelscroll': 'Filter', 'keyField': 'id', 'valueField': 'name'
        },

        "extFilter": {
            'title': 'Filter', 'displayField': 'extFilterFiltername', 'value': null, 'type': 'modelscroll',
            'modelscroll': 'Filter', 'keyField': 'id', 'valueField': 'name'
        },

        "active":{'value': null, 'type': 'boolean'},
    }
}
