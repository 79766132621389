import {Injectable} from '@angular/core';

import {Item} from "../../framework/model/core/items/item";
import {CartModel} from "../../framework/model/core/items/cartModel";
import {ModelProvider} from "../../framework/providers/modelProvider/ModelProvider";
import {BusinessService} from "./BusinessService";
import {AppConfig} from "../conf/main";
import {ModelToolsProvider} from "../../framework/providers/model-tools/model-tools";
import {AppDataService} from "./AppDataService";
import {Events} from "../../framework/providers/events";

const CartData = {
    'curTicket': null,
    'tickets': [],
    'coupons': {
        'checked':{},
        'checkedData':{}
    },

    'discount': 0,

    'units': 0,
    'amount': 0,

    'charge': 0,
    'shippingPrice': 0,
    'vat': 0,
    'priceAfterDiscount': 0,
    'totalCharge': 0,

    'action': null,

    'pickup': false,

    'cartUpdated': true,
    'cartUpdatedProgress': false,
    'cart-calc-prefix': null,
}


@Injectable()
export class CartService {

    public cart = {}
    public cartData = CartData;

    public cartCalcData = {};

    public business;

    constructor(public modelProvider: ModelProvider,
                public businessService: BusinessService,
                public appDataService:AppDataService,
                public appevents:Events,
                public tools:ModelToolsProvider) {
    }

    start()
    {
        this.getCartFromStorage();

        this.business = this.businessService.getBusiness();
        this.cartData['tickets_allowed'] = this.business.data.GlobalSettingsData.tickets;
    }

    addToCart(ticketId:any = 1, item: Item, cartModel: CartModel = null, options = {}) {
        let ticket = this.getTicket(ticketId ? ticketId : this.cartData['curTicket']);

        if (!cartModel) cartModel = item.cart_model;
        let cart_prefix = cartModel.data['prefix'];
        if (this.isInCart(ticket, cartModel)) {
            this.updateItemTickets(item, cartModel, ticket['id']);
            this.updateCart();
            // this.addedToast(options, cartModel);
            return;
        }

        ticket['models'][cart_prefix] = {'item': item, 'cartModel': cartModel};
        ticket['modelKeys'].push(cart_prefix);

        if(!options['skip_amount']) {
            ticket['data']['amount']++;
            this.cartData['amount']++;
        }

        this.updateItemTickets(item, cartModel, ticket['id'])

        this.updateCart();
        // this.addedToast(options, cartModel)
    }

    addedToast(options, cartModel)
    {
        if (!options['disable-toast']) {
            let buttons = [
                {
                    side: 'end',
                    icon: 'cart',
                    text: cartModel.getValue('amount'),
                    handler: () => {
                        this.tools.goTo('cart');
                    }
                }
            ]
            // //console.log(buttons)
            this.modelProvider.presentToast('addedToCart', true, 'success', buttons, true, null, 'bottom', 600);
        }
    }

    removeFromCart(ticketId, cartModel, model:Item=null) {
        let ticket = this.getTicket(ticketId);
        if (!this.isInCart(ticket, cartModel)) return;

        let cart_prefix = cartModel.data['prefix'];
        let index = ticket['modelKeys'].indexOf(cart_prefix);

        delete ticket['models'][cart_prefix];
        ticket['modelKeys'].splice(index, 1)

        ticket['data']['amount']--;
        this.cartData['amount']--;

        if(model)
        {
            model.cart_tickets_models['count']--;
            var t_index = model.cart_tickets_models['tickets'][ticket['prefix']].indexOf(cartModel.data.prefix);
            if (index !== -1) {
                model.cart_tickets_models['tickets'][ticket['prefix']].splice(t_index, 1);
            }
        }

        this.updateCart();
    }


    updateItemTickets(model, cart_model, ticketId = null)
    {
        let cart_prefix = cart_model.data['prefix']
        if(!ticketId) ticketId = this.getCurTicket();
        let cur_ticket = this.getTicket(ticketId);

        if (!model.cart_tickets_models['tickets'][cur_ticket['prefix']]) {
            model.cart_tickets_models['tickets'][cur_ticket['prefix']] = [];
        }
        if (!model.cart_tickets_models['tickets'][cur_ticket['prefix']].includes(cart_prefix)) {
            model.cart_tickets_models['tickets'][cur_ticket['prefix']].push(cart_prefix);
            model.cart_tickets_models['count']++;
        }
    }

    updateCart(params = {'checkout':false}) {
        this.cartData['cartUpdated'] = false;
        this.saveCartOnStorage();
        this.getCartCalc(false, params);
    }

    getCartCalc(init = false, params = {'checkout':false}) {
        if (this.cartData['cartUpdated'])
            return;

        if(!params['checkout']) params['checkout'] = false;

        let timer = 2000;
        let me = this;

        this.setCartCalcPrefix();
        clearInterval(this.cartCalcData['interval']);
        this.cartData['cartUpdatedProgress'] = true;

        this.cartCalcData['interval'] = setInterval(function () {
            me.setCartCalcPrefix();
            me.doCartCalc(init, params);
            clearInterval(me.cartCalcData['interval'])
        }, timer)
    }

    setCartCalcPrefix() {
        this.cartData['cart-calc-prefix'] = 'cartcalc_' + Math.floor(Math.random() * Math.floor(1000000));
    }

    doCartCalc(init = false, params = {}) {
        //console.log("NEW CART CALC", this.cartData['cart-calc-prefix'])

        let filters = {
            'cartId': this.cartData['cartId'],
            'action': this.cartData['action'],
            'cart_prefix': this.cartData['cart-calc-prefix'],
            'ver': 2,
        }

        for(let key in params)
        {
            let value = params[key]
            if (value === true) value = '1';
            if (value === false) value = '0';
            filters[key] = value;
        }

        console.log(params, filters)

        // this.cartData['cartUpdatedProgress'] = true;
        this.modelProvider.customPost('cartcalc', {'cart': this.cart, 'cart_data': this.cartData},
            filters).then((res) => {

            let data = res['data'];

            let cart_data = data['cart_data'];

            if (cart_data['cart_prefix'] && cart_data['cart_prefix'] != this.cartData['cart-calc-prefix']) return;
            //console.log('CART CALC IS BACK!!!!', cart_data['cart_prefix'], this.cartData['cart-calc-prefix']);

            this.cartData['cartUpdated'] = true;
            this.cartData['cartUpdatedProgress'] = false;
            this.cartData['units'] = 0;

            this.updateData(data);

            this.appevents.publish('cartCalcUpdated', data);
            this.appevents.publish('cartUpdated', data);

            // if (!init)
            //     this.businessService.checkAppVersion(this.cartData['data_version']);

        }, (res) => {
            //console.log"REGECTEDD!!")
        });
    }

    updateData(res)
    {
        let cart_data = res['cart_data'];
        for(let k in cart_data)
        {
            this.cartData[k] = cart_data[k];
        }

        let cart = res['cart']
        for(let t in cart)
        {
            for(let key in cart[t])
            {
                if(!this.cart[t])
                {
                    this.getTicket(t)
                    this.setActiveTicket(t)
                    // this.menuCtrl.close();

                }

                if(key == 'ticket_data')
                {
                    for(let k in cart[t][key])
                    {
                        this.cart[t]['data'][k] = cart[t][key][k]
                    }
                    continue
                }

                let model_data = this.cart[t]['models'][key];
                if(!model_data)
                {
                    let item = this.appDataService.getItemModel(cart[t][key]['item_id'])
                    if(item) {
                        model_data = {'cartModel': item.cart_model}
                        item.cart_model.setValues(cart[t][key]);
                        this.addToCart(t, item, item.cart_model, {'skip_amount':true})
                        continue
                    }
                }

                // this.appDataService.getItemModel()
                if(!model_data) continue
                let cart_model = model_data['cartModel']
                cart_model.updateValues(cart[t][key]);
            }
        }

        this.saveCartOnStorage();
    }

    saveCartOnStorage() {
        let data = {'cart': this.cart, 'cartData': this.cartData};
        this.tools.setKey('storageCart_' + AppConfig.bisPrefix, data);
    }

    getCartFromStorage(init = false) {
        this.tools.getKey('storageCart_' + AppConfig.bisPrefix).then((res) => {
            this.loadCartFromStorage(res);
        });
    }

    loadCartFromStorage(data){
        if(!data) return
        let storageCart = data['cart']
        let storageCartData = data['cartData']
        let inited_items = {};

        for(let t in storageCart)
        {
            this.getTicket(t, storageCart[t]['prefix']);
            if(!this.cartData['curTicket'])
                this.setActiveTicket(t);

            for(let key of storageCart[t]['modelKeys'].reverse())
            {
                let model_data = storageCart[t]['models'][key];
                let itemId = model_data['item'].data.id;
                let item = this.appDataService.getItemModel(itemId);
                let cartModel = new CartModel(item, [], model_data['cartModel'].data);
                let values = cartModel.getValues()

                if(!inited_items[itemId])
                {
                    cartModel = item.cart_model;
                    inited_items[itemId] = true;
                }

                cartModel.setValues(values);
                cartModel.updateModel();

                this.addToCart(t, item, cartModel, {'disable-toast':true});
            }
        }

        this.cartData['db_cart'] = storageCartData['db_cart']
    }

    createOrder(order)
    {
        this.modelProvider.customPost('createOrder', {'cart':this.cart, 'cart_data':this.cartData, 'order':order},
            {
                'cartId': this.cartData['cartId'],
                'action': this.cartData['action'],
                'cart_prefix': this.cartData['cart-calc-prefix'],
                'ver': 2,
            }).then((res) => {
                //console.log('CREATEDD!!!', res)
        });
    }

    isInCart(ticket, cartModel) {
        let cart_prefix = cartModel.data['prefix'];
        return ticket['modelKeys'].includes(cart_prefix)
    }

    getTicket(ticketId, prefix = null) {
        if(!ticketId) ticketId = 'Ticket '+(this.cartData['tickets'].length+1);
        if (this.cart[ticketId]) return this.cart[ticketId];
        this.cart[ticketId] = {
            'id': ticketId,
            'name': ticketId,
            'models': {},
            'modelKeys': [],
            'prefix': prefix ? prefix : this.tools.getRandomize(),

            'data': {'amount': 0, 'totalPrice': 0}
        }
        this.cartData['tickets'].push(ticketId);

        if(!this.cartData['curTicket'])
            this.setActiveTicket(ticketId);

        return this.cart[ticketId];
    }

    removeTicket(ticketId)
    {
        let ticket = this.getTicket(ticketId)
        //console.log(ticket);

        for(let k in ticket['models'])
        {
            this.removeFromCart(ticketId, ticket['models'][k]['cartModel'], ticket['models'][k]['model']);
        }

        delete this.cart[ticketId]
        let index = this.cartData['tickets'].indexOf(ticketId);
        this.cartData['tickets'].splice(index, 1);

        //console.log(this.cart)
        //console.log(this.cartData)
    }

    setTicketName(id, name) {
        //console.log(this.cart)
        this.cart[id]['name'] = name;
        this.updateCart();
    }


    getCurTicket()
    {
        let curTicket = this.cartData['curTicket'];
        if(curTicket == -1) curTicket = this.cartData['tickets'][0]
        return curTicket
    }

    setActiveTicket(ticket = null) {
        this.cartData['curTicket'] = ticket;

        if(!this.cartData['tickets_allowed'])
            this.cartData['activeTicket'] = ticket;

        //console.log(this.cart[ticket])

        if(ticket == -1) return
        this.appevents.publish('activeTicketChanged', {'ticket_id':ticket, 'prefix':this.cart[ticket]['prefix']});
    }

    clearCart() {
        for(let t in this.cart)
        {
            for(let m in this.cart[t]['models'])
            {
                let cartModel = this.cart[t]['models'][m]['cartModel'];
                cartModel.setValue('amount', 0);
                cartModel.updateModel();
                this.removeFromCart(t, cartModel, this.cart[t]['models'][m]['item']);
            }
        }

        this.cartData['coupons'] = {
            'checked':{},
            'checkedData':{}
        }

        this.updateCart();
    }

    isEmpty()
    {
        return !(this.cartData['amount'] > 0);
    }

    getCart() {
        return this.cart;
    }

    getCartData() {
        return this.cartData;
    }
}