import {Component, Input, OnInit} from '@angular/core';
import {AdminService} from "../../../../../core/services/AdminService";
import {NavigationCancel, NavigationExtras} from "@angular/router";
import {BusinessService} from "../../../../../core/services/BusinessService";
import {NavController} from "@ionic/angular";

@Component({
    selector: 'app-admin-menu',
    templateUrl: './admin-menu.component.html',
    styleUrls: ['./admin-menu.component.scss'],
})
export class AdminMenuComponent implements OnInit {

    @Input()
    openMenuKey;
    @Input()
    forceNewWindow = false;
    @Input()
    popupCLick = false;

    @Input()
    prefix;

    @Input()
    calendarModelsObj;

    @Input()
    exTableData;

    menu = {};

    constructor(public adminService: AdminService,
                public navCtrl: NavController,
                public businessService: BusinessService) {
    }

    ngOnInit() {
        this.calendarModelsObj = this.adminService.manageModels['calendarModelsObj'];
        if (!this.exTableData) {
            this.exTableData = this.adminService.manageModels;
        }

        if (this.openMenuKey)
        {
            this.menu[this.openMenuKey] = true;
        }
    }

    rowClick(sectionName, openBig = false, menuIndex = null) {
        // if (this.businessService.screenSize[0] < 1000 || openBig || this.forceNewWindow) {
            let params = {
                'openMenu': true,
                'sectionName': sectionName,
                'openMenuKey': menuIndex
            };
            let navigationExtras: NavigationExtras = {
                queryParams: params
            };
            this.navCtrl.navigateForward('admin-model/' + sectionName, navigationExtras);
        // } else {
        //     this.adminService.popupModelTable(sectionName, this.exTableData, this.prefix);
        // }
    }

    menuClick(event, menu, menuKey, i, openPopup = false) {
        if (!openPopup && !this.popupCLick) {
            menu[menuKey] = !!!menu[menuKey];
        } else {
            this.adminService.popupEditBox(event, '', this.exTableData, this.prefix,
                null, 'adminMenu', true,
                null, null, null, null,
                null, {
                    'menuData': this.adminService.manageModels['showBoxes'][(i)],
                    'title': this.adminService.manageModels['showBoxesTitles'][i],
                    'activeMenu': menuKey,
                })
        }
    }
}
