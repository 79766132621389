// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-item.none-option ion-label {
  padding: 0 7px;
}`, "",{"version":3,"sources":["webpack://./src/core/pages/admin/pages/sections-manager/pages/sections-list/sections-list.page.scss"],"names":[],"mappings":"AACE;EACE,cAAA;AAAJ","sourcesContent":["ion-item.none-option {\n  ion-label {\n    padding: 0 7px;\n  }\n\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
