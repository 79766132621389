import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';

import {Options} from "ngx-google-places-autocomplete/objects/options/options";
import {Events} from "../../../../../framework/providers/events";
import {ServerService} from "../../../../../framework/providers/server/server";
import {ModelProvider} from "../../../../../framework/providers/modelProvider/ModelProvider";
import {AppConfig} from "../../../../../core/conf/main";
import {AdminService} from "../../../../../core/services/AdminService";

declare var google;

// import { GeocoderResult, Geocoder } from '@capacitor/google-maps';
@Component({
    selector: 'app-pick-address',
    templateUrl: './pick-address.component.html',
    styleUrls: ['./pick-address.component.scss'],
})
export class PickAddressComponent implements OnInit {
    @Output() locationSelected = new EventEmitter<string>();
    // @ViewChild("placesRef") placesRef: GooglePlaceDirective;
    // private geocoder: google.maps.Geocoder;
    appConfig = AppConfig;

    @ViewChild('map', null) mapElement: ElementRef;
    map: any;

    @ViewChild("search")
    public searchElementRef: ElementRef;

    @Input()
    fieldType;
    @Input()
    myprefix;
    @Input()
    exTableData;
    @Input()
    extraData;
    @Input()
    model;
    @Input()
    addressId;

    @Input()
    latLng;
    @Input()
    sectionName;
    @Input()
    addressFieldKey;

    address;
    ready = false;

    searchTxt;

    searchTimeout;
    init_address;

    formattedaddress = " ";

    options: Options = {
        bounds: undefined,
        fields: ['address_component'],
        strictBounds: false,
        types: ['address'],
        origin: undefined,
        componentRestrictions: undefined
    };

    address_results = [];

    constructor(public events: Events, public server: ServerService,
                public modelProvider: ModelProvider, public adminService: AdminService) {
    }

    ngOnInit() {
        let s = [];
        if (this.model[this.addressFieldKey]) {
            this.searchTxt = this.model[this.addressFieldKey]['value'];
            // this.getAutocompleteOptions(this.searchTxt, true);
        } else {
            if (this.model['address']['value']) s.push(this.model['address']['value'].split(',')[0]);
            if (this.model['city']['value']) s.push(this.model['city']['value'].split(',')[0]);
            if (this.model['state']['value']) s.push(this.model['state']['value'].split(',')[0]);
            this.searchTxt = s.join(", ");
        }

        this.ready = true;
    }

    ngOnDestroy(): void {
    }

    ngAfterViewInit() {
    }

    handleAddressChange(address: any) {
    }


    async getAutocompleteOptions(query) {
        let me = this;
        clearTimeout(this.searchTimeout);
        this.searchTimeout = setTimeout(function () {
            let params = {
                input: query,
                types: ['address']
            }
            me.mapGet(params, true).then((results: any) => {
            });
        }, 1000);
    }

    showAddress(address) {
        this.events.publish('mapShowAddress' + this.myprefix, {'address': address});
    }

    setAddress(addressModel) {
        let addressField = 'address';
        let cityField = 'city';
        let stateField = 'state';
        let zipCodeField = 'zipCode';

        this.init_address = addressModel[0];
        let me = this;
        this.ready = false;
        setTimeout(function () {
            me.ready = true;
        })

        if (this.addressFieldKey && this.model[this.addressFieldKey]) {
            this.model[this.addressFieldKey]['value'] = addressModel[0];
            this.adminService.markUpdated(this.exTableData[this.sectionName + 'Data'], this.sectionName, this.model[this.addressFieldKey], this.model);
        } else if (this.model) {

            if (this.fieldType && this.fieldType['mapSelectFieldPrefix']) {
                let pref = this.fieldType['mapSelectFieldPrefix'];
                addressField = 'address' + pref;
                cityField = 'city' + pref;
                stateField = 'state' + pref;
                zipCodeField = 'zipCode' + pref;
            }

            this.model[addressField].displayName = this.model[addressField].value = addressModel[1];
            this.model[cityField].displayName = this.model[cityField].value = addressModel[2];
            this.model[stateField].displayName = this.model[stateField].value = addressModel[3];
            this.model[stateField].optionsActive = [addressModel[3]];
            if (this.model[zipCodeField]) this.model[zipCodeField].value = addressModel[4];
            this.adminService.markUpdated(this.exTableData[this.sectionName + 'Data'], this.sectionName, this.model[zipCodeField], this.model);
            this.adminService.markUpdated(this.exTableData[this.sectionName + 'Data'], this.sectionName, this.model[addressField], this.model);
            this.adminService.markUpdated(this.exTableData[this.sectionName + 'Data'], this.sectionName, this.model[cityField], this.model);
            this.adminService.markUpdated(this.exTableData[this.sectionName + 'Data'], this.sectionName, this.model[stateField], this.model);
            // this.adminService.markUpdated(this.exTableData[this.sectionName+'Data'], this.sectionName+, this.model['zipCode'], this.model);

        }
    }

    async mapGet(filtersExtra, showOnMap=false) {
        let filters = {'key': this.appConfig.googleMapsApi, offset: 10}
        for (let k in filtersExtra) {
            filters[k] = filtersExtra[k]
        }
        let first = true;
        this.modelProvider.custom('address_auto_complete', filters).then((results: any) => {
            console.log(results);
            let res = []
            for (let m of results) {
                // let addRes = this.extractLocationDetails(m);
                // res.push(addRes)
                // this.locationSelected.emit(selectedLocation);
                console.log(m.description)

                const mainText = m['structured_formatting'].main_text;
                const secondaryText = m['structured_formatting'].secondary_text;

// Extracting state, city, and address from secondary text
                const [city, stateAndZip] = secondaryText.split(',').map(part => part.trim());
                const state = stateAndZip;//.split(/\s+/); // Assuming zip code is separated by whitespace
                const address = mainText;

                let zipCodePattern = /\b\d{5}\b/;
                let match = mainText.match(zipCodePattern);
                let zipCode = match ? match[0] : 0;

                console.log('City:', city);
                console.log('State:', state);
                console.log('Address:', address);
                console.log('zipCode:', zipCode);
                let r = [m.description, address, city, state, zipCode]
                res.push(r);

                if (showOnMap && first)
                {
                    first = false;
                    this.setAddress([m.description]);
                }
            }
            this.address_results = res;
        })
    }

    // async getAddressComponents(address) {
    //     this.modelProvider.custom('json', {address: address, 'key': this.appConfig.googleMapsApi, offset: 10},
    //         'maps.googleapis.com/maps/api/geocode', false, 'https://').then((data: { 'results': [], 'status': '' }) => {
    //
    //     });
    // }
}
