import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BusinessService} from "../../../../core/services/BusinessService";
import {IonContent, NavController} from "@ionic/angular";
import {AppConfig} from "../../../../core/conf/main";
import {ModelProvider} from "../../../../framework/providers/modelProvider/ModelProvider";
import {ModelToolsProvider} from "../../../../framework/providers/model-tools/model-tools";
import {Events} from "../../../../framework/providers/events";

@Component({
    selector: 'app-signup',
    templateUrl: './signup.page.html',
    styleUrls: ['./signup.page.scss'],
})
export class SignupPage implements OnInit, OnDestroy {

    constructor(public businessService: BusinessService,
                public navCtrl: NavController,
                public events: Events,
                public modelTools: ModelToolsProvider,
                public modelProvider: ModelProvider) {

    }


    ngOnInit() {
        //console.log'SignupPage end!!!!');

        let me = this;
        this.events.subscribe('formIsDone', (data) => {
            //console.log'end!!!!');
            me.back();
        });
    }

    ngOnDestroy(): void {

    }

    back() {
        //console.log'end!!!!');
        this.navCtrl.navigateRoot('login');
    }

}
