// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-box {
  padding: 5px;
}

.nav-btn {
  height: 100%;
}
.nav-btn .title {
  font-weight: bold;
}

.sales-box {
  height: 500px;
  overflow: scroll;
  position: fixed;
}`, "",{"version":3,"sources":["webpack://./src/core/pages/main/main.page.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;;AAMA;EAME,YAAA;AARF;AAUE;EACE,iBAAA;AARJ;;AAiBA;EACE,aAAA;EACA,gBAAA;EACA,eAAA;AAdF","sourcesContent":[".main-box {\n  padding: 5px;\n}\n\n//.adv-slider-box {\n//  padding: 5px 10px;\n//}\n\n.nav-btn {\n  //ion-title {\n  //  background: #fff;\n  //  text-align: center;\n  //  font-size: 16px;\n  //}\n  height: 100%;\n\n  .title {\n    font-weight: bold;\n  }\n}\n\n//ion-content {\n//  background:  var(--app-background);\n//  --background: var(--app-background);\n//}\n\n.sales-box {\n  height: 500px;\n  overflow: scroll;\n  position: fixed;\n}\n\nion-content.body-content {\n  //--offset-bottom: auto !important;\n  //--overflow: hidden;\n  //overflow: auto;\n\n  //&::-webkit-scrollbar {\n  //  display: none;\n  //}\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
