// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bar {
  height: 40px;
  text-align: center;
  padding: 5px;
}

.box {
  border-radius: 20px;
}

.body {
  max-height: 400px;
  padding: 10px;
  text-align: center;
}

.img-box {
  max-height: 110px;
  width: auto;
  margin: auto;
}

.card-box {
  padding: 5px;
  margin: 0;
  text-align: center;
}

.title-download {
  text-align: center;
  font-size: 20px;
}`, "",{"version":3,"sources":["webpack://./src/core/components/platforms-download/platforms-download.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,kBAAA;EACA,YAAA;AACF;;AAEA;EACE,mBAAA;AACF;;AAEA;EACE,iBAAA;EACA,aAAA;EACA,kBAAA;AACF;;AAEA;EACE,iBAAA;EACA,WAAA;EACA,YAAA;AACF;;AAEA;EACE,YAAA;EACA,SAAA;EACA,kBAAA;AACF;;AAEA;EACE,kBAAA;EACA,eAAA;AACF","sourcesContent":[".bar {\n  height: 40px;\n  text-align: center;\n  padding: 5px;\n}\n\n.box{\n  border-radius: 20px;\n}\n\n.body {\n  max-height: 400px;\n  padding: 10px;\n  text-align: center;\n}\n\n.img-box {\n  max-height: 110px;\n  width: auto;\n  margin: auto;\n}\n\n.card-box {\n  padding: 5px;\n  margin: 0;\n  text-align: center;\n}\n\n.title-download{\n  text-align: center;\n  font-size: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
