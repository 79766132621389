// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.iframe-box .inner {
  position: relative;
  height: 100%;
}

#iframe {
  width: 100%;
  position: absolute;
  z-index: 40;
  left: 0;
}

.faild-box {
  color: #F44336;
  font-size: 25px;
  font-weight: bold;
}

.loading-box {
  width: 100%;
  height: 700px;
  position: absolute;
  z-index: 39;
  padding: 100px 0;
}

.loading-box app-loading .loading-icon ion-spinner {
  width: 200px;
  height: 200px;
}

@media (max-width: 680px) {
  .iframe-box .inner {
    background: #fff;
  }
  .iframe-box .inner #iframe form .Yform.siteW {
    margin: 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/core/pages/cart/pages/payment/components/payment/payment.component.scss"],"names":[],"mappings":"AAKA;EACE,kBAAA;EACA,YAAA;AAJF;;AAYA;EACE,WAAA;EACA,kBAAA;EACA,WAAA;EACA,OAAA;AATF;;AAYA;EACE,cAAA;EACA,eAAA;EACA,iBAAA;AATF;;AAYA;EACE,WAAA;EACA,aAAA;EACA,kBAAA;EACA,WAAA;EACA,gBAAA;AATF;;AAYA;EACE,YAAA;EACA,aAAA;AATF;;AAYA;EACE;IACE,gBAAA;EATF;EAWA;IACE,SAAA;EATF;AACF","sourcesContent":[".iframe-box {\n  //height: 800px;\n  //width: 700px;\n}\n\n.iframe-box .inner {\n  position: relative;\n  height: 100%;\n  //width: 199%;\n  //float: left;\n  //transform: scale(0.5);\n  //left: 2.5%;\n  //margin-top: 10px;\n}\n\n#iframe {\n  width: 100%;\n  position: absolute;\n  z-index: 40;\n  left: 0;\n}\n\n.faild-box {\n  color: #F44336;\n  font-size: 25px;\n  font-weight: bold;\n}\n\n.loading-box {\n  width: 100%;\n  height: 700px;\n  position: absolute;\n  z-index: 39;\n  padding: 100px 0;\n}\n\n.loading-box app-loading .loading-icon ion-spinner {\n  width: 200px;\n  height: 200px\n}\n\n@media (max-width: 680px) {\n  .iframe-box .inner {\n    background: #fff;\n  }\n  .iframe-box .inner #iframe form .Yform.siteW {\n    margin: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
