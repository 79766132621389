import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-orders-filters',
  templateUrl: './orders-filters.component.html',
  styleUrls: ['./orders-filters.component.scss'],
})
export class OrdersFiltersComponent implements OnInit {

  @Input()
  public prefix;
  @Input()
  public filters;
  @Input()
  public activeFilters;
  @Input()
  public activeStatuses;
  @Input()
  public activeActions;
  @Input()
  public options = {'activeTab':'status'};


  constructor() { }

  ngOnInit() {
    console.log(this.prefix);
  }

}
