// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.showOnHover5 .showOnHoverSon5 {
  display: none;
}

.showOnHover5:hover .showOnHoverSon5 {
  display: block;
}`, "",{"version":3,"sources":["webpack://./src/app/shaps/components/tools/generic-models-menu/generic-models-menu.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;;AAEA;EACE,cAAA;AACF","sourcesContent":[".showOnHover5 .showOnHoverSon5 {\n  display: none;\n}\n\n.showOnHover5:hover .showOnHoverSon5 {\n  display: block;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
