import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
})
export class AvatarComponent implements OnInit {

  @Input()
  public image:string;

  constructor() { }

  ngOnInit() {
    if(!this.image)
      this.image = 'https://ionicframework.com/docs/v4/demos/api/avatar/avatar.svg';
  }

}
