// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `span.nowrap {
  white-space: nowrap;
}

.oldprice {
  text-decoration: line-through;
  font-size: 16px;
}

.price-div {
  direction: ltr;
  display: flex;
  gap: 5px;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/framework/components/core/price/price.component.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF;;AAEA;EACE,6BAAA;EACA,eAAA;AACF;;AAMA;EACE,cAAA;EACA,aAAA;EACA,QAAA;EACA,mBAAA;AAHF","sourcesContent":["span.nowrap {\n  white-space: nowrap;\n}\n\n.oldprice {\n  text-decoration: line-through;\n  font-size: 16px;\n}\n\nspan {\n  //color: white;\n}\n\n.price-div{\n  direction: ltr;\n  display: flex;\n  gap: 5px;\n  align-items: center;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
