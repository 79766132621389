import { Component, OnInit } from '@angular/core';
import {Deal} from "../../../framework/model/core/deal/deal";
import {ModelToolsProvider} from "../../../framework/providers/model-tools/model-tools";

@Component({
  selector: 'app-deals',
  templateUrl: './deals.page.html',
  styleUrls: ['./deals.page.scss'],
})
export class DealsPage implements OnInit {

  public models:Array<Deal> = [];
  public options:object = {};

  constructor(public modelTools:ModelToolsProvider) { }

  ngOnInit() {
  }

  goTo(page, params)
  {
    this.modelTools.goTo(page, params);
  }

}
