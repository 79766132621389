import {Component, Input, OnInit} from '@angular/core';
import {Address} from "../../../model/core/address/address";
import {NewAddressPage} from "../../../../core/pages/cart/components/order-address/pages/new-address/new-address.page";
import {PlatformsDownloadComponent} from "../../../../core/components/platforms-download/platforms-download.component";
import {PopoverController} from "@ionic/angular";
import {AppMapComponent} from "../app-map/app-map.component";

@Component({
    selector: 'map-icon',
    templateUrl: './map-icon.component.html',
    styleUrls: ['./map-icon.component.scss'],
})
export class MapIconComponent implements OnInit {

    @Input()
    public address: string;

    @Input()
    public model: Address;

    constructor(public popoverCtrl:PopoverController) {
    }

    ngOnInit() {
    }


    async openMap() {
        const popover = await this.popoverCtrl.create({
            component: AppMapComponent,
            componentProps: {
                'address':this.address,
                'model':this.model,
            },
            event: null,
            animated: true,
            showBackdrop: true,
            cssClass: 'note-popover icenter-popover'
        });
        return await popover.present();
    }

}
