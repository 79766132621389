import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {AppUser} from "../../../../../../framework/model/core/appuser/appuser";
import {Order} from "../../../../../../framework/model/core/order/order";
import {Address} from "../../../../../../framework/model/core/address/address";
import {Business} from "../../../../../../framework/model/core/business/business";
import {OrderService} from "../../../../../services/OrderService";
import {ActionSheetController, NavController, PopoverController} from "@ionic/angular";
import {Events} from "../../../../../../framework/providers/events";
import {ModelProvider} from "../../../../../../framework/providers/modelProvider/ModelProvider";
import {BusinessService} from "../../../../../services/BusinessService";
import {TranslateService} from "@ngx-translate/core";
import {ItemProvider} from "../../../../item/providers/item-provider/item-provider.service";
import {ModelToolsProvider} from "../../../../../../framework/providers/model-tools/model-tools";
import {AppSettings} from "../../../../../conf/appSettings";
import {MainService} from "../../../../../services/MainService";
import {ResObject} from "../../../../../../app/global-module/global-module.module";
import {NavigationExtras} from "@angular/router";
import {OrderItem} from "../../../../../../framework/model/core/order/orderItem";
import {CouponsComponent} from "../../../components/coupons/coupons.component";
import {CustomItemsListComponent} from "../../../../item/components/custom-items-list/custom-items-list.component";
import {AppDataService} from "../../../../../services/AppDataService";
import {AdminService} from "../../../../../services/AdminService";
import {CartService} from "../../../../../services/CartService";
import {CartModel} from "../../../../../../framework/model/core/items/cartModel";
import {GenericFormComponent} from "../../../../../components/generic-form/generic-form.component";

@Component({
    selector: 'app-cart-details-component',
    templateUrl: './cart-details-component.component.html',
    styleUrls: ['./cart-details-component.component.scss'],
})
export class CartDetailsComponentComponent implements OnInit {
    public appuser: AppUser;
    public order: Order;
    public cartData: object;
    public cart: object;
    public address: Address;

    public formOptions: object = {};
    public addressFormOptions: object = {};

    public formPaymentOptions: object = {};
    public formPaymentFieldsOptions: object = {};

    public createFilters: object = {};

    public settings: object;

    public useTerms: boolean = false;
    public useTermsError: boolean = false;

    public makeInProgress: boolean = false;

    public business: Business;

    public btnTitle = 'makeOrder';

    public appFormsOptions: object = {};

    public paymentTypes: Array<any>;

    public orderActions;

    public cartStatus = {};

    @Input()
    public showBackBtn;
    @Input()
    public quickOrder;

    @Input()
    public actionsOnly:boolean;

    public colors = ['primary', 'success', 'warning', 'light', 'secondary', 'tertiary']


    public sub_prefix: string = 'sub_' + Math.floor(Math.random() * Math.floor(1000000));

    constructor(public orderService: OrderService, public popoverCtrl: PopoverController, public appdataService:AppDataService,
                public events: Events, public modelProvider: ModelProvider, public navCtrl: NavController,
                public businessService: BusinessService, public translate: TranslateService, public itemProvider: ItemProvider,
                public modelToolsProvider: ModelToolsProvider, public appSettings: AppSettings,
                public mainService: MainService, public cartService:CartService,
                public actionSheetController: ActionSheetController, public modelTools: ModelToolsProvider) {
        this.business = this.businessService.getBusiness();
        this.appuser = this.orderService.getAppUser();
        this.settings = this.appSettings.getSettings('cart');

        // //console.logthis.business)
        // //console.logthis.settings)

        if (!this.business.data.GlobalSettingsData.pickup && this.settings['fields'].includes("pickup")) {
            let i = this.settings['fields'].indexOf("pickup")
            this.settings['fields'].splice(i, 1);
        }
        if (this.settings['toDate'] && !this.settings['fields'].includes('toDate')) {
            this.settings['fields'].push('toDate')
        }


        // if (this.orderService.isAgent() && !this.settings['fields'].includes('discount')) {
        //     this.settings['fields'].push('discount')
        // }


    }

    ngOnInit() {
        this.getcart();
        this.getOrder();

        this.events.subscribe('cartUpdated', (params) => {
            this.getcart();
            this.getOrder();
        }, this.sub_prefix);


        this.events.subscribe('cartStatusChanged', (params) => {
            this.getCartStatus();
        }, this.sub_prefix);

        this.address = this.orderService.getAddress();
        this.orderActions = this.appdataService.getPricesListKey('order_actions');

        // this.paymentTypes = this.orderService.copy(this.settings['paymentTypes']);
        // if (this.paymentTypes) {
        //     if (!this.formPaymentFieldsOptions['paymentMethod'])
        //         this.formPaymentFieldsOptions['paymentMethod'] = {'options': {}};
        //     this.formPaymentFieldsOptions['paymentMethod']['options']['options'] = this.paymentTypes;
        // }
        this.setPaymentTypes();
        this.getCartStatus();

        // if (this.quickOrder && !this.orderActions) {
        //     this.proceed();
        // }

        // if (!this.quickOrder)
        // {
        //     this.proceed();
        // }
    }


    // ngDoCheck()
    // {
    //     this.getCartCalc();
    // }

    getCartStatus() {
        this.cartStatus = this.orderService.getCartStatus();
        //console.log'CART STATUS', this.cartStatus)
        this.getOrder();
    }

    getCartCalc() {
        this.cartService.getCartCalc();
    }

    ionViewWillEnter() {

        if (!this.cartData['amount'])
            this.navCtrl.navigateBack('main')

        this.getCartCalc();
        this.setPaymentTypes();

    }

    ngAfterViewInit() {
        this.events.subscribe(this.formOptions['prefix'] + '_pickup', (params) => {
            this.setPaymentTypes();
            this.cartService.updateCart();
        }, this.sub_prefix);
        this.events.subscribe(this.formOptions['prefix'] + '_discount', (params) => {
            this.cartData['discount'] = this.order.data.discount;
            this.cartService.updateCart();
        }, this.sub_prefix);

    }

    ngOnDestroy(){
        this.events.unsubscribe(this.formOptions['prefix'] + '_pickup', this.sub_prefix);
        this.events.unsubscribe(this.formOptions['prefix'] + '_discount', this.sub_prefix);
        this.events.unsubscribe('cartStatusChanged', this.sub_prefix);
        this.events.unsubscribe('cartUpdated', this.sub_prefix);
    }

    setPaymentTypes() {
        let types = this.settings['paymentTypes'];
        if (this.business.data.GlobalSettingsData.paymentTypes) {
            types = this.business.data.GlobalSettingsData.paymentTypes.split(',');
        }
        if (types)
            this.paymentTypes = this.orderService.copy(types);

        //console.logthis.paymentTypes)

        if (this.settings['fields'].includes('pickup')) {
            this.cartData['pickup'] = this.order.data.pickup;
            this.order.data.type = this.cartData['pickup'] ? 'pickup' : 'delivery';
        }

        if (this.paymentTypes) {
            if (this.order.data.type == 'delivery' && !this.business.data.GlobalSettingsData.cashInDelivery) {
                this.paymentTypes = ['creditCard'];
            }
        }

        if (!this.formPaymentFieldsOptions['paymentMethod'])
            this.formPaymentFieldsOptions['paymentMethod'] = {'options': {'forceOptions':true}};

        this.formPaymentFieldsOptions['paymentMethod']['options']['options'] = this.paymentTypes;
        this.events.publish(this.formPaymentOptions['prefix'] + '_paymentMethod_optionsChanged',
            {'options': this.paymentTypes, 'forceOptions':true});
    }

    getOrder() {
        this.order = this.orderService.getOrder();

        if (this.order.data.action && this.settings['btnTitleOnAction'])
            this.btnTitle = 'make' + this.order.data.action;
        console.log(this.settings, this.order)

    }

    getcart() {
        this.cartData = this.cartService.getCartData()
        this.cart = this.cartService.getCart()
    }

    checkCartValid() {
        let nopricesitems = [];
        for (var ticket in this.cart) {
            for (var k in this.cart[ticket]['products']) {
                if (!this.itemProvider.checkDelivery(this.cart[ticket]['products'][k]['product'])) {
                    this.modelProvider.presentToast('cantDeliverItem', true, null, null, true);
                    return;
                }

                let priceerror = false;
                // let priceerror = !this.cart[ticket]['products'][k].price && !this.settings['allowNoPrice'];
                //
                // //console.logthis.cart[ticket]['products'][k], k, this.cart[ticket]['products'][k].price, !this.cart[ticket]['products'][k].price)
                if (this.cart[ticket]['products'][k]['disable'])
                    nopricesitems.push({'model':this.cart[ticket]['products'][k]['product'], 'msg':this.cart[ticket]['products'][k]['disableMsg']})
            }
        }

        if (nopricesitems.length) {
            this.showCustomItems(nopricesitems)
            return
        }

        if (this.settings['chooseType']) {
            if (!this.orderService.getOrderType()) {
                this.modelProvider.presentToast('chooseOrderType', true, null, null, true)
                return;
            }
            if (this.orderService.getOrderType() == 'delivery' && !this.order.data['address']['id'] && !this.actionsOnly) {
                this.modelProvider.presentToast('noShipmentAddress', true, null, null, true);
                return;
            }
        }

        if (this.cartData['needBranch'] && this.business.data.GlobalSettingsData.branches && !this.cartData['pickupBranch']) {
            this.modelProvider.presentToast('noPickupBranch', true, null, null, true);
            return;
        }

        return true
    }


    checkValidation() {
        this.events.publish(this.formOptions['prefix']);
        this.events.publish(this.addressFormOptions['prefix']);

        if (!this.formOptions['valid']) return false;
        // if (!this.addressFormOptions['valid'] && !this.order.data.pickup) return false;
        if (!this.cartData['amount']) return false;

        if (!this.settings['chooseType']) {
            if (!this.order.data.pickup && !this.order.data['address']['id'] && !this.settings['forms'] && !this.actionsOnly) {
                if (this.settings['shipping-address']) {
                    this.modelProvider.presentToast('noShipmentAddress', true, null, null, true)
                    return false;
                }
            } else if (this.settings['forms']) {
                this.events.publish(this.appFormsOptions['prefix'] + '_checkValid');
            }
        }
        if (this.order.data.pickup && this.business.data.GlobalSettingsData.branches && !this.order.data.pickupBranch) {
            this.modelProvider.presentToast('noPickupBranch', true, null, null, true)
            return false;
        }

        return true;
    }

    bid(key) {
        this.createFilters[key] = true;
        this.createFilters['isbid'] = true;

        let action = key == 'askForBid' ? 'bidAsk' : 'bid';
        this.setAction(action)
    }

    setAction(action, action_id = null)
    {
        this.order.data.action = action;
        this.order.data.action_id = action_id;
        this.proceed();
    }

    setOrderAction(action){
        let me = this;
        this.modelTools.yesOrNoAlert(function ()
        {
            me.setAction(action['key'], action['id']);
        }, {
            'yes':me.modelTools.getTranslate('acceptAndSend'),
            'no':me.modelTools.getTranslate('cancel'),
            'title':me.modelTools.getTranslate(action['name']),
            'subTitle':me.modelTools.getTranslate('areYouSure'),
        })
    }

    async viewOrderActions()
    {
        let buttons = []
        console.log(this.orderActions)
        for(let action of this.orderActions)
        {
            buttons.push({
                text: this.modelTools.getTranslate(action['name']),
                icon: action['icon'],
                handler: () => {
                    this.setOrderAction(action)
                }
            });
        }
        buttons.push({
            text: this.modelTools.getTranslate('close'),
            role: 'destructive',
            icon: 'close',
            handler: () => {

            }
        });

        const actionSheet = await this.actionSheetController.create({
            header: this.modelTools.getTranslate('orderOptions'),
            cssClass: 'my-custom-class',
            buttons: buttons
        });
        await actionSheet.present();

        const {role, data} = await actionSheet.onDidDismiss();
    }

    proceed() {
        this.getOrder();

        delete this.order.data['id'];

        if (!this.order.data.action)
            this.order.data.action = this.cartData['action'];

        this.order.data.extorder = this.cartData['extorder'];

        if (!this.order.data.contactMan)
            this.order.data.contactMan = this.order.data.name;
        this.order.data.paymentTypeName = 'creditCard';
        this.order.data.paymentStation = this.cartData['station'];

        if (!this.checkValidation() && !this.quickOrder)
            return;

        if (!this.checkCartValid() && !this.quickOrder)
            return;

        // this.navCtrl.navigateForward('cart-summary');
        this.makeOrder({'checkMakers': true});
    }

    updateForms(order) {
        this.events.publish(this.appFormsOptions['prefix'] + '_updateForms', {'params': {'order': order.data.id}});
        // this.events.subscribe(this.appFormsOptions['prefix']+'_formsUpdated', (res)=>{
        //     // this.order.data.orderDetails = this.appFormsOptions['data']['orderDetails'].id;
        //     this.events.unsubscribe(this.appFormsOptions['prefix']+'_formsUpdated');
        // })
    }


    makeOrder(params = {}) {
        let me = this;
        if (params['this'])
            me = params['this'];

        if (!me.useTerms && me.settings['useTerms'] && !this.quickOrder && !this.orderService.isStaffOrAgent()) {
            me.useTermsError = true;
            return false;
        } else
            me.useTermsError = false;

        if (!me.cartData['amount'])
            return;

        // this.cartService.createOrder(this.order);
        //
        // return ;

        /////////////////////////////////////////
        /////////////////////////////////////////
        /////////////////////////////////////////
        /////////////////////////////////////////
        /////////////////////////////////////////

        if (me.makeInProgress)
            return;

        if(this.business.data.GlobalSettingsData['order_verify_user'] && !params['validated'])
        {

            let prefix = this.modelTools.getRandomize();
            this.modelTools.showModal(GenericFormComponent, {'key':'pincode', 'filters':{'publishKey':prefix, 'isModal':true}});
            this.events.subscribe(prefix, (res)=>{
                params['validated'] = true;
                this.makeOrder(params)
                this.modelTools.closeModal();
            })
            return
        }

        this.makeInProgress = true;

        var items = {};

        let ticketRes = {'counter': 1};
        for (var ticket in me.cart) {
            // ticketRes['counter'] = 1;
            ticketRes['name'] = me.cart[ticket]['name'];
            for (var k of me.cart[ticket]['modelKeys']) {
                let item_data = me.cart[ticket]['models'][k]
                let cart_model = item_data['cartModel']
                // if(me.cartData['action'] && !me.cart[ticket]['products'][k]['actionChecked'])
                if (!cart_model.getValue('amount'))
                    continue;

                // let item = me.cart[ticket]['products'][k]['product'];
                // if(item.data.itemMaker)
                // {
                //     if(!makers[item.data.makerName])
                //     {
                //         makers[item.data.makerName] = 0;
                //         makers['count']++;
                //     }
                //
                //     makers[item.data.makerName]++;
                // }

                me.setOrderItem(items, item_data, ticketRes);
                ticketRes['counter']++;
            }
        }

        me.order.data['items'] = items;
        me.order.data['cart_data'] = me.cartData;
        //

        let userName = me.order.data.name;
        me.events.publish('setBackLoadingOn');
        me.modelProvider.create(me.order, me.createFilters).then((res: ResObject) => {
            let navigationExtras: NavigationExtras = {
                queryParams: {
                    'orderId': res.data.id,
                    'order': me.order,
                    'userName': userName,
                    'backToRoot': true,
                    'neworder': true,
                },
            };

            if (me.settings['forms']) {
                me.updateForms(res);
            }

            me.events.publish('setBackLoadingOff');

            // if (me.business.data.GlobalSettingsData.appPayment && me.order.data.paymentMethod == 'creditCard' && !me.createFilters['isbid'])
            //     me.navCtrl.navigateRoot('cart-payment', navigationExtras);
            // else {
            let page = 'order/' + res.data.id;
            if(this.orderService.isAgent() && this.business.data.GlobalSettingsData.agentOrderCreatePage){
                page = this.business.data.GlobalSettingsData.agentOrderCreatePage;
                this.orderService.newOrderPopup(res);
            }

            me.navCtrl.navigateRoot(page, navigationExtras);
            this.makeInProgress = false;
            // }

            setTimeout(function () {
                me.orderService.orderCreated();
                me.orderService.clearCart(true);
            }, 700)
        }, (res) => {
            me.events.publish('setBackLoadingOff');
            // //console.log'REJECTED!!')
            this.makeInProgress = false;
        })
    }

    setOrderItem(obj, item_data, ticketRes) {
        let item = item_data['item'];
        let cart_model:CartModel = item_data['cartModel'];
        let amount = parseInt(cart_model.getValue('amount'));

        // let fixedprice = product['agentPrice'] ? product['agentPrice'] : (product['fixedPrice'] ? product['fixedPrice'] : 0);
        // let fixedprice = product['fixedPrice'];
        //
        // //console.logproduct)

        let props = [];
        let item_props = cart_model.getValue('props')
        if(item_props){
            for (let k in item_props) {
                for (let p in item_props[k]['checked']) {
                    // let value = null;
                    // if(product['props'][k]['checkedData'][p].inputType != 'checkbox')
                    //     value = product['props'][k]['checked'][p];
                    props.push({
                        'id': p,
                        'value': item_props[k]['checkedValue'][p],
                    })
                }
            }
        }

        let dealData = {};
        // let dealData = product['dealData'] ? product['dealData'] : {};

        let images = []
        // for (let i in product['images']) {
        //     images.push(product['images'][i].data);
        // }

        obj[ticketRes['counter']] = new OrderItem({
            itemId: item.data.id,
            amount: amount,
            actionAmount: cart_model.getValue('actionAmount'),
            comments: cart_model.getValue('comments'),
            free: cart_model.getValue('free'),
            unitPrice: cart_model.getValue('price'),
            agentPrice:  cart_model.getValue('price'),
            fixedPrice: 0,
            totalPrice: cart_model.getValue('totalPrice'),
            deal: dealData['id'],
            dealNo: dealData['prefix'],
            ticket: ticketRes['name'],
            images: images,
            props: props,

            features: cart_model.data['features'],

            // price: product['corePrice'],
        }).data;

        for (let k in dealData['items']) {
            ticketRes['counter']++;
            let itemres = {
                product: dealData['items'][k]['product'],
                amount: amount,
                comments: '',
                free: true,
                unitPrice: 0,
                agentPrice: null,
                fixedPrice: 0,
                totalFixedPrice: 0,
                setTotalFixedPrice: true,
                totalPrice: 0,
                dealData: {
                    'id': dealData['id'],
                    'prefix': dealData['prefix'],
                },
                props: dealData['items'][k]['props'],
            }
            this.setOrderItem(obj, itemres, ticketRes);
        }
    }

    setCouponPopup() {
        this.orderService.setCouponPopup()
    }


    notePopup(key, title, text) {
        title = this.translate.instant(title);
        this.mainService.notePopup(key, title, text)
    }


    back() {
        this.navCtrl.pop();
    }


    async showCustomItems(models) {
        const popover = await this.popoverCtrl.create({
            component: CustomItemsListComponent,
            componentProps: {'models': models},
            event: null,
            animated: true,
            showBackdrop: true,
            cssClass: 'center-popover',
        });


        popover.onDidDismiss().then(
            (data: any) => {
            });


        return await popover.present();
    }

    // async setOrderAddressPopup() {
    //     const popover = await this.popoverCtrl.create({
    //         component: OrderAddressComponent,
    //         componentProps: {
    //             'navCtrl': this.navCtrl,
    //             'order': this.order,
    //         },
    //         event: null,
    //         animated: true,
    //         showBackdrop: true
    //     });
    //     return await popover.present();
    // }


}
