import { Component, OnInit } from '@angular/core';
import {ModalController, NavParams} from "@ionic/angular";
import {Address} from "../../../../../../../framework/model/core/address/address";
import {ModelProvider} from "../../../../../../../framework/providers/modelProvider/ModelProvider";
import {OrderService} from "../../../../../../services/OrderService";
import {AppUser} from "../../../../../../../framework/model/core/appuser/appuser";
import {Events} from "../../../../../../../framework/providers/events";

@Component({
  selector: 'new-address',
  templateUrl: './new-address.page.html',
  styleUrls: ['./new-address.page.scss'],
})
export class NewAddressPage implements OnInit {
  public address:Address;
  public appuser:AppUser;

  public formOptions: object = {};
  public fields:Array<string> = ['cityId', 'street', 'homeNumber', 'floor', 'apt'];

  public models: Array<Address>;
  public callback:any;

  constructor(public modalController:ModalController, public navParams:NavParams, public events:Events, public modelProvider:ModelProvider, public orderService:OrderService) {
    this.address = new Address({});
    this.appuser = this.orderService.getAppUser();

    this.models = navParams.get('models');
    this.callback = navParams.get('callback');
  }

  ngOnInit() {
      this.setFields();
  }

  setFields()
  {
      let fieldKey = 'cityId';
      this.events.subscribe('fieldChanged_'+fieldKey, (params)=> {
          if (this.address.data[fieldKey + 'Data'].data['neighborsCount']) {
              this.fields = ['cityId', 'neighborhood', 'street', 'homeNumber', 'floor', 'apt'];
              this.events.publish('neighborhood'+'_updateModelScroll', {'city':this.address.data.cityId});
          }
          else
              this.fields = ['cityId', 'street', 'homeNumber', 'floor', 'apt'];
      })


  }


    ngOnDestroy() {
        this.events.unsubscribe('fieldChanged_'+'cityId');
    }

   createAddress() {
        if(this.address.data['cityIdData'].data['neighborsCount'] && !this.address.data.neighborhood) {
            this.address.data['neighborhood' + '_invalid'] = true;
        }
        else
            this.address.data['neighborhood' + '_invalid'] = false;

        this.events.publish(this.formOptions['prefix']);
       if (!this.formOptions['valid']) return;

        this.address.data.appuser = this.appuser.data.id;
        this.address.data.company = this.appuser.data.companyId;
        this.modelProvider.create(this.address, {'disableLoading': true}).then((res: Address) => {
            this.formOptions['isSent'] = false;
            let address = new Address(res.data);
            this.models.push(address);
            this.callback(address)
            // this.chooseAddress(res);
            // this.addresses.splice(0, 0, res);
            // if (this.notOnRadius)
            // this.slideTo(0);
            // else
            //     this.add();

            this.close()
        });
    }

  close() {
      this.modalController.dismiss();
  }
}
