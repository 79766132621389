import {Injectable} from '@angular/core';
import {AppConfig} from "../../../../../conf/main";
import {ModelProvider} from "../../../../../../framework/providers/modelProvider/ModelProvider";
import {Events} from "../../../../../../framework/providers/events";



@Injectable()
export class SectionsManagerService {

    public sections:Array<any> = [];

    public checkedItems: object = {};
    public checkedItemsData: object = {};

    public sectionsData: object = {};
    public itemsData: object = {};
    public transferData : object = {};

    constructor(public modelProvider:ModelProvider, public events:Events) {
        this.initAll();
    }

    initAll()
    {
        this.initData(this.sectionsData);
        this.initData(this.itemsData);
        this.initTransferData();
    }
    
    initData(obj) {
        // this.sectionsData['reorderSections'] = checked;
        obj['checked'] = {};
        obj['checkedData'] = {};
        obj['checkedArr'] = [];
        obj['reorder'] = true;
        obj['donecheck'] = false;


        setTimeout(function () { /* because checkboxes calls change function */
            obj['amount'] = 0;
        }, 100)
    }

    initTransferData()
    {
        this.transferData['tosection'] = null;
        this.transferData['toSectionChanged'] = false;
        this.transferData['tosectiondata'] = {};
        this.transferData['resetAll'] = {'items':false, 'sections':false};
    }

    setSectionsValues(res, data) { // after sections list. called by SectionListPage.
        let secdata;
        for (var k in res) {
           secdata  = {'id':res[k]['id'], 'name':res[k]['name'], 'amount':res[k]['amount'], 'items':res[k]['items'], 'image':res[k]['image'], 'sections':[]}
           data.push(secdata);
           if(secdata['amount'])
               this.setSectionsValues(res[k]['sections'], secdata['sections'])
        }
    }

    setSectionValuesByRes(res) // after section create.
    {
        return {'id': res.data.id, 'name': res.data.name, 'sections': [], 'items': 0, 'amount': 0};
    }


    transfer() {
        if(this.sectionsData['amount'])
            this.transferSections();
        else
            this.allowResetAll('sections');

        if(this.itemsData['amount'])
            this.transferItems();
        else
            this.allowResetAll('items');

        this.sectionsData['amount'] = 0;
        this.itemsData['amount'] = 0;

    }

    transferSections(){
        let sections = [];
        let newsections = []

        for (var k in this.sectionsData['checked']) {
            if (this.sectionsData['checked'][k])
                sections.push(k)
        }

        for(var k in sections)
        {
            let dupData = this.sectionsData['checkedData'][sections[k]];
            dupData['oldId'] = dupData['id'];
            delete dupData['id'];
            newsections.push(dupData)
            this.transferData['tosectiondata']['sections'].push(dupData);
            this.transferData['tosectiondata']['amount'] = this.transferData['tosectiondata']['sections'].length;
        }

        this.modelProvider.custom('updateSectionsTopSection', {
            'pref': AppConfig.bisPrefix,
            'sections': sections,
            'section': this.transferData['tosection']
        }, null, true).then((res) => {

            for(var k in newsections)
            {
                newsections[k]['id'] = res[newsections[k]['oldId']]; // update sections new ids.
            }

            this.initData(this.sectionsData);
            this.allowResetAll('sections');

        })
    }
    

    transferItems() {
        if(this.transferData['tosection'] == -1) {
            this.allowResetAll('items');
            return;
        }

        let items = [];

        for (var k in this.itemsData['checked']) {
            if (this.itemsData['checked'][k]) {
                items.push(k);
                this.transferData['tosectiondata']['items']++;
            }
        }

        this.modelProvider.custom('insertItemsToSection', {
            'pref': AppConfig.bisPrefix,
            'items': items,
            'section': this.transferData['tosection']
        }, null, true).then((res) => {
            this.events.publish(this.transferData['tosection']+'_sectionItems'+'_updateModels');
            this.initData(this.itemsData);
            this.allowResetAll('items');
        })
    }

    allowResetAll(key)
    {
        //console.log'RESET!')
        //console.logthis.transferData['resetAll']);

        this.transferData['resetAll'][key] = true;
        if(this.transferData['resetAll']['sections'] && this.transferData['resetAll']['items']) {
            this.initAll()
        }
    }

    refreshCheckedArr(obj = this.sectionsData, datakey = false) // datakey = items from item list comes with data.
    {
        let amount = 0;
        let checked = obj['checked'];
        let checkedData = obj['checkedData'];
        let arr = [];

        for(var k in checked)
        {
            let obj = checkedData[k];
            if(datakey) {
                obj = checkedData[k]['data'];
            }

            if(checked[k])
            {
                amount++;
                arr.push({'id':k, 'name':obj['name']})
            }
        }

        obj['checkedArr'] = arr;
        obj['amount'] = amount;
    }

    getSections()
    {
        return this.sections;
    }

    getSectionsData()
    {
        return this.sectionsData;
    }

    getItemsData()
    {
        return this.itemsData;
    }

    getTransferData()
    {
        return this.transferData;
    }
}









