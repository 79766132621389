// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host ::ng-deep autofield modeloptionscroll .value {
  text-align: center;
  border-left: 1px solid #bda15b;
}
:host ::ng-deep autofield modeloptionscroll .value {
  text-align: center;
  border-left: 1px solid #bda15b;
}
:host ::ng-deep autofield modeloptionscroll .value:last-child {
  border-bottom-left-radius: 9px;
  border-top-left-radius: 9px;
  border: 0;
}
:host ::ng-deep autofield modeloptionscroll .value.active {
  background: #fac75a;
  color: #000;
  font-weight: bold;
}
:host ::ng-deep .input-row.boolean autofield .field-box {
  padding: 0;
}`, "",{"version":3,"sources":["webpack://./src/framework/components/core/valid-form/valid-form.component.scss"],"names":[],"mappings":"AAoBE;EACE,kBAAA;EACA,8BAAA;AAnBJ;AAsBE;EACE,kBAAA;EACA,8BAAA;AApBJ;AAuBE;EACE,8BAAA;EACA,2BAAA;EACA,SAAA;AArBJ;AAwBE;EACE,mBAAA;EACA,WAAA;EACA,iBAAA;AAtBJ;AAyBE;EACE,UAAA;AAvBJ","sourcesContent":["//.adir autofield\n//{\n//  color:#0ec254;\n//}\n:host ::ng-deep {\n  //autofield modeloptionscroll .value {\n  //  background: #fac75a;\n  //  color: #000;\n  //  font-weight: bold;\n  //}\n\n  //\n  //.input-form .input-row.boolean autofield .field-text {\n  //  border: 0;\n  //}\n  //\n  //.input-form .input-row.boolean .value.active autofield .field-text {\n  //  color: #000;\n  //}\n\n  autofield modeloptionscroll .value {\n    text-align: center;\n    border-left: 1px solid #bda15b;\n  }\n\n  autofield modeloptionscroll .value {\n    text-align: center;\n    border-left: 1px solid #bda15b;\n  }\n\n  autofield modeloptionscroll .value:last-child {\n    border-bottom-left-radius: 9px;\n    border-top-left-radius: 9px;\n    border: 0;\n  }\n\n  autofield modeloptionscroll .value.active {\n    background: #fac75a;\n    color: #000;\n    font-weight: bold;\n  }\n\n  .input-row.boolean autofield .field-box {\n    padding: 0;\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
