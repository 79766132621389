// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-badge {
  margin: 0 2px;
}
ion-badge ion-icon {
  vertical-align: bottom;
}`, "",{"version":3,"sources":["webpack://./src/core/pages/admin/pages/sections-manager/components/sections-options/sections-options.component.scss"],"names":[],"mappings":"AAAA;EAEE,aAAA;AAAF;AAEE;EAEE,sBAAA;AADJ","sourcesContent":["ion-badge\n{\n  margin:0 2px;\n\n  ion-icon\n  {\n    vertical-align: bottom;\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
