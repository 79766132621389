import {Component, OnInit} from '@angular/core';
import {ItemMaker} from "../../../framework/model/core/items/itemMaker";
import {NavController} from "@ionic/angular";
import {NavigationExtras} from "@angular/router";
import {BusinessService} from "../../services/BusinessService";

@Component({
    selector: 'app-makers',
    templateUrl: './makers.page.html',
    styleUrls: ['./makers.page.scss', '../../components/sliders/model-slider/model-slider.component.scss'],
})
export class MakersPage implements OnInit {
    public models: Array<ItemMaker> = [];
    public filters: object = {'topsec': true, 'limit': 20, 'inMakersPage': true};
    public options: object = {};
    public prefix: string = 'makersList';

    constructor(public navCtrl: NavController, public businessService: BusinessService) {
    }

    ngOnInit() {
    }

    goTo(page, params) {
        let navigationExtras: NavigationExtras = {
            queryParams: params
        };

        this.navCtrl.navigateForward(page, navigationExtras)
    }

    log(k)
    {
        console.log(k)
    }
}
