import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Events} from "../../../../../framework/providers/events";
import {AppConfig} from "../../../../../core/conf/main";
import {AdminService} from "../../../../../core/services/AdminService";

@Component({
    selector: 'app-minicalendar',
    templateUrl: './minicalendar.component.html',
    styleUrls: ['./minicalendar.component.scss'],
})
export class MinicalendarComponent implements OnInit, OnDestroy {

    appConfig = AppConfig;
    dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wedensday', 'Thusday', 'Friday', 'Saturday'];

    @Input()
    monthDayDates;
    @Input()
    type;
    @Input()
    hideEvents = false;
    @Input()
    eventsBy;
    @Input()
    appEventDays = {};
    @Input()
    prefix;
    @Input()
    daysMark;

    @Input()
    extableData;

    @Input()
    currentDate;

    onOverEvent;

    constructor(public events: Events, public adminService:AdminService) {
    }

    ngOnInit() {
        // this.events.subscribe('dateData' + this.prefix, (data) => {
        //     if (data['key'] == 'setCurrenDate') {
        //         this.currentDate = data['currentDate'];
        //     }
        // });
        console.log(this.monthDayDates);
    }

    chooseDay(newDate = null, getInDay = true, closeWindow = false, manualChoose = false) {
        this.events.publish('chooseDay' + this.prefix, {'newDate': newDate, 'type': this.type});
    }

    ngOnDestroy() {
        // this.events.unsubscribe('save' + this.prefix);
    }

    prev() {

    }

    next() {

    }

    eventOnOver(e, modelEvent) {
        console.log(e);
        let me = this;
        this.onOverEvent = {'event': e, 'eventModel': modelEvent};
        setTimeout(function () {
            me.onOverEvent = null;
        }, 7000);
    }
}
