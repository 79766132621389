import { Component, OnInit } from '@angular/core';
import {ItemToUser} from "../../../framework/model/core/items/itemToUser";
import {StockReport} from "../../../framework/model/core/stock/stockReport";
import {WeeklyReport} from "../../../framework/model/core/stock/weeklyReport";
import {AppUser} from "../../../framework/model/core/appuser/appuser";
import {OrderService} from "../../services/OrderService";
import {ModelProvider} from "../../../framework/providers/modelProvider/ModelProvider";
import {Events} from "../../../framework/providers/events";


@Component({
  selector: 'app-stock-reports',
  templateUrl: './stock-reports.page.html',
  styleUrls: ['./stock-reports.page.scss'],
})
export class StockReportsPage implements OnInit {

  public appuser:AppUser;
  public tabs:object = {'pages':[], 'value':null, 'data':{}};
  //
  // public items:Array<ItemToUser> = [];
  // public reports:Array<StockReport> = [];
  // public weeklyReports:Array<WeeklyReport> = [];

  constructor(public orderService:OrderService, public modelProvider:ModelProvider, public events:Events) {
    this.appuser = orderService.getAppUser();
  }

  ngOnInit() {


    this.tabs['pages'] = ['weekly','reports','items'];
    this.tabs['data'] = {
      'weekly':{'modelName':'weeklyReport', 'filters':{'itemToUser__company':this.appuser.data.companyId}, 'data':[], 'prefix':'weeklyList',
        'fields':[{'key':'startStock', 'size':5}, {'key':'sold', 'size':4}, {'key':'returned', 'size':3}, {'key':'stock', 'size':5}, {'key':'income', 'size':4}]},
      'reports':{'modelName':'stockReport', 'filters':{'itemToUser__company':this.appuser.data.companyId}, 'data':[]}, 'prefix':'reportsList',
      'items':{'modelName':'itemToUser', 'filters':{'company':this.appuser.data.companyId, 'item__stockReports':true}, 'data':[], 'prefix':'itemsToReportList',
        'fields':[{'key':'stock', 'size':5}, {'key':'weeklySold', 'size':4}, {'key':'weeklyReturned', 'size':3}, {'key':'weeklyStartStock', 'size':5}, {'key':'weeklyIncome', 'size':4}]}
    }

    this.tabs['value'] = 'items';
  }

  setTab(e)
  {
    this.tabs['value'] = e['detail']['value']
  }

  addReportAmount(item, num)
  {
    if(!item.data.reportAmount) item.data.reportAmount = 0;
    item.data.reportAmount+=num;

    if(item.data.reportAmount < 0)
      item.data.reportAmount = 0;
  }

  report(item) {
      var stock;

      let amount = item.data.reportAmount;

      if(!item.data.stockReported) {
        stock = amount;
        item.data.stockReported = true;
      }
      else
      {
        stock = item.data.stock - amount;
        if(stock < 0) stock = 0;
      }
      item.data.stock = stock;
      item.data['weeklySold'] = item.data['weeklySold'] + amount;

      let _stockReport = new StockReport({});
      _stockReport.data.itemToUser = item.data.id;
      _stockReport.data.amount = amount;
      _stockReport.data.type = 'sold';

      this.modelProvider.create(_stockReport, {}, null, true).then((res)=>{
        item.data.reportAmount = 0;
        this.events.publish(this.tabs['data']['items']['prefix']+'_updateFilter');
      })
    }

}
