import {Component, Input, OnInit} from '@angular/core';
import {ModelProvider} from "../../../../framework/providers/modelProvider/ModelProvider";
import {PopoverController} from "@ionic/angular";
import {SelectBarComponent} from "../select-bar/select-bar.component";
import {AdminService} from "../../../../core/services/AdminService";

@Component({
    selector: 'app-admin-search',
    templateUrl: './admin-search.component.html',
    styleUrls: ['./admin-search.component.scss'],
})
export class AdminSearchComponent implements OnInit {
    @Input()
    exTableData;

    isClosed;

    searchText = '';
    results = [];

    constructor(public modelProvider: ModelProvider,
                public adminService:AdminService,
                public popoverCtrl: PopoverController) {
    }

    ngOnInit() {
    }

    search() {
        //console.log'search text!!');
        let filters = {'type': 'moving', 'name': 'taarif'};
        filters['sectionName'] = 'search';
        filters['searchStr'] = this.searchText;

        let me = this;

        this.modelProvider.list('report', filters)
            .then(function (data: any) {
                //console.logdata);
                let res = data['models'][0]['data']['finalResReport']['results'];
                //console.logres);
                me.results = res;
            });
    }

    async presentSelectBar(myEvent, res) {
        this.adminService.popupEditBox(myEvent, res['model'], this.exTableData, "asdasdqwdqwdqw",
                       null, res['id'] ? 'modelTableEdit' : 'simpleTable', true,
                       null, null, res['id'] ? res['id'] : null, null, null);
        // const popover = await this.popoverCtrl.create({
        //     component: SelectBarComponent,
        //     componentProps: {
        //         'getModelById': res['id'], // can be null for section table display
        //         'exTableData': this.exTableData,
        //         'prefix': "asdasdqwdqwdqw",
        //         'genericModelTable': res['type'] == 'section' ? true : false,
        //         'sectionName': res['model'],//if select options and not select table.
        //         'type': 'modelTableEdit'
        //     },
        //     event: null,
        //     animated: true,
        //     showBackdrop: true,
        //     cssClass: 'note-popover icenter-popover right-popup'
        // });
        // return await popover.present();
    }

}
