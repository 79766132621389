import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {Storage} from '@ionic/storage';
import {UserData} from "../user-data";
import {User} from "../user-data";
import {ServerService} from "../server/server";
import {ModelProvider} from "../modelProvider/ModelProvider";
import {AuthModel} from "./authModel";

import {ModelToolsProvider} from "../model-tools/model-tools";
import {Events} from "../events";
import {AppConfig} from "../../../core/conf/main";


@Injectable()
export class AuthService {
    public userLoggedIn: boolean = false;
    public userPrefix = 'appuser1';
    public bisPrefix = '';

    constructor(private authModel: AuthModel,
                private server: ServerService,
                public userData: UserData,
                public events: Events,
                public storage: Storage,
                public modelTools: ModelToolsProvider,
                private modelProvider: ModelProvider) {

        // ////console.log'auth service!!')

        // this.getSavedCredentials()
    }

    public setBisPrefix(prefix) {
        this.bisPrefix = prefix;
        this.getSavedCredentials(this.getBisUserKey());
    }

    public login(credentials) {
        var protocolName = 'api-token-auth';

        if (credentials.email === null || credentials.password === null) {
            return throwError("Please insert credentials");
        } else {
            return Observable.create(observer => {
                // At this point make a request to your backend to make a real check!

                //var res = {'fname':'asaf', 'lname':'cohen',
                //    'puserId':1, 'id':2, 'email':'email@email.com',
                //    'token':'token'};
                this.server.post(null, protocolName + '/', credentials)
                    .then((res: any) => {
                        // this.userData.currentUser = this.userData;
                        this.userData.login({
                            'fname': res.fname,
                            'lname': res.lname,
                            'puserId': res.puserId,
                            'puserType': res.puserType,
                            'username': res.username,
                            'is_superuser': res.issuperuser,
                            'is_staff': res.isstaff,
                            'id': res.id,
                            'email': res.email,
                            'token': res.token
                        });

                        this.userLoggedIn = true;

                        credentials['password'] = '';

                        this.saveCredentials(credentials);
                        this.events.publish('userLogged', credentials);

                        //var userData = this.userData.getUserdata();
                        //let access = res.token ? true : false; //(credentials.password === "pass" && credentials.email === "email");
                        //this.modelProvider.get('puser', userData.data.puserId, {}).then((res) => {

                        observer.next(true);
                        observer.complete();

                        //});
                    })
                    .catch((e) => {
                        this.userData.logout();
                        ////console.log"!! ERORR !!!");
                        ////console.logobserver);
                        observer.next(false);
                        observer.complete();
                    });
            });
        }
    }

    public register(credentials) {
        if (credentials.email === null || credentials.password === null) {
            return throwError("Please insert credentials");
        } else {
            // At this point store the credentials to your backend!
            return Observable.create(observer => {
                observer.next(true);
                observer.complete();
            });
        }
    }

    public getUserInfo(): User {
        return this.userData.currentUser;
    }

    public getAuthModel(): AuthModel {
        return this.authModel;
    }

    public hasLoggedIn(): Promise<boolean> {
        //////console.log'baaaaaa cureent user?!?!');
        //////console.logthis.authModel.currentUser);
        return this.userData.hasLoggedIn()
    }

    public hasUserLoggedIn() {
        //////console.log'baaaaaa cureent user?!?!');
        //////console.logthis.authModel.currentUser);
        return this.userLoggedIn;
    }

    public logout() {
        //return Observable.create(observer => {
        //    this.currentUser = null;
        //    observer.next(true);
        //    observer.complete();
        //});
        this.saveCredentials(null);
        this.userData.logout();
        this.userLoggedIn = false

    }

    getBisUserKey()
    {
        return this.userPrefix + AppConfig.corePrefix
    }

    saveCredentials(data) {
        this.modelTools.setKey(this.getBisUserKey(), data, false);
    }

    getSavedCredentials(key, initevent = true) {

        key = this.userPrefix + this.bisPrefix;
        this.userData.getUserdata().subscribe((userData: User) => {

            ////console.log'getSavedCredentials');
            ////console.loguserData);
            ////console.logkey);

            if (userData && userData.data) {

                // this.login(res).subscribe(allowed => {
                //         if (allowed) {
                //
                //         } else {
                //
                //         }
                //     },
                //     error => {
                //     });
                userData.data['fromStorage'] = true;
                this.events.publish('userLogged', userData.data);
                this.userLoggedIn = true;

            } else {
                // if (key != 'loginDetails') {
                //     // this.getSavedCredentials('loginDetails', false);
                // } else
                this.events.publish('noUserInStorage');
            }

            if (initevent) {
                this.events.publish('initLogingChecked', userData);
            }


            // console.trace()
            // this.modelTools.getKey(key).then((res) => {
            //     ////console.logkey);
            //     ////console.logres);
            //     if (res) {
            //
            //         // this.login(res).subscribe(allowed => {
            //         //         if (allowed) {
            //         //
            //         //         } else {
            //         //
            //         //         }
            //         //     },
            //         //     error => {
            //         //     });
            //
            //
            //     } else {
            //         if (key != 'loginDetails')
            //             this.getSavedCredentials('loginDetails', false);
            //         else
            //             this.events.publish('noUserInStorage');
            //     }
            //
            //     if (initevent)
            //         this.events.publish('initLogingChecked', res);
            // });
        });
    }
}
