import {Component, OnInit} from '@angular/core';
import {AppUser} from "../../../framework/model/core/appuser/appuser";
import {OrderService} from "../../services/OrderService";
import {NavigationExtras} from "@angular/router";
import {NavController} from "@ionic/angular";
import {AppConfig} from "../../conf/main";
import {BusinessService} from "../../services/BusinessService";
import {Events} from "../../../framework/providers/events";

@Component({
    selector: 'app-users-manager',
    templateUrl: './users-manager.page.html',
    styleUrls: ['./users-manager.page.scss'],
})
export class UsersManagerPage implements OnInit {
    public models: Array<AppUser> = [];
    public appuser: AppUser;
    public options: object = {};
    public prefix: string = 'usersList';
    public tablesData = {};

    public appConfig = AppConfig;
    isLargeScreen;

    tablExtData = {'showEdit': false, 'isOpen': false};
    filters = {
        'ordering': '-id',
        'type': 'dashboard',
        'subReportFilterType': 'appusersPref',
        'fulldetails': true,
        'prefix': 'appusers'
    };

    constructor(public navCtrl: NavController,
                public orderService: OrderService,
                public businessService: BusinessService,
                public events: Events) {
        this.appuser = this.orderService.getAppUser();
    }

    ngOnInit() {
        if (this.businessService.screenSize[0] > 1000) {
            this.isLargeScreen = true;
        }

        let me = this;
        this.events.subscribe('update_' + this.prefix, (data: any) => {
            console.log(this.prefix);
            console.log(data.models);

            me.models = data.models;

            let tablesData = {};
            let refresh = {};
            for (let key in me.models) {
                // let finalReportRes = me.models[key].data.finalResReport;
                // //console.logfinalReportRes);
                tablesData[me.models[key].data.name] = me.models[key];
                refresh[me.models[key].data.name] = true;
            }

            setTimeout(function () {
                me.tablesData = tablesData;
            })

            // let keys = ['leads', 'maker'];
            // let i = 1;
            // for (let key in keys) {
            //     if (refresh[keys[key]]) {
            //         this.getGraph(this.getViewCanvas(i),
            //             this.tablesData[keys[key]].data.finalResReport,
            //             this.tablesData[keys[key]].data.graphType);
            //     }
            //     i++;
            // }

            // //console.logthis.tablesData);
        });
    }

    goTo(page, data) {
        let navigationExtras: NavigationExtras = {
            queryParams: data
        };

        this.navCtrl.navigateForward(page, navigationExtras)
    }


}
