import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Deal} from "../../../../../framework/model/core/deal/deal";
import {DealGroup} from "../../../../../framework/model/core/deal/dealGroup";
import {ItemProvider} from "../../../item/providers/item-provider/item-provider.service";
import {AppDataService} from "../../../../services/AppDataService";
import {ModelToolsProvider} from "../../../../../framework/providers/model-tools/model-tools";

@Component({
    selector: 'app-deal',
    templateUrl: './deal.page.html',
    styleUrls: ['./deal.page.scss'],
})
export class DealPage implements OnInit {

    public modelId: any;
    public model: Deal;
    public groups: Array<DealGroup> = [];

    public checkedItems:object = {};

    constructor(public route: ActivatedRoute,
                public itemProvider:ItemProvider,
                public modelTools:ModelToolsProvider,
                public appDataService:AppDataService) {
        this.modelId = this.route.snapshot.paramMap.get("id");

        this.route.queryParams.subscribe(params => {
            // this.model = params['model'];
            if(!this.model) this.listDeal();
        });
    }

    ngOnInit() {
    }

    listDeal()
    {
        this.model = this.appDataService.getModelById('deals', 'deal', this.modelId);
    }

    addToCart()
    {
        let dealData = {
            // 'data': {
                'id': this.model.data.id,
                'title': this.model.data.name,
                'items': [],
                'prefix': this.model.data.id + '_' + Math.floor(Math.random() * Math.floor(1000000)),
            // },
                'price':this.model.data.price,
        }
        // let price = this.model.data.price;
        let mainGroup = this.groups[0];
        if(mainGroup) {
            let mainGroupData = this.checkedItems[mainGroup.data.id];
            let items = [];

            let propsPrice = 0;

            for (let g in this.checkedItems) {
                if (g == mainGroup.data.id)
                    continue;

                let itemdata = {
                    'product': this.checkedItems[g]['item'],
                    'price': 0,
                    'props': this.checkedItems[g]['props'],
                    'images': [],
                };


                let props = this.checkedItems[g]['props'];
                for (let k in props) {
                    for (let p in props[k]['checkedData']) {
                        if (props[k]['checked'][p])
                            propsPrice += props[k]['checkedData'][p]['price'];
                    }
                }

                items.push(itemdata)

            }

            dealData['price'] += propsPrice;

            dealData['items'] = items;

            if (!mainGroupData['item']) return;

            this.itemProvider.addToCart(mainGroupData['item'], 1, false, false, false, mainGroupData['props'], dealData)
            this.modelTools.goTo('back');
        }
            // dealData['price'] = 0; // after first item, all deal price already sets.
            // price = 0; // after first item, all deal price already sets.
    }

    initDeal()
    {

    }


}
