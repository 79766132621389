import {Component, Input, OnInit} from '@angular/core';
import {Item} from "../../../../../framework/model/core/items/item";
import {ActivatedRoute} from "@angular/router";
import {AppDataService} from "../../../../services/AppDataService";

@Component({
  selector: 'app-item-article',
  templateUrl: './item-article.page.html',
  styleUrls: ['./item-article.page.scss'],
})
export class ItemArticlePage implements OnInit {
  public model: Item;

  @Input()
  public modelId: any;

  constructor(public route: ActivatedRoute,
              public appDataService: AppDataService) {
    this.modelId = this.route.snapshot.paramMap.get("id");
  }

  ngOnInit() {
    this.model = this.appDataService.getItemModel(this.modelId);
    console.log(this.model)
  }

}
