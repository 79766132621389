import {Component, OnInit} from '@angular/core';
import {Item} from "../../../../../framework/model/core/items/item";
import {ActivatedRoute} from "@angular/router";
import {ModelProvider} from "../../../../../framework/providers/modelProvider/ModelProvider";
import {Events} from "../../../../../framework/providers/events";

@Component({
    selector: 'items',
    templateUrl: './items.page.html',
    styleUrls: ['./items.page.scss'],
})
export class ItemsPage implements OnInit {

    public options: object;
    public filters: object;
    public prefix = 'items_list';

    constructor(public route: ActivatedRoute, public modelProvider: ModelProvider, public events: Events) {
    }

    ngOnInit() {
        this.route.queryParams.subscribe(params => {
            this.initItems(JSON.parse(JSON.stringify(params)));
        });
    }
    ngAfterViewInit() {
        this.initItems();
    }

    initItems(filters= this.filters) {
        filters['setPrefix'] = true;
        this.filters = filters;
        this.events.publish(this.prefix + '_updateItemsFilter', this.filters);
    }
}
