export class Book {
    data = {
        "id": null,
        "name": null,
        "link": null,
        "description": null,
        "business": null,
        "item": null,
        "fileName": null,
        "itemName": null,
        "itemImage": null
    };

    constructor(public data1: any) {
        this.data = data1;
    }

    //shortHeaders = [];
    prefix = "book";
    title = "Books";
    dataFields = {
        id: {'title': 'ID', 'value': null, 'type': 'number', 'readonly': true},
        name: {'title': 'username', 'value': null, 'type': 'text'},
        item: {'title': 'item', 'value': null, 'type': 'text'},
        description: {'title': 'description', 'value': null, 'type': 'text'},
        business: {'title': 'business', 'value': null, 'type': 'text'},
        link: {'title': 'link', 'value': null, 'type': 'text'},
    }
}
