export class Neighborhood {
    data = {
        "id": null,
        "name": null,
        "city": null,
    };

    constructor(public data1: any) {
        this.data = data1;
    }

    //shortHeaders = [];
    prefix = "neighborhood";
    title = "neighborhood";
    dataFields = {
        id: {'title': 'ID', 'value': null, 'type': 'number', 'readonly': true},
        name: {'title': 'username', 'value': null, 'type': 'text'},
    }
}
