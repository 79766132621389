// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.flex-row.space-between * {
  flex: 1 1;
}

ion-item {
  font-size: 14px;
}

.invoice-forward {
  width: 40px;
  padding: 0;
  --padding-start: 0;
  --padding-end: 0;
}

.invoice-forward.big {
  width: 120px;
}`, "",{"version":3,"sources":["webpack://./src/core/pages/client-invoice/components/invoice/invoice.component.scss"],"names":[],"mappings":"AAAA;EACE,SAAA;AACF;;AAEA;EACE,eAAA;AACF;;AAEA;EACE,WAAA;EACA,UAAA;EACA,kBAAA;EACA,gBAAA;AACF;;AACA;EACE,YAAA;AAEF","sourcesContent":[".flex-row.space-between * {\n  flex: 1 1;\n}\n\nion-item {\n  font-size: 14px;\n}\n\n.invoice-forward {\n  width: 40px;\n  padding: 0;\n  --padding-start: 0;\n  --padding-end: 0;\n}\n.invoice-forward.big {\n  width: 120px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
