import {Component, Input, OnInit} from '@angular/core';
import {AppConfig} from "../../../../../core/conf/main";
import {AdminService} from "../../../../../core/services/AdminService";

@Component({
  selector: 'app-generic-models-menu',
  templateUrl: './generic-models-menu.component.html',
  styleUrls: ['./generic-models-menu.component.scss'],
})
export class GenericModelsMenuComponent implements OnInit {
  @Input()
  model;
  @Input()
  sectionName;
  @Input()
  exTableData;

  appConfig = AppConfig;

  constructor(public adminService:AdminService) { }

  ngOnInit() {}

}
