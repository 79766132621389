import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {AdminService} from "../../../../../core/services/AdminService";
import {Events} from "../../../../../framework/providers/events";
import {AppConfig} from "../../../../../core/conf/main";

@Component({
    selector: 'app-table-header',
    templateUrl: './table-header.component.html',
    styleUrls: ['./table-header.component.scss'],
})
export class TableHeaderComponent implements OnInit, OnDestroy {

    public appConfig = AppConfig;

    @Input()
    mini = false;
    @Input()
    prefix;
    @Input()
    sectionName;
    @Input()
    tableData;
    @Input()
    exTableData;
    @Input()
    tableInfoData;
    @Input()
    sideBoxMode;
    @Input()
    boxShow;
    @Input()
    boxShowEdit;
    @Input()
    filterTab;
    @Input()
    tableParams;
    @Input()
    showFields;
    @Input()
    showFieldsPage;
    @Input()
    orderbyField;
    @Input()
    clickThData;
    @Input()
    showFieldsPageLimit;
    @Input()
    markData = [];
    @Input()
    markDataById = {};

    initialized = false;
    ready = false;
    filterByField;

    constructor(public adminService: AdminService, public events: Events) {
    }

    ngOnInit() {
        this.events.subscribe('setUpHeaders' + this.prefix, (data) => {
            if (this.initialized) return;
            this.tableData = data['tableData'];
            this.tableInfoData = data['tableInfoData'];
            this.sideBoxMode = data['sideBoxMode'];
            this.boxShowEdit = data['boxShowEdit'];
            this.boxShow = data['boxShow'];
            this.filterTab = data['filterTab'];
            this.tableParams = data['tableParams'];
            this.showFields = data['showFields'];
            this.showFieldsPage = data['showFieldsPage'];
            this.markDataById = data['markDataById'];
            this.showFieldsPageLimit = data['showFieldsPageLimit'];
            this.clickThData = data['clickThData'];
            this.orderbyField = data['orderbyField'];
            this.markData = data['markData'];
            this.ready = true;
            this.initialized = true;

            // let me = this;
            // setTimeout(function () {
            //     me.loadQuickFilters();
            // }, 3000);
        });

        // this.loadQuickFilters();
    }

    ngOnDestroy() {
        this.events.unsubscribe('setUpHeaders' + this.prefix);
    }

    //on move table headers
    onDropTh(e, isDrop = false) {
        if (!this.clickThData) return;

        let x1 = this.clickThData['event']['clientX'];
        let x2 = e['clientX'];

        if (!this.clickThData['field']['startWidth']) this.clickThData['field']['startWidth'] = parseInt(this.clickThData['field']['fieldWidth']);

        if (this.appConfig.dir == 'ltr') {
            this.clickThData['field']['fieldWidth'] = (x2 - x1) + this.clickThData['field']['startWidth'];
        } else {
            this.clickThData['field']['fieldWidth'] = (x1 - x2) + this.clickThData['field']['startWidth'];
        }

        //? stop holding this field. set is over.
        if (isDrop) {

            let me = this;
            let clickThData = this.clickThData;
            // clearTimeout(this.saveTimeout);
            // this.saveTimeout = setTimeout(function () {
            me.adminService.saveField('modelField',
                {'id': {'value': clickThData['field']['fieldId']}, 'main': {}},
                {'type': 'text', 'name': 'fieldWidth', 'value': clickThData['field']['fieldWidth']},
                {'type': 'text', 'name': 'fieldWidth'});
            // }, 1000);

            me.clickThData['field']['startWidth'] = null;
            setTimeout(function () {
                me.clickThData = null;
            });
        }
        // let params = {}
        // params['fieldData'] = {
        //     'sectionName': this.sectionName,
        //     'fieldWidth': this.clickThData['field']['fieldWidth'],
        //     'fieldName': this.clickThData['field']['name'],
        // }
        // this.modelProvider.customPost('saveDataReport', [params], this.filters, true).then((data: any) => {
        // });

        e.stopPropagation();
    }


    //notify relation model
    checkRow(model) {
    }

    checkAll() {
        console.log('btb click!!')
        this.events.publish("btnclick_" + this.prefix, {'key': 'checkAll', 'data': {}});
    }


    expandAll() {
        let status = !!!this.tableData['main']['openBox'];

        for (let model of this.tableData) {
            model['main']['openBox'] = status;
        }
    }


    //order by
    orderby(key) {
        //on mode field size mode
        if (this.clickThData) return;

        if (this.orderbyField == key) {
            this.orderbyField = "-" + key;
        } else {
            this.orderbyField = key;
        }
        this.events.publish("btnclick_" + this.prefix, {'key': 'addFilter', 'data': {'orderBy': this.orderbyField}});
    }

    fieldClick(event, fieldType) {
        //    orderby(key)
        this.adminService.popupEditBox(event, this.sectionName, this.adminService.manageModels,
            'columnDisplay_etr', null, 'columnDisplay',
            false, null, fieldType, null, null, null,
            {'changeType': 'showTable', 'prefix': this.prefix});
    }

    // loadQuickFilters() {
    //     if (this.filterByField) return this.filterByField;
    //     if (this.exTableData[this.sectionName + 'QuickFilterByName']) {
    //         this.filterByField = this.exTableData[this.sectionName + 'QuickFilterByName'];
    //         return this.filterByField;
    //     }
    //
    //     let filters = this.exTableData[this.sectionName + 'QuickFilter'];
    //     if (!filters) return;
    //
    //     let filterByField = {};
    //     for (let filter of filters) {
    //         filterByField[filter.fieldKey] = filter;
    //     }
    //     this.filterByField = filterByField;
    //     this.exTableData[this.sectionName + 'QuickFilterByName'] = this.filterByField;
    // }

}
