import {Component, Input, OnInit} from '@angular/core';
import {Events} from "../../../../framework/providers/events";

//depricated
//depricated
//depricated
//depricated
//depricated

@Component({
    selector: 'app-one-to-many',
    templateUrl: './one-to-many.component.html',
    styleUrls: ['./one-to-many.component.scss'],
})
export class OneToManyComponent implements OnInit {

    @Input()
    exTableData;
    @Input()
    sectionName;
    @Input()
    prefix;
    @Input()
    tableParams;

    tableData;

    markData1 = {};
    markData2 = {};

    connectionType = true;

    constructor(public events: Events) {
    }

    ngOnInit() {
        this.tableData = this.exTableData[this.sectionName];
    }

    copyThat() {
        let fieldsIndex1 = this.tableParams['fieldsIndex'][0];
        let fieldsIndex2 = this.tableParams['fieldsIndex'][1];

        let copyModel = this.tableData[0];

        for (let key1 in this.markData1['markedRow']) {
            for (let key2 in this.markData2['markedRow']) {
                let newModel = JSON.parse(JSON.stringify(copyModel));
                newModel[0].value = null;

                newModel[fieldsIndex1]['value'] = this.markData1['markedRow'][key1];
                newModel[fieldsIndex2]['value'] = this.markData2['markedRow'][key2];
                newModel[0].updated = true;
                this.exTableData[this.sectionName].unshift(newModel);
            }
        }

        this.events.publish('addEditedSections', {
            'key': this.sectionName
        });
        //console.logthis.exTableData[this.sectionName]);
    }


}
