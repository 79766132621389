import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {AnimationController, NavController, PopoverController} from "@ionic/angular";
import {ModelProvider} from "../../../../framework/providers/modelProvider/ModelProvider";
import {AppConfig} from "../../../../core/conf/main";
import {ModelToolsProvider} from "../../../../framework/providers/model-tools/model-tools";
import {NavigationExtras} from "@angular/router";
import {AdminService} from "../../../../core/services/AdminService";
import {BusinessService} from "../../../../core/services/BusinessService";
import {Events} from "../../../../framework/providers/events";
import {MainService} from "../../../../core/services/MainService";
import * as moment from "moment";

@Component({
    selector: 'app-view-table',
    templateUrl: './view-table.component.html',
    styleUrls: ['./view-table.component.scss'],
})
export class ViewTableComponent implements OnInit, OnDestroy {
    scrollTop = 0;
    scrollLeft;
    onScroll = false;

    public appConfig = AppConfig;
    filters = {'ordering': '-id', 'type': 'moving', 'name': 'taarif', 'modelOffset': 0, 'modelLimit': 45};
    //current page for infinite page add
    pageRequests: number = 0;
    page: number = 1;
    rowsPerPage: number = 45;

    //hard limit
    @Input()
    limit;

    @Input()
    showTableHeaders = true;

    @Input()
    extraData;

    @Input()
    extraQueryParams;

    @Input()
    boxStyle;
    @Input()
    style;
    @Input()
    rowStyle = {};

    @Input()
    tabSizes;
    @Input()
    hideFilters;
    @Input()
    exTableData;

    @Input()
    sideBoxMode = false;

    @Input()
    sectionName = '';
    @Input()
    edit = false;
    @Input()
    prefix;

    //mark this rows by id. - for multichoose from appfilter
    @Input()
    markIds;

    //if selectTable for choosen list
    @Input()
    selectTable = false;

    //if subTable show
    @Input()
    subTable = false;
    @Input()
    onPopup = true;

    //specific table header
    @Input()
    tableHeadKeys = {};
    tableHeadKeysBox = [];
    newModel;

    @Input()
    spliteField = null;
    splitedKeys = null;
    splitedModels = null;

    @Input()
    showOptions = true;
    @Input()
    displayType = 'table';

    @Input()
    tableData;
    @Input()
    allowClickRow: boolean = true;
    @Input()
    tableParams: any;
    @Input()
    quickFilterData;
    @Input()
    parentKey;

    //should be: {'event':'exact event subscribe', 'name':'some name'}
    @Input()
    selectTablePrefix;

    orderbyField;
    searchText;

    @Input()
    boxColsize;
    @Input()
    showRowModel;

    @Input()
    markData = [];
    markDataById = {};

    //
    @Input()
    boxShowEdit = false;
    //
    @Input()
    openOnSide = false;

    //show box ? + box small - hide extra data from boxes. like general models relations. for very small views.
    @Input()
    boxShow = false;
    @Input()
    boxSmall = false;

    @Input()
    rowClickEvent;
    @Input()
    exModel;
    @Input()
    baseFilter;
    @Input()
    extraFilters;
    @Input()
    extraSaveFilters;

    baseFilters = {};

    @Input()
    refreshOnStart;
    @Input()
    mapMode = false;
    mapModeTableParams = {};

    @Input()
    showMap = false;

    //if this table is in select-bar-view Table type - update num of rows of the "fieldData" from the opener table.
    //main table -> select-bar-view -> this table | update main table of this table count rows.
    @Input()
    exCounterFieldData;

    @Input()
    specificBoxFields;

    isCalendarList = false;
    isMapList = false;

    @Input()
    myprefix = "selectTablePrefix3" + Math.floor(Math.random() * Math.floor(1000000));
    subTopicEvent = "selectTablePrefix3" + Math.floor(Math.random() * Math.floor(1000000));


    //
    //
    //
    //
    newModels;

    //insurance calc
    //number auto add calc
    showCalc;

    //on filter top of table
    fieldFunnelModel = {'main': {}};

    //if need to show quickAdd above table
    quickAddModel;
    currentChosenSubModel;
    currentChosenModel;
    currentChosenFieldData;

    //filter mode - selected model to filter
    @Input()
    lastSelectedModel;

    //on add, it waits and clear the "add popup" to wait open multiple at the same time
    addPopupTimer;

    clickThData;
    showFields;

    editMode = false;
    showFieldsPage = 1;
    @Input()
    showFieldsPageLimit = 28;

    showFieldKeys;

    @Input()
    customFilter = {};

    @Input()
    cantCheck;
    @Input()
    showOpenBox = false;

    tableInfoData;

    //if checked relation table models - show add button.
    addRelationModelMode;

    filterModels;//field on the right filter side
    filterField;
    fileDownload;
    currentSideFilter;
    sideFilter;
    copiedRow;
    ready = false;
    showLoading = true;
    rowClickEventPrefix = "selectTablePrefixrowClickEvent" + Math.floor(Math.random() * Math.floor(1000000));
    tableSelectTablePrefix = "selectTablePrefix" + Math.floor(Math.random() * Math.floor(1000000));
    onMultiSelectIndex;
    chooseFieldData;
    chooseFieldType;


    //show boxes :/
    @Input()
    filterTab;
    @Input()
    autoQuickAdd = false;
    @Input()
    autoShowMap = false;

    tabSize;

    //fields for copy
    defaultFieldData;
    deFData;

    tmpModel;

    //if side filter = keeps filter details
    searchMode;
    keepFilter;
    viewFieldParams = {'edit': false, 'fullView': false};

    //show only boxshow fields in table at start.
    startView = false;
    saveTimeout;
    setScrollTimeout;

    //fields type by name
    fieldTypeByName;

    relationTableData; //extra data for relationTableData - show side table for quick add
    showRelationTableData = false; //extra data for relationTableData - show side table for quick add

    startShowIndex = 0;
    scrollHeight = 600;
    rowStock = [];
    rowStockInterval = null;
    markFieldMode = null;
    //create real animation on screen - change css
    //animation
    // @ViewChild('loadingIcon', {read: ElementRef}) loadingIcon: ElementRef;
    //
    // setTimeout(function () {
    //     const loadingAnimation = me.animationCtrl.create('loading-animation')
    //         .addElement(me.loadingIcon.nativeElement)
    //         .duration(1500)
    //         .iterations(3)
    //         .fromTo('transform', 'rotate(0deg)', 'rotate(360deg)');
    //
    //     // Don't forget to start the animation!
    //     loadingAnimation.play();
    // }, 2000);

    constructor(public events: Events, public modelProvider: ModelProvider,
                public adminService: AdminService,
                public mainService: MainService,
                public popoverCtrl: PopoverController,
                public businessService: BusinessService,
                public animationCtrl: AnimationController,
                public modelTools: ModelToolsProvider, public navCtrl: NavController) {
    }

    ngOnInit() {
        let me = this;

        this.adminService.setCheckModel(this.sectionName);

        this.viewFieldParams = {
            'edit': false,
            'fullView': false
        };

        if (this.exTableData) {
            this.fieldTypeByName = this.adminService.getFieldsType(this.sectionName, this.exTableData);
        }

        this.filters[this.sectionName + 'modelLimit'] = this.filters['modelLimit'];
        if (this.limit) {
            this.filters[this.sectionName + 'modelLimit'] = this.limit;
        }

        if (!this.tableParams) {
            this.tableParams = {'exFieldData': {}}
        }

        if (this.tableParams['exFieldData'] && this.tableParams['exFieldData']['boxShow']) {

            //console.log'box shoWWW');
            //console.logthis.tableParams['exFieldData']);

            // this.boxShow = true;
            this.tabSize = this.tableParams['exFieldData']['tabSize'];
            if (!this.tabSize) this.tabSize = 12;
        }


        if (this.adminService.manageModels && this.adminService.manageModels[this.sectionName + 'MarkData']) {
            this.markData = this.adminService.manageModels[this.sectionName + 'MarkData'];
            this.markDataById = this.adminService.manageModels[this.sectionName + 'MarkDataById'];

            if (!this.markDataById) this.markDataById = {};
        }
        if (!this.markData['markedRow']) {
            this.markData['markedRow'] = [];
        }
        if (!this.markData['markedCol']) {
            this.markData['markedCol'] = [];
            this.markData['removedRow'] = [];
        }
        ``
        this.adminService.manageModels[this.sectionName + 'MarkData'] = this.markData;
        //???
        this.events.publish('addEditedSections', {'key': this.sectionName});

        //if init with row model. copy real one form tabledata.
        if (this.showRowModel) {
            for (let k in this.tableData) {
                if (this.tableData[k][0]['value'] == this.showRowModel[0]['value']) {
                    this.showRowModel = this.tableData[k];
                }
            }
        }

        this.listen();

        if (this.exTableData && this.exTableData[this.sectionName + 'Data']) {
            this.tableInfoData = this.exTableData[this.sectionName + 'Data'];
        }
        if (!this.tableInfoData) {
            this.tableInfoData = {};
            this.exTableData[this.sectionName + 'Data'] = this.tableInfoData;
            this.exTableData[this.sectionName] = [];
        }
        if (!this.exTableData[this.sectionName + 'Ref']) this.exTableData[this.sectionName + 'Ref'] = []

        //box show on small screens!
        if (this.tableInfoData['boxShow'] || this.businessService.screenSize[0] < 768) {
            this.boxShow = true;
        }

        if (this.tableInfoData['boxTableFieldsShow']) {
            ////console.log'start view!!');
            this.startView = true;
        }

        // if (this.tableInfoData['sideFilter']) {
        //     // this.currentSideFilter = this.tableInfoData['filterOptions'][this.tableInfoData['sideFilter']][0];
        //     this.showRelationTableData = true;
        // }

        // if (this.tableParams['sideFilterTable']) {
        //     if (!this.tableInfoData['filterOptions']) this.tableInfoData['filterOptions'] = {};
        //     this.tableInfoData['sideFilter'] = this.tableParams['sideFilterTable'];
        //
        //     let extable = this.exTableData[this.tableParams['sideFilterTable']];
        //     this.sideFilter = extable;
        // }
        if (this.tableInfoData['filterModels']) {
            this.filterModels = this.tableInfoData['filterModels'];
            // this.filterTab = this.tableInfoData['filterModels'][0]['sideFilterTable'];
        }
        if (this.tableInfoData['rowStyle']) {
            for (let k in this.tableInfoData['rowStyle']) {
                this.rowStyle[k] = this.tableInfoData['rowStyle'][k];
            }
        }

        //refresh on start?
        if (this.tableParams['exFieldData'] || this.tableParams['refresh']) {
            let filters = {};
            if (this.tableParams['exFieldData']['extraFilter']) {
                filters = this.tableParams['exFieldData']['extraFilter'];
                filters['sectionName'] = this.sectionName;

                // this.events.publish('refresh_request_' + this.prefix, filters);
            }
        }

        if (this.autoQuickAdd || this.tableInfoData['autoQuickAdd']) {
            // me.createQuickAdd();
            me.quickAddModel = me.getNewModel();
        }
        if (this.autoShowMap) {
            //for new filter will be ready refreshing.
            // setTimeout(function () {
            me.showModelsOnMap();
            // }, 750);
        }

        if (this.refreshOnStart || !this.tableData) {
            this.ready = false;
            //mark as first request and not change page or something. for load extra data.
            let f = JSON.parse(JSON.stringify(this.filters));
            f['firstRequest'] = this.subTable ? false : true;
            this.refresh(f, null, false, {'loadExtraData': true});
            //load select fields options data
            // setTimeout(function () {
            //     me.getRquestSelectFields(me.filters);
            // }, 3000);
        } else {
            console.log(':boxShow 2:' + this.boxShow + ' - ' + this.sectionName)
            this.loadRelationModelData();
            this.setHideLoading();

            setTimeout(function () {
                me.listenHeaders();
            }, 100);

            this.ready = true;
            // this.createQuickAdd();
            //load select fields options data - pre loaded ?
            // this.onResultModels(this.exTableData);
        }

        if (this.tableData && this.exTableData) {
            this.defaultFieldData = this.deFData = this.exTableData[this.sectionName + 'Ref'];
            this.setTableKeys();
        }
        // if (this.exTableData && this.exTableData[this.sectionName + 'Data']) {
        //     this.quickAddShow = this.exTableData[this.sectionName + 'Data']['quickAdd'];
        // }

        //
        setTimeout(function () {
            me.viewFieldParams['fullView'] = true;
        }, 100);

        //auto open minibox?
        if (this.showOpenBox || this.tableInfoData['autoOpenBox'] ||
            (this.tableParams['exFieldData'] && this.tableParams['exFieldData']['autoOpenBox'])) {
            this.adminService.openModels[this.sectionName + this.prefix] = true;
        }
    }

    listen() {
        let me = this;

        this.events.subscribe('prevPage' + this.prefix, data => {
            this.nextPage(true);
        });
        this.events.subscribe('nextPage' + this.prefix, data => {
            this.nextPage();
        });
        this.events.subscribe('nextPage' + this.prefix + 'SubTable', data => {
            this.nextPage();
        });

        this.events.subscribe("btnclick_" + this.myprefix, (keyData) => {
            me.btnClick(keyData);
        }, this.subTopicEvent);

        this.events.subscribe("onScroll", (scroll) => {
            //backwards?
            // console.log(me.scrollLeft + ' , ' + scroll[0])
            // if (scroll[0] < -200) {
            //     me.scrollLeft = 1400;
            //     me.scrollTop = 1400;
            // }
            //

            let event = scroll[2];


            if (me.scrollLeft != scroll[0]) {
                me.onScroll = true;
                clearTimeout(me.setScrollTimeout);
                me.setScrollTimeout = setTimeout(function () {
                    me.onScroll = false;
                }, 400);
            }
            me.scrollLeft = scroll[0];
            me.scrollTop = scroll[1];

            let startIndex = Math.round(event.detail.scrollTop / 31);
            this.startShowIndex = startIndex;

            if (event.detail['contentHeight'] && this.scrollHeight != event.detail['contentHeight'])
                this.scrollHeight = event.detail['contentHeight'];
            // console.log(me.scrollTop)
            // me.currentScrollTop = me.scrollTop;
            // me.pageStartIndex = me.scrollTop;
            // console.log(me.scrollTop-me.currentScrollTop)
            // this.pageStartIndex = Math.floor(me.scrollTop / 50);
        }, this.subTopicEvent);

        this.events.subscribe('globalSaveClick', () => {
            this.saveCurrentChanges();
        }, this.subTopicEvent);

        //save from view-field
        // this.events.subscribe('save' + this.myprefix, (data) => {
        ////console.log'save!!');
        ////console.logdata);

        //when other rows are marked checked - show the new value on them quick. this data will be removed.
        // this.saveFieldView(data['model'], data['fieldType'], data['fieldData']);

        // clearTimeout(me.saveTimeout);
        // me.saveTimeout = setTimeout(function () {
        //     me.saveCurrentChanges();
        // this.waitForSave.push([data['model'], data['fieldType'], data['fieldData']]);

        // }, 2000);
        // });
        // on close event from outter button click
        this.events.subscribe('clickTableRowBack', () => {
            this.showRowModel = null;
        }, this.subTopicEvent);

        //for side filter add button
        this.events.subscribe(this.rowClickEventPrefix, (data) => {
            let model = data['model'];
            let subParams = data['subParams'];
            this.addToForigenKeyModel(model[0]['value'], subParams);
        }, this.subTopicEvent);

        //event to pages component
        // this.events.subscribe('update_' + this.prefix, (data) => {
        //     this.events.publish('update_' + this.myprefix, data);
        // });

        //
        //
        //refresh_request_
        //event from pages component
        this.events.subscribe('refresh_request_' + this.myprefix, (filters) => {
            // console.log('refresh!!!', this.sectionName)
            // this.onUpdateReady();
            // this.events.publish('refresh_request_' + this.prefix, filters);
            this.refresh(filters, null, true);
        }, this.subTopicEvent);

        //event from box component
        this.events.subscribe('table_refresh_request_' + this.myprefix, (filters) => {
            // this.onUpdateReady();
            // this.events.publish('refresh_request_' + this.prefix, filters);
            this.refresh(filters, null, true);
        }, this.subTopicEvent);

        //from side filters and select tables
        this.events.subscribe(this.tableSelectTablePrefix, (data) => {
            me.onSelectTablePrefix(data);
        }, this.subTopicEvent);

        //on edit from view-field component click
        this.events.subscribe('openTableModelOptions' + this.prefix, (data) => {
            // //////console.logdata);
            this.selectModel(data['model'], data['colIndex'], data['fieldData']);
        }, this.subTopicEvent);

        // listen to select popup to save multi check models.
        this.events.subscribe('closeSelectPopup' + this.prefix, (data) => {
            me.saveMultiModel();
            me.chooseFieldData = null;
        }, this.subTopicEvent);

        // active side filter on to admin-model-page
        this.events.subscribe('toSideFilterMode' + this.myprefix, (active) => {
            //console.log'set table show side filter:/************* ', active);
            this.filterTab = active;
        }, this.subTopicEvent);

        // #listen_to_checked_relation_models
        //listen to check relation model
        this.events.subscribe('checkRowChange' + this.prefix, (data) => {
            //console.log'checkRowChange!!!!', this.sectionName);
            if (data['sectionName'] == this.sectionName) {
                return;
            }

            //{'model': period, 'checkedRows': this.markData['markedRow']}
            if (data['sectionName'] && this.tableInfoData && this.tableInfoData['filterModels']) {
                for (let sideFilter of this.tableInfoData['filterModels']) {
                    if (data['sectionName'] == sideFilter['sideFilterTable']) {
                        if (data['checkedRows'].length > 0) {
                            this.addRelationModelMode = {'data': data, 'sideFilter': sideFilter};
                        } else {
                            this.addRelationModelMode = false;
                        }
                    }
                }
            }
        }, this.subTopicEvent);

        this.events.subscribe('closeOpenField_' + this.myprefix, (data) => {
            me.chooseFieldData = null;
        });

        if (this.mapMode) {
            //me.myprefix(map) + '_map_refresh'
            this.events.subscribe(this.myprefix + '_map_refresh', (params) => {
                console.log('map !!!!');
                this.addFilter();
            });
        }
    }


    ngOnDestroy(): void {
        clearInterval(this.rowStockInterval);
        if (this.mapMode) {
            this.events.unsubscribe(this.myprefix + '_map_refresh');
        }
        this.events.unsubscribe('closeOpenField_' + this.myprefix);
        this.events.unsubscribe('prevPage' + this.prefix);
        this.events.unsubscribe('nextPage' + this.prefix);
        this.events.unsubscribe('nextPage' + this.prefix + 'SubTable');

        this.events.unsubscribe("btnclick_" + this.myprefix, this.subTopicEvent);
        if (this.relationTableData) {
            this.events.unsubscribe(this.relationTableData['sideFilterPrefix'], this.subTopicEvent);
        }
        this.events.unsubscribe("onScroll", this.subTopicEvent);
        // this.events.unsubscribe('save' + this.prefix);
        //////////console.log'on destroy table!!!', this.prefix);
        // this.events.unsubscribe('update_' + this.prefix);
        // this.events.unsubscribe('refresh_request_' + this.myprefix);
        this.events.unsubscribe('clickTableRowBack', this.subTopicEvent);
        this.events.unsubscribe(this.rowClickEventPrefix, this.subTopicEvent);
        this.events.unsubscribe('refresh_request_' + this.myprefix, this.subTopicEvent);
        this.events.unsubscribe('toSideFilterMode' + this.myprefix, this.subTopicEvent);
        this.events.unsubscribe('table_refresh_request_' + this.myprefix, this.subTopicEvent);
        this.events.unsubscribe(this.tableSelectTablePrefix, this.subTopicEvent);
        this.events.unsubscribe('checkRowChange' + this.prefix, this.subTopicEvent);
        this.events.unsubscribe('globalSaveClick' + this.prefix, this.subTopicEvent);
        this.events.unsubscribe('openTableModelOptions' + this.prefix, this.subTopicEvent);
        this.events.unsubscribe('closeSelectPopup' + this.prefix, this.subTopicEvent);
    }


    getRquestSelectFields(filters) {
        let me = this;
        filters['getModelSelectTableOptions'] = this.sectionName;
        this.modelProvider.list('report', filters)
            .then(function (data: any) {
                let extableData = data['models'][0].data['finalResReport'];
                let modelSelectOptions = extableData['modelSelectOptions']
                for (let k in modelSelectOptions) {
                    me.exTableData[k] = modelSelectOptions[k];
                }
                //console.logme.exTableData);
            });
    }

    loadRelationModelData() {
        //from which side of the relation table we have got here.
        let exSectionName = this.tableParams['exModelSectionName'];

        //relationTable - filter
        if (this.exTableData[this.sectionName + 'Data']['sideFilter']) {
            this.showRelationTableData = true;
            this.relationTableData = this.exTableData[this.sectionName + 'Data']['sideFilter'];
            this.relationTableData['customFilter'] = this.relationTableData['customFilter'];
            this.relationTableData['desc'] = this.relationTableData['desc'];
            this.relationTableData['sideFilterOnly'] = true;
            this.relationTableData['sideFilterPrefix'] = "prefix3" + Math.floor(Math.random() * Math.floor(1000000));
            let me = this;
            this.events.subscribe(this.relationTableData['sideFilterPrefix'], function (data) {
                //filter only?
                if (me.relationTableData['sideFilterOnly']) {

                    let rowModel = data['rowModel'];
                    me.selectFilterModel(rowModel);

                    let filter = {};
                    filter['value'] = rowModel['id']['value'];
                    filter['field'] = me.relationTableData['sideFilterKeyFix'];
                    me.customFilter['filters']['quickFieldFilter'] = [filter];

                    //'filters': {'quickFieldFilter': [filters]}
                    me.refresh({}, null, true, {'userFilters': 1});
                }
            }, this.subTopicEvent);
        }

        // else.. :

        //side table add
        if (this.exTableData[this.sectionName + 'Data']['relationTable']) {
            this.relationTableData = this.exTableData[this.sectionName + 'Data']['relationTable'];

            //need to switch main field? for opening the other table. we dont know which
            //example: both models:item&section are connected to itemToSection model (this model)

            if (this.relationTableData['sideFilterTable1'] == exSectionName) {
                this.relationTableData['sideFilterTableFix'] = this.relationTableData['sideFilterTable2'];
                this.relationTableData['sideFilterKeyFix'] = this.relationTableData['sideFilterrKey2'];
                this.relationTableData['sideFilterTable1Id'] = this.tableParams['exModel']['id']['value'];
            } else {
                this.relationTableData['sideFilterTableFix'] = this.relationTableData['sideFilterTable1'];
                this.relationTableData['sideFilterKeyFix'] = this.relationTableData['sideFilterrKey1'];
                if (this.tableParams['exModel']) this.relationTableData['sideFilterTable2Id'] = this.tableParams['exModel']['id']['value'];
            }

            this.relationTableData['sideFilterPrefix'] = "prefix3" + Math.floor(Math.random() * Math.floor(1000000));

            let me = this;

            // on choose row of side table
            this.events.subscribe(this.relationTableData['sideFilterPrefix'], function (data) {
                if (me.relationTableData['sideFilterTable1'] == exSectionName) {
                    me.relationTableData['sideFilterTable2Id'] = data['rowModel']['id']['value'];
                } else {
                    me.relationTableData['sideFilterTable1Id'] = data['rowModel']['id']['value'];
                }

                let filters = me.filters;

                if (me.extraSaveFilters) {
                    filters['extraSaveFields'] = JSON.stringify(me.extraSaveFilters);
                }

                if (me.baseFilter) {
                    filters['extraSaveFilters'] = JSON.stringify(me.baseFilter);
                }

                me.adminService.addRelationModelConnection(me.sectionName, me.myprefix,
                    me.relationTableData, me.exTableData, filters);

            });
        }
    }

    //order fields
    setTableKeys() {
        this.defaultFieldData = this.deFData = this.exTableData[this.sectionName + 'Ref'];
        // console.log('this.defaultFieldData:', this.defaultFieldData)

        // //console.logthis.deFData);
        // //console.logthis.sectionName);

        if (!this.tableHeadKeys['hideFields']) this.tableHeadKeys['hideFields'] = [];

        this.deFData.sort(function (a, b) {
            return b['order'] - a['order'];
        });

        //get all fields keys no more than 20
        this.showFieldKeys = [];
        this.showFields = [];
        if (this.tableData) {
            for (let i in this.deFData) {
                // if (!this.deFData[i]['showTable']) {
                //     continue
                // }
                if (this.tableData[0]) {
                    if (!this.tableData[0][this.deFData[i]['name']]) {
                        continue
                    }
                }

                // //console.log'check ', this.deFData[i]['name'], '-', this.deFData[i]['showTable'])

                if (this.tableHeadKeys['showFields'] && this.tableHeadKeys['showFields'].indexOf(this.deFData[i]['name']) != -1) {
                    this.showFieldKeys.push(parseInt(i));
                    this.showFields.push(this.deFData[i]);
                    // //console.log'add' + this.deFData[i])
                } else if (!this.tableHeadKeys['showFields'] && this.showFieldKeys.length < 25) {

                    //show table
                    if (this.deFData[i]['showTable']) {
                        //no hides defined or not in hidden fields
                        if (this.tableHeadKeys['hideFields'].indexOf(this.deFData[i]['name']) == -1) {
                            //this.viewFieldParams['fullView'] &&
                            // if (!(this.boxShowEdit || this.filterTab || this.boxShow)) {
                            this.showFieldKeys.push(parseInt(i));
                            this.showFields.push(this.deFData[i]);
                            // }
                        }
                    }

                    //showInBox
                    if (this.deFData[i]['showInBox']) {
                        //no hides defined or not in hidden fields
                        if (this.tableHeadKeysBox.indexOf(this.deFData[i]['name']) == -1) {
                            this.tableHeadKeysBox.push(this.deFData[i]['name']);
                        }
                    }
                }
            }
        }
        if (this.tableData) {

            //fields order by - first col seconed col
            // for (let key in this.tableData) {
            // this.tableData.sort(function (a, b) {
            //     return b['order'] - a['order'];
            // });
            // }
            //
        }

        if (this.tabSizes) {
            this.tableInfoData['tabSizes'] = this.tabSizes;
        }
    }

    onSelectTablePrefix(data) {
        // ////////console.logthis.sectionName);
        // ////////console.logdata);
        //quickFilterData

        // if (this.onMultiSelectIndex) {
        //     this.onMultiSelect(data);
        //     return;
        // }
        if (data['quickFilterData']) {
            // ////////console.log0);

            // if (data['quickFilterData']['filterType'] == 'direct') {
            //     // ////////console.log1);
            //     this.filterByForigenKey(data['quickFilterData']['fieldData'], data['rowModel']);
            // } else {

            //rowModel = trip
            let rowModel = data['rowModel'];

            // ////////console.log2);
            this.filterByForigenKey(data['quickFilterData'], rowModel);

            // }
        } else {
            this.chooseFieldData['value'] = data.rowModel[0]['value'];//id
            this.chooseFieldData['displayName'] = data.rowModel[0]['value'];//tmp need to show relevant field name
            this.chooseFieldData = null;
        }
    }

    btnClick(keyData) {
        console.log('button click!!!')
        console.log(keyData)
        let me = this;

        let key = keyData['key'];
        let data = keyData['data'];
        //console.log('btnclick_' + key);
        //console.log(data);

        if (key == 'otherToolbarPopup') {
            this.popupEditBox(null, null, 'other', false);
        } else if (key == 'add') {
            this.createQuickAdd();
        } else if (key == 'startViewToggle') {
            if (this.tableInfoData['boxTableFieldsShow']) {
                setTimeout(function () {
                    me.startView = !me.startView;
                }, 1000);
            }
        } else if (key == 'calc') {
            this.addCalcData(data['type']);
        } else if (key == 'boxShow') {
            if (this.displayType == 'table') {
                this.displayType = 'splitedField';
            } else if (this.displayType == 'splitedField') {
                this.displayType = 'large';
            } else {
                this.displayType = 'table';
                this.boxShow = false;
            }

        } else if (key == 'save') {
            this.saveCurrentChanges();
        } else if (key == 'showCalc') {
            this.showCalc = !!!this.showCalc;
        } else if (key == 'openAll') {
            this.adminService.openModels[this.sectionName + this.myprefix] = !!!this.adminService.openModels[this.sectionName + this.myprefix];
        } else if (key == 'checkAll') {
            this.checkAll();
        } else if (key == 'rightPage') {
            this.showFieldsPage++;
        } else if (key == 'leftPage') {
            this.showFieldsPage--;
        } else if (key == 'editMode') {
            this.editMode = !!!this.editMode;
            // for (let period of this.tableData) {
            //     for (let colIndex of this.showFieldKeys) {
            //         this.fieldEdit(period, this.deFData[colIndex], period[this.deFData[colIndex]['name']]);
            //     }
            // }
        } else if (key == 'setSpliteField') {
            this.splitModels(data['spliteField']);
            this.displayType = 'splitedField';
        } else if (key == 'checkRow') {
            if (me.isMapList) return;
            this.checkRow(data['model']);
        } else if (key == 'clickRow') {
            this.clickRow(data['rowModel'], data['rowIndex'], data['forceEdit'], data['forceBigPopup'], data['event']);
        } else if (key == 'copy') {
            this.copyRow();
        } else if (key == 'paste') {
            this.pasteRow();
        } else if (key == 'addFilter') {
            if (me.isMapList) return;
            this.addFilter(data);
        } else if (key == 'showCalendar') {
            me.isCalendarList = !me.isCalendarList;
        } else if (key == 'showMap') {
            me.showModelsOnMap();
        } else if (key == 'remove') {
            this.removeModel();
        } else if (key == 'refresh') {
            if (me.isMapList) return;
            this.refresh();
        } else if (key == 'edit') {
            this.edit = !this.edit;
            this.viewFieldParams['edit'] = !this.viewFieldParams['edit'];
        } else if (key == 'clearFilters') {
            // this.onUpdateReady();
            me.tableData = [];
            this.removeFilter();
            this.quickFilter({'filterType': 'all'}, 'all');
        } else if (key == 'exprt_report') {
            let filters = this.getFilersForServer(null);
            //console.logfilters);

            let me = this;
            this.modelProvider.download('downloadTable', filters).then(function (res) {
                me.fileDownload = res['savePath'];
            });

        } else if (key == 'search') {
            this.searchText = data['searchText'];
            this.refresh({}, null, true);

        } else if (key == 'customFilter') {
            this.onUpdateReady();
            // this.customFilter = data; //tmp comment..
            // this.events.publish('refresh_request_' + this.prefix, data);
            this.refresh(this.customFilter, null, true);
        }
    }

    //hastag # to same comment list above
    // #listen_to_checked_relation_models
    //add pre defined relation marked models to seleced button model
    // create relation model and save it.
    addRelationModelConnection(model) {
        //console.logthis.addRelationModelMode);
        //console.logthis.exTableData);

        //relation model table

        let sideFilter = this.addRelationModelMode['sideFilter'];
        let markedRows = this.addRelationModelMode['data']['checkedRows'];

        let relationModel = sideFilter['relationModel'];
        let relationModelOwnKey = sideFilter['relationModelOwnKey'];
        let sideFilterKeyPrefix = sideFilter['sideFilterTablePrefix'];
        let countFieldName = sideFilter['countFieldName'];
        //console.log'countFieldName:' + countFieldName);

        let fieldTypeByName = this.adminService.getFieldsType(relationModel, this.exTableData);

        let newModels = [];

        if (markedRows.length > 0) {
            for (let id of markedRows) {
                // let ref = this.exTableData[relationModel + 'Ref'];
                let newModel = {
                    'id': {'value': null},
                    'main': {'updated': true},
                };
                newModel[sideFilterKeyPrefix] = {
                    'name': sideFilterKeyPrefix,
                    'edited': true,
                    'value': id,
                    'type': fieldTypeByName[sideFilterKeyPrefix]['type'],
                    'typeData': fieldTypeByName[sideFilterKeyPrefix]
                };
                newModel[relationModelOwnKey] = {
                    'name': relationModelOwnKey,
                    'edited': true,
                    'value': model['id']['value'],
                    'type': fieldTypeByName[relationModelOwnKey]['type'],
                    'typeData': fieldTypeByName[relationModelOwnKey]
                };

                newModels.push(newModel);
            }
            this.filters['editedSections'] = [relationModel];
            this.filters['sectionName'] = relationModel;

            let tableData = {};
            tableData[relationModel] = newModels;
            //console.logmodel);
            this.modelProvider.customPost('saveDataReport', [tableData], this.filters, true).then((data: any) => {
                // data = data['data'];
                //console.log'saved!!');
                if (!model[countFieldName]['value']) model[countFieldName]['value'] = 0;
                model[countFieldName]['value'] += newModels.length;
                model[countFieldName]['displayName'] = model[countFieldName]['value'];
            });
        }

    }


    checkAll() {
        console.log('checkAll!!')
        for (let i in this.tableData) {
            // this.markSubModel('markedRow', this.tableData[i]);
            this.checkRow(this.tableData[i]);
        }
    }

    checkRow(model) {
        if (this.tableInfoData['cantCheck'] || this.cantCheck) return;

        console.log('check row!!!', model)
        // model['main']['isMarked'] = !!!model['main']['isMarked'];

        this.markSubModel('markedRow', model);
        this.adminService.checkModel(this.sectionName, 'markedRow', model);

        this.events.publish('checkRowChange' + this.prefix, {
            'sectionName': this.sectionName,
            'model': model,
            'checkedRows': this.markData['markedRow']
        });
        this.events.publish('checkRowChange' + this.myprefix, {
            'sectionName': this.sectionName,
            'model': model,
            'checkedRows': this.markData['markedRow']
        });
    }

    markSubModel(markTypeKey, model) {
        let id = model['id'].value;

        if (!this.markData[markTypeKey]) {
            this.markData[markTypeKey] = [];
        }

        if (this.markData[markTypeKey].indexOf(id) !== -1) {
            this.markData[markTypeKey].splice(this.markData[markTypeKey].indexOf(id), 1);
        } else {
            this.markData[markTypeKey].push(model['id'].value);
            this.markDataById[id] = model;
        }
        this.adminService.manageModels[this.sectionName + 'MarkData'] = this.markData;
        this.adminService.manageModels[this.sectionName + 'MarkDataById'] = this.markDataById;
    }

    createQuickAdd(force = false) {
        if (this.exTableData[this.sectionName + 'Data']['quickAddFields']) {
            if (this.quickAddModel && !force) {
                this.quickAddModel = null;
            } else {
                this.quickAddModel = null;
                let me = this;
                setTimeout(function () {
                    me.quickAddModel = me.getNewModel();
                });
            }
        } else {
            this.addSubModel();
        }
    }

    getNewModel() {
        let newModel = {'main': {'value': -1, 'updated': true, 'name': '', 'type': 'none'}};
        let refmodel = this.exTableData[this.sectionName + 'Ref'];
        for (let fieldData of refmodel) {
            ////console.logfieldData);
            newModel[fieldData['name']] = {
                'value': null,
                'name': fieldData['name'],
                'edited': true,
                'type': fieldData['type']
            }
            if (fieldData['optionsActive']) newModel[fieldData['name']]['optionsActive'] = [];
        }
        newModel['id']['value'] = null;
        return newModel;
    }

    removeAllMarked() {
        for (let i in this.markData['markedRow']) {
            let id = this.markData['markedRow'][i];
            let tmodel = this.markDataById[id];
            tmodel['main']['isMarked'] = false;
        }

        this.markData['markedRow'] = [];

        //see checkRowChange: checkRow(period)
        this.events.publish('checkRowChange' + this.prefix, {
            'sectionName': this.sectionName,
            'checkedRows': this.markData['markedRow']
        });

        //myprefix
        this.events.publish('checkRowChange' + this.myprefix, {
            'sectionName': this.sectionName,
            'checkedRows': this.markData['markedRow']
        });
    }

    setShowLoading() {
        this.showLoading = true;
        // this.ready = false;
        this.events.publish('showloading_' + this.myprefix);
        this.events.publish('showGlobalLoading');
    }

    setHideLoading() {
        let me = this;
        me.ready = true;
        this.showLoading = false;
        this.events.publish('hideGlobalLoading');
        this.events.publish('hideloading_' + this.myprefix);
    }

    //infinite page sucsribe in init data
    nextPage(prev = false) {
        // console.log('wiil move? event..')
        // !prev &&
        if (this.page < (this.tableInfoData['totalCount'] / this.rowsPerPage)) {
            this.page++;
            this.refresh();
        }
        // else if (this.page > 1) {
        //     this.page--;
        //     return;
        // } else {
        //     this.setHideLoading();
        //     return;
        // }

        // if (((this.page+1) * this.rowsPerPage) <= this.tableData.length) {
        //     return;
        // }

    }

    refresh(mfilters = {}, callback = null, reset = false, extraFilter = {}) {
        if (reset) {
            this.page = 1;
            this.adminService.manageModels[this.sectionName + 'addCount' + this.prefix] = 0;
        }

        this.markData['removedRow'] = [];
        this.markData['markedRow'] = [];
        this.markData['markedCol'] = [];
        let filters = this.getFilersForServer(mfilters);
        for (let k in extraFilter) {
            filters[k] = extraFilter;
        }

        filters['extraQueryParams'] = this.extraQueryParams ? JSON.stringify(this.extraQueryParams) : '';
        filters['boxShowEdit'] = this.boxShowEdit;
        filters['flat'] = true;

        //////console.log'extra filters!!!!')
        //////console.logfilters['extraSaveFilters']);

        // let res = this.adminService.getResult(this.sectionName);
        // if (res && !force) {
        //     //////console.log'cache result for ', this.sectionName);
        //     this.onResultList(res, callback);
        // } else {
        this.setShowLoading();
        // this.events.publish('refresh_request_' + this.prefix, filters);
        let me = this;

        // if (this.rowStock.length > 0) {
        //     let data = this.rowStock[0];
        //     this.rowStock.splice(0, 1);
        //     me.onGetResult(reset, data, callback, mfilters, extraFilter, filters);
        // } else {
        this.pageRequests += 1;

        // this.events.publish('clear_events');
        me.modelProvider.list('report', filters)
            .then(function (data: any) {
                me.onGetResult(reset, data, callback, mfilters, extraFilter, filters);
                // me.exTableData[me.sectionName+'Data'] = me.tableData;
            });
        //
        // }
    }

    onGetResult(reset, data, callback, mfilters, extraFilter, filters) {
        let me = this;
        if (reset) {
            me.tableData = [];
        }

        // hack for not creating addfilter again. for filters custom add.
        // if (me.customFilter['filters'] && me.customFilter['filters']['fieldFilters'])
        //     me.customFilter['filters']['fieldFilters']['addFilter'] = false;

        me.adminService.setResult(me.sectionName, data);

        me.setHideLoading();

        let extableData = data['models'][0].data['finalResReport'];
        me.onResultList(extableData, callback);


        //console.log'filters is here!!!');
        me.events.publish('newTableFilters' + me.myprefix, filters);

        if (me.exCounterFieldData) {
            me.exCounterFieldData['displayName'] = me.tableData.length;
        }
        //refresh splite tables.
        if (mfilters['filters'] && mfilters['filters']['splitField']) {
            me.events.publish('refreshMainModelPage' + me.prefix);
        }
        me.ready = true;

        console.log('call ' + me.myprefix + 'map' + '_refresh')

        //call address details table to refresh.
        if (!me.mapMode) {
            me.events.publish(me.myprefix + 'map_map' + '_refresh', {});
        }

        //refresh map
        if (me.mapMode) {
            me.events.publish(me.myprefix + 'map_refresh_container', {'tableData': me.tableData});
        }

        //load filters and heavy data.
        //load filters and heavy data.
        //load filters and lazy data.
        // if (extraFilter['loadExtraData']) {
        //     this.rowStockInterval = setInterval(function () {
        //         if (me.page - 4 > me.pageRequests) {
        //             console.log('refresh queue')
        //             return;
        //         }
        //
        //         if (me.page < (me.tableInfoData['totalCount'] / me.rowsPerPage)) {
        //             me.page++;
        //             let f = {'page': me.page};
        //             let d = JSON.parse(filters['filters']);
        //             d['page'] = me.page;
        //             filters['filters'] = JSON.stringify(d);
        //             return me.modelProvider.list('report', filters)
        //                 .then(function (data: any) {
        //                     console.log(me.rowStock);
        //                     me.rowStock.push(data);
        //                 });
        //         } else {
        //             clearInterval(me.rowStockInterval);
        //         }
        //
        //     }, 2300);
        // }
    }

    getFilersForServer(mfilters = {}) {
        let filters = {};
        for (let key in this.filters) {
            filters[key] = this.filters[key];
        }

        if (!mfilters) {
            mfilters = {};
        }

        if (mfilters) {
            if (!this.customFilter) {
                this.customFilter = mfilters;
            } else {
                if (!this.customFilter['filters']) this.customFilter['filters'] = {};

                for (let key in mfilters) {
                    if (key != 'filters') filters[key] = mfilters[key];
                }
                for (let key in mfilters['filters']) {
                    this.customFilter['filters'][key] = mfilters['filters'][key];
                }

                /* :/ */
                for (let key in mfilters) {
                    if (key == 'baseFilter') this.customFilter[key] = mfilters[key];
                }
            }
        }

        //console.log'get filters');
        //console.logthis.customFilter);

        for (let key in this.customFilter) {
            filters[key] = this.customFilter[key];
        }
        // if (this.baseFilter) {
        //     for (let key in this.baseFilter) {
        //         filters[key] = this.baseFilter[key];
        //     }
        // }

        if (this.extraFilters && !mfilters['clear']) {
            for (let key in this.extraFilters) {
                filters[key] = this.extraFilters[key];
            }
        }
        // }

        if (this.baseFilter) {
            filters['baseFilter'] = JSON.stringify(this.baseFilter);
        }
        filters['sectionName'] = this.sectionName;

        if (this.extraSaveFilters) {
            filters['extraFilter'] = JSON.stringify(this.extraSaveFilters);
        }

        let f = filters['filters'] ? filters['filters'] : {};
        f['page'] = this.page;
        if (this.searchText !== null) {
            f['searchText'] = this.searchText;
        }
        if (this.orderbyField) {
            f['orderBy'] = this.orderbyField;
        }
        filters['filters'] = JSON.stringify(f);

        //console.logfilters);

        return filters;
    }

    onResultList(extableData, callback = null) {

        this.onResultModels(extableData, callback);
        this.loadRelationModelData();

        let me = this;
        setTimeout(function () {
            me.listenHeaders();
        }, 100);
    }

    listenHeaders() {
        this.events.publish('setUpHeaders' + this.myprefix, {
            tableData: this.tableData,
            tableInfoData: this.tableInfoData,
            sideBoxMode: this.sideBoxMode,
            boxShowEdit: this.boxShowEdit,
            boxShow: this.boxShow,
            filterTab: this.filterTab,
            tableParams: this.tableParams,
            showFields: this.showFields,
            showFieldsPage: this.showFieldsPage,
            markDataById: this.markDataById,
            showFieldsPageLimit: this.showFieldsPageLimit,
            clickThData: this.clickThData,
            orderbyField: this.orderbyField,
            markData: this.markData
        });
    }

    mouseUpField() {
    }

    mouseOnField(model, fieldType, clearIfFirst = false) {
        let fieldData = model[fieldType['name']];

        //if new mark - clear history
        if (clearIfFirst) {
            if (!fieldData['fieldOver']) this.adminService.clearMarkedFields(this.sectionName);
        }

        if (!this.markFieldMode) {
            return;
        }
        console.log('here!!!')
        console.log(fieldData)

        // if (!fieldData['fieldOver']) {
        fieldData['fieldOver'] = !!!fieldData['fieldOver'];
        // this.checkRow(model);
        // this.adminService.markCol(this.sectionName, 'markedCol', fieldType['name']);
        // }
        //sectionName, modelId, fieldName, fieldData
        this.adminService.markField(this.sectionName, model, fieldType['name'], fieldData);
    }

    onResultModels(extableData, callback = null) {
        let me = this;

        if (!me.exTableData) {
            me.exTableData = extableData;
        } else {
            for (let key in extableData) {
                me.exTableData[key] = extableData[key];
            }
        }

        ////console.logme.exTableData);
        let tableData = me.exTableData[me.sectionName];
        ////console.logtableData);

        // this.quickAddShow = this.exTableData[this.sectionName + 'Data']['quickAdd'];
        // this.createQuickAdd();

        //if regular table
        // if (!me.selectTablePrefix) {
        if (!me.tableData) me.tableData = [];
        // me.tableData.splice(0, me.tableData.length);

        for (let key in tableData) {
            //if (!key.includes('Data'))
            me.tableData.push(tableData[key]);
            me.adminService.editedSections[key] = tableData[key];
        }
        // }

        // if (me.tableData) {
        me.defaultFieldData = me.deFData = me.exTableData[this.sectionName + 'Ref'];
        // }
        me.tableInfoData = me.exTableData[me.sectionName + 'Data'];

        ////////console.logme.tableInfoData);
        //for pages:
        // me.events.publish('update_' + me.myprefix, data);

        me.setTableKeys();
        //more than 3 rows
        //more the 5 fields
        // this.boxShow = (me.tableData.length <= 6 && me.showFieldKeys.length < 3);

        if (callback) {
            callback();
        }

        this.exTableData[this.sectionName + 'Data']['edited'] = false;

        ////console.log'on table done: ', this.prefix);

        //let pages know
        this.events.publish('onModelsUpdate' + this.myprefix, this.exTableData);
        this.events.publish('onModelsUpdate' + this.prefix, this.exTableData);

        //set funnel field for filterting.
        if (me.exTableData[this.sectionName].length > 0) {
            me.fieldFunnelModel = me.exTableData[this.sectionName][0];
        }

        // if (this.tableInfoData['isCalendarList']) {
        //     this.isCalendarList = true;
        // }
        // if (this.tableInfoData['canChangeOrder']) {
        //     this.tableData.sort(function (a, b) {
        //         return b[me.tableInfoData['canChangeOrder']]['value'] - a[me.tableInfoData['canChangeOrder']]['value'];
        //     });
        // }

        if (this.tableInfoData['boxShowEdit']) {
            this.boxShowEdit = this.tableInfoData['boxShowEdit'];
        }
        this.setHideLoading();

        if (this.spliteField) this.splitModels(this.spliteField, true);
    }

    splitModels(splitField, force = false) {
        //splited fields by status ...eg
        if (!force && this.spliteField == splitField) {
            this.spliteField = null;
            this.displayType = 'table';
            return;
        }

        this.spliteField = splitField;

        if (this.spliteField) {
            this.splitedModels = {};
            this.splitedKeys = [];
            for (let model of this.tableData) {
                let id = model[this.spliteField]['value'] ? model[this.spliteField]['value'] : '-';

                if (!this.splitedModels[id]) {
                    this.splitedModels[id] = {
                        'title': id ? model[this.spliteField]['displayName'] : '-',
                        'models': [],
                        'fieldType': this.fieldTypeByName[this.spliteField],
                        'id': id,//value is the id of the external model
                        'color': model[this.spliteField]['color'],
                        'icon': model[this.spliteField]['icon'],
                    };
                    this.splitedKeys.push(id);
                }
                this.splitedModels[id]['models'].push(model);
            }
        }
    }

    createPostModel(model, fieldType, fieldData) {
        let newModel = {
            'main': {'value': model['id']['value'], 'updated': true, 'name': '', 'type': 'none'},
            'id': model['id']
        };

        newModel[fieldType['name']] = {
            'value': fieldData['value'],
            'name': fieldType['name'],
            'edited': true,
            'type': fieldType['type'],
            'typeData': fieldType
        };

        return newModel;
    }

    //when other rows are marked checked - show the new value on them quick. this data will be removed.
    saveFieldView(model, fieldType, fieldData) {
        //just for display the value on other marked models - save data is not relvant here.
        //only if selected model is in the markedFields.
        if (this.markData['markedRow'].indexOf(model['id']['value']) != -1) {
            for (let i in this.markData['markedRow']) {
                let id = this.markData['markedRow'][i]
                let tmodel = this.markDataById[id];
                tmodel['main']['updated'] = true;

                let tfieldData = tmodel[fieldType['name']];
                tfieldData['value'] = fieldData['value'];
                tfieldData['displayName'] = fieldData['displayName'];
                tfieldData['color'] = fieldData['color'];
                tfieldData['icon'] = fieldData['icon'];
                tfieldData['validate'] = true;
                tfieldData['edited'] = true;
            }
        }
    }

    //run all over models and update what's needed.
    saveCurrentChanges() {
        this.adminService.checkSave(this.tableData, this.sectionName, this.filters, this.exTableData,
            false, this.markData, this.markDataById, this.extraSaveFilters, this.baseFilter);
    }

    //save specific field
    saveField(model, fieldType, fieldData) {
        let newModel = this.createPostModel(model, fieldType, fieldData);

        //console.lognewModel);
        this.saveModel(newModel, function (data) {
            model['main']['updated'] = false;
            model['main'] = data['data']['models'][0]['main'];
            fieldData['edited'] = false;
        });
    }

    saveModel(newModel, callback, remove = false) {
        let newModels = [];

        if (newModel) {
            newModels = [newModel];
        }

        let me = this;
        me.adminService.saveModels(me.tableData, me.sectionName, me.filters, newModels, me.exTableData,
            remove, me.markData, me.markDataById, me.extraSaveFilters, me.baseFilter, callback);
    }

    saveModels(newModels, callback = null, remove = false) {

    }

    //add - regular add
    addSubModel(newModel = null) {
        let me = this;
        let valid = true;

        //if relation model - open side choose table.
        if (this.relationTableData && this.tableParams['exModel'] && !this.relationTableData['sideFilterOnly']) {
            this.showRelationTableData = !this.showRelationTableData;
            return;
        }

        //for pages
        this.events.publish('rowCLick' + this.myprefix);

        /* copy model from marked row ? - new model will be full*/
        if (!newModel) {
            newModel = this.getNewModel();

            if (this.markData['markedRow'].length > 0 || this.adminService.copyModel) {

                let cModel;
                if (this.adminService.copyModel) {
                    cModel = this.adminService.copyModel;
                    this.adminService.copyModel = null;
                } else {
                    cModel = this.markDataById[this.markData['markedRow'][0]];
                }

                for (let k in newModel) {
                    //tmp date time remove
                    if (['id', 'created', 'updated', 'business', 'main'].indexOf(k) == -1) {
                        if (['dateTime'].indexOf(newModel[k].type) != -1) {
                            if (moment(cModel[k]['value']).isValid())
                                newModel[k]['value'] = moment(cModel[k]['value']).format(this.appConfig.datetimeFormatServer);
                        } else if (['date'].indexOf(newModel[k].type) != -1) {
                            if (moment(cModel[k]['value']).isValid())
                                newModel[k]['value'] = moment(cModel[k]['value']).format(this.appConfig.dateFormatServer);
                        } else {
                            newModel[k]['value'] = cModel[k]['value'];
                        }
                    }
                }
            }
        } else {
            //check only on edit.
            for (let fieldKey in newModel) {
                if (this.fieldTypeByName[fieldKey]) {
                    if (!this.adminService.isValidateField(newModel[fieldKey], this.fieldTypeByName[fieldKey])) {
                        valid = false;
                    }
                }
            }
        }
        if (!valid) {
            console.log('not valid!!!')
            return;
        }

        if (!this.newModels) this.newModels = [];
        this.newModels.push(newModel);
        if (!this.adminService.manageModels[this.sectionName + 'addCount' + this.prefix]) this.adminService.manageModels[this.sectionName + 'addCount'];
        this.adminService.manageModels[this.sectionName + 'addCount' + this.prefix] = this.newModels.length;
        this.adminService.manageModels[this.sectionName + 'addCount' + this.myprefix] = this.adminService.manageModels[this.sectionName + 'addCount' + this.prefix];

        clearTimeout(me.saveTimeout);
        me.saveTimeout = setTimeout(function () {
            if (me.customFilter['filters']) me.filters['filters'] = JSON.stringify(me.customFilter['filters']);
            if (me.exModel && me.exModel['fieldData'] && me.exModel['fieldData']['modelId']) {
                if (!me.extraSaveFilters) me.extraSaveFilters = {};
                me.extraSaveFilters['topModelId'] = me.exModel['fieldData']['modelId'];
                me.extraSaveFilters['topModelSection'] = me.exModel['fieldData']['topModelSection'];
            }
            me.adminService.saveModels(me.tableData, me.sectionName, me.filters, me.newModels, me.exTableData,
                false, me.markData, me.markDataById, me.extraSaveFilters, me.baseFilter, function (data) {
                    let newModels = data['data']['models'];
                    for (let newModel of newModels) {
                        me.tableData.unshift(newModel);
                    }
                    me.adminService.manageModels[me.sectionName + 'addCount' + me.prefix] =
                        me.adminService.manageModels[me.sectionName + 'addCount' + me.myprefix] = 0;
                    me.newModels = [];
                    if (me.exCounterFieldData) {
                        me.exCounterFieldData['displayName'] = me.tableData.length;
                    }
                    newModel['main']['updated'] = false;
                    if (me.tableInfoData['popupOnNew']) {
                        me.clickRow(newModels[0], 0, false, true);
                    }
                    me.quickAddModel = null;
                });
        }, 850);
    }

    removeModel() {
        let me = this;

        // this.modelTools.yesOrNoAlert(function () {
        me.saveModel(null, function () {
            ////console.log'done remove!');
            me.markData['markedRow'] = [];
            // me.refresh();

            me.events.publish('checkRowChange' + me.prefix, {
                'sectionName': me.sectionName,
                'checkedRows': me.markData['markedRow']
            });

        }, true);
        // }, {}, 'remove ' + me.markData['markedRow'].length + ' ' + me.sectionName + ' ?');
    }

    //
    // this.events.publish('showGenericModel', {
    //     'modelName': this.sectionName,
    //     'model': model,
    //     'modelEdit': true
    // });
    async popupEditBox(myEvent, model, type = 'modelTableEdit', bigPopup = true,
                       fieldData = null, fieldType = null) {
        // //console.logthis.exTableData);
        // //console.logfieldData);
        // //console.log"aaaa");
        // //console.logfieldType);
        //for sending to server if needed in select table.
        if (fieldData) {
            fieldData['fieldName'] = fieldType ? fieldType['name'] : null;
            fieldData['modelId'] = model['id']['value'];
            fieldData['topModelSection'] = this.sectionName;
        }

        if (fieldData && fieldData['type'] != 'selectTable' && fieldData['type'] != 'table') {
            type = 'editField';
        }
        let d = {
            'model': model,
            'prefix': this.prefix,
            'sectionName': this.sectionName,
            'fieldData': fieldData,
            'fieldType': fieldType,
            'exTableData': this.exTableData,
            'avoidSave': false,
            'type': type
        };
        this.adminService.popupEditBoxByData(myEvent, d, bigPopup);

        //
        // let cls = 'note-popover icenter-popover ' + (bigPopup ? 'bigger-popover right-popup' : 'smaller-popover')
        // if (this.exTableData[this.sectionName + 'Data']['popoverClass'])
        //     cls = this.exTableData[this.sectionName + 'Data']['popoverClass'];
        //
        // const popover = await this.popoverCtrl.create({
        //     component: SelectBarComponent,
        //     componentProps: d,
        //
        //     event: !bigPopup ? myEvent : null,
        //     animated: false,
        //     showBackdrop: false,
        //     cssClass: cls
        // });
        // return await popover.present();
    }

    onUpdateReady() {
        return;
        let me = this;
        this.events.subscribe('onTaarifUpdate', (tableData) => {

            ////////console.log'on taarif updated!!');
            ////////console.logthis.sectionName);

            this.events.unsubscribe('onTaarifUpdate');
            // this.ready = false;

            // this.tableData = tableData[this.sectionName];

            this.tableData.splice(0, this.tableData.length);
            for (let key in tableData[this.sectionName]) // skip -1 id
            {
                this.tableData.push(tableData[this.sectionName][key]);
            }

            // setTimeout(function () {
            //     me.ready = true;
            // });
        });
    }

    selectFilterModel(rowModel) {
        let me = this;
        this.lastSelectedModel = null;
        setTimeout(function () {
            me.lastSelectedModel = rowModel;
            // me.lastSelectedModelId = rowModel['id']['value'];
        }, 1);
    }

    //main click row - first function - click
    //main click row - first function - click
    //main click row - first function - click
    clickRow(rowModel, rowIndex = 0, forceEdit = false, forceBigPopup: boolean = false, event = null) {
        if (this.tableParams['exFieldData'] && this.tableParams['exFieldData']['readOnly']) {
            ////////console.log'read only');
            return;
        }
        ////////console.log'click row!!!')
        //if this table ment to choose from forigen key or other choose option.
        //or filter!
        if (this.selectTablePrefix && !forceEdit) {
            let d;
            // if (this.tableParams['exFieldData']) {
            //     d = this.tableParams['exFieldData']['quickFilterData'];
            //     d['keyId'] = rowModel[0]['value'];
            // }

            // this.selectFilterModel(rowModel);

            // if (this.quickFilterData) {
            //     d = this.quickFilterData;
            //     d['keyId'] = rowModel[0]['value'];
            // }
            this.events.publish(this.selectTablePrefix['event'], {
                'quickFilterData': d,
                'rowModel': rowModel,
                'name': this.selectTablePrefix['name']
            });
            // this.adminService.markSubModel(this.sectionName, this.markData, this.markDataById, 'markedRow', rowModel);

        } else {
            //else show box of row
            //big screen
            // && !this.subTable
            if (!forceBigPopup && !this.tableInfoData['secondRowOpenStyle'] && !this.subTable) {
                //open other model - not the current one.
                if (rowModel['main']['customOpen']) {
                    this.adminService.openLeftModelBox(rowModel['main']['customOpen']['sectionName'], null,
                        rowModel['main']['customOpen'].id, 'fewfeweeee', this.navCtrl);
                } else {
                    this.adminService.openLeftModelBox(this.sectionName, rowModel, rowModel.id.value, this.prefix, this.navCtrl);
                }
                // this.events.publish('showMenu',
                //     {
                //         'menu': 'menuAdminBox',
                //         'id': rowModel.id.value,
                //         'rowModel': rowModel,
                //         'prefix': this.prefix,
                //         'boxMenu': true,
                //         'sectionName': this.sectionName
                //     });


            } else if (this.tableInfoData['secondRowOpenStyle']) {
                // !this.onPopup &&
                //fixed box show - on main table page
                //1. same model? just hide:
                if (this.currentChosenModel == rowModel) {
                    this.currentChosenModel = null;
                    rowModel['openRow'] = false;
                } else {
                    rowModel['openRow'] = !!!rowModel['openRow'];
                    // let me = this;
                    // this.currentChosenModel = null;
                    // if (this.tableInfoData['inTableOpen']) {
                    //     rowModel['openRow'] = !!!rowModel['openRow'];
                    // } else {
                    //     //in table page popup
                    //     setTimeout(function () {
                    //         me.currentChosenModel = rowModel;
                    //         //     //for footer.
                    //         //     me.events.publish('rowClick', {
                    //         //         'sectionName': me.sectionName,
                    //         //         'exTableData': me.exTableData,
                    //         //         'model': me.currentChosenModel
                    //         //     });
                    //     });
                    // }
                }
                // rowModel['openBox'] = !!!rowModel['openBox'];
            } else {
                this.navModelWindow(rowModel, forceBigPopup ? false : true, event);
            }
        }
    }

    rowClose() {
        this.events.publish('rowClose');
    }

    openRow111(model) {
        // if (this.tableInfoData['fullWindow']) {
        this.adminService.openCustomModel(this.navCtrl, this.sectionName, null, null, null, model);
        // } else {
        //     this.adminService.popupEditBox(event, this.sectionName, this.exTableData, 'dadad',
        //         model, 'modelTableEdit', this.exTableData[this.sectionName + 'Data']['smallerPopup'] ? false : true, null, null);
        // }
    }

    //main row click click click
    //main row click - open model - eye
    navModelWindow(rowModel, popup = false, event = null) {
        // if (this.tableInfoData['fullWindow']) {
        //     this.adminService.setRowModel(rowModel);
        if (!popup && !this.subTable) {
            this.openAdminModel(this.sectionName, rowModel['id']['value']);
        } else {
            this.popupEditBox(event, rowModel);
        }
    }

    copyRow() {
        let me = this;

        me.markData['markedRowCopy'] = JSON.stringify(me.markData['markedRow']);
        me.markData['markedColCopy'] = JSON.stringify(me.markData['markedCol']);

        // for (let key in me.markData['markedRow']) {
        //     if (me.tableData[key]['id']['value'] == me.markData['markedRow']['id']) {
        //         this.copiedRow = me.tableData[key];
        //     }
        // }
    }


    //need to fix
    pasteRow() {
        let me = this;

        let copyFromRow = JSON.parse(me.markData['markedRowCopy']);
        let copyFromCol = JSON.parse(me.markData['markedColCopy']);

        let copyToRow = me.markData['markedRow'];
        let copyToCol = me.markData['markedCol'];
        for (let i = 0; i < copyFromRow.length; i++) {
            let toRow = copyToRow[i];
            let toModel = this.markDataById[toRow];
            for (let j = 0; j < copyFromCol.length; j++) {
                let fromModel = this.markDataById[copyFromRow[i]];
                let field = toModel[copyToCol[j]];
                field['value'] = fromModel[copyFromCol[j]]['value'];
                field['displayName'] = field['value'];
                field['edited'] = field['validate'] = true;
            }

            toModel['main']['updated'] = true;

        }
        this.saveCurrentChanges();
    }

    //need to fix
    addCalcData(data) {
        let me = this;
        this.modelTools.inputAlert('Enter a Value (' + data + ')', '', function (val) {

            for (let i in me.markData['markedRow']) {
                //////////console.logi);
                for (let key in me.tableData) {
                    if (me.tableData[key][0]['value'] == me.markData['markedRow'][i]) {
                        for (let index in me.markData['markedCol']) {

                            val = parseInt(val);
                            let value = parseInt(me.tableData[key][me.markData['markedCol'][index]]['value']);

                            if (data == '+') {
                                value += val;
                            } else if (data == '-') {
                                value -= val;
                            } else if (data == '+%') {
                                value += value * (val / 100);
                            } else if (data == '-%') {
                                value -= value * (val / 100);
                            } else if (data == 'I') {
                                value = val;
                            } else if (data == 'X') {
                                value = value * val;
                            } else if (data == '/') {
                                value = value / val;
                            }

                            me.tableData[key][me.markData['markedCol'][index]]['value'] = value;
                        }
                    }
                }
            }

        }, true);
    }

    //when set from popup of multi select value
    saveMultiModel(model = null) {
        if (!model) {
            model = this.tmpModel;
        }

        if (this.searchMode) {

            let filters = {
                'searchFieldValue': this.tmpModel[this.onMultiSelectIndex]['value'],
                'searchField': this.tmpModel[this.onMultiSelectIndex]['name'],
                'search_in_field': true,
                'search_in_field_type': 'in'
            };
            ////////console.logfilters);

            this.refresh(filters);
            this.onMultiSelectIndex = null;
            this.chooseFieldData = null;

        }
        //multiple edit
        else if (this.markData['markedRow'].length > 0) {
            return
            for (let model of this.tableData) {
                ////////console.logmodel[0]['value']);
                model[this.onMultiSelectIndex]['value'] = this.tmpModel[this.onMultiSelectIndex]['value'];
                model['main']['updated'] = true;
                // }
            }
        }
        //single edit
        else {

            if (!this.tmpModel) {
                //////console.log'errror!!!!!');

            } else {
                ////////console.log'set field!!!', this.tmpModel[this.onMultiSelectIndex]);
                this.chooseFieldData['value'] = this.tmpModel[this.onMultiSelectIndex]['value'];
                this.chooseFieldData['displayName'] = this.tmpModel[this.onMultiSelectIndex]['displayName'];
                this.chooseFieldData['icon'] = this.tmpModel[this.onMultiSelectIndex]['icon'];
                this.chooseFieldData['color'] = this.tmpModel[this.onMultiSelectIndex]['color'];
            }
        }

        this.onMultiSelectIndex = null;
        this.chooseFieldData = null;
    }

    addFilter(data = {}) {
        // this.baseFilters.append(data);
        let filters: any = {'filters': data};
        if (data && data['clear']) {
            this.customFilter = {}
            filters = {};
            this.extraFilters = {};
        }

        if (!this.customFilter['filters']) this.customFilter['filters'] = {};

        let custom = this.customFilter['filters']['custom'];
        console.log(custom);

        let extraFilter = {};

        if (data['custom']) {
            if (!this.customFilter['filters']['custom']) this.customFilter['filters']['custom'] = {};
            for (let k in data['custom']) {
                this.customFilter['filters']['custom'][k] = data['custom'][k];
            }
            this.refresh({}, null, true);
        } else {
            for (let k in data) {
                if (k == 'fieldFilters' && this.customFilter['filters'][k]) {
                    for (let z in data[k]) {
                        this.customFilter['filters'][k][z] = data[k][z];
                    }
                } else if ('quickFilters' == k) {
                    extraFilter['loadExtraData'] = 1;
                } else {
                    this.customFilter['filters'][k] = data[k];
                }
            }
            this.refresh(this.customFilter, null, true, extraFilter);
        }
        console.log(this.customFilter)
    }

    //quick filter by id - from field data - select table filter
    filterByForigenKey(quickFilterData, rowModel) {
        //console.log'depricated filterByForigenKey')
        ////////console.logquickFilterData);

        // let filters: any;
        // let me = this;
        // filters = {'ordering': '-id', 'type': 'moving', 'name': 'taarif', 'modelOffset': 0, 'modelLimit': 20};
        // filters['sectionName'] = me.sectionName;
        // filters['filterSectionName'] = me.sectionName;
        // filters['keyId'] = rowModel[0]['value'];
        //
        // let fieldData;
        // if (quickFilterData['fieldData']) {
        //     fieldData = quickFilterData['fieldData'];
        //     let r = {};
        //     // r['sideFilterTable'] = fieldData['filterkey'];
        //     // r['filterType'] = 'direct';
        //
        //     //keep filter is for map
        //     //filters add 'searchFieldValue' filters
        //
        //     if (!this.baseFilters) this.baseFilters = {};
        //     this.baseFilters[fieldData['filterkey']] = rowModel[0]['value'];
        //
        //     let keepFilter = {};
        //     if (!this.baseFilter) {
        //         filters['searchFieldValue'] = rowModel[0]['value'];
        //         filters['searchField'] = fieldData['filterkey'];
        //         filters['search_in_field'] = keepFilter['search_in_field'] = true;
        //     }
        //     filters['baseFilters'] = JSON.stringify(this.baseFilters);
        //
        //     keepFilter['searchField1'] = rowModel[0]['value'];
        //     keepFilter['searchFieldValue1'] = rowModel[0]['value'];
        //     keepFilter['search_in_field'] = true;
        //
        //     this.keepFilter = keepFilter;
        // } else if (quickFilterData['sideFilter']) {
        //     let sideFilterData = quickFilterData['sideFilter'];
        //     sideFilterData['filterType'] = 'relation';
        //     sideFilterData['keyId'] = rowModel[0]['value'];
        //     let data = JSON.stringify(sideFilterData);
        //     filters['filterOptionsData'] = data;
        //
        //     this.keepFilter = {'filterOptionsData': filters['filterOptionsData']};
        // }
        //
        // this.setShowLoading();
        // this.modelProvider.list('report', filters)
        //     .then(function (data: any) {
        //
        //         me.setHideLoading();
        //         me.tableData = data['models'][0].data['finalResReport'][me.sectionName];
        //         me.setTableKeys();
        //     });
    }

    filterit(fieldData) {
        ////////console.logfieldData);
        let me = this;
        this.filterField = fieldData;
        let filters = {
            'ordering': '-id',
            'type': 'moving',
            'name': 'taarif',
            'modelOffset': 0,
            'modelLimit': this.filters['modelLimit']
        };

        filters['filterSectionName'] = fieldData['filterkey'];
        filters[fieldData['filterkey']] = fieldData['value'];//tarrif only
        filters[fieldData['model'] + '_' + fieldData['filterkey']] = fieldData['value'];//rest..
        filters['sectionName'] = fieldData['model'];
        filters['quickFilter'] = 1;

        ////////console.log'model:' + fieldData['model']);
        ////////console.log'filters', filters);

        //////////console.log'section name:' + this.sectionName);
        if (this.selectTablePrefix) {
            // this.onUpdateReady();
            // this.events.publish('refresh_request_' + this.prefix, filters);
            this.setShowLoading();
            this.modelProvider.list('report', filters)
                .then(function (data: any) {

                    me.setHideLoading();
                    me.tableData = data['models'][0].data['finalResReport'][me.sectionName];
                });

        } else {

            let params = {
                'sectionName': fieldData['model'],
                'baseFilter': filters
            };

            let navigationExtras: NavigationExtras = {
                queryParams: params
            };

            this.navCtrl.navigateForward('admin-model/' + this.sectionName + fieldData['value'], navigationExtras);
        }
    }

    //open specific model page
    openAdminModel(sectionName, id = null) {
        ////////console.logsectionName, id);

        let params = {
            'sectionName': sectionName,
            'rowId': id,
            // 'rowIndex': 1 //skip first row -1
        };
        if (id) {
            params['baseFilter'] = {
                'searchFieldValue': id,
                'searchField': 'id',
                'search_in_field': true
            }
        }

        let navigationExtras: NavigationExtras = {
            queryParams: params
        };
        this.navCtrl.navigateForward('admin-model/' + sectionName + id, navigationExtras);
    }

    removeFilter() {
        //console.log'depricated RemoveFilter()');
        // let filterField = this.filterField;
        // let filters = {};
        //
        // if (filterField && filterField['filterkey']) {
        //     filters[filterField['filterkey']] = ''; //tarrif only
        //     filters[this.sectionName + '_' + filterField['filterkey']] = ''; //rest..
        // }
        // filters['sectionName'] = this.tableParams['modelPrefix'];
        // filters['clearFilters'] = true;
        // filters['search_in_report'] = '';
        // filters['offset'] = 0;
        //
        // this.baseFilters = {};
        // // this.events.publish('refresh_request_' + this.prefix, filters);
        //
        // this.filterField = null;
    }


    //regular td click - edit or open
    onFieldClickT($event, fieldData, model, rowIndex) {
        $event.stopPropagation();

        model['main']['updated'] = true;
        fieldData['edited'] = true;


        return;

        if (fieldData['editMode']) {
            return;
        }

        //for pages
        this.events.publish('rowCLick' + this.prefix);
        this.events.publish('rowCLick' + this.myprefix);

        // if (fieldData.type == 'boolean' && this.edit) {
        // fieldData['value'] = !fieldData['value'];
        // model[0]['updated'] = true;
        // } else
        if (this.edit) {
            fieldData['editMode'] = true;
        } else {

            //handle in app-field
            if (fieldData['type'] == 'boolean' || fieldData['type'] == 'selectOptions') {
                return;
            }

            this.clickRow(model, rowIndex);
        }

        model[0]['updated'] = true;
    }

    markFieldEdit(model, fieldData) {
        this.tableInfoData['updated'] = true;
        fieldData['edited'] = true;
        fieldData['validate'] = true;
        model['main']['updated'] = true;
    }

    rowClickIcon($event, iconName, model, fieldType = null) {
        let fieldData = model[fieldType.name];
        if (fieldData['iconsData'] && fieldData['iconsData'][iconName]) {
            let r = fieldData['iconsData'][iconName];
            if (r['type'] == 'map') {
                this.adminService.mapSelect($event, this.prefix, this.exTableData, model,
                    r['addressId'], r['latLng'], fieldType, this.sectionName);
            }
        }
    }

    fieldEdit(model, fieldType = null, fieldData = null, myEvent = null) {
        if (this.tableInfoData['fullrowOpenClick']) {
            this.clickRow(model);
            return;
        }
        this.adminService.fieldClick(myEvent, fieldData, fieldType, model,
            this.sectionName, this.tableInfoData, this.prefix, this.navCtrl, this.exTableData);
    }

    onTdClick($event, colIndex, model) {
        $event.stopPropagation();

        this.showCalc = true;

        // model[0]['updated'] = true;

        //
        let me = this;
        setTimeout(function () {
            me.events.publish('onTdClick', {
                'exTableData': me.exTableData,
                'sectionName': me.sectionName,
                'fieldData': model[me.deFData[colIndex]['name']],
                'colTitle': me.deFData[colIndex]['title'],
                'fieldType': me.deFData[colIndex],
                'model': model
            });
            if (me.currentChosenFieldData) me.currentChosenFieldData['onSet'] = false;
            me.currentChosenFieldData = model[me.deFData[colIndex]['name']];
            me.currentChosenFieldData['onSet'] = true;
        });


    }

    // filterTable(model, fieldData) {
    //     let filters = {};
    //     filters['fulldataId'] = model[0].value;
    //     filters['fulldataPrefix'] = me.tableParams['modelPrefix'];
    //
    //     this.events.publish('refresh_request_' + this.prefix, filters);
    // }

    quickFilter(quickFilterData, type = null) {

        let filterSectionName = this.tableParams['modelPrefix'];
        let data;
        if (type == 'all') {
            filterSectionName = '';
        }
        // this.currentSideFilter = value;

        // let data = this.exTableData[this.sectionName + 'Data']['filterOptionsData'];
        // if (!data) data = {};
        // data = quickFilterData;

        let filters = {
            'modelFilterId': -1,
            'sectionName': this.tableParams['modelPrefix'],
            'filterSectionName': filterSectionName,
            'clearFilters': true
        };

        if (this.baseFilter) {
            for (let key in this.baseFilter) {
                filters[key] = this.baseFilter[key];
            }
        }
        for (let key in quickFilterData) {
            filters[key] = quickFilterData[key];
        }

        // this.onUpdateReady();

        // this.tableData = data[this.sectionName];
        this.refresh(filters, null, true);

        // this.events.publish('refresh_request_' + this.prefix, filters);


    }

    filterAddKey(name, value) {
        // let pref = '';
        // if (this.currentFilters['search_in_field']) {
        //     pref = '1'
        // }

        let filters = {};
        filters['sectionName'] = this.sectionName;
        filters['search_in_field'] = true;
        filters['searchField'] = name;
        filters['searchFieldValue'] = value;

        // this.currentFilters = filters;

        // this.onUpdateReady();

        // this.events.publish('refresh_request_' + this.prefix, filters);


        this.refresh(filters);

    }

    addToForigenKeyModel(forigenId, subParams) {
        ////////console.log"subParams:");
        ////////console.logsubParams);

        //save new value for forigen key field.
        if (subParams['fieldData']) {
            ////////console.log'field data exists!!!');

            let fieldData = subParams['fieldData'];
            // let forigenKey = fieldData['forigenKey'];
            let rowIndex = subParams['rowIndex'];

            let editedModels = [];
            if (this.markData['markedRow'].length > 0) {
                for (let i in this.tableData) {

                    if (this.markData['markedRow'].indexOf(this.tableData[i]['id'].value) != -1) {
                        this.tableData[i][rowIndex]['value'] = forigenId;
                        this.tableData[i][0]['updated'] = true;
                        editedModels.push(this.tableData[i]);
                    }
                }
            }

        } else {


        }
    }

    pageButton(fieldType, model) {
        let me = this;
        let filters = JSON.parse(JSON.stringify(this.filters));
        let fieldData = model[fieldType['name']];

        if (fieldData['externalLink']) {
            if (fieldType['share']) {
                this.mainService.shareViewWhatsapp({'data': fieldData['externalLink']});
            } else {
                window.open(fieldData['externalLink']);
            }
        }

        if (fieldType['serverCall']) {

            filters['customApi'] = fieldType['pagePrefix']
            filters['id'] = model[fieldType['filterKey']]['value']
            filters['saveAndGetIdPrefix'] = fieldType['saveAndGetIdPrefix']
            filters['manager'] = 1

            for (let key in fieldData['extraFilters']) {
                filters[key] = fieldData['extraFilters'][key];
            }

            // this.modelProvider.custom(fieldType['serverCall'], filters, fieldType['domain'])
            this.modelProvider.customPost('saveDataReport', [], filters, true).then((data: any) => {
                // me.modelTools.presentToast('DONE!');
                // this.refresh({}, null, true);
            });

            return;
        }
    }

    openPage(fieldData) {
        ////////console.logfieldData);

        let navigationExtras: NavigationExtras = {
            queryParams: {'id': fieldData['value'], 'manager': 1}
        };
        this.navCtrl.navigateForward(fieldData['pagePrefix'], navigationExtras);
    }

    selectModel(model, colIndex, fieldData) {

        if (model) {
        } else {
            model = this.exTableData[this.sectionName][0];
        }

        model[0]['updated'] = true;
        this.tableInfoData['edited'] = true;
        fieldData['edited'] = true;

        if (fieldData['type'] == 'selectTable') {
            this.events.publish("selectFieldPopup" + this.prefix,
                {
                    'selectTablePrefix': this.selectTablePrefix,
                    'fieldData': fieldData, 'tableData': this.tableData, 'sectionName': this.sectionName
                });
        }
        return;


        // this.onMultiSelectIndex = colIndex;
        // this.chooseFieldData = fieldData;
        //
        // fieldData['edited'] = true;
        //
        //
        // ////////console.logthis.sectionName);
        // ////////console.logthis.exTableData);
        //
        // let newModel = JSON.parse(JSON.stringify(model));
        // newModel[0].value = null;
        // newModel[0]['updated'] = true;
        // this.tmpModel = newModel;
        //
        // //for pages
        // this.events.publish('rowCLick' + this.myprefix);
    }

    //event to other table map
    showOnMap(model) {
        // let filters = {
        //     'searchFieldValue1': model[0]['value'],
        //     'searchField1': 'objectId',
        //     'search_in_field': true,
        //     'searchFieldValue': this.sectionName,
        //     'searchField': 'contentType__model'
        // };
        //
        // this.events.publish('showOnMap', filters);
    }

    //on this table map
    showModelsOnMap() {
        let me = this;
        // setTimeout(function () {
        //     me.events.publish('showOnMap' + this.myprefix, filters);
        // });


        if (this.sectionName == 'addressDetails') {
            me.isMapList = !me.isMapList;
        } else {
            me.isMapList = !me.isMapList;
            return;

            let params = {
                'sectionName': 'addressDetails',
                'baseFilter': {
                    'search_in_field': true,
                    'searchFieldValue': this.sectionName,
                    'searchField': 'contentType__model'
                },
                'mapMode': true
            };

            if (this.keepFilter) {
                ////////console.logthis.keepFilter);
                params['baseFilter']['baseFilter2'] = JSON.stringify(this.keepFilter);
            }
            if (this.baseFilter) {
                ////////console.logthis.baseFilter);
                let baseFilter = {};
                baseFilter['contentType__model'] = this.sectionName;
                params['contentModelFilter'] = JSON.stringify(baseFilter);
            }

            // this.mapModeTableParams = params;
            this.extraFilters = params;

            // let navigationExtras: NavigationExtras = {
            //     queryParams: params
            // };
            // this.navCtrl.navigateForward('admin-model/' + 'addressDetails', navigationExtras);
        }

    }

    chooseSubModel(selecedSubmodel) {
        if (this.currentChosenSubModel == selecedSubmodel) {
            this.currentChosenSubModel = null;
            return;
        }

        let me = this;
        this.currentChosenSubModel = null;
        setTimeout(function () {
            me.currentChosenSubModel = selecedSubmodel;
        })
    }

    customAddButton(key) {

        //for subzones.. remove prefix.
        //this.myprefix + evet..
        if (key == 'mapSearchAddress') {
            this.events.publish('mapSearchAddress', {'newModel': this.quickAddModel});
        }
    }

    staticFilter(field, option) {
        let f = {}
        f[field['name']] = option['id'];
        this.customFilter[field['name']] = option['id'];
        let d = {'custom': f}
        this.addFilter(d);
    }

    isRowVisible(i): boolean {
        const rowHeight = 31; // Replace this with the actual height of your rows
        const scrollTop = this.scrollTop;
        const scrollHeight = 600;

        // Calculate the top and bottom positions of the row
        const rowTop = i * rowHeight;
        const rowBottom = rowTop + rowHeight;

        return (rowTop > scrollTop - (scrollHeight * 2.5)) && (rowBottom < scrollTop + (scrollHeight * 2.5));
    }

    //col click!
    rowClickCond(event, defaultFieldData, colIndex, period, deFData) {
        if (this.editMode) {
            this.mouseOnField(period, period[deFData[colIndex]['name']]);
            return;
        }
        this.fieldClick(event, defaultFieldData, colIndex, period, deFData);
    }

    fieldClick(event, defaultFieldData, colIndex, period, deFData) {
        if (defaultFieldData[colIndex]['columnChooseOnly'])
            this.onTdClick(event, colIndex, period);
        else
            this.fieldEdit(period, deFData[colIndex], period[deFData[colIndex]['name']], event);
    }

}
