import {Component, OnInit} from '@angular/core';
import {Item} from "./item";
import {ModelToImage} from "../general/modelToImage";

export class MiniItem {
    data = {
        id: null,
        name: null,
        code: null,
        price: null,
        images:null,
        basePrice:null,
        baseDistributorPrice:null,
        currencyCode:null,
    };

    constructor(public data1: any) {
        this.data = data1;
        //
        // for(let k in this.data['images'])
        // {
        //     if(this.data['images'][k]['data'])
        //         return;
        //     //
        //     this.data['images'][k] = new ModelToImage(this.data['images'][k]);
        // }
        //
        // //console.logthis.data)
    }
    //
    // getPrice()
    // {
    //     return new Item(this.data).getPrice()
    // }

    //shortHeaders = [];
    prefix = "miniitem";
    title = "item";
    dataFields = {
        id: {'title': 'ID', 'value': null, 'type': 'number', 'readonly': true},
        created: {'title': 'Created', 'value': null, 'type': 'date', 'readonly': true},
        updated: {'title': 'Updated', 'value': null, 'type': 'date', 'readonly': true},
        'name': {'title': 'name', 'value': null, 'type': 'text', 'required': true},
        'price': {'title': 'price', 'value': null, 'type': 'float', 'nis': true, 'maxlength': 5},
        'code': {'title': 'price', 'value': null, 'type': 'text'},
        'basePrice': {'title': 'basePrice', 'value': null, 'type': 'number'},
        'baseDistributorPrice': {'title': 'distributorPrice', 'value': null, 'type': 'number'},
        'comments': {'title': 'comments', 'value': null, 'type': 'textarea'},

        categoryName: {'title': 'categoryName', 'keyField': 'id', 'value': null, 'type': 'text', 'required': true},
        'order': {'title': 'order', 'value': null, 'type': 'number'},
        'stock': {'title': 'stock', 'value': null, 'type': 'number'},
        "type": {
            'showDefault': true, 'value': null,
            'filters': {'contentTypeName': 'item', 'fieldName': 'type'},
            'displayField': 'typeName', 'type': 'modelscroll',
            'modelscroll': 'ModelStatus', 'keyField': 'id', 'valueField': 'name',
            //'multipleSelect':true,
        },
        "section": {
            'title': 'category',
            'required': true,
            'showDefault': true, 'value': null,
            'filters': {'ordering': 'order'},
            'displayField': 'sectionName', 'type': 'modelscroll',
            'modelscroll': 'Section', 'keyField': 'id', 'valueField': 'name',
            //'multipleSelect':true,
        },
        'handleSide': {
            'title': 'handleSide',
            'showDefault': true, 'value': null,
            'displayField': 'handleSide', 'type': 'optionsscroll', 'optionsscroll': 'HANDLE_SIDE',
        },
        'description': {'title': 'description', 'value': null, 'type': 'textarea', 'maxlength': 3000},
        'mainImageUrl': {'title': 'mainImageUrl', 'value': null, 'type': 'text'},
        'makerName': {'title': 'makerName', 'value': null, 'type': 'text'},
        'sectionName': {'title': 'sectionName', 'value': null, 'type': 'text'},
        'currencyCode': {'title': 'currencyCode', 'value': null, 'type': 'text'},
        'topItem': {'title': 'topItem', 'value': null, 'type': 'number'},

        "itemMaker": {
            'title': 'brand',
            'required': false,
            'showDefault': true, 'value': null,
            'filters': {},
            'displayField': 'name', 'type': 'modelscroll',
            'modelscroll': 'itemMaker', 'keyField': 'id', 'valueField': 'name',
            //'multipleSelect':true,
        },
        shippingable: {'title': 'shippingItem', 'value': true, 'type': 'boolean'},
        active: {'title': 'active', 'value': true, 'type': 'boolean'},
    }
}