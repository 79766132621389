import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {Routes, RouterModule} from '@angular/router';

import {IonicModule} from '@ionic/angular';

import {SectionsManagerTabsPage} from './sections-manager-tabs.page';
import {GlobalModule} from "../../../../../../../app/global-module/global-module.module";

const routes: Routes = [
    {
        path: '',
        component: SectionsManagerTabsPage,
        children: [
            {
                path: 'sections-list/:id',
                children: [
                    {
                        path: '',
                        loadChildren: () => import('../sections-list/sections-list.module').then(x => x.SectionsListPageModule),
                    },
                    {
                        path: 'section-items-manager/:id',
                        loadChildren: () => import('../section-items-manager/section-items-manager.module').then(x => x.SectionItemsManagerPageModule),
                    }
                ]
            },
            {
                path: 'sections-manager-summary',
                loadChildren: () => import('../sections-manager-summary/sections-manager-summary.module').then(x => x.SectionsManagerSummaryPageModule),
            }
        ]
    }

];

@NgModule({
    imports: [
        GlobalModule,
        CommonModule,
        FormsModule,
        IonicModule,
        RouterModule.forChild(routes),
    ],
    declarations: [SectionsManagerTabsPage]
})
export class SectionsManagerTabsPageModule {
}
