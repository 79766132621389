import {Component, Input, OnInit} from '@angular/core';
import {AppUser} from "../../../../../framework/model/core/appuser/appuser";
import {OrderService} from "../../../../services/OrderService";
import {Events} from "../../../../../framework/providers/events";
import {ModelProvider} from "../../../../../framework/providers/modelProvider/ModelProvider";
import {ModelClasses} from "../../../../model/modelClasses";
import {ModelToolsProvider} from "../../../../../framework/providers/model-tools/model-tools";
import {PaymentPage} from "../../../cart/pages/payment/payment.page";
import {ItemProvider} from "../../../item/providers/item-provider/item-provider.service";

@Component({
    selector: 'app-invoice',
    templateUrl: './invoice.component.html',
    styleUrls: ['./invoice.component.scss'],
})
export class InvoiceComponent implements OnInit {
    @Input()
    public modelId;
    @Input()
    public title: boolean = true;

    public appuser: AppUser;
    // public models: Array<AppUser> = [];
    public options: object = {};
    public tabs: object = {'value': null, "data": []};
    public filters;
    public companyData;

    public prefix: string = 'inv_';

    // public prefix: string = 'inv_' + Math.floor(Math.random() * Math.floor(1000000));

    constructor(public orderService: OrderService, public events: Events, public modelProvider: ModelProvider, public modelClasses: ModelClasses, public itemProvider:ItemProvider,
                public tools: ModelToolsProvider) {
        this.appuser = this.orderService.getAppUser();
    }

    ngOnInit() {
        console.log("INVOICEE!!!")
        this.filters = {'company': this.modelId};

        this.modelProvider.custom('getInvoiceInterface', {
            'setPrefix': false,
            'company': this.modelId,
            'mode': 'api_types'
        }).then((res) => {
            if (res['res']) {
                this.setInterfaceData(res);
            } else {
                this.setDefaultData();
            }
        });

        this.setTab(false);
    }

    setInterfaceData(res) {
        let models = res['models'];
        for (let modelData of models) {
            let cls = this.modelClasses.getClass(modelData['modelName']);
            let model = new cls({});
            let fields = modelData['fields'] ? modelData['fields'] : model.fields;
            let data = {
                'value': modelData['name'] ? modelData['name'] : modelData['modelName'],
                'model': model,
                'title': modelData['name'] ? modelData['name'] : modelData['modelName'],
                'checked': true,
                'modelName': modelData['modelName'],
                'display': 'table',
                'header': fields,
                'fields': fields,
                'calcable': modelData['calcable'],
                'checkedData': {'checked': {}, 'data': {}, 'balance': 0, 'amount': 0},
                'filters': modelData['filters'],
                'options': modelData['options'],
                'models': [],
                'prefix': this.tools.getRandomize()
            };
            this.tabs['data'].push(data);
            if (!this.tabs['value']) this.tabs['value'] = data['value'];
        }

        this.companyData = res['company_data']
    }

    setDefaultData() {
        this.tabs['value'] = 'checks';
        this.tabs['data'] = [
            {
                'value': 'checks',
                'title': 'checks',
                'checked': true,
                'modelName': 'bandObligoChecks',
                'display': 'table',
                'header': ['payDate', 'cardNum', 'qprice', 'currency'],
                'fields': ['payDate', 'cardNum', 'qprice', 'code'],
                'filters': {},
                'options': {},
                'models': [],
                'prefix': 'checksList'
            },
            {
                'value': 'openDebt',
                'title': 'openDebt',
                'checked': true,
                'modelName': 'bandObligoFncitems',
                'display': 'card',
                'fields': ['balDate', 'ivnum', 'fncPatname', 'details', 'price', 'fncDate'],
                'filters': {},
                'options': {},
                'models': [],
                'prefix': 'openDebtList'
            },
            {
                'value': 'withoutCharge',
                'title': 'withoutCharge',
                'checked': false,
                'modelName': 'bandObligoTrans',
                'display': 'table',
                'header': ['curDate', 'docno', 'price', 'currency'],
                'fields': ['curDate', 'docno', 'price', 'currencyCode'],
                'filters': {},
                'options': {},
                'models': [],
                'prefix': 'withoutChargetList'
            }
        ]
    }

    setTab(event = null) {
        if (event) {
            this.tabs['value'] = event['detail']['value'];
        }
    }

    setChecked(tab, model) {
        let amount = 0;
        let toPay = 0;
        let toPay_ids = [];
        let balance = 0;
        let checked = tab['checkedData']['checked'];
        tab['checkedData']['data'][model.data.id] = model;
        for (let key in checked) {
            let invoice = tab['checkedData']['data'][key];
            let invoice_balance = parseFloat(invoice.data.balance);
            if (checked[key]) {
                amount++;
                balance += invoice_balance;

                if (tab['options']['payment_allow'] && !invoice.data.paymentVerified) {
                    toPay += invoice_balance
                    toPay_ids.push(invoice.data.id)
                }
            }
        }


        tab['checkedData']['amount'] = amount;
        tab['checkedData']['balance'] = balance;
        tab['checkedData']['toPay'] = toPay;
        tab['checkedData']['toPay_ids'] = toPay_ids;
    }

    refresh(prefix) {
        this.events.publish(prefix + '_updateFilter', {'refresh': true});
    }

    setModel(model, tab)
    {
        let options = tab['options']
        console.log(model)
        console.log(options)
        console.log(tab)

        if(model.data['order']) {
            this.tools.goTo('order/' + model.data['order'])
            return;
        }

        if(!options['onclick'])
             return

        if(model.data.item && options['onclick']['page'] == 'item/')
        {
            let item = this.itemProvider.getItemById(model.data.item);
            this.itemProvider.openItem(item);
            return;
        }

        if(options['onclick']['url_callback'])
        {
            let me = this;
            this.tools.yesOrNoAlert(function (){
                me.modelProvider.customPost(options['onclick']['url_callback'], {'modelId':model.data[options['onclick']['modelKey']],}, {'setPrefix':true}).then((res)=>{
                    me.modelProvider.presentToast(me.tools.getTranslate(res['data']['res']), true, res['data']['color']);
                    me.events.publish(tab['prefix']+'_updateModels', tab['filters']);
                })
            })
        }
    }

    payInvoice(tab) {
        let prefix = 'invoice_new_payment_'+this.tools.getRandomize();
        this.tools.showModal(PaymentPage, {
            'params':{'invoices': tab['checkedData']['toPay_ids'].join(','), 'prefix':prefix}
        })

        this.events.subscribe(prefix, (res)=>{
            Object.keys(tab['checkedData']['checked']).forEach(key => {
                delete tab['checkedData']['checked'][key];
            })

            tab['checkedData']['amount'] = 0;
            tab['checkedData']['balance'] = 0;
            tab['checkedData']['toPay'] = 0;
            tab['checkedData']['toPay_ids'] = [];


            this.events.publish(tab['prefix'] + '_updateModels');
            this.events.unsubscribe(prefix);
        })

    }

}
