import {TranslateService} from "@ngx-translate/core";

export class Address {
    data = {
        'city': null,
        'street': null,
        'ext_id': null,
        'homeNumber': null,
        'floor': null,
        'apt': null,
        'cityId': null,
        'state': null,
        'neighborhood': null,
        'onRadius': null,
        'appuser': null,
        'company': null,
        'shippingData': {'active':null}
    };

    constructor(public data1: any) {
        this.data = data1;
    }

    getAddress() {
        if(!this.data) return '';

        let city = this.data.city;
        if(this.data.cityId && this.data['cityIdData'])
            city = this.data['cityIdData']['data']['name'];
        if(this.data.cityId && this.data['cityName'])
            city = this.data['cityName'];

        let address = city + ', ' + this.data.street + ' ' + this.data.homeNumber;

        if(this.data.state)
        {
            if(this.data.state && this.data['stateData'])
                address = this.data['stateData']['data']['name']+', '+address;
            if(this.data.state && this.data['stateName'])
                address = this.data['stateName']+', '+address;
        }
        // address += this.data.floor ?', '+ this.translate.instant('floor') + ' ' + this.data.floor : '';
        // address += this.data.apt ?', '+ this.translate.instant('apt') + ' ' + this.data.apt : '';
        // //console.logthis.data)
        // //console.logaddress)
        return address
    }

    //shortHeaders = [];
    prefix = "shipmentAdress";
    title = "Address";
    dataFields = {
        id: {'title': 'ID', 'value': null, 'type': 'number', 'readonly': true},
        created: {'title': 'Created', 'value': null, 'type': 'date', 'readonly': true},
        updated: {'title': 'Updated', 'value': null, 'type': 'date', 'readonly': true},
        onRadius: {'title': 'onRadius', 'value': null, 'type': 'boolean', 'readonly': true},
        // state: {'title': 'state', 'value': null, 'type': 'text'},
        city: {'title': 'city', 'value': null, 'type': 'modelscroll', 'displayField':'name', 'required': true, 'maxlength': 30},

        state: {
            'title': 'state',
            'showDefault': true, 'value': null, 'required':true,
            'filters': {},
            'displayField': 'stateName', 'type': 'modelscroll',
            'modelscroll': 'State', 'keyField': 'id', 'valueField': 'name',
            //'multipleSelect':true,
        },
        cityId: {
            'title': 'city',
            'showDefault': true, 'value': null, 'required':true,
            'filters': {},
            'displayField': 'cityName', 'type': 'modelscroll',
            'modelscroll': 'City', 'keyField': 'id', 'valueField': 'name',
            //'multipleSelect':true,
        },
        neighborhood: {
            'title': 'neighborhood',
            'showDefault': true, 'value': null, 'required':true,
            'filters': {},
            'displayField': 'neighborhoodName', 'type': 'modelscroll',
            'modelscroll': 'Neighborhood', 'keyField': 'id', 'valueField': 'name',
            //'multipleSelect':true,
        },

        // city: {'title': 'city', 'value': null, 'type': 'typeahead', 'modelName':'City', 'displayField':'name', 'required': true, 'maxlength': 30},
        street: {'title': 'street', 'value': null, 'type': 'text', 'required': true, 'maxlength': 30},
        homeNumber: {'title': 'homeNumber', 'value': null, 'type': 'number', 'required': true, 'maxlength': 4},
        floor: {'title': 'floor', 'value': null, 'type': 'number', 'maxlength': 4},
        apt: {'title': 'apt', 'value': null, 'type': 'text', 'maxlength': 4},
        shippingData: {'title': 'shippingData', 'value': null, 'type': 'text', 'readonly':true},
    }


}
