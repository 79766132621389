import {Component, Input, OnInit} from '@angular/core';
import {AppConfig} from "../../../../core/conf/main";

import {ModelProvider} from "../../../providers/modelProvider/ModelProvider";
import {Events} from "../../../providers/events";

@Component({
    selector: 'app-appnewlist',
    templateUrl: './appnewlist.component.html',
    styleUrls: ['./appnewlist.component.scss'],
})
export class AppnewlistComponent implements OnInit {

    appConfig = AppConfig;

    @Input()
    modelName: any;

    @Input()
    prefix;

    @Input()
    keys: any;

    @Input()
    checked: any;
    datachecked: any = {};

    @Input()
    tablExtData = {'showEdit': false};


    filters = {};
    ready = false;
    models = [];

    constructor(public events: Events,
                public modelProvider: ModelProvider) {

    }

    ngOnInit() {
        //console.logthis.checked);
        for (let key in this.checked) {
            this.datachecked[key] = this.checked[key];
        }

        let me = this;
        this.events.subscribe('update_' + this.prefix, (data) => {
            me.models = data.models;
            me.ready = true;
        });
    }

    clear() {
        for (let key of this.checked) {
            this.checked[key] = false;
        }
    }

}
