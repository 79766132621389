// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.field-label {
  font-size: 15px;
}

.title-text {
  font-size: 14px;
}

.company-title {
  vertical-align: top;
  padding: 0 5px;
}

.icon-size {
  font-size: 18px;
}

.main-title {
  padding: 5px;
  text-align: center;
}

.box-size {
  --min-height: 35px;
  padding: 0;
}`, "",{"version":3,"sources":["webpack://./src/core/pages/agent/agent-companies/agent-companies.component.scss"],"names":[],"mappings":"AAAA;EAEE,eAAA;AAAF;;AAGA;EACE,eAAA;AAAF;;AAGA;EACE,mBAAA;EACA,cAAA;AAAF;;AAGA;EACE,eAAA;AAAF;;AAGA;EACE,YAAA;EACA,kBAAA;AAAF;;AAGA;EACE,kBAAA;EACA,UAAA;AAAF","sourcesContent":[".field-label {\n  //max-width: 35%;\n  font-size: 15px;\n}\n\n.title-text {\n  font-size: 14px;\n}\n\n.company-title {\n  vertical-align: top;\n  padding: 0 5px;\n}\n\n.icon-size{\n  font-size: 18px;\n}\n\n.main-title{\n  padding: 5px;\n  text-align: center;\n}\n\n.box-size{\n  --min-height: 35px;\n  padding: 0;\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
