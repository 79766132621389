import {Component, ElementRef, Input, OnInit} from '@angular/core';
import {CartModel} from "../../../../../framework/model/core/items/cartModel";
import {Item} from "../../../../../framework/model/core/items/item";

@Component({
  selector: 'app-item-sale-data',
  templateUrl: './item-sale-data.component.html',
  styleUrls: ['./item-sale-data.component.scss'],
})
export class ItemSaleDataComponent implements OnInit {

  @Input()
  public cartModel:CartModel;

  @Input()
  public item:Item;

  @Input()
  public el:ElementRef;

  @Input()
  public listPrefix;

  constructor() { }

  ngOnInit() {}

}
