import {Injectable} from '@angular/core';

import {Storage} from "@ionic/storage";

import {ModelProvider} from "../../framework/providers/modelProvider/ModelProvider";
import {UserData} from "../../framework/providers/user-data";
import {AuthService} from "../../framework/providers/auth-service/auth-service";
import {AppConfig} from "../conf/main";
import {AlertController, PopoverController} from "@ionic/angular";
import {Order} from "../../framework/model/core/order/order";
import {AppUser} from "../../framework/model/core/appuser/appuser";
import {Business} from "../../framework/model/core/business/business";
import {BusinessService} from "./BusinessService";
import {ActivatedRoute} from "@angular/router";
import {Item} from "../../framework/model/core/items/item";
import {Address} from "../../framework/model/core/address/address";
import {TranslateService} from "@ngx-translate/core";
import {AppDataService} from "./AppDataService";
import {CouponsComponent} from "../pages/cart/components/coupons/coupons.component";
import {AddToCartComponent} from "../pages/item/components/add-to-cart/add-to-cart.component";
import {InitFormsPage} from "../pages/forms/pages/init-forms/init-forms.page";
import {BonusItemsComponent} from "../pages/item/components/bonus-items/bonus-items.component";
import {NewCartTicketsComponent} from "../pages/cart/components/new-cart-tickets/new-cart-tickets.component";
import {GlobalSettings} from "../../framework/model/core/business/globalSettings";
import {ModelToolsProvider} from "../../framework/providers/model-tools/model-tools";
import {AppSettings} from "../conf/appSettings";
import {Events} from "../../framework/providers/events";
import {AdminService} from "./AdminService";
import {Company} from "../../framework/model/core/company/company";
import {CartService} from "./CartService";
import {SavedCartComponent} from "../pages/cart/components/saved-cart/saved-cart.component";
import {async} from "@angular/core/testing";

// import {OrderStatusAlertComponent} from "../admin/component/order-status-alert/order-status-alert.component";

@Injectable()
export class OrderService {
    aviodAppDataMode = false;
    showStaffButtons = false;

    cart: Object;
    cartData: Object;

    cartStatus: object = {
        'value': true,
        'prices': true,
        'buttonText': '',
    };

    order: Order;
    orderId: number;
    totalCharge: number;

    public appuser: AppUser;
    public company: Company;
    business: Business;
    address: Address;

    appuserPrefix = 'appuser';

    pageName: string;
    sectionPage_sideSectionBarOpen: boolean = false;
    adminUserLogin: boolean = false;
    orderStatusAlertVisible: boolean = false;
    addToCartPopupVisible: boolean = false;

    cartCalcData: object = {};

    options = {};

    public savedCartList: Array<object>;

    public lastSavedState;

    constructor(private modelProvider: ModelProvider,
                private adminService: AdminService,
                private auth: AuthService,
                public userData: UserData,
                public businessService: BusinessService,
                public modelTools: ModelToolsProvider,
                public route: ActivatedRoute,
                public appDataService: AppDataService,
                public translate: TranslateService,
                public popoverCtrl: PopoverController,
                public appSettings: AppSettings,
                public cartService: CartService,
                public events: Events) {

        this.events.subscribe('businessSets', (res) => {
            this.businessSets(res);
        });

        this.events.subscribe('userLogged', (cren) => {
            // //console.log'userLogged')
            // //console.logcren)
            this.listAppuser(cren['username'], cren['pref']);
        });

        this.events.subscribe('getAppUser', (params) => { // for business service
            params['appuser'] = this.appuser;
        });


        this.events.subscribe('appPriceSets', (params) => {
            // this.recheckCart();


            // this.getCartCalc(true);

        });


        // from cart page.
        this.events.subscribe('agentCompanyChanged', (params) => {
            this.initOrder()
            this.setCartStatus();
        });


        this.events.subscribe('orderActionChanged', (params) => {
            // this.cartStatus['buttonText'] = 'finish'+this.cartData['action'];
            this.setCartStatus();
        });

        // from appdataService - 280
        //will show on profile page the open carts.
        this.events.subscribe('setCart', (data) => {
            if (data && data != 'undefined') {
                let me = this;
                if(!this.savedCartList) this.savedCartList = [];
                this.savedCartList.length = 0;
                data.forEach((c) => {
                    this.savedCartList.push(c);
                })

                if(this.savedCartList.length)
                {
                    this.cartService.clearCart();
                    this.cartService.updateData(this.savedCartList[0]['data']);
                }
            }
        });

        this.events.subscribe('cartCalcUpdated', (data) => {
            this.checkClientCompany(data['cart_data']['company_data']);
            this.setCartStatus();
        });
    }

    start() {
        this.initCart();
        this.initOrder();


        this.events.subscribe('appuserChanged', (params) => {
            //console.log'subscribe APP USER CHANGED')
            this.setCartStatus();
        });
        this.events.subscribe('appuserChanged1', (params) => {
            //console.log'subscribe APP USER CHANGED 111')
            this.setCartStatus();
        });
    }


    checkClientCompany(company_data) {
        if (!this.isAgent()) return
        if (company_data['id'] == this.appuser.data.companyId) return;

        let me = this;
        let params = {
            'title': this.translate.instant('otherClientRecongnized'),
            'message': this.translate.instant('clientName') + ' ' + company_data['name'] + '<br><br>' + this.translate.instant('whichClientDoYouWant'),
            'no': this.company.data.companyName,
            'yes': company_data['name'],
        }

        this.modelTools.dismissAlert();
        this.modelTools.yesOrNoAlert(function () {
            me.changeAgentCompany(null, company_data['id'], true, null, true)
        }, params, params['title'], function () {
            me.changeAgentCompany(null, me.appuser.data.companyId, true, null, true)
        });
    }


    public logout() {
        this.order = new Order({});
        this.cart = {};
        this.totalCharge = null;
        this.appuser = null;
        this.auth.logout();
        this.clearCart();
        let me = this;
        setTimeout(function () {
            me.events.publish('appuserChanged', {'appuser': null});
        }, 100)
        // this.events.publish('appuserChanged3', {'appuser': null});
    }

    businessSets(params = {}) {
        this.business = this.businessService.getBusiness();
        this.initOrder();


        // if (params['cart'])
        //     this.cartItemsByPrefix(params['cart']);
        //
        // // this.route.queryParams.subscribe(params => {
        // //     let cartItemsPrefix = params['cart'];
        // if (this.cartData['cartPrefix'])
        //     this.cartItemsByPrefix(this.cartData['cartPrefix']);
        // // });
        this.setCartStatus();
        this.getCartFromStorage(true);
    }

    initCart() {
        this.cart = this.clearObj(this.cart);
        // this.cartData = this.clearObj(this.cartData);
        //
        // this.cartData['curTicket'] = null;
        // this.cartData['tickets'] = 0;
        //
        // this.cartData['amount'] = 0;
        // this.cartData['vat'] = 0;
        // this.cartData['totalCharge'] = 0;
        // this.cartData['coupon'] = null;
        // this.cartData['couponDiscount'] = 0;
        //
        // this.cartData['shippingPrice'] = 0;
        // this.cartData['minimumPrice'] = 0;
        //
        // this.cartData['action'] = null;
        // this.cartData['actionData'] = null;
        // this.cartData['orderDetails'] = null;
        //
        // this.cartData['items'] = {};
        //
        // this.cartData['searchPrefix'] = 'cartSearch';
        // this.cartData['searchText'] = null;
        //
        // this.cartData['extorder'] = null;
        //
        // this.cartData['cartUpdated'] = false;
        // this.cartData['cartUpdatedProgress'] = false;
        //
        // this.cartData['needBranch'] = null;
        // this.cartData['pickupBranch'] = null;

        this.cartData = this.cartService.getCartData();

        this.setCartStatus();
        this.setSearchEvent();

        this.totalCharge = 0;
    }

    clearObj(obj) {
        if (!obj) {
            return {};
        }
        for (let k in obj) {
            delete obj[k];
        }
        return obj
    }

    isApproved() {
        return (this.appuser && this.appuser.data['approved']);
    }

    isStaff() {
        return (this.appuser && this.appuser.data['is_staff']);
    }

    isAgent() {
        return (this.appuser && this.appuser.data['userType'] == 3);
    }

    isStaffOrAgent() {
        return (this.appuser && (this.appuser.data['userType'] == 3 || this.appuser.data['is_staff']));
    }

    isAgentTool() {
        return this.businessService.getGlobalSettings().data['agentTechnicalTool'] && this.isAgent();
    }

    getCartOptions() {
        if (!this.isStaffOrAgent())
            this.options['agentTechnicalTool'] = false;

        this.options['tickets'] = this.business.data.GlobalSettingsData.tickets;
        return this.options;
    }

    getCartOptionKey(key) {
        return this.options[key];
    }

    changeAgentToolVisible() {
        this.setCartOptionValue('agentTechnicalTool');
    }

    setCartOptionValue(key, value: any = -1) {
        if (value == -1) {
            value = !this.options[key]
        }
        this.options[key] = value
        this.modelTools.setKey('cartOptions' + AppConfig.bisPrefix, this.options);
    }

    initOrder() {
        if (!this.order) this.order = new Order({});
        // let address = {'city': 'תל אביב', 'street': 'הארבעה', 'homeNumber': 1, 'floor': 3, 'apt': 4};
        let address = {};
        let data: any = {'address': address, 'paymentTypeName': 'creditCard'};

        if (this.order) {
            data = {
                'name': this.order.data.name,
                'email': this.order.data.email,
                'cellphone': this.order.data.cellphone,
                'contactMan': this.order.data.contactMan,
                'address': this.order.data.address,
                'type': this.order.data.type,
                'shipmentAdress': this.order.data.shipmentAdress,
                'appuser': this.order.data.appuser,
                'paymentTypeName': this.order.data.paymentTypeName,
                'action': null,
            }
        }

        let order = new Order(data);
        this.order['data'] = order.data;

        if (this.appuser) {
            this.order.data.appuser = this.appuser.data.id;
            this.order.data.name = this.appuser.data.name;
            this.order.data.email = this.appuser.data.company_emails;
            this.order.data.contactMan = this.appuser.data.name;
            this.order.data.cellphone = this.appuser.data.cellphone;
        }

        return this.getOrder();
    }


    setCartPrefix(prefix) {
        this.cartData['cartPrefix'] = prefix;
    }

    cartItemsByPrefix(prefix) {
        this.modelProvider.list('cartItem', {'prefix': prefix, 'disableLoading': true}).then((res) => {
            // this.orderService.addToBasket(ticket, this.item, 1, null, null, price);
            if (!res['models'].length) return;

            for (let k = 0; k < res['models'].length; k++) {
                let cartItem = res['models'][k]
                let item = new Item(cartItem.data['itemData']);
                let ticket = {'data': {'id': 1}};
                let price = cartItem.data.price;
                this.addToBasket(ticket, item, 1, null, null, price, price);
                this.cartData['massof'] = cartItem.data.massof;
                this.cartData['station'] = cartItem.data.paymentStation
            }

            let cren = {
                'username': res['models'][0].data.username,
                'password': res['models'][0].data.username
            }
            // this.auth.login(cren)
            //     .subscribe(allowed => {
            //         this.events.publish('setLoadingOff');
            //         if (allowed) {
            //             // this.events.publish('user:login');
            //             this.listAppuser(cren['username'])
            //
            //         } else {
            //
            //         }
            //     });
        })
    }

    getOrder() {
        return this.order;
    }

    getAddress() {
        return this.address;
    }

    setOrderType(type) {
        // //console.logthis.order)
        this.order.data.type = type;
        this.cartData['pickup'] = type == 'takeAway';
        this.cartData['needBranch'] = type == 'takeAway' || type == 'sit';
        this.order.data.pickup = this.cartData['pickup'];
        this.cartData['minimumPrice'] = 0;
        this.cartData['shippingPrice'] = 0;
        this.updateTotalCharge();
        this.setCartStatus();
        this.events.publish('orderTypeChanged', {'type': type});
        //
        // //console.logthis.order)
        // //console.logthis.cartData)
    }

    setPickupBranch(model) {
        this.cartData['pickupBranch'] = model.data.id;
        this.cartData['pickupBranchData'] = model;
        this.order.data.pickupBranch = model.data.id;

        // //console.logthis.cartData);
    }

    setAppUser(appuser,  cache = true) {
        this.appuser = appuser;
        if (appuser.data['company_data'])
            this.company = new Company(appuser.data['company_data']);

        this.initOrder();
        this.getDefaultAddress();

        // //console.log'PUBLISH APP USER CHANGED')
        this.events.publish('appuserChanged', {'appuser': this.appuser, 'skip_storage':!cache});

        // if(this.business && this.cartData['amount'])
        //     this.getCartCalc();

        if (this.business && this.cartData['amount']) {
            this.cartData['cartUpdated'] = false;
            this.getCartCalc();
        }

        // console.log("APPUS", appuser.data.companyPriceApproved)
        this.setCartStatus();

        // this.events.publish('appuserChanged1', {'appuser': this.appuser});

        // this.saveAppUserToStorage(this.appuser);
        // this.login(this.appuser.data.username, this.appuser.data.username);
    }

    getAppUser() {
        return this.appuser;
    }

    getCompany() {
        return this.company
    }

    getAppUserKey() {
        if (!this.appuser) return;
        if (this.appuser.data['appuserKey'])
            return this.appuser.data['appuserKey'];
        return this.appuser.data['id'];
    }

    getOrderType() {
        if (!this.order) return;
        return this.order.data.type;
    }

    isagent() {
        if (!this.appuser) return false;
        return this.appuser.data.userType == 3;
    }

    checkUserUpdates() {
        this.listAppuser(null, null, false);
        this.businessService.getBis({'pref': AppConfig.bisPrefix}, false);
    }

    listAppuser(username = null, prefix = null, cache = true) {


        // let storageKey = username + '_' + AppConfig.bisPrefix+'_DATA';
        //
        // this.modelTools.getKey(storageKey).then((res) => {
        //     // //console.log'GET APPUSER', storageKey, res)
        //     let timeout = 0;
        //
        //     if (res) {
        //         this.setAppUser(res);
        //         timeout = 4000;
        //     }
        //
        let filters = this.getAppUserListFilters(username, prefix);
        let me = this;
        //
        //     setTimeout(function () {
        me.modelProvider.storageList('appuser', filters, cache, 'getAppUser').then((res) => {
            //console.log("LIST APP USER", res)
            if (res['models'] && res['models'].length == 0) {
                me.auth.logout();
                // me.modelTools.setKey(storageKey, null);
            } else {
                me.setAppUser(res['models'][0], cache);
                // //console.log'SAVE APPUSER', storageKey, res['models'][0])
                // me.modelTools.setKey(storageKey, res['models'][0]);
            }
        })
        // }, timeout)
        // //console.log"GET APPUSER", AppConfig.bisPrefix);
        // });
    }

    getAppUserListFilters(username = null, prefix = null) {
        if (!username && this.appuser) {
            username = this.appuser.data.username;
        }

        let filters = {'username': username, 'disableLoading': true, 'include_company': true};
        if (prefix)
            filters['pref'] = prefix;
        return filters;
    }

    resetAppUser() {
        // this.saveAppUserToStorage(null);
        this.logout();
        // this.events.publish('appuserSets', {'appuser': null});
        // return this.appuser;
    }

    addToBasket(ticket, product, amount, orderProduct = null, items = null, price = null, agentPrice = null, index = null,
                addToAmount = true, comments = null, props = [], dealData = null, showPopup = true, actionAmount = 0,
                data = {}) {
        // this.cartData['amount'] += 1;
        // this.events.publish('cartUpdated', {});
        // return
        // //console.log'add to basket')
        // //console.logprice)
        // //console.logproduct.data.name)
        // //console.logamount)
        // //console.logorderProduct ? orderProduct.data.id : 0)
        ticket = this.initTicket(ticket);

        if (ticket == -1) {
            // if (showPopup)
            //     this.showTicketsPopup();
            let ticketId = this.newTicket('New Ticket', null);
            ticket = this.initTicket(ticketId);
        }

        if (!index)
            index = 100000 - this.cartData['amount']; // makes new items to the top of the obj.

        if (amount < 0 && !this.cart[ticket.data.id]['products'][index]) // find item with props.
        {
            for (let k in this.cart[ticket.data.id]['products']) {
                if (this.cart[ticket.data.id]['products'][k]['product'].data.id)
                    index = k;
            }
        }

        // //console.logindex)
        // //console.logthis.cart)


        // copy models;
        items = this.copy(items);
        // product = this.copy(product);
        price = price !== null ? price : product.data.price;

        let corePrice = agentPrice ? agentPrice : price; // cart item price before currency calc.

        price = this.getPriceCurrencyCalc(product, price);
        agentPrice = this.getPriceCurrencyCalc(product, agentPrice);


        if (!this.cart[ticket.data.id]['products'][index]) {
            this.cart[ticket.data.id]['products'][index] = {
                'index': index,
                'product': product,
                'props': props,
                'amount': 0,
                'actionAmount': actionAmount,
                'type': product.prefix,
                'items': items,
                'corePrice': corePrice,
                'price': price,
                'agentPrice': agentPrice,
                'final_unit_price': price,
                'totalPrice': price * amount,
                'fixedPrice': 0,
                'dealData': dealData,
                'images': data['images'] ? data['images'] : [],
                'comments': comments,
                'stock': product.data.stock,
                'ticket': ticket.data.id,
                'active': true,
                'data': {}
            };
            this.cartData['amount'] += 1;
            this.cart[ticket.data.id]['amount'] += 1;

            if (showPopup) {
                // this.showAddToCartToast();
            }
        } else {
            this.cart[ticket.data.id]['products'][index]['props'] = props;
            this.cart[ticket.data.id]['products'][index]['comments'] = comments;
        }

        let cartItem = this.cart[ticket.data.id]['products'][index];

        // //console.log'cart', this.cart);

        for (let key in data) {
            if (!data[key]) continue;
            this.cart[ticket.data.id]['products'][index]['data'][key] = data[key];
        }

        this.cart[ticket.data.id]['products'][index]['agentPrice'] = agentPrice;

        let cartprice = price;
        if (this.cart[ticket.data.id]['products'][index]['agentPrice'])
            cartprice = this.cart[ticket.data.id]['products'][index]['agentPrice'];


        // //console.logthis.cart[ticket.data.id]['products'][index]['agentPrice'])
        // //console.logcartprice)
        // //console.logcorePrice)
        this.cart[ticket.data.id]['products'][index]['corePrice'] = corePrice;
        this.cart[ticket.data.id]['products'][index]['price'] = cartprice;

        // //console.logaddToAmount, amount, this.cart[ticket.data.id]['products'][index]['amount'])

        if (addToAmount)
            this.cart[ticket.data.id]['products'][index]['amount'] += amount;
        else
            this.cart[ticket.data.id]['products'][index]['amount'] = amount;

        if (orderProduct)
            this.cart[ticket.data.id]['products'][index]['orderProduct'] = orderProduct;

        this.setCartCalcPrefix();

        if (this.cart[ticket.data.id]['products'][index]['amount'] == 0 && !actionAmount) {
            this.removeFromCart(ticket.data.id, index, null, true);
            // //console.log'REMOVE!!!', index)
            return {};
        }

        //     this.cart[ticket.data.id]['products'][index]['amount'] = 0;
        //     this.cartData['amount']-=1;
        // }


        this.cartUpdated(true);
        this.updateTotalCharge();
        // this.saveCartOnStorage();

        // this.cartItemChanged(index, ticket.data.id);

        // this.updateTicketProductsArr(this.cart[ticket.data.id])
        return this.getCartItem(index);
        // return this.cart[ticket.data.id]['products'][index];
    }

    updateTicketProductsArr(ticket) {
        let newarr = [];
        for (let p in ticket['products']) {
            newarr.push(ticket['products'][p]);
        }
        ticket['productsarr'] = newarr;

        // //console.log'arr', ticket['productsarr'])
    }

    addPropsToCartItem(cartItem, props) {
        //console.logcartItem)
        //console.logprops)
    }

    showAddToCartToast() {

        this.modelProvider.presentToast(this.translate.instant('itemAddedToCart'));
    }

    addToCartPopup(model) {
        if (this.addToCartPopupVisible) {
            let me = this;
            setTimeout(function () {
                me.events.publish('addToCartComponentExist', {'model': model});
            }, 200)
        } else {
            this.addToCartPopupVisible = true;
            this.showAddToCartPopup(model);
        }

        // let existParams = {'res': false, 'model': model};
        //
        //
        // let me = this;
        // setTimeout(function () {
        //     //console.log'existParams', existParams);
        //
        //     if (existParams['res']) {
        //         //console.log"EXISTTT!!!!!!")
        //         return
        //     }
        // }, 1000);
    }

    //thank you for adding to cart popup
    async showAddToCartPopup(model) {
        const popover = await this.popoverCtrl.create({
            component: AddToCartComponent,
            componentProps: {'model': model},
            event: null,
            animated: true,
            showBackdrop: true,
            cssClass: 'center-popover',
        });


        popover.onDidDismiss().then(
            (data: any) => {
                // //console.log'addToCartPopupVisible dismissss!!!!')
                this.addToCartPopupVisible = false;
            });


        return await popover.present();
    }

    getPriceCurrencyCalc(item, price) {
        // //console.logthis.business);
        // //console.logitem);
        // //console.logprice);

        let rates = this.appDataService.getRates();
        // //console.logrates)
        let dollarRate = rates['dollarRate'];
        let dollarSaleRate = rates['dollarSaleRate'];

        if (item.data.currencyCode && item.data.currencyCode.length) {
            if (item.data.currencyCode[0] && item.data.currencyCode[0].includes('$')) {
                if (item.data.currencyCode[0] == '$')
                    price = price * dollarSaleRate;
                else if (item.data.currencyCode[0] == '$*')
                    price = price * dollarRate;
            }
        }
        return price

    }

    initTicket(ticketId) {
        if (!ticketId)
            ticketId = this.cartData['curTicket'];

        if (!ticketId) {
            if (this.business.data.GlobalSettingsData.tickets) {
                return -1
            }
        }

        if (!ticketId) {
            ticketId = 1;
        }

        let ticket = {'data': {'id': ticketId}};

        if (!this.cart[ticketId]) {
            this.newTicket('New Ticket', ticketId)
        }
        return ticket
    }

    getAmount(ticket, product) {
        if (!this.cart[ticket.data.id]) return 0;
        if (!this.cart[ticket.data.id]['products'][product.data.id]) return 0;
        return this.cart[ticket.data.id]['products'][product.data.id]['amount']
    }

    removeFromCart(ticketId, productKey, alert = null, byIndex = false) {
        let ticket = this.initTicket(ticketId);
        if (ticket == -1) return;
        if (!this.cart[ticket['data'].id]) return;

        //console.logproductKey, this.cart, byIndex, ticket);

        for (let k in this.cart[ticket['data'].id]['products']) {
            if (!byIndex) {
                if (this.cart[ticket['data'].id]['products'][k]['product'].data.id == productKey)
                    this.removeItem(ticket['data'].id, k);
            } else {
                if (this.cart[ticket['data'].id]['products'][k]['index'] == productKey)
                    this.removeItem(ticket['data'].id, k);
            }
        }
        // if (!this.cart[ticket.data.id]['products'][productKey]) return;
        // this.removeItem(ticket.data.id, productKey);

    }

    removeItem(ticketId, productKey) {
        let modelId = this.cart[ticketId]['products'][productKey]['product'].data.id;

        this.cartData['amount'] -= 1;
        this.cartData['totalCharge'] -= this.cart[ticketId]['products'][productKey]['price'];
        this.cartData['charge'] -= this.cart[ticketId]['products'][productKey]['price'];
        this.cartData['items'][ticketId][modelId]['amount'] = 0;

        this.cart[ticketId]['products'][productKey]['amount'] = 0;
        this.cart[ticketId]['amount'] -= 1;
        // this.cartItemChanged(productKey, ticketId);

        delete this.cart[ticketId]['products'][productKey];
        delete this.cartData['items'][ticketId][modelId];

        this.updateTotalCharge();
        this.cartUpdated(true);
        this.saveCartOnStorage();

        this.cartItemChanged(modelId, ticketId);
    }

    cartItemChanged(itemId, ticketId, updateModel = true) {
        // if(updateModel) {
        //     let appitem = this.appDataService.getItemModel(itemId);
        //     // console.log("APP ITEM!", appitem)
        //     // appitem.setAgentTool(this.cart[ticketId]['products'][itemId])
        // }
        this.events.publish(itemId + '_cartItemChanged', this.cart[ticketId]['products'][itemId]);
    }

    clearCart(initAppUser = false) {
        let dbCart = this.cartData['db_cart']
        if(dbCart){
            this.removeCartById(dbCart);
        }

        // this.clearCartItems();
        // this.initCart();
        this.cartService.clearCart();
        return
        this.cartUpdated(true);
        // this.saveCartOnStorage();

        if (initAppUser)
            this.initOrder()

        // if (initAppUser)
        //     this.setAppUser(this.appuser)
    }

    newOrderPopup(order) {
        // let alerttxt = this.translate.instant('dearCustomerThankYouForYourOrder') + ' ' + this.business.data.GlobalSettingsData.name + '.';
        // let alertext = this.translate.instant('orderNumber') + ' ' + order.data.id + '.';
        let action = order.data.action && order.data.action != 'updateOrder' ? order.data.action : 'order'
        let alerttxt = this.translate.instant('dearCustomer') + ', ' + this.translate.instant(action) + ' ' + this.translate.instant('id') + ' ' + order.data.id;
        let alertext = this.translate.instant('successfulyDode') + '.'

        let settings = this.appSettings.getSettings('cart');

        if (settings['new_order_text']) {
            alerttxt = settings['new_order_text']
        }

        if (order.data.action && settings['new_order_text_' + order.data.action]) {
            alerttxt = settings['new_order_text_' + order.data.action]
        }


        this.modelTools.textAlert(alerttxt, alertext);
    }

    orderCreated() {
        this.disconnectClient()
    }

    disconnectClient() {
        let company_settings = this.appDataService.getPricesListKey('company_settings');
        //console.log("COMPANY SETTINGS", company_settings)
        if (!company_settings) return;
        // this.modelProvider.custom('getUserDefaultCompany', {'setPrefix':true}).then((res)=>{
        if (!company_settings['default_company']) return;
        this.changeAgentCompany(null, company_settings['default_company']);
        // })
    }

    clearCartItems() {

        for (let ticket in this.cart) {
            for (let prod in this.cart[ticket]['products']) {
                // delete this.cart[ticket]['products'][prod]
                this.removeItem(ticket, prod);
            }
        }

    }

    cartUpdated(newItem = false, params = {}) {
        params['new'] = newItem;
        this.setCartStatus();
        // this.events.publish('cartUpdated', params);
    }

    getCartAmount() {
        return this.cartData['amount']
    }

    getCartData() {
        return this.cartData
    }

    getCart() {
        return JSON.parse(JSON.stringify(this.cart));
    }

    getCartObj() {
        return this.cart;
    }

    getCartItem(itemId, getAll = false) {
        // console.log("get item id: ", itemId)
        // console.log(this.cartData['items'])
        // console.log(this.cartData['curTicket'])

        //TODO: asaf change. is that ok??
        if (!this.cartData['curTicket']) {
            this.cartData['curTicket'] = 1;
        }

        if (!this.business.data.GlobalSettingsData.tickets)
            return this.getCartTicketItem(itemId, this.cartData['curTicket'], getAll);
        if (this.cartData['items']) {
            if (!this.cartData['items'][this.cartData['curTicket']]) return {'amount': 0, 'data': {}};
            if (!this.cartData['items'][this.cartData['curTicket']][itemId]) return {'amount': 0, 'data': {}};
        } else {
            return {'amount': 0, 'data': {}};
        }

        return this.cartData['items'][this.cartData['curTicket']][itemId];
    }

    getCartItemByIndex(index) {
        for (let t in this.cart) {
            for (let p in this.cart[t]['products']) {
                if (p == index) {
                    return this.cart[t]['products'][p]
                }
            }
        }
        return {'amount': 0, 'data': {}};
    }

    getCartTicketItem(itemId, ticket = null, getAll = false) {
        if (!ticket) ticket = this.getActiveTicket();
        if (!this.cart[ticket]) return {'amount': 0, 'data': {}};

        if (getAll) {
            let cartItem = this.cartData['items'][ticket][itemId];
            if (cartItem)
                return cartItem
        }

        if (!this.cart[ticket]['products'][itemId]) {
            let amount = 0;
            for (let k in this.cart[ticket]['products']) {
                if (this.cart[ticket]['products'][k]['product'].data.id == itemId)
                    amount += this.cart[ticket]['products'][k]['amount'];
            }

            return {'amount': amount, 'data': {}};
        }
        return this.cart[ticket]['products'][itemId];
    }

    hideAgentToolbar(value) {
        this.cartData['hide-agent-toolbar'] = value;
        this.saveCartOnStorage();
    }

    updateTotalCharge(setUpdatedCart = true) {
        return;
        if (!setUpdatedCart) {
            this.totalCharge = 0;

            for (let ticketKey in this.cart) {
                let ticketPrice = 0;

                for (let productKey in this.cart[ticketKey]['products']) {
                    let price = this.setItemSalePrice(ticketKey, productKey)

                    // if(this.cartData['action'] && !this.cart[ticketKey]['products'][productKey]['actionChecked'])
                    //     continue;

                    this.totalCharge += price;
                    ticketPrice += price;
                }
                this.cart[ticketKey]['price'] = ticketPrice;
            }

            this.cartData['charge'] = this.totalCharge;
            this.cartData['priceAfterDiscount'] = 0;
            if (this.cartData['discount']) {
                this.cartData['discountPrice'] = (this.totalCharge * this.cartData['discount']) / 100;
                this.totalCharge -= this.cartData['discountPrice'];
                this.cartData['priceAfterDiscount'] = this.totalCharge
                this.order.data.discount = this.cartData['discount'];
            }

            if (!this.order.data.pickup && this.cartData['shippingPrice'])
                this.totalCharge += this.cartData['shippingPrice'];

            if (!this.business.data.GlobalSettingsData.vatIncluded) {
                this.cartData['vat'] = (this.totalCharge * this.business.data.GlobalSettingsData.vat) / 100;
                this.totalCharge += this.cartData['vat'];
            }

            this.totalCharge = this.totalCharge - this.cartData['couponDiscount'];
            if (this.totalCharge < 0) this.totalCharge = 0;

            this.cartData['totalCharge'] = this.totalCharge;

            this.saveCartOnStorage();
        } else {
            this.cartData['cartUpdated'] = false;
            this.getCartCalc();
        }

        this.setItemsCartData();

    }

    setItemsCartData(updateItem = true) {
        let inititems = {}
        // this.cartData['items'] = {};
        let itemsData = this.cartData['items'];

        // for(let k in itemsData) {
        //     //console.logk)
        //     //console.logitemsData)
        //     // itemsData[k]['amount'] = 0;
        // }

        for (let ticketKey in this.cart) {
            if (!inititems[ticketKey]) {
                inititems[ticketKey] = {};
            }
            if (!itemsData[ticketKey]) {
                itemsData[ticketKey] = {};
            }

            for (let k in itemsData[ticketKey]) {
                itemsData[ticketKey][k]['amount'] = 0;
            }

            // //console.log!this.cart[ticketKey]['properties'])

            if (!this.cart[ticketKey]['properties']) this.cart[ticketKey]['properties'] = {};
            for (let k in this.cart[ticketKey]['properties']) {
                this.cart[ticketKey]['properties'][k] = 0;
            }


            for (let k in this.cart[ticketKey]['products']) {
                let cartitem = this.cart[ticketKey]['products'][k]
                let productKey = cartitem['product'].data.id;

                if (inititems[ticketKey][productKey]) {
                    itemsData[ticketKey][productKey]['amount'] += cartitem['amount']
                } else {
                    if (!itemsData[ticketKey][productKey])
                        itemsData[ticketKey][productKey] = {'amount': 0, 'data': {}}

                    itemsData[ticketKey][productKey]['amount'] = cartitem['amount'];
                    inititems[ticketKey][productKey] = true;
                }

                this.addTicketPropeties(this.cart[ticketKey], cartitem['product'], cartitem['amount'])
                // this.events.publish(productKey + '_cartItemChanged')
                this.cartItemChanged(productKey, ticketKey, updateItem);

            }
        }
    }

    setItemSalePrice(ticketKey, productKey) {
        let cartItem = this.cart[ticketKey]['products'][productKey];
        // cartItem['totalPrice'] = cartItem['price'] * cartItem['amount'];
        return cartItem['totalPrice'];
    }

    setItemSalePrice1(ticketKey, productKey) { // not in use.
        let cartitem = this.cart[ticketKey]['products'][productKey];
        let itemData = cartitem['product'];
        let itemprice = itemData.data.price;
        let saledata = itemData.data['sale'];
        let dealdata = cartitem['dealData'];

        // //console.logcartitem['price'])

        let props = cartitem['props'];
        let propsPrice = 0;
        for (let k in props) {
            for (let p in props[k]['checkedData']) {
                if (props[k]['checked'][p])
                    propsPrice += props[k]['checkedData'][p]['price'];
            }
        }

        cartitem['corePrice'] += propsPrice;

        let cartitemPrice = cartitem['corePrice'] * cartitem['amount'];

        if (saledata && !cartitem['agentPrice']) {
            let itemsale = itemData.data['sale']['itemSale'] && this.cart[ticketKey]['products'][itemData.data['sale']['itemSale']] ? this.cart[ticketKey]['products'][itemData.data['sale']['itemSale']] : null;

            saledata['unitsData'] = {};

            let itemSaleAmount = itemsale ? itemsale['amount'] : 0;


            let saleunits = itemSaleAmount ? parseInt((itemSaleAmount / (saledata['amountToBuy'])) + '') : 0;
            let itemsaleunits = saleunits && saleunits > cartitem['amount'] ? cartitem['amount'] : saleunits;

            if (saledata['itemSale']) {
                saledata['unitsData'][saledata['itemSale']] = {
                    'amount': itemsaleunits,
                    'price': saledata['priceAfterDiscount'],
                };
            } else {
                itemprice = saledata['priceAfterDiscount']
            }

            let coreprice = itemprice;
            itemprice = this.getPriceCurrencyCalc(itemData, itemprice);

            // //console.log'dealdata', dealdata);
            // //console.log'itemprice', itemprice);

            saledata['unitsData'][-1] = {'amount': cartitem['amount'] - itemsaleunits, 'price': itemprice};


            //console.logsaledata, saleunits, itemsale);

            cartitemPrice = 0;
            let setTotalFixedPrice = false;
            for (let k in saledata['unitsData']) {
                saledata['unitsData'][k]['total'] = saledata['unitsData'][k]['amount'] * saledata['unitsData'][k]['price'];
                cartitemPrice += saledata['unitsData'][k]['total'];
                if (k != '-1') setTotalFixedPrice = true;
            }

            cartitemPrice += propsPrice

            this.cart[ticketKey]['products'][productKey]['fixedPrice'] = coreprice;

            // //console.logthis.cart[ticketKey]['products'][productKey])
            // //console.logsetTotalFixedPrice)
            // //console.logcartitemPrice)
            if (setTotalFixedPrice)
                this.cart[ticketKey]['products'][productKey]['totalFixedPrice'] = cartitemPrice;
            this.cart[ticketKey]['products'][productKey]['setTotalFixedPrice'] = setTotalFixedPrice;

            // //console.log'totalPRice!')
            // //console.logcartitemPrice)
        } else {
            cartitemPrice = this.getPriceCurrencyCalc(itemData, cartitemPrice);
        }

        if (dealdata) {
            cartitemPrice = dealdata['price'];
            this.cart[ticketKey]['products'][productKey]['free'] = (cartitemPrice == 0);
            this.cart[ticketKey]['products'][productKey]['fixedPrice'] = cartitemPrice;
            this.cart[ticketKey]['products'][productKey]['totalFixedPrice'] = cartitemPrice;
            this.cart[ticketKey]['products'][productKey]['setTotalFixedPrice'] = true;
        }

        // //console.log'cartitemPrice', cartitemPrice);


        this.cart[ticketKey]['products'][productKey]['totalPrice'] = cartitemPrice;
        return cartitemPrice
    }

    saveCartOnStorage() {
        // if(AppConfig.stationMode)
        //     return;

        // if (!this.appuser)
        //     return;

        let data = {'cart': this.cart, 'cartData': this.cartData};
        this.modelTools.setKey('cart_' + AppConfig.bisPrefix, data);
    }

    getCartFromStorage(init = false) {
        //console.log("GET CART FROM STORAGE!!!!");
        // if (!this.appuser) return;
        this.modelTools.getKey('cart_' + AppConfig.bisPrefix).then((res) => {
            this.loadCartFromData(res, init);
        });

        this.modelTools.getKey('cartOptions' + AppConfig.bisPrefix).then((res) => {
            if (!res) res = {};
            this.options = res;
            //console.log(this.options);
        });
    }

    loadCartFromData(res, init) {
        //console.log('load cart:', res)

        if (!res) {
            this.initCart();
            this.cartUpdated(init);
            return;
        }
        // this.cart = res['cart'];
        // this.cartData = res['cartData'];

        this.copyObjElms(this.cart, res['cart'])
        // this.copyObjElms(this.cartData, res['cartData'])

        this.cartData['cartId'] = res['cartId'];
        this.cartData['cartUpdated'] = false;
        this.cartData['cartUpdatedProgress'] = false;

        this.cartUpdated(init);
    }

    copyObjElms(obj, fobj) {
        if (!obj) obj = {};
        for (let k in fobj) {
            obj[k] = fobj[k];
        }

    }

    recheckCart() {
        let cart = JSON.parse(JSON.stringify(this.cart));
        let cartres = [];
        for (let t in cart) {
            for (let p in cart[t]['products']) {
                let cartitem = cart[t]['products'][p]
                // //console.log'cart item', p, cart[t]['products'][p])
                let res = {
                    'key': p,
                    'id': cartitem['product'].data.id,
                    'amount': cartitem['amount'],
                    'props': cartitem['props'],
                    'dealData': cartitem['dealData']
                };
                // cartres.push(res);
            }
        }

        let me = this;

        // setTimeout(function() {
        //     me.clearCart();
        // });

        // setTimeout(function() {
        for (let k in cartres) {
            if (cartres[k]['dealData']) continue;
            let item = me.appDataService.getItemModel(cartres[k]['id']);

            let price = me.appDataService.getItemPrice(item);
            // price += me.getPropsPrice(cartres[k]['props'])
            // me.removeFromCart(null, cartres[k]['key']);

            // //console.logitem, price, cartres[k]['amount'])
            me.addToBasket(null, item, cartres[k]['amount'], null, null, price, null, cartres[k]['key'], false, null, cartres[k]['props'], cartres[k]['dealData'], false);
        }
        // }, 1000)

    }

    getPropsPrice(props) {
        let price = 0;

        for (let p in props) {
            for (let k in props[p]['checked']) {
                if (props[p]['checked'][k]) {
                    // //console.logprops[p]['checkedData'][k], k, props[p]['checkedData'])
                    price += props[p]['checkedData'][k].price;
                }
            }
        }

        return price

    }

    getDefaultAddress() {
        // this.modelProvider.list('shipmentAdress', {'appuser':this.appuser.data.id, 'limit':1, 'disableLoading': true}).then((res)=>
        //         // {
        //         //     if(!res['models'].length) return;
        this.setAddress(new Address({}));
        //         // })

    }

    setAddress(address) {
        this.address = address;
        this.order.data.shipmentAdress = address.data.id;
        this.order.data.address = address.data;

        if (address.data.shippingData) {
            this.cartData['shippingPrice'] = address.data.shippingData['price'];
            this.cartData['minimumPrice'] = address.data.shippingData['minimum'];
            this.updateTotalCharge();
        }

        this.events.publish('orderAddressSets');
    }

    async setCouponPopup(cartItem = null) {
        //console.log(cartItem)
        const popover = await this.popoverCtrl.create({
            component: CouponsComponent,
            componentProps: {
                'cartItem': cartItem
            },
            event: null,
            animated: true,
            showBackdrop: true,
            cssClass: 'center-popover sm-popup'
        });
        return await popover.present();
    }

    setCoupon(coupon, cartItem = null) {
        // if (cartItem) {
        //     //console.log(coupon)
        //     if (!cartItem['data']) cartItem['data'] = {};
        //     cartItem['data']['coupon_code'] = coupon ? coupon.data.code : null;
        //     cartItem['data']['active_coupon'] = coupon;
        //
        //     //console.log(cartItem)
        //
        //     this.cartData['cartUpdated'] = false;
        //     this.getCartCalc();
        //     return
        // }

        // let discount = 0;
        // if (!coupon) {
        //     this.order.data.coupon = null;
        //     discount = 0;
        // } else {
        //     this.order.data.coupon = coupon.data.id;
        //     if (coupon.data.isPercentage) {
        //         discount = this.cartData['charge'] / 100 * coupon.data.discount
        //     } else {
        //         discount = coupon.data.discount
        //     }
        // }

        // this.cartData['coupon'] = coupon;
        // this.cartData['coupons']['checked'][coupon.data.id] = !this.cartData['coupons']['checked'][coupon.data.id];
        this.cartData['coupons']['checkedData'][coupon.data.id] = coupon.data;
        // this.cartData['couponDiscount'] = discount;
        this.getCartCalc();
    }

    async showTicketsPopup() {
        const popover = await this.popoverCtrl.create({
            component: NewCartTicketsComponent,
            componentProps: {},
            event: null,
            animated: true,
            showBackdrop: true,
        });
        return await popover.present();
    }

    addTicketPropeties(ticketRes, item, amount) {
        // if(!ticketRes['propeties']) ticketRes['propeties'] = {};
        let props = item.data.topItemPropertyData;
        // //console.log'addTicketPropeties', props, item);
        for (let k in props) {
            let key = props[k]['itemTypeProperty__name'];
            let value = props[k]['value'];

            // //console.logkey, value);

            if (!ticketRes['properties'][key]) ticketRes['properties'][key] = 0;
            ticketRes['properties'][key] += parseFloat(value) * amount;
        }
        // let key =

        // ticketRes['']
    }

    newTicket(name, ticketId = null) {
        return
        if (!name) return;

        for (let t in this.cart) {
            if (this.cart[t]['name'] == name) {
                this.setActiveTicket(this.cart[t]['ticket']);
                this.cartUpdated(false, {'newTicket': name});
                return
            }
        }

        if (!ticketId) ticketId = this.cartData['tickets'] + 1;
        this.cart[ticketId] = {'products': {}, 'price': 0, 'ticket': ticketId, 'name': name, 'amount': 0};
        this.cartData['tickets']++;

        this.setActiveTicket(ticketId);
        this.cartUpdated(false, {'newTicket': name});
        return ticketId;
    }

    setActiveTicket(ticket = null) {
        if (!ticket) return
        // return
        // if (!ticket) {
        //     for (let k in this.cart) {
        //         ticket = k;
        //     }
        // }

        this.cartData['curTicket'] = ticket;
        // //console.log'ACTIVE TICKET', this.cartData['curTicket'])
        // let me = this;
        // setTimeout(function () {
        //     me.events.publish('activeTicketChanged');
        // })
    }

    setTicketName(id, name) {
        console.log(this.cart)
        this.cart[id]['name'] = name;
        this.cartUpdated();
    }

    removeTicket(id) {
        return
        this.cartData['amount'] -= this.cart[id]['amount'];
        delete this.cart[id];
        this.setActiveTicket();
        this.cartUpdated();
        this.updateTotalCharge();
        this.saveCartOnStorage();
        this.cartData['tickets']--;
    }

    allowAddToCart() {
        let company_settings = this.appDataService.getPricesListKey('company_settings');
        if (!company_settings) return true;
        // this.modelProvider.custom('getUserDefaultCompany', {'setPrefix':true}).then((res)=>{
        if (!company_settings['allow_cart']) {
            if (this.isStaffOrAgent()) {
                this.modelTools.goTo('agent-clients');
            }
            return false;
        }
        if (!this.business.data.GlobalSettingsData.tickets)
            return true;
        if (!this.getActiveTicket()) {
            // this.showTicketsPopup();
            this.newTicket('New Ticket');
            // return false;
        }
        return true;
    }

    getActiveTicket() {
        return this.cartData['curTicket']
    }

    setOrderDetails(model) {
        this.cartData['orderDetails'] = model;
    }

    setOrderAction(action, order = null, action_id = null) {
        let settings = this.appSettings.getSettings('cart');
        this.cartData['action'] = action;
        this.cartData['action_id'] = action_id;

        if (order)
            this.cartData['extorder'] = order.data.id;

        this.order.data.action = action;
        this.order.data.action_id = action_id;


        if (action == 'pickup') {
            this.cartData['actionData'] = {
                'colors': ['yellow', 'black', 'green', 'pink', 'blue', 'red'],
                'color': '',
                'colorsType': '',
                'types': {
                    'runningNo': {
                        'key': 'runningNo',
                        'value': '',
                        'current': 0,
                    },
                    'barcode': {
                        'key': 'barcode',
                        'value': '',
                        'current': 0,
                    }
                },
                'type': '',
            }

            this.modelProvider.list('dealGeneralPropertyType', {
                'key': 'filmcolor',
                'includeProperties': true
            }).then((res) => {
                this.cartData['actionData']['colorsType'] = res['models'][0];
                this.cartData['actionData']['colors'] = res['models'][0].data['properties'];

                this.saveCartOnStorage();
            })
        }

        if (this.cartData['actionData'])
            this.cartData['actionData']['showCartActionOptions'] = settings['showCartActionOptions'];

        this.events.publish('orderActionChanged');
    }

    cancelAction() {
        this.cartData['action'] = null;
        this.order.data.action = this.cartData['action'];

        return

        for (let t in this.cart) {
            for (let p in this.cart[t]['products']) {
                this.cart[t]['products'][p]['amount'] = this.cart[t]['products'][p]['actionAmount'];
                this.cart[t]['products'][p]['actionAmount'] = 0;
            }
        }

        this.setCartStatus();
    }

    setSearchEvent() {
        let key = this.cartData['searchPrefix'] + '_updateFilter';
        this.events.unsubscribe(key);
        this.events.subscribe(key, (params) => {
            this.cartData['searchText'] = params['search'];
            this.searchItems()
        })
    }

    searchItems() {
        return
        let key = this.cartData['searchText'];
        for (let t in this.cart) {
            for (let p in this.cart[t]['products']) {
                let cartItem = this.cart[t]['products'][p];
                let prod = cartItem['product'];
                cartItem.active = prod.data.name.indexOf(key) != -1;

                if (!cartItem['active']) {
                    cartItem.active = this.searchInCartItemProps(cartItem, key);
                }
            }
        }

        this.cartUpdated();
    }

    searchInCartItemProps(cartItem, key) {
        let foundInProps = false;
        for (let t in cartItem['props']) {
            let props = cartItem['props'][t]['checkedData'];
            for (let pr in props) {
                foundInProps = foundInProps || props[pr]['name'].indexOf(key) != -1;
            }

            props = cartItem['props'][t]['checkedValue'];
            for (let pr in props) {
                foundInProps = foundInProps || ('' + props[pr]).indexOf(key) != -1;
            }
        }

        return foundInProps;
    }

    getCartStatus() {
        return this.cartStatus;
    }

    setCartStatus() {
        // if(this.appuser)
        // console.log("SET CART STATUS!", this.appuser.data.companyPriceApproved)

        this.cartStatus['prices'] = true;
        this.cartStatus['value'] = false;
        this.cartStatus['msg'] = null;
        this.cartStatus['url'] = null;
        this.cartStatus['buttonText'] = 'proceedOrder';

        if (this.cartData['action'])
            // this.cartStatus['buttonText'] = 'finish' + this.cartData['action'];
            this.cartStatus['buttonText'] = 'proceed';

        if (!this.business)
            return

        let gb = new GlobalSettings(this.business.data.GlobalSettingsData);
        // //console.log"GB", gb)
        if (!gb.data.openingData['open'] || gb.data.closingForOrders) {
            this.cartStatus['value'] = false;
            return;
        }

        let minimumPrice = gb.data.minimumOrderPrice ? gb.data.minimumOrderPrice : 0;
        if (this.cartData['minimumPrice'])
            minimumPrice = this.cartData['minimumPrice'];
        // //console.log'MINIMUM', gb.data.minimumOrderPrice, this.cartData['totalCharge'], this.cartData['totalCharge'] < gb.data.minimumOrderPrice)
        if (this.cartData['totalCharge'] < minimumPrice) {
            this.cartStatus['msg'] = this.translate.instant('minimumPrice') + ': ' + minimumPrice;
            this.cartStatus['value'] = false;
            return;
        }

        if (!this.appuser) {
            this.cartStatus['value'] = true;
            this.cartStatus['url'] = 'profile';
            this.cartStatus['buttonText'] = 'proceedOrder';
            this.cartStatus['prices'] = gb.data.pricesVisible;
            return;
        } else if (!gb.data.pricesVisible && !this.appuser.data.companyPriceApproved) {
            this.cartStatus['value'] = false;
            this.cartStatus['prices'] = gb.data.pricesVisible;
            this.cartStatus['msg'] = 'companyNotApporvoedYet';
            return;
        }

        // this.appuser.data.companyStatus = 'blockWarning';
        if (!this.appuser.data.validate) {
            this.cartStatus['value'] = false;
            // this.cartStatus['msg'] = 'unableToPlaceOrderAtThisTimePleaseContactYourAgent';
            this.cartStatus['msg'] = 'accountNotValidateYet';
            return
        }

        if (this.appuser.data.companyStatus == 'limited' || this.appuser.data.companyStatus == 'inactive') {
            this.cartStatus['value'] = false;
            // this.cartStatus['msg'] = 'unableToPlaceOrderAtThisTimePleaseContactYourAgent';
            this.cartStatus['msg'] = 'accountBlockWarning';
            return
        }

        if (this.appuser.data.companyStatus == 'blockWarning') {
            this.cartStatus['value'] = true;
            this.cartStatus['msg'] = 'accountBlockWarning';

            // this.cartStatus['msg'] = 'warning:yourAccountBeforeBlocking,PleaseContactYourAgentImmediately';
            return;
        }

        if (!this.appuser.data.approved) {
            this.cartStatus['value'] = false;
            this.cartStatus['msg'] = 'companyNotApporvoedYet';

            // this.cartStatus['msg'] = 'warning:yourAccountBeforeBlocking,PleaseContactYourAgentImmediately';
            return;
        }


        this.cartStatus['value'] = true;

        this.events.publish('cartStatusChanged');
    }

    changeAgentCompany(company = null, companyId = null, force = false, prefix = null, forceChange = false) {
        //console.log("CHANGE COM", company, companyId)
        if (!company) {
            if (this.appuser.data.companyId == companyId && !forceChange) return;
            this.modelProvider.list('company', {'id': companyId}).then((res) => {
                this.changeAgentCompany(res['models'][0], null, force, prefix, forceChange);
            });
            return
        }

        let me = this;
        // this.modelTools.yesOrNoAlert(function () {
        if (me.isCartEmpty() && !force) {
            me.modelTools.yesOrNoAlert(function () {
                me.clearCart();
                me.setAgentCompany(company, forceChange, prefix);
            }, null, me.translate.instant('doYouWantToClearCart'), function () {
                me.setAgentCompany(company, forceChange, prefix);
            });
            return
        }
        // });

        this.setAgentCompany(company, forceChange, prefix);
    }

    setAgentCompanyDetails(company) {
        this.appuser.data.companyId = company.data.id;
        this.appuser.data.companyName = company.data.companyName;
        this.appuser.data.companyFileNumber = company.data.companyFileNumber;
        this.appuser.data.companyStatus = company.data.status;
        this.appuser.data.internalCompanyId = company.data.internalCompanyId;
        this.appuser.data.companyApproved = company.data.approved;
        this.appuser.data.companyPriceApproved = company.data.priceApproved;
        this.appuser.data.company_emails = company.data.email;
        this.appuser.data.companyGroup = company.data.group_id;
        this.company = company;
    }

    setAgentCompany(company, forceChange = false, prefix = null) {
        console.log(this.appuser.data.companyId == company.data.id && !forceChange, forceChange)
        if (this.appuser.data.companyId == company.data.id && !forceChange) return;

        this.setAgentCompanyDetails(company);

        this.modelProvider.loading();

        this.modelProvider.custom('updateAgentCompany', {'company': company.data.id, 'setPrefix': true}).then((res) => {
            let filters = this.getAppUserListFilters();
            this.modelProvider.setStorageListValue('appuser', filters, null); // initial appuser storage list value;

            this.modelProvider.loadingOff();

            this.events.publish('agentCompanyChanged');

            if (prefix)
                this.events.publish(prefix);
        })

        // this.modelProvider.update(this.appuser, {}).then((res) => {
        //     this.events.publish('agentCompanyChanged');
        // })
    }

    isCartEmpty() {
        return this.cartData['amount'] > 0;
    }

    changeAgentCompanyByAppUser(appuser) {
        // //console.log'appusaer', appuser)
        this.appuser.data.companyId = appuser.data.companyId;
        this.appuser.data.companyName = appuser.data.companyName;
        this.appuser.data.companyFileNumber = appuser.data.companyFileNumber;
        this.appuser.data.internalCompanyId = appuser.data.internalCompanyId;
        this.appuser.data.companyApproved = appuser.data.companyApproved;
        this.appuser.data.companyPriceApproved = appuser.data.companyPriceApproved;

        this.getCartCalc();
    }

    updateCartCalc() {
        return
        this.cartData['cartUpdated'] = false;
        this.getCartCalc();
    }

    getCartCalc(init = false) {
        this.cartData['cartUpdated'] = false;
        this.cartService.getCartCalc();

        return;
        if (this.cartData['cartUpdated'])
            return;

        let timer = 2000;
        let me = this;

        // //console.logthis.cartCalcData)
        // if(this.cartData['cartUpdatedProgress']) {
        clearInterval(this.cartCalcData['interval']);
        // }

        this.cartData['cartUpdatedProgress'] = true;

        this.cartCalcData['interval'] = setInterval(function () {
            me.doCartCalc(init);
            clearInterval(me.cartCalcData['interval'])
        }, timer)
    }

    setCartCalcPrefix() {
        let prefix = 'cartcalc_' + Math.floor(Math.random() * Math.floor(1000000));
        this.cartData['cart-calc-prefix'] = prefix;
    }

    doCartCalc(init = false) {
        return
        this.setCartCalcPrefix();
        console.log("NEW CART CALC", this.cartData['cart-calc-prefix'])

        // this.cartData['cartUpdatedProgress'] = true;
        this.modelProvider.customPost('cartcalc', this.cart,
            {
                'cartId': this.cartData['cartId'],
                'action': this.cartData['action'],
                'cart_prefix': this.cartData['cart-calc-prefix']
            }).then((res) => {
            let data = res['data'];

            if (data['cart_prefix'] && data['cart_prefix'] != this.cartData['cart-calc-prefix']) return;
            console.log('CART CALC IS BACK!!!!', data['cart_prefix'], this.cartData['cart-calc-prefix']);
            //console.log("CART CALC PROCEED......");

            this.cartData['cartUpdated'] = true;
            this.cartData['cartUpdatedProgress'] = false;
            this.cartData['units'] = 0;

            let amount = 0;
            for (let t in data) {

                if (t == 'cart_data') {
                    for (let key in data[t]) {
                        this.cartData[key] = data[t][key];
                    }
                    continue
                }

                if (!this.cart[t]) continue;
                this.initTicket(t);

                for (let c in data[t]) {
                    let itemData = data[t][c];
                    let cartItem = this.cart[t]['products'][c];
                    if (!cartItem) continue;
                    cartItem['price'] = itemData['price'];
                    cartItem['final_unit_price'] = itemData['final_unit_price'];
                    cartItem['corePrice'] = itemData['price'];
                    cartItem['coreTotalPrice'] = itemData['coreTotalPrice'];
                    cartItem['totalPrice'] = itemData['totalPrice'];
                    cartItem['fixedPrice'] = itemData['fixedPrice'];
                    cartItem['sale'] = itemData['saledata'];
                    cartItem['stock'] = itemData['stock'];

                    cartItem['disable'] = itemData['disable'];
                    cartItem['disableMsg'] = itemData['disableMsg'];
                    cartItem['features'] = itemData['features'];

                    cartItem['product'].data.stock = itemData['stock'];

                    this.cartData['units'] += cartItem['amount'];

                    amount++;

                }

            }

            this.cartData['amount'] = amount;

            this.updateTotalCharge(false);
            //
            // // //console.logthis.cart)
            //
            this.cartUpdated();

            if (!init)
                this.businessService.checkAppVersion(this.cartData['data_version']);

            this.checkCompanyChangedDetect()
        }, (res) => {
            //console.log"REGECTEDD!!")
        });

    }

    checkCompanyChangedDetect() {
        let company_data = this.cartData['company_data']
        if (!company_data) return;
        if (!this.isAgent()) return;

        let me = this;
        if (company_data['id'] != this.appuser.data.companyId) {
            this.changeAgentCompany(null, company_data['id'], true, null, true)

            this.modelTools.yesOrNoAlert(function () {
                me.modelTools.goTo('agent-clients');
                // me.changeAgentCompany(null, company_data['id'], true, null, true)
            }, {
                'title': this.modelTools.getTranslate('notice'),
                'subTitle': this.modelTools.getTranslate('companyChangedDetected'),
                'message': this.modelTools.getTranslate('clientName') + company_data['name'],

                'yes': this.modelTools.getTranslate('clientsList'),
                // 'no': this.modelTools.getTranslate('stayWithClient')
            }, null, function () {
                // me.changeAgentCompany(null, me.appuser.data.companyId, true, null, true)
            })
        }
    }


    viewStaffSettings(inCart, cartItem, model, modelKey = 'item') {
        let buttons = [];

        if (this.isStaff()) {
            // buttons.push({
            //     text: this.modelTools.getTranslate('delete'),
            //     role: 'destructive',
            //     icon: 'trash',
            //     handler: () => {
            //         this.adminService.removeModels('item', [model.data.id]);
            //     }
            // });
            buttons.push({
                text: this.modelTools.getTranslate('Images'),
                role: 'destructive',
                icon: 'image',
                handler: () => {
                    if (this.isStaff()) {
                        this.adminService.openGenericModelTable('image', model.data.id,
                            modelKey, 'imageUrl');
                        if (event) event.stopPropagation();
                    }
                }
            });

            buttons.push({
                text: this.modelTools.getTranslate('edit'),
                role: 'destructive',
                icon: 'create',
                handler: () => {
                    this.adminService.popupEditBox(null, modelKey, null, 'a1231a',
                        null, 'modelTableEdit', true, null, null, model.data.id);
                }
            });

            // buttons.push({
            //     text: this.modelTools.getTranslate('itemProperties'),
            //     role: 'destructive',
            //     icon: 'wine',
            //     handler: () => {
            //       this.adminService.popupEditBox(null,'itemType', null, 'a1231a',
            //     null, 'modelTableEdit', true, null, null, this.model.data['itemType']);
            //     }
            // });
        }

        if (this.isAgent() && inCart) {
            buttons.push({
                text: this.modelTools.getTranslate('couponDiscount'),
                role: 'destructive',
                icon: 'ticket-outline',
                handler: () => {
                    this.setCouponPopup(cartItem);
                }
            });
        }
        return buttons;
    }

    getCartTickets() {
        let options = [];
        for (let k in this.cart) {
            options.push({
                'data': this.cart[k],
                'ticket': this.cart[k]['ticket'] + '',
                'value': this.cart[k]['name']
            })
        }
        return options
    }

    copy(data) {
        if (!data) return data;
        return JSON.parse(JSON.stringify(data))
    }

    closeSavedCart(cart)
    {
        this.modelProvider.customPost('closeSavedCart', {'id': cart['_id']})
        cart['open'] = false;
    }

    removeCartById(id: string): void {
        // Find the index of the object with the matching '_id'
        const index = this.savedCartList.findIndex(obj => obj['_id'] === id);
        // If the object exists, remove it using splice
        if (index !== -1) {
            this.closeSavedCart(this.savedCartList[index]);
            this.savedCartList.splice(index, 1);
        }
    }

    async checkClientSavedCart() {
        return new Promise(async (resolve, reject) => {
            if (!this.savedCartList || !this.savedCartList.length) {
                reject(); // Reject the promise if no saved cart
                return;
            }

            const popover = await this.popoverCtrl.create({
                component: SavedCartComponent,
                componentProps: {
                    isPopup: true,
                    onClose: resolve, // Resolve the promise when the popover is closed
                },
                event: null,
                animated: true,
                showBackdrop: true,
                cssClass: 'center-popover sm-popup',
            });

            return await popover.present();
        });
    }

}









