import {Component, OnInit} from '@angular/core';
import {NavParams} from "@ionic/angular";
import {ModelProvider} from "../../../../../../../framework/providers/modelProvider/ModelProvider";
import {ActivatedRoute} from "@angular/router";
import {SectionsManagerService} from "../../providers/sectionManagerService";
import {AppConfig} from "../../../../../../conf/main";
import {Events} from "../../../../../../../framework/providers/events";

@Component({
    selector: 'section-items-manager',
    templateUrl: './section-items-manager.page.html',
    styleUrls: ['./section-items-manager.page.scss'],
})
export class SectionItemsManagerPage implements OnInit {

    public model: any;
    public modelId: number;
    public modelName: string;
    public sections: Array<any>;

    public models: Array<any> = [];
    public itemsOptions: object = {};

    // public checkedItems: object;
    // public checkedItemsData: object;
    public itemsData: object;
    public sectionsData: object;
    public transferData: object;
    public readonly: boolean;

    public ready: boolean = false;

    constructor(public route: ActivatedRoute, public modelProvider: ModelProvider, public events: Events, public sectionsManagerService: SectionsManagerService) {
        this.sectionsData = this.sectionsManagerService.getSectionsData();
        this.itemsData = this.sectionsManagerService.getItemsData();
        this.transferData = this.sectionsManagerService.getTransferData();

        this.route.queryParams.subscribe(params => {
            this.modelId = params['modelId'];
            this.modelName = params['modelName'];


            // request the objects params, fix of routing bug.
            let objectsPrefix = params['objectsPrefix'];

            this.ready = false;
            this.events.subscribe(objectsPrefix + '_params', (params) => {
                this.model = params['model'];
                this.sections = this.model['sections'];
                this.readonly = params['readonly'];

                // this.checkedItems = params['checkedItems'];
                // this.checkedItemsData = params['checkedItemsData'];

                this.ready = true;
                // this.events.unsubscribe(objectsPrefix+'_params')
            })
            this.events.publish(objectsPrefix)
        });

    }

    ngOnInit() {
        this.events.subscribe(this.modelId + '_newSection', (res) => {
            let secdata = this.sectionsManagerService.setSectionValuesByRes(res);
            this.sections.push(secdata);
        });

        this.getModel()
    }

    ngOnDestroy()
    {
        this.events.unsubscribe(this.modelId+'_newSection');
    }


    getModel() {
        // this.modelProvider.list(this.modelName, {'id': this.modelId}).then((res) => {
        //     this.model = res['models'][0];
        // })
    }

    close() {
        // this.modalCtrl.dismiss({
        //     'dismissed': true
        // });
    }

    setItems() {
        this.sectionsManagerService.refreshCheckedArr(this.itemsData, true);
    }

    checkAll() {
        for (var k in this.models) {
            this.itemsData['checked'][this.models[k].data.id] = true;
            this.itemsData['checkedData'][this.models[k].data.id] = this.models[k];
        }
        // this.setItems();
    }

    removeAll() {
        for (var k in this.models) {
            this.itemsData['checked'][this.models[k].data.id] = false;
        }
        // this.setItems();
    }

    removeItem(item, index)
    {
        this.modelProvider.custom('removeItemToSection', {'item':item.data.id, 'section':this.modelId, 'pref': AppConfig.bisPrefix}).then((res) => {
            this.models.splice(index, 1);
            this.model['items']--;
        })
    }


}
