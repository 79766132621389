import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {NavController, PopoverController} from "@ionic/angular";
import {TranslateService} from "@ngx-translate/core";
import {ModelProvider} from "../../../providers/modelProvider/ModelProvider";
import {FileUploader} from "ng2-file-upload";
import {ServerService} from "../../../providers/server/server";
import {ModelToImage} from "../../../model/core/general/modelToImage";
import {AppConfig} from "../../../../core/conf/main";
import {Events} from "../../../providers/events";

@Component({
    selector: 'app-image-upload',
    templateUrl: './app-image-upload.component.html',
    styleUrls: ['./app-image-upload.component.scss'],
})
export class AppImageUploadComponent implements OnInit {
    @Input()
    public model: any;

    @Input()
    public src: string;

    @Input()
    public icon: string = 'camera';

    @Input()
    public showIcon: boolean = true;

    @Input()
    public fieldkey: string = 'imagefullurl';

    @Input()
    public datakey: string;

    @Input()
    public background: boolean = false;

    @Input()
    public header: boolean = true;

    @Input()
    public selfModelToImgaeHandle: boolean = false;

    @Input()
    public style: object = {'max-height': '150px'};

    @Input()
    public prefix: string = 'imageUploadPrefix_' + +Math.floor(Math.random() * Math.floor(1000000));

    @Input()
    public disableUpdate: boolean = false;

    @Input()
    public buttonIcon: string;

    @Input()
    public multiple: boolean = false;

    @Input()
    public showAmount: boolean = false;

    @Input()
    public showButton: boolean = true;

    @Input()
    public buttonText: string = '';

    @Input()
    public images: Array<any> = [];

    @Input()
    public buttonClass: string = 'button-b';

    @Input()
    public iconStyle: object;

    @Input()
    public imagesKey:string = 'images';

    @Input()
    public addParams:object = {};

    public uploader: FileUploader;


    public showImage: boolean = true;

    @Input()
    public showGallery: boolean = true;

    public ready: boolean = false;

    @ViewChild('fileInput') fileInput!: ElementRef;

    constructor(public translate: TranslateService, public events: Events, public modelProvider: ModelProvider,
                private server: ServerService,
                public navCtrl: NavController, public popoverCtrl: PopoverController) {
    }

    ngOnInit() {
        this.events.subscribe(this.prefix + '_doneUpload', (res) => {
            // this.getImage({'id': JSON.parse(res)['id']})
        })

        if(!this.images.length && this.model)
            this.images = this.model.data[this.imagesKey];

        // //console.logthis.style)

        this.setUploader();

        // this.events.subscribe(this.prefix + '_doneAdding', (res) => {
        //     this.src = this.model.data.images[0];
        //     this.setShowImage();
        // })

        this.events.subscribe(this.prefix + '_viewUploader', (res) => {
            // console.log(this.fileInput)
            this.fileInput['nativeElement'].click()
        })

        // this.modelProvider.getUserToken().then((token: string) => {
        //     this.authToken = token;
        //     var urlprefix = 'image/?';
        //     this.url = this.server.getEndPoint() + '/' + urlprefix;
        //
        //     this.uploader = new FileUploader({
        //         authToken: this.authToken,
        //
        //         url: this.url + 'objectId=' + this.model.data.id +
        //             '&contentTypeName=' + this.model.prefix +
        //             '&typePrefix=' + this.typePrefix
        //     });
        //
        //     this.uploader.onBeforeUploadItem = function (item) {
        //         //console.log"ADDD")
        //         item.withCredentials = false;
        //     };
        //
        //     this.uploader.onAfterAddingFile = function (fileItem) {
        //         for (var k in fileItem.uploader.queue) {
        //             fileItem.uploader.queue[k].upload();
        //         }
        //         // fileItem.uploader.queue = [];
        //     }
        //
        //     this.uploader.onCompleteAll = () => {
        //         this.uploader.clearQueue();
        //     };
        //
        //     this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
        //         this.events.publish(this.prefix + '_doneUpload', response)
        //         this.events.publish(this.model.prefix + '_doneUpload', response)
        //     };
        // });
    }


    setUploader() {
        this.modelProvider.getUserToken().then((token: string) => {
            let authToken = token;
            var urlprefix = 'image/?';
            let url = this.server.getEndPoint() + '/' + urlprefix;

            if(this.model)
            {
                 url = url + 'objectId=' + this.model.data.id +
                  '&contentTypeName=' + this.model.prefix +
                  '&pref=' + AppConfig.bisPrefix;

                for(let k in this.addParams)
                {
                    url += '&'+k+'='+ this.addParams[k];
                }
                  // '&typePrefix=' + this.typePrefix
            }

            this.uploader = new FileUploader({
                authToken: authToken,
                url: url
            });

            let me = this;
            this.uploader.onBeforeUploadItem = function (item) {
                // //console.logitem)
                // this.change(item);
                me.modelProvider.loading();

                item.withCredentials = false;
            };


            this.uploader.onAfterAddingFile = function (fileItem: any) {
                // //console.logfileItem)


                // if (fileItem.file.size > 2000000) //2MB
                // {
                //   me.dismiss().then( () => {
                //      me.presentAlert('התמונה שבחרת גדולה מידי. אנא בחר תמונה  עד 2MG');
                //   })
                //   // this.bigImage = true;
                //   return;
                // }
                // //console.log"me laegr:",me.istooLarge)

                // if(!me.istooLarge)
                // {
                //   //console.log"laegr:",me.istooLarge)

                //   return;

                // }
                for (var k in fileItem.uploader.queue) {
                    fileItem.uploader.queue[k].upload();
                }
                // fileItem.uploader.queue = [];
                // if(!this.bigImage)
                // me.change(fileItem);


            }


            this.uploader.onCompleteAll = () => {
                // //console.log'onCompleteAll')
                // //console.log"me laegr:",me.istooLarge)
                // if (!me.istooLarge) {
                //     return;
                //
                // }
                this.uploader.clearQueue();
                me.modelProvider.loadingOff();
                this.events.publish(this.prefix + '_doneUpload')

            };

            this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
                // //console.log'HSON')
                // //console.logresponse)
                let image = new ModelToImage(JSON.parse(response));
                this.images.splice(0, 0, image);
                //console.logthis.model)
                //
                // //console.logresponse)

                // //console.log"me laegr:",me.istooLarge)
                // //console.log"me laegr:",this.istooLarge)
                // if (me.istooLarge) {
                //     this.events.publish(this.prefix + '_doneUpload', response)
                //     this.events.publish(this.model.prefix + '_doneUpload', response)
                // }

            };
        });
    }


    // setShowImage()
    // {
    //     this.showImage = false;
    //     var me =this;
    //     setTimeout(function()
    //     {
    //         me.showImage = true;
    //     })
    // }
    //
    // async addImages() {
    //     const popover = await this.popoverCtrl.create({
    //         component: ModelImagesComponent,
    //         componentProps: {
    //             'navCtrl': this.navCtrl,
    //             'model':this.model,
    //             'parts': ['all'],
    //             'prefix':this.prefix,
    //             'filters':{'businessInType':true, 'contentTypeName':this.model.prefix, 'objectId':this.model.data.id},
    //         },
    //         event: null,
    //         animated: true,
    //         showBackdrop: true
    //     });
    //     return await popover.present();
    // }
    //
    // async addImages1() {
    //     const popover = await this.popoverCtrl.create({
    //         component: ModelImagesComponent,
    //         componentProps: {
    //             'navCtrl': this.navCtrl,
    //             'model':this.model,
    //             'parts': ['all'],
    //             'prefix':this.prefix,
    //             'filters':{'businessInType':true, 'contentTypeName':this.model.prefix, 'objectId':this.model.data.id},
    //         },
    //         event: null,
    //         animated: true,
    //         showBackdrop: true
    //     });
    //     return await popover.present();
    // }

    // addImages()
    // {
    //     // let popoverCtrl = this.popoverCtrl.create(ModelImagesComponent, {
    //     //     'navCtrl': this.navCtrl,
    //     //     'model':this.model,
    //     //     'parts': ['all'],
    //     //     'prefix':this.prefix,
    //     //     'filters':{'businessInType':true, 'contentTypeName':this.model.prefix, 'objectId':this.model.data.id},
    //     // }, {'cssClass':'popup-white-shadow'});
    //     // popoverCtrl.present();
    // }

    // uploadImage() {
    //     //console.log"pref: ", this.prefix)
    //     this.events.publish(this.prefix, {});
    // }


    //
    // getImage(filters) {
    //     this.showImage = false;
    //     if(this.selfModelToImgaeHandle) return;
    //     this.modelProvider.custom('getModelToImageByImage', filters).then((res) => {
    //         var mti = new ModelToImage(res)
    //         if(!this.model.data.images) this.model.data.images = [];
    //         this.model.data.images.unshift(mti)
    //
    //         // if(!this.disableUpdate)
    //         //     this.modelProvider.update(this.model);
    //
    //         this.showImage = true;
    //     })
    // }
    //
    // async explan()
    // {
    //     const popover = await this.popoverCtrl.create({
    //         component: SupportModelComponent,
    //         componentProps:{
    //             text: this.translate.instant('clickOnTheImageToChangeIt')
    //         },
    //         backdropDismiss: false
    //
    //     },
    //         );
    //     popover.present();
    //     document.getElementById('dismiss-pop').style.opacity = " 0.2";
    //
    //
    // }
}
