export class DelayedRelay {
    data = {
        "id": null,
    };

    constructor(public data1: any) {
        this.data = data1;
    }

    prefix = "delayedRelay";
    title = "delayedRelay";
    fields = ["doc_no", "balance", "due_date"];
    dataFields = {
        id: {'title': 'id', 'value': null, 'type': 'number', 'readonly': true},
        doc_no: {'title': 'doc_no', 'value': null, 'type': 'text'},
        balance: {'title': 'summary', 'value': null, 'type': 'text'},
        due_date: {'title': 'due_date', 'value': null, 'type': 'datetime'},
    }
}
