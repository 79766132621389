// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-item {
  --highlight-color-focused: var(--text-a);
}

.item-has-focus ion-label {
  color: #000;
}

.text-no-user {
  text-align: center;
  color: #d33939;
}`, "",{"version":3,"sources":["webpack://./src/core/pages/login/pages/forget-password/forget-password.page.scss"],"names":[],"mappings":"AAAA;EAEE,wCAAA;AAAF;;AAGA;EAEE,WAAA;AADF;;AAIA;EACE,kBAAA;EACA,cAAA;AADF","sourcesContent":["ion-item\n{\n  --highlight-color-focused: var(--text-a);\n}\n\n.item-has-focus ion-label {\n  //color: var(--text-a);\n  color:#000;\n}\n\n.text-no-user{\n  text-align: center;\n  color: #d33939;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
