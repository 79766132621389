import {ChangeDetectorRef, Component, Input, OnInit, ViewChild} from '@angular/core';

import {AppConfig} from "../../../../core/conf/main";
import {ResObject} from "../../../../app/global-module/global-module.module";
import {ModelProvider} from "../../../providers/modelProvider/ModelProvider";
import {Form} from "@angular/forms";
import {Events} from "../../../providers/events";

@Component({
  selector: 'valid-form',
  templateUrl: './valid-form.component.html',
  styleUrls: ['./valid-form.component.scss'],
})
export class ValidFormComponent implements OnInit {
    @Input()
    public fields: any;

    @Input()
    public options: object;

    @Input()
    public addParams: object;

    @Input()
    public fieldsOptions: object = {};

    @Input()
    public model: any;

    @Input()
    public allRequired: boolean = false;

    @Input()
    public readonly: boolean = false;

    @Input()
    public addTitleText: string;

    @Input()
    public createButton:boolean = false;

    public prefix = 'validForm_' + +Math.floor(Math.random() * Math.floor(1000000));

    constructor(public events: Events, private changeDetector: ChangeDetectorRef, public modelProvider: ModelProvider) {

    }

    ngOnInit() {
        if (!this.options) this.options = {
            'isSent': false,
            'valid': false,
        }

        if(this.options['prefix'])
            this.prefix = this.options['prefix'];
        else
            this.options['prefix'] = this.prefix;

        this.events.subscribe(this.prefix, (params) => {
            this.options['isSent'] = true;
            this.checkValid();
        })

        // //console.logthis.fieldsOptions)

        this.changeDetector.detectChanges();
    }

    ngOnDestroy() {
    }

    isValidField(key) {
        if (!this.allRequired && !this.model.dataFields[key].required) return true;

        if(this.fieldsOptions[key] && this.fieldsOptions[key]['hidden']) return true;

        // //console.logkey, this.model.data[key], this.model.dataFields[key]['min'])
        if(this.model.dataFields[key]['type'] == 'number' && this.model.dataFields[key]['min']) {
            this.model.data[key + '_invalid'] = this.model.data[key] < this.model.dataFields[key]['min'];
        }

        if(this.model.dataFields[key]['minlength'])
           this.model.data[key + '_invalid'] = (''+this.model.data[key]).length < this.model.dataFields[key]['minlength'];

        return this.model.data[key] != null && this.model.data[key] != '' && !this.model.data[key+'_invalid'];
    }

    checkValid() {
        var valid = true;
        for (var key in this.fields)
            if (!this.isValidField(this.fields[key])) valid = false;
        this.options['valid'] = valid;
    }

    changed() {
        this.events.publish('onTouch');
    }

    setFocus(key) {
        this.events.publish(this.prefix + '_' + key + '_setFocus');
    }

    setTextOption(key, opt)
    {
        if(!this.model.data[key] || !this.model.dataFields[key]['textOptionsMultiple'])
        {
            this.model.data[key] = opt;
            return;
        }

        if(this.indexOf(this.model.data[key],opt) != -1)
        {
            var exists = this.model.data[key].split(',')
            var values = []
            for(var k in exists)
            {
                if(exists[k] != opt)
                    values.push(exists[k])
            }
        }
        else
        {
            let values = this.model.data[key].split(',')
            values.push(opt)

        }

        this.model.data[key] = values.join();
    }

    indexOf(str, key)
    {
        if(!str) return -1
        return str.indexOf(key);
    }

    getDir()
    {
        return AppConfig.dir;
    }

    save()
    {
        if(this.model.data.id)
            this.update();
        else
            this.create();
    }

    update()
    {
        this.events.publish(this.options['prefix']);

        if (!this.options['valid']) {
            return false;
        }

        this.modelProvider.update(this.model, {}, null, true).then((res: ResObject) => {
        });
    }

    create()
    {
        this.events.publish(this.options['prefix']);

        if (!this.options['valid']) {
            return false;
        }

        for(let p in this.addParams)
        {
            this.model.data[p] = this.addParams[p];
        }

        this.modelProvider.create(this.model).then((res: ResObject) => {
            this.model.data = {}; // init model.
        });
    }

}
