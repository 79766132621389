import { Component, OnInit } from '@angular/core';

export class Signature {
    data = {'title': null, 'objectId': null, 'contentTypeName': null, file:null, signatureImage:null, type:null, active:null};

    constructor(public data1:any) {
        this.data = data1;
    }

    frameworkModel = true;
    //shortHeaders = [];
    prefix = "signature";
    title = "Signature";
    defaultHeaders =
        ['created', 'puser', 'type', 'signatureImage', 'file', 'active'];
    disableDetails=true;
    dataFields = {
        id: {'title': 'ID', 'value': null, 'type': 'number', 'readonly': true},
        created: {'title': 'Created', 'value': null, 'type': 'date', 'readonly': true},
        updated: {'title': 'Updated', 'value': null, 'type': 'date', 'readonly': true},
        puser: {
            'value': null,
            'displayField': 'puserName',
            'type': 'modelscroll',
            'modelscroll': 'Puser',
            'keyField': 'id',
            'valueField': 'fname'
        },
        active: {'title': 'Active', 'value': null, 'type': 'boolean'},
        signerName: {'title': 'SignerName', 'value': null, 'type': 'text'},
        type: {'title': 'Type', 'value': null, 'type': 'text', 'fixText':true}, // use FIX filter.
        // signatureImage: {'title': 'SignatureImage', 'value': null, 'type': 'text'},
        signatureImage: { 'showDefault': false, 'selfTemplate': 'signature-image', 'readonly': true },
        contentType: {
            'value': null,
            'displayField': 'contentTypeName',
            'type': 'modelscroll',
            'modelscroll': 'ContentType',
            'keyField': 'id',
            'valueField': 'name'
        },
        objectId: {'title': 'ObjectId', 'value': null, 'type': 'number'},
        contentObject: {'title': 'ContentObject', 'value': null, 'type': 'text'},
        file: { 'showDefault': false, 'selfTemplate': 'signature-file', 'readonly': true },
    }
}
