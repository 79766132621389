import { Component, OnInit } from '@angular/core';
import {Events} from "../../../framework/providers/events";
import {BusinessService} from "../../services/BusinessService";


@Component({
  selector: 'app-sales',
  templateUrl: './sales.page.html',
  styleUrls: ['./sales.page.scss'],
})
export class SalesPage implements OnInit {

  public searchText: string;
    public options: object = {'hide_search':false};

  constructor(public events:Events, public businessService:BusinessService) { }

  ngOnInit() {
  }


    search() {
        // if (!this.searchText || this.searchText == '') return;
        // this.modelProvider.list('miniItem', {'search':this.searchText}).then((res)=>{
        //   this.models = res['models'];
        // })
        this.events.publish(this.options['prefix'] + '_updateItemsFilter', {'search': this.searchText});
    }

}
