export class Branch {
    data = {
        "id": null,
        "name": null,
        "enname": null,
        "homephone": null,
        "comments": null,
        "encomments": null,
        "business": null,
        "shipmentAdress": null,
        "address": null,
    };

    constructor(public data1: any) {
        this.data = data1;
    }

    prefix = "branch";
    title = "TicketMessage";
    dataFields = {
        id: {'title': 'id', 'value': null, 'type': 'number', 'readonly': true},
        name: {'title': 'name', 'value': null, 'type': 'text'},
        enname: {'title': 'name', 'value': null, 'type': 'text'},
        homephone: {'title': 'homephone', 'value': null, 'type': 'number'},
        comments: {'title': 'comments', 'value': null, 'type':'text'},
        encomments: {'title': 'encomments', 'value': null, 'type':'text'},
        address: {'title': 'address', 'value': null, 'type':'text'},
    }
}
