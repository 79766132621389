import {Component, Input, OnInit} from '@angular/core';
import {AppDataService} from "../../../../services/AppDataService";
import {ItemProvider} from "../../../item/providers/item-provider/item-provider.service";

@Component({
  selector: 'app-catalog-model',
  templateUrl: './catalog-model.component.html',
  styleUrls: ['./catalog-model.component.scss'],
})
export class CatalogModelComponent implements OnInit {

  @Input()
  public data:object;

  @Input()
  public filters;

  @Input()
  public appDataKey;

  @Input()
  public step = 0;

  constructor(public appDataService:AppDataService, public itemProvider:ItemProvider) { }

  ngOnInit() {
    if(!this.data)
    {
      this.data = this.initObject();
      this.data['checkedData']['sections'] = true;
    }

    if(!this.appDataKey)
    {
      this.setModelData();
    }
  }

  openModel(model)
  {
      if(model.prefix == 'item')
      {
        this.itemProvider.openItem(model);
        return;
      }

      if(this.data['checked'] == model.data.id)
      {
        this.data['checked'] = null;
        return
      }

      if(!this.data['checkedData'][model.data.id])
          this.data['checkedData'][model.data.id] = this.initObject(model);
      this.data['checked'] = model.data.id;

      // this.data['checkedData'][model.data.id]['model'] = model
      // this.data['checked'][model.data.id] = !this.data['checked'][model.data.id];
      // this.data['checkedData'][model.data.id]['checkedData']['sections'] = true
  }

  setModelData()
  {
    let modelId = this.data['model'].data.id;
    this.data['models'] = this.appDataService.getSectionSubSections(modelId);
    this.data['items'] = this.appDataService.getSectionItems({'sectionId':modelId, 'limit':100})['models'];
  }

  initObject(model=null)
  {
      return {
          'model':model,
          'models': [],
          'checked': null,
          'checkedData': {
            'sections':true
          }
      }
  }

}
