import {Component, Input, OnInit} from '@angular/core';
import {Deal} from '../../../../../framework/model/core/deal/deal';
import {ModelToolsProvider} from '../../../../../framework/providers/model-tools/model-tools'

@Component({
  selector: 'deals-slider',
  templateUrl: './deals-slider.component.html',
  styleUrls: ['./deals-slider.component.scss'],
})
export class DealsSliderComponent implements OnInit {

  public models:Array<Deal> = [];

  @Input()
  public filters:object = {'allimages':true};

  @Input()
  public options:object = {'slidesPerView':2};

  constructor(public modelToolsProvider:ModelToolsProvider) { }

  ngOnInit() {}

  goTo(page, params) {
    this.modelToolsProvider.goTo(page, params);
  }

}
