// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-box {
  margin-bottom: 5px;
}

.image-box .image-row {
  background: #ac1f25;
  color: #fff;
  margin: 5px;
  padding: 5px;
  opacity: 0.8;
}

.image-box .body {
  background: #fff;
  padding: 5px;
  color: #000;
}

.new-item-edit {
  box-shadow: 0px 1px 0px 0px #fff6af;
  background: linear-gradient(to bottom, #ffec64 5%, #ffab23 100%);
  background-color: #ffec64;
  border-radius: 6px;
  border: 1px solid #ffaa22;
  display: inline-block;
  cursor: pointer;
  color: #333333;
  font-size: 15px;
  font-weight: bold;
  text-decoration: none;
  text-shadow: 0px 1px 0px #ffee66;
  margin: 2px;
}`, "",{"version":3,"sources":["webpack://./src/framework/components/general/app-image-upload/app-image-upload.component.scss"],"names":[],"mappings":"AAAA;EAEE,kBAAA;AAAF;;AAIA;EACE,mBAAA;EACA,WAAA;EAEA,WAAA;EACA,YAAA;EAEA,YAAA;AAHF;;AAMA;EACE,gBAAA;EACA,YAAA;EACA,WAAA;AAHF;;AAMA;EACE,mCAAA;EACD,gEAAA;EACA,yBAAA;EACA,kBAAA;EACA,yBAAA;EACA,qBAAA;EACA,eAAA;EACA,cAAA;EACA,eAAA;EACA,iBAAA;EACA,qBAAA;EACC,gCAAA;EACA,WAAA;AAHF","sourcesContent":[".image-box {\n  //border: 1px solid #ac1f25;\n  margin-bottom: 5px;\n\n}\n\n.image-box .image-row {\n  background: #ac1f25;\n  color: #fff;\n  // padding: 10px;\n  margin: 5px;\n  padding: 5px;\n  //background-image:url('../../../assets/dashboard/imagesss.jpg');\n  opacity: 0.8;\n}\n\n.image-box .body {\n  background: #fff;\n  padding: 5px;\n  color: #000;\n}\n\n.new-item-edit{\n  box-shadow: 0px 1px 0px 0px #fff6af;\n\tbackground:linear-gradient(to bottom, #ffec64 5%, #ffab23 100%);\n\tbackground-color:#ffec64;\n\tborder-radius:6px;\n\tborder:1px solid #ffaa22;\n\tdisplay:inline-block;\n\tcursor:pointer;\n\tcolor:#333333;\n\tfont-size:15px;\n\tfont-weight:bold;\n\ttext-decoration:none;\n  text-shadow:0px 1px 0px #ffee66;\n  margin: 2px;\n\t\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
