// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-item.no-padding {
  --padding-end: 0px;
  --padding-start: 0px;
  --inner-padding-end: 0px;
  padding: 0;
}

ion-list.list-ios {
  margin: 0;
}

.article {
  margin: 5px 0;
}
.article ion-avatar {
  width: 160px;
  vertical-align: top;
  height: 100%;
  padding: 5px;
  margin: 0;
}
.article ion-label {
  white-space: pre-line;
  padding: 5px;
  height: 100%;
}
.article ion-label div {
  font-size: 18px;
}`, "",{"version":3,"sources":["webpack://./src/core/components/feed/components/feed-post/feed-post.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,oBAAA;EACA,wBAAA;EACA,UAAA;AACF;;AAEA;EAEE,SAAA;AAAF;;AAGA;EAEE,aAAA;AADF;AAGE;EAEE,YAAA;EACA,mBAAA;EACA,YAAA;EACA,YAAA;EACA,SAAA;AAFJ;AAKE;EAEE,qBAAA;EACA,YAAA;EACA,YAAA;AAJJ;AAMI;EACE,eAAA;AAJN","sourcesContent":["ion-item.no-padding {\n  --padding-end: 0px;\n  --padding-start: 0px;\n  --inner-padding-end: 0px;\n  padding: 0;\n}\n\nion-list.list-ios\n{\n  margin: 0;\n}\n\n.article\n{\n  margin: 5px 0;\n\n  ion-avatar\n  {\n    width: 160px;\n    vertical-align: top;\n    height: 100%;\n    padding: 5px;\n    margin: 0;\n  }\n\n  ion-label\n  {\n    white-space: pre-line;\n    padding: 5px;\n    height: 100%;\n\n    div{\n      font-size: 18px;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
