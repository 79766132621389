import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {PopoverController, ToastController} from "@ionic/angular";
import {AdminService} from "../../../../../core/services/AdminService";
import {SelectBarComponent} from "../../select-bar/select-bar.component";
import {Events} from "../../../../../framework/providers/events";
import {ModelProvider} from "../../../../../framework/providers/modelProvider/ModelProvider";


@Component({
    selector: 'app-admin-filter',
    templateUrl: './admin-filter.component.html',
    styleUrls: ['./admin-filter.component.scss'],
})
export class AdminFilterComponent implements OnInit, OnDestroy {
    @Input()
    exTableData;
    @Input()
    sectionName: string = 'appFilter';
    @Input()
    model;
    @Input()
    topPrefix: string;
    @Input()
    prefix: string;

    @Input()
    type: string = 'regular';

    tableInfoData;
    fieldsType;
    contentTypeModel;
    contentTypeModelName;
    modelFieldsType;
    saveInterval;

    //filters select table option data from server
    advancedFilters;

    //selectTable templte/field choose
    selectTableType = 'field';
    templateModel;

    //current field to be choose / copy
    fields;

    selectTableModel = {'main': {}};

    currentField;

    callbackPrefix = 'asddd' + +Math.floor(Math.random() * Math.floor(1000000));

    opTypes = {
        'float': ['Greater than', 'Lower than', 'Equal', 'Not Equal'],
        'number': ['Greater than', 'Lower than', 'Equal', 'Not Equal'],
        'text': ['Equal', 'Contain', 'Not Contain'],
        'textarea': ['Equal', 'Contain', 'Not Contain'],
        'boolean': ['True', 'False'],
        'selectTable': ['In', 'Not in', 'Minimum count', 'Maximum count'],

        'time': ['is', 'is not', 'in the next', 'in the last', 'is between', 'this hour', 'today'],

        'datetime': ['is', 'is not', 'in the next', 'in the last', 'is between', 'is on or after', 'is on or before', 'is empty', 'is set', 'Today', 'Yesterday', 'This week(Sun-Today)', 'Last 7 days', 'Last week(Sun-Sat)', 'Last 14 days', 'This month', 'Last 30 days', 'Last month', 'All time'],
        'date': ['is', 'is not', 'in the next', 'in the last', 'is between', 'is on or after', 'is on or before', 'is empty', 'is set', 'Today', 'Yesterday', 'This week(Sun-Today)', 'Last 7 days', 'Last week(Sun-Sat)', 'Last 14 days', 'This month', 'Last 30 days', 'Last month', 'All time'],

        'groupByNumber': ['Sum', 'Avg', 'Count', 'No'],
    };

    // models by field type
    types = {
        'date': [
            {'type': 'field', 'val': 'created', 'title': 'Field name'},

            {'type': 'dateOptions', 'val': 'choose', 'title': 'Set Type'},

            {'type': 'timeOptions', 'val': 'days', 'title': 'Range Type', 'condition': [1, 'choose']},

            {'type': 'dateInput', 'val': '5', 'title': 'From', 'condition': [1, 'range']},
            {'type': 'dateInput', 'val': '5', 'title': 'To', 'condition': [1, 'range']},

            {'type': 'number', 'val': 'Greater than', 'title': 'Compare', 'condition': [1, 'custom']},
            {'type': 'input', 'val': '5', 'title': 'Range', 'condition': [1, 'custom']},
            {'type': 'times', 'val': 'days', 'title': 'Range Type', 'condition': [1, 'custom']},
            {'type': 'dateOp', 'val': 'ago', 'title': 'Before/After', 'condition': [1, 'custom']}
        ],
        'number': [{'type': 'field', 'val': 'created', 'title': 'field name'},
            {'type': 'number', 'val': 'Greater than', 'title': 'field name'}, {
                'type': 'input',
                'val': 10
            }, {'type': 'groupByNumber', 'val': 'No', 'title': 'groupByNumber'}],
        'text': [{'type': 'field', 'val': 'created'},
            {'type': 'text', 'val': 'Equal'},
            {'type': 'input', 'val': ''}],
        'boolean': [{'type': 'field', 'val': 'created'}, {'type': 'boolean', 'val': true}
        ],
        'selectTable': [{'type': 'field', 'val': 'agent'},

            {'type': 'selectTableOptions', 'val': 'choose', 'title': 'Select option'},

            {'type': 'select', 'val': 'In'},
            {'type': 'selectTable', 'multiple': true, 'condition': [1, 'choose']},
            {'type': 'selectFilter', 'condition': [1, 'filter']},

            {'type': 'boolean', 'val': true, 'title': 'Group By'}],
        'table': [
            {'type': 'field', 'val': 'orderItems'},
            {'type': 'select', 'val': 'in'},
            {'type': 'selectFilter'}
        ]
    };

    filters = [];

    constructor(private adminService: AdminService,
                private toastCtrl: ToastController,
                private popoverCtrl: PopoverController,
                private modelProvider: ModelProvider,
                private events: Events) {
    }

    ngOnInit() {
        // this.fieldsType = this.adminService.getFieldsTypeByLower('appFilterField', this.exTableData);
        this.fieldsType = this.exTableData['appFilterFieldRef'];

        this.contentTypeModelName = this.model['main']['contentTypeModelName'];
        this.contentTypeModel = this.adminService.getNewModel(this.contentTypeModelName, this.exTableData);
        this.modelFieldsType = this.exTableData[this.contentTypeModelName + 'Ref'];


        //console.log"this.fieldsType:");
        //console.logthis.fieldsType);
        // this.model['main']['contentTypeModel']
        // this.fields = this.adminService.getModelByLower( + 'appFilterField');
        //console.logthis.fields);
        this.tableInfoData = this.exTableData[this.sectionName + 'Data'];
        // this.adminService.getModelByLower(this.model['main']['contentTypeModel'] + 'data');

        // if (this.fieldData['value']) {
        //     this.filters = JSON.parse(this.fieldData['value']);
        // }

        // for (let fieldType of this.fields) {
        //     this.selectTableModel[fieldType['name']] = {
        //         'value': null,
        //         'multiple': true,
        //     }
        // }

        if (this.type == 'advanced') {
            this.events.publish('showGlobalLoading');
            // this.events.publish('refresh_request_' + this.prefix, filters);
            let me = this;

            let filters = this.adminService.getMainFilters();
            filters['getModelSelectTableOptions'] = this.model['main']['contentTypeModelName'];

            this.modelProvider.list('report', filters)
                .then(function (data: any) {
                    me.advancedFilters = data['models'][0].data['finalResReport']['advancedFilters'];
                });
        } else {

            if (this.model['fieldName']['value']) {
                this.currentField = this.adminService.getFieldsType(this.contentTypeModelName, this.exTableData)[this.model['fieldName']['value']];
                this.contentTypeModel[this.model['fieldName']['value']] = '';
            }

            //listen to chose field popup
            this.events.subscribe(this.callbackPrefix, (res) => {
                console.log(res);

                this.model['fieldName']['value'] = res['name'];
                this.model['fieldType']['value'] = res['type'];

                this.currentField = this.adminService.getFieldsType(this.contentTypeModelName, this.exTableData)[this.model['fieldName']['value']];

                if (this.model['fieldType']['value'] == 'selectTable') {
                    this.currentField['multiple'] = true;
                }
            });
        }
    }

    ngOnDestroy() {
        this.events.unsubscribe(this.callbackPrefix);
    }

    run() {
        this.buttonClick('addFilter', {'quickFilter': this.model['id']['value']});
    }

    buttonClick(key, data = {}) {
        this.events.publish("btnclick_" + this.prefix, {'key': key, 'data': data});
    }

    save() {
        //console.log'save');
        //console.logthis.filters);

        // this.fieldData['fieldSaveWait'] = true;
        // this.fieldData['validate'] = true;
        // this.fieldData['value'] = JSON.stringify(this.filters);
        //
        // let me = this;
        // me.adminService.saveField(me.sectionName, me.model, me.fieldData, me.fieldType);
        // me.fieldData['editMode'] = false;
    }

    addField(fieldType, sectionName = null, topField = null) {
        this.currentField = fieldType;

        //console.logfieldType);

        let type = fieldType.type;
        if (fieldType.type == 'float') {
            type = 'number'
        }
        if (fieldType.type == 'date' || fieldType.type == 'time' || fieldType.type == 'datetime') {
            type = 'date';
        }
        //console.logtype);

        let newModel = JSON.parse(JSON.stringify(this.types[type]));
        newModel[0]['value'] = 'temp!';
        newModel[0]['checked'] = true;
        newModel[0]['fieldName'] = fieldType.name;
        newModel[0]['topField'] = topField ? topField['name'] : null;
        newModel[0]['sectionName'] = sectionName ? sectionName : this.model['main']['contentTypeModel'];
        this.filters.push(newModel);

        //console.logthis.filters);

        // return
        //
        // //this types require more data.
        // if (['table', 'selectTable'].indexOf(this.currentField.type) != -1 && extraData.length == 0) {
        //     return;
        // }
        //
        // let val;
        //
        // // if (fieldType['type'] == 'table') {
        // //     val = "{" + fieldType['name'];// + "|template|filter|sort|num";
        // // } else if (fieldType['type'] == 'selectTable') {
        // //     val = "{" + fieldType['name']; //|type|fieldName // type-template/field
        // // } else {
        // val = fieldType['name'];
        // // }
        //
        // //console.logextraData);
        //
        // for (let key of extraData) {
        //     val += '/' + key;
        // }
        // val = "[{" + val + "}]";
        //
        // this.copyToCliporad(val);
    }

    //depricated
    async copyToCliporad(val) {

        // this.fieldData.value += val;
        this.save();
        return;

        let selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = val;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);

        //     showCloseButton: true,
        //     closeButtonText: 'OK',
        let toast = await this.toastCtrl.create({
            message: val + " - Copied to clipboard.",
            duration: 1000,
            position: 'bottom',
        });
        toast.present();


        // toast.present();
    }

    //select table
    //select table template
    async presentSelectBar(myEvent, model, field) {
        // this.fieldData['modelId'] = 1;
        // this.fieldData['mainDisplayName'] = 'Template';

        let sectionName = 'chooseTemplate';

        this.exTableData[sectionName + 'Ref'] = [
            {'type': 'number', 'name': 'id'},
            {'type': 'selectTable', 'name': 'filter', 'model': 'appFilter'}
        ];
        this.exTableData[sectionName + 'Data'] = {'title': sectionName};

        //to global
        this.adminService.manageModels['chooseTemplateData'] = this.exTableData[sectionName + 'Data'];
        this.adminService.manageModels[sectionName + 'Ref'] = this.exTableData[sectionName + 'Ref'];

        this.templateModel = {'filter': {'value': null}};
        this.templateModel['filter']['baseFilter'] = {
            'searchFieldValue': this.model['main']['contentTypeModel'],
            'searchField': 'contentType__model',
            'search_in_field': true
        };

        const popover = await this.popoverCtrl.create({
            component: SelectBarComponent,
            componentProps: {
                'model': this.templateModel, // if search by id so dont send model - it will query it from server
                'prefix': "Aaa",
                'avoidSave': true,
                'fieldData': field,
                'fieldType': {'type': 'selectTable', 'model': model},
                'exTableData': this.exTableData,
                'sectionName': sectionName,
                'type': 'selectTable'
            },
            event: myEvent,
            animated: true,
            showBackdrop: true,
            cssClass: 'note-popover icenter-popover smaller-popover'
        });

        //right-popup
        await popover.present();
    }

    //depricated
    addChooseField(sectionName) {
        let res = [];
        //console.logthis.selectTableModel[this.currentField['name']]['multiChooseArr']);
        for (let item of this.selectTableModel[this.currentField['name']]['multiChooseArr']) {
            //console.logitem);
            res.push(item['value']);
        }
        //['choose', res.join(",")],
        // this.addField(this.currentField, sectionName);
    }

    removeFilter(i) {
        this.filters.splice(i, 1);
    }

    chooseModelField(id) {
        this.adminService.showModal({
            'type': 'chooseModelField',
            'callbackPrefix': this.callbackPrefix,
            'tableOptions': {'model': this.modelFieldsType}
        });
    }

    onTableScroll(event, prefix) {
        if (event.target.scrollHeight - 300 < event.target.scrollTop) {
            this.events.publish('nextPage' + prefix, {});
        }
    }

    runFilter() {
        let filters = {};
        console.log(this.advancedFilters)

        for (let advk in this.advancedFilters) {
            let adv = this.advancedFilters[advk];

            console.log(adv);

            for (let key in adv['data']) {
                if (adv['data'][key]['main']['isMarked']) {
                    if (!filters[adv['name']]) filters[adv['name']] = [];
                    filters[adv['name']].push(adv['data'][key]['id']['value']);
                }
            }
        }

        let me = this;
        this.model['filter']['value'] = JSON.stringify(filters);
        this.adminService.saveField('appFilter', this.model, this.model['filter'], {
            'name': 'filter',
            'type': 'textarea'
        }).then(res => {
            me.events.publish('btnclick_' + me.topPrefix, {
                key: 'addFilter',
                data: {'filterId': me.model['id']['value']}
            })
        });

    }
}

