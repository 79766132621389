// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-card {
  margin: 0 5px;
}

.section-header {
  padding: 10px;
}
.section-header .title {
  font-size: 30px;
  font-weight: bold;
}

.desc-box {
  font-size: 15px;
  max-height: 70px;
}

.details, .details div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/core/pages/section/components/section-tree/section-tree.component.scss"],"names":[],"mappings":"AAKA;EACE,aAAA;AAJF;;AAgBA;EAEE,aAAA;AAdF;AAgBE;EACE,eAAA;EACA,iBAAA;AAdJ;;AAkBA;EACE,eAAA;EACA,gBAAA;AAfF;;AAkBA;EAEE,aAAA;EACA,8BAAA;EACA,mBAAA;AAhBF","sourcesContent":["//.relative .name-box {\n//  position: initial;\n//  margin-top: 10px;\n//}\n\nion-card {\n  margin: 0 5px;\n}\n\n//.section-box {\n//  margin-top: 5px;\n//  margin-bottom: 10px;\n//}\n//\n//.section-box ion-card {\n//  margin-bottom: 15px;\n//}\n\n.section-header\n{\n  padding: 10px;\n\n  .title{\n    font-size: 30px;\n    font-weight: bold;\n  }\n}\n\n.desc-box {\n  font-size: 15px;\n  max-height: 70px;\n}\n\n.details, .details div\n{\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
