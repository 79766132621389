import {Component, Input, OnInit} from '@angular/core';
import {ResObject} from "../../../../../app/global-module/global-module.module";
import {ModelProvider} from "../../../../../framework/providers/modelProvider/ModelProvider";
import {TranslateService} from "@ngx-translate/core";
import {OrderService} from "../../../../services/OrderService";
import {PopoverController} from "@ionic/angular";
import {Coupon} from "../../../../../framework/model/core/coupon/coupon";
import {CartService} from "../../../../services/CartService";
import {Events} from "../../../../../framework/providers/events";
import {ModelToolsProvider} from "../../../../../framework/providers/model-tools/model-tools";

@Component({
    selector: 'coupons',
    templateUrl: './coupons.component.html',
    styleUrls: ['./coupons.component.scss'],
})
export class CouponsComponent implements OnInit {

    @Input()
    public cartItem;

    public activeCoupon;

    public couponText: string;
    public cartData: object;

    public loading: boolean;

    public couponCode:string;

    public mode = 'by-code';
    public models: Array<Coupon> = [];

    public prefix= 'co_'+this.tools.getRandomize();

    constructor(public modelProvider: ModelProvider, public translate: TranslateService, public orderService: OrderService, public cartService:CartService,
                public popoverctrl: PopoverController, public events:Events, public tools:ModelToolsProvider) {
    }

    ngOnInit() {
        this.cartData = this.cartService.getCartData();
        console.log(this.cartData)

        //
        // if (this.cartItem) {
        //     this.activeCoupon = this.cartItem['data']['coupon_code'];
        // }
        //
        // console.log(this.orderService.isAgent())
        // if (this.orderService.isAgent()) {
        //     this.mode = 'by-list';
        //     this.getModels();
        // }

    }

    getModels() {
        this.modelProvider.list('coupon', {'disableLoading': true}).then((res: ResObject) => {
            this.models = res['models'];
        })
    }


    findCoupon(text) {
        if (!text || text == '') return;
        let coupon;
        this.loading = true;
        this.modelProvider.list('coupon', {'code': text, 'disableLoading': true}).then((res: ResObject) => {
            this.loading = false;

            if (!res['models'].length) {
                coupon = null;
                this.modelProvider.presentToast(this.translate.instant('notValidCoupon'))
            } else {
                coupon = res['models'][0]
            }

            this.setCoupon(coupon)
        })
    }

    setCoupon(coupon) {
        this.orderService.setCoupon(coupon);
        // this.activeCoupon = coupon ? coupon.data.code : null;
    }

    createClientCoupon(couponCode)
    {
        this.modelProvider.customPost('createCompanyCoupon', {'code':couponCode, 'setPrefix':true}).then((res)=>{
            console.log(res);
            this.events.publish(this.prefix+'_updateModels');
            if(!res['res'])
            {
                this.tools.presentToast('noCouponFound', true, 'dark', null, true);
            }
        })
    }


    close() {
        this.popoverctrl.dismiss();
    }

}
