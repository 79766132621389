import {Component, EventEmitter, Input, Output} from '@angular/core';
import {OrderService} from "../../../../services/OrderService";
import {CartService} from "../../../../services/CartService";
import {ModelToolsProvider} from "../../../../../framework/providers/model-tools/model-tools";
import {PopoverController} from "@ionic/angular";
import {ModelProvider} from "../../../../../framework/providers/modelProvider/ModelProvider";
import {Events} from "../../../../../framework/providers/events";

@Component({
    selector: 'app-saved-cart',
    templateUrl: './saved-cart.component.html',
    styleUrls: ['./saved-cart.component.scss']
})
export class SavedCartComponent {

    @Input()
    public onClose: any;

    public savedCarts: any;

    @Input()
    public isPopup: boolean;

    public prefix: string = 'savedCart_' + Math.floor((Math.random() * 1000) + 1);

    constructor(public orderService: OrderService,
                public cartService: CartService,
                public events: Events,
                public tools: ModelToolsProvider,
                public modelProvider: ModelProvider,
                public popoberCtrl: PopoverController) {
    }

    ngOnInit() {
        this.events.subscribe('appPriceSets', (res) => {
            this.initData();
        }, this.prefix);

        this.initData();
    }

    ngOnDestroy()
    {
        this.events.unsubscribe('appPriceSets', this.prefix);
    }

    initData() {
        this.savedCarts = this.orderService.savedCartList;
    }

    setCart(cartData) {
        this.cartService.clearCart();
        this.cartService.updateData(cartData['data']);

        this.close()

        // this.orderService.initCart();
        // this.orderService.loadCartFromData({
        //     'cartId': cartData['cartId'],
        //     'cart': cartData['cart'],
        //     'cartData': this.orderService.cartData
        // }, false);
        // this.orderService.doCartCalc();
    }

    removeCart(cart) {
        let me = this;
        this.tools.yesOrNoAlert(function () {
            me.orderService.removeCartById(cart['_id'])
        }, {'title': this.tools.getTranslate('removeSavedCart'), 'message': this.tools.getTranslate('areYouSure')});
    }

    close() {
        if (!this.isPopup) return
        this.onClose();
        this.popoberCtrl.dismiss();
    }
}
