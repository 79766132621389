// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.buttonHover:hover:not(.disable-hover) {
  background-color: #a4a5a4;
  cursor: pointer;
}

.empty-box {
  border: 1px solid #c8c8c8;
  border-radius: 5px;
  padding-left: 2px 4px;
  display: inline-block;
  width: 50px;
}

.empty-title {
  font-size: 13px;
  color: #c8c8c8;
}

.danger {
  color: var(--ion-color-danger);
  font-weight: 600;
}`, "",{"version":3,"sources":["webpack://./src/framework/components/field/displayfield/displayfield.component.scss"],"names":[],"mappings":"AAKA;EACE,yBAAA;EACA,eAAA;AAJF;;AAcA;EACE,yBAAA;EACA,kBAAA;EACA,qBAAA;EACA,qBAAA;EACA,WAAA;AAXF;;AAcA;EACE,eAAA;EACA,cAAA;AAXF;;AAcA;EACE,8BAAA;EACA,gBAAA;AAXF","sourcesContent":[".buttonHover {\n  // padding:5px;\n\n}\n\n.buttonHover:hover:not(.disable-hover) {\n  background-color: #a4a5a4;\n  cursor: pointer;\n}\n\n.field {\n  // padding: 3px;\n  // display: block;\n  // min-width: 20px;\n  // height: 30px;\n}\n\n.empty-box {\n  border: 1px solid #c8c8c8;\n  border-radius: 5px;\n  padding-left: 2px 4px;\n  display: inline-block;\n  width: 50px;\n}\n\n.empty-title {\n  font-size: 13px;\n  color: #c8c8c8;\n}\n\n.danger {\n  color: var(--ion-color-danger);\n  font-weight: 600;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
