// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.noStyle {
  padding: 0px;
  height: 100%;
}

.horiz-panel.name-box {
  display: table;
}
.horiz-panel.name-box div {
  text-align: start;
  vertical-align: middle;
  display: table-cell;
}

.search-box {
  border: 1px solid #ccc;
  color: #fff;
}
.search-box .name-box {
  vertical-align: middle;
}

.topsec-slider img {
  width: auto;
  max-height: 150px;
}

.panel-padding {
  padding: 5px;
}

.panel-col {
  height: max-content;
  overflow: hidden;
}

.panel-col.hidden {
  display: none;
}

.element-box {
  position: relative;
}
.element-box .actions {
  position: absolute;
  display: none;
  z-index: 150;
  height: 0px;
  background: white;
  border-radius: 5px;
  border: 1px solid #cacaca;
  padding: 0 5px;
}

.element-box:hover .actions {
  display: inherit;
}`, "",{"version":3,"sources":["webpack://./src/core/components/panels/app-panel/app-panel.component.scss"],"names":[],"mappings":"AAKA;EACE,YAAA;EACA,YAAA;AAJF;;AAQA;EACI,cAAA;AALJ;AAOI;EACE,iBAAA;EACA,sBAAA;EACA,mBAAA;AALN;;AASA;EACE,sBAAA;EACA,WAAA;AANF;AAQE;EAEE,sBAAA;AAPJ;;AAeE;EACE,WAAA;EACA,iBAAA;AAZJ;;AAgBA;EAEE,YAAA;AAdF;;AAiBA;EAEE,mBAAA;EACA,gBAAA;AAfF;;AAiBA;EAEE,aAAA;AAfF;;AAkBA;EACE,kBAAA;AAfF;AAgBE;EACE,kBAAA;EACA,aAAA;EACA,YAAA;EACA,WAAA;EAEA,iBAAA;EACA,kBAAA;EACA,yBAAA;EACA,cAAA;AAfJ;;AAmBE;EACE,gBAAA;AAhBJ","sourcesContent":[".panel-grid\n{\n\n}\n\n.noStyle {\n  padding: 0px;\n  height: 100%;\n\n}\n\n.horiz-panel.name-box {\n    display: table;\n\n    div{\n      text-align: start;\n      vertical-align: middle;\n      display: table-cell;\n    }\n}\n\n.search-box {\n  border: 1px solid #ccc;\n  color: #fff;\n\n  .name-box\n  {\n    vertical-align: middle;\n  }\n}\n\n.topsec-slider\n{\n  //max-height: 150px;\n\n  img{\n    width: auto;\n    max-height: 150px;\n  }\n}\n\n.panel-padding\n{\n  padding: 5px;\n}\n\n.panel-col\n{\n  height: max-content;\n  overflow: hidden;\n}\n.panel-col.hidden\n{\n  display: none;\n}\n\n.element-box{\n  position: relative;\n  .actions{\n    position: absolute;\n    display: none;\n    z-index: 150;\n    height: 0px;\n\n    background: white;\n    border-radius: 5px;\n    border:1px solid #cacaca;\n    padding: 0 5px;\n  }\n}\n.element-box:hover{\n  .actions{\n    display: inherit;\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
