import {Component, OnInit} from '@angular/core';
import {OrderService} from "../../services/OrderService";
import {Events} from "../../../framework/providers/events";
import {AppDataService} from "../../services/AppDataService";

@Component({
    selector: 'app-backtostock',
    templateUrl: './backtostock.page.html',
    styleUrls: ['./backtostock.page.scss'],
})
export class BacktostockPage implements OnInit {
    public itemsarr;

    constructor(public appdataService:AppDataService) {
        this.itemsarr = this.appdataService.getDataByKey('backtostock');
        console.log(this.itemsarr)
    }


    ngOnInit() {
        let date = new Date();
        let month = date.getUTCMonth()
        if (month == 0) month = 12;
        let sdate = date.getFullYear() + '/' + month + '/' + date.getUTCDate();
        //console.logsdate)
    }

}
