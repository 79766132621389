// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fields-box {
  line-height: 1.5;
  padding: 0 5px;
}
.fields-box p {
  margin: 0;
}

.title-text {
  font-size: 14px;
}

.syl-box {
  padding: 0 8px;
}

.company-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 5px;
}
.company-list .list-field {
  gap: 3px;
  flex: 1 1 0;
}

.model-item ion-label {
  background: #fff;
  cursor: pointer;
  font-size: 14px;
  line-height: 1.5;
}
.model-item ion-label:hover, .model-item ion-label:active {
  filter: brightness(95%);
}`, "",{"version":3,"sources":["webpack://./src/core/pages/agent/agent-companies/pages/agent-companies/agent-companies.page.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,cAAA;AACF;AACE;EACE,SAAA;AACJ;;AAGA;EACE,eAAA;AAAF;;AAGA;EACE,cAAA;AAAF;;AAGA;EACE,aAAA;EACA,8BAAA;EACA,eAAA;EACA,QAAA;AAAF;AAEE;EACE,QAAA;EACA,WAAA;AAAJ;;AAKE;EACE,gBAAA;EACA,eAAA;EACA,eAAA;EACA,gBAAA;AAFJ;AAIE;EACE,uBAAA;AAFJ","sourcesContent":[".fields-box {\n  line-height: 1.5;\n  padding: 0 5px;\n\n  p {\n    margin: 0;\n  }\n}\n\n.title-text {\n  font-size: 14px;\n}\n\n.syl-box {\n  padding: 0 8px;\n}\n\n.company-list {\n  display: flex;\n  justify-content: space-between;\n  flex-wrap: wrap;\n  gap: 5px;\n\n  .list-field{\n    gap:3px;\n    flex: 1 1 0;\n  }\n}\n\n.model-item{\n  ion-label{\n    background: #fff;\n    cursor: pointer;\n    font-size: 14px;\n    line-height: 1.5;\n  }\n  ion-label:hover, ion-label:active{\n    filter:brightness(95%);\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
