import {Component, OnInit} from '@angular/core';
import {BusinessService} from "../../services/BusinessService";
import {Business} from "../../../framework/model/core/business/business";
import {BusinessImages} from "../../../framework/model/core/business/businessImages";
import {NavigationExtras} from "@angular/router";
import {AppSettings} from "../../conf/appSettings";
import {Panel} from "../../../framework/model/core/panel/panel";
import {MainService} from "../../services/MainService";
import {Events} from "../../../framework/providers/events";
import {NavController, Platform} from "@ionic/angular";
import * as moment from "moment";
import {OrderService} from 'src/core/services/OrderService';
import {AdminService} from "../../services/AdminService";

@Component({
    selector: 'app-main',
    templateUrl: './main.page.html',
    styleUrls: ['./main.page.scss'],
})

export class MainPage implements OnInit {
    public advmodels: Array<any> = [];
    public panels: Array<Panel> = [];

    public business: Business;
    public businessImages: BusinessImages;

    public navButtons: object;
    public navSecButtons: object;

    public catalog: boolean = this.businessService.isCatalog();

    public settings;

    public restMode: boolean = false;

    public title: any;
    public pageWidth: any;

    public cartData: any;

    public topSectionsPrefix = 'appDataSets_' + +Math.floor(Math.random() * Math.floor(1000000));

    ready = false;
    afterView = false;


    presentingElement = null;

    constructor(private platform: Platform,
                public adminService: AdminService,
                public orderService: OrderService,
                public mainService: MainService,
                public businessService: BusinessService, public events: Events, public navCtrl: NavController,
                public appSettings: AppSettings) {

        platform.ready().then(() => {
            this.pageWidth = platform.width();
        });
    }

    backtoOrder() {
        this.navCtrl.navigateBack('admin-model/orderDetails');
    }

    ngOnInit() {
       this.initData();

        // this.setNavButtonsAll()

        let me = this;
        setTimeout(function () {
            me.ready = true;
        }, 10);
    }

    initData()
    {
        this.business = this.businessService.getBusiness();
        this.businessImages = this.businessService.getImages();

        this.title = this.business.data.GlobalSettingsData.title;

        this.settings = this.appSettings.getSettings('main');

        this.restMode = this.business.data.GlobalSettingsData.bisType == 'rest';
    }

    resetReady() {
        let me = this;
        me.ready = false;
        setTimeout(function () {
            me.ready = true;
        }, 500);
    }

    ngAfterViewInit() {
        //open tmp adv modal at start
        // this.presentingElement = document.querySelector('.ion-page');
        // setTimeout(function () {
        //     document.getElementById("open-modal").click();
        // }, 4000);
        // //console.log"MAN PAGE EVENTSS!!!")

        this.events.subscribe('businessImagesSets', (params) => {
            this.businessImages = this.businessService.getImages();
            // this.setNavButtonsAll();
        });

        this.events.subscribe('businessAppDataSets', (res) => {
            this.initData();
        });

        // this.events.subscribe('adir23', (params) => {
        //     //console.log'TOP SECTION UPDATE!')
        //     this.events.publish(this.topSectionsPrefix+'_updateModels');
        // });

        this.afterView = true;
    }

    // ngAfterContentInit(){
    //     //console.log'ngAfterContentInit')
    //     this.events.subscribe('adir23', (params) => {
    //         //console.log'TOP SECTION UPDATE! ngAfterContentInit')
    //         this.events.publish(this.topSectionsPrefix+'_updateModels');
    //     });
    // }

    setNavButtonsAll() {

        this.navButtons = this.setNavButtons(this.settings['navButtons']);

        if (!this.restMode)
            this.navSecButtons = this.setNavButtons(['backtostock', 'makers', 'favorites']);
    }

    setNavButtons(keys) {
        if (!this.businessImages) return;

        let navButtons = {};

        for (let k in keys) {
            let key = keys[k];

            let img = 'assets/dashboard/img-' + key + '.png';
            if (this.businessImages.data[key])
                img = this.businessImages.data[key + '_image'].imagefullurl;

            let title = key;

            if (key == 'logo')
                title = '';

            if (key == 'store') {
                title = this.business.data.GlobalSettingsData.menuText;
                if (!title) title = key;
            }

            navButtons[key] = {
                'title': title,
                'img': img,
                'size': 12 / keys.length,
                // 'size': key == 'store' || key == 'sales' ? 6 : 4,
            }
        }

        return navButtons;
    }

    goTo(page, params) {

        let navigationExtras: NavigationExtras = {
            queryParams: params
        };

        this.navCtrl.navigateForward(page, navigationExtras);
    }
}
