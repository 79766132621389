import {Component, OnInit} from '@angular/core';
import {AppUser} from '../../../framework/model/core/appuser/appuser';
import {OrderService} from '../../services/OrderService';
import {AlertController, ModalController, NavController, PopoverController} from '@ionic/angular';
import {AuthService} from '../../../framework/providers/auth-service/auth-service';
import {ModelProvider} from "../../../framework/providers/modelProvider/ModelProvider";
import {ActivatedRoute, NavigationExtras, Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {ModelToolsProvider} from "../../../framework/providers/model-tools/model-tools";
import {Company} from "../../../framework/model/core/company/company";
import {AppSettings} from "../../conf/appSettings"
import {MainService} from "../../services/MainService";
import {BusinessService} from "../../services/BusinessService";
import {InitFormsPage} from "../forms/pages/init-forms/init-forms.page";
import {Events} from "../../../framework/providers/events";
import {AppDataService} from "../../services/AppDataService";
import {AppConfig} from "../../conf/main";
import {CartService} from "../../services/CartService";

@Component({
    selector: 'app-profile',
    templateUrl: './profile.page.html',
    styleUrls: ['./profile.page.scss'],
})
export class ProfilePage implements OnInit {
    public advmodels: Array<any> = [];

    public appuser: AppUser;
    public company: Company;
    public formOptions: object = {};
    public companyOptions: object = {};
    public fieldsOptions: object = {};

    public showAppUserId;

    public settings;
    public catalog: boolean = this.businessService.isCatalog();

    public prefix: string = 'profile_' + +Math.floor(Math.random() * Math.floor(1000000));

    constructor(public orderService: OrderService,
                public route: ActivatedRoute,
                public authService: AuthService,
                public navCtrl: NavController,
                public events: Events,
                public modelProvider: ModelProvider,
                public modelToolsProvider: ModelToolsProvider,
                public mainService: MainService,
                public cartService:CartService,
                public appSettings: AppSettings,
                public businessService: BusinessService,
                public modalController: ModalController) {
        this.settings = this.appSettings.getSettings('profile');
        // //console.logthis.settings)
        this.getAppUser();
    }

    ngOnInit() {
        this.events.subscribe('agentCompanyChanged', (res) => {
            this.appuser = this.orderService.getAppUser();
            this.setCompany();
        });

        this.events.subscribe('appuserChanged', (res) => {
            this.getAppUser();
        });

        this.events.subscribe('newpaymentAppuser', (res) => {
            this.refresh();
        });
        this.events.subscribe(this.prefix + '_doneUpload', (res) => {
            this.refresh();
        });

        this.fieldsOptions['username'] = {'options': {'readonly': true}};
        this.fieldsOptions['bisType'] = {'options': {'changePrefix': 'propfileTypeModelScroll', 'hideAddOther': true}};
        this.fieldsOptions['bisSubType'] = {
            'options': {
                'changePrefix': 'propfileSubTypeModelScroll',
                'tablePrefix': 'propfileSubTypePrefix',
                'hideAddOther': true
            }
        };
        this.fieldsOptions['bisTypeOther'] = {'hidden': true};

        this.checkForms();

        let me = this;
        this.route.queryParams.subscribe(params => {
            if (params['id'] && params['manager']) {
                ////console.logparams)
                me.showAppUserId = params['id'];

                this.modelProvider.list('company', {'appUser': params['id']}).then((res) => {
                    this.company = res['models'][0];
                });
            } else {
                this.setCompany();
            }
        });
    }

    ngAfterViewInit() {
        this.events.subscribe('propfileTypeModelScroll', (params) => {
            this.events.publish('propfileSubTypePrefix' + '_updateModelScroll', {'type': params['data']});
            this.checkOtherType('bisType');
        });

        this.events.subscribe('propfileSubTypeModelScroll', (params) => {
            this.checkOtherType('bisSubType');
        });

        let me = this;
        setTimeout(function () {
            me.events.publish('propfileSubTypePrefix' + '_updateModelScroll', {'type': me.appuser.data.bisType});
        }, 100)
    }

    checkForms() {
        let keys = [];
        console.log(this.settings['checkPayment'])
        console.log(this.appuser.data.licenseApproved)
        if (this.settings['checkPayment'] && !this.appuser.data.licenseApproved) {
            keys.push('payment')
        }

        if (this.settings['note']) {
            if (!this.appuser.data[this.settings['note']['fields'][0]])
                keys.push('note')
        }

        if (keys.length)
            this.showForms(keys);
    }


    checkOtherType(key) {
        let me = this;
        setTimeout(function () {
            ////console.logme.appuser)
            if (!me.appuser.data[key + 'Data']) return;
            let value = me.appuser.data[key + 'Data'].data.name == 'אחר';
            me.fieldsOptions['bisTypeOther']['hidden'] = !value;
            me.fieldsOptions['bisSubType']['hidden'] = value && key == 'bisType';
        })
    }

    getAppUser() {
        this.appuser = this.orderService.getAppUser();
        console.log(this.appuser)
    }

    setCompany() {
        if (!this.settings['company']) return;
        this.modelProvider.list('company', {'id': this.appuser.data.companyId}).then((res) => {
            this.company = res['models'][0];
        })
    }

    refresh() {
        this.setCompany();
        this.orderService.checkUserUpdates();
    }

    logout() {
        let me = this;
        this.modelToolsProvider.yesOrNoAlert(function () {
            me.doLogout()
        })
    }

    doLogout(){
        this.mainService.userLoggedOut();
        this.orderService.logout();
    }

    deleteAccount() {
        let me = this;
        this.modelToolsProvider.yesOrNoAlert(function () {
            let params = {
                'title':me.modelToolsProvider.getTranslate('notice'),
                'subTitle':me.modelToolsProvider.getTranslate('thisActionWillDeleteYourAccount'),
                'message':me.modelToolsProvider.getTranslate('toProceed'),
                'no': me.modelToolsProvider.getTranslate('no'),
                'yes': me.modelToolsProvider.getTranslate('yes')
            }

            me.modelToolsProvider.yesOrNoAlert(function () {
                me.modelProvider.customPost('deleteAccount', {}).then((res) => {
                    me.doLogout();
                })
            }, params)
        })
    }

    //
    // async logout() {
    //     const alert = await this.alertCtrl.create({
    //       header: this.translate.instant('areYouSure'),
    //       buttons: [
    //         {
    //           text: this.translate.instant('no'),
    //           role: 'cancel',
    //           cssClass: 'secondary',
    //           handler: (blah) => {
    //           }
    //         }, {
    //           text: this.translate.instant('yes'),
    //           handler: () => {
    //             this.orderService.logout();
    //             this.navCtrl.navigateRoot('main');
    //           }
    //         }
    //       ]
    //     });
    //
    //     await alert.present();
    //   }

    save() {
        this.events.publish(this.formOptions['prefix']);
        if (this.formOptions['valid'])
            this.modelProvider.update(this.appuser, {}, null, true);

        if (this.company) {
            this.events.publish(this.companyOptions['prefix']);
            if (this.companyOptions['valid'])
                this.modelProvider.update(this.company, {}, null);
        }

        this.events.publish('profileUpdated');
    }


    async showForms(keys = []) {
        const modal = await this.modalController.create({
            component: InitFormsPage,
            componentProps: {'keys': keys, 'options': {'finishForm': false}},
            backdropDismiss: false
        });

        return await modal.present();
    }


    async addCompanyMember() {
        let me = this;
        const modal = await this.modalController.create({
            component: InitFormsPage,
            componentProps: {
                'keys': ['user'],
                'addFilters': {'agent': this.appuser.data.id},
                'options': {
                    'externalCallback': function (data) {
                        //console.log"CREATED!!!!")
                    },
                    'finishForm': false,
                    'userFields': ['name', 'cellphone', 'username', 'password', 'confirmPassword'],
                    'addParams': {
                        'user': {
                            'companyId': this.company.data.id,
                            'companyName': this.company.data.companyName,
                            'companyFileNumber': this.company.data.companyFileNumber
                        }
                    },
                    'userCreateCallback': function () {
                        me.modelProvider.presentToast('saved', true, 'dark', null, true);
                    }
                }
            }
        });

        return await modal.present();
    }

    // logOut() {
    //     this.orderService.logout();
    //     this.navCtrl.navigateRoot('main');
    // }

    goTo(page) {
        let navigationExtras: NavigationExtras = {};
        this.navCtrl.navigateForward(page, navigationExtras);
    }


    getCompanyPrices() {
        this.events.publish('getDataPrices', {'skipStorage': true});
    }

    uploadImage() {
        this.events.publish(this.prefix + '_viewUploader');
    }
}
