// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-col {
  padding: 0px 4px;
  line-height: 21px;
}

ion-grid {
  padding: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/shaps/components/custom/trip-box/trip-box.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACE,iBAAA;AACJ;;AAEA;EACE,UAAA;AACF","sourcesContent":["ion-col {\n  padding: 0px 4px;\n    line-height: 21px;\n}\n\nion-grid {\n  padding: 0;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
