import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';

import { IonicModule } from '@ionic/angular';

import { CatalogListPage } from './catalog-list.page';
import {GlobalModule} from "../../../app/global-module/global-module.module";

const routes: Routes = [
  {
    path: '',
    component: CatalogListPage
  }
];

@NgModule({
    imports: [
        GlobalModule,
        CommonModule,
        FormsModule,
        IonicModule,
        RouterModule.forChild(routes)
    ],
    exports: [
        CatalogListPage
    ],
    declarations: [CatalogListPage]
})
export class CatalogListPageModule {}
