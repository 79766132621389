import {Component, OnInit} from '@angular/core';
import {NavController, PopoverController} from "@ionic/angular";
import {ModelToolsProvider} from "../../../../providers/model-tools/model-tools";
import {ActivatedRoute} from "@angular/router";
import {Events} from "../../../../providers/events";

@Component({
    selector: 'app-model-scroll',
    templateUrl: './app-model-scroll.component.html',
    styleUrls: ['./app-model-scroll.component.scss'],
})
export class AppModelScrollComponent implements OnInit {

    public title: string;

    public models: Array<any>;

    public model: any;
    public fieldkey: string;

    public valueField: string;
    public keyField: string;

    public changePrefix: string;
    public listPrefix: string;
    public multipleSelect: boolean = false;

    public checkedModels: Object;
    public checkedModelsData: Object;

    public addParams: Object;
    public tablePrefix: string;
    public filters: Object;

    public searchText: string;
    public options: object;


    constructor(public events: Events, public navCtrl: NavController, public modelTools: ModelToolsProvider, public popoverCtrl: PopoverController, public route: ActivatedRoute) {
        this.route.queryParams.subscribe(params => {
            this.title = params['title'];
            this.models = params['models'];
            this.model = params['model'];
            this.fieldkey = params['fieldkey'];
            this.valueField = params['valueField'];
            this.keyField = params['keyField'];
            this.changePrefix = params['changePrefix'];
            this.listPrefix = params['listPrefix'];
            this.multipleSelect = params['multipleSelect'];
            this.options = params['options'];

            this.addParams = params['addParams'];
            this.tablePrefix = params['tablePrefix'];
            this.filters = params['filters'];
        });

        this.checkedModels = {};
        this.checkedModelsData = {};

    }

    ngOnInit() {
        this.setInitData();

        this.events.subscribe(this.tablePrefix + '_updated', (params) => {
            this.events.publish(this.listPrefix + '_updateFilter', this.filters);
        })
        this.events.subscribe(this.listPrefix + '_modelUpdated', (params) => {
            this.models = params['models']
        })
    }

    ngOnDestroy() {
        this.events.unsubscribe(this.tablePrefix + '_updated')
    }

    setInitData() {
        if (!this.model.data[this.fieldkey]) return;
        if (!this.model.data[this.fieldkey].length) return;

        var modelsKeys = this.model.data[this.fieldkey].split(',')
        for (var k in this.models) {
            if (this.ifOnArr(modelsKeys, this.models[k].data[this.keyField])) {
                this.checkedModels[this.models[k].data[this.keyField]] = true;
                this.checkedModelsData[this.models[k].data[this.keyField]] = this.models[k];
            }
        }
    }

    ifOnArr(arr, value) {
        for (var k in arr) {
            if (arr[k] == value) return true;
        }
        return false;
    }

    save() {
        this.setEvent();
        this.popoverCtrl.dismiss();
        // this.navCtrl.pop()
    }

    setEvent() {
        ////console.log'PUBLISH', this.changePrefix)
        this.events.publish(this.changePrefix, {
            'data': this.objToStr(this.checkedModels),
            'models': this.checkedModelsData
        });
    }

    objToStr(obj) {
        var str = ''
        for (var k in obj) {
            if (obj[k]) {
                if (str != '') str += ',';
                str += k;
            }
        }
        return str;
    }

    isChecked(model) {
        return this.checkedModels[model.data[this.keyField]]
    }

    setChecked(model) {
        if (!this.multipleSelect) this.checkedModels = {}
        //

        this.checkedModels[model.data[this.keyField]] = !this.checkedModels[model.data[this.keyField]]
        this.checkedModelsData[model.data[this.keyField]] = model
        //
        if (!this.multipleSelect) this.save();
    }

    addOther() {
        if (!this.addParams) this.addParams = {}
        this.addParams['fieldName'] = this.fieldkey;

        this.modelTools.addModel(this.navCtrl, this.model.dataFields[this.fieldkey].modelscroll,
            this.filters, this.addParams, this.tablePrefix, this.popoverCtrl, null, false)
    }

    search(e) {
        this.filters['search'] = this.searchText;
        this.filters['clearFilters'] = true;
        this.events.publish(this.listPrefix + '_updateFilter', this.filters);
    }
}
