import {Component, OnInit} from '@angular/core';
import {AppConfig} from "../../../../core/conf/main";
import {NavController, ToastController} from "@ionic/angular";
import {ModelProvider} from "../../../../framework/providers/modelProvider/ModelProvider";
import {User, UserData} from "../../../../framework/providers/user-data";
import {Events} from "../../../../framework/providers/events";

@Component({
    selector: 'app-search-device',
    templateUrl: './search-device.page.html',
    styleUrls: ['./search-device.page.scss'],
})
export class SearchDevicePage implements OnInit {

    rasPort = '83';
    data = {
        'masterFullIp': "",
        'masterIp': "",
        'startIp': 2,
        'netIp': "",
        'endIp': 100,
    };
    currentIpIndex = 0;
    syncOn = false;
    devices = {};
    sites = [];
    deviceIds = [];
    appConfig = AppConfig;

    constructor(public navCtrl: NavController, public userData: UserData,
                private events: Events,
                private toastController: ToastController,
                private modelProvider: ModelProvider) {
    }

    ngOnInit() {
        //console.log'settings?')
        //console.logAppConfig['searchIps']);

        // this.getSettings();
        if (AppConfig['searchIps']) {
            //console.log'found!')
            this.data['endIp'] = AppConfig['searchIps']['endIp'];
            this.data['startIp'] = AppConfig['searchIps']['startIp'];
            this.data['netIp'] = AppConfig['searchIps']['netIp'];
        }

        // //console.logme.data);

        let me = this;

        setInterval(function () {
            if (!me.syncOn) {
                return;
            }
            //console.logme.data);

            if (me.currentIpIndex < me.data.endIp+1) {
                //console.log'get');
                me.searchIp(me.currentIpIndex);

                me.currentIpIndex += 1;
            } else {
                me.syncOn = false;
            }
        }, 300);
    }

    searchNetwork(currentIpIndex) {
        let me = this;
        me.modelProvider.custom('searchNetwork', {}).then(function (res) {
            //console.logres);
        });
    }

    searchIp(currentIpIndex) {
        let url = this.data.netIp + '.' + currentIpIndex + ':' + this.rasPort;

        let me = this;
        me.modelProvider.custom('ping', {}, url).then(function (res) {
            if (res['devices']) {
                me.foundDevice(currentIpIndex, res['devices'][0]);
            }
        });
    }

    foundDevice(currentIpIndex, device) {
        if (!this.devices[device.mac]) {
            device.ip = this.data.netIp + '.' + currentIpIndex;
            this.deviceIds.push(device.mac);
            this.devices[device.mac] = device;
        }
    }

    reboot(device) {
        let url = device.ip + ':' + this.rasPort;
        this.modelProvider.custom("reboot", {}, url);
    }

    ledOn(device) {
        let url = device.ip + ':' + this.rasPort;
        this.modelProvider.custom("mainLedOn", {}, url);
    }

    goDevices() {
        this.navCtrl.navigateForward("devices");
    }

    reset(device) {
        let url = device.ip + ':' + this.rasPort;
        this.modelProvider.custom("reset", {});
    }

    addDevice(device) {
        // if (!AppConfig.chosenSite) {
        //     //console.log'no site selected!');
        //     return
        // }

        let me = this;

        //console.log'tokens:');
        //console.logAppConfig.tokens);
        //console.logAppConfig['fullMasterIp']);

        this.userData.getUserdata().subscribe((userData: User) => {
            var token;
            //console.log"userData:");
            //console.loguserData);
            if (userData)
                token = userData.data.token;

            this.modelProvider.custom("addDevice",
                {
                    'masterIp': AppConfig['dataServerIp'],
                    'masterToken': token,
                    'prefix': device.prefix,
                    'id': device.id,
                    // 'deviceData': device
                }, device.ip + ':' + this.rasPort).then(function () {
                me.toastit('בקר נוסף בהצלחה.', 'success');
            });
        });

        // this.modelProvider.custom("setMasterIp",
        //     {'masterIp': this.devicesService.masterIp}, device.ip + ':'+this.rasPort);
    }

    foundMaster(device) {
        let url = device.ip + ':' + this.rasPort;

        this.data.masterIp = device.ip;
        // me.syncOn = false;
        this.data.masterFullIp = url;
        // this.setSettings();


        this.modelProvider.custom("setThisMaster", {}, url);
        // this.devicesService.logout();
        // this.devicesService.setStaticIp(device.ip);
        // this.devicesService.setSettings();

        // this.events.publish('onSetMasterIp');
    }

    searchDevice() {
        // this.devices = [];

        if (!this.data.endIp) {
            this.data.endIp = 200;
        }
        this.currentIpIndex = this.data.startIp;
        this.syncOn = true;

        this.setSettings();
    }

    getSettings() {

    };

    setSettings() {
        AppConfig['searchIps'] = this.data;
        // this.devicesService.setSettings();

        // this.storage.set(SETTINGS_KEY, this.data);
        //
        // let me = this;
        // setTimeout(function () {
        //     me.getSettings();
        // }, 2000);
    }

    clear() {
        this.devices = {};
        this.deviceIds = [];
    }

    // chooseSite(site) {
    //     AppConfig['chosenSite'] = site;
    // }

    async toastit(txt, color) {
        let me = this;
        const toast = await this.toastController.create({
            message: txt,
            duration: 2000,
            color: color
        });
        toast.present();
    }

}
