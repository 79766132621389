// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bar {
  height: 40px;
  text-align: center;
  padding: 8px;
}

.button-a, .button-b {
  border-radius: 10px;
}

.active-coupon {
  font-size: 16px;
  padding: 9px;
}`, "",{"version":3,"sources":["webpack://./src/core/pages/cart/components/coupons/coupons.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,kBAAA;EACA,YAAA;AACF;;AAEA;EAEE,mBAAA;AAAF;;AAGA;EAEE,eAAA;EACA,YAAA;AADF","sourcesContent":[".bar {\n  height: 40px;\n  text-align: center;\n  padding: 8px;\n}\n\n.button-a, .button-b\n{\n  border-radius: 10px;\n}\n\n.active-coupon\n{\n  font-size: 16px;\n  padding: 9px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
