// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-badge {
  padding: 0px;
  height: 100%;
  max-height: 12px;
}`, "",{"version":3,"sources":["webpack://./src/core/pages/admin/pages/sections-manager/pages/sections-manager-tabs/sections-manager-tabs.page.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,YAAA;EACA,gBAAA;AACF","sourcesContent":["ion-badge {\n  padding: 0px;\n  height: 100%;\n  max-height: 12px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
