import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {Item} from "../../../../../framework/model/core/items/item";
import {PopoverController} from "@ionic/angular";
import {MiniItem} from "../../../../../framework/model/core/items/miniitem";
import {ModelToImage} from "../../../../../framework/model/core/general/modelToImage";
import {Events} from "../../../../../framework/providers/events";

@Component({
  selector: 'bonus-items',
  templateUrl: './bonus-items.component.html',
  styleUrls: ['./bonus-items.component.scss'],
})
export class BonusItemsComponent implements OnInit {

  @Input()
  public model:Item;

  public models:Array<MiniItem> = [];

  public options:object = {};
  public prefix:string = 'itemList_' + Math.floor(Math.random() * Math.floor(1000000));

  constructor(public route:ActivatedRoute, public popoverCtrl:PopoverController, public events:Events) {
    // this.route.queryParams.subscribe(params => {
    //     //     this.model = params['model'];
    //     // });

    this.events.subscribe(this.prefix+'_modelUpdated', (res)=>{
      this.setItems(res['models']);
    })
  }

  ngOnInit() {}

  setItems(bonuses)
  {
    for(let k in bonuses)
    {
      let itemData = new MiniItem(bonuses[k].data['itemData']);
      // for(let k in itemData.data['images'])
      // {
      //     itemData.data['images'][k] = new ModelToImage(itemData.data['images'][k]);
      // }
      this.models.push(itemData)
    }

  }

  close()
  {
    this.popoverCtrl.dismiss();
  }
}
