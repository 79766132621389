import {Component, OnInit} from '@angular/core';
import {OrderService} from '../../services/OrderService';
import {AppUser} from '../../../framework/model/core/appuser/appuser';
import {Events} from "../../../framework/providers/events";
import {AppDataService} from "../../services/AppDataService";
import {ModelToolsProvider} from "../../../framework/providers/model-tools/model-tools";


@Component({
    selector: 'app-favorites',
    templateUrl: './favorites.page.html',
    styleUrls: ['./favorites.page.scss'],
})
export class FavoritesPage implements OnInit {
    public appuser: AppUser;
    public prefix = 'favorites_list'+this.tools.getRandomize();

    public filters = {};
    public itemsarr;

    constructor(public orderService: OrderService, public events:Events, public appdataService:AppDataService, public tools:ModelToolsProvider) {

    }

    ngOnInit() {
        this.start()
        // this.events.subscribe('favorites_updated', ()=>{
        //     this.events.publish(this.prefix+'_updateItemsFilter');
        //     this.events.publish(this.prefix+'_updateItemsFilter');
        // })
        this.events.subscribe('appPriceSets', ()=>{
            this.start();
            this.events.publish(this.prefix+'_updateItems', this.filters);
        }, this.prefix)
    }

    start()
    {
        this.appuser = this.orderService.getAppUser();
        this.itemsarr = this.appdataService.getPricesListKey('itemToUser');

        this.filters['itemsarr'] = this.itemsarr;
    }

    ngOnDestroy()
    {
        this.events.unsubscribe('appPriceSets', this.prefix);
        // this.events.unsubscribe('favorites_updated');
    }

}
