import {Component, OnInit} from '@angular/core';
import {Order} from '../../../framework/model/core/order/order';
import {BusinessImages} from '../../../framework/model/core/business/businessImages';
import {BusinessService} from '../../services/BusinessService';
import {NavController} from '@ionic/angular';
import {Business} from '../../../framework/model/core/business/business';
import {Ticket} from "../../../framework/model/core/ticket/ticket";
import {Item} from "../../../framework/model/core/items/item";
import {ActivatedRoute} from "@angular/router";
import {OrderService} from "../../services/OrderService";
import {ItemProvider} from "../item/providers/item-provider/item-provider.service";
import {Events} from "../../../framework/providers/events";
import {AppConfig} from "../../conf/main";

@Component({
    selector: 'app-about',
    templateUrl: './about.page.html',
    styleUrls: ['./about.page.scss'],
})

export class AboutPage implements OnInit {
    public advmodels: Array<any> = [];
    appconfig = AppConfig

    public businessImages: BusinessImages;
    public business: Business;
    public models: Array<Business> = [];

    // public cartItem: object;

    public tabs: object = {'pages': [], 'active': '', 'readmore': false};

    public prefix = 'about' + +Math.floor(Math.random() * Math.floor(1000000));

    public catalog: boolean = this.businessService.isCatalog();


    constructor(public navCtrl: NavController, public route: ActivatedRoute, public businessService: BusinessService, public events: Events) {
        this.business = this.businessService.getBusiness();
        this.businessImages = this.businessService.getImages();
        this.events.subscribe('businessImagesSets', (params) => {
            this.businessImages = this.businessService.getImages();
        });
    }

    ngOnInit() {
        // this.events.subscribe(this.modelId+'_cartItemChanged', (res) => {
        //     this.cartItem = res;
        // });
        //
        // this.events.subscribe(this.prefix + '_modelUpdated', (res) => {
        //     this.model = res['models'][0];
        //
        //     if (this.model.data.description) this.tabs['pages'].push('itemDetails');
        //     if (this.model.data.topItemPropertyData.length) this.tabs['pages'].push('specifications');
        //     this.tabs['active'] = this.tabs['pages'][0];
        // })
    }


}
