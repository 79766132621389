import {Component, Input, OnInit} from '@angular/core';
import {DealGroup} from '../../../../../framework/model/core/deal/dealGroup'
import {AppDataService} from '../../../../services/AppDataService'
import {ItemProvider} from '../../../../pages/item/providers/item-provider/item-provider.service'

import {PropsProvider} from "../../../item/providers/props-provider/props-provider.service";
import {Events} from "../../../../../framework/providers/events";

@Component({
  selector: 'deal-group-items',
  templateUrl: './deal-group-items.component.html',
  styleUrls: ['./deal-group-items.component.scss'],
})
export class DealGroupItemsComponent implements OnInit {

  @Input()
  public group:DealGroup;

  @Input()
  public checkedItems:object = {};

  public checkedProps:object = {};

  constructor(public appDataService:AppDataService, public itemProvider:ItemProvider, public propsProvider:PropsProvider,
              public events:Events) { }

  ngOnInit() {
    if(!this.checkedItems[this.group.data.id])
    {
      this.checkedItems[this.group.data.id] = {};
      this.initCheckedItems();
    }
  }

  setItem(itemres)
  {
    let item = this.appDataService.getItemModel(itemres['itemId']);
    this.checkedItems[this.group.data.id]['item'] = item;
    this.checkedItems[this.group.data.id]['itemres'] = itemres;

    if(!item.data.dealprops.length) return;

    let propsKey = 'groupDealProps_'+this.group.data.id;
    this.propsProvider.showPropsModal(item, {}, this.checkedProps, propsKey, 'proceed', false);

    this.events.unsubscribe(propsKey);
    this.events.subscribe(propsKey, (res)=>{
      // //console.logpropsKey, res)
      this.checkedItems[this.group.data.id]['props'] = res['props'];
      this.checkedProps = res['checkedProps'];
    })

    // this.events.unsubscribe(propsKey+'_closed');
    // this.events.subscribe(propsKey+'_closed', (res)=>{
    //   this.removeCheckedItem();
    // })

  }

  removeCheckedItem() {
    this.initCheckedItems();
  }

  initCheckedItems(){
    this.checkedItems[this.group.data.id]['item'] = null;
    this.checkedItems[this.group.data.id]['itemres'] = null;
    this.checkedItems[this.group.data.id]['props'] = {};

    this.checkedProps = {};
  }

}
