import {Item} from "../items/item";

export class OrderItem {
    data = {
        'itemData': null,
        'dealProperties': null,
        'unitPrice': null,
        'totalPrice': null,
        'price': null,
        'amount': null,
        'code': null,
        'comments': null,
        'itemId': null,
        'saleTitle': null,
        'ticket': null,
        'images': null,
        'originalItemPrice': null,
        'finalUnitPrice': null,

        'error': null,
        'error_msg': null,
    };

    constructor(public data1: any) {
        this.data = data1;

        if(this.data && this.data.itemData)
            this.data.itemData = new Item(this.data.itemData)
    }

    //shortHeaders = [];
    prefix = "orderItem";
    title = "Order Item";
    dataFields = {
        id: {'title': 'ID', 'value': null, 'type': 'number', 'readonly': true},
        created: {'title': 'Created', 'value': null, 'type': 'date', 'readonly': true},
        updated: {'title': 'Updated', 'value': null, 'type': 'date', 'readonly': true},
        itemName: {'title': 'ItemName', 'value': null, 'type': 'text'},
        itemId: {'title': 'Item', 'value': null, 'type': 'text'},
        finalUnitPrice: {'title': 'finalUnitPrice', 'value': null, 'type': 'number'},
        amount: {'title': 'Amount', 'value': null, 'type': 'number'},
        orderId: {'title': 'Order', 'value': null, 'type': 'text'},
        // image: {'title': 'Image', 'value': null, 'type': 'image'},
    }
}
