// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.type-header {
  margin: 5px 10px;
}
.type-header .title {
  font-weight: 800;
}

.prop-box {
  width: 100%;
  margin: 0px;
  position: relative;
}
.prop-box .img {
  width: 100%;
  border-bottom: 1px solid #dcdcdc;
  text-align: center;
}
.prop-box .img .checked-box {
  text-align: start;
  position: absolute;
  z-index: 50;
  color: #fff;
  top: 0;
  font-size: 17px;
  padding: 1px 1px 0 1px;
  width: 22px;
  background: #8BC34A;
  border-bottom-left-radius: 13px;
}

.prop-box.active {
  border: 1px solid #8BC34A;
}
.prop-box.active .img {
  border-color: #8BC34A;
}

.prop-box:active {
  opacity: 0.8;
}

.title {
  font-size: 14px;
}

.button-a.small, .button-b.small {
  height: 25px;
  --padding-start: 5px;
  --padding-end: 5px;
}

.props-box {
  line-height: 1.2;
  font-size: 14px;
}
.props-box .title {
  font-weight: bold;
}
.props-box .props span {
  font-style: italic;
}`, "",{"version":3,"sources":["webpack://./src/core/pages/deals/components/deal-group-items/deal-group-items.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;AACE;EACE,gBAAA;AACJ;;AAGA;EACE,WAAA;EACA,WAAA;EACA,kBAAA;AAAF;AAEE;EACE,WAAA;EACA,gCAAA;EACA,kBAAA;AAAJ;AAEI;EACE,iBAAA;EACA,kBAAA;EACA,WAAA;EACA,WAAA;EACA,MAAA;EACA,eAAA;EACA,sBAAA;EACA,WAAA;EACA,mBAAA;EACA,+BAAA;AAAN;;AAKA;EACE,yBAAA;AAFF;AAIE;EACE,qBAAA;AAFJ;;AAMA;EACE,YAAA;AAHF;;AAOA;EACE,eAAA;AAJF;;AAQA;EACE,YAAA;EACA,oBAAA;EACA,kBAAA;AALF;;AASA;EACE,gBAAA;EACA,eAAA;AANF;AAQE;EACE,iBAAA;AANJ;AAYI;EACE,kBAAA;AAVN","sourcesContent":[".type-header {\n  margin: 5px 10px;\n\n  .title {\n    font-weight: 800;\n  }\n}\n\n.prop-box {\n  width: 100%;\n  margin: 0px;\n  position: relative;\n\n  .img {\n    width: 100%;\n    border-bottom: 1px solid #dcdcdc;\n    text-align: center;\n\n    .checked-box {\n      text-align: start;\n      position: absolute;\n      z-index: 50;\n      color: #fff;\n      top: 0;\n      font-size: 17px;\n      padding: 1px 1px 0 1px;\n      width: 22px;\n      background: #8BC34A;\n      border-bottom-left-radius: 13px;\n    }\n  }\n}\n\n.prop-box.active {\n  border: 1px solid #8BC34A;\n\n  .img {\n    border-color: #8BC34A;\n  }\n}\n\n.prop-box:active {\n  opacity: 0.8;\n  //border:1px solid #0ec254;\n}\n\n.title {\n  font-size: 14px;\n  //background: #fff;\n}\n\n.button-a.small, .button-b.small {\n  height: 25px;\n  --padding-start: 5px;\n  --padding-end: 5px;\n}\n\n\n.props-box {\n  line-height: 1.2;\n  font-size: 14px;\n\n  .title {\n    font-weight: bold;\n  }\n\n  .props {\n    //padding: 0 5px;\n\n    span {\n      font-style: italic;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
