// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bar {
  height: 40px;
  text-align: center;
  padding: 8px;
}

.bar.opening-bar {
  background: var(--main-top);
  color: var(--main-top-text);
  font-size: 20px;
}

.body {
  max-height: 400px;
  max-width: 200px;
  margin: auto;
  padding: 3px;
  line-height: 1.5;
}`, "",{"version":3,"sources":["webpack://./src/core/components/opening/opening.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,kBAAA;EACA,YAAA;AACF;;AAEA;EAGE,2BAAA;EACA,2BAAA;EACA,eAAA;AADF;;AAIA;EACE,iBAAA;EAEA,gBAAA;EACA,YAAA;EAEA,YAAA;EACA,gBAAA;AAHF","sourcesContent":[".bar {\n  height: 40px;\n  text-align: center;\n  padding: 8px;\n}\n\n.bar.opening-bar {\n  //border: 2px solid;\n  //border-color: var(--button-a-background);\n  background: var(--main-top);\n  color: var(--main-top-text);\n  font-size: 20px;\n}\n\n.body {\n  max-height: 400px;\n  //padding:5px;\n  max-width: 200px;\n  margin: auto;\n\n  padding: 3px;\n  line-height: 1.5;\n}\n\n.box-content {\n  //border: 2px solid;\n  //border-top: 0;\n  //border-bottom: 0;\n  //border-color: var(--button-a-background);\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
