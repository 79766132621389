import {ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {OrderService} from "../../../../services/OrderService";
import {MenuController, ModalController} from "@ionic/angular";
import {ModelToolsProvider} from "../../../../../framework/providers/model-tools/model-tools";
import {TicketsListPage} from "../../pages/tickets-list/tickets-list.page";
import {Business} from "../../../../../framework/model/core/business/business";
import {BusinessService} from "../../../../services/BusinessService";
import {AppSettings} from "../../../../conf/appSettings";
import {Events} from "../../../../../framework/providers/events";
import {CartService} from "../../../../services/CartService";

@Component({
    selector: 'quick-cart',
    templateUrl: './quick-cart.component.html',
    styleUrls: ['./quick-cart.component.scss'],
})
export class QuickCartComponent implements OnInit, OnDestroy {

    @Input()
    hideCartDetails = true;

    @Input()
    quickOrder = false;

    @Input()
    options: object = {};

    public business: Business;
    public openTickets = {};
    public cart;
    public cartData;
    public cartStatus;
    public openStatus = {};
    public catalog: boolean;

    public active: boolean = true;

    public itemSettings;
    public settings;
    public appuser;

    public sub_prefix: string = 'sub_' + Math.floor(Math.random() * Math.floor(1000000));

    public itemView = 'small';

    @ViewChild('cartContent', null) private cartContent: ElementRef;

    constructor(public events: Events,
                public modalController: ModalController,
                public businessService: BusinessService,
                private changeRef: ChangeDetectorRef,
                private menuCtrl: MenuController,
                public appsettings: AppSettings,
                public cartService: CartService,
                public orderService: OrderService, public modelToolsProvider: ModelToolsProvider) {
        this.business = this.businessService.getBusiness();

        this.catalog = this.businessService.isCatalog();
        this.settings = this.appsettings.getSettings('cart');
        this.itemSettings = this.appsettings.getSettings('items');
        this.appsettings = this.appsettings.getSettings('app');
    }

    ngOnInit() {
        this.events.subscribe('cartUpdated', (res) => {
            this.getCart();
        }, this.sub_prefix);

        this.events.subscribe('cartStatusChanged', (params) => {
            this.getCartStatus();
        }, this.sub_prefix);

        this.events.subscribe('appuserChanged', (params) => {
            this.appuser = this.orderService.getAppUser();
        }, this.sub_prefix);

        this.appuser = this.orderService.getAppUser();

        this.getCart();
        this.getCartStatus();

        // let me = this;
        // setTimeout(function () {
        //     me.setActiveTicket(me.cartData['tickets'][0]);
        // }, 200)
    }

    ngOnDestroy() {
        this.events.unsubscribe('cartUpdated', this.sub_prefix);
        this.events.unsubscribe('cartStatusChanged', this.sub_prefix);
        this.events.unsubscribe('appuserChanged', this.sub_prefix);
    }

    changeViewSize() {
        if (this.options['big-screen']) {
            this.itemView = 'big';
            this.options['big-screen'] = false;
        } else if (this.itemView == 'big') {
            this.itemView = 'small';
        } else if (this.businessService.getGlobalSettings().data.bisType == 'moving') {
            this.options['big-screen'] = true;
            this.itemView = 'big';
        } else {
            this.itemView = 'big';
        }
    }

    goToCartPage() {
        this.menuCtrl.close('cartMenu');
        // this.menuCtrl.enable(false, 'cartMenu');
        // this.menuCtrl.enable(false, 'menu2');
        this.modelToolsProvider.goTo('cart');
    }

    // setCartStatus() {
    //     this.orderService.setCartStatus();
    // }

    getCartStatus() {
        this.cartStatus = this.orderService.getCartStatus();
    }

    getCart() {
        let me = this;
        me.cart = me.cartService.getCart();
        me.cartData = me.cartService.getCartData();

        // //console.logme.cartData)
        // //console.logme.cart)
        //
        // if (Object.keys(me.cart).length == 1) {
        //     //////console.log'open ticket!!!')
        //     me.openStatus[Object.keys(me.cart)[0]] = true;
        // }

        this.changeRef.detectChanges();

        // this.scrollToBottom();
    }

    emptyCart() {
        let me = this;
        this.modelToolsProvider.yesOrNoAlert(function () {
            me.orderService.clearCart();
        })
    }

    proceed() {
        if (!this.appuser) {
            this.modelToolsProvider.goTo('login');
            return
        }
        this.hideCartDetails = false;
    }

    async showTickets() {
        if (!this.cartData['tickets']) return;
        const modal = await this.modalController.create({
            component: TicketsListPage,
            componentProps: {}
        });

        return await modal.present();
    }

    // openCloseRoom(name)
    // {
    //     if (this.openRooms.indexOf(name)!=-1)
    //     {
    //         this.openRooms.splice(this.openRooms.indexOf(name))
    //     }
    // }


    scrollToBottom(): void {
        // try {
        this.cartContent.nativeElement.scrollTop = this.cartContent.nativeElement.scrollHeight;
        // } catch(err) { }
    }

    closeAllTickets() {
        for (let ticket of this.cartData['tickets']) {
            this.cart[ticket]['open'] = false;
            this.openTickets[ticket] = false;
        }
    }

    setActiveTicket(ticket) {
        this.closeAllTickets();
        this.openTickets[ticket] = !!!this.openTickets[ticket];
        this.cartService.setActiveTicket(ticket);

        let value = ticket;
        if (this.cartData['activeTicket'] == ticket) value = null;
        this.cartData['activeTicket'] = value;

        if (ticket == -1) return;

        this.cart[ticket]['open'] = value == ticket;
    }
}
