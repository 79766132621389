import {Component, OnInit} from '@angular/core';
import {AppUser} from '../../../framework/model/core/appuser/appuser';
import {OrderService} from '../../services/OrderService';
import {LoadingController, NavController, PopoverController} from '@ionic/angular';
import {AuthService} from '../../../framework/providers/auth-service/auth-service';
import {ResObject} from '../../../app/global-module/global-module.module';
import {ActivatedRoute, NavigationExtras} from '@angular/router';
import {ModelProvider} from '../../../framework/providers/modelProvider/ModelProvider';
import {ModelToolsProvider} from "../../../framework/providers/model-tools/model-tools";
import {TranslateService} from "@ngx-translate/core";
import {Business} from "../../../framework/model/core/business/business";
import {BusinessService} from "../../services/BusinessService";
import {AppSettings} from "../../conf/appSettings";
import {MainService} from "../../services/MainService";
import {Events} from "../../../framework/providers/events";

@Component({
    selector: 'app-register',
    templateUrl: './register.page.html',
    styleUrls: ['./register.page.scss'],
})
export class RegisterPage implements OnInit {
    public appuser: AppUser;
    public business: Business;
    public formOptions: object = {};
    public companyOptions: object = {};
    public badpassword: boolean = false;

    public block: boolean = false;

    public userExists: boolean;
    public fileNumberExist: boolean;

    public loadingPopover;

    public appuserFields: Array<string>;
    public companyFields: Array<string>;

    constructor(public events: Events, public modelProvider: ModelProvider, private auth: AuthService,
                public route: ActivatedRoute, public appSettings: AppSettings,
                public mainService: MainService, public loadingController: LoadingController,
                public navCtrl: NavController, public businessService: BusinessService,
                public modelToolsProvider: ModelToolsProvider, public translate: TranslateService) {
        this.initAppUser();

        this.route.queryParams.subscribe(params => {
            this.block = params['block'];
        });
    }

    ngOnInit() {
        let settings = this.appSettings.getSettings('register');
        this.appuserFields = settings.appuserKeys;
        this.companyFields = settings.companyKeys;
    }

    initAppUser() {
        this.appuser = new AppUser({
            'name': 'בדיקה',
            'companyName': 'בדיקה',
            'companyFileNumber': '182872333',
            'cellphone': '123',
            'username': 'test00007@onebutton.co.il',
            'password': '3dfewe3',
            'confirmPassword': '3dfewe3',
            'notfication': true
        });
        this.business = this.businessService.getBusiness()
    }

    ngOnDestroy() {
        //console.log"##!#!## DESTT")
        this.initAppUser();
    }

    register() {
        // this.presentLoading();
        // return;

        this.checkPassword(this.appuser.data['password']);
        this.events.publish(this.formOptions['prefix']);
        this.events.publish(this.companyOptions['prefix']);

        if (!this.formOptions['valid'] || !this.companyOptions['valid']) {
            return false;
        }
        // this.presentLoading();

        this.modelProvider.custom('checkUserExsists',
            {
                'username': this.appuser.data.username,
                'companyFileNumber': this.appuser.data.companyFileNumber,
                'businessCode': this.appuser.data['businessCode'],
                'verifyCode': true,
                'loading': true, 'disableStopLoading': true,
                'setPrefix': true
            }).then((params) => {
            this.userExists = params['res'] > 0;
            this.fileNumberExist = params['fileNumberExist'] > 0;
            // this.stopLoading();
            // //console.logparams)

            if (this.userExists || this.fileNumberExist) {
                this.stopLoading();
                return;
            }

            this.userExists = false;

            this.stopLoading();

            let prefix = 'regiserCodePrefix_'+ Math.floor(Math.random() * Math.floor(1000000));
            this.mainService.showRegisterModal(null, {
                'codeOnly': true,
                'prefix': prefix,
                'appuserData': this.appuser.data,
                'title': 'verifyCodeSent'
            })

            this.events.subscribe(prefix, (res) => {
                this.modelProvider.custom('checkVerifyCode',
                    {
                        'username': this.appuser.data.username,
                        'code': res,
                        'loading': true,
                        'disableStopLoading': true,
                        'businessCode': this.appuser.data['businessCode'],
                        'setPrefix': true
                    }).then((params) => {
                    if(params['res'] == 0)
                    {
                        this.events.publish(prefix + 'inputKeyExternalCheck', {'key': 'codeError', 'value': true});
                        this.stopLoading();

                        return;
                    }

                    this.createAppUser();
                    this.mainService.closeModal();

                });
            });
            return
        });

        // if (this.appuser.data['password'+'_invalid']) {
        //
        //     return false;
        // }
        //
    }

    createAppUser() {

        let cren = {'email': this.appuser.data.username, 'password': this.appuser.data['password']};
        this.modelProvider.create(this.appuser, {
            'loading': true,
            'disableStopLoading': true
        }).then((res: ResObject) => {
            this.formOptions['isSent'] = false;
            this.login(cren);

            this.stopLoading();

            let title = null;
            let registerMsg = this.business.data.GlobalSettingsData.registerDoneMessage;

            if (!registerMsg) {
                title = this.translate.instant('welcome');
                registerMsg = this.translate.instant('registrationSuccessfullyCompleted');
                // registerMsg += '\n' + this.translate.instant('accountNotValidateYet');
            }
            this.modelToolsProvider.textAlert(title, registerMsg);

            // this.modelToolsProvider.goTo('login');

        });
    }

    checkPassword(pass) {
        this.appuser.data['password' + '_invalid'] = this.appuser.data['password'] != this.appuser.data['confirmPassword'];
        this.appuser.data['confirmPassword' + '_invalid'] = this.appuser.data['password' + '_invalid'];

        if (this.appuser.data['password' + '_invalid']) return;

        this.badpassword = pass ? pass.length < 6 : false;
        this.appuser.data['password' + '_invalid'] = this.badpassword;
        this.appuser.data['confirmPassword' + '_invalid'] = this.badpassword;
    }

    public login(cren) {
        this.events.publish('setBackLoadingOn');
        cren['username'] = cren['email'];
        this.auth.login(cren)
            .subscribe(allowed => {
                    this.events.publish('setBackLoadingOff');

                    this.stopLoading();

                    if (allowed) {

                        // let registerMsg = this.business.data.GlobalSettingsData.registerDoneMessage;
                        // if (!registerMsg) {
                        //     registerMsg = this.translate.instant('welcome'), this.translate.instant('registrationSuccessfullyCompleted');
                        //     registerMsg += '\n' + this.translate.instant('accountNotValidateYet');
                        // }
                        // this.modelToolsProvider.textAlert(null, registerMsg);


                        ////console.log'user login in publish event!!');
                        // this.events.publish('user:login');
                        // this.mainService.userLogged()

                    } else {
                        this.modelToolsProvider.goTo('login', {'badLogin': true,});
                        ////console.log'false!')
                        //this.loading.dismiss();
                        // this.showError("Wrong credentials has been provided.");
                    }

                },
                error => {
                    // this.loading.dismiss();
                    // this.showError(error);
                });
    }


    // async presentLoading() {
    //     this.loadingPopover = await this.loadingController.create({
    //         cssClass: 'register-loading',
    //         message: this.translate.instant('loading')+'...',
    //     });
    //     await this.loadingPopover.present();
    //
    //     // const { role, data } = await loading.onDidDismiss();
    //     // //console.log'Loading dismissed!');
    // }

    stopLoading() {
        this.modelProvider.loadingOff();
        // this.loadingPopover.dismiss();
    }

    back() {
        this.navCtrl.pop();
    }
}




