import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {IonSlides} from "@ionic/angular";
import {PhotoViewer} from "@awesome-cordova-plugins/photo-viewer/ngx";
import {Events} from "../../../../../framework/providers/events";

@Component({
    selector: 'app-gallery-slider',
    templateUrl: './gallery-slider.page.html',
    styleUrls: ['./gallery-slider.page.scss'],
})
export class GallerySliderPage implements OnInit {

    @Input()
    public images;

    @Input()
    public index = 0;

    @Input()
    public allowChanges: boolean = true;

    @Input()
    public prefix: string = 'imageUploadPrefix_' + +Math.floor(Math.random() * Math.floor(1000000));

    @ViewChild(IonSlides, null) slides: IonSlides;

    constructor(public events: Events, private photoViewer: PhotoViewer) {
    }

    ngOnInit() {
        this.events.subscribe(this.prefix + '_doneUpload', (res) => {
            let me = this;
            setTimeout(function () {
                me.slideTo(me.images.length - 1, true);
            })
        })
    }

    ngAfterViewInit() {
        this.slideTo(this.index, true);
    }

    slideTo(key, toKey = false) {
        // let active = this.slides.getActiveIndex();
        // //console.logactive);
        // if(!toKey)
        // {
        //   key = active + key;
        // }
        // //console.logkey);
        // this.slides.slideTo(key);

        this.slides.getActiveIndex().then(index => {
            if (!toKey) {
                key = index + key;
            }

            this.slides.slideTo(key);
            // OR this.slides.slideTo(index + 1);
        });
    }

    removeImage() {
        this.slides.getActiveIndex().then(index => {
            this.images.splice(index, 1);
        });
    }


    showImage(image) {
        this.photoViewer.show(image);
    }

}
