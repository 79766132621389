// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.slide-btn {
  color: #fff;
  height: 93%;
  margin: 25px 7px;
  text-align: center;
  font-size: 17px;
  width: 0;
  height: 0;
}

.slide-btn.disabled {
  opacity: 0.3;
}

.slide-btn.left {
  border-top: 35px solid transparent;
  border-left: 16px solid #ac1f25;
  border-bottom: 35px solid transparent;
  margin-right: auto;
}

.slide-btn.right {
  border-top: 35px solid transparent;
  border-right: 16px solid #ac1f25;
  border-bottom: 35px solid transparent;
}

.slider-back.opacity {
  opacity: 0.8;
}

.swiper-wrapper {
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/framework/components/core/dynamic-slider/dynamic-slider.component.scss"],"names":[],"mappings":"AAAA;EAGE,WAAA;EACA,WAAA;EACA,gBAAA;EACA,kBAAA;EAEA,eAAA;EACA,QAAA;EACA,SAAA;AAFF;;AAKA;EACE,YAAA;AAFF;;AAKA;EACE,kCAAA;EACA,+BAAA;EACA,qCAAA;EACA,kBAAA;AAFF;;AAKA;EAEE,kCAAA;EACA,gCAAA;EACA,qCAAA;AAHF;;AAMA;EACE,YAAA;AAHF;;AAOA;EACE,uBAAA;AAJF","sourcesContent":[".slide-btn {\n  //border: 2px solid #fff;\n  //background: #ac1f25;\n  color: #fff;\n  height: 93%;\n  margin: 25px 7px;\n  text-align: center;\n  //padding: 50px 0px;\n  font-size: 17px;\n  width: 0;\n  height: 0;\n}\n\n.slide-btn.disabled {\n  opacity: 0.3;\n}\n\n.slide-btn.left {\n  border-top: 35px solid transparent;\n  border-left: 16px solid #ac1f25;\n  border-bottom: 35px solid transparent;\n  margin-right: auto;\n}\n\n.slide-btn.right {\n\n  border-top: 35px solid transparent;\n  border-right: 16px solid #ac1f25;\n  border-bottom: 35px solid transparent;\n}\n\n.slider-back.opacity {\n  opacity: 0.8;\n}\n\n\n.swiper-wrapper {\n  justify-content: center;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
