// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.results-box {
  position: absolute;
  z-index: 50;
  width: 100%;
  box-shadow: 0px 1px 1px 1px #ccc;
  background: #fff;
  max-height: 250px;
  overflow: scroll;
}`, "",{"version":3,"sources":["webpack://./src/framework/components/field/auto-complete/auto-complete.component.scss"],"names":[],"mappings":"AAAA;EAEI,kBAAA;EACA,WAAA;EACA,WAAA;EACA,gCAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;AAAJ","sourcesContent":[".results-box\n{\n    position: absolute;\n    z-index: 50;\n    width: 100%;\n    box-shadow: 0px 1px 1px 1px #ccc;\n    background: #fff;\n    max-height: 250px;\n    overflow: scroll;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
