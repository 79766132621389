import {Component, Input, OnInit} from '@angular/core';
import {NavController} from "@ionic/angular";
import {OrderService} from "../../../../services/OrderService";
import {BusinessService} from "../../../../services/BusinessService";
import {Events} from "../../../../../framework/providers/events";
import {AppUser} from "../../../../../framework/model/core/appuser/appuser";
import {Business} from "../../../../../framework/model/core/business/business";
import {ModelToolsProvider} from "../../../../../framework/providers/model-tools/model-tools";
import {AppSettings} from "../../../../conf/appSettings";

@Component({
    selector: 'cart-fab',
    templateUrl: './cart-fab.component.html',
    styleUrls: ['./cart-fab.component.scss'],
})
export class CartFabComponent implements OnInit {
    @Input()
    public avoidCartClick: boolean = false;

    @Input()
    public options: object = {};
    @Input()
    public vertical: string = 'bottom';

    @Input()
    public upperNeeded: boolean = false;

    public cartData: object;
    public settings: object;

    public appuser: AppUser;
    public business: Business;
    public sub_prefix: string = 'sub_' + Math.floor(Math.random() * Math.floor(1000000));

    public upper: boolean;

    constructor(public navCtrl: NavController, public orderService: OrderService,
                public events: Events, public businessService: BusinessService, public tools: ModelToolsProvider, public appSettings:AppSettings) {
        this.cartData = orderService.getCartData();
        this.business = businessService.getBusiness();

        this.settings = this.appSettings.getSettings('app');
    }

    ngOnInit() {
        this.upper = this.upperNeeded && this.businessService.getBusiness().data.GlobalSettingsData.tickets;
        this.appuser = this.orderService.getAppUser();

        this.events.subscribe('appuserChanged', (params) => {
            this.appuser = this.orderService.getAppUser();
        }, this.sub_prefix);
    }

    ngOnDestroy() {
        this.events.unsubscribe('appuserChanged', this.sub_prefix);
    }

    goTo(page, params = {}) {
        if (!this.avoidCartClick) this.tools.goTo(page, params);
    }

    sendWhatsappMessage() {
        let phone = this.business.data.GlobalSettingsData['supportCellphone'];
        if (this.appuser && this.appuser.data.companyAgent)
            phone = this.appuser.data.companyAgent['cellphone'];
        if(this.options['data'])
        {
            this.events.publish('shareViaWhatsapp', {'data': this.options['data'], 'phone':phone});
            return
        }

        let url = 'http://api.whatsapp.com/send?phone=' + phone;
        window.open(url, '_system', 'location=yes');
    }

}
