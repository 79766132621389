import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {MenuController, ModalController, NavController} from "@ionic/angular";
import {OrderService} from "../../services/OrderService";
import {AppUser} from "../../../framework/model/core/appuser/appuser";
import {BusinessService} from "../../services/BusinessService";
import {Business} from "../../../framework/model/core/business/business";
import {AppConfig} from "../../conf/main";
import {AppSettings} from "../../conf/appSettings";
import {ItemProvider} from "../../pages/item/providers/item-provider/item-provider.service";
import {PropsProvider} from "../../pages/item/providers/props-provider/props-provider.service";
import {Events} from "../../../framework/providers/events";
import {NavigationExtras, Router} from "@angular/router";
import {ModelToolsProvider} from "../../../framework/providers/model-tools/model-tools";
import {AppDataService} from "../../services/AppDataService";
import {AdminService} from "../../services/AdminService";

@Component({
    selector: 'app-footer',
    templateUrl: './app-footer.component.html',
    styleUrls: ['./app-footer.component.scss'],
})
export class AppFooterComponent implements OnInit {

    //row data: prefix, pageParams, title
    @Input()
    public rowButtonsData: Array<object>;
    @Input()
    public buttons: Array<object>;

    @Input()
    public modal: boolean = false;

    @Input()
    public backButton: boolean = true;

    public business: Business;
    public appuser: AppUser;

    @Input()
    backEvent: string;

    @Input()
    public page;

    showHeaderFooter = true;
    showTimeOut;

    //listen to showItemToolbar and set for display
    showItemToolbar;

    @Input()
    options = {};

    public sub_prefix: string = 'sub_' + Math.floor(Math.random() * Math.floor(1000000));

    // @ViewChild('inputId', {static: false}) inputEl: IonInput;

    constructor(public navCtrl: NavController, public modalController: ModalController, public events: Events,
                private menuCtrl: MenuController,
                public orderService: OrderService, public businessService: BusinessService, public appsettings: AppSettings,
                private changeRef: ChangeDetectorRef, public propsProvider: PropsProvider, public tools: ModelToolsProvider,
                public adminService: AdminService, private router: Router,
                public itemProvider: ItemProvider, public appdataService: AppDataService) {
        this.appuser = this.orderService.getAppUser();
    }

    start() {
        this.business = this.businessService.getBusiness();
        this.showHeaderFooter = !this.business.data.GlobalSettingsData['hide_app_toolbar']
        this.setButtons();
    }

    ngOnInit() {
        this.router.events.subscribe((event) => {
            let value = true;

            if(this.router.url.includes('main'))
                value = false;

            this.backButton = value;
        });

        this.start();
        // let me = this;
        // this.events.subscribe('onscroll_innerpage', params => {
        //     clearTimeout(this.showTimeOut);
        //     if (!params['on']) {
        //         this.showTimeOut = setTimeout(function () {
        //             me.showHeaderFooter = true;
        //         }, 100);
        //     } else {
        //         me.showHeaderFooter = false;
        //     }
        // });


        this.events.subscribe('businessSets', (params) => {
            this.start();
        });

        this.events.subscribe('appuserChanged', (params) => {
            this.appuser = this.orderService.getAppUser();
            this.setButtons();
        }, this.sub_prefix);

        this.events.subscribe('agentCompanyChanged', (params) => {
            this.appuser = this.orderService.getAppUser();
            this.setButtons();
        }, this.sub_prefix);

        this.events.subscribe('showItemToolbar', (params) => {
            this.showItemToolbar = true;
            this.options['showItemToolbar'] = this.showItemToolbar
            // console.log('open!');
        }, this.sub_prefix);

        this.events.subscribe('closeItemToolbar', (params) => {
            this.showItemToolbar = false;
            this.options['showItemToolbar'] = this.showItemToolbar
            // console.log('close!');
        }, this.sub_prefix);
    }


    // However, Ionic provides lifecycle hooks of its own that
    // will fire consistently during route navigation

    ionViewWillEnter() {
        // This method will be called every time the component is navigated to
        // On initialization, both ngOnInit and this method will be called

        console.log("LoginPage - ViewWillEnter")
    }

    ionViewWillLeave() {
        // This method will be called every time the component is navigated away from
        // It would be a good method to call cleanup code such as unsubscribing from observables

        console.log("LoginPage - ViewWillLeave")
    }

    ngOnDestroy() {
        console.log("FOOTER DESTROY")
        this.events.unsubscribe('appuserChanged', this.sub_prefix)
        this.events.unsubscribe('agentCompanyChanged', this.sub_prefix)
        this.events.unsubscribe('showItemToolbar', this.sub_prefix)
        this.events.unsubscribe('closeItemToolbar', this.sub_prefix);
    }

    setButtons() {

        let profile_btn = {
            'title': this.appuser && this.appuser.data ? this.appuser.data.name : 'login',//'myProfile',
            'icon': 'person',
            'secIcon': 'briefcase',
            'url': 'profile',

            // 'color': 'light',
            'colorAgent': 'warning'
        };

        let panels = this.appdataService.getDataByKey('app-toolbar');

        if (panels && panels.length) {
            this.buttons = [];
            panels.forEach(panel => {
                let button: any = {
                    'title': panel.title,
                    'icon': panel.icon,
                    'images': panel.images,
                    'cssClass': panel['options']['cssClass'] ? panel['options']['cssClass'] : '',
                    'order': panel.order,
                    'url': panel.page
                };

                if (button['url'] == 'profile') {
                    button = profile_btn;
                    button['images'] = panel.images;
                    button['icon'] = '';
                }

                this.buttons.push(button);
            });
        } else {
            // if (!this.buttons) {
            this.buttons = [
                {
                    // 'title': 'mainPage',
                    'icon': 'home',
                    'url': 'main'
                },
                // {
                //     'title': 'favorites',
                //     'icon': 'star',
                //     'url': 'favorites'
                // },
                {
                    // 'title': this.business.data.GlobalSettingsData.menuText ? this.business.data.GlobalSettingsData.menuText : 'store',
                    'icon': 'basket',
                    'url': 'store'
                },
                // {
                //     'title': 'myOrders',
                //     'icon': 'folder-open',
                //     'url': 'orders'
                // },
                profile_btn,
            ];
            // }
        }
        if (this.rowButtonsData) {
            for (let k of this.rowButtonsData) {
                this.buttons.push({
                    'title': k['title'],
                    'color': k['color'],
                    'secIcon': k['icon'],
                    'count': k['count'],
                    'hideSm': true,
                    'url': 'admin-model/' + k['prefix'],
                    'params': k['pageParams']
                });
            }
        }

        if (this.appuser) {
            // profile_btn['title'] = this.appuser.data.name;
            if (this.orderService.isAgent()) {
                let url = this.appuser.data.companyApproved ? 'client-page/' + this.appuser.data.companyId + '/1' : 'agent-clients';
                this.buttons.push({
                    'cssClass': 'button-b',
                    'title': this.appuser.data.companyName ? this.appuser.data.companyName.slice(0, 8) + '..' : '-',
                    'icon': 'briefcase',
                    'fill': 'solid',
                    'url': url,
                    'order': 111111
                })
            }
        }
        // this.appuser = this.orderService.getAppUser();
    }

    goTo(page, buttonData = null) {
        if (this.modal)
            this.close();

        if (page == 'main') {
            this.tools.goTo(page);
            return;
        }

        if (page == 'store' && AppConfig.bisPrefix == 'oneflow') {
            page = 'profile';
        } else if (page == 'store') {
            //on big screen open catalog page. store.
            if (this.businessService.screenSize[0] < 1000) {
                this.menuCtrl.enable(false, 'menu2');
                this.menuCtrl.enable(true, 'menuSection');
                this.menuCtrl.open('menuSection');
                return;
            }
        }

        let navigationExtras: NavigationExtras = {
            queryParams: buttonData ? buttonData['params'] : {}
        };

        this.tools.goTo(page, navigationExtras)

        // this.navCtrl.navigateForward(page, navigationExtras);
    }


    goBack() {
        if (this.modal)
            this.close();

        if (this.backEvent) {
            this.events.publish(this.backEvent);
            return;
        }

        this.tools.goBack();
    }


    close() {
        this.modalController.dismiss({
            'dismissed': true
        });
    }


    // addPropsToCart(key, index = null, props = null, comments = null) {
    //     if (!index) index = this.itemToolbarData['cartItem']['index'];
    //     if (!props) props = this.itemToolbarData['cartItem']['props'];
    //
    //     //console.logkey, index);
    //     this.itemProvider.addToCart(this.itemToolbarData['model'], key, false, false, true, props,
    //         null, null, this.itemToolbarData['cartItem']['price'], true, index, false, null, 0, {'comments': comments});
    // }

}
