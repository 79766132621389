import {Component, Input, OnInit} from '@angular/core';
import {NavController} from "@ionic/angular";
import {AdminService} from "../../../../../core/services/AdminService";

@Component({
    selector: 'app-moving-address-box',
    templateUrl: './moving-address-box.component.html',
    styleUrls: ['./moving-address-box.component.scss'],
})
export class MovingAddressBoxComponent implements OnInit {

    @Input()
    prefix;
    @Input()
    model;

    @Input()
    tableData;

    @Input()
    sectionName;

    @Input()
    exTableData;

    fieldTypes;

    constructor(public adminService: AdminService) {
    }

    ngOnInit() {
        //console.log'addres load!!')
        this.fieldTypes = this.adminService.getFieldsType(this.sectionName, this.exTableData);

        //console.logthis.model);
        //console.logthis.fieldTypes);
        //console.logthis.model['Coi-addressDetialsCoi']);
    }
}
