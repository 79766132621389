import {Component, OnInit} from '@angular/core';
import {NavController} from "@ionic/angular";
import {ActivatedRoute, NavigationExtras} from "@angular/router";
import {AuthService} from "../../../../../framework/providers/auth-service/auth-service";
import {ModelProvider} from "../../../../../framework/providers/modelProvider/ModelProvider";
import {ModelToolsProvider} from "../../../../../framework/providers/model-tools/model-tools";
import {TranslateService} from "@ngx-translate/core";
import {Events} from "../../../../../framework/providers/events";

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.page.html',
    styleUrls: ['./reset-password.page.scss'],
})
export class ResetPasswordPage implements OnInit {
    public credentials = {'password': '', 'confirmPassword': ''};
    public badPassword: boolean = false;

    public passpref: string;
    public email: string;

    public block: boolean = false;

    constructor(public events: Events, private auth: AuthService, public route: ActivatedRoute, public translate:TranslateService,
                public navCtrl: NavController, public modelProvider: ModelProvider, public modelTools:ModelToolsProvider) {
        this.route.queryParams.subscribe(params => {
            this.passpref = params['passpref'];
            this.email = params['email'];

            this.block = params['block'];
        });
    }

    ngOnInit() {
    }

    reset(cren) {
        this.badPassword = false;
        if (cren.password.length < 6) {
            this.badPassword = true;
            return
        }
        if (cren.password != cren.confirmPassword) {
            this.badPassword = true;
            return;
        }
        let passpref = this.passpref;
        this.modelProvider.customPost('resetPassword', {'passpref': passpref, 'pass': cren.password}).then((res) => {
            // this.navCtrl.navigateRoot('login')
            this.login({'email':this.email, 'password':cren.password});
        })
    }

    private login(cren) {
        if(!cren['email'] || cren['email'] == '') return;
        if(!cren['password'] || cren['password'] == '') return;

        this.events.publish('setBackLoadingOn');
        cren['username'] = cren['email'];
        this.auth.login(cren)
            .subscribe(allowed => {
                    this.events.publish('setBackLoadingOff');
                    if (allowed) {
                        ////console.log'user login in publish event!!');
                        // this.events.publish('user:login');
                        // this.navCtrl.navigateRoot('main');
                        this.modelTools.textAlert(this.translate.instant('yourPasswordHasBeenResetSuccessfully'))

                    } else {
                        ////console.log'false!')
                        //this.loading.dismiss();
                        // this.showError("Wrong credentials has been provided.");
                    }
                },
                error => {

                    // this.loading.dismiss();
                    // this.showError(error);
                });
    }



    goTo(page, params = {}) {
        // let navigationExtras: NavigationExtras = {
        //     queryParams: params
        // };
        // this.navCtrl.navigateForward(page, navigationExtras);
        this.modelTools.goTo(page, params);
    }

}
