// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cardit {
  background-image: none;
  --background: transparent;
}

.cardit ion-card-content {
  --background: transparent;
}`, "",{"version":3,"sources":["webpack://./src/app/shaps/components/admin-box/admin-box.component.scss"],"names":[],"mappings":"AAAA;EAEE,sBAAA;EACA,yBAAA;AAAF;;AAGA;EAEE,yBAAA;AADF","sourcesContent":[".cardit {\n  //--background: #eaeaea;\n  background-image: none;\n  --background: transparent;\n}\n\n.cardit ion-card-content {\n  //--background: #eaeaea;\n  --background: transparent;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
