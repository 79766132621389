// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cart-box {
  /* border: 2px solid #ccc; */
  /* border-radius: 0; */
  height: 100%;
  background: #fff;
  border-radius: 3px;
  border: 0px solid #e3e3e2;
  overflow: hidden;
}
.cart-box ion-content {
  padding: 0px;
}

.items-scroll::-webkit-scrollbar {
  display: none;
}

.cart-icon {
  font-size: 100px;
  color: #d2d2d2;
}

.price-item {
  --background: var(--button-a-background);
  --color: var(--button-a-text);
}`, "",{"version":3,"sources":["webpack://./src/core/pages/cart/components/quick-cart/quick-cart.component.scss"],"names":[],"mappings":"AAAA;EAEE,4BAAA;EACA,sBAAA;EAEA,YAAA;EAKA,gBAAA;EACA,kBAAA;EACA,yBAAA;EACA,gBAAA;AALF;AAOE;EAGE,YAAA;AAPJ;;AAYA;EACE,aAAA;AATF;;AAYA;EACE,gBAAA;EACA,cAAA;AATF;;AAiBA;EACE,wCAAA;EACA,6BAAA;AAdF","sourcesContent":[".cart-box {\n  //box-shadow: 0px 0px 8px #8c8c8c;\n  /* border: 2px solid #ccc; */\n  /* border-radius: 0; */\n  //margin: 8px;\n  height: 100%;\n  //min-height: 650px;\n\n  //box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%);\n\n  background: #fff;\n  border-radius: 3px;\n  border: 0px solid #e3e3e2;\n  overflow: hidden;\n\n  ion-content {\n    //overflow: scroll;\n    //max-height: 625px;\n    padding: 0px;\n    //height: 750px;\n  }\n}\n\n.items-scroll::-webkit-scrollbar {\n  display: none;\n}\n\n.cart-icon {\n  font-size: 100px;\n  color: #d2d2d2;\n  //padding-top: 50px;\n}\n\nion-list {\n  //margin-bottom: 50px;\n}\n\n.price-item{\n  --background: var(--button-a-background);\n  --color: var(--button-a-text);\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
