import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {BusinessService} from "../../services/BusinessService";
import {Events} from "../../../framework/providers/events";

@Component({
    selector: 'app-contact',
    templateUrl: './contact.page.html',
    styleUrls: ['./contact.page.scss'],
})
export class ContactPage implements OnInit {
    businessImages;

    constructor(public businessService:BusinessService, public events:Events) {
    }

    ngOnInit() {
        this.businessImages = this.businessService.getImages();
        this.events.subscribe('businessImagesSets', (params) => {
            this.businessImages = this.businessService.getImages();
        });
    }

}
