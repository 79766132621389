import {Component, Input, OnInit} from '@angular/core';
import {Events} from "../../../framework/providers/events";

@Component({
    selector: 'edit-field-toolbar',
    templateUrl: './edit-field-toolbar.component.html',
    styleUrls: ['./edit-field-toolbar.component.scss'],
})
export class EditFieldToolbarComponent implements OnInit {

    public editField = {
        'active': false,
        'data': null
    };

    @Input()
    public options = {};

    constructor(public events: Events) {
    }

    ngOnInit() {

        this.events.subscribe('viewEditFieldToolbar', (params) => {
            this.editField['data'] = params['data'];
            this.editField['prefix'] = params['prefix'];
            this.editField['currencyCode'] = params['currencyCode'];
            // this.editField['value'] = this.editField['data']['value'];

            this.initValue();

            this.editField['active'] = true;
            this.setVisible();

            // if(!this.inputEl) return
            // let me = this;
            // setTimeout(function () {
            //     let element = document.getElementById('inputId');
            //     element['setFocus']();
            // }, 100)
        });
    }

    addToValue(add)
    {
        let value = this.editField['data']['value'];
        if(!value || !(''+value).length){
            value = 0;
        }
        value = parseFloat(value)+ add;
        if(value < 0) value = 0;

        this.editField['data']['value'] = value;
    }

    initValue(force = true) {
        // if (this.editField['data']['type'] == 'number') {
        //     if(force)
        //         this.editField['value'] = this.editField['value'] && (this.editField['value']+'').length ? parseFloat(this.editField['value']) : null;
        //
        //     if(this.editField['data']['max'] && this.editField['value'] > this.editField['data']['max'])
        //     {
        //         this.editField['value'] = this.editField['data']['max'];
        //     }
        // }
    }

    editFieldChanged(ischanged = true, close=true) {
        // this.initValue(false);
        //
        // if (ischanged)
        //     this.editField['data']['value'] = this.editField['value'];
        // // console.log("CAHANGED!", this.editField)
        // this.events.publish(this.editField['prefix'], close);
        //
        // if(close) {
        //     this.editField['active'] = false;
        //     this.setVisible(false);
        // }
    }

    emptyField() {
        this.editField['data']['value'] = null;
        this.initValue();
    }

    setVisible(value = true)
    {
        this.options['visible'] = value;
    }
}
