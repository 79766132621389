import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {OrderService} from "../../services/OrderService";
import {MenuController, ModalController, NavController} from "@ionic/angular";
import {SearchPage} from "../../pages/search/search.page";
import {BusinessService} from "../../services/BusinessService";
import {BusinessImages} from "../../../framework/model/core/business/businessImages";
import {AppSettings} from "../../conf/appSettings";
import {Events} from "../../../framework/providers/events";
import {ActivatedRoute, NavigationEnd, NavigationError, NavigationStart, Router} from "@angular/router";
import {AppConfig} from "../../../core/conf/main";
import {ModelToolsProvider} from "../../../framework/providers/model-tools/model-tools";
import {MainService} from "../../services/MainService";
import {CartService} from "../../services/CartService";
import {AppDataService} from "../../services/AppDataService";
import {AdminService} from "../../services/AdminService";
import {Business} from "../../../framework/model/core/business/business";

@Component({
    selector: 'app-header',
    templateUrl: './app-header.component.html',
    styleUrls: ['./app-header.component.scss'],
})
export class AppHeaderComponent implements OnInit, OnDestroy {

    @Input()
    public title;
    public menuIndex = 1;

    public pageName: string = '';

    // @Input()
    public menu: boolean = true;
    public backButton: boolean = true;

    @Input()
    public modal: boolean = false; // ?

    @Input()
    public ismodal: boolean = false; // ?

    @Input()
    public showLogo: boolean = false;

    @Input()
    public closeButton: boolean = true;
    @Input()
    public modalCloseIcon: string = 'close';

    public cartData: object;

    public catalog: boolean = this.businessService.isCatalog();

    public business: Business;
    public businessImages: BusinessImages;

    public settings;
    public AppConfig = AppConfig;

    appuser;
    buttons;

    //section
    section;
    showSubSections = false;
    relatedSections = [];

    constructor(public orderService: OrderService, public navCtrl: NavController,
                public adminService: AdminService,
                private menuCtrl: MenuController,
                public appDataService: AppDataService,
                public events: Events, public modalController: ModalController, public tools: ModelToolsProvider,
                public businessService: BusinessService, public mainService: MainService, public cartService: CartService,
                public appSettings: AppSettings, private route: ActivatedRoute, private router: Router, public appdataService: AppDataService) {
        this.cartData = cartService.getCartData();
        this.settings = appSettings.getSettings('app');
        this.appuser = this.orderService.getAppUser();

        this.router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                //do something on start activity
            }

            if (event instanceof NavigationError) {
                // Handle error
                console.error(event.error);
            }
            this.section = null;
            if (event instanceof NavigationEnd) {
                this.setTitle();
            }
        });

        this.events.subscribe('setHeaderSection', (params) => {
            let me = this;
            this.section = null;
            let section;
            if (params['sectionId']) {
                let models = this.appDataService.getSections([params['sectionId']]);
                if (!models.length) return;
                section = models[0];
            } else {
                section = params['section'];
            }
            this.section = null;
            setTimeout(function () {
                me.section = section;
                me.showSubSections = true;
                me.relatedSections = me.appDataService.getSectionSubSections(me.section.data.sectionId);
                me.relatedSections = me.relatedSections.concat(me.appDataService.getSectionSubSections(me.section.data.id));
            })
        });

        this.events.subscribe('catalogChanged', (params) => {
            this.catalog = params['catalog'];
        });

        // this.events.subscribe('cartUpdated', (params) => {
        //     // this.cartData = orderService.getCartData();
        //     // //console.log'$$$', this.cartData);
        //
        // });

        this.businessImages = this.businessService.getImages();
        this.business = this.businessService.getBusiness();


        this.events.subscribe('businessSets', (params) => {
            this.business = this.businessService.getBusiness();
            this.start();
            this.initImages();
        });

        this.events.subscribe('businessImagesSets', (params) => {
            this.initImages()
        });

        this.events.subscribe('appuserChanged', (params) => {
            this.appuser = this.orderService.getAppUser();
            this.setNav();
        });

        this.events.subscribe('agentCompanyChanged', (params) => {
            this.appuser = this.orderService.getAppUser();
            console.log("agentCompanyChanged CHANGEDD!!!!", this.appuser.data.companyId)
            this.setNav();
        });
    }

    initImages() {
        this.businessImages = this.businessService.getImages();
        this.showLogo = this.businessImages.data['logo2'] != null;
    }


    ngOnInit() {
        this.start();
    }

    ngOnDestroy() {
        // this.events.unsubscribe('setHeaderSection');
    }

    start() {
        this.setTitle();
        this.setNav();
        this.appuser = this.orderService.getAppUser();
    }

    setNav() {
        let profile_btn = {
            // 'color': 'warning',
            'title': 'login',
            'icon': 'person',
            'secIcon': 'briefcase',
            'url': 'profile'
        };


        let panels = this.appdataService.getDataByKey('app-toolbar');

        if (panels && panels.length) {
            this.buttons = [];
            panels.forEach(panel => {
                let button: any = {
                    'images': panel.images,
                    'title': panel.title,
                    'icon': panel.icon,
                    'order': panel.order,
                    'url': panel.page
                };

                if (button['url'] == 'profile') {
                    button = profile_btn;
                    button['images'] = panel.images;
                    button['icon'] = '';
                }

                this.buttons.push(button);
            });
        } else {

            this.buttons = [
                {
                    'title': 'mainPage',
                    'icon': 'home',
                    'url': 'main'
                },
                {
                    'title': this.businessService.getBusiness().data.GlobalSettingsData.menuText ? this.businessService.getBusiness().data.GlobalSettingsData.menuText : 'store',
                    'icon': 'basket',
                    'url': 'store'
                },
            ]
            this.buttons.push(profile_btn)
        }

        if (this.appuser) {
            profile_btn['title'] = this.appuser.data.name;
            if (this.orderService.isAgent()) {
                let url = this.appuser.data.companyOurId != '-1' ? 'client-page/' + this.appuser.data.companyId + '/1' : 'agent-clients';
                this.buttons.push({
                    'title': this.appuser.data.companyName,
                    'icon': 'briefcase',
                    'cssClass': 'button-b ion-color',
                    'url': url
                })
            }
        }

    }

    setTitle() {
        if (this.ismodal) return;
        let url = this.router.url;
        this.pageName = url.split('/')[1];
        this.pageName = this.pageName.split('?')[0];
        this.title = this.pageName;
        this.backButton = this.pageName != 'main';

        if (this.title.includes('main')) {
            let bis = this.businessService.getBusiness();
            this.title = bis.data.GlobalSettingsData.title;
        }

        // console.log(this.title);
        // console.log(this.pageName);

    }

    openSectionsMenu() {
        this.menuCtrl.enable(true, 'menuSection');
        this.menuCtrl.enable(false, 'cartMenu');
        this.menuCtrl.open('menuSection');
    }

    goTo(page, params = {}) {
        // if (this.businessService.screenSize[0] < 2768 && page == 'cart') {
        //     let me = this;
        //     me.mainService.closeMenu('cartMenu', true, false);
        //     // me.menuCtrl.enable(false, 'menuSection');
        //     // this.menuCtrl.enable(true, 'cartMenu').then(() => {
        //     //     me.menuCtrl.open('cartMenu');
        //     // });
        //     return;
        // }

        if (this.modal)
            this.close();

        this.tools.goTo(page);
    }

    // goBack() {
    //     if(this.modal)
    //         this.close();
    //
    //     if (this.backEvent) {
    //         this.events.publish(this.backEvent);
    //         return;
    //     }
    //
    //     this.navCtrl.pop();
    // }

    showMenu() {
        if (this.menuIndex == 1) {
            this.menuIndex = 2;
            this.menuCtrl.close('menuSection');
            this.menuCtrl.enable(false, 'menuSection');

            this.menuCtrl.enable(true, 'menu2');
            this.menuCtrl.open('menu2');
        } else if (this.menuIndex == 2) {
            this.menuIndex = 1;
            this.menuCtrl.enable(true, 'menuSection')
            this.menuCtrl.enable(false, 'menu2');
        }
        // else
        // {
        //     this.menuIndex = 1;
        //     this.menuCtrl.close('menu2');
        //     this.menuCtrl.close('menuSection');
        // }

        // this.events.publish('showMenu');
    }

    async search() {
        if (this.modal) return;
        let me = this;
        const modal = await this.modalController.create({
            component: SearchPage,
            componentProps: {},
        });

        return await modal.present();
    }

    close() {
        this.modalController.dismiss({
            'dismissed': true
        });
        this.events.publish('closeItemToolbar')
    }

    goBack() {
        if (this.modal)
            this.close();

        this.tools.goBack();
    }
}
