import {Component, Input, OnInit} from '@angular/core';
import {AdminService} from "../../../../../core/services/AdminService";
import {BusinessService} from "../../../../../core/services/BusinessService";
import {MenuController} from "@ionic/angular";
import {MainService} from "../../../../../core/services/MainService";
import {ModelToolsProvider} from "../../../../../framework/providers/model-tools/model-tools";

@Component({
    selector: 'app-admin-page-menu',
    templateUrl: './admin-page-menu.component.html',
    styleUrls: ['./admin-page-menu.component.scss'],
})
export class AdminPageMenuComponent implements OnInit {

    @Input()
    navCtrl;

    currenctSection;
    currenctSectionName;

    constructor(public adminService: AdminService,
                public menu: MenuController,
                public adminTools:ModelToolsProvider,
                public mainService:MainService,
                public businessService: BusinessService) {

    }

    ngOnInit() {
        if (this.adminService.manageModels && this.adminService.manageModels['menuData']) {
            //init auto start
            this.currenctSection = this.adminService.manageModels['menuData'][0];
        }
    }

    changeSectionName(sectionName) {
        let me = this;
        this.currenctSectionName = null;
        setTimeout(function () {
            me.currenctSectionName = sectionName;
        })
    }

}
