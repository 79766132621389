import {Component, Input, OnInit} from '@angular/core';
import {AdminService} from "../../../../../core/services/AdminService";
import {Events} from "../../../../../framework/providers/events";

@Component({
    selector: 'app-column-options',
    templateUrl: './column-options.component.html',
    styleUrls: ['./column-options.component.scss'],
})
export class ColumnOptionsComponent implements OnInit {
    currentPage;

    @Input()
    navCtrl;

    @Input()
    sectionName;

    @Input()
    fieldType;

    csearchFilter;
    prefix;

    @Input()
    extraData = {};

    searchText;

    constructor(public adminService: AdminService, public events: Events) {
    }

    ngOnInit() {
        if (this.extraData && this.extraData['changeType'] == 'canFilter') {
            this.currentPage = 'changeField';
        }
        if (this.extraData && this.extraData['changeType'] == 'filterField') {
            this.currentPage = 'filterField';
        }
        if (this.extraData && this.extraData['prefix']) {
            this.prefix = this.extraData['prefix'];
        }

        this.fieldType['resmodel'] = {};
    }

    addFieldWidth(width, gap) {
        return parseInt(width) + gap;
    }

    //switch between two fields
    fieldRight(moveSize) {
        let fieldModel;
        let modelFields = this.adminService.manageModels[this.sectionName + 'ModelFields'];
        for (let f in modelFields) {
            if (modelFields[f]['name']['value'] == this.fieldType['name']) {
                fieldModel = modelFields[f];
                break;
            }
        }
        if (!fieldModel) return;

        fieldModel['order']['value'] += moveSize;
        let saveRow = [fieldModel];
        this.adminService.markUpdated(this.adminService.manageModels['modelFieldData'],
            'modelField', fieldModel['order'], fieldModel);
        this.adminService.checkSave(saveRow, 'modelField', {}, this.adminService.manageModels);

        let me = this;
        setTimeout(function () {
            me.orderby();
        }, 1500);
    }

    //switch between two fields
    changeField(fieldModel, removeFieldName, changeType) {
        fieldModel[changeType]['value'] = true;
        let saveRow = [fieldModel];

        let removeFieldModel;
        let modelFields = this.adminService.manageModels[this.sectionName + 'ModelFields'];
        for (let f in modelFields) {
            if (modelFields[f]['name']['value'] == removeFieldName) {
                removeFieldModel = modelFields[f];
                break;
            }
        }

        if (removeFieldModel) {
            removeFieldModel[changeType]['value'] = false;
            saveRow.push(removeFieldModel);
            this.adminService.markUpdated(this.adminService.manageModels['modelFieldData'],
                'modelField', removeFieldModel[changeType], removeFieldModel);
        }

        if (changeType == 'showTable') {
            let order = removeFieldModel['order']['value']
            removeFieldModel['order']['value'] = fieldModel['order']['value'];
            fieldModel['order']['value'] = order;
            this.adminService.markUpdated(this.adminService.manageModels['modelFieldData'],
                'modelField', removeFieldModel['order'], removeFieldModel);
            this.adminService.markUpdated(this.adminService.manageModels['modelFieldData'],
                'modelField', fieldModel['order'], fieldModel);
        }

        // fieldModel[fieldType['name']][changeType] = false;

        this.adminService.markUpdated(this.adminService.manageModels['modelFieldData'],
            'modelField', fieldModel[changeType], fieldModel);
        this.adminService.checkSave(saveRow, 'modelField', {}, this.adminService.manageModels);
        this.refresh();
    }

    orderby(desc = '', field = null) {
        this.events.publish("btnclick_" + this.prefix,
            {'key': 'addFilter', 'data': {'orderBy': desc + (field ? field : this.fieldType['name'])}});
    }

    filterit(filters) {
        this.events.publish("btnclick_" + this.prefix, {
            'key': 'addFilter',
            'data': {'fieldFilters': filters}
        });
    }

    buttonClick(key, data = null) {
        this.events.publish("btnclick_" + this.prefix, {'key': key, 'data': data});
        this.events.publish("btnclickAdmin_" + this.prefix, {'key': key, 'data': data});
    }

    hideFromTable(changeType) {
        // let changeType = 'showTable';
        this.fieldType[changeType] = !!!this.fieldType[changeType];
        let fieldModel;
        let modelFields = this.adminService.manageModels[this.sectionName + 'ModelFields'];
        for (let f in modelFields) {
            if (modelFields[f]['name']['value'] == this.fieldType['name']) {
                fieldModel = modelFields[f];
                break;
            }
        }
        if (fieldModel) {
            fieldModel[changeType]['value'] = this.fieldType[changeType];
            let saveRow = [fieldModel];

            this.adminService.markUpdated(this.adminService.manageModels['modelFieldData'],
                'modelField', fieldModel[changeType], fieldModel);
            this.adminService.checkSave(saveRow, 'modelField', {}, this.adminService.manageModels);

            this.refresh();
        }
    }

    refresh() {
        let me = this;
        setTimeout(function () {
            me.events.publish("refresh_request_" + me.prefix, {'loadColumns': true});
        }, 500);
    }
}
