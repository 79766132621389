import {ChangeDetectorRef, Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router} from "@angular/router";
import {AuthService} from "../../framework/providers/auth-service/auth-service";

import {BusinessService} from "./BusinessService";
import {AppConfig} from "../conf/main";
import {AppSettings} from "../conf/appSettings";
import {MainService} from "./MainService";

@Injectable({
    providedIn: "root"
})
export class AuthGuardService implements CanActivate {
    constructor(private router: Router,
                public authService: AuthService, public mainService: MainService, public appSettings: AppSettings) {
    }

    canActivate(route: ActivatedRouteSnapshot): boolean {
        let authInfo = {
            authenticated: this.authService.hasUserLoggedIn()
        };


        if (!authInfo.authenticated) {
            this.mainService.handelLogin();

            // let settings = this.appSettings.getSettings('login')
            // if(settings['type'] == 'modal')
            // {
            //   this.mainService.showRegisterModal();
            //   return false;
            // }
            //
            // this.router.navigate(["login"]);
            return false;
        }

        return true;
    }
}








