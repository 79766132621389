import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {NavController, PopoverController} from "@ionic/angular";
import {ModelProvider} from "../../../../framework/providers/modelProvider/ModelProvider";
import {AppConfig} from "../../../../core/conf/main";
import {NavigationExtras} from "@angular/router";
import {AdminService} from "../../../../core/services/AdminService";
import {BusinessService} from "../../../../core/services/BusinessService";
import {SelectBarComponent} from "../select-bar/select-bar.component";
import {Events} from "../../../../framework/providers/events";

@Component({
    selector: 'app-view-box',
    templateUrl: './view-box.component.html',
    styleUrls: ['./view-box.component.scss'],
})
export class ViewBoxComponent implements OnInit, OnDestroy {
    public appConfig = AppConfig;

    @Input()
    tabIndex = 0;
    wizardTabs = ['job', 'typeOfservice', 'estType', 'customer', 'fromAddress', 'toAddress', 'fromAddress1', 'toAddress1', 'distance', 'notes', 'inTrips'];

    @Input()
    miniViewOpen = true;
    @Input()
    exTableData;
    @Input()
    sectionName;
    @Input()
    filterModel;
    @Input()
    errors = {};
    @Input()
    editMode = false;
    @Input()
    prefix;
    @Input()
    topPrefix;//top page modeldata for open select options choose
    @Input()
    initRefresh = false;
    @Input()
    model;
    modelType;
    modelTypeByName;
    @Input()
    tableParams;
    @Input()
    viewOnly = false;
    @Input()
    miniView = false;
    @Input()
    colsize;
    @Input()
    onMainPage = false;
    @Input()
    showOkButtons = true;
    @Input()
    tabs;
    @Input()
    tabsOpen;
    @Input()
    rowClickEvent;
    @Input()
    maxFields = 1000;

    //hide top header - on top of viewbox
    @Input()
    hideTabs = true;
    @Input()
    hideHeader = true;
    @Input()
    hideModelOptions = true;

    @Input()
    specificFields;
    @Input()
    specificTab;

    buttonFields;
    mainTabsSizes;
    tableData;
    buttonTabs;

    tableDataField;
    @Input()
    activeTab;
    activeTabIndex = 0;
    totalTabs = 0;

    modelIns;
    tabsData;
    subTabsData;
    tabKeys;
    showMapAddress;

    selectTablePrefix;
    showSelectTableData;
    showSelectTableKey;
    autoShow;//hide background when select table mode

    subTableCurrentFiled;
    currentEditField;

    ready = false;
    showLoading = false;
    hiddenSubTabs = {};

    constructor(public businessService: BusinessService,
                public popoverCtrl: PopoverController,
                public events: Events, public modelProvider: ModelProvider, public navCtrl: NavController, public adminService: AdminService) {
        this.selectTablePrefix = 'oneTableRowClick_' + Math.floor(Math.random() * Math.floor(1000000));
    }

    //
    //*** refresh for full model on selectBar component.
    //
    ngOnInit() {
        //listen to all fields - selected table type
        // for type select table fields - catch selection of external table.
        this.tableData = this.exTableData[this.sectionName + 'Data'];
        this.modelType = this.exTableData[this.sectionName + 'Ref'];
        this.modelTypeByName = this.adminService.getFieldsType(this.sectionName, this.exTableData);

        if (this.initRefresh) {
            this.filteritFullModel();
        } else if (this.model) {
            this.start();
        }
        if (this.tableData['showSubTabs']) {
            this.hiddenSubTabs = this.tableData['showSubTabs'];
        }
        if (!this.tableData['showModelOptions']) {
            this.hideModelOptions = true;
        }
        // [''] = true;

        // //////console.logthis.tableData);
        // if (this.model && this.model.length > 1 && (this.viewOnly || this.miniView) && !this.initRefresh) {
        //     this.start();
        //
        //     //check if need to auto open tables
        //     // if (!this.filterModel) {
        //     //     this.filteritFullModel(tableFields);
        //     // }
        // } else {
        //     //refresh with full model data;
        //     this.filteritFullModel();
        // }

        //on edit from view-field component click
        // this.events.subscribe('openTableModelOptions' + this.prefix, (data) => {
        ////console.logdata);
        // this.selectModel(data['model'], data['colIndex'], data['fieldData']);
        // });

        this.buttonFields = [];
        for (let key in this.modelType) {
            if (this.modelType[key]['showOnTopModelPage']) {
                this.buttonFields.push(this.modelType[key])
            }
        }

        let me = this;
        this.events.subscribe('changeTab' + this.prefix, (data) => {
            me.changeTab(data['key']);
        });

        this.events.subscribe('globalSaveClick', () => {
            this.adminService.checkSave([this.model], this.sectionName, {}, this.exTableData,
                false, {}, null, null, null);
        }, this.prefix);
    }

    start() {

        this.modelIns = {'data': {'id': this.model['id'].value, 'images': []}, 'prefix': this.sectionName};
        this.modelIns['images'] = [];

        if (this.tableData['tabsOpen']) {
            this.tabsOpen = true;
        }
        if (this.tableData['hiddenSubTabs']) {
            this.hiddenSubTabs = this.tableData['hiddenSubTabs'];
        }
        this.loadTabs();


        let me = this;
        // this.ready = false;
        // setTimeout(function () {
        me.ready = true;


        // });

    }

    editTab(subTab, tabFields) {
        for (let fieldData of tabFields) {
            if (!fieldData['readOnly'] && fieldData['startSection'] == subTab) {
                this.model[fieldData['name']]['editMode'] = !!!this.model[fieldData['name']]['editMode'];
                console.log('found ', fieldData)
            }
        }
    }

    //// //////console.log'filter auto request ');
    //                 let tableFields = [];
    //                 for (let key in this.model) {
    //                     if (this.model[key]['autoRequest']) {
    //
    //                         // //////console.log'filter auto request ' + this.model[key]['name'])
    //                         tableFields.push(this.model[key]);
    //                     }
    //                 }

    loadTabs() {
        // ////console.log'loadTabs:');
        // ////console.log'load tabs: ', this.sectionName);

        //show only 'basic' tab on this views:
        if (this.miniView || this.specificFields) {
            this.tabKeys = ['basic'];
            let tabsData = [];

            // //////console.logthis.specificFields);
            // let maxFields = (this.maxFields > this.model.length) ? this.model.length : this.maxFields;
            for (let fieldKey = 0; fieldKey < this.modelType.length; fieldKey++) {

                if (this.specificFields && this.specificFields.length > 0) {
                    if (this.specificFields && this.specificFields.length == 1) {
                        tabsData.push(this.modelType[fieldKey]);
                    } else if (this.specificFields.indexOf(this.modelType[fieldKey]["name"]) != -1) {
                        tabsData.push(this.modelType[fieldKey]);
                        this.modelType[fieldKey]['startSection'] = '';
                    }
                    // else if (!this.modelType[fieldKey]['showInBox'] && this.specificFields.length > 1) continue;
                } else if (this.modelType[fieldKey]['showInBox']) {
                    this.modelType[fieldKey]['startSection'] = '';
                    tabsData.push(this.modelType[fieldKey]);
                }

                if (tabsData.length == this.maxFields) {
                    break;
                }
            }
            tabsData.sort(function (a, b) {
                return b['order'] - a['order'];
            })

            // //console.log'miniviwe: ', this.sectionName);
            // //console.logtabsData);

            this.activeTab = null;
            this.tabsData = [tabsData];
            ////console.logtabsData);

            //if specific one field and it is a select table type - than auto open.
            if (this.specificFields && this.specificFields.length == 1 && tabsData[0] &&
                (tabsData[0]['type'] == 'selectTable' || tabsData[0]['type'] == 'selectOptions')) {
                this.autoShow = true;

                //need to fixxxx1

                this.showSelectTableKey = tabsData[0]['name'];
                this.showSelectTableData = tabsData[0]; //showSelectTableData - field data
            }
        }

        //tabs
        if (!this.tabs) {
            let tabKeys;
            tabKeys = this.exTableData[this.sectionName + 'Data']['tabs'];
            ////console.logtabKeys);
            if (!tabKeys) tabKeys = ['-'];
            this.tabKeys = tabKeys;
        } else {
            this.tabKeys = this.tabs;
        }

        let t = [];
        for (let tabkey of this.tabKeys) {
            if (tabkey != '0') t.push(tabkey);
        }
        this.tabKeys = t;

        let tabsData = {};
        for (let tabkey of this.tabKeys) {
            tabsData[tabkey.toString()] = [];
        }
        // insert fields in to their tab
        for (let fieldKey in this.modelType) {
            // if (this.specificFields && this.specificFields.indexOf(this.model[fieldKey]["name"]) == -1) {
            //     continue;
            // }
            if (!this.modelType[fieldKey]['tabIndex']) continue;

            let tabName = this.modelType[fieldKey]['tabIndex'].toString();

            if (!tabsData[tabName]) continue;
            tabsData[tabName].push(this.modelType[fieldKey]);
        }

        //tabs
        if (!this.activeTab) {
            this.activeTabIndex = 0;
        }

        // this.activeTab = '';
        this.totalTabs = 1;
        if (this.exTableData[this.sectionName + 'Data']['tabs']) {
            // //////console.logthis.exTableData[this.sectionName + 'Data']);
            // this.activeTab = this.exTableData[this.sectionName + 'Data']['tabs'][this.activeTabIndex];
            this.totalTabs = this.exTableData[this.sectionName + 'Data']['tabs'].length;
        }

        if (this.specificTab) {
            this.activeTab = this.specificTab;
            this.tabKeys = [this.specificTab];
            console.log(this.specificTab);
        }

        let tabsArr = [];
        for (let key of this.tabKeys) {
            if (tabsData[key]) {
                tabsData[key].sort(function (a, b) {
                    return b['order'] - a['order'];
                });

                tabsArr.push(tabsData[key]);
            }
        }

        this.tabsData = tabsArr;

        //console.log"this.tabKeys:");
        //////console.logtabsData);
        //console.logthis.tabKeys);
        //console.logthis.tableData['allHideTab']);

        // this.activeTab = null;
        if (!this.tabsOpen && !this.activeTab) {
            this.activeTab = this.tabKeys[0];
        }

        if (!this.tableData['buttonTabs']) {
            this.buttonTabs = this.tabKeys;
        } else {
            this.buttonTabs = this.tableData['buttonTabs'];
        }

        this.tabSizesSet();

        //sub Tabs
        //sub Tabs
        let subTabs;
        let subTabsData = {};
        let i = 0;
        let fkey;
        for (let tabKey in this.tabsData) {
            subTabs = this.tableData['subTabs'][i].split(',');
            if (!subTabsData[i]) subTabsData[i] = [];
            for (let k in subTabs) {
                fkey = subTabs[k];
                for (let fieldData of this.tabsData[tabKey]) {
                    if (fieldData['startSection'] == fkey) {
                        if (!subTabsData[i][fkey]) subTabsData[i][fkey] = [];
                        subTabsData[i][fkey].push(fieldData);
                    }
                }
            }
            i++;
        }
        this.subTabsData = subTabsData;
        // console.log(subTabsData)
    }

    changeTabSize(tabIndex, add = true) {
        //console.logthis.mainTabsSizes);
        //console.logtabIndex);

        for (let key of ['lg', 'xs', 'md', 're']) {

            let i = 0;
            for (let tabFields in this.tabsData) {
                let size = this.mainTabsSizes[key][i];

                if (i == tabIndex) {
                    size += add ? 2 : -2;
                } else if (Math.abs(i - tabIndex) == 1)//if it next to this tab. left or right - -1 or 1
                {
                    size += add ? -1 : 1;
                }
                this.mainTabsSizes[key][i] = size;

                i++;
            }
        }
    }

    //calculate tab sizes
    tabSizesSet() {
        let tabsData = this.tabsData;
        let colsize = this.colsize;
        let activeTab = this.activeTab;
        let tableData = this.tableData;

        // console.log('calc tab sizes:')
        // console.log(tabsData);
        // console.log(this.tableData['tabsData']);

        let mainTabsSizes = {'lg': [], 'xs': [], 'md': [], 're': []};
        let tabsSizes = [];
        let i = 0;


        //loop for every tab add index to size array. tab data is not used in the loop.
        for (let tabFields in tabsData) {
            let tabsSizes = null;
            if (this.tableData['tabsData'] && this.tableData['tabsData'][this.tabKeys[i]]) {
                if (this.tableData['tabsData'][this.tabKeys[i]]['tabSize'])
                    tabsSizes = this.tableData['tabsData'][this.tabKeys[i]]
            }

            let size1 = colsize ? colsize : activeTab ? 12 : tableData['topTabSize'][i] ? tableData['topTabSize'][i] : tableData['tabsOpen'] ? 6 : 12;
            if (tabsSizes) size1 = tabsSizes['tabSize'].split(',')[0];
            mainTabsSizes['re'].push(parseInt(size1));

            size1 = this.colsize ? this.colsize : 12;
            if (tabsSizes) size1 = tabsSizes['tabSize'].split(',')[1];
            mainTabsSizes['xs'].push(parseInt(size1));

            size1 = 12
            if (tabsSizes) size1 = tabsSizes['tabSize'].split(',')[2];
            mainTabsSizes['md'].push(parseInt(size1));

            size1 = 12
            if (tabsSizes) size1 = tabsSizes['tabSize'].split(',')[3];
            mainTabsSizes['lg'].push(parseInt(size1));

            i++;
        }
        this.mainTabsSizes = mainTabsSizes;
    }

    ngOnDestroy(): void {
        this.events.unsubscribe(this.selectTablePrefix);
        this.events.unsubscribe('globalSaveClick');
    }

    backTab() {
        this.activeTabIndex--;
        this.activeTab = this.exTableData[this.sectionName + 'Data']['tabs'][this.activeTabIndex];
    }

    done() {
        this.events.publish("formFillIsDon_" + this.prefix);
    }

    forwardTab() {
        this.activeTabIndex++;
        this.activeTab = this.exTableData[this.sectionName + 'Data']['tabs'][this.activeTabIndex];
    }

    changeOptionsField(fieldData, key) {
        if (fieldData['chooseOneOnly']) {
            fieldData['optionsActive'].splice(0);
        }

        if (fieldData['optionsActive'].indexOf(key) !== -1) {
            fieldData['optionsActive'].splice(fieldData['optionsActive'].indexOf(key), 1);
        } else {
            fieldData['optionsActive'].push(key);
        }
        fieldData['value'] = fieldData['optionsActive'].join(',')
    }

    removeModel() {
        let me = this;
        this.adminService.removeModels(this.sectionName, this.model, function () {
        });

        // model.removed = true;
        // this.events.publish('markmodel' + this.prefix, {
        //     sectionKey: this.sectionName,
        //     markType: 'removeIds', id: model[0].value
        // });
    }

    //change taarif..
    filterit(modeldata) {
        // //////console.logmodeldata);
        // let filters = {};
        // filters[modeldata['filterkey']] = modeldata['value'];//tarrif only
        // filters[this.sectionName + '_' + modeldata['filterkey']] = modeldata['value'];//rest..
        // filters['sectionName'] = this.sectionName;
        // //////console.logfilters);

        let filters = {};
        filters['sectionName'] = this.sectionName;
        filters['search_in_field'] = true;
        filters['searchField'] = modeldata['filterkey'];
        filters['searchFieldValue'] = modeldata['value'];

        this.events.publish('table_refresh_request_' + this.prefix, filters);
    }

    //<!--[
    // baseFilter]="{'search_in_field' : model[0]['value'],-->
    // <!--'searchField':fieldData['forigenKey'],-->
    // <!--'searchFieldValue':model[0]['value']}"-->
    openRowData(fieldData, searchFieldKey = null) {
        if (!searchFieldKey) {
            searchFieldKey = fieldData['forigenKey'];
        }

        let baseFilter = {
            'search_in_field': this.model[0]['value'],
            'searchField': searchFieldKey,
            'searchFieldValue': this.model[0]['value'],
        };

        //'rowIndex': rowIndex,
        let params = {
            'sectionName': fieldData['model'],
            'baseFilter': baseFilter,
            'rowId': fieldData['value']
        };

        let navigationExtras: NavigationExtras = {
            queryParams: params
        };

        this.navCtrl.navigateForward('admin-model/' + fieldData['model'] + this.model[0]['value'], navigationExtras);
    }

    //sub models data
    filteritFullModel(force = false) {
        // for (let fieldData of fieldsData) {
        //     //close table
        //     // if ((fieldData['data'] && fieldData['data'].length > 1) || !fieldData['topModelId']) {
        //     if (fieldData['isOpen']) {
        //         fieldData['isOpen'] = false;
        //         //////console.log'break no:');
        //         return
        //     }
        //     fieldData['isOpen'] = true;
        //
        //     //////console.log'1')
        //
        //     //commit - tmp
        //     // this.events.publish('addEditedSections' + this.prefix, {'key': fieldData['model']});
        //
        //     //hide table and re-add it
        //     // fieldData['tableRefresh'] = true;
        // }

        let filters;
        let me = this;

        let sectionName = this.sectionName;


        // let res = this.adminService.getResultById(this.sectionName, this.model[0]['value']);
        // if (res && !force) {
        //     ////console.log'cache result for ', this.sectionName, ' - ', this.model[0]['value']);
        //     this.onResultList(res);
        // } else {

        filters = {'type': 'moving', 'name': 'taarif'};

        filters['sectionName'] = sectionName;
        filters['getId'] = this.model['id']['value'];//fieldData['topModelId'];
        // filters[fieldData['filterkey']] = this.model[0]['value'];//fieldData['topModelId'];
        // this.ready = false;
        me.showLoading = true;
        this.modelProvider.list('report', filters)
            .then(function (data: any) {
                me.adminService.setResultById(me.sectionName, me.model['id']['value'], data);
                me.onResultList(data);

                me.showLoading = false;
            });

        // }
    }

    onResultList(data) {
        let me = this;

        if (!data['models']) {
            ////console.log('error in server request!');
            return;
        }

        // ////console.log(data['models'][0].data['finalResReport'][sectionName])
        let res = data['models'][0].data['finalResReport'];
        // //console.log('res!!!!');
        // //console.log(res);
        let items = res[this.sectionName];

        //if sent only [{'value':0}]
        // if (!me.model || me.model.length <= 1) {
        //     me.model = items[1];
        // }

        // me.model = items[0];
        for (let k in items[0]) {
            me.model[k] = items[0][k];
        }
        // me.model.splice(0);
        //console.log(items[1]);

        // for (let key in me.modelType) // skip -1 id - 2 is me.
        // {
        //     let fieldData = me.modelType[key];
        // me.model.push(fieldData);

        //add table data of table fields.
        // if (fieldData.type == 'table') {
        //     me.exTableData[fieldData['model'] + 'Data'] = res[fieldData['model'] + 'Data'];
        // }
        // }
        // me.tableData = res[me.sectionName + 'Data'];
        // me.exTableData[me.sectionName + 'Data'] = me.tableData;
        // me.model['main']['updated'] = true;
        me.start();
        // me.events.publish('update_showRowModel_' + me.prefix, me.model);

        // this.showSubTables(this.model);
    }

    showSubTables(model) {
        let me = this;
        console.log('set interval');
        setTimeout(function () {
            console.log('run');
            for (let i in model) {
                console.log(model[i]);
                if (model[i]['type'] == 'table' && !model[i]['showMe']) {
                    console.log('found!!');
                    model[i]['showMe'] = true;

                    // me.showSubTables(model);
                    // break;
                }
            }

        }, 1000);
    }

    openPage(fieldData) {
        let navigationExtras: NavigationExtras = {
            queryParams: {'id': fieldData['value'], 'manager': 1}
        };
        this.navCtrl.navigateForward(fieldData['pagePrefix'], navigationExtras);
    }

    // equations tags
    addTagToField(tag, fieldData) {
        if (!fieldData['value']) fieldData['value'] = '';

        fieldData['value'] += "[" + tag + "]";
    }

    //
    callFieldEvent(fieldData) {
        ////console.log(fieldData['event']);
        this.events.publish(fieldData['event'], {'model': this.model});
    }

    parseInt(v) {
        //js function - with no this.
        return parseInt(v);
    }

    //fieldData -> it's fieldType..
    setFieldEdit(fieldData, $event) {
        return;
        console.log('setFieldEdit!!!!');

        //fieldData -> it's fieldType..

        if (fieldData['tabLink']) {
            this.changeTab(fieldData['tabLink']);
        }

        if (fieldData['readOnly']) {
            return;
        }

        this.model[fieldData['name']]['editMode'] = true;

        this.model['main']['updated'] = true;
        this.tableData['edited'] = true;
        fieldData['edited'] = true;

        // this.fieldClick(fieldData);

        if (this.currentEditField) {
            this.currentEditField['editMode'] = false;
            this.model[this.currentEditField['name']]['editMode'] = false;
        }


        this.currentEditField = fieldData;
        this.currentEditField['editMode'] = true;
        this.model[this.currentEditField['name']]['editMode'] = true;

        // if (fieldData['type'] == 'selectTable') {
        //     //console.log("selectFieldPopup" + this.topPrefix);
        //     this.events.publish("selectFieldPopup" + this.topPrefix,
        //         {
        //             'selectTablePrefix': this.selectTablePrefix,
        //             'fieldData': fieldData, 'tableData': this.tableData, 'sectionName': this.sectionName
        //         });
        // }

        if (this.editMode) {
            $event.stopPropagation();
        }

    }

    restartScreen() {
        this.filteritFullModel(true);
    }

    openAdminModel() {
        let sectionName = this.sectionName;
        let id = this.model[0]['value'];

        let params = {
            'sectionName': this.sectionName,
            'baseFilter': {
                'searchFieldValue': sectionName,
                'searchField': 'id',
                'search_in_field': true
            },
            'rowId': id,
            // 'rowIndex': 1 //skip first row -1
        };

        let navigationExtras: NavigationExtras = {
            queryParams: params
        };
        this.navCtrl.navigateForward('admin-model/' + sectionName + id, navigationExtras);
    }


    fieldClick(myEvent, fieldType, model) {
        let fieldData = this.model[fieldType['name']];

        this.adminService.fieldClick(myEvent, fieldData, fieldType, model,
            this.sectionName, this.exTableData[this.sectionName + 'Data'], this.prefix, this.navCtrl, this.exTableData);

        return

        if (fieldType['tabLink']) {
            this.changeTab(fieldType['tabLink']);
            return
        }

        if (fieldType['readOnly']) {
            return;
        }

        //if field is table.
        if (fieldType['type'] == 'table') {
            return;
        }

        fieldData['edited'] = true;
        this.model['main']['updated'] = true;
        fieldData['editMode'] = true;
    }

    // async popupEditBox(myEvent, model, type = 'modelTableEdit', bigPopup = true,
    //                    fieldData = null, fieldType = null) {
    //     if (fieldData) {
    //         fieldData['fieldName'] = fieldType ? fieldType['name'] : null;
    //         fieldData['modelId'] = model['id']['value'];
    //         fieldData['topModelSection'] = this.sectionName;
    //     }
    //
    //     if (fieldData && fieldData['type'] != 'selectTable' && fieldData['type'] != 'table') {
    //         type = 'editField';
    //     }
    //     let d = {
    //         'model': model,
    //         'prefix': this.prefix,
    //         'sectionName': this.sectionName,
    //         'fieldData': fieldData,
    //         'fieldType': fieldType,
    //         'exTableData': this.exTableData,
    //         'avoidSave': false,
    //         'type': type
    //     };
    //     this.adminService.popupEditBoxByData(myEvent, d, bigPopup);
    // }

    changeTab(key) {
        let me = this;
        if (me.activeTab == key) {
            me.activeTab = null;
            return
        }

        me.activeTab = null;
        setTimeout(function () {
            me.activeTab = me.activeTab == key ? null : key;
        }, 0);
        this.activeTabIndex = this.buttonTabs.indexOf(key);
    }

    async presentSelectBar(myEvent, type) {
        console.log(this.exTableData);

        const popover = await this.popoverCtrl.create({
            component: SelectBarComponent,
            componentProps: {
                'prefix': this.prefix,
                'tabsData': this.tabsData,
                'model': this.model,
                'exTableData': this.exTableData,
                'sectionName': this.sectionName,
                'type': type
            },
            event: myEvent,
            animated: true,
            showBackdrop: true,
            cssClass: 'note-popover icenter-popover smaller-popover'
        });
        return await popover.present();
    }

    buttonClick(key, data = null) {
        this.events.publish("btnclick_" + this.prefix, {'key': key, 'data': data});
        this.events.publish("btnclickAdmin_" + this.prefix, {'key': key, 'data': data});
    }

    getRand(from, to) {
        return from + Math.floor(Math.random() * Math.floor(to))
    }

}
