import {Component, Input, OnInit} from '@angular/core';
import {Item} from "../../../framework/model/core/items/item";
import {AppUser} from "../../../framework/model/core/appuser/appuser";
import {BandObligoChecks} from "../../../framework/model/core/bandObligoChecks/bandObligoChecks";
import {OrderService} from "../../services/OrderService";
import {Events} from "../../../framework/providers/events";
import {ModelProvider} from "../../../framework/providers/modelProvider/ModelProvider";
import {ModelClasses} from "../../model/modelClasses";
import {ModelToolsProvider} from "../../../framework/providers/model-tools/model-tools";
import {ActivatedRoute} from "@angular/router";


@Component({
    selector: 'app-client-invoice',
    templateUrl: './client-invoice.page.html',
    styleUrls: ['./client-invoice.page.scss'],
})
export class ClientInvoicePage implements OnInit {
    public modelId;

    constructor(public orderService: OrderService, public events: Events, public modelProvider: ModelProvider, public modelClasses: ModelClasses,
                public tools:ModelToolsProvider, public route: ActivatedRoute) {
        this.modelId = this.route.snapshot.paramMap.get("id");

        if(!this.modelId) {
            let appuser = this.orderService.getAppUser()
            this.modelId = appuser ? appuser.data.companyId : null;
        }
    }

    ngOnInit() {
    }
}
