import {Component} from '@angular/core';
import {Events} from "../../../framework/providers/events";
import {NavController, PopoverController} from "@ionic/angular";
import {BusinessService} from "../../../core/services/BusinessService";
import {OrderService} from "../../../core/services/OrderService";
import {ModelProvider} from "../../../framework/providers/modelProvider/ModelProvider";
import {AppDataService} from "../../../core/services/AppDataService";
import {ModelToolsProvider} from "../../../framework/providers/model-tools/model-tools";
import {AdminService} from "../../../core/services/AdminService";
import {ActivatedRoute, NavigationExtras} from "@angular/router";
import {MainService} from "../../../core/services/MainService";

@Component({
    selector: 'app-taarif',
    templateUrl: './taarif.page.html',
    styleUrls: ['./taarif.page.scss'],
})
export class TaarifPage {
    ready = true;
    loading = false;
    tableData;
    menu = {};
    currentOpenSection;
    exTableData;
    exTableData2;

    userOpenBox;
    showSystemMenu = false;

    myprefix = "selectTablePrefix3" + Math.floor(Math.random() * Math.floor(1000));

    constructor(public events: Events,
                public popoverCtrl: PopoverController,
                public menuService: MainService,
                public business: BusinessService,
                public navCtrl: NavController,
                public mainService: MainService,
                public orderService: OrderService,
                public modelProvider: ModelProvider,
                public appData: AppDataService,
                public modelTools: ModelToolsProvider,
                public businessService: BusinessService,
                public adminService: AdminService,
                public route: ActivatedRoute) {
    }

    ngOnInit(): void {
        let me = this;

        this.events.subscribe('chooseOpenBox' + this.myprefix, (data) => {
            if (data['sectionName'] == 'projectTask') {
                this.adminService.popupEditBoxNav(null, 'projectTask',
                    this.exTableData, 'fopqwwqdd', null, this.navCtrl);
            } else
                this.setUserOpenBox(data);
        });

        this.events.subscribe('showSystemMenu', (data)=>{
            me.showSystemMenu = true;
        })

        //TODO: do not commit this.
        if (true) {
            setTimeout(function () {
                // me.adminService.goToAdmin(me.navCtrl, 'homePage', null, 244342);
                // me.adminService.goToAdmin(me.navCtrl, 'taarif', null, 79);
                // me.adminService.goToAdmin(me.navCtrl, 'lead', null, null);
                // me.adminService.goToAdmin(me.navCtrl, 'orderDetails', null, 484);
                // me.adminService.goToAdmin(me.navCtrl, 'form', null, null);
                // me.adminService.goToAdmin(me.navCtrl, 'addressDetails', null, 1297);
                // me.modelTools.goTo('reports');
            }, 10);
        }

        // this.mainService.closeMenu('menuAdmin', true);
    }

    setUserOpenBox(data) {
        if (this.userOpenBox == data['sectionName']) {
            this.userOpenBox = null;
        } else {
            this.userOpenBox = null;
            let me = this;
            setTimeout(function () {
                me.userOpenBox = data['sectionName'];
            }, 500);
        }
    }

    refresh() {

        //tmp fix. for agents auto load every thing.
        if (!this.orderService.isStaff()) {
            this.modelTools.setKey('adminData', null);
        }

        let me = this;
        this.ready = false;
        setTimeout(function () {
            me.ready = true;
        })
    }

    ngOnDestroy(): void {
        // this.events.unsubscribe('showAdminMenu');
    }

    async presentSelectBar(myEvent, type, isbig = false) {
        this.adminService.popupEditBox(myEvent, null, this.exTableData, "asdasdqwdqwdqw",
            null, type, false,
            null, null, null, null, null);

        // const popover = await this.popoverCtrl.create({
        //     component: SelectBarComponent,
        //     componentProps: {
        //         'prefix': 'aaaa',
        //         'exTableData': this.exTableData,
        //         'sectionName': null,
        //         'type': type
        //     },
        //     event: myEvent,
        //     animated: true,
        //     showBackdrop: true,
        //     cssClass: 'note-popover icenter-popover ' + (isbig ? 'bigger-popover' : 'smaller-popover')
        // });
        // return await popover.present();
    }

    openCustomModel(sectionName, menuIndex) {
        ////console.log'open custom model: ', sectionName);
        let params = {
            'menuIndex': menuIndex,
            'sectionName': sectionName
        };

        let navigationExtras: NavigationExtras = {
            queryParams: params
        };

        this.navCtrl.navigateForward('admin-model/' + sectionName, navigationExtras);
    }

    tableButtonClick(tablePrefix, key, data) {
        this.events.publish("btnclick_" + tablePrefix, {'key': key, 'data': data});
//     'addFilter'
// }, {'quickFieldFilter':[{'field':'status', 'value':counter['status__id']}]})
    }


    customEvent(key) {
        this.navCtrl.navigateForward(key);
    }

    chooseOpenBox(secName) {
        let me = this;
        if (me.userOpenBox == secName) {
            me.userOpenBox = null;
            return;
        }
        //else..
        me.userOpenBox = null;
        setTimeout(function () {
            me.userOpenBox = secName;
        }, 100);
    }

    openMenu() {
        this.menuService.closeMenu('menuAdmin', true);
    }

    loadDashboard(appUserTypeId) {
        let me = this;
        let filters = {'getDashboard': 1, 'userTypeId': appUserTypeId}
        me.adminService.loadAdmin(filters).then(function (tableData: any) {
            me.events.publish('resetHome' + me.myprefix, {'tableData': tableData});
        });
    }

}
