import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';

import { IonicModule } from '@ionic/angular';

import { SectionPage } from './section.page';
import {GlobalModule} from "../../../app/global-module/global-module.module";

const routes: Routes = [
  {
    path: '',
    component: SectionPage
  }
];

@NgModule({
  imports: [
    GlobalModule,
    CommonModule,
    FormsModule,
    IonicModule,
    RouterModule.forChild(routes)

  ],
  declarations: [SectionPage]
})
export class SectionPageModule {}
