// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep cart-fab.search-cart-fab ion-fab {
  bottom: 5px !important;
}`, "",{"version":3,"sources":["webpack://./src/core/pages/search/search.page.scss"],"names":[],"mappings":"AAGM;EACE,sBAAA;AAFR","sourcesContent":["::ng-deep {\n    cart-fab.search-cart-fab {\n\n      ion-fab {\n        bottom: 5px !important;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
