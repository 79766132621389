// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.field-label {
  max-width: 35%;
  font-size: 15px;
}

.button-text {
  margin-top: 5px;
  text-align: center;
}

.syl-box {
  padding: 0 8px;
}

.image-upload {
  --padding-top: 10px;
  --padding-bottom: 10px;
}
.image-upload ion-avatar {
  width: 200px;
  height: 200px;
  margin: auto;
  position: relative;
  border: 1px solid rgba(204, 214, 221, 0.6);
}
.image-upload ion-avatar ion-button {
  position: absolute;
  --border-radius: 50px;
  border-radius: 50px;
  bottom: 25px;
  right: -25px;
  background: #fff;
  --border-color: #ccd6dd99;
  --color: var(--button-a-background);
  --padding-bottom: 10px;
  --padding-top: 10px;
  --padding-end: 14px;
  --padding-start: 14px;
}`, "",{"version":3,"sources":["webpack://./src/core/pages/profile/profile.page.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,eAAA;AACF;;AAEA;EACE,eAAA;EACA,kBAAA;AACF;;AAEA;EACE,cAAA;AACF;;AAOA;EACE,mBAAA;EACA,sBAAA;AAJF;AAME;EACE,YAAA;EACA,aAAA;EACA,YAAA;EACA,kBAAA;EACA,0CAAA;AAJJ;AAMI;EACE,kBAAA;EACA,qBAAA;EACA,mBAAA;EACA,YAAA;EACA,YAAA;EACA,gBAAA;EACA,yBAAA;EACA,mCAAA;EACA,sBAAA;EACA,mBAAA;EACA,mBAAA;EACA,qBAAA;AAJN","sourcesContent":[".field-label{\n  max-width: 35%;\n  font-size: 15px;\n}\n\n.button-text{\n  margin-top: 5px;\n  text-align: center;\n}\n\n.syl-box{\n  padding: 0 8px;\n}\n\n//.text-form{\n//  font-size: 17px;\n//}\n\n\n.image-upload{\n  --padding-top: 10px;\n  --padding-bottom: 10px;\n\n  ion-avatar{\n    width: 200px;\n    height: 200px;\n    margin: auto;\n    position: relative;\n    border: 1px solid #ccd6dd99;\n\n    ion-button{\n      position: absolute;\n      --border-radius: 50px;\n      border-radius: 50px;\n      bottom: 25px;\n      right: -25px;\n      background: #fff;\n      --border-color: #ccd6dd99;\n      --color: var(--button-a-background);\n      --padding-bottom: 10px;\n      --padding-top: 10px;\n      --padding-end: 14px;\n      --padding-start: 14px;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
