import {Component, Input, OnInit} from '@angular/core';
import {FormControl, Validators} from "@angular/forms";
import {ModelToolsProvider} from "../../../../../framework/providers/model-tools/model-tools";
import {ModelListPage} from "../model-list/model-list.page";

@Component({
    selector: 'app-generic-form-field',
    templateUrl: './generic-form-field.component.html',
    styleUrls: ['./generic-form-field.component.scss'],
})
export class GenericFormFieldComponent implements OnInit {

    @Input()
    public fieldKey;

    @Input()
    public fieldData;

    @Input()
    public form;

    constructor(public tools:ModelToolsProvider) {
    }

    ngOnInit() {
        this.addFormField();
    }

    addFormField() {
        let formControl = new FormControl('', []);

        this.form.addControl(this.fieldKey, formControl);

        if (this.fieldData['required']) {
            if(this.fieldData['type'] == 'boolean'){
                this.form.controls[this.fieldKey].setValidators(Validators.requiredTrue);
            }
            else
                this.form.controls[this.fieldKey].setValidators(Validators.required);

        } else {
            this.form.controls[this.fieldKey].clearValidators();
        }

        this.form.controls[this.fieldKey].updateValueAndValidity();
    }

    viewModelList()
    {
        this.tools.showModal(ModelListPage, {'fieldKey':this.fieldKey, 'fieldData':this.fieldData, 'form':this.form})
    }

    fieldClicked()
    {
        switch (this.fieldData['type']){
            case 'model-list':{
                this.viewModelList();
            }
        }
    }

}
