// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sort-card {
  min-width: 155px;
  border-radius: 0;
  height: 42px;
}
.sort-card ion-item {
  --min-height: 42px;
  font-size: 14px;
  --padding-start: 5px;
  --padding-end: 5px;
}`, "",{"version":3,"sources":["webpack://./src/framework/components/list/list-search/list-search.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,gBAAA;EACA,YAAA;AACF;AACE;EACE,kBAAA;EACA,eAAA;EACA,oBAAA;EACA,kBAAA;AACJ","sourcesContent":[".sort-card{\n  min-width: 155px;\n  border-radius: 0;\n  height: 42px;\n\n  ion-item {\n    --min-height: 42px;\n    font-size: 14px;\n    --padding-start: 5px;\n    --padding-end: 5px;\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
