import { Component, OnInit } from '@angular/core';

export class Image {
	data = {};

	constructor(public data1: any) {
		this.data = data1;
	}

	title = "image";
	prefix = "image";
	icon = "glyphicon glyphicon-book";
	showDefaultsCols = true;

	frameworkModel = true;
	dataFields = {
		"id": null,
		"created": null,
		"file": null,
		"title": null,
		"typePrefix": null,
		"objectId": null,
		"business": null,
		"contentType": null,
	}
};
