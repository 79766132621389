import {Component, OnInit} from '@angular/core';
import {actionColors} from "../../../../core/conf/main";

export class Order {
    data = {
        'id': null,
        'created': null,
        'title': null,
        'orderTicketData': null,
        'objectId': null,
        'type': null,
        'orderExtId': null,
        'extorder': null,
        'nextDeliveryDate': null,

        'name': null,
        'email': null,
        'coupon': null,
        'paymentStation': null,

        'cellphone': null,
        'contactMan': null,
        'address': null,
        'appuser': null,
        'paymentTypeName': null,
        'contentTypeName': null,
        'forTime': null,
        'isForTime': null,

        'finalPrice': null,
        'vatPrice': null,
        'orderPrice': null,
        'couponDiscount': null,
        'discount': null,
        'discountPrice': null,
        'priceAfterDiscount': null,

        'shipmentAdress': null,
        'shippingPrice': null,
        'orderAdress': null,
        'pickup': null,
        'shipmentFloor': null,
        'shipmentApt': null,
        'appuserName': null,
        'userEmail': null,
        'statusName': null,
        'paymentVerified': null,
        'paymentId': null,
        'paymentMethod': null,
        'comments': null,
        'driverComments': null,
        'newStatus': null,

        'reports': null,

        'items': null,
        'statuses': null,

        'status': null,
        'cancelOrderStatus': null,

        'companyId': null,
        'companyName': null,
        'itemCount': null,
        'internalCompanyId': null,

        'orderDetails': null,
        'bidStatus': null,
        'balance': null,

        'toDate': null,

        'action': null,
        'action_id': null,
        'toPay': null,

        'startShift': null,
        'endShift': null,

        'lenOfComments': null,

        'pdfCreated': null,
        'pdf': null,

        'pickupBranch': null,
        'pickupBranchData': null,
        'erpCheck': null,

        'error': null,
        'error_msg': null,

        'doc_id': null,
        'total_items': null,

        'statusData': null,
        "companyAgent": {
            "cellphone": null,
            "name": null,
        },
    };

    constructor(public data1: any) {
        this.data = data1;

    }

    frameworkModel = true;
    //shortHeaders = [];
    prefix = "order";
    title = "Order";
    imagesKey = "reports";
    defaultHeaders =
        ['name', 'countField', 'desktopStatFilter', 'contentType', 'puserType'];
    dataFields = {
        id: {'title': 'id', 'value': null, 'type': 'number', 'readonly': true},
        created: {'title': 'date', 'value': null, 'type': 'datetime', 'readonly': true},
        updated: {'title': 'Updated', 'value': null, 'type': 'datetime', 'readonly': true},
        due_date: {'title': 'date', 'value': null, 'type': 'datetime', 'readonly': true},
        receiveDate: {'title': 'receiveDate', 'value': null, 'type': 'datetime', 'readonly': true},
        employee: {
            'value': null,
            'displayField': 'employeeName',
            'type': 'modelscroll',
            'modelscroll': 'Employee',
            'keyField': 'id',
            'valueField': 'fname', 'required': true
        },
        status: {
            'title': 'status',
            'showDefault': true,
            'value': null,
            "displayField": 'status',
            'filters': {'contentTypeName': 'order', 'fieldName': 'status', 'perPrefixIfExists': true, 'removed': false},
            'multiple': true,
            'multipleFilterKey': 'statuses',
            'type': 'modelscroll',
            'modelscroll': 'ModelStatus',
            'required': true,
            'keyField': 'id',
            'valueField': 'name',
        },

        paymentType: {
            'title': 'paymentType',
            'value': null,
            'type': 'optionsscroll',
            'displayfield': 'paymentTypeName',
            'primary': true
        },
        paymentStatus: {'title': 'paymentStatus', 'value': null, 'type': 'boolean', 'primary': true},
        paymentVerified: {'title': 'paymentStatus', 'value': null, 'type': 'boolean', 'primary': true},
        paymentId: {'title': 'reference', 'value': null, 'type': 'text', 'primary': true},
        'paymentMethod': {
            'title': 'paymentMethod',
            'showDefault': true, 'value': null,
            'displayField': 'handleSide', 'type': 'optionsscroll', 'optionsscroll': 'PAYMENT_METHOD',
        },

        payedPrice: {'title': 'PayedPrice', 'value': null, 'type': 'float', 'primary': true},
        invoiceNumber: {'title': 'InvoiceNumber', 'value': null, 'type': 'text'},
        barcodeData: {'title': 'BarcodeData', 'value': null, 'type': 'text'},
        externalId: {'title': 'ExternalId', 'value': null, 'type': 'text', 'primary': true},
        verifyCode: {'title': 'VerifyCode', 'value': null, 'type': 'text', 'primary': true},
        orderTicket: {'type': 'oneToMany', 'class': 'orderTicket', 'foreignKey': 'order'},
        statusData: {'title': 'statusData', 'value': null, 'type': 'text', 'readonly': true},

        secContactMan: {'title': 'secContactMan', 'value': null, 'type': 'text'},
        secCellphone: {'title': 'cellphone', 'value': null, 'type': 'number'},
        secEmail: {'title': 'email', 'value': null, 'type': 'text'},
        secClose: {'title': 'closeness', 'value': null, 'type': 'text'},

        extorder: {'title': 'forOrder', 'value': null, 'type': 'text', 'readonly': true},

        name: {'title': 'name', 'value': null, 'type': 'text', 'required': true},
        type: {'title': 'orderType', 'value': null, 'type': 'text', 'readonly': true},
        itemCount: {'title': 'itemCount', 'value': null, 'type': 'float', 'required': true},
        finalPrice: {'title': 'price', 'value': null, 'type': 'float', 'primary': true},
        userName: {'title': 'username', 'value': null, 'type': 'text', 'readonly': true},
        companyName: {'title': 'companyName', 'value': null, 'type': 'text', 'readonly': true},
        orderExtId: {'title': 'orderExtId', 'value': null, 'type': 'text', 'readonly': true},
        extBusinessName: {'title': 'seller', 'value': null, 'type': 'text', 'readonly': true},
        internalCompanyId: {'title': 'internalCompanyNumber', 'value': null, 'type': 'text', 'readonly': true},
        contactMan: {'title': 'name', 'value': null, 'type': 'text', 'maxlength': 15},
        cellphone: {'title': 'cellphone', 'value': null, 'type': 'tel', 'maxlength': 10},
        orderAdress: {'title': 'address', 'value': null, 'type': 'number', 'readonly': true},
        email: {'title': 'email', 'value': null, 'type': 'email', 'readonly': false, 'maxlength':230},
        userEmail: {'title': 'email', 'value': null, 'type': 'email', 'readonly': true},
        comments: {'title': 'orderComments', 'value': null, 'type': 'textarea', 'maxlength': 500},
        driverComments: {'title': 'driverComments', 'value': null, 'type': 'textarea', 'maxlength': 500},
        pickup: {'title': 'pickup', 'value': null, 'type': 'boolean'},
        isForTime: {'title': 'isForTime', 'value': null, 'type': 'boolean'},
        forTime: {'title': 'forTime', 'value': null, 'type': 'time'},
        toDate: {'title': 'orderToDate', 'value': null, 'type': 'datetime'},
        nextDeliveryDate: {'title': 'nextDeliveryDate', 'value': null, 'type': 'date'},
        action: {
            'title': 'orderType', 'value': null, 'type': 'action', 'colors': actionColors,
        },
        action_id:{
            'title': 'orderType', 'value': null, 'type': 'modelscroll',
            'modelscroll': 'ModelStatus',
            'keyField': 'id',
            'valueField': 'name',
            'filters': {'model': 'order', 'fieldName': 'action', 'perPrefixIfExists': true, 'removed': false},
        },

        doc_id: {'title': 'orderDoc', 'value': null, 'type': 'text'},

        carrier_code:{
            'title': 'carrier_code', 'value': null, 'type': 'modelscroll',
            'modelscroll': 'ModelStatus',
            'keyField': 'key',
            'readonly':true,
            'valueField': 'name',
            'options':{'hideAddOther':true, 'placeholder':'choose'},
            'filters': {'model': 'order', 'fieldName': 'carrier_code', 'perPrefixIfExists': true, 'removed': false},
        },

        error: {'title': 'error', 'value': null, 'type': 'boolean'},
        error_msg: {'title': 'error_msg', 'value': null, 'type': 'text'},
        discount: {'title': 'discountPercentage', 'value': null, 'type': 'number', 'max': 100},

        pickupBranchName: {'title': 'branch', 'value': null, 'type': 'text', 'readonly': true},
    }
}
