import { Injectable } from '@angular/core';
import { UserData, User } from "../user-data";

@Injectable()
export class AuthModel {
	currentUser: User;
	userPref: string;
	deviceInfo: string;

	constructor() {
		this.userPref = 'user_' + Math.floor((Math.random() * 1000) + 1);
		this.currentUser = new User({});

		// this.deviceInfo = this.deviceService.getDeviceInfo();

	}
}
