// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-slide {
  display: initial;
}

.title {
  font-weight: bold;
}

.error {
  text-align: start;
}`, "",{"version":3,"sources":["webpack://./src/core/pages/register/pages/phone-register/phone-register.page.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;;AAEA;EACE,iBAAA;AACF;;AAEA;EAEE,iBAAA;AAAF","sourcesContent":["ion-slide {\n  display: initial;\n}\n\n.title {\n  font-weight: bold;\n}\n\n.error {\n  //font-weight: bold;\n  text-align: start;\n  //margin: 5px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
