import {Component, Input, OnInit} from '@angular/core';
import {AppUser} from "../../../../framework/model/core/appuser/appuser";
import {OrderService} from "../../../services/OrderService";
import {NavController} from "@ionic/angular";
import {ModelProvider} from "../../../../framework/providers/modelProvider/ModelProvider";
import {NewAddressPage} from "../../cart/components/order-address/pages/new-address/new-address.page";
import {AgentCompaniesPage} from "./pages/agent-companies/agent-companies.page";
import {Company} from "../../../../framework/model/core/company/company";
import {InitFormsPage} from "../../forms/pages/init-forms/init-forms.page";
import {Events} from "../../../../framework/providers/events";
import {ModalController} from "@ionic/angular";
import {ModelToolsProvider} from "../../../../framework/providers/model-tools/model-tools";

@Component({
    selector: 'agent-companies',
    templateUrl: './agent-companies.component.html',
    styleUrls: ['./agent-companies.component.scss'],
})
export class AgentCompaniesComponent implements OnInit {

    public appuser: AppUser;
    public models: Array<Company> = [];
    public prefix: string = 'companiesList_' + Math.floor(Math.random() * Math.floor(1000000));
    public options: object = {};
    public filters: object = {'limit': 20};

    @Input()
    public disable:boolean = false;

    @Input()
    public navButtons:boolean = false;

    @Input()
    public listPrefix:string;

    public agentOptions: object = {
        'changePrefix': 'agentCompanyChanged_' + Math.floor((Math.random() * 1000) + 1),
        'hideAddOther': true,
    };

    constructor(public orderService: OrderService, public events: Events, public modelProvider: ModelProvider, public modalController: ModalController, public modelTools:ModelToolsProvider) {
        this.getAppUser();

        // this.events.subscribe(this.agentOptions['changePrefix'], (res) => {
        //     let me = this;
        //     setTimeout(function () {
        //         me.appuser.data.companyName = me.appuser.data['companyIdData'].data.companyName;
        //         me.appuser.data.companyFileNumber = me.appuser.data['companyIdData'].data.companyFileNumber;
        //         //console.logme.appuser);
        //
        //         me.modelProvider.update(me.appuser, {}, null, true).then((res) => {
        //             me.events.publish('agentCompanyChanged');
        //         })
        //     });
        //
        //
        // });
    }

    ngOnInit() {
    }

    async showCompanies() {
        if(this.disable) return;
        let me = this;
        const modal = await this.modalController.create({
            component: AgentCompaniesPage,
            componentProps: {
            }
        });

        return await modal.present();
    }

    async addClient() {
        let me = this;
        const modal = await this.modalController.create({
            component: InitFormsPage,
            componentProps: {
                'keys':['user'],
                'addFilters':{'agent':this.appuser.data.id},
                'options':{
                    'externalCallback':function(data)
                    {
                        // //console.log"CREATED!!!!")
                        me.orderService.changeAgentCompanyByAppUser(data['model']);
                        if(me.listPrefix)
                            me.events.publish(me.listPrefix+'_updateModels');
                    },
                    'finishForm':false,
                }
            }
        });

        return await modal.present();
    }



    getAppUser() {
        this.appuser = this.orderService.getAppUser();
    }

    goTo(page)
    {
        this.modelTools.goTo(page);
    }

}
