// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ticket-divider {
  padding: 0px 0;
  border-radius: 0;
}

.tickets-chips ion-chip {
  margin: 0 1px;
}`, "",{"version":3,"sources":["webpack://./src/core/pages/cart/components/ticket-header/ticket-header.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EAKA,gBAAA;AAHF;;AAOE;EACE,aAAA;AAJJ","sourcesContent":[".ticket-divider {\n  padding: 0px 0;\n\n  //background: var(--button-a-background);\n  //color: var(--button-b-text);\n\n  border-radius: 0;\n}\n\n.tickets-chips{\n  ion-chip{\n    margin: 0 1px;\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
