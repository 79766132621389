import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';

import {IonicModule, IonicRouteStrategy} from '@ionic/angular';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';

import {HttpClientModule, HttpClient} from '@angular/common/http';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

// import {AngularFirestoreModule} from "@angular/fire/firestore";
// import {AngularFireAuthModule} from "@angular/fire/auth";
// import {AngularFireStorageModule} from "@angular/fire/storage";


import {IonicStorageModule} from '@ionic/storage-angular';
import {GooglePlaceModule} from "ngx-google-places-autocomplete";

declare var FirebaseConfig;

import {DatePipe} from "@angular/common";
import {GlobalModule} from "./global-module/global-module.module";
import {ItemProvider} from "../core/pages/item/providers/item-provider/item-provider.service";
import {PropsProvider} from "../core/pages/item/providers/props-provider/props-provider.service";
import {MainService} from "../core/services/MainService";
import {AdminService} from "../core/services/AdminService";
import {OrderService} from "../core/services/OrderService";
import {BusinessService} from "../core/services/BusinessService";
import {AuthGuardService} from "../core/services/AuthGuradService";
import {SectionsManagerService} from "../core/pages/admin/pages/sections-manager/providers/sectionManagerService";
import {AppDataService} from "../core/services/AppDataService";
import {PushService} from "../core/services/PushService";
import {CutPipe, IsLast5MinutesPipe, NumberItPipe} from "../framework/pipes/cut/cut.pipe";
import {FilterProvider} from "../framework/providers/modelFilter";
import {ModelProvider} from "../framework/providers/modelProvider/ModelProvider";
import {UserData} from "../framework/providers/user-data";
import {ServerService} from "../framework/providers/server/server";
import {ModelClasses} from "../core/model/modelClasses";
import {AuthModel} from "../framework/providers/auth-service/authModel";
import {ModelToolsProvider} from "../framework/providers/model-tools/model-tools";
import {PageClasses} from "../core/model/pageClasses";
import {FrameWorkGlobals} from "../framework/providers/frameworkGlobals";
import {AuthService} from "../framework/providers/auth-service/auth-service";
import {AppConfig} from "../core/conf/main";
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '../environments/environment';
import {CartService} from "../core/services/CartService";
// import {AngularFirestoreModule} from "@firebase/firestore";
// import {AngularFireMessagingModule} from "@firebase/messaging";
import {SwiperModule} from "swiper/angular";
import {AdminPageMenuComponent} from "./shaps/components/tools/admin-page-menu/admin-page-menu.component";
import {AdminModelPage} from "./shaps/pages/admin-model/admin-model.page";
import {AngularFireModule} from "@angular/fire/compat";
import {AngularFirestoreModule} from "@angular/fire/compat/firestore";
import {AngularFireMessagingModule} from "@angular/fire/compat/messaging";
// import {AngularFireModule} from "@angular/fire/compat";

export function HttpLoaderFactory(http: HttpClient) {
    // return new TranslateHttpLoader(http);
    let tran = new TranslateHttpLoader(http, AppConfig.assets + 'i18n/', '.json');
    return tran
}

@NgModule({
    declarations: [AppComponent, AdminPageMenuComponent, AdminModelPage],
    imports: [SwiperModule,
        AppRoutingModule,
        GlobalModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        HttpClientModule,
        BrowserModule,
        IonicModule.forRoot(),
        IonicStorageModule.forRoot(),
        AngularFireModule.initializeApp(FirebaseConfig),
        AngularFirestoreModule,
        AngularFireMessagingModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
            // Register the ServiceWorker as soon as the app is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000'
        }),
        // VgCoreModule,
        // VgControlsModule,
        // VgOverlayPlayModule,
        // VgBufferingModule,
    ],
    exports: [TranslateModule],
    providers: [
        ItemProvider,
        PropsProvider,
        MainService,
        AdminService,
        OrderService,
        CartService,
        BusinessService,
        AuthGuardService,
        SectionsManagerService,
        AppDataService,
        PushService,
        // FileTransfer,
        // File,
        CutPipe,
        NumberItPipe,
        FilterProvider,
        ModelProvider,
        UserData,
        ServerService,
        ModelClasses,
        AuthModel,
        ModelToolsProvider,
        PageClasses,
        FrameWorkGlobals,
        AuthService,
        DatePipe,
        // Push,
        // HttpClient,
        // StatusBar,
        // SplashScreen,
        // SocialSharing,
        // VideoPlayer,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
