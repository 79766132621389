import {NgModule} from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import {IonicModule} from "@ionic/angular";
import {HttpClient} from "@angular/common/http";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
// import {IonicStorageModule} from "@ionic/storage";
import {NoteComponent} from "../../framework/components/core/note/note.component";
import {ApplistComponent} from "../../framework/components/list/app-list/app-list.component";
import {AppImgComponent} from "../../framework/components/general/appimg/app-img.component";
import {DisplayfieldComponent} from "../../framework/components/field/displayfield/displayfield.component";
import {AutofieldComponent} from "../../framework/components/field/autofield/autofield.component";
import {ModelscrollComponent} from "../../framework/components/field/modelscroll/modelscroll.component";
import {
    AppModelScrollComponent
} from "../../framework/components/field/modelscroll/app-model-scroll/app-model-scroll.component";
import {PagingComponent} from "../../framework/components/list/paging/paging.component";
import {
    CutPipe,
    IsLast5MinutesPipe,
    LowerCasePipe,
    NumberItPipe,
    PhoneitPipe,
    RangePipe,
    RoundPipe,
    SplitCapitalPipe
} from "../../framework/pipes/cut/cut.pipe";
import {ObjectKeysPipe} from "../../framework/pipes/object-keys/object-keys.pipe";
import {AutoCompleteComponent} from "../../framework/components/field/auto-complete/auto-complete.component";
import {
    ModeloptionscrollComponent
} from "../../framework/components/field/modeloptionscroll/modeloptionscroll.component";
import {AppfileIonicComponent} from "../../framework/components/file/appfile-ionic/appfile-ionic.component";
import {AppfileWebComponent} from "../../framework/components/file/appfile-web/appfile-web.component";
import {AppfileComponent} from "../../framework/components/file/appfile/appfile.component";

import {TranslateHttpLoader} from "@ngx-translate/http-loader";

import {AppHeaderComponent} from "../../core/components/app-header/app-header.component";
import {PriceComponent} from "../../framework/components/core/price/price.component";
import {AppLoadingComponent} from "../../framework/components/core/app-loading/app-loading";
import {DynamicSliderComponent} from "../../framework/components/core/dynamic-slider/dynamic-slider.component";
import {
    DynamicSliderBodyComponent
} from "../../framework/components/core/dynamic-slider-body/dynamic-slider-body.component";
import {ValidFormComponent} from "../../framework/components/core/valid-form/valid-form.component";
import {AppBackgroundComponent} from "../../core/components/app-background/app-background.component";
import {OrderAddressComponent} from "../../core/pages/cart/components/order-address/order-address.component";
import {CouponsComponent} from "../../core/pages/cart/components/coupons/coupons.component";
import {NewOrderComponent} from "../../core/pages/order/components/new-order/new-order.component";
import {ModelSliderComponent} from "../../core/components/sliders/model-slider/model-slider.component";
import {ModelComponents} from "../../core/model/modelComponents";
import {AdvSliderComponent} from "../../core/components/sliders/adv-slider/adv-slider.component";
import {AppItemComponent} from "../../core/pages/item/components/app-item/app-item.component";
import {ItemListComponent} from "../../core/pages/item/components/item-list/item-list.component";
import {AppFooterComponent} from "../../core/components/app-footer/app-footer.component";
import {
    SectionsManagerTreeComponent
} from "../../core/pages/admin/pages/sections-manager/components/sections-manager-tree/sections-manager-tree.component";
import {ModelAddPage} from "../../framework/pages/model-add/model-add.page";
import {
    SectionsOptionsComponent
} from "../../core/pages/admin/pages/sections-manager/components/sections-options/sections-options.component";
import {RouterModule} from "@angular/router";
import {ImagesSliderComponent} from "../../core/components/sliders/images-slider/images-slider.component";
import {SectionListComponent} from "../../core/pages/section/components/section-list/section-list.component";
import {BonusItemsComponent} from "../../core/pages/item/components/bonus-items/bonus-items.component";
import {CartItemComponent} from "../../core/pages/cart/components/cart-item/cart-item.component";
import {ItemsFilterPage} from "../../core/pages/item/pages/items-filter/items-filter.page";
import {
    ItemsFilterComponent
} from "../../core/pages/item/pages/items-filter/components/items-filter/items-filter.component";
import {AgentKeyboardPage} from "../../core/pages/agent/agent-keyboard/agent-keyboard.page";
import {ItemPropsViewComponent} from "../../core/pages/item/components/item-props-view/item-props-view.component";
import {AppMapComponent} from "../../framework/components/core/app-map/app-map.component";
import {PhotoViewer} from "@awesome-cordova-plugins/photo-viewer/ngx";
import {BranchesComponent} from "../../core/pages/contact/components/branches/branches.component";
import {InfinitePagerComponent} from "../../core/components/infinite-pager/infinite-pager.component";
import {BookListComponent} from "../../core/pages/technical-literature/components/book-list/book-list.component";
import {AgentCompaniesComponent} from "../../core/pages/agent/agent-companies/agent-companies.component";
import {ListFilterComponent} from "../../framework/components/list/list-filter/list-filter.component";
import {PlatformsDownloadComponent} from "../../core/components/platforms-download/platforms-download.component";
import {NewAddressPage} from "../../core/pages/cart/components/order-address/pages/new-address/new-address.page";
import {AgentCompaniesPage} from "../../core/pages/agent/agent-companies/pages/agent-companies/agent-companies.page";
import {ListSearchComponent} from "../../framework/components/list/list-search/list-search.component";
import {ListRefreshComponent} from "../../framework/components/list/list-refresh/list-refresh.component";
import {CartFabComponent} from "../../core/pages/cart/components/cart-fab/cart-fab.component";
import {ItemDealPropsPage} from "../../core/pages/item/pages/item-deal-props/item-deal-props.page";
import {
    ItemDealPropsTypeComponent
} from "../../core/pages/item/pages/item-deal-props/components/item-deal-props-type/item-deal-props-type.component";
import {DealsSliderComponent} from "../../core/pages/deals/components/deals-slider/deals-slider.component";
import {DealGroupItemsComponent} from "../../core/pages/deals/components/deal-group-items/deal-group-items.component";
import {PhoneRegisterPage} from "../../core/pages/register/pages/phone-register/phone-register.page";
import {AppSettings} from "../../core/conf/appSettings";
import {OpeningComponent} from "../../core/components/opening/opening.component";
import {AppPanelComponent} from "../../core/components/panels/app-panel/app-panel.component";
import {OrderTypeComponent} from "../../core/pages/cart/components/order-type/order-type.component";
import {SectionTreeComponent} from "../../core/pages/section/components/section-tree/section-tree.component";
import {AddToCartComponent} from "../../core/pages/item/components/add-to-cart/add-to-cart.component";
import {CustomItemsListComponent} from "../../core/pages/item/components/custom-items-list/custom-items-list.component";
import {InitFormsPage} from "../../core/pages/forms/pages/init-forms/init-forms.page";
import {NewCartTicketsComponent} from "../../core/pages/cart/components/new-cart-tickets/new-cart-tickets.component";
import {CartTicketsComponent} from "../../core/pages/cart/components/cart-tickets/cart-tickets.component";
import {AppFormComponent} from "../../core/pages/forms/components/app-form/app-form.component";
import {CartItemToolbarComponent} from "../../core/pages/cart/components/cart-item-toolbar/cart-item-toolbar.component";
import {TicketsListPage} from "../../core/pages/cart/pages/tickets-list/tickets-list.page";
import {AppImageUploadComponent} from "../../framework/components/general/app-image-upload/app-image-upload.component";
import {FileUploadModule} from "ng2-file-upload";
import {GalleryComponent} from "../../core/components/gallery/gallery.component";
import {GallerySliderPage} from "../../core/components/gallery/pages/gallery-slider/gallery-slider.page";
import {ProfileImageComponent} from "../../core/pages/profile/components/profile-image/profile-image.component";
import {QuickCartComponent} from "../../core/pages/cart/components/quick-cart/quick-cart.component";
import {OrderContentComponent} from "../../core/pages/order/components/order-content/order-content.component";
import {PaymentComponent} from "../../core/pages/cart/pages/payment/components/payment/payment.component";
import {MapIconComponent} from "../../framework/components/core/map-icon/map-icon.component";
import {CommentComponent} from "../../framework/components/core/comment/comment.component";
import {AppnewlistComponent} from "../../framework/components/list/appnewlist/appnewlist.component";
import {PagesComponent} from 'src/framework/components/pages/pages.component';
import {PaymentPage} from "../../core/pages/cart/pages/payment/payment.page";
import {AddModelImageComponent} from "../../core/components/add-model-image/add-model-image.component";
import {ViewTableComponent} from '../shaps/components/view-table/view-table.component';
import {ViewBoxComponent} from "../shaps/components/view-box/view-box.component";
import {
    ModelSliderHeadersComponent
} from "../../core/components/sliders/model-slider-headers/model-slider-headers.component";
import {ProfileFormComponent} from "../shaps/components/profile-form/profile-form.component";
import {InsuranceCalcComponent} from "../shaps/components/general/insurance-calc/insurance-calc.component";
import {CalendarComponent} from "../shaps/components/calendar/calendar.component";
import {OneToManyComponent} from "../shaps/components/one-to-many/one-to-many.component";
import {VideoPlayerComponent} from "../../core/components/video-player/video-player.component";
import {FeedComponent} from "../../core/components/feed/feed.component";
import {FeedPostComponent} from "../../core/components/feed/components/feed-post/feed-post.component";
import {AvatarComponent} from "../../core/components/feed/components/avatar/avatar.component";
//
import {AppConfig} from "../../core/conf/main";
import {ViewFieldComponent} from "../shaps/components/view-field/view-field.component";
import {CatalogModelComponent} from "../../core/pages/catalog-list/components/catalog-model/catalog-model.component";
import {ItemFooterComponent} from "../../core/pages/item/components/item-footer/item-footer.component";
import {TicketHeaderComponent} from "../../core/pages/cart/components/ticket-header/ticket-header.component";
import {OrderBoxComponent} from "../../core/pages/orders/components/order-box/order-box.component";
import {ShiftStatusesComponent} from "../../core/pages/orders/components/shift-statuses/shift-statuses.component";
import {SelectBarComponent} from "../shaps/components/select-bar/select-bar.component";
import {MovingAddressBoxComponent} from "../shaps/components/custom/moving-address-box/moving-address-box.component";
import {SocialSharing} from '@awesome-cordova-plugins/social-sharing/ngx';
import {TripBoxComponent} from "../shaps/components/custom/trip-box/trip-box.component";
import {AdminFilterComponent} from "../shaps/components/custom/admin-filter/admin-filter.component";
import {AppfilterComponent} from "../../framework/components/list/appfilter/appfilter.component";
import {AdminSearchComponent} from "../shaps/components/admin-search/admin-search.component";
import {MakeReportComponent} from "../shaps/components/make-report/make-report.component";
import {PickAddressComponent} from "../shaps/components/tools/pick-address/pick-address.component";
import {HtmlEditorComponent} from "../shaps/components/tools/html-editor/html-editor.component";
import {AppEquationComponent} from "../shaps/components/tools/app-equation/app-equation.component";
import {MiniboxComponent} from "../shaps/components/view-box/minibox/minibox.component";
import {
    AdditionalServicesComponent
} from "../shaps/components/custom/additional-services/additional-services.component";
import {InventoryComponent} from "../shaps/components/custom/inventory/inventory.component";
import {ColorPickerModule} from 'ngx-color-picker';
import {
    CartDetailsComponentComponent
} from "../../core/pages/cart/pages/cart-details/cart-details-component/cart-details-component.component";
import {CalendarWrapperComponent} from "../shaps/components/calendar/calendar-wrapper/calendar-wrapper.component";
import {RecSectionsComponent} from "../shaps/components/custom/inventory/rec-sections/rec-sections.component";
import {SideBarComponent} from "../../core/components/side-bar/side-bar.component";
import {MenuNavComponent} from "../../core/pages/section/components/menu-nav/menu-nav.component";
import {PanelElementComponent} from "../../core/components/panels/panel-element/panel-element.component";
import {GenericFormComponent} from "../../core/components/generic-form/generic-form.component";
import {
    GenericFormFieldComponent
} from "../../core/components/generic-form/components/generic-form-field/generic-form-field.component";
import {
    AgentItemToolbarComponent
} from "../../core/pages/item/components/agent-item-toolbar/agent-item-toolbar.component";
import {EditFieldToolbarComponent} from "../../core/components/edit-field-toolbar/edit-field-toolbar.component";
import {InvoiceComponent} from "../../core/pages/client-invoice/components/invoice/invoice.component";
import {MapWrapperComponent} from "../shaps/components/map-wrapper/map-wrapper.component";
import {AppQuickFilterComponent} from "../shaps/components/app-quick-filter/app-quick-filter.component";
import {AppGraphComponent} from "../shaps/components/custom/app-graph/app-graph.component";
import {CustomTableComponent} from "../shaps/components/general/custom-table/custom-table.component";
import {MinicalendarComponent} from "../shaps/components/calendar/minicalendar/minicalendar.component";
import {
    FilterAccordionComponent
} from "../../core/pages/item/pages/items-filter/components/filter-accordion/filter-accordion.component";

import {VgCoreModule} from '@videogular/ngx-videogular/core';
import {VgControlsModule} from '@videogular/ngx-videogular/controls';
import {VgOverlayPlayModule} from '@videogular/ngx-videogular/overlay-play';
import {VgBufferingModule} from '@videogular/ngx-videogular/buffering';
import {AdminFooterComponent} from "../shaps/components/general/admin-footer/admin-footer.component";
import {ItemAgentToolComponent} from "../../core/pages/item/components/item-agent-tool/item-agent-tool.component";
import {CartStatusComponent} from "../../core/pages/cart/components/cart-status/cart-status.component";
import {KeyboardComponent} from "../../core/components/keyboard/keyboard.component";
import {OrdersFiltersComponent} from "../../core/pages/orders/components/orders-filters/orders-filters.component";
import {
    QuickFilterFieldComponent
} from "../shaps/components/app-quick-filter/quick-filter-field/quick-filter-field.component";
import {ItemBoxComponent} from "../../core/pages/item/components/item-box/item-box.component";
// import {CKEditorModule} from "ckeditor4-angular";
import {ItemBoundsComponent} from "../../core/pages/item/components/item-bounds/item-bounds.component";
import {AdminBoxComponent} from "../shaps/components/admin-box/admin-box.component";
import {ModelTemplateComponent} from "../shaps/components/tools/model-template/model-template.component";
import {AppSignatureComponent} from "../shaps/components/tools/app-signature/app-signature.component";
import {AdminMenuComponent} from "../shaps/components/tools/admin-menu/admin-menu.component";
import {GenericModelsMenuComponent} from "../shaps/components/tools/generic-models-menu/generic-models-menu.component";
import {TableHeaderComponent} from "../shaps/components/view-table/table-header/table-header.component";
import {ItemSaleDataComponent} from "../../core/pages/item/components/item-sale-data/item-sale-data.component";
import {ModelListPage} from "../../core/components/generic-form/components/model-list/model-list.page";
import {ItemReviewsComponent} from "../../core/pages/item/components/item-reviews/item-reviews.component";
import {BoxHeaderComponent} from "../shaps/components/view-box/box-header/box-header.component";
import {DataValueComponent} from "../../core/components/data-value/data-value.component";
import {SectionsSalesComponent} from "../../core/pages/sales/components/sections-sales/sections-sales.component";
import {ColumnOptionsComponent} from "../shaps/components/tools/column-options/column-options.component";
import {SwiperModule} from "swiper/angular";
import {FieldSelectComponent} from "../shaps/components/tools/html-editor/field-select/field-select.component";
import {CustomDatePipe} from "../../framework/pipes/customDate/custom-date.pipe";
import {ItemsPage} from "../../core/pages/item/pages/items/items.page";
import {SavedCartComponent} from "../../core/pages/cart/components/saved-cart/saved-cart.component";
import {CountdownTimerComponent} from "../../core/components/countdown-timer/countdown-timer.component";


export function HttpLoaderFactory(http: HttpClient) {
    // return new TranslateHttpLoader(http);
    let tran = new TranslateHttpLoader(http, AppConfig.assets + 'i18n/', '.json');
    return tran
}

@NgModule({
    providers: [
        ModelComponents,
        PhotoViewer,
        AppSettings,
        DatePipe,
        CustomDatePipe,
        SocialSharing,
    ],
    imports: [
        SwiperModule,
        CommonModule,
        // CKEditorModule,
        IonicModule.forRoot(),
        FormsModule,
        ReactiveFormsModule,
        ColorPickerModule,
        TranslateModule.forChild({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        RouterModule,
        FileUploadModule,


        VgCoreModule,
        VgControlsModule,
        VgOverlayPlayModule,
        VgBufferingModule,

        // VgCoreModule,
        // VgControlsModule,
        // VgOverlayPlayModule,
        // VgBufferingModule,

    ],

    declarations: [
        FieldSelectComponent,
        ColumnOptionsComponent,
        BoxHeaderComponent,
        TableHeaderComponent,
        GenericModelsMenuComponent,
        AdminMenuComponent,
        AppSignatureComponent,
        ModelTemplateComponent,
        AdminBoxComponent,
        AdminFooterComponent,
        MinicalendarComponent,
        CustomTableComponent,
        AppGraphComponent,
        RecSectionsComponent,
        AppHeaderComponent,
        AppBackgroundComponent,
        AppFooterComponent,
        InfinitePagerComponent,

        ModelSliderComponent,
        ModelSliderHeadersComponent,
        AdvSliderComponent,
        ImagesSliderComponent,

        AppItemComponent,
        ItemBoxComponent,
        ItemBoundsComponent,
        ItemListComponent,
        ItemsFilterComponent,
        SectionListComponent,
        MenuNavComponent,
        CartItemComponent,
        CartFabComponent,
        ItemPropsViewComponent,
        ItemDealPropsTypeComponent,
        AddToCartComponent,
        ItemFooterComponent,
        CustomItemsListComponent,

        PlatformsDownloadComponent,

        BookListComponent,

        BonusItemsComponent,

        SectionsManagerTreeComponent,
        SectionsOptionsComponent,

        OrderAddressComponent,
        CouponsComponent,
        NewOrderComponent,
        OrderBoxComponent,
        ShiftStatusesComponent,

        BranchesComponent,

        PriceComponent,
        AppLoadingComponent,
        DynamicSliderComponent,
        DynamicSliderBodyComponent,
        ValidFormComponent,
        NoteComponent,
        AppMapComponent,
        MapIconComponent,

        ApplistComponent,
        AppImgComponent,
        DisplayfieldComponent,
        AutofieldComponent,
        ListFilterComponent,
        ListSearchComponent,
        ListRefreshComponent,

        AutoCompleteComponent,
        ModeloptionscrollComponent,
        ModelscrollComponent,
        AppModelScrollComponent,
        PagingComponent,

        IsLast5MinutesPipe,
        PhoneitPipe,
        NumberItPipe,
        CutPipe,
        SplitCapitalPipe,
        LowerCasePipe,
        RangePipe,
        ObjectKeysPipe,
        RoundPipe,
        CustomDatePipe,

        AppfileIonicComponent,
        AppfileWebComponent,
        AppfileComponent,

        ItemsFilterPage,
        AgentKeyboardPage,
        AgentCompaniesComponent,
        AgentCompaniesPage,
        NewAddressPage,

        ItemDealPropsPage,

        ModelAddPage,

        DealsSliderComponent,
        DealGroupItemsComponent,

        PhoneRegisterPage,

        SectionTreeComponent,
        OpeningComponent,
        OrderTypeComponent,
        AppPanelComponent,
        PanelElementComponent,

        InitFormsPage,
        NewCartTicketsComponent,
        CartTicketsComponent,
        TicketHeaderComponent,
        TicketsListPage,
        AppFormComponent,
        CartItemToolbarComponent,

        AppImageUploadComponent,

        GalleryComponent,
        GallerySliderPage,

        AddModelImageComponent,

        PaymentPage,

        // LoginPage,
        // RegisterPage,

        ProfileImageComponent,
        QuickCartComponent,
        OrderContentComponent,

        CatalogModelComponent,

        PaymentComponent,
        CommentComponent, AppnewlistComponent,
        PagesComponent,
        ViewTableComponent,
        OneToManyComponent,
        ViewBoxComponent,
        ProfileFormComponent,
        InsuranceCalcComponent,
        CalendarComponent,

        VideoPlayerComponent,
        FeedComponent,
        FeedPostComponent,
        AvatarComponent,
        ViewFieldComponent,
        SelectBarComponent,

        MovingAddressBoxComponent,
        TripBoxComponent,
        AppfilterComponent, AdminFilterComponent,
        AdminSearchComponent,
        MakeReportComponent,
        AppEquationComponent,
        HtmlEditorComponent,
        MiniboxComponent,
        InventoryComponent,
        PickAddressComponent,
        AdditionalServicesComponent,
        CartDetailsComponentComponent,
        CalendarWrapperComponent,

        SideBarComponent,

        GenericFormComponent,
        GenericFormFieldComponent,

        AgentItemToolbarComponent,
        EditFieldToolbarComponent,
        InvoiceComponent,
        MapWrapperComponent,
        AppQuickFilterComponent,

        FilterAccordionComponent,

        ItemAgentToolComponent,
        CartStatusComponent,
        KeyboardComponent,
        QuickFilterFieldComponent,

        OrdersFiltersComponent,
        ItemSaleDataComponent,
        SectionsSalesComponent,

        ModelListPage,

        ItemReviewsComponent,
        DataValueComponent,

        ItemsPage,
        SavedCartComponent,
        CountdownTimerComponent,
    ],
    exports: [
        FieldSelectComponent,
        ColumnOptionsComponent,
        BoxHeaderComponent,
        TableHeaderComponent,
        GenericModelsMenuComponent,
        AdminMenuComponent,
        AppSignatureComponent,
        ModelTemplateComponent,
        AdminBoxComponent,
        AdminFooterComponent,
        MinicalendarComponent,
        CustomTableComponent,
        AppGraphComponent,
        RecSectionsComponent,
        AppHeaderComponent,
        AppBackgroundComponent,
        AppFooterComponent,
        InfinitePagerComponent,

        AppItemComponent,
        ItemBoxComponent,
        ItemBoundsComponent,
        ItemListComponent,
        ItemsFilterComponent,
        SectionListComponent,
        MenuNavComponent,
        CartItemComponent,
        CartFabComponent,
        ItemPropsViewComponent,
        ItemDealPropsTypeComponent,
        BonusItemsComponent,
        ItemFooterComponent,

        BookListComponent,

        OrderAddressComponent,
        OrderBoxComponent,
        ShiftStatusesComponent,

        SectionsManagerTreeComponent,
        SectionsOptionsComponent,

        BranchesComponent,

        AgentCompaniesComponent,

        ModelSliderComponent,
        ModelSliderHeadersComponent,
        AdvSliderComponent,
        ImagesSliderComponent,

        PriceComponent,
        AppLoadingComponent,
        DynamicSliderComponent,
        DynamicSliderBodyComponent,
        ValidFormComponent,
        AppMapComponent,
        MapIconComponent,

        TranslateModule,
        ApplistComponent,
        AppImgComponent,
        DisplayfieldComponent,
        AutofieldComponent,
        ModelscrollComponent,
        AppModelScrollComponent,
        PagingComponent,
        ListFilterComponent,
        ListSearchComponent,

        IsLast5MinutesPipe,
        CutPipe,
        PhoneitPipe,
        NumberItPipe,
        SplitCapitalPipe,
        LowerCasePipe,
        RangePipe,
        ObjectKeysPipe,
        RoundPipe,
        ListRefreshComponent,
        CustomDatePipe,

        DealsSliderComponent,
        DealGroupItemsComponent,

        OrderTypeComponent,
        AppPanelComponent,
        PanelElementComponent,
        SectionTreeComponent,

        CartTicketsComponent,
        TicketHeaderComponent,

        AppFormComponent,
        CartItemToolbarComponent,

        AppImageUploadComponent,
        AddModelImageComponent,

        GalleryComponent,
        ProfileImageComponent,

        CatalogModelComponent,

        QuickCartComponent,
        OrderContentComponent,
        PaymentComponent,
        CommentComponent,
        AppnewlistComponent,
        PagesComponent,

        ViewTableComponent,
        ViewBoxComponent,
        ProfileFormComponent,
        InsuranceCalcComponent,
        OneToManyComponent,
        CalendarComponent,

        VideoPlayerComponent,
        FeedComponent,
        FeedPostComponent,
        AvatarComponent,
        ViewFieldComponent,
        SelectBarComponent,

        MovingAddressBoxComponent,
        TripBoxComponent,
        AppfilterComponent, AdminFilterComponent,
        AdminSearchComponent,
        MakeReportComponent,
        HtmlEditorComponent,
        MiniboxComponent,
        AppEquationComponent,
        PickAddressComponent,
        InventoryComponent,
        AdditionalServicesComponent,
        CartDetailsComponentComponent,
        CalendarWrapperComponent,

        SideBarComponent,

        GenericFormComponent,
        GenericFormFieldComponent,

        AgentItemToolbarComponent,
        EditFieldToolbarComponent,
        InvoiceComponent,
        MapWrapperComponent,
        AppQuickFilterComponent,
        AppGraphComponent,
        FilterAccordionComponent,

        ItemAgentToolComponent,
        CartStatusComponent,
        KeyboardComponent,
        QuickFilterFieldComponent,

        ItemSaleDataComponent,

        ItemReviewsComponent,
        DataValueComponent, SectionsSalesComponent,
        SavedCartComponent,
        CountdownTimerComponent,


    ],
})
export class GlobalModule {
}


export interface ResObject {
    models?: any;
    data?: any;
}

export interface CostumRes {
    res?: any;
}

export interface ModelObject {
    data?: ResData;
}

interface ResData {
    id?: object;
}

export interface FieldDataObject {
    calFunc?: any,
    selfTemplate?: any,
    readonly?: boolean,
    displayField?: string,
    type?: any,
    oneToOne?: any,
    extFieldName?: any,
    price?: any,
    nis?: any,
    precents?: any,
    addText?: any,
    modelDataKey?: any,
    selectMultiple?: boolean,
    title?: any,
    height?: any,
    min?: any
    addParams?: any,
    maxlength?: any,
    minlength?: any,
    bigarea?: boolean,
    editTemplate?: any,
    defaultValue?: any,
}


// entryComponents: [
//         FieldSelectComponent,
//         ColumnOptionsComponent,
//         BoxHeaderComponent,
//         TableHeaderComponent,
//         GenericModelsMenuComponent,
//         AdminMenuComponent,
//         AppSignatureComponent,
//         ModelTemplateComponent,
//         AdminBoxComponent,
//         AdminFooterComponent,
//         RecSectionsComponent,
//         NoteComponent,
//         CouponsComponent,
//         NewOrderComponent,
//
//         AppItemComponent,
//         ItemBoxComponent,
//
//         PlatformsDownloadComponent,
//
//         AppModelScrollComponent,
//
//         ModelAddPage,
//         ItemsFilterPage,
//         AgentKeyboardPage,
//         NewAddressPage,
//         AgentCompaniesPage,
//
//         ItemDealPropsPage,
//
//         PhoneRegisterPage,
//
//         PaymentPage,
//
//         OpeningComponent,
//         AddToCartComponent,
//         CustomItemsListComponent,
//         InitFormsPage,
//
//         NewCartTicketsComponent,
//         TicketsListPage,
//
//         GallerySliderPage,
//         OrderContentComponent,
//         CommentComponent, AppnewlistComponent,
//
//         AppMapComponent,
//         PagesComponent,
//         ViewTableComponent,
//         ViewBoxComponent,
//         ProfileFormComponent,
//         AppFormComponent,
//         OneToManyComponent,
//         InsuranceCalcComponent,
//         CalendarComponent,
//         ViewFieldComponent,
//         SelectBarComponent,
//
//         MovingAddressBoxComponent,
//         TripBoxComponent,
//         AppfilterComponent, AdminFilterComponent,
//         AdminSearchComponent,
//         AppEquationComponent,
//         HtmlEditorComponent,
//         MiniboxComponent,
//         InventoryComponent,
//         PickAddressComponent,
//         AdditionalServicesComponent,
//         CartDetailsComponentComponent,
//         CalendarWrapperComponent,
//         InvoiceComponent,
//
//         MapWrapperComponent,
//         AppQuickFilterComponent,
//         AppGraphComponent,
//         KeyboardComponent,
//         QuickFilterFieldComponent,
//
//         OrdersFiltersComponent,
//
//         SectionsSalesComponent,
//
//
//         ModelListPage,
//         //
//         // LoginPage,
//         // RegisterPage,
//     ],