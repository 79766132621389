// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-box {
  margin: 0;
}

.find-coupon-button {
  border-radius: 20px;
  float: left;
  left: 20px;
  position: absolute;
  margin-top: 0px;
}

.input-form {
  margin-top: 10px;
}

.vert-center {
  min-height: 100px;
}

ion-badge {
  --background: var(--button-a-background);
  --color: var(--button-a-text);
}

ion-item-group ion-label {
  max-width: 50%;
}

ion-list.circle-list {
  margin: 5px;
  padding: 0;
}

.order-type-box {
  width: 90%;
  margin: 0 auto;
  --background: #c7c7c7;
  padding: 0 10px;
  border-radius: 15px;
  background: #c7c7c7;
  --padding-start: 10px;
  --min-height: 50px;
  font-weight: bold;
  color: var(--button-b-background);
  margin-top: 10px;
}
.order-type-box ion-note {
  padding: 7px 0;
}

.msgs-box {
  padding: 10px;
}

.msgs-box.warning {
  color: #d33939;
}`, "",{"version":3,"sources":["webpack://./src/core/pages/cart/pages/cart-details/cart-details-component/cart-details-component.component.scss"],"names":[],"mappings":"AAAA;EAEE,SAAA;AAAF;;AAIA;EAEE,mBAAA;EACA,WAAA;EACA,UAAA;EACA,kBAAA;EACA,eAAA;AAFF;;AAKA;EACE,gBAAA;AAFF;;AAMA;EACE,iBAAA;AAHF;;AAMA;EACE,wCAAA;EACA,6BAAA;AAHF;;AAQE;EACE,cAAA;AALJ;;AAUA;EACE,WAAA;EACA,UAAA;AAPF;;AAUA;EACE,UAAA;EACA,cAAA;EACA,qBAAA;EACA,eAAA;EACA,mBAAA;EACA,mBAAA;EACA,qBAAA;EACA,kBAAA;EACA,iBAAA;EACA,iCAAA;EAEA,gBAAA;AARF;AAUE;EACE,cAAA;AARJ;;AAkBA;EACE,aAAA;AAfF;;AAkBA;EACE,cAAA;AAfF","sourcesContent":[".input-box {\n  //background: #878181;\n  margin: 0;\n  //padding: 5px 15px;\n}\n\n.find-coupon-button {\n  //width:100%;\n  border-radius: 20px;\n  float: left;\n  left: 20px;\n  position: absolute;\n  margin-top: 0px;\n}\n\n.input-form {\n  margin-top: 10px;\n}\n\n\n.vert-center {\n  min-height: 100px;\n}\n\nion-badge {\n  --background: var(--button-a-background);\n  --color: var(--button-a-text);\n}\n\n\nion-item-group {\n  ion-label {\n    max-width: 50%;\n  }\n}\n\n\nion-list.circle-list {\n  margin: 5px;\n  padding: 0;\n}\n\n.order-type-box {\n  width: 90%;\n  margin: 0 auto;\n  --background: #c7c7c7;\n  padding: 0 10px;\n  border-radius: 15px;\n  background: #c7c7c7;\n  --padding-start: 10px;\n  --min-height: 50px;\n  font-weight: bold;\n  color: var(--button-b-background);\n  //margin: 0 10px;\n  margin-top: 10px;\n\n  ion-note {\n    padding: 7px 0;\n  }\n}\n\n@media screen and (min-width: 920px) {\n  .order-type-box {\n    //width: 50%;\n  }\n}\n\n.msgs-box {\n  padding: 10px;\n}\n\n.msgs-box.warning {\n  color: #d33939;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
