export class Company {
    data = {
        'id': null,
        "insideId": "",
        "internalCompanyId": "",
        "companyFileNumber": "",
        "statde": "",
        "compaus": null,
        "taxconyNumber": null,
        "companyName": "",
        "homephone": "",
        "cellphone": "",
        "fax": "",
        "email": "",
        "tz": "",
        "city": "",
        "street": "",
        "homeNumber": null,
        "floor": null,
        "zipCode": null,
        "mailbox": null,
        "comments": "",
        "accountManager1": "",
        "contactMan": "",
        "accountManager2": "",
        "accountManager3": "",
        "ourId": "",
        "discount": null,
        "balance": null,
        "active": false,
        "distributer": false,
        "approved": false,
        "erpCheck": false,
        "priceApproved": false,
        "xp": null,
        "payment": "",
        "rate": null,
        "companyType": null,
        "business": null,
        "admin": null,
        "type": null,
        "companyAgent": null,

        'group_id': null,
        'showObligo': null,
        'acc_debbit': null,
        'obligo_rest': null
    };

    constructor(public data1: any) {
        this.data = data1;
    }

    //shortHeaders = [];
    prefix = "company";
    title = "company";
    sortOptions = {
        value:'nameUpDown',
        fields: ['last_order_date_up', 'last_order_date_down', 'newToOld', 'oldToNew', 'nameUpDown', 'nameDownUp'],
        keys:{
            'last_order_date_down':'-last_order_date',
            'last_order_date_up':'last_order_date',

            'newToOld':'-id',
            'oldToNew':'id',
            'nameUpDown':'companyName',
            'nameDownUp':'-companyName',
        }
    }
    dataFields = {
        id: {'title': 'id', 'value': null, 'type': 'number', 'readonly': true},
        created: {'title': 'date', 'value': null, 'type': 'date', 'readonly': true},
        updated: {'title': 'Updated', 'value': null, 'type': 'date', 'readonly': true},
        last_order_date: {'title': 'last_order_date', 'value': null, 'type': 'date', 'readonly': true},

        name: {'title': 'name', 'value': null, 'type': 'text'},
        city: {'title': 'city', 'value': null, 'type': 'text'},
        street: {'title': 'street', 'value': null, 'type': 'text'},
        companyName: {'title': 'companyName', 'value': null, 'type': 'text', 'maxlength': 60, 'required': true},
        companyFileNumber: {
            'title': 'companyFileNumber',
            'value': null,
            'type': 'number',
            'maxlength': 9,
            'required': true
        },
        internalCompanyId: {
            'title': 'internalCompanyId',
            'value': null,
            'type': 'number',
            'maxlength': 30,
            'required': false,
            'readonly':true,
        },
        email: {'title': 'emailsForUpdates', 'value': null, 'type': 'text', 'maxlength':90},
        fax: {'title': 'fax', 'value': null, 'type': 'text', 'maxlength':90},
        contactMan: {'title': 'contactMan', 'value': null, 'type': 'text', 'maxlength':90},
        cellphone: {'title': 'cellphone', 'value': null, 'type': 'text', 'maxlength':90},
        status: {'title': 'status', 'value': null, 'type': 'text', 'readonly': true},
        erpCheck: {'title': 'ErpCheck', 'value': null, 'type': 'boolean'},
        approved: {'title': 'companyApproved', 'value': null, 'type': 'boolean'},
        priceApproved: {'title': 'priceApproved', 'value': null, 'type': 'boolean'},
        comments: {'title': 'comments', 'value': null, 'type': 'text'},


        type: {
            'showDefault': true, 'value': null, 'title': 'bisType',
            'filters': {},
            'displayField': 'typeName', 'type': 'modelscroll',
            'modelscroll': 'Type', 'keyField': 'id', 'valueField': 'name',
        },

        subType: {
            'showDefault': true, 'value': null, 'title': 'subType',
            'filters': {},
            'displayField': 'subtypeName', 'type': 'modelscroll',
            'modelscroll': 'SubType', 'keyField': 'id', 'valueField': 'name',
        },
        clientsDiscount: {
            'title': 'clientsDiscount',
            'value': null,
            'type': 'number',
            'maxlength': 50,
            'required': true
        },

        acc_debbit: {'title': 'acc_debbit', 'value': null, 'type': 'text', 'readonly': true},
        userCode: {'title': 'clientCode', 'value': null, 'type': 'text', 'readonly': true},
        obligo_rest: {'title': 'obligo_rest', 'value': null, 'type': 'text', 'readonly': true},

        discount: {'title': 'discount', 'value': null, 'type': 'text'},
        postdated_check: {'title': 'postdated_check', 'value': null, 'type': 'price'},
        balance: {'title': 'balance', 'value': null, 'type': 'price'},
        payment: {'title': 'paymentType', 'value': null, 'type': 'text'},
        lastVisit: {'title': 'lastVisit', 'value': null, 'type': 'datetime'},

        'agentName': {'title': 'agentName', 'value': null, 'type': 'text', 'readonly': true},
        'agentCellphone': {'title': 'agentCellphone', 'value': null, 'type': 'text', 'readonly': true},
    }
}
