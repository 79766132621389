export class CompanyCoupon {
    data = {
        'id': null,
        "company__name": null,
        "coupon__code": null,
        "coupon__discount": null,
        "item_name": null,
        "coupon__expirationDate": null,
        "isPercentage": null,
    };

    constructor(public data1: any) {
        this.data = data1;
    }

    //shortHeaders = [];
    prefix = "companyCoupon";
    title = "Coupon";
    dataFields = {
        id: {'title': 'ID', 'value': null, 'type': 'number', 'readonly': true},
        created: {'title': 'Created', 'value': null, 'type': 'date', 'readonly': true},
        updated: {'title': 'Updated', 'value': null, 'type': 'date', 'readonly': true},
        item_name: {'title': 'for', 'value': null, 'type': 'text', 'readonly': true},
        coupon__code: {'title': 'couponCode', 'value': null, 'type': 'text'},
        company__name: {'title': 'name', 'value': null, 'type': 'text'},
        coupon__discount: {'title': '%discount', 'value': null, 'type': 'text'},
        coupon__expirationDate: {'title': 'expirationDate', 'value': null, 'type': 'date'},
        isPercentage: {'title': 'prefix', 'value': null, 'type': 'text'},
        coupon__visible: {'title': 'active', 'value': null, 'type': 'boolean'},

    }
}
