// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.empty-box {
  margin-bottom: 40px;
}
.empty-box .empty-icon {
  font-size: 100px;
  color: #d2d2d2;
  padding-top: 50px;
}

.padding {
  margin: 5px 9px;
}

@media screen and (min-width: 900px) {
  .wideOverflow {
    max-height: 700px;
    overflow: scroll;
  }
  .fixedOnBigScreen {
    position: fixed;
    z-index: 111;
    margin: 0 auto;
    width: 900px;
    left: 0;
    right: 0;
  }
  .statuses-item {
    flex-direction: row !important;
  }
}
.statuses-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.statuses-item ion-label {
  width: revert;
  text-overflow: inherit;
  overflow: initial;
}
.statuses-item ion-text {
  display: flex;
  flex-wrap: wrap;
  gap: 1px;
  width: 100%;
  justify-content: center;
}
.statuses-item ion-text ion-chip {
  flex: 0 0 auto;
}`, "",{"version":3,"sources":["webpack://./src/core/pages/orders/orders.page.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF;AAEE;EACE,gBAAA;EACA,cAAA;EACA,iBAAA;AAAJ;;AAaA;EACE,eAAA;AAVF;;AAaA;EACE;IACE,iBAAA;IACA,gBAAA;EAVF;EAaA;IACE,eAAA;IACA,YAAA;IACA,cAAA;IACA,YAAA;IAEA,OAAA;IACA,QAAA;EAZF;EAgBA;IACE,8BAAA;EAdF;AACF;AAkBA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;AAhBF;AAkBE;EACE,aAAA;EACA,sBAAA;EACA,iBAAA;AAhBJ;AAmBE;EACE,aAAA;EACA,eAAA;EACA,QAAA;EACA,WAAA;EACA,uBAAA;AAjBJ;AAmBI;EACE,cAAA;AAjBN","sourcesContent":[".empty-box {\n  margin-bottom: 40px;\n\n\n  .empty-icon {\n    font-size: 100px;\n    color: #d2d2d2;\n    padding-top: 50px;\n  }\n}\n\n.badge-span {\n  //left: 4px;\n  //position: absolute;\n}\n\nion-card {\n  //margin: 10px 16px;\n}\n\n.padding {\n  margin: 5px 9px;\n}\n\n@media screen and (min-width: 900px) {\n  .wideOverflow {\n    max-height: 700px;\n    overflow: scroll;\n  }\n\n  .fixedOnBigScreen {\n    position: fixed;\n    z-index: 111;\n    margin: 0 auto;\n    width: 900px;\n\n    left: 0;\n    right: 0;\n  }\n\n\n  .statuses-item{\n    flex-direction: row !important;\n  }\n\n}\n\n.statuses-item{\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n\n  ion-label {\n    width: revert;\n    text-overflow: inherit;\n    overflow: initial;\n  }\n\n  ion-text{\n    display: flex;\n    flex-wrap: wrap;\n    gap: 1px;\n    width: 100%;\n    justify-content: center;\n\n    ion-chip{\n      flex: 0 0 auto;\n    }\n  }\n\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
