export class OpenInvoice {
    data = {
        "id": null,
    };

    constructor(public data1: any) {
        this.data = data1;
    }

    prefix = "openInvoice";
    title = "openInvoice";
    fields = ["doc_no", "date", "due_date", "balance"];
    dataFields = {
        id: {'title': 'id', 'value': null, 'type': 'number', 'readonly': true},
        doc_no: {'title': 'doc_no', 'value': null, 'type': 'text'},
        balance: {'title': 'summary', 'value': null, 'type': 'float', 'minusWarning':true},
        date: {'title': 'date', 'value': null, 'type': 'date'},
        due_date: {'title': 'payment_date', 'value': null, 'type': 'date'},
        doc_type: {'title': 'type', 'value': null, 'type': 'text'},
        paymentVerified: {'title': 'paymentStatus', 'value': null, 'type': 'boolean'},
    }
}
