// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-item-divider {
  --color: #555;
  font-weight: 700;
}

ion-item, ion-item-divider {
  gap: 10px;
  border-right: 1px solid #dedede;
  border-left: 1px solid #dedede;
}
ion-item ion-icon, ion-item-divider ion-icon {
  margin: 0;
}
ion-item ion-label div, ion-item-divider ion-label div {
  min-width: 100px;
}

.type_date ion-label {
  max-width: 90px;
  font-size: 13px;
}

ion-label.title {
  display: flex;
  gap: 5px;
}

.error {
  background: #fff;
  margin: 0;
  color: #f44336;
  font-size: 16px;
  gap: 5px;
  grid-gap: 5px;
  padding: 0 2px;
  font-style: italic;
}

.full_label {
  white-space: pre-line;
  flex-grow: 4;
}

ion-datetime {
  --ion-color-base: var(--button-a-background) !important;
}`, "",{"version":3,"sources":["webpack://./src/core/components/generic-form/components/generic-form-field/generic-form-field.component.scss"],"names":[],"mappings":"AAAA;EAGE,aAAA;EACA,gBAAA;AADF;;AAMA;EACE,SAAA;EAEA,+BAAA;EACA,8BAAA;AAJF;AAOE;EACE,SAAA;AALJ;AASI;EACE,gBAAA;AAPN;;AAcE;EACE,eAAA;EACA,eAAA;AAXJ;;AAeA;EACE,aAAA;EACA,QAAA;AAZF;;AAeA;EACE,gBAAA;EACA,SAAA;EAEA,cAAA;EACA,eAAA;EAEA,QAAA;EAEA,aAAA;EACA,cAAA;EACA,kBAAA;AAfF;;AA2BA;EACE,qBAAA;EACA,YAAA;AAxBF;;AAgCA;EACE,uDAAA;AA7BF","sourcesContent":["ion-item-divider {\n  //--background: var(--button-a-background);\n  //--color: var(--button-a-text);\n  --color: #555;\n  font-weight: 700;\n\n  //border-top: 3px solid var(--button-b-background);\n}\n\nion-item, ion-item-divider {\n  gap: 10px;\n\n  border-right: 1px solid #dedede;\n  border-left: 1px solid #dedede;\n  //border-bottom: 1px solid #dedede;\n\n  ion-icon {\n    margin: 0;\n  }\n\n  ion-label {\n    div {\n      min-width: 100px;\n    }\n  }\n\n}\n\n.type_date {\n  ion-label {\n    max-width: 90px;\n    font-size: 13px;\n  }\n}\n\nion-label.title {\n  display: flex;\n  gap: 5px;\n}\n\n.error {\n  background: #fff;\n  margin: 0;\n  //padding: 5px 15px;\n  color: #f44336;\n  font-size: 16px;\n  //display: flex;\n  gap: 5px;\n\n  grid-gap: 5px;\n  padding: 0 2px;\n  font-style: italic;\n\n  //position: absolute;\n  //text-align: center;\n  //width: 100%;\n\n  //bottom: 0;\n  //padding: 0 10px;\n  //display: flex;\n  //justify-content: flex-end;\n}\n\n.full_label{\n  white-space: pre-line;\n  flex-grow: 4;\n}\n\n\n//.error::after{\n//  content: ' - ';\n//}\n\nion-datetime {\n  --ion-color-base: var(--button-a-background) !important;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
