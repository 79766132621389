import {Component, Input, OnInit} from '@angular/core';
import {ItemsFilterPage} from "../../items-filter.page";
import {ModalController} from "@ionic/angular";
import {ModelProvider} from "../../../../../../../framework/providers/modelProvider/ModelProvider";
import {AppSettings} from "../../../../../../conf/appSettings";

@Component({
    selector: 'items-filter',
    templateUrl: './items-filter.component.html',
    styleUrls: ['./items-filter.component.scss'],
})
export class ItemsFilterComponent implements OnInit {

    @Input()
    public prefix: object;

    @Input()
    public filters: object;

    @Input()
    public activeFilters: Array<any> = [];

    public currentListFilters: object = {};
    public listFilters: object;
    public settings: object;

    constructor(public modalController: ModalController, public modelProvider:ModelProvider, public appSettings: AppSettings) {
    }

    ngOnInit() {
        this.listFilters = this.currentListFilters = JSON.parse(JSON.stringify(this.filters))
        this.getFilters();

        this.settings = this.appSettings.getSettings('items');
    }


    getFilters() {
        this.listFilters['setPrefix'] = true;
        this.modelProvider.custom('getItemsFilters', this.listFilters).then((res) => {
            this.setFilters(res['res'])
        })
    }

    setFilters(filters) {
        for (let k in filters) {
            if (filters[k]['type'] == 'number') {
                filters[k]['res'] = {'lower': filters[k]['data']['min'], 'upper': filters[k]['data']['max']}
                //
                // let keys = {'min': 'lower', 'max': 'upper'};
                // for (let key in keys) {
                //     if (this.currentFilters[key + '_' + filters[k]['title']]) {
                //         filters[k]['res'][keys[key]] = this.currentFilters[key + '_' + filters[k]['title']];
                //     }
                // }
            }
            else
            {
                filters[k]['res'] = {}
            }
        }
        this.filters = filters
    }

    async viewFilters(sort=false) {
        if(!this.filters) return;
        const modal = await this.modalController.create({
            component: ItemsFilterPage,
            componentProps: {
                'prefix': this.prefix,
                'listFilters': this.listFilters,
                'currentListFilters': this.currentListFilters,
                'activeFilters': this.activeFilters,
                'filters': this.filters,
                'sort': sort,
            }
        });

        return await modal.present();
    }
}
