import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {Routes, RouterModule} from '@angular/router';

import {IonicModule} from '@ionic/angular';

import {OrdersPage} from './orders.page';
import {GlobalModule} from '../../../app/global-module/global-module.module';
import {OrderContentComponent} from "../order/components/order-content/order-content.component";

const routes: Routes = [
    {
        path: '',
        component: OrdersPage
    }
];

@NgModule({
    imports: [
        GlobalModule,
        CommonModule,
        FormsModule,
        IonicModule,
        RouterModule.forChild(routes)
    ],
    declarations: [OrdersPage]

})
export class OrdersPageModule {
}
