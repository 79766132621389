import {Component, Input, OnInit} from '@angular/core';
import {AppSettings} from "../../../../conf/appSettings";
import {BusinessService} from "../../../../services/BusinessService";

@Component({
    selector: 'init-forms',
    templateUrl: './init-forms.page.html',
    styleUrls: ['./init-forms.page.scss'],
})
export class InitFormsPage implements OnInit {

    @Input()
    public settingsKey;

    @Input()
    public keys;

    @Input()
    public options;

    @Input()
    public addFilters;

    constructor(public appSettings: AppSettings,
                public businessService: BusinessService) {
    }

    ngOnInit() {
        if (this.settingsKey) {
            let settings = this.appSettings.getSettings(this.settingsKey);
            this.keys = settings['formKeys'];
        }
    }

}
