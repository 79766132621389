import {Component, Input, OnInit} from '@angular/core';
import {PopoverController} from "@ionic/angular";
import {AdminService} from "../../../../core/services/AdminService";
import {ModelProvider} from "../../../../framework/providers/modelProvider/ModelProvider";
import {Events} from "../../../../framework/providers/events";

//model report create

@Component({
    selector: 'app-make-report',
    templateUrl: './make-report.component.html',
    styleUrls: ['./make-report.component.scss'],
})
export class MakeReportComponent implements OnInit {
    @Input()
    filters;
    @Input()
    prefix;
    @Input()
    tableInfoData;
    @Input()
    exTableData;
    @Input()
    sectionName;
    @Input()
    type = 'choose';

    ready = false;

    fileDownload = '';

    sums = [];
    groupby = [];

    constructor(public popoverCtrl: PopoverController,
                public adminService: AdminService,
                public events: Events,
                public modelProvider: ModelProvider) {
    }

    ngOnInit() {
        this.ready = true;

        //console.log'make report page:');
        //console.logthis.filters);

    }

    async closeWindow() {
        await this.popoverCtrl.dismiss();
    }

    shrinkit(fieldType) {
        if (this.groupby.indexOf(fieldType['name']) != -1) {
            this.groupby.splice(this.groupby.indexOf(fieldType['name'], 1));
        } else {
            this.groupby.push(fieldType['name']);
        }
    }

    sumit(fieldType) {
        if (this.sums.indexOf(fieldType['name']) != -1) {
            this.sums.splice(this.sums.indexOf(fieldType['name'], 1));
        } else {
            this.sums.push(fieldType['name']);
        }
    }

    go() {
        let me = this;
        this.filters['groupit'] = this.groupby;
        this.filters['sums'] = this.sums;

        this.modelProvider.download('downloadTable', this.filters).then(function (res) {
            me.fileDownload = res['savePath'];
            //console.logme.fileDownload);
        });
    }


}
