// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-toolbar {
  box-shadow: 0px 1px 3px #3e3e3e;
  z-index: 5;
  --min-height: initial;
  --background: var(--main-top);
  padding: 0;
  color: var(--main-top-text);
  --ion-toolbar-color: var(--main-top-text);
}
ion-toolbar ion-buttons ion-button.button-clear {
  --color: var(--main-top-text);
  --color-activated: var(--main-top-text);
}
ion-toolbar ion-buttons ion-button {
  --border-radius: 0;
}

ion-menu-button {
  --color: var(--main-top-text);
}

ion-title {
  height: 100%;
}

tr:nth-of-type(odd) {
  background: #efefef;
}`, "",{"version":3,"sources":["webpack://./src/app/shaps/main/main-agents/main-agents.page.scss"],"names":[],"mappings":"AAAA;EACE,+BAAA;EACA,UAAA;EACA,qBAAA;EAEA,6BAAA;EACA,UAAA;EAEA,2BAAA;EACA,yCAAA;AADF;AAGE;EACE,6BAAA;EACA,uCAAA;AADJ;AAIE;EACE,kBAAA;AAFJ;;AAMA;EACE,6BAAA;AAHF;;AAMA;EAGE,YAAA;AALF;;AAQA;EACE,mBAAA;AALF","sourcesContent":["ion-toolbar {\n  box-shadow: 0px 1px 3px #3e3e3e;\n  z-index: 5;\n  --min-height: initial;\n\n  --background: var(--main-top);\n  padding: 0;\n\n  color: var(--main-top-text);\n  --ion-toolbar-color: var(--main-top-text);\n\n  ion-buttons ion-button.button-clear {\n    --color: var(--main-top-text);\n    --color-activated: var(--main-top-text);\n  }\n\n  ion-buttons ion-button {\n    --border-radius: 0;\n  }\n}\n\nion-menu-button {\n  --color: var(--main-top-text);\n}\n\nion-title {\n\n  //display: inline-block;\n  height: 100%;\n}\n\ntr:nth-of-type(odd) {\n  background: #efefef;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
