import {Component, OnDestroy, OnInit} from '@angular/core';
import {Section} from "../../../framework/model/core/sections/section";
import {ActivatedRoute, NavigationExtras} from "@angular/router";
import {NavController, PopoverController} from "@ionic/angular";
import {AppSettings} from '../../conf/appSettings'
import {Deal} from "../../../framework/model/core/deal/deal";
import {OrderService} from "../../services/OrderService";
import {Business} from "../../../framework/model/core/business/business";
import {BusinessService} from "../../services/BusinessService";
import {ModelProvider} from "../../../framework/providers/modelProvider/ModelProvider";
import {Panel} from "../../../framework/model/core/panel/panel";
import {ModelToolsProvider} from "../../../framework/providers/model-tools/model-tools";
import {Events} from "../../../framework/providers/events";
import {MainService} from "../../services/MainService";

@Component({
    selector: 'app-store',
    templateUrl: './store.page.html',
    styleUrls: ['./store.page.scss'],
})
export class StorePage implements OnInit, OnDestroy {
    public business: Business;

    public models: Array<Section> = [];
    public deals: Array<Deal> = [];
    public panels: Array<Panel> = [];

    public options: object = {};
    public settings: object;

    public quickNav: boolean = true;
    cart: any;

    ready = false;
    itemsShowReady = false;

    public sub_prefix: string = 'sub_' + Math.floor(Math.random() * Math.floor(1000000));

    constructor(public navCtrl: NavController,
                public orderService: OrderService,
                public modelProvider: ModelProvider,
                public events: Events,
                public menuSrv:MainService,
                public route: ActivatedRoute,
                public tools: ModelToolsProvider,
                public businessService: BusinessService,
                public appSettings: AppSettings) {
        this.settings = this.appSettings.getSettings('store');
        this.quickNav = this.settings['quick-nav'] !== null ? this.settings['quick-nav'] : this.quickNav;
        this.business = this.businessService.getBusiness();
    }

    ngOnInit() {
        // this.cart = this.orderService.getCart();

        let me = this;
        setTimeout(function () {
            me.ready = true;
        }, 1);

        // can change company id if requested.
        this.route.queryParams.subscribe(params => {
            if (params['menus'])
            {
                for (let menu of params['menus'])
                {
                    this.menuSrv.closeMenu(menu, true, false);
                }
            }
        //     if (params['id'] && params['manager']) {
        //         let appuser = this.orderService.getAppUser();
        //
        //         this.orderService.appuser['data']['companyId'] = params['id'];
        //
        //         appuser.data.companyId = params['id'];
        //         this.modelProvider.update(appuser);
        //     }
        });
        this.events.subscribe('appPriceSets', (params) => {
            this.events.publish('topSections_appDataUpdated')
            // this.refresh();
        }, this.sub_prefix);

        this.events.subscribe('agentCompanyChanged', (params) => {
            // this.refresh();
        }, this.sub_prefix);
    }

    refresh()
    {
        this.models.length = 0;
        this.ready = false;
        let me = this;
        setTimeout(function () {
            me.ready = true;
            console.log("READY!")
        }, 1000);
    }

    ngOnDestroy() {
        this.events.unsubscribe('agentCompanyChanged', this.sub_prefix);
        this.events.unsubscribe('appPriceSets', this.sub_prefix);
        console.log("STORE DESTT!")
    }

    ionViewWillEnter() {

    }

    ngAfterViewInit() {
        let me = this;
        setTimeout(function () {
            me.itemsShowReady = true;
        }, 100);
    }

    goTo(page, params) {
        let navigationExtras: NavigationExtras = {
            queryParams: params
        };

        this.tools.goTo(page, navigationExtras)
    }
}
