import { Component, OnInit } from '@angular/core';

export class ColumnModel {
    data = {
        'title': null,
        'contentTypeName':null,
        'fields':null,
        'prefix':null};

    constructor(public data1:any) {
        this.data = data1;
    }

    frameworkModel = true;
    prefix = "columnModel";
    title = "ColumnModel";
    icon = "grid"
    showDefaultsCols = true;
    defaultHeaders = ['contentType', 'puserType', 'fields', 'prefix'];

    dataFields = {
        id: {'title': 'ID', 'value': null, 'type': 'number', 'readonly': true},
        created: {'title': 'Created', 'value': null, 'type': 'date', 'readonly': true},
        updated: {'title': 'Updated', 'value': null, 'type': 'date', 'readonly': true},
        prefix: {'title': 'Prefix', 'value': null, 'type': 'text', 'readonly': true},
        contentType: {
            'showDefault': true,
            'value': null,
            'displayField': 'contentTypeName',
            'type': 'modelscroll',
            'modelscroll': 'ContentType',
            'keyField': 'id',
            'valueField': 'model'
        },
        puserType: {
            'showDefault': true,
            'value': null,
            'displayField': 'puserTypeName',
            'type': 'modelscroll',
            'modelscroll': 'PuserType',
            'keyField': 'id',
            'valueField': 'name'
        },
        fields: {'showDefault': true, 'title': 'Fields', 'value': null, 'type': 'text',
            editTemplate: 'columnModelFieldsEdit',},

    }
}