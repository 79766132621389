import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {Item} from "../../../../../framework/model/core/items/item";
import {CartModel} from "../../../../../framework/model/core/items/cartModel";
import {ItemProvider} from "../../providers/item-provider/item-provider.service";
import {OrderService} from "../../../../services/OrderService";
import {Events} from "../../../../../framework/providers/events";
import {ModelToolsProvider} from "../../../../../framework/providers/model-tools/model-tools";
import {AppSettings} from "../../../../conf/appSettings";
import {IonTextarea, PopoverController} from "@ionic/angular";

@Component({
    selector: 'app-item-box',
    templateUrl: './item-box.component.html',
    styleUrls: ['./item-box.component.scss'],
})
export class ItemBoxComponent implements OnInit {

    @Input()
    public model: Item;

    @Input()
    public view: string = 'big';

    @Input()
    public options: object = {};
    @Input()
    public listOptions: object = {};

    @Input()
    public cartModel: CartModel;

    public cartOptions: object;

    @Input()
    public inCart: boolean = false;

    @Input()
    public listPrefix: string;

    @Input()
    public listIndex: number;

    public subPrefix: string;
    public isAgent: boolean;
    public settings: string;
    public action: string;

    public extraData: any;
    public extraFields: any;

    @ViewChild('itemcontent') itemContent: ElementRef;
    @ViewChild('commentTextarea', { static: false }) commentTextarea: IonTextarea;


    constructor(public itemProvider: ItemProvider, public orderService:OrderService, public appevents:Events, public tools:ModelToolsProvider, public appsettings:AppSettings,
                private popoverController: PopoverController) {
    }

    ngOnInit() {
        if (!this.cartModel)
            this.cartModel = this.model.cart_model;

        this.isAgent = this.orderService.isAgent();

        this.options['in_cart'] = this.inCart
        this.subPrefix = this.tools.getRandomize();
        this.settings = this.appsettings.getSettings('items');

        this.action = this.orderService.cartData['action'];

        this.setCartOptions();

        this.setTicketsSubscribes()

        this.extraFields = ['name']
        this.extraData = []
        if(this.isAgent){
            // this.extraData = this.extraData.concat(this.model.data['stockData']);
            this.extraFields.push('stock')
        }
        if(this.listOptions && this.listOptions['items_data'] && this.listOptions['items_data'][this.model.data.id])
        {
            this.extraData = this.extraData.concat(this.listOptions['items_data'][this.model.data.id])
        }
    }

    setTicketsSubscribes(){
        if(this.inCart) return;

        this.appevents.subscribe('activeTicketChanged', (res)=>{
            let cartModel = this.itemProvider.getTicketCartModel(this.model);
            this.setCartOptions();
            this.cartModel = cartModel;
        }, this.subPrefix);


        this.appevents.subscribe('activeTicketChanged_'+this.model.data.id, (params)=>{
            let cartModel = this.itemProvider.getTicketCartModel(this.model, false, params['ticketId']);
            this.cartModel = cartModel;
        }, this.subPrefix);
    }

    ngOnDestroy()
    {
        this.appevents.unsubscribe('activeTicketChanged', this.subPrefix);
        this.appevents.unsubscribe('activeTicketChanged_'+this.model.data.id, this.subPrefix);
    }

    setCartOptions() {
        // console.log(this.model)
        let options = {}
        options['out_of_stock'] = this.model.data.outofstock && !this.settings['allow_outofstock_order'];
        options['is_top_item'] = this.model.data.isTopItem;
        options['view_type'] = this.model.data.view_type;
        options['cart_options'] = !options['out_of_stock'] && !options['is_top_item'] && parseFloat(this.cartModel.getValue('corePrice')) > 0;

        let buttons = null;

        switch (options['view_type']){
            default:{
                buttons = [
                    {
                        'value': 1,
                        'icon': 'add',
                        'text': null,
                        'add_to_value': true,
                        'outside_item_page': this.listOptions ? this.listOptions['outside_item_page'] : true
                    },
                    {
                        'value': 1,
                        'icon': null,
                        'cartKey': 'amount',
                        'extra_key': this.model.data.packageUnits > 1 ? 'package_amount' : null,
                        'extra_key_text': 'packages',
                        'add_to_value': true,
                        'outside_item_page': this.listOptions ? this.listOptions['outside_item_page'] : true
                    },
                    {
                        'value': -1,
                        'icon': 'remove',
                        'text': null,
                        'add_to_value': true,
                        'outside_item_page': this.listOptions ? this.listOptions['outside_item_page'] : true
                    }
                ];

                break;
            }

            case 'business_card':{
                buttons = [
                    {
                        'value':null,
                        'icon': this.model.data.main_button_icon,
                        'text': this.model.data.main_button_title,
                    },
                ]
                break;
            }
        }

        // console.log(buttons, options)
        let global_options = this.orderService.getCartOptions();
        options['tickets'] = global_options['tickets'];

        if(!this.cartOptions) this.cartOptions = {}
        if(JSON.stringify(this.cartOptions['buttons'])!=JSON.stringify(buttons))
            this.cartOptions['buttons'] = buttons
        if(JSON.stringify(this.cartOptions['options'])!=JSON.stringify(options)) {
            this.cartOptions['options'] = options
        }
        // this.options['action_values_options'] = this.orderService.getActionOptions()
    }

    cartAction(option) {
        if(option['value'] === null){
            this.openItem();
            return
        }

        option['in_cart'] = this.inCart
        let cart_model = this.inCart ? this.cartModel : null

        if(!this.inCart && option['init-field']) {
            this.cartModel.data['init-field'] = option['init-field'];
        }

        cart_model = this.itemProvider.add_to_cart(this.model, option['value'], 'amount', option, cart_model);
        if(cart_model) this.cartModel = cart_model;
    }

    removeFromCart()
    {
        let me = this;
        let params = {
            'title': this.tools.getTranslate('removeFromCart'),
            'message': this.tools.getTranslate('areYouSure'),
            'no': this.tools.getTranslate('no'),
            'yes': this.tools.getTranslate('yes')
        }
        this.tools.yesOrNoAlert(function (){
            me.cartAction({'value':0, 'add_to_value':false})
        }, params)
    }

    openItem() {
        console.log(this.model)
        let autoProps = this.model.data.autoProps;
        this.itemProvider.openItem(this.model, this.cartModel, autoProps, {'listIndex':this.listIndex, 'listPrefix':this.listPrefix});
        // this.cartModel.data['visible'] = true;
    }

    goTo(page, params = {}) {
        this.tools.goTo(page, params);
    }

    moveToTicket()
    {
        this.itemProvider.moveToTicket(this.model, this.cartModel);
    }

    setAgentToolVisible()
    {
        this.cartModel.data['visible'] = true;
        this.itemProvider.setAgentToolEditField(this.cartModel, this.model, 'amount', this.options);
    }

    setCommentFocus(tryn=0)
    {
        if(tryn > 2) return
        let me = this;
        setTimeout(function (){
            if(!me.commentTextarea)
            {
                me.setCommentFocus(tryn+1)
                return
            }

            me.commentTextarea.setFocus()

        }, 100)
    }

    closePopover()
    {
        this.popoverController.dismiss();
    }
}
