// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.colors-row.absolute {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0 2px;
  max-width: 60%;
  text-align: end;
}

.colors-row.center {
  text-align: center;
}

.color-prop-box {
  width: 15px;
  height: 15px;
  border: 1px solid #000;
  display: inline-block;
  border-radius: 4px;
  margin: 3px 1px;
}

.color-prop-box.big {
  width: 30px;
  height: 30px;
  border: 1px solid #000;
  display: inline-block;
  border-radius: 10px;
  margin: 0 2px;
}

.color-prop-box.small {
  width: 23px;
  height: 23px;
}

.color-prop-box.disabled {
  opacity: 0.1;
}

.props-row {
  white-space: pre-line;
}

.prop-box {
  margin: 2px;
}

.prop-box.active, .color-prop-box.active {
  opacity: 0.7;
}

.prop-box.small {
  padding-inline-start: 6px;
  padding-inline-end: 6px;
  height: 22px;
}

.title-choose {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/core/pages/item/components/item-props-view/item-props-view.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,MAAA;EACA,OAAA;EACA,cAAA;EACA,cAAA;EACA,eAAA;AACF;;AAEA;EACE,kBAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;EACA,sBAAA;EACA,qBAAA;EACA,kBAAA;EACA,eAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;EACA,sBAAA;EACA,qBAAA;EACA,mBAAA;EACA,aAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;AACF;;AAEA;EACE,YAAA;AACF;;AAEA;EAEE,qBAAA;AAAF;;AAGA;EAEE,WAAA;AADF;;AAIA;EAEE,YAAA;AAFF;;AAKA;EAEE,yBAAA;EACA,uBAAA;EACA,YAAA;AAHF;;AAMA;EACE,eAAA;EACA,iBAAA;EACA,kBAAA;AAHF","sourcesContent":[".colors-row.absolute {\n  position: absolute;\n  top: 0;\n  left: 0;\n  padding: 0 2px;\n  max-width: 60%;\n  text-align: end;\n}\n\n.colors-row.center {\n  text-align: center;\n}\n\n.color-prop-box {\n  width: 15px;\n  height: 15px;\n  border: 1px solid #000;\n  display: inline-block;\n  border-radius: 4px;\n  margin: 3px 1px;\n}\n\n.color-prop-box.big {\n  width: 30px;\n  height: 30px;\n  border: 1px solid #000;\n  display: inline-block;\n  border-radius: 10px;\n  margin: 0 2px;\n}\n\n.color-prop-box.small {\n  width: 23px;\n  height: 23px;\n}\n\n.color-prop-box.disabled {\n  opacity: 0.1;\n}\n\n.props-row\n{\n  white-space: pre-line;\n}\n\n.prop-box\n{\n  margin: 2px;\n}\n\n.prop-box.active, .color-prop-box.active\n{\n  opacity: 0.7;\n}\n\n.prop-box.small\n{\n  padding-inline-start: 6px;\n  padding-inline-end: 6px;\n  height: 22px;\n}\n\n.title-choose{\n  font-size: 18px;\n  font-weight: bold;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
