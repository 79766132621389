import {Component, Input, OnInit} from '@angular/core';
import {Order} from "../../../../../framework/model/core/order/order";
import {ModelProvider} from "../../../../../framework/providers/modelProvider/ModelProvider";
import {ModelStatus} from "../../../../../framework/model/modelStatus";

@Component({
  selector: 'shift-statuses',
  templateUrl: './shift-statuses.component.html',
  styleUrls: ['./shift-statuses.component.scss'],
})
export class ShiftStatusesComponent implements OnInit {

  @Input()
  public model:Order;

  constructor(public modelProvider:ModelProvider) { }

  ngOnInit() {}

  setStatus(status)
  {
    this.model.data.status = status.id;
    this.model.data['statusData'] = new ModelStatus(status);
    this.modelProvider.update(this.model, {}, -1, true).then((res) => {
    });
  }

  setShift(key = 'start')
  {
    let date = new Date();
    this.model.data[key+'Shift'] = date;
  }

}
