import {Injectable} from '@angular/core';
import {ModelProvider} from "../../framework/providers/modelProvider/ModelProvider";
import {BusinessService} from "./BusinessService";
import {Business} from "../../framework/model/core/business/business";

import {AppUser} from "../../framework/model/core/appuser/appuser";
import {OrderService} from "./OrderService";
import {ResObject} from "../../app/global-module/global-module.module";
import {TranslateService} from "@ngx-translate/core";
import {ModelToolsProvider} from "../../framework/providers/model-tools/model-tools";
import {Events} from "../../framework/providers/events";
import {AngularFireAuth} from "@angular/fire/compat/auth";
import {AngularFireMessaging} from "@angular/fire/compat/messaging";
// import { AngularFireDatabase } from '@angular/fire/database';
import {
    ActionPerformed,
    PushNotifications,
    PushNotificationSchema,
    PushNotificationToken,
    Token
} from "@capacitor/push-notifications";
import {Platform} from "@ionic/angular";

declare var FirebaseConfig;

@Injectable()
export class PushService {

    private business: Business;
    private appuser: AppUser;

    private pushId = '';

    private messaging;

    // private push: Push;
    constructor(private modelProvider: ModelProvider, public businessService: BusinessService, public modelTools: ModelToolsProvider,
                private fireAuth: AngularFireAuth, private angularFireMessaging: AngularFireMessaging, private angularFireAuth: AngularFireAuth,
                public events: Events, public orderService: OrderService, public translate: TranslateService, public platform: Platform
    ) {
    }


    ngOnInit() {
    }


    start() {
        //console.log("PUSH SERVICE START!");
        //
        // if (!firebase.apps.length) {
        // initializeApp(FirebaseConfig);
        // } else {
        //     firebase.app(); // if already initialized, use that one
        // }

        this.setPushId();

        this.events.subscribe('businessSets', (params) => {
            this.business = this.businessService.getBusiness();
        });

        this.events.subscribe('appuserChanged', (params) => {
            this.appuser = this.orderService.getAppUser();
            this.checkPushId();
        })
    }

    setPushId() {

        if (this.platform.is('cordova')) {
            this.initCordova()
        } else {
            // this.firebasePermission();
        }
    }

    initCordova() {

        PushNotifications.requestPermissions().then(result => {
            if (result.receive === 'granted') {
                console.log('recived granted!!!')
                // Register with Apple / Google to receive push via APNS/FCM
                PushNotifications.register();
            } else {
                console.log('register error!!!!!!')
                // Show some error
            }
        });

        PushNotifications.addListener('registration', (token: Token) => {
            console.log('Push registration success, token: ');
            console.log(token)
            this.pushId = token.value
        });

        PushNotifications.addListener('registrationError', (error: any) => {
            console.log('Error on registration: ' + JSON.stringify(error));
        });

        PushNotifications.addListener(
            'pushNotificationReceived',
            (notification: PushNotificationSchema) => {
                console.log('Push received: ' + JSON.stringify(notification));
                this.events.publish('push_' + notification['additionalData']['type'], notification['additionalData'])
            },
        );

        PushNotifications.addListener(
            'pushNotificationActionPerformed',
            (notification: ActionPerformed) => {
                //console.log('Push action performed: ' + JSON.stringify(notification));
            },
        );

    }


    firebasePermission() {
        this.angularFireMessaging.requestToken.subscribe(
            (token) => {
                //console.log('DESKTOP PUSH ID', token)
                this.pushId = token;
            },
            (err) => {
                console.error('Unable to get permission to notify.', err);
            }
        );
    }

    checkPushId() {
        if (!this.appuser)
            return

        //console.log("### PUSH ID", this.pushId);
        //console.log("### PUSH ID", this.appuser.data.pushId);
        //console.log("### PUSH ID", this.appuser.data.id);
        if (this.pushId && this.appuser.data.pushId != this.pushId) {
            this.appuser.data.pushId = this.pushId;
            console.log("### PUSH ID", this.pushId);
            console.log("### PUSH ID", this.appuser.data.pushId);
            console.log("### PUSH ID", this.appuser.data.id);
            this.modelProvider.update(this.appuser, {'pref': this.appuser.data.prefix}).then((res: ResObject) => {
                //console.log'update is done push!!!!!! ######')
            });
        }
    }

    getExistChannels() {
        // let id =
        // //console.log"getExistChannels !!!!!!!!!!!!!!")
        // this.push.listChannels().then((channels) => //console.log'List of channels', channels))
    }

    getPushId() {
        return this.pushId;
    }


}









