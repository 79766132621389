import {Component, Input, OnInit} from '@angular/core';
import {Events} from "../../../providers/events";


@Component({
    selector: 'list-refresh',
    templateUrl: './list-refresh.component.html',
    styleUrls: ['./list-refresh.component.scss'],
})
export class ListRefreshComponent implements OnInit {

    @Input()
    public filters: object;

    @Input()
    public prefix: string;

    constructor(public events: Events) {
    }

    ngOnInit() {
    }

    refresh(event) {
        this.events.publish(this.prefix + '_updateModels', this.filters);

        setTimeout(() => {
            //console.log'Async operation has ended');
            event.target.complete();
        }, );
    }

}
