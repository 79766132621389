export class AppComment {
    data = { 'title': null, 'objectId': null, 'contentTypeName': null };

    constructor(public data1: any) {
        this.data = data1;
    }


    frameworkModel = true;
    prefix = "comment";
    title = "Comment";
    defaultHeaders =
        ['user', 'type', 'text', 'created'];
    dataFields = {
        "id": { 'value': null, 'type': 'number', 'readonly': true },
        "created": { 'value': null, 'type': 'datetime', 'readonly': true },
        "updated": { 'value': null, 'type': 'datetime', 'readonly': true },
        "contentType": { 'value': null, 'type': 'number', 'readonly': true },
        "appuserName": { 'value': null, 'type': 'text', 'readonly': true },
        "objectId": { 'value': null, 'type': 'number', 'readonly': true },
        // "title": { 'value': null, 'type': 'text', 'required': true },
        "text": { 'value': null, 'type': 'textarea', 'required': true, 'height': '300px', 'maxlength':1000 },
        // "type": {
        //     'showDefault': true, 'value': null,
        //     'filters': { 'contentTypeName': 'comment', 'fieldName': 'type' },
        //     'displayField': 'commentTypeName', 'type': 'modelscroll',
        //     'modelscroll': 'ModelStatus', 'keyField': 'id', 'valueField': 'name',
        //     //'multipleSelect':true,
        // }

        // "type": {
        //     'showDefault': true, 'value': null,
        //     'filters': { 'contentTypeName': 'comment', 'fieldName': 'type' },
        //     'displayField': 'commentTypeName', 'type': 'modelscroll',
        //     'modelscroll': 'ModelStatus', 'keyField': 'id', 'valueField': 'name',
        //     //'multipleSelect':true,
        // },
    }
}
