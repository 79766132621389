// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.displayname {
  max-height: 45px;
  line-height: 16px;
}`, "",{"version":3,"sources":["webpack://./src/app/shaps/components/view-box/minibox/minibox.component.scss"],"names":[],"mappings":"AAMA;EACE,gBAAA;EACA,iBAAA;AALF","sourcesContent":["\n.fieldcellItem {\n  //max-height: 31px;\n  //--min-height: 31px;\n}\n\n.displayname {\n  max-height: 45px;\n  line-height: 16px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
