import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Events} from "../../../../../framework/providers/events";
import {AdminService} from "../../../../../core/services/AdminService";

@Component({
    selector: 'app-insurance-calc',
    templateUrl: './insurance-calc.component.html',
    styleUrls: ['./insurance-calc.component.scss'],
})
export class InsuranceCalcComponent implements OnInit, OnDestroy {
    @Input()
    type = 'insurance';

    @Input()
    baseFilter;
    @Input()
    title;
    @Input()
    exTableData;
    @Input()
    sectionName;
    @Input()
    editFields;
    @Input()
    lastSelectedModel;

    newModel;
    model;
    colTitle;
    fieldType;
    fieldData;
    amountOfLiability;
    valuationCharge;

    opened = false;
    ready = false;

    constructor(public events: Events, public adminService: AdminService) {
    }

    ngOnInit() {

        this.events.subscribe('onTdClick', (data) => {
            console.log('baboooo', data)
            this.sectionName = data['sectionName'];
            this.model = data['model'];
            this.colTitle = data['colTitle'];
            this.fieldType = data['fieldType'];
            // this.baseFilter = data['baseFilter'];
            this.fieldData = data['fieldData'];
            this.amountOfLiability = this.model[this.fieldType['valueField']]['value'];
            // this.valuationCharge = this.fieldData['value'];
        });

        if (this.sectionName) {
            this.getNewModel(this.sectionName);
        }

        this.type = this.exTableData[this.sectionName + 'Data']['showCalc'] ? 'insurance' : 'rateCalc';

        this.ready = true;
        console.log(this.baseFilter)
        //(['rate', 'durationRate'].includes(this.sectionName) ? 'rateCalc' : 'insurance')
    }

    //insurance set value
    set() {
        // this.exTableData[this.sectionName + 'Data']['updated'] = true;
        // this.fieldData['fieldSaveWait'] = true;
        // this.fieldData['edited'] = true;
        this.model['main']['updated'] = true;
        this.fieldData['displayName'] = (this.valuationCharge / this.amountOfLiability * 1000).toFixed(2);
        this.fieldData['value'] = (this.valuationCharge / this.amountOfLiability * 1000).toFixed(2);

        this.adminService.markUpdated(this.exTableData[this.sectionName + 'Data'], this.sectionName, this.fieldData, this.model);
        this.adminService.saveToMarkedRows(this.sectionName, this.fieldType, this.fieldData);
        // this.adminService.saveField(this.sectionName, this.model, this.fieldData, this.fieldType);
        this.adminService.globalSave();
    }

    ngOnDestroy(): void {
        this.events.unsubscribe('onTdClick');
    }

    getNewModel(secName) {
        this.newModel = this.adminService.getNewModel(secName, this.exTableData);

        let k = JSON.parse(JSON.stringify(this.baseFilter));
        k['searchFieldValue1'] = 'from'
        k['searchField1'] = 'type'
        this.newModel['zoneFrom']['baseFilter'] = k;

        let k1 = JSON.parse(JSON.stringify(this.baseFilter));
        k1['searchFieldValue1'] = 'from'
        k1['searchField1'] = 'type'
        this.newModel['zoneTo']['baseFilter'] = k1;
    }

    operation(op, value) {
        let markedFields = this.adminService.markFields[this.sectionName];
        if (!markedFields) return;

        for (let key in markedFields) {
            let fieldData = markedFields[key]['fieldData'];
            let model = markedFields[key]['model'];
            let exValue = fieldData['value'];
            value = parseInt(value);
            exValue = parseInt(exValue);

            if (op == '+%') {
                fieldData['value'] = exValue + ((value / 100) * exValue);
            } else if (op == '-%') {
                fieldData['value'] = exValue - ((value / 100) * exValue);
            } else if (op == '+') {
                fieldData['value'] = exValue + value;
            } else if (op == '-') {
                fieldData['value'] = exValue - value;
            }
            fieldData['displayName'] = fieldData['value'];
            fieldData['edited'] = true;
            this.adminService.markUpdated(this.exTableData[this.sectionName + 'Data'], this.sectionName, fieldData, model);
            // this.adminService.saveField(this.sectionName, model, fieldData, defData[i]);
        }
    }
}
