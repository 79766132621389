import {Component, Input, OnInit} from '@angular/core';
import {Post} from "../../../framework/model/core/post/post";

@Component({
  selector: 'app-feed',
  templateUrl: './feed.component.html',
  styleUrls: ['./feed.component.scss'],
})
export class FeedComponent implements OnInit {

  @Input()
  public filters:object = {};

  public models:Array<Post> = [];

  constructor() { }

  ngOnInit() {}

}
