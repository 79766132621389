import {Component, OnInit} from '@angular/core';
import {ModelProvider} from "../../../../../../../framework/providers/modelProvider/ModelProvider";
import {AppConfig} from "../../../../../../conf/main";
import {} from "@ionic/angular";
import {ModelAddPage} from "../../../../../../../framework/pages/model-add/model-add.page";
import {Section} from "../../../../../../../framework/model/core/sections/section";
import {ActivatedRoute} from "@angular/router";
import {SectionsManagerService} from "../../providers/sectionManagerService";
import {Storage} from '@ionic/storage';
import {ModelToolsProvider} from "../../../../../../../framework/providers/model-tools/model-tools";
import {Events} from "../../../../../../../framework/providers/events";

@Component({
    selector: 'app-sections-list',
    templateUrl: './sections-list.page.html',
    styleUrls: ['./sections-list.page.scss'],
})
export class SectionsListPage implements OnInit {

    public sections: Array<any>;

    public sectionsData: object;
    public transferData: object;

    public filters:object = {'pref': AppConfig.bisPrefix};

    public readonly:boolean;

    constructor(public modelProvider: ModelProvider,
                public events: Events,
                private route: ActivatedRoute,
                private modelTools: ModelToolsProvider,
                public sectionsManagerService:SectionsManagerService,
                public storage:Storage) {

        let id = this.route.snapshot.paramMap.get("id");
        this.readonly = id != ':1';
        this.filters['includeInMenu'] = this.readonly ? 0 : 1;

        this.sectionsData = this.sectionsManagerService.getSectionsData();
        this.transferData = this.sectionsManagerService.getTransferData();

        // this.events.subscribe('refreshSectionsTree', (cren) => {
        //     this.getSections();
        // });
        //
        // this.events.subscribe('initCheckedItems', (cren) => {
        //     this.initCheckedItems();
        // });
        //
        // this.events.subscribe('initSectionsOptions', (cren) => {
        //     this.initCheckedItems();
        // });
    }

    ngOnInit() {

        this.getSections();
        // this.initCheckedItems();

        if(this.readonly) return;
        this.events.subscribe('-1' + '_newSection', (res) => {
            let secdata = this.sectionsManagerService.setSectionValuesByRes(res);
            this.sections.push(secdata);
        });
    }

    ngOnDestroy()
    {
        // this.events.unsubscribe('-1'+'_newSection');
    }


    getSections()
    {
        // let listAlready = false;
        // let storageKey = 'getSectionsTree_'+JSON.stringify(this.filters);
        // let storageRes = null;
        //
        // this.modelTools.getKey(storageKey).then((res)=>{
        //     storageKey = res;
        //     if(!listAlready && res)
        //         this.setSections(res)
        // });
        //
        // this.modelProvider.custom('getSectionsTree', this.filters).then((res) => {
        //     if(JSON.stringify(storageKey) == JSON.stringify(res))
        //         return;
        //     listAlready = true;
        //     this.setSections(res)
        //     this.modelTools.setKey(storageKey, res);
        // })
    }

    setSections(res)
    {
        if(!this.sections) this.sections = [];
        this.sections.length = 0;
        this.sectionsManagerService.setSectionsValues(res, this.sections)
    }


    // initCheckedItems() {
    //     for (var k in this.checkedItems)
    //         delete this.checkedItems[k];
    //
    //     this.checkedItemsData['amount'] = 0;
    //     this.checkedItemsData['tosection'] = null;
    // }




    // setReady() {
    //     this.checkedItemsData['amount'] = 0;
    //     for (var k in this.checkedItems) {
    //         if (this.checkedItems[k])
    //             this.checkedItemsData['amount']++
    //     }
    //
    //     this.checkedItemsData['ready'] = true;
    //     // let me = this;
    //     // setTimeout(function () {
    //     //     me.checkedItemsData['ready'] = true;
    //     // },100 )
    // }


    doReorder(e) {
        // let from = e.detail['from']
        // let to = e.detail['to']
        //
        // this.toupdate = []
        // e.detail.complete(this.items);
        // for(let k=0;k<this.items.length;k++)
        // {
        //     this.items[k]['data']['order'] = this.items.length-k
        //     this.toupdate.push(new Item({'id':this.items[k]['data']['id'], 'order':this.items[k]['data']['order']}))
        // }
    }

}
