// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content {
  max-width: 650px;
  margin: auto;
}

ion-list {
  border-top: 1px solid #dedede;
  margin: 0px;
  padding: 0;
}`, "",{"version":3,"sources":["webpack://./src/core/components/generic-form/generic-form.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,YAAA;AACF;;AAEA;EACE,6BAAA;EAEA,WAAA;EACA,UAAA;AAAF","sourcesContent":[".content {\n  max-width: 650px;\n  margin: auto;\n}\n\nion-list {\n  border-top: 1px solid #dedede;\n  //margin: 10px 0px;\n  margin: 0px;\n  padding: 0;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
