export class TicketMessage {
    data = {
        "id": null,
        "message": "",
        "visibleToAll": false,
        "business": null,
        "ticketId": null,
        "user": null,
        "userName": null,
        "created": null,
    };

    constructor(public data1: any) {
        this.data = data1;
    }

    prefix = "ticketMessage";
    title = "TicketMessage";
    dataFields = {
        id: {'title': 'id', 'value': null, 'type': 'number', 'readonly': true},
        created: {'title': 'Created', 'value': null, 'type': 'date', 'readonly': true},
        ticketId: {'title': 'ticketId', 'value': null, 'type': 'number', 'readonly': true},
        message: {'title': 'message', 'value': null, 'type': 'textarea', 'maxlength': 800},
        visibleToAll: {'title': 'visibleToAll', 'value': null, 'type': 'text'},
        business: {'title': 'business', 'value': null, 'type': 'text'},
        user: {'title': 'user', 'value': null, 'type': 'text'},
    }
}
