import { Component, OnInit } from '@angular/core';
import {OrderService} from "../../../../services/OrderService";
import {Events} from "../../../../../framework/providers/events";

@Component({
  selector: 'app-cart-status',
  templateUrl: './cart-status.component.html',
  styleUrls: ['./cart-status.component.scss'],
})
export class CartStatusComponent implements OnInit {

  public cartStatus;

  constructor(public orderService:OrderService, public events:Events) { }

  ngOnInit() {
    this.getCartStatus();

    this.events.subscribe('cartStatusChanged', (params) => {
      this.getCartStatus();
    });
  }

  getCartStatus() {
    this.cartStatus = this.orderService.getCartStatus();
  }

  refresh()
  {
    this.orderService.checkUserUpdates();
  }

}
