import {Component, Input, OnInit} from '@angular/core';
import {ModelClasses} from "../../../../core/model/modelClasses";
import {Events} from "../../../providers/events";
import {ModelToolsProvider} from "../../../providers/model-tools/model-tools";

@Component({
    selector: 'list-filter',
    templateUrl: './list-filter.component.html',
    styleUrls: ['./list-filter.component.scss'],
})
export class ListFilterComponent implements OnInit {

    @Input()
    public modelName: string;

    public model: any;

    @Input()
    public prefix: string;

    @Input()
    public filters: object;

    @Input()
    public fieldKey: string;

    @Input()
    public options: object = {};

    @Input()
    public fields: Array<string> = [];

    public models: Array<any> = [];

    public fieldData: object;
    public fieldsData: object;

    @Input()
    public activeFilters = {'checked':{}, 'checkedData': {}, 'checkedArr':[], 'count':0};

    constructor(public modelClasses: ModelClasses, public events: Events, public tools:ModelToolsProvider) {
    }

    ngOnInit() {
        let cls = this.modelClasses.getClass(this.modelName);
        this.model = new cls({});
        //
        // for(let key in this.fields)
        // {
        //   this.fieldsData[key] = this.model.dataFields[this.fieldKey]
        // }

        this.fieldData = this.model.dataFields[this.fieldKey];

        this.initFilters()
    }

    initFilters()
    {
        let key = this.fieldKey;
        if (this.fieldData['multipleFilterKey'])
            key = this.fieldData['multipleFilterKey'];

        if(!this.filters[key]) return;

        let key_filters =  this.filters[key].split(',');
        for(let k in key_filters){
            this.activeFilters['checked'][key_filters[k]] = true;
        }
    }

    setFilter(field, value) {
        console.log(value)
        this.activeFilters['checkedArr'].length = 0

        if(value === null) {
            this.filters[field] = -1;
            this.activeFilters['checked'][field] = false
            this.publishFilters();
            return
        }


        let model = {
            'data':{
                'name': this.tools.getTranslate(field)+'- '+convertDate(value, true),
            }
        }

        if(this.fieldData['type'] == 'datetime') {
            value = convertDate(value);
        }


        if(this.filters[field] == value)
        {
            return;
        }

        this.filters[field] = value;


        this.activeFilters['checked'][field] = true
        this.activeFilters['checkedData'][field] = model

        for(let f in this.activeFilters['checked']) {
            if(this.activeFilters['checked'][f])
                this.activeFilters['checkedArr'].push(f)
        }

        console.log(this.activeFilters)

        this.publishFilters()
    }

    setModelFilter(model) {
        // if(this.activeFilters['checked'][model.data.id])
        //   this.activeFilters['checked'][model.data.id] = -1;
        // else
        //   this.activeFilter = model.data.id;

        this.activeFilters['checked'][model.data.id] = !this.activeFilters['checked'][model.data.id];
        this.activeFilters['checkedData'][model.data.id] = model;

        this.updateFilters()
    }

    updateFilters() {
        let key = this.fieldKey;
        if (this.fieldData['multipleFilterKey'])
            key = this.fieldData['multipleFilterKey'];

        let arr = []
        for (let k in this.activeFilters['checked']) {
            if (this.activeFilters['checked'][k]) {
                arr.push(k);
            }
        }

        this.activeFilters['count'] = arr.length;
        this.activeFilters['checkedArr'] = arr;

        this.filters[key] = arr.join(',');
        ////console.logthis.filters)
        this.publishFilters();
    }

    publishFilters(){
        this.filters['offset'] = 0;
        this.events.publish(this.prefix + '_updateFilter', this.filters);
    }

    tabChanged(e)
    {
        if(!e.srcElement.localName.includes('group')) return;
        this.options['activeTab'] = e.detail['value']
    }

}

function convertDate(date, locaDate = false)
{
    if(locaDate){
        let locale = new Date(date)
        return locale.toLocaleDateString()
    }

    return date.split('T')[0]+'T00:00:00'

    // return '2023-01-02T00:00:00Z'
}