// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.create-btn {
  text-align: center;
  margin-top: 10px;
}

.text-size {
  font-size: 14px;
}

.error-text {
  font-size: 14px;
  color: #d33939;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/core/pages/register/register.page.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,gBAAA;AACF;;AAEA;EACE,eAAA;AACF;;AAEA;EACE,eAAA;EACA,cAAA;EACA,kBAAA;AACF","sourcesContent":[".create-btn {\n  text-align: center;\n  margin-top: 10px;\n}\n\n.text-size {\n  font-size: 14px;\n}\n\n.error-text {\n  font-size: 14px;\n  color: #d33939;\n  text-align: center;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
