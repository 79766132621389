import {Component, Input, OnInit} from '@angular/core';
import {ModelProvider} from "../../../../../framework/providers/modelProvider/ModelProvider";

import {AppConfig} from 'src/core/conf/main';
import {AdminService} from "../../../../../core/services/AdminService";
import {ModalController} from "@ionic/angular";

@Component({
    selector: 'app-calendar-wrapper',
    templateUrl: './calendar-wrapper.component.html',
    styleUrls: ['./calendar-wrapper.component.scss'],
})
export class CalendarWrapperComponent implements OnInit {
    @Input()
    sideModel; //list models and set self calendar for each result
    @Input()
    modeleventsType;
    @Input()
    onMainPage = false;
    @Input()
    miniCalendar = true;
    @Input()
    avoidSave;
    @Input()
    baseFilter;
    @Input()
    prefix;
    @Input()
    topPrefix;
    @Input()
    tableData;
    @Input()
    exTableData;

    @Input()
    dateModelInfoData;

    @Input()
    dateFieldType;
    @Input()
    dateFieldData;

    @Input()
    dateModel;
    @Input()
    dateModelSectionName;

    dateFieldDataIndex;

    ready = false;

    public appConfig = AppConfig;

    constructor(public modelProvider: ModelProvider, public adminService: AdminService, public modelController: ModalController) {
    }

    ngOnInit() {
        this.listSideModel();
        this.ready = true;
    }

    listSideModel() {
        if (this.sideModel) {
            let me = this;
            let filters = {};
            filters['sectionName'] = this.sideModel;
            filters['getEvents'] = true;
            filters[this.sideModel + 'modelOffset'] = 0;
            filters[this.sideModel + 'modelLimit'] = 1000;
            this.modelProvider.list('report', filters)
                .then(function (data: any) {
                    let extableData = data['models'][0].data['finalResReport'];
                    me.tableData[me.sideModel] = extableData[me.sideModel];
                });
        }
    }

    saveModelDateField() {
        this.adminService.markUpdated(this.exTableData[this.dateModelSectionName + 'Data'], this.dateModelSectionName,
            this.dateModel[this.dateFieldType['toField']], this.dateModel);

        this.adminService.markUpdated(this.exTableData[this.dateModelSectionName + 'Data'], this.dateModelSectionName,
            this.dateModel[this.dateFieldType['fromField']], this.dateModel);

        this.modelController.dismiss();
    };
}
