import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {IonInfiniteScroll} from "@ionic/angular";
import {ModelProvider} from "../../../framework/providers/modelProvider/ModelProvider";
import {Events} from "../../../framework/providers/events";

@Component({
    selector: 'infinite-pager',
    templateUrl: './infinite-pager.component.html',
    styleUrls: ['./infinite-pager.component.scss'],
})
export class InfinitePagerComponent implements OnInit {

    @Input()
    public modelName;

    @Input()
    public models: Array<any>;

    @Input()
    public prefix;

    @Input()
    public filters;

    @Input()
    public appDataKey;

    @Input()
    public options = {};

    @Input()
    public limit: number = 6;

    public offset: number = 0;
    private modelsCount: number;

    public initlimit:boolean = false;

    @ViewChild(IonInfiniteScroll, null) ioninfiniteScroll: IonInfiniteScroll;

    constructor(public events: Events, public modelProvider: ModelProvider) {
    }

    ngOnInit() {
        this.filters['limit'] = this.limit;
        this.filters['offset'] = this.offset;

        this.events.subscribe(this.prefix + '_modelUpdated', (res) => {
            this.modelsCount = res['modelsCount'];
            if(this.models.length == this.modelsCount) {
                this.ioninfiniteScroll.disabled = true;
            }

        });

        // //console.logthis.filters)

        this.events.subscribe(this.prefix + '_initPager', (res) => {
            this.filters['offset'] = 0;
            this.ioninfiniteScroll.disabled = false;
        });
    }

    nextPage(e) {

        if(this.models.length >= this.modelsCount) {
            this.ioninfiniteScroll.disabled = true;
            return;
        }

        let limit = this.filters['limit'];
        if(!limit)
            limit = this.limit;

        this.filters['limit'] = limit;

        if(!this.initlimit)
        {
            limit = this.models.length;
            this.initlimit = true;
        }

        if(!this.filters['offset']) this.filters['offset'] = 0;

        this.filters['offset'] += limit;

        // console.log('nextPage', this.models.length, this.modelsCount, this.filters)


        this.listNextPage(e);

        // this.events.publish(this.prefix+'_updateFilter', this.filters);
    }

    listNextPage(e) {
        if(this.appDataKey)
        {
            this.events.publish(this.prefix + '_nextPage', this.filters);
            this.stopInfinite(e);
            return
        }
        console.log("LIST NEXT PAGE", this.filters)
        this.modelProvider.list(this.modelName, this.filters).then((res) => {
            this.addModels(res['models'], e);
        })
    }

    addModels(addmodels, e) {
        for (let k in addmodels) {
            this.models.push(addmodels[k]);
        }

        if(this.models.length >= this.modelsCount) {
            this.ioninfiniteScroll.disabled = true;
            return;
        }

        this.stopInfinite(e)
    }


    stopInfinite(e) {
        if(!e) return;
        // this.ioninfiniteScroll.disabled = true;

        // let me = this;
        // setTimeout(function () {
            e.target.complete();
            // me.ioninfiniteScroll.disabled = !me.ioninfiniteScroll.disabled;
        // }, 200)
    }

}
