import {Component, Input, OnInit} from '@angular/core';
import {CartService} from "../../../../services/CartService";
import {Events} from "../../../../../framework/providers/events";
import {ModelToolsProvider} from "../../../../../framework/providers/model-tools/model-tools";

@Component({
  selector: 'app-sections-sales',
  templateUrl: './sections-sales.component.html',
  styleUrls: ['./sections-sales.component.scss'],
})
export class SectionsSalesComponent implements OnInit {

  @Input()
  public sections_sales:object;

  @Input()
  public sale_key:string;

  @Input()
  public mode:string = 'list';
  @Input()
  public view:string = 'small';
  @Input()
  public prefix:string;

  @Input()
  public isModal:boolean;

  public cartData:object;
  public cart_sections_sales:object;
  public sub_prefix:string = this.tools.getRandomize();

  constructor(public cartService:CartService, public appevents:Events, public tools:ModelToolsProvider) { }

  ngOnInit() {
    this.appevents.subscribe('cartCalcUpdated', (params) => {
      this.getData();
    }, this.sub_prefix);

    this.getData();
    this.cartData = this.cartService.getCartData()

  }

  ngOnDestroy()
  {
    //console.log("DESTORUY!")
    this.appevents.unsubscribe('cartCalcUpdated', this.sub_prefix);
  }

  getData(){
    let cartData=this.cartService.getCartData();
    this.cart_sections_sales = cartData['section_sales']

    //console.log(this.cart_sections_sales)
  }

  proceed()
  {
    if(this.prefix)
    {
      this.appevents.publish(this.prefix)
      this.tools.closeModal();
    }
  }

}
