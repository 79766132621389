// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.saved-cart-list ion-item .flex-nowrap {
  gap: 3px;
}
.saved-cart-list ion-item .flex-nowrap span {
  font-weight: bold;
}

ion-card.isPopup {
  box-shadow: none;
  margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/core/pages/cart/components/saved-cart/saved-cart.component.scss"],"names":[],"mappings":"AAEQ;EACE,QAAA;AADV;AAEU;EACE,iBAAA;AAAZ;;AAMA;EAEE,gBAAA;EACA,SAAA;AAJF","sourcesContent":[".saved-cart-list{\n    ion-item{\n        .flex-nowrap{\n          gap: 3px;\n          span{\n            font-weight: bold;\n          }\n        }\n    }\n}\n\nion-card.isPopup\n{\n  box-shadow: none;\n  margin: 0;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
