import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {PhotoViewer} from "@awesome-cordova-plugins/photo-viewer/ngx";
import {MainService} from "../../../services/MainService";
import {IonSlides} from "@ionic/angular";

@Component({
    selector: 'images-slider',
    templateUrl: './images-slider.component.html',
    styleUrls: ['./images-slider.component.scss'],
})
export class ImagesSliderComponent implements OnInit {
    @ViewChild('aSlider', {static: true}) slider: IonSlides;

    @Input()
    public images: Array<any> = [];

    @Input()
    public height: number = 200;

    @Input()
    public autoplay: boolean = true;

    @Input()
    public bottomListShow: boolean = false;
    public ready: boolean = false;

    @Input()
    public style: object = {};

    public options: object;

    public videotypes: string = '.mp4';

    public imgagesurls = [];

    constructor(private photoViewer: PhotoViewer, public mainService: MainService) {
    }

    ngOnInit() {
        this.style['max-height'] = this.height + 'px';
        this.style['background-size'] = 'contain';

        for (let k in this.images) {
            this.imgagesurls.push(this.images[k].data['imagefullurl']);
        }

        //console.logthis.imgagesurls)

        this.autoplay = this.mainService.sliderAutoPlay;

        this.options = {'autoplay': this.autoplay};

        this.ready = true;
    }

    showImage(image) {
        if (this.mainService.isbrowser) {
            window.open(image)
        } else {
            this.photoViewer.show(image);
        }
    }


    // playVideo(image)
    // {
    //     this.videoPlayer.play(image).then(() => {
    //      //console.log'video completed');
    //     }).catch(err => {
    //      //console.logerr);
    //     });
    // }

}
