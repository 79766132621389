import {Component, Input, OnInit} from '@angular/core';
import {BusinessService} from "../../services/BusinessService";
import {BusinessImages} from "../../../framework/model/core/business/businessImages";
import {Router} from "@angular/router";
import {AppSettings} from "../../conf/appSettings";
import {Events} from "../../../framework/providers/events";
import {ModelProvider} from "../../../framework/providers/modelProvider/ModelProvider";

@Component({
  selector: 'app-background',
  templateUrl: './app-background.component.html',
  styleUrls: ['./app-background.component.scss'],
})
export class AppBackgroundComponent implements OnInit {

    businessImages: BusinessImages;
    @Input()
    public blank: boolean = false;

    @Input()
    public template_background: boolean = false;

    @Input()
    public disableBackground: boolean = false;

    @Input()
    public global: boolean = false;

    @Input()
    public setLoading: boolean = false;

    @Input()
    public main: boolean = false;

    public sub_prefix: string = 'sub_' + Math.floor(Math.random() * Math.floor(1000000));

    // @Input()
    // public inital: boolean = false;

    public loading: boolean = false;
    public showBackButton: boolean = false;
    public loadingOffline: boolean = false;

    public appbackground:boolean;
    public settings;

    constructor(public events: Events, public businessService: BusinessService, public router:Router, public appsettings:AppSettings, public modelProvider:ModelProvider) {
        // //console.log'PAGE', this.router.url)
        this.settings = this.appsettings.getSettings('app');
        let pagename = this.getPageName();
        // //console.log'PAGE', pagename)
        this.appbackground = this.settings['background-pages'].includes(pagename)
    }

    ngOnInit() {
        this.events.subscribe('businessImagesSets', (params) => {
            this.businessImages = params['data']
        }, this.sub_prefix);

        this.getImages();

        if(this.setLoading) {
            this.events.subscribe('setBackLoadingOn', (params) => {
                // console.trace();
                this.setLoadingOn();
            }, this.sub_prefix);

            this.events.subscribe('setBackLoadingOff', (params) => {
                this.setLoadingOff();
            }, this.sub_prefix);

            this.events.subscribe('setLoadingOffline', (params) => {
                ////console.log'!!', 'setLoadingOffline')
                this.modelProvider.presentToast('checkYourConnection', true, 'dark', null, true)
                // this.loadingOffline = true;
                // this.mode
            }, this.sub_prefix);
        }

        // var me = this;
        // setInterval(function () {
        //     me.inital = false;
        // }, 2000);
    }

    ngOnDestroy()
    {
        this.events.unsubscribe('businessImagesSets', this.sub_prefix);
        this.events.unsubscribe('setBackLoadingOn', this.sub_prefix);
        this.events.unsubscribe('setBackLoadingOff', this.sub_prefix);
        this.events.unsubscribe('setLoadingOffline', this.sub_prefix);
    }

    getImages() {
        this.businessImages = this.businessService.getImages()
    }

    setLoadingOn() {
        this.loadingOffline = false;

        this.loading = true;
        this.interval()
    }

    setLoadingOff() {
        // //console.log"LOADING OFF");
        var me = this;
        this.showBackButton = false;
        this.loadingOffline = false;
        setTimeout(function () {
            me.loading = false;
        })

    }

    interval() {
        var me = this;
        let interval = setInterval(function () {
            // //console.log'INTERVAL!', me.loading)
            if (me.loading)
            {
                me.loadingOffline = true;
                let interval = setInterval(function () {
                    me.loadingOffline = false;
                }, 2000);
            }

            clearInterval(interval)
        }, 20000);
    }

    tryAgain()
    {
        this.setLoadingOff();
        this.events.publish('goToRoot');
    }

    getPageName()
    {
        let name = this.router.url.split('?')[0];
        name = name.substring(1).split('/')[0];
        // //console.log"PAGE NAME ", this.router)
        return name
    }
}
