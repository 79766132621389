import {Component, OnInit, Input} from '@angular/core';
import {ModalController} from "@ionic/angular";
import {OrderService} from "../../../../services/OrderService";
import {ItemProvider} from "../../../item/providers/item-provider/item-provider.service";
import {ItemDealPropsPage} from "../../../item/pages/item-deal-props/item-deal-props.page";
import {PropsProvider} from "../../../item/providers/props-provider/props-provider.service";
import {AppSettings} from "../../../../conf/appSettings";
import {Events} from "../../../../../framework/providers/events";

@Component({
    selector: 'cart-item-toolbar',
    templateUrl: './cart-item-toolbar.component.html',
    styleUrls: ['./cart-item-toolbar.component.scss'],
})
export class CartItemToolbarComponent implements OnInit {

    @Input()
    public options = {};

    public cartItemData: object = {'visible': false};

    public ready = false;
    public appuser;
    public settings;

    constructor(public events: Events, public orderService: OrderService, public propsProvider: PropsProvider,
                public itemProvider: ItemProvider, public modalController: ModalController, public appSettings: AppSettings) {
        this.events.unsubscribe('showCartItemBar');
        this.events.subscribe('showCartItemBar', (params) => {
            this.ready = false;
            let me = this;
            //console.log'baa');
            setTimeout(function () {
                me.setCartItem(params);
            })
            // //console.log'showCartItemBar', params);
        })

        this.appuser = this.orderService.getAppUser();
        this.settings = this.appSettings.getSettings('cart');
    }

    // ngOnDestroy()
    // {
    //   this.e
    // }

    ngOnInit() {
    }


    setCartItem(params) {
        let cartItem = this.orderService.getCartTicketItem(params['index']);
        if (this.cartItemData['visible'] && params['item'].data.id == this.cartItemData['model'].data.id) {
            this.cartItemData['cartItem'] = cartItem;
            this.cartItemData['amount'] += params['amount'];
            return
        }

        this.cartItemData = {
            'model': params['item'],
            'amount': params['amount'],
            'cartItem': cartItem,
            'props': cartItem['props'],
            'images': [],
            'index': cartItem['index'],

            'timer': 0,
            'progress-bar': false,
            'visible': true,
        }

        // let me = this;
        // this.cartItemData['interval'] = setInterval(function () {
        //     me.cartItemData['timer'] += 0.04;
        //     if (me.cartItemData['timer'] > 1.2)
        //         me.close();
        // }, 150)

        this.options['visible'] = this.cartItemData['visible'];
        this.ready = true;

        //console.logthis.cartItemData);
        // //console.logparams);
        // //console.logcartItem);
    }

    add(key) {
        this.cartItemData['amount'] += key;
        this.addToCart(key);
        this.stopInterval();
    }

    addToCart(key, index = null, props = null, comments = null) {
        if (!index) index = this.cartItemData['index'];
        if (!props) props = this.cartItemData['props'];

        // //console.logkey, index);
        this.itemProvider.addToCart(this.cartItemData['model'], key, false, false, true, props,
            null, null, this.cartItemData['cartItem']['price'], true, index, false, null, 0, {'comments': comments});
    }

    showProps() {
        let propsEvent = 'propsEvent' + '_' + +Math.floor(Math.random() * Math.floor(1000000));
        this.propsProvider.showPropsModal(this.cartItemData['model'], this.cartItemData['cartItem'], {}, propsEvent, 'proceed', false)
        this.events.subscribe(propsEvent, (res) => {
            // //console.logthis.cartItemData);
            // //console.logthis.cartItemData['amount']);

            let index = this.cartItemData['model'].data.id + '_' + +Math.floor(Math.random() * Math.floor(1000000));

            let props = this.propsProvider.getPropsArr(res['checkedProps']);
            this.addToCart(-this.cartItemData['amount'], this.cartItemData['cartItem']['index']);
            this.addToCart(this.cartItemData['amount'], index, props, res['comments']);

            this.cartItemData['cartItem'] = this.orderService.getCartTicketItem(index);

            let me = this;
            setTimeout(function () {
                me.close();
            }, 2000);
            // this.addToCart(model, 1, true, cartItemEdit, true, res['props'], null, null, null, true, cartItem['index'], true);
            this.events.unsubscribe(propsEvent);
        })
        this.stopInterval();
    }

    close() {
        this.cartItemData['cartItem']['images'] = this.cartItemData['cartItem']['images'].concat(this.cartItemData['images']);
        this.cartItemData['visible'] = false;
        this.options['visible'] = this.cartItemData['visible'];
        this.stopInterval();
    }

    stopInterval() {
        clearInterval(this.cartItemData['interval']);
        this.cartItemData['progress-bar'] = false;
    }


}
