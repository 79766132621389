import {Component, Input, OnInit} from '@angular/core';
import {AdminService} from "../../../../core/services/AdminService";
import {ModelProvider} from "../../../../framework/providers/modelProvider/ModelProvider";
import {Events} from "../../../../framework/providers/events";

@Component({
    selector: 'app-map-wrapper',
    templateUrl: './map-wrapper.component.html',
    styleUrls: ['./map-wrapper.component.scss'],
})
export class MapWrapperComponent implements OnInit {
    @Input()
    mapOnly = false;

    @Input()
    autoQuickAdd = false;

    @Input()
    contentModelFilter;
    @Input()
    baseFilter = null;
    @Input()
    modeleventsType;

    @Input()
    myprefix;
    @Input()
    prefix;
    @Input()
    tableData;

    @Input()
    exTableData;

    @Input()
    exFieldType;
    @Input()
    exFieldData;

    @Input()
    exModel;
    @Input()
    exModelSectionName;
    @Input()
    showMap = true;

    ready = false;
    addressBaseFilter;
    baseFilterJson;

    constructor(private adminService: AdminService, private modelProvider: ModelProvider, private events: Events) {
    }

    ngOnInit() {
        //
        //         'searchFieldValue1': this.dateModel['id']['value'],
        //         'searchField1': 'objectId',

        this.addressBaseFilter = {
            'search_in_field': true,
            'searchFieldValue': this.exModelSectionName,
            'searchField': 'contentType__model'
        }
        this.baseFilterJson = JSON.stringify(this.baseFilter);

        this.exModel = this.adminService.getNewModel(this.exModelSectionName, this.exTableData);
        let me = this;
        //wait for upper table to fire headers event - plaster.
        setTimeout(function () {
            me.ready = true;
        }, 1000);
    }

    refreshMap() {
        // this.events.publish(this.myprefix + 'map' + '_refresh');
        // let me = this;
        // this.showMap = false;
        // setTimeout(function () {
        //     me.showMap = true;
        // })
    }

}
