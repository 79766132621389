import {Component, Input, OnInit} from '@angular/core';
import {Events} from "../../../providers/events";
import {ModelClasses} from "../../../../core/model/modelClasses";
import {ModelToolsProvider} from "../../../providers/model-tools/model-tools";


@Component({
    selector: 'list-search',
    templateUrl: './list-search.component.html',
    styleUrls: ['./list-search.component.scss'],
})
export class ListSearchComponent implements OnInit {

    @Input()
    public modelName: string;

    @Input()
    public searchText: string;

    @Input()
    public filters: object = {};

    @Input()
    public sortOptions:object;

    @Input()
    public options:object = {'ready':false};

    @Input()
    public prefix: string;

    constructor(public events: Events, public modelClasses:ModelClasses, public tools:ModelToolsProvider) {
    }

    ngOnInit() {
        this.setSorting();
    }

    setSorting(){
        if(!this.modelName) return
        let cls = this.modelClasses.getClass(this.modelName)
        let model = new cls({})
        this.sortOptions = model.sortOptions;

        this.tools.getKey(this.modelName+'_sorting').then((res)=>{
            if(res){
                this.filters['ordering'] = res;
            }
            this.options['ready'] = true;
        })
    }

    search() {
        ////console.log'search!');
        this.filters['search'] = this.searchText;
        if(!this.searchText.length)
            delete this.filters['search'];

        this.updateFilter();
    }

    updateFilter()
    {
        console.log("UPDATE FILTERSS ", this.modelName, this.filters)
        if(this.filters['ordering'])
            this.tools.setKey(this.modelName+'_sorting', this.filters['ordering']);

        this.filters['offset'] = 0;
        this.filters['limit'] = 20;
        this.filters['clearFilters'] = true;
        this.events.publish(this.prefix + '_updateFilter', this.filters);
        this.events.publish(this.prefix + '_initPager', this.filters);
    }

}
