export class Coupon {
    data = {
        'id': null,
        "name": null,
        "code": null,
        "discount": null,
        "item_name": null,
        "expirationDate": null,
        "isPercentage": null,
    };

    constructor(public data1: any) {
        this.data = data1;
    }

    //shortHeaders = [];
    prefix = "coupon";
    title = "Coupon";
    dataFields = {
        id: {'title': 'ID', 'value': null, 'type': 'number', 'readonly': true},
        created: {'title': 'Created', 'value': null, 'type': 'date', 'readonly': true},
        updated: {'title': 'Updated', 'value': null, 'type': 'date', 'readonly': true},
        item_name: {'title': 'for', 'value': null, 'type': 'text', 'readonly': true},
        code: {'title': 'couponCode', 'value': null, 'type': 'text'},
        name: {'title': 'name', 'value': null, 'type': 'text'},
        discount: {'title': '%discount', 'value': null, 'type': 'text'},
        visible: {'title': 'active', 'value': null, 'type': 'boolean'},
        expirationDate: {'title': 'expirationDate', 'value': null, 'type': 'date'},
        isPercentage: {'title': 'prefix', 'value': null, 'type': 'text'},
    }
}
