// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.body {
  padding: 5px;
}

.bar.footer {
  background: #fff;
}
.bar.footer ion-button {
  width: 95%;
}`, "",{"version":3,"sources":["webpack://./src/core/pages/item/components/add-to-cart/add-to-cart.component.scss"],"names":[],"mappings":"AAAA;EAEE,YAAA;AAAF;;AAGA;EAEE,gBAAA;AADF;AAGE;EAEE,UAAA;AAFJ","sourcesContent":[".body\n{\n  padding:5px;\n}\n\n.bar.footer\n{\n  background:#fff;\n\n  ion-button\n  {\n    width:95%\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
