// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-toolbar {
  --background: var(--main-top);
  color: var(--main-top-text);
  --ion-toolbar-color: var(--main-top-text);
}
ion-toolbar ion-buttons ion-button.button-clear {
  --color: var(--main-top-text);
  --color-activated: var(--main-top-text);
}

.options-row {
  border-bottom: 1px solid #dedede;
}`, "",{"version":3,"sources":["webpack://./src/core/pages/item/pages/items-filter/items-filter.page.scss"],"names":[],"mappings":"AAAA;EACE,6BAAA;EACA,2BAAA;EACA,yCAAA;AACF;AACE;EACE,6BAAA;EACA,uCAAA;AACJ;;AAIA;EACE,gCAAA;AADF","sourcesContent":["ion-toolbar {\n  --background: var(--main-top);\n  color: var(--main-top-text);\n  --ion-toolbar-color: var(--main-top-text);\n\n  ion-buttons ion-button.button-clear {\n    --color: var(--main-top-text);\n    --color-activated: var(--main-top-text);\n  }\n}\n\n\n.options-row {\n  border-bottom: 1px solid #dedede;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
