import { Component, OnInit } from '@angular/core';

export class DesktopLink {
    data = {'title': null, 'objectId': null, 'contentTypeName': null};

    constructor(public data1:any) {
        this.data = data1;
    }

    frameworkModel = true;
    shortHeaders = [];
    prefix = "desktopLink";
    title = "DesktopLink";
    tableFields =
        ['id', 'user', 'created', 'title', 'text', 'type', 'objectId', 'contentType'];
    dataFields = {
        "id": {'value': null, 'type': 'number', 'readonly': true},
        "created": {'value': null, 'type': 'date', 'readonly': true},
        "objectId": {'value': null, 'type': 'number'},
        "contentType": {
            'title': 'Content Type',
            'value': null, 'type': 'modelscroll', 'displayField': 'contentTypeName',
            'modelscroll': 'ContentType', 'keyField': 'id', 'valueField': 'model'
        },
        "filter": {
            'title': 'Filter',
            'value': null, 'type': 'modelscroll',
            'displayField': 'filterName', 'required': true,
            'filterFunction': 'filterFilterFunction',
            'modelscroll': 'Filter', 'keyField': 'id', 'valueField': 'name'
        },
        "puserType": {
            'showDefault': true, 'value': null, 'type': 'modelscroll',
            'modelscroll': 'PuserType', 'required': true,
            'displayField': 'puserTypeName', 'keyField': 'id', 'valueField': 'name'
        },
    }

    //filterFilterFunction(model, filters) {
    //    filters['contentType'] = model.data.contentType;
    //    return filters;
    //}

    //getScrollFieldDisplay(desktopStat, fieldName) {
    //    var fields = {'name': desktopStat.data.contentTypeName + ": " + desktopStat.data.name,}
    //    return fields[fieldName] ? fields[fieldName] : false;
    //}

    //piechart(desktopStat) {
    //    $timeout(function () {
    //        var pie = [];
    //        for (var key in desktopStat.data.filtersRes)
    //            pie.push([desktopStat.data.filtersRes[key][0],
    //                desktopStat.data.filtersRes[key][1]])
    //
    //        var pieChart = c3.generate({
    //            bindto: '#pie-chart' + desktopStat.data.id,
    //            data: {
    //                columns: pie,
    //                type: 'donut'
    //            },
    //            donut: {
    //                label: {
    //                    format: function (value) {
    //                        return value;
    //                    }
    //                }
    //            }
    //        });
    //    }, 10);
    //}

}