// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-item {
  --padding: 0;
  --min-height: 72px;
  --max-height: 72px;
}
ion-item ion-checkbox {
  margin: 5px;
}
ion-item ion-icon {
  min-width: 40px;
}
ion-item ion-label {
  padding: 0 7px;
}

ion-item.checked {
  --background: #efefef;
}

.tree-divider {
  border-top: 1px solid #000;
  background: #fff;
}

.options-col {
  background: #fff;
  border-bottom: 1px solid #dedede;
}

.options-row .item-native .item-inner {
  padding: 0;
}
.options-row ion-button {
  height: 30px;
  width: 40px;
}
.options-row .margin-auto {
  margin: auto;
}

.loading-box ion-spinner {
  width: 25px;
  height: 25px;
}`, "",{"version":3,"sources":["webpack://./src/core/pages/admin/pages/sections-manager/components/sections-manager-tree/sections-manager-tree.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,kBAAA;EACA,kBAAA;AACF;AACE;EACE,WAAA;AACJ;AAEE;EACE,eAAA;AAAJ;AAGE;EACE,cAAA;AADJ;;AAKA;EAEE,qBAAA;AAHF;;AAMA;EACE,0BAAA;EACA,gBAAA;AAHF;;AAMA;EACE,gBAAA;EACA,gCAAA;AAHF;;AAQE;EACE,UAAA;AALJ;AAQE;EACE,YAAA;EACA,WAAA;AANJ;AASE;EACE,YAAA;AAPJ;;AAWA;EACE,WAAA;EACA,YAAA;AARF","sourcesContent":["ion-item {\n  --padding: 0;\n  --min-height: 72px;\n  --max-height: 72px;\n\n  ion-checkbox {\n    margin: 5px;\n  }\n\n  ion-icon {\n    min-width: 40px;\n  }\n\n  ion-label {\n    padding: 0 7px;\n  }\n}\n\nion-item.checked\n{\n  --background: #efefef;\n}\n\n.tree-divider {\n  border-top: 1px solid #000;\n  background: #fff;\n}\n\n.options-col {\n  background: #fff;\n  border-bottom: 1px solid #dedede;\n}\n\n.options-row {\n  //--min-height: 0;\n  .item-native .item-inner {\n    padding: 0;\n  }\n\n  ion-button {\n    height: 30px;\n    width: 40px;\n  }\n\n  .margin-auto {\n    margin: auto;\n  }\n}\n\n.loading-box ion-spinner {\n  width: 25px;\n  height: 25px\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
