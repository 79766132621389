import {Component, Input, OnInit} from '@angular/core';
import {Signature} from "../../../../../framework/model/general/signature";
import {NavController, ToastController} from "@ionic/angular";
import {Events} from "../../../../../framework/providers/events";
import {ModelProvider, ResInterface} from "../../../../../framework/providers/modelProvider/ModelProvider";
// import SignaturePad from "signature_pad";

@Component({
    selector: 'app-app-signature',
    templateUrl: './app-signature.component.html',
    styleUrls: ['./app-signature.component.scss'],
})
export class AppSignatureComponent implements OnInit {
    signaturePad: any;
    private signaturePadOptions: Object;

    @Input()
    model: any;

    signature: string = '';
    signatureModel: Signature;
    isDrawing: boolean = false;

    @Input()
    type: string;

    @Input()
    hideTitle: boolean = false;

    @Input()
    title: string

    @Input()
    canvasWidth: number = 400;

    @Input()
    canvasHeight: number = 200;

    @Input()
    prefix: string;

    @Input()
    callback: any;

    ready: boolean = true;

    constructor(public navController: NavController,
                public events: Events,
                public toastCtrl: ToastController,
                public modelProvider: ModelProvider) {
    }

    ngOnInit() {
        this.signaturePadOptions = { // Check out https://github.com/szimek/signature_pad
            'minWidth': 0.5,
            'maxWidth': 0.5,
            'canvasWidth': this.canvasWidth,
            'canvasHeight': this.canvasHeight - 10,
            'backgroundColor': '#f6fbff',
            'penColor': '#666a73'
        };
        console.log(this.model);
        return

        this.modelProvider.list('signature',
            {
                'contentTypeName': this.model.prefix,
                'objectId': this.model.data.id,
                'active': true,
                'type': this.type,
            }).then((res: ResInterface) => {

            if (res.models.length > 0) {
                this.signatureModel = res.models[0];
                this.signature = this.signatureModel.data.signatureImage;
            } else {
                this.signatureModel = new Signature({});
                this.signatureModel.data.type = this.type;
                this.signatureModel.data.objectId = this.model.data.id;
                this.modelProvider.create(this.signatureModel, {'contentTypeName': this.model.prefix})
            }

            this.loadSignature(this.signature);

        });
    }

    drawComplete() {
        this.isDrawing = false;
    }

    drawStart() {
        this.isDrawing = true;
    }

    ionViewDidEnter() {
        //this.signaturePad.clear()
        //this.storage.get('savedSignature').then((data) => {
        //  this.signature = data;
        //});
    }

    clearPad() {
        this.signaturePad.clear();
        this.signaturePad.backgroundColor = "rgb(0,0,0)";
        this.signaturePad.penColor = "rgb(0,0,0)";
        this.signature = null;
        this.signaturePad = null;
        this.ready = false;
        this.signatureModel.data.active = false;
        this.modelProvider.update(this.signatureModel, {}).then((res: ResInterface) => {
            this.events.publish(this.prefix, {'clear': true});
            this.ready = true;

            let me = this;
            setTimeout(function () {
                me.loadSignature();
            })
        });
    }

    async savePad() {
        if (this.signaturePad.isEmpty()) {
            console.log('signature not filled.')
            return;
        }

        // this.signature = this.signaturePad.toDataURL();
        const base64Data = this.signaturePad.toDataURL();
        //this.storage.set('savedSignature', this.signature);
        this.signaturePad.clear();

        this.signatureModel.data.signatureImage = base64Data;
        this.signature = base64Data;

        this.signatureModel.data.active = true;
        this.modelProvider.update(this.signatureModel, {}).then((res: ResInterface) => {
            console.log(this.prefix)
            if (this.prefix) {
                this.events.publish(this.prefix, {'save': true});
            }
        });

        const toast = await this.toastCtrl.create({
            header: 'Signature',
            message: 'New Signature saved',
            duration: 1,
            color: 'success',
            cssClass: 'no-shadow'
        });
        toast.present();
    }


    loadSignature(sigatureBase64 = null) {
        // const canvas = document.querySelector("canvas123");
        // this.signaturePad = new SignaturePad(canvas);
        const canvas = document.querySelector(".mesignature").querySelector("canvas");
        // this.signaturePad = new SignaturePad(canvas);

        this.signaturePad.minWidth = 1;
        this.signaturePad.maxWidth = 1;
        this.signaturePad.dotSize = 0.3;
        this.signaturePad.backgroundColor = "rgb(0,0,0)";
        this.signaturePad.penColor = "rgb(0,0,0)";
        if (sigatureBase64) this.signaturePad.fromDataURL(sigatureBase64);
        console.log('sugnature loaded!!!');
        return
        let signaturePad;
        // Returns signature image as data URL (see https://mdn.io/todataurl for the list of possible parameters)
        signaturePad.toDataURL(); // save image as PNG
        signaturePad.toDataURL("image/jpeg"); // save image as JPEG
        signaturePad.toDataURL("image/jpeg", 0.5); // save image as JPEG with 0.5 image quality
        signaturePad.toDataURL("image/svg+xml"); // save image as SVG

        // Draws signature image from data URL (mostly uses https://mdn.io/drawImage under-the-hood)
        // NOTE: This method does not populate internal data structure that represents drawn signature. Thus, after using #fromDataURL, #toData won't work properly.
        // signaturePad.fromDataURL("data:image/png;base64,iVBORw0K...");
        //
        // // Draws signature image from data URL and alters it with the given options
        // signaturePad.fromDataURL("data:image/png;base64,iVBORw0K...", {
        //     ratio: 1,
        //     width: 400,
        //     height: 200,
        //     xOffset: 100,
        //     yOffset: 50
        // });
        return

        // Returns signature image as an array of point groups
        const data = signaturePad.toData();

        // Draws signature image from an array of point groups
        signaturePad.fromData(data);

        // Draws signature image from an array of point groups, without clearing your existing image (clear defaults to true if not provided)
        signaturePad.fromData(data, {clear: false});

        // Clears the canvas
        signaturePad.clear();

        // Returns true if canvas is empty, otherwise returns false
        signaturePad.isEmpty();

        // Unbinds all event handlers
        signaturePad.off();

        // Rebinds all event handlers
        signaturePad.on();
    }
}
