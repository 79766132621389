// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mydiv {
  width: 200px;
  background: white;
  z-index: 911199;
  position: absolute;
  right: 0;
  top: -57px;
}

div[cdkDrag] {
  cursor: move;
  -webkit-user-select: none;
          user-select: none;
  z-index: 1231233;
}`, "",{"version":3,"sources":["webpack://./src/app/shaps/components/general/insurance-calc/insurance-calc.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,iBAAA;EACA,eAAA;EACA,kBAAA;EACA,QAAA;EACA,UAAA;AACF;;AACA;EACE,YAAA;EACA,yBAAA;UAAA,iBAAA;EACA,gBAAA;AAEF","sourcesContent":[".mydiv {\n  width: 200px;\n  background: white;\n  z-index: 911199;\n  position: absolute;\n  right: 0;\n  top: -57px;\n}\ndiv[cdkDrag] {\n  cursor: move;\n  user-select: none;\n  z-index: 1231233;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
