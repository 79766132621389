// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.signature {
  padding: 3px;
  border: 1px solid;
  margin: 0 auto;
}

canvas {
  display: block;
  border: 1px solid rgb(255, 255, 178);
  background-color: #ffffff;
}`, "",{"version":3,"sources":["webpack://./src/app/shaps/components/tools/app-signature/app-signature.component.scss"],"names":[],"mappings":"AAAA;EAEE,YAAA;EACA,iBAAA;EACA,cAAA;AAAF;;AAGA;EACE,cAAA;EACA,oCAAA;EACA,yBAAA;AAAF","sourcesContent":[".signature {\n   //width: 320px;\n  padding: 3px;\n  border: 1px solid;\n  margin: 0 auto;\n}\n\ncanvas {\n  display: block;\n  border: 1px solid rgb(255, 258, 178);\n  background-color: #ffffff;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
