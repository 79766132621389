import {Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Address} from "../../../model/core/address/address";

import {ModelProvider} from "../../../providers/modelProvider/ModelProvider";
import {AdminService} from "../../../../core/services/AdminService";
import {Events} from "../../../providers/events";
import {AppConfig} from "../../../../core/conf/main";

declare var google;
declare var STATES_LIST;

@Component({
    selector: 'app-map',
    templateUrl: './app-map.component.html',
    styleUrls: ['./app-map.component.scss'],
})
export class AppMapComponent implements OnInit, OnDestroy {
    appConfig = AppConfig;

    @ViewChild("map", {'static': true}) mapElement: ElementRef;
    map: any;

    @Input()
    sectionName = 'orderDetails';

    filters = {'ordering': '-id', 'type': 'moving', 'name': 'taarif', 'modelOffset': 0, 'modelLimit': 20};

    @Input()
    public address: any;
    @Input()
    public tableData: any;
    @Input()
    public exTableData: any;
    @Input()
    public extraData: any;

    @Input()
    public model: Address;

    @Input()
    public radius;

    @Input()
    public latLng;

    @Input()
    public prefix;

    @Input()
    public type = 'map';//walk


    public searchMarker;
    public markerRemoveTimeout;
    public mapPopupData;
    public states;
    public invalidMap: boolean;

    public geocoder = new google.maps.Geocoder();

    constructor(public events: Events,
                public adminService: AdminService, public modelProvider: ModelProvider) {
    }

    ngOnInit() {
        //console.log'start map');
        //console.logthis.tableData);
        console.log(this.latLng);
        console.log(this.extraData);

        this.address = this.adminService.getNewModel(this.sectionName, this.exTableData);

        // if (!this.address && !this.latLng && this.model && this.model.getAddress) {
        //     this.address = this.model.getAddress();
        // }


        let me = this;
        let domain = AppConfig.CDN_URL_DOMAIN;

        //get states first.
        me.states = STATES_LIST;

        if (me.latLng) {
            me.initMap(me.latLng.lat, me.latLng.lng);
            me.showCord(me.latLng.lat, me.latLng.lng);
            me.setCenter(me.latLng);
            //debugger
        } else if (me.tableData) {
            me.showResultsOnMap(me.tableData);
        }

        setTimeout(function () {
            me.map.setZoom(4);
        }, 1000);

        this.searchListen();


        // this.initMap(0, 0);

        // this.events.subscribe('showOnMap'+this.prefix, (filters) => {
        //     //console.logfilters);
        //     this.getData(filters);
        // data = {'type':'trip/single', models:[['lat','lng', 'model'],['lat','lng', 'model']]}

        // });
    }

    searchListen() {
        let me = this;
        //search on map
        //search on map
        //search on map
        //search on map
        // console.log('prefix listen ', this.prefix + 'mapSearchAddress');
        // this.events.subscribe('clearmapSearchAddress', (params) => {
        //     console.log('haaaaa');
        //     // if (me.searchMarker) {
        //     //     me.searchMarker.setMap(null);
        //     //     me.map.setZoom(4);
        //     // }
        // });

        this.events.subscribe('mapSearchAddress', (params) => {
            let newModel = params['newModel'];// , {'newModel': this.quickAddModel}

            console.log(newModel);
            console.log(newModel);
            console.log(newModel);
            let state = newModel['state']['value'];

            if (state && !newModel['city']['value'] && !newModel['zip']['value'] && !newModel['address']['value']) {
                let states = state.split(',');
                console.log('search state!!');
                for (let st1 of states) {
                    // this.searchMarker =
                    this.drawState(st1, 'search result', newModel['color'] ? newModel['color']['value'] : null);
                    me.map.setZoom(8);
                }
            } else {
                if (this.searchMarker) {
                    this.searchMarker.setMap(null);
                }

                let address = [];
                for (let key of ['address', 'city', 'state', 'zip']) {

                    console.log(address);
                    if (key == 'state') {
                        if (state) {
                            let states = state.split(',');
                            address.push(states[0]);
                        }
                    } else if (newModel[key]['value']) {
                        address.push(newModel[key]['value']);
                    }
                }

                this.modelProvider.custom('json', {address: address.join(', '), 'key': this.appConfig.googleMapsApi},
                    'maps.googleapis.com/maps/api/geocode', false, 'https://').then((res) => {
                    console.log(res);
                    if (!res['results']) return;
                    if (res['results'].length == 0) return;

                    const myLatLng = {
                        lat: res['results'][0]['geometry']['location']['lat'],
                        lng: res['results'][0]['geometry']['location']['lng']
                    };
                    this.setCenter(myLatLng);
                    //debugger
                    let marker1 = this.showCord(myLatLng['lat'], myLatLng['lng'], newModel['radius']['value'],
                        null, newModel['color'] ? newModel['color']['value'] : null);
                    me.map.setZoom(8);
                    this.searchMarker = marker1;
                });
            }
        });
    }

    ngOnDestroy(): void {
        this.events.unsubscribe('showOnMap');
        this.events.unsubscribe('mapSearchAddress');
    }

    ngAfterViewInit() {
        let me = this;
        setTimeout(function () {
            if (!me.map) me.initMap(0, 0);
        });

        if (this.latLng) {
            // this.showCord(this.latLng[0], this.latLng[1]);
        } else {
            this.mapInitializer();
        }
    }

    initMap(lat, lng) {
        let latLng = new google.maps.LatLng(lat, lng);
        let mapOptions = {
            center: latLng,
            streetViewControl: true,
            zoom: 2,
            mapTypeId: google.maps.MapTypeId.ROADMAP
        };

        this.map = new google.maps.Map(this.mapElement.nativeElement, mapOptions);

        /*us*/
        let myLatLng = {lat: 30.06, lng: -90.12};

        this.setCenter(myLatLng);
    }

    showCord(lat, lng, radius = null, text = '', color = null, extraData = null) {
        console.log('show cord: ', text, ' -> ', lat, lng);
        const myLatLng = {lat: lat, lng: lng};

        let me = this;
        if (!this.map) {
            this.initMap(lat, lng);
        }
        //
        // let colors = ['primary', 'secondary', 'tertiary', 'success', 'warning', 'danger', 'light', 'medium', 'dark'];
        // let colorsFile = ['blue', 'brown', 'darkgreen', 'green', 'orange', 'pink', 'purple', 'red', 'yellow'];
        //
        let mainImageUrl;
        if (extraData && extraData['model'] && extraData['model']['main']['mainImageUrl']) {
            mainImageUrl = extraData['model']['main']['mainImageUrl'];
        }

        // const image = "https://shapsstatic-a872.kxcdn.com/admin09/googlemapMarkers/" + (colorsFile[colorIndex]) + "_MarkerC.png";
        let marker = new google.maps.Marker({
            map: me.map,
            position: myLatLng,
            extraData: extraData ? extraData : {},
            icon: mainImageUrl,
            label: {
                className: 'marker-label',
                fontWeight: 'bold',
                color: color,
                text: text ? text : ' ',
                fontSize: '21px',
            },
        });

        //extra icons
        // if (extraData['model']['main']['mainImageUrl1']) {
        //     myLatLng['lng'] = myLatLng['lng']-0.1
        //     myLatLng['lat'] = myLatLng['lat']-0.1
        //     let marker1 = new google.maps.Marker({
        //         map: me.map,
        //         position: myLatLng,
        //         extraData: extraData ? extraData : {},
        //         icon: extraData['model']['main']['mainImageUrl1']
        //     });
        // }

        //
        google.maps.event.addListener(marker, 'mouseover', function () {
            console.log('marker!!');
            // marker.setIcon(icon2);

            let overlay = new google.maps.OverlayView();
            overlay.draw = function () {
            };
            overlay.setMap(me.map);
            let proj = overlay.getProjection();
            let pos = marker.getPosition();
            if (!proj) return;
            let p = proj.fromLatLngToContainerPixel(pos);
            console.log(p);
            console.log(marker['extraData']['model']);
            me.mapPopupData = null;
            setTimeout(function () {
                me.mapPopupData = {data: marker['extraData'], 'left': p['x'], 'top': p['y']};
            }, 0);
            clearTimeout(me.markerRemoveTimeout);
        });

        //remove marker
        google.maps.event.addListener(marker, 'mouseout', function () {
            console.log('marker out!');
            me.markerRemoveTimeout = setTimeout(function () {
                me.mapPopupData = null;
                // marker.setIcon(icon1);
            }, 2000);
        });

        // let customTxt = "<div>Blah blah sdfddd<ul><li>Blah 1<li>blah 2 </ul></div>"
        // let TxtOverlay = this.getTxtOverlay();
        // let txt = new TxtOverlay(myLatLng, customTxt, "customBox", this.map);

        // marker.setLabel('Hi! how are you?');
        //console.log'show cord tradius??', radius);

        if (!color) color = '#' + Math.floor(Math.random() * 16777215).toString(16);
        if (!color.includes('#')) color = '#' + color;
        if (radius) {
            const cityCircle = new google.maps.Circle({
                strokeColor: "#00000",
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: color,
                fillOpacity: 0.35,
                map: me.map,
                center: myLatLng,
                radius: radius * 1000
            });
        }

        return marker;
    }

    getData(exFilters) {
        let filters = this.filters;
        let sectionName = 'addressDetails';
        // filters['baseFilter'] = JSON.stringify(data['baseFilter']);

        for (let key in exFilters) {
            filters[key] = exFilters[key];
        }

        filters['sectionName'] = sectionName;
        let me = this;

        // let tableData = this.adminService.manageModels[sectionName];
        // me.showResultsOnMap(tableData);

        this.modelProvider.list('report', filters)
            .then(function (data: any) {
                let tableData = data['models'][0].data['finalResReport'];
                let data1 = tableData[sectionName + 'Data'];
                //console.logtableData);
                me.showResultsOnMap(tableData[sectionName]);
                if (!me.map) me.initMap(0, 0);
            });
    }

    // Function to fetch city polygon data
    async getCityPolygon(cityName) {
        return

        // const axios = require('axios');
        // try {
        //     const url = `https://nominatim.openstreetmap.org/search?q=${encodeURIComponent(cityName)}&format=json&polygon_geojson=1&limit=1`;
        //     const response = await axios.get(url);
        //
        //     if (response.data && response.data.length > 0) {
        //         const cityGeoJSON = response.data[0].geojson;
        //         const cityPolygon = this.extractPolygonCoordinates(cityGeoJSON);
        //         return cityPolygon;
        //     } else {
        //         console.log(`City "${cityName}" not found.`);
        //         return null;
        //     }
        // } catch (error) {
        //     console.error('Error fetching city polygon:', error.message);
        //     return null;
        // }
        return null;
    }

    // Function to extract polygon coordinates from GeoJSON data
    extractPolygonCoordinates(geoJSON) {
        const coordinates = [];
        const type = geoJSON.type;

        if (type === 'Polygon') {
            for (const coord of geoJSON.coordinates[0]) {
                coordinates.push(new google.maps.LatLng(coord[1], coord[0]));
            }
        } else if (type === 'MultiPolygon') {
            for (const polygon of geoJSON.coordinates) {
                for (const coord of polygon[0]) {
                    coordinates.push(new google.maps.LatLng(coord[1], coord[0]));
                }
            }
        }

        return coordinates;
    }

    // List of cities
    // Fetch polygon for each city
    async fetchCityPolygons(cities) {
        for (const city of cities) {
            const polygon = await this.getCityPolygon(city);
            if (polygon) {
                console.log(`City: ${city}, Polygon: `, polygon);
                const poli = new google.maps.Polygon({
                    paths: polygon,
                    strokeColor: "#ffffff",
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillColor: '#8bff41',
                    fillOpacity: 0.35,
                });
                poli.setMap(this.map);
            }
        }
    }

    showResultsOnMap(tableData) {


        let routes = {};
        let centered = false;

        tableData.sort(function (a, b) {
            return a['index']['value'] - b['index']['value'];
        });

        let cities = [];
        let pointsById = {}
        for (let i in tableData) {
            let model = tableData[i];

            let objectId = model['objectId']['value'];
            let id = model['id']['value'];
            pointsById[id] = model;

            if (id == -1) {
                continue;
            }
            let lat = model['lat']['value'],
                lng = model['lng']['value'];
            let statesStr = model['state']['value'];
            let states;
            if (statesStr) {
                states = statesStr.split(',');
            }
            if (states && states.length > 0
                && (!model['city']['value'] && !model['zipCode']['value'] && !model['address']['value'])) {
                let myLatLng, mainCord;

                for (let st1 of states) {
                    //model['main']['displayName']
                    mainCord = this.drawState(st1, model['main']['displayName'], model['main']['color'], {'model': model});
                }

                if (mainCord) {
                    myLatLng = mainCord;
                } else {
                    myLatLng = {lat: lat, lng: lng};
                }
                if (this.map) {
                    this.setCenter(myLatLng);
                    //debugger
                }
            } else {//marker  - not state
                if (parseFloat(lat) && parseFloat(lng)) {
                    //
                    this.showCord(lat, lng, model['radius']['value'],
                        model['main']['displayName'],
                        model['color'] ? model['color']['value'] : null,
                        {'model': model});

                    if (!centered) {
                        centered = true;
                        const myLatLng = {lat: lat, lng: lng};
                        this.setCenter(myLatLng);
                        //debugger
                    }

                    // waypoints.push({location: {lat: lat, lng: lng}, 'stopover': true})

                    //path!!!!
                    //path!!!!
                    //path!!!!
                }
            }

            let s = [];
            if (model['city']['value']) s.push(model['city']['value']);
            if (model['state']['value']) s.push(model['state']['value']);
            if (model['zipCode']['value']) {
                // this.getCityNameFromZip(model['city']['value'], model['zipCode']['value']);
                s.push(model['zipCode']['value']);
            }
            if (s.length > 0) {
                cities.push(s.join(', '));
            }
        }

        let trips = this.exTableData['addressDetailsData']['trips'];
        console.log('trip!?!!?', trips)
        let address;
        for (let tripId in trips) {
            console.log('trip!!', tripId)
            if (!routes[tripId]) routes[tripId] = [];
            for (let i in trips[tripId]) {
                address = pointsById[trips[tripId][i]];
                if (address && address.lat.value) {
                    routes[tripId].push({location: {lat: address.lat.value, lng: address.lng.value}, 'stopover': true});
                }
            }
        }

        console.log('routes:')
        console.log(routes)

        //if objectId has more than one address - show route.
        //console.logroutes);
        for (let key in routes) {
            let waypoints = routes[key];
            //console.logwaypoints);
            if (waypoints.length > 1) {
                let directionsService = new google.maps.DirectionsService();
                let directionsDisplay = new google.maps.DirectionsRenderer();
                var request = {
                    origin: waypoints[0]['location'],
                    destination: waypoints[waypoints.length - 1]['location'],
                    waypoints: waypoints,
                    optimizeWaypoints: true,
                    provideRouteAlternatives: false,
                    travelMode: 'DRIVING'
                };
                //console.logrequest);

                directionsDisplay.setMap(this.map);
                directionsService.route(request, function (response, status) {

                    //console.log'status!!');
                    //console.logstatus);

                    if (status == 'OK') {
                        directionsDisplay.setDirections(response);
                    }
                });
            }
        }

        if (cities) {
            this.fetchCityPolygons(cities);//['New York', 'Los Angeles', 'London', 'Paris']);
        }

    }

    getCityNameFromZip(cityName, zipCode) {
        const geocoder = new google.maps.Geocoder();
        debugger
        geocoder.geocode({address: cityName + ', ' + zipCode}, (results, status) => {
            if (status === google.maps.GeocoderStatus.OK) {
                if (results.length > 0) {
                    const addressComponents = results[0].address_components;
                    let city = '';
                    for (const component of addressComponents) {
                        if (component.types.includes('locality')) {
                            city = component.long_name;
                            break;
                        }
                    }
                    console.log('City Name:', city);
                } else {
                    console.log('No results found for ZIP code:', zipCode);
                }
            } else {
                console.error('Geocode error:', status);
            }
        });
    }

    setCenter(myLatLng) {
        this.map.setCenter(myLatLng);

        let panorama = new google.maps.StreetViewPanorama(
            document.getElementById("street-view"),
            {
                position: myLatLng,
                pov: {heading: 0, pitch: 0},
                zoom: 1,
            }
        );
        this.map.setStreetView(panorama);
    }

    //show state on map
    //show state on map
    //show state on map
    drawState(state, text, color, extraData = {}) {
        console.log('state:', state)
        console.log('state color:', color)

        let cords = this.states[state];
        let lng, lat;
        let i = 0;
        let mainTriangleCoords = null;
        // let color = '#' + Math.floor(Math.random() * 16777215).toString(16);

        for (let k in cords) {
            let triangleCoords = [];

            for (let k1 in cords[k]) {
                triangleCoords = [];
                for (let k2 in cords[k][k1]) {
                    lat = cords[k][k1][k2][1];
                    lng = cords[k][k1][k2][0];
                    if (!lat) {
                        lat = cords[k][k1][1];
                        lng = cords[k][k1][0];
                    }

                    if (lat) {
                        triangleCoords.push({lat: parseFloat(lat ? lat : 0), lng: parseFloat(lng ? lng : 0)});
                        if (!mainTriangleCoords) {
                            mainTriangleCoords = triangleCoords[0];
                        }
                    }
                }

                // Construct the polygon.
                const poli = new google.maps.Polygon({
                    paths: triangleCoords,
                    strokeColor: "#ffffff",
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillColor: color,
                    fillOpacity: 0.35,
                });
                poli.setMap(this.map);
            }

            if (i < 1 && triangleCoords.length > 0) {
                this.showCord(triangleCoords[0]['lat'], triangleCoords[0]['lng'], 0, text, color, extraData);
                this.setCenter(triangleCoords[0]);
                //debugger
            }
            i++;
        }

        if (mainTriangleCoords) {
            return {lat: mainTriangleCoords['lat'], lng: mainTriangleCoords['lng']};
        }
    }

    mapInitializer() {

        if (this.address) {
            let me = this;
            this.geocoder.geocode({'address': this.address}, function (results, status) {
                //console.log'map results:');
                //console.logresults);
                if (!results.length) {
                    me.invalidMap = true;
                    return
                }
                me.invalidMap = false;
                if (status == google.maps.GeocoderStatus.OK) {
                    let lat = results[0].geometry.location['lat']();
                    let lng = results[0].geometry.location['lng']();

                    // , results[0].geometry.location
                    me.showCord(lat, lng);

                } else {
                    alert('Geocode was not successful for the following reason: ' + status);
                }
            });
        }
    }
}
