import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {AuthGuardService} from '../core/services/AuthGuradService';
import {MainPageModule} from "../core/pages/main/main.module";
import {TaarifPageModule} from "./shaps/taarif/taarif.module";
import {CartPageModule} from "../core/pages/cart/cart.module";
import {CartDetailsPageModule} from "../core/pages/cart/pages/cart-details/cart-details.module";
import {CartSummaryPageModule} from "../core/pages/cart/pages/cart-summary/cart-summary.module";
import {PaymentPageModule} from "../core/pages/cart/pages/payment/payment.module";
import {OrderPageModule} from "../core/pages/order/order.module";
import {StorePageModule} from "../core/pages/store/store.module";
import {SectionPageModule} from "../core/pages/section/section.module";
import {AboutPageModule} from "../core/pages/about/about.module";
import {LoginPageModule} from "../core/pages/login/login.module";
import {SearchPageModule} from "../core/pages/search/search.module";
import {ProfilePageModule} from "../core/pages/profile/profile.module";
import {FavoritesPageModule} from "../core/pages/favorites/favorites.module";
import {SalesPageModule} from "../core/pages/sales/sales.module";
import {ItemPageModule} from "../core/pages/item/item.module";
import {SectionsListPageModule} from "../core/pages/admin/pages/sections-manager/pages/sections-list/sections-list.module";
import {SectionItemsManagerPageModule} from "../core/pages/admin/pages/sections-manager/pages/section-items-manager/section-items-manager.module";
import {SectionsManagerTabsPageModule} from "../core/pages/admin/pages/sections-manager/pages/sections-manager-tabs/sections-manager-tabs.module";
import {SectionsManagerSummaryPageModule} from "../core/pages/admin/pages/sections-manager/pages/sections-manager-summary/sections-manager-summary.module";
import {OrdersPageModule} from "../core/pages/orders/orders.module";
import {RegisterPageModule} from "../core/pages/register/register.module";
import {ContactPageModule} from "../core/pages/contact/contact.module";
import {MakerPageModule} from "../core/pages/makers/pages/maker/maker.module";
import {MakersPageModule} from "../core/pages/makers/makers.module";
import {TicketPageModule} from "../core/pages/support/ticket/ticket.module";
import {ForgetPasswordPageModule} from "../core/pages/login/pages/forget-password/forget-password.module";
import {ResetPasswordPageModule} from "../core/pages/login/pages/reset-password/reset-password.module";
import {SupportPageModule} from "../core/pages/support/support.module";
import {EnterCodePageModule} from "../core/pages/login/pages/enter-code/enter-code.module";
import {BusinessManagerPageModule} from "../core/pages/admin/pages/business-manager/business-manager.module";
import {TechnicalLiteraturePageModule} from "../core/pages/technical-literature/technical-literature.module";
import {BacktostockPageModule} from "../core/pages/backtostock/backtostock.module";
import {UsersManagerPageModule} from "../core/pages/users-manager/users-manager.module";
import {UserInfoPageModule} from "../core/pages/users-manager/pages/user-info/user-info.module";
import {StockReportsPageModule} from "../core/pages/stock-reports/stock-reports.module";
import {ClientInvoicePageModule} from "../core/pages/client-invoice/client-invoice.module";
import {CatalogListPageModule} from "../core/pages/catalog-list/catalog-list.module";
import {DealPageModule} from "../core/pages/deals/pages/deal/deal.module";
import {DealsPageModule} from "../core/pages/deals/deals.module";
import {ReportsPageModule} from "../core/pages/reports/reports.module";
import {StockPageModule} from "../core/pages/item/pages/stock/stock.module";
import {OrderHandlePageModule} from "../core/pages/orders/pages/order-handle/order-handle.module";
import {MainAgentsPageModule} from "./shaps/main/main-agents/main-agents.module";
import {NewItemPageModule} from "../core/pages/item/pages/new-item/new-item.module";
import {SignupPageModule} from "./shaps/main/signup/signup.module";
import {SearchDevicePageModule} from "./shaps/pages/search-device/search-device.module";
import {AdminModelPageModule} from "./shaps/pages/admin-model/admin-model.module";
import {AgentCompaniesPage} from "../core/pages/agent/agent-companies/pages/agent-companies/agent-companies.page";
import {AgentCompaniesPageModule} from "../core/pages/agent/agent-companies/pages/agent-companies/agent-companies.module";

const routes: Routes = [
    {
        path: '',
        redirectTo: 'main',
        pathMatch: 'full'
    },
    {path: 'main', loadChildren:() => MainPageModule},
    {
        path: 'cart', loadChildren:() => CartPageModule,
        canActivate: [AuthGuardService]
    },
    {
        path: 'cart-details',
        loadChildren:() => CartDetailsPageModule,
        canActivate: [AuthGuardService]
    },
    {
        path: 'cart-summary',
        loadChildren:() => CartSummaryPageModule,
        canActivate: [AuthGuardService]
    },
    {
        path: 'cart-payment',
        loadChildren:() => PaymentPageModule,
        canActivate: [AuthGuardService]
    },
    {
        path: 'order/:id',
        loadChildren:() => OrderPageModule,
        canActivate: [AuthGuardService]
    },
    {path: 'store', loadChildren:() => StorePageModule},
    {path: 'section/:id', loadChildren:() => SectionPageModule},
    {path: 'search', loadChildren:() => SearchPageModule},
    {path: 'about', loadChildren:() => AboutPageModule},
    {path: 'login', loadChildren:() => LoginPageModule},
    {
        path: 'profile',
        loadChildren:() => ProfilePageModule,
        canActivate: [AuthGuardService]
    },
    {
        path: 'favorites', loadChildren:() => FavoritesPageModule,
        canActivate: [AuthGuardService]
    },
    {
        path: 'sales', loadChildren:() => SalesPageModule,
        // canActivate: [AuthGuardService]
    },
    {path: 'item/:id', loadChildren:() => ItemPageModule},
    {
        path: 'sections-list/:id',
        loadChildren:() => SectionsListPageModule,
        // canActivate: [AuthGuardService]
    },
    {
        path: 'section-items-manager',
        loadChildren:() => SectionItemsManagerPageModule,
        // canActivate: [AuthGuardService]
    },
    {
        path: 'sections-manager-tabs',
        loadChildren:() => SectionsManagerTabsPageModule
    },
    {
        path: 'sections-manager-summary',
        loadChildren:() => SectionsManagerSummaryPageModule
    },


    {
        path: 'orders',
        loadChildren:() => OrdersPageModule,
        canActivate: [AuthGuardService]
    },
    {path: 'register', loadChildren:() => RegisterPageModule},
    {path: 'contact', loadChildren:() => ContactPageModule},
    {path: 'maker/:id', loadChildren:() => MakerPageModule},
    {path: 'makers', loadChildren:() => MakersPageModule},

    {
        path: 'support', loadChildren:() => SupportPageModule,
        canActivate: [AuthGuardService]
    },
    {path: 'ticket/:id', loadChildren:() => TicketPageModule},
    {
        path: 'forget-password',
        loadChildren:() => ForgetPasswordPageModule
    },
    {
        path: 'reset-password',
        loadChildren:() => ResetPasswordPageModule
    },
    {path: 'enter-code', loadChildren:() => EnterCodePageModule},

    {
        path: 'business-manager',
        loadChildren:() => BusinessManagerPageModule,
        canActivate: [AuthGuardService]
    },
    {
        path: 'technical-literature',
        loadChildren:() => TechnicalLiteraturePageModule
    },
    {path: 'backtostock', loadChildren:() => BacktostockPageModule},
    {path: 'users-manager', loadChildren:() => UsersManagerPageModule},
    {
        path: 'user-info/:id',
        loadChildren:() => UserInfoPageModule,
        canActivate: [AuthGuardService]
    },
    {
        path: 'stock-reports', loadChildren:() => StockReportsPageModule,
        canActivate: [AuthGuardService]
    },
    {
        path: 'client-invoice/:id',
        loadChildren:() => ClientInvoicePageModule
    },
    {
        path: 'client-invoice',
        loadChildren:() => ClientInvoicePageModule,
        canActivate: [AuthGuardService]
    },
    {path: 'catalog-list', loadChildren:() => CatalogListPageModule},
    {path: 'deal/:id', loadChildren:() => DealPageModule},
    {path: 'deals', loadChildren:() => DealsPageModule},
    {path: 'agent-clients', loadChildren:() => AgentCompaniesPageModule},
    {
        path: 'order-handle',
        loadChildren:() => OrderHandlePageModule
    },
    {path: 'reports', loadChildren:() => ReportsPageModule},
    {
        path: 'stock', loadChildren:() => StockPageModule,
        canActivate: [AuthGuardService]
    },
    {
        path: 'taarif', loadChildren:() =>TaarifPageModule,
        canActivate: [AuthGuardService]
    },
    {
        path: 'taarif/:currentSectionKey', loadChildren:() => TaarifPageModule,
        canActivate: [AuthGuardService]
    },
    {
        path: 'taarif/:currentSectionKey/:rowIndex', loadChildren:() => TaarifPageModule,
        canActivate: [AuthGuardService]
    },
    {
        path: 'main-agents', loadChildren:() => MainAgentsPageModule,
        canActivate: [AuthGuardService]
    },
    {
        path: 'new-item', loadChildren:() => NewItemPageModule,
        canActivate: [AuthGuardService]
    },
    {path: 'signup', loadChildren:() => SignupPageModule},
    {path: 'searchDevice', loadChildren:() => SearchDevicePageModule},
    {path: 'admin-model/:secNameId', loadChildren:() => AdminModelPageModule},
    {path: 'admin-model', loadChildren:() => AdminModelPageModule},
  {
    path: 'client-page/:id/:counter',
    loadChildren: () => import('../core/pages/agent/client-page/client-page.module').then(m => m.ClientPagePageModule)
  },
  {
    path: 'contacts',
    loadChildren: () => import('../core/pages/contacts/contacts.module').then(m => m.ContactsPageModule)
  },
  {
    path: 'item-article',
    loadChildren: () => import('../core/pages/item/pages/item-article/item-article.module').then(m => m.ItemArticlePageModule)
  },
  {
    path: 'model-list',
    loadChildren: () => import('../core/components/generic-form/components/model-list/model-list.module').then(m => m.ModelListPageModule)
  },
  {
    path: 'items',
    loadChildren: () => import('../core/pages/item/pages/items/items.module').then(m => m.NewItemPageModule)
  },





];


@NgModule({
    imports: [
        RouterModule.forRoot(routes,
            {
                preloadingStrategy: PreloadAllModules,
                useHash: true,
            })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
    constructor() {
    }
}


// import { NgModule } from '@angular/core';
// import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
//
// const routes: Routes = [
//   {
//     path: 'home',
//     loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
//   },
//   {
//     path: '',
//     redirectTo: 'home',
//     pathMatch: 'full'
//   },
// ];
//
// @NgModule({
//   imports: [
//     RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
//   ],
//   exports: [RouterModule]
// })
// export class AppRoutingModule { }
