import {Component, OnInit} from '@angular/core';

export class WeeklyReport {
    data = {
            "id": null,
            "sold": null,
            "income": null,
            "returned": null,
            "startStock": null,
            "stock": null,
            "fromdate": null,
            "todate": null,
            "comments": "",
            "business": null,
            "itemToUser": null,
            "itemCode": null
    };

    constructor(public data1: any) {
        this.data = data1;
    }

    //shortHeaders = [];
    prefix = "weeklyReport";
    title = "weeklyReport";
    dataFields = {
        id: {'title': 'ID', 'value': null, 'type': 'number', 'readonly': true},
        created: {'title': 'Created', 'value': null, 'type': 'date', 'readonly': true},
        updated: {'title': 'Updated', 'value': null, 'type': 'date', 'readonly': true},
        startStock: {'title': 'startStock', 'value': null, 'type': 'text'},
        stock: {'title': 'finalStock', 'value': null, 'type': 'text'},
        sold: {'title': 'sold', 'value': null, 'type': 'text'},
        income: {'title': 'income', 'value': null, 'type': 'text'},
        returned: {'title': 'returned', 'value': null, 'type': 'text'},
    }
}