import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'appfile',
  templateUrl: './appfile.component.html',
  styleUrls: ['./appfile.component.scss'],
})
export class AppfileComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
