import { Component, OnInit } from '@angular/core';

export class ModelStatus {
	data = {};

	constructor(public data1: any) {
		this.data = data1;
	}

	title = "ModelStatus";
	prefix = "modelStatus";
	icon = "glyphicon glyphicon-book";
	showDefaultsCols = true;

	dataFields = {
		"id": { 'value': null, 'type': 'number', 'readonly': true },
		"created": { 'value': null, 'type': 'date', 'readonly': true },
		"name": { 'value': null, 'type': 'text', 'required': true, 'maxlength':30 },
		"description": { 'value': null, 'type': 'textarea', 'maxlength':300 },
		"color": { 'value': null, 'type': 'text', 'maxlength':30, 'readonly':true },
		"icon": { 'value': null, 'type': 'text', 'maxlength':30, 'readonly':true },
		"fieldName": { 'value': null, 'type': 'text', 'readonly': true, },
		"default": { 'value': null, 'type': 'boolean', 'readonly':true },

		"contentType": {
			'value': null, 'type': 'modelscroll', 'readonly': true,
			'displayField': 'contentTypeName',
			'modelscroll': 'ContentType', 'keyField': 'id', 'valueField': 'model', 'required': true
		},
	}
}
