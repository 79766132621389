import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NavController} from "@ionic/angular";
import {ActivatedRoute, NavigationExtras} from "@angular/router";
import * as moment from 'moment';
import {ModelProvider} from "../../../../framework/providers/modelProvider/ModelProvider";
import {AppConfig} from "../../../../core/conf/main";
import {Events} from "../../../../framework/providers/events";
import {ArcElement, Chart, LineController, LineElement, PointElement} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import {
    LinearScale,
    BarElement,
    BarController,
    CategoryScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip
} from 'chart.js';

@Component({
    selector: 'app-main-agents',
    templateUrl: './main-agents.page.html',
    styleUrls: ['./main-agents.page.scss'],
})
export class MainAgentsPage implements OnInit, OnDestroy {
    @ViewChild("barCanvas11", {'static': true}) barCanvas11: ElementRef;
    @ViewChild("barCanvas12", {'static': true}) barCanvas12: ElementRef;
    @ViewChild("barCanvas13", {'static': true}) barCanvas13: ElementRef;
    @ViewChild("barCanvas14", {'static': true}) barCanvas14: ElementRef;
    // @ViewChild("barCanvas15", {'static': true}) barCanvas15: ElementRef;

    barChart = {};

    appConfig = AppConfig;
    models = [];
    prefix = 'myreports123';
    tablExtData = {'showEdit': false};
    filters = {'ordering': '-id', 'type': 'dashboard'};
    modelName;

    ready = false;
    onLoading = false;

    reportPages;
    keys;
    currentReport;
    finalReportRes;

    modelKeys = {
        'orderItem': ['image', 'makerImage', 'itemId', 'created', 'orderId', 'itemName', 'finalUnitPrice', 'amount']
    };

    currentPageIndex = 0;
    currentFieldsKeys;
    currentFields;
    currentPage = 1;

    checked = {};

    resultData: any = {};

    tablesData = {};
    refreshInterval;

    constructor(public events: Events,
                public navCtrl: NavController,
                public modelProvider: ModelProvider,
                public route: ActivatedRoute) {
        Chart.register(PointElement, LineController, LineElement, LinearScale, BarElement, BarController, CategoryScale, Decimation, Filler, Legend, Title, Tooltip, ArcElement);
    }

    ngOnDestroy() {
        clearInterval(this.refreshInterval);
        this.events.unsubscribe('update_' + this.prefix);
    }

    ionViewDidEnter() {
        if (!this.ready) {
            this.init();
        }
    }

    ionViewDidLeave() {
        clearInterval(this.refreshInterval);
        this.events.unsubscribe('update_' + this.prefix);
        this.ready = false;
    }

    ngOnInit() {
        this.modelName = 'reports';
        this.ready = true;
        this.init();
    }

    init() {
        let me = this;
        this.events.subscribe('update_' + this.prefix, (data) => {
            me.ready = true;
            me.models = data.models;

            let refresh = {};
            for (let key in me.models) {
                // let finalReportRes = me.models[key].data.finalResReport;
                // //console.logfinalReportRes);
                this.tablesData[me.models[key].data.name] = me.models[key];
                refresh[me.models[key].data.name] = true;
            }

            let keys = ['leads', 'activity', 'maker', 'company'];
            let i = 1;
            for (let key in keys) {
                if (refresh[keys[key]]) {
                    this.getGraph(key, this.getViewCanvas(i),
                        this.tablesData[keys[key]].data.finalResReport,
                        this.tablesData[keys[key]].data.graphType);
                }
                i++;
            }

            me.onLoading = false;
        });

        me.onLoading = false;
        this.refreshInterval = setInterval(function () {
            me.onLoading = true;
            me.events.publish('refresh_request_' + me.prefix, {});
        }, 120 * 1000);
    }

    refresh() {
        let me = this;
        me.onLoading = true;
        me.ready = false;
        me.events.publish('refresh_request_' + me.prefix, {});
    }

//this.reportPages.data.info.pages =[     0:
// fields:
// {
//  maker:
//  {
//      fieldKey: "id"
//      fieldName: "name"
//      name: "maker"
//      options: Array(62) [0: {id: 25, internalId: 13, name: "Sandisk"]
//  }
// }
    setFieldListCheck(key) {
        //console.logthis.checked);
        this.resultData['data'][key] = this.checked[key];
    }


    getGraph(graphKey, barCanvas, resReportData, graphType = 'bar') {
        // graphVarId
        let keys = [];
        let values = [];
        let values2;
        let colors = [];

        let models = resReportData.models;
        if (resReportData.graphModels) {
            models = resReportData.graphModels;
        }

        //if has the third key on model grpah server config
        if (resReportData['graph'][2]) {
            values2 = [];
        }

        let labelkey;
        for (let key in models) {
            labelkey = models[key][resReportData['graph'][0] + '__name'] ? models[key][resReportData['graph'][0] + '__name'] : models[key][resReportData['graph'][0]];

            if (resReportData['graphkey'] == 'date') {
                // //console.loglabelkey);
                let dt = moment(labelkey);
                // //console.logdt);
                keys.push(dt.format('HH DD/MM'));
            } else {
                keys.push(labelkey ? labelkey.substr(0, 15) : '-');
            }

            values.push(models[key][resReportData['graph'][1]]);
            if (resReportData['graph'][2]) {
                values2.push(models[key][resReportData['graph'][2]]);
            }

            if (models[key][resReportData['graph'][0] + '__color']) {
                colors.push(models[key][resReportData['graph'][0] + '__color']);
            } else {
                colors.push(-1);
            }
        }
        // //console.logkeys);
        // //console.logvalues);
        // let lables = ["קבלנים", "..."];
        // let data = [1,52,13,,43];
        let me = this;
        setTimeout(function () {
            me.setChart(graphKey, barCanvas, keys, values, graphType, colors, values2);
        });
    }

    refreshReport(report, filters = {}) {
        filters['id'] = report.data.id;

        this.events.publish('refresh_request_' + this.prefix, filters);
    }

    getReport(report) {
        this.currentReport = report;
        this.currentPage = 1;

        this.modelProvider.get('report', report.data.id, {'pageDesc': true}).then((res: any) => {
            //console.logres);
            this.reportPages = res.models[0];

            //console.logthis.reportPages.data.info);

            this.currentFields = this.reportPages.data.info['fields'];
            this.currentFieldsKeys = Object.keys(this.currentFields);

            this.resultData = {'prefix': report.data.name};
            this.resultData['data'] = {};
            this.resultData['dataFields'] = {};
            for (let key in this.currentFields) {
                let type;
                if (this.currentFields[key].type == 'options') {
                    this.resultData['dataFields'][key] = {
                        'title': this.currentFields[key].title,
                        'value': null,
                        'type': 'number'
                    };
                    this.resultData['data'][key] = [];
                } else if (this.currentFields[key].type == 'dateRange') {
                    this.resultData['dataFields'][key] = {
                        'title': this.currentFields[key].title,
                        'value': 'today',
                        'type': 'dateRange'
                    };
                    this.resultData['data'][key] = [];
                } else if (this.currentFields[key].type == 'dateime') {

                    this.resultData['dataFields'][key] = {
                        'title': this.currentFields[key].title,
                        'value': null,
                        'type': 'datetime'
                    };
                    this.resultData['data'][key] = moment().format('YYYY-MM-DD 01:MM');

                } else if (this.currentFields[key].type == 'text') {

                    this.resultData['dataFields'][key] = {
                        'title': this.currentFields[key].title,
                        'value': null,
                        'type': 'text'
                    };
                    this.resultData['data'][key] = '';

                } else if (this.currentFields[key].type == 'list') {
                    this.resultData['dataFields'][key] = {
                        'title': this.currentFields[key].title,
                        'value': null,
                        'type': 'list'
                    };
                    this.resultData['data'][key] = [];
                    this.checked[key] = {};
                }
            }
        });
    }

    nextPage() {
        //console.logthis.checked);
        this.currentPage++;
        //console.logthis.resultData);
    }

    prevPage() {
        //console.logthis.resultData);
        this.currentPage--;
    }

    optionAdd(key, option) {

        if (this.resultData.data[key].indexOf(option.id) != -1) {
            this.resultData.data[key].splice(this.resultData.data[key].indexOf(option.id), 1)
        } else {
            this.resultData.data[key].push(option.id);
        }
    }

    //take chosen options and make a report
    formIsDone() {
        for (let key in this.resultData.dataFields) {
            if (this.resultData['dataFields'][key].type == 'list') {
                this.resultData['data'][key] = [];
                for (let id in this.checked[key]) {
                    if (this.checked[key][id]) {
                        this.resultData['data'][key].push(id);
                    }
                }

            }
            if (this.resultData['dataFields'][key].type == 'datetime') {
                this.resultData['data'][key + 'Format'] = moment(this.resultData['data'][key]).format('YYYY-MM-DD HH:MM');
            }
        }

        //need to fix this
        this.modelProvider.get('report', this.currentReport.data.id,
            {'result': true, 'data': JSON.stringify(this.resultData.data)})
            .then((res: any) => {
                //console.logres);

                this.finalReportRes = res['models'][0].data.finalResReport;

                if (this.finalReportRes.graph) {
                    // this.getGraph(this.barCanvas1, this.finalReportRes);
                }

            });
    }

    //lables = ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"]
    //data = [12, 19, 3, 5, 2, 3]
    setChart(graphKey, barElement, labales, data, type, dcolors = null, data2 = null) {
        if (this.barChart[graphKey]) {
            this.barChart[graphKey].clear();
        }

        let colors = [];
        let colorsData = {
            "primary": "rgba(56, 128, 255, 0.2)",
            "danger": "rgba(245, 61, 61, 0.2)",
            "warning": "rgba(255, 206, 0, 0.2)",
            "secondary": "rgba(12, 209, 232, 0.2)",
            "dark": "rgba(34, 34, 34, 0.2)",
            "success": "rgba(16, 220, 96, 0.2)"
        };

        for (let key in colorsData) {
            colors.push(colorsData[key]);
        }

        if (dcolors) {
            for (let key in dcolors) {
                if (dcolors[key] != -1) {
                    colors[key] = colorsData[dcolors[key]];
                }
            }
        }

        let datasets = [];
        if (type == 'radar') {
            for (let i in data) {
                datasets.push({
                    'label': i,
                    'data': data[i]
                })
            }
        } else {
            datasets = [
                {
                    label: "",
                    data: data,
                    backgroundColor: colors,
                    borderColor: colors,
                    borderWidth: 1
                }
            ];
            if (data2) {
                datasets.push(
                    {
                        label: "",
                        data: data2,
                        backgroundColor: colors,
                        borderColor: colors,
                        borderWidth: 1
                    })
            }
        }

        if (this.barChart[graphKey]) {
            this.barChart[graphKey].data.datasets = datasets;
            this.barChart[graphKey].data.labels = labales;
            this.barChart[graphKey].update();
        } else {

            //
            //         type: 'horizontalBar',
            this.barChart[graphKey] = new Chart(barElement.nativeElement, {
                plugins: [ChartDataLabels],
                type: type,
                data: {
                    labels: labales,
                    datasets: datasets
                },
                options: {
                    plugins: {
                        // Change options for ALL labels of THIS CHART
                        datalabels: {
                            color: '#00000',
                            rotation: 30,
                            labels: {
                                title: {
                                    font: {
                                        weight: 'bold'
                                    }
                                },
                                value: {
                                    color: '#00000'
                                }
                            }
                        }
                    },
                    scales: {
                        yAxes: [
                            {
                                ticks: {
                                    beginAtZero: true
                                }
                            }
                        ]
                    }
                }
            });
        }
    }


    getViewCanvas(i) {
        let barCanvas;
        switch (i) {
            case 1:
                barCanvas = this.barCanvas11;
                break;
            case 2:
                barCanvas = this.barCanvas12;
                break;
            case 3:
                barCanvas = this.barCanvas13;
                break;
            case 4:
                barCanvas = this.barCanvas14;
                break;
            // case 5:
            //     barCanvas = this.barCanvas15;
            //     break;
            // case 6:
            //     barCanvas = this.barCanvas16;
            //     break;
            // case 7:
            //     barCanvas = this.barCanvas17;
            //     break;
            // case 8:
            //     barCanvas = this.barCanvas18;
            //     break;
            // case 9:
            //     barCanvas = this.barCanvas19;
            //     break;
        }

        return barCanvas;
    }

    openModel(key, model) {
        let report = this.tablesData[key];
        let finalResReport = report.data.finalResReport;

        //console.logmodel);
        //console.logfinalResReport);
        if (finalResReport.modelPrefix) {

            let edata = {'id': model.id};

            let navigationExtras: NavigationExtras = {
                queryParams: edata
            };

            this.navCtrl.navigateForward(finalResReport.modelPrefix + '/' + model.id, navigationExtras);
        }
    }


    addListStatusFilter(reportKey, filterId) {
        let filters = {};
        let data = {};
        data[reportKey + "__tableList"] = filterId;

        filters['data'] = JSON.stringify(data);
        filters['result'] = true;

        this.refreshReport(this.tablesData[reportKey], filters);
    }

}
