import {TranslateService} from "@ngx-translate/core";

export class AddressDetails {
    data = {
        'city': null,
        'street': null,
        'homeNumber': null,
        'floor': null,
        'apt': null,
        'cityId': null,
        'neighborhood': null,
        'onRadius': null,
        'appuser': null,
        'company': null,
        'shippingData': {'active':null}
    };

    constructor(public data1: any) {
        this.data = data1;
    }

    //shortHeaders = [];
    prefix = "addressDetails";
    title = "AddressDetails";
    dataFields = {
        id: {'title': 'ID', 'value': null, 'type': 'number', 'readonly': true},
        created: {'title': 'Created', 'value': null, 'type': 'date', 'readonly': true},
        updated: {'title': 'Updated', 'value': null, 'type': 'date', 'readonly': true},

        'placeType': {
            'title': 'placeType',
            'showDefault': true, 'value': null,
            'displayField': 'handleSide', 'type': 'optionsscroll', 'optionsscroll': 'PLACE_TYPE',
        },
        elevator: {'title': 'elevator', 'value': null, 'type': 'boolean'},
        truckAccess: {'title': 'truckAccess', 'value': null, 'type': 'boolean'},
        crane: {'title': 'crane', 'value': null, 'type': 'boolean'},
        longCarry: {'title': 'longCarry', 'value': null, 'type': 'boolean'},
        coi: {'title': 'coi', 'value': null, 'type': 'boolean'},
        comments: {'title': 'comments', 'value': null, 'type': 'textarea', 'maxlength': 500},
    }


}
