// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-toolbar {
  --background: #fff;
}

.edit-field ion-item {
  margin: 0 auto;
  max-width: 350px;
}
.edit-field ion-input {
  font-size: 23px;
  text-align: center;
  max-width: 200px;
}
.edit-field ion-buttons {
  margin: 0;
}
.edit-field .proceed-btn {
  font-size: 20px;
  margin: 0 auto;
  height: 34px;
}`, "",{"version":3,"sources":["webpack://./src/core/components/edit-field-toolbar/edit-field-toolbar.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;;AAGE;EACE,cAAA;EACA,gBAAA;AAAJ;AAGE;EACE,eAAA;EACA,kBAAA;EACA,gBAAA;AADJ;AAIE;EACE,SAAA;AAFJ;AAKE;EACE,eAAA;EACA,cAAA;EACA,YAAA;AAHJ","sourcesContent":["ion-toolbar{\n  --background: #fff;\n}\n\n.edit-field {\n  ion-item{\n    margin: 0 auto;\n    max-width: 350px;\n  }\n\n  ion-input {\n    font-size: 23px;\n    text-align: center;\n    max-width: 200px;\n  }\n\n  ion-buttons{\n    margin: 0;\n  }\n\n  .proceed-btn{\n    font-size: 20px;\n    margin: 0 auto;\n    height: 34px;\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
