import { Component, OnInit } from '@angular/core';
import {ModalController, NavParams} from "@ionic/angular";
import {ModelProvider} from "../../providers/modelProvider/ModelProvider";
import {Events} from "../../providers/events";

@Component({
  selector: 'app-model-add',
  templateUrl: './model-add.page.html',
  styleUrls: ['./model-add.page.scss'],
})
export class ModelAddPage implements OnInit {

  public modelId:number;
  public model:any;
  public callback:any;
  public listModel:boolean = false;
  public addParams:object;

  public loading:boolean = false;

  public options:object = {};

  constructor(public navParams: NavParams, public modelProvider:ModelProvider, public modalCtrl: ModalController, public events:Events) {
    this.modelId = navParams.get('modelId');
    this.model = navParams.get('model');
    this.listModel = navParams.get('listModel');
    this.callback = navParams.get('callback');
    this.addParams = navParams.get('addParams');
  }

  ngOnInit() {
    if(this.listModel) this.getModel();
    this.addAddParams();
  }

  getModel()
  {
    this.loading = true;
    this.modelProvider.list(this.model.prefix, {'id':this.modelId}).then((res)=>
    {
      this.model = res['models'][0]
      this.loading = false;
    })
  }

  addAddParams()
  {
    if(!this.addParams) return;
    for(var k in this.addParams)
      this.model.data[k] = this.addParams[k]
  }

  save()
  {
    if(this.model.data.id)
      this.update();
    else
      this.create();
  }

  update()
  {
    this.loading = true;
    this.modelProvider.update(this.model, {}, null, true).then((res)=> {
      if (this.callback) this.callback(res);
      this.loading = false;
      this.close()
    });
  }

  create()
  {
    this.loading = true;
    this.modelProvider.create(this.model, {}, null, true).then((res)=> {
      if (this.callback) this.callback(res);
      this.loading = false;
      this.close()
    });
  }

  close()
  {
    this.modalCtrl.dismiss({
      'dismissed': true
    });
  }
}