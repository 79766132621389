// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.branch-header {
  background: var(--button-a-background);
  color: var(--button-a-text);
  font-size: medium;
}`, "",{"version":3,"sources":["webpack://./src/core/pages/contact/components/branches/branches.component.scss"],"names":[],"mappings":"AAAA;EACE,sCAAA;EACA,2BAAA;EACA,iBAAA;AACF","sourcesContent":[".branch-header {\n  background: var(--button-a-background);\n  color: var(--button-a-text);\n  font-size: medium;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
