export class Post {
    data = {
        "id": null,
        "name": null,
        "link": null,
        "subject": null,
        "description": null,
        "comments": null,
        "business": null,
        "item": null,
        "fileName": null,
        "itemName": null,
        "itemImage": null,
        "type": null,
        "images": null
    };

    constructor(public data1: any) {
        this.data = data1;
    }

    //shortHeaders = [];
    prefix = "post";
    title = "Posts";
    dataFields = {
        id: {'title': 'ID', 'value': null, 'type': 'number', 'readonly': true},
        description: {'title': 'description', 'value': null, 'type': 'text'},
    }
}
