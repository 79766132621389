import {Component, Input, OnInit} from '@angular/core';
import {ModelProvider, ResInterface} from "../../../providers/modelProvider/ModelProvider";
import {AppComment} from "../../../model/general/comment";

@Component({
    selector: 'app-comment',
    templateUrl: './comment.component.html',
    styleUrls: ['./comment.component.scss'],
})
export class CommentComponent implements OnInit {
    @Input()
    model;

    newMsg;
    comments = [];

    constructor(public m: ModelProvider) {
    }

    ngOnInit() {
        this.newMsg = new AppComment({
            objectId: this.model.data.id,
            contentTypeName: this.model.prefix
        });

        this.refresh();
    }

    refresh() {
        this.m.list("comment",
            {
                'ordering': '-id',
                'objectId': this.model.data.id,
                'contentType__model': this.model.prefix
            })
            .then((data: ResInterface) => {
                this.comments = data.models;
                //console.logthis.comments);
            });
    }

    add() {
        this.m.create(this.newMsg, {'contentTypeName': this.model.prefix}).then(() => {

            this.newMsg = new AppComment({
                objectId: this.model.data.id,
                contentTypeName: this.model.prefix
            });

            this.refresh();
        });
    }


    changed() {

    }

}
