// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-fab {
  bottom: 140px;
  z-index: 0;
}
ion-fab .cart-fab {
  --background: var(--button-a-background);
  --color: var(--button-a-text);
}
ion-fab .cart-fab ion-icon {
  font-size: 35px;
}

.whatsapp-fab {
  --background: #4CAF50;
  --color: #fff;
  bottom: 120px;
}

ion-fab {
  bottom: 55px;
}

.cart-amount {
  font-size: 12px;
  border: 1px solid;
  border-radius: 10px;
  min-width: 18px;
  height: auto;
  text-align: center;
  position: absolute;
  top: 7px;
  right: 8px;
  padding: 1px;
  opacity: 0.9;
}

.cart-amount {
  border-color: var(--button-a-background);
  color: var(--button-a-background);
  background: var(--button-a-text);
}

.price-box {
  background: var(--button-a-background);
  color: var(--button-a-text);
  width: auto;
  text-align: center;
  border-radius: 15px;
  margin: 5px 0;
  font-size: 13px;
  white-space: nowrap;
  padding: 0 5px;
}

ion-spinner {
  height: 11px;
  margin-top: 2px;
  color: var(--button-a-text);
}`, "",{"version":3,"sources":["webpack://./src/core/pages/cart/components/cart-fab/cart-fab.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,UAAA;AACF;AAEE;EACE,wCAAA;EACA,6BAAA;AAAJ;AAII;EACE,eAAA;AAFN;;AAQA;EACE,qBAAA;EACA,aAAA;EACA,aAAA;AALF;;AAQA;EACE,YAAA;AALF;;AASA;EACE,eAAA;EACA,iBAAA;EACA,mBAAA;EACA,eAAA;EACA,YAAA;EACA,kBAAA;EACA,kBAAA;EACA,QAAA;EACA,UAAA;EACA,YAAA;EACA,YAAA;AANF;;AASA;EACE,wCAAA;EACA,iCAAA;EACA,gCAAA;AANF;;AASA;EACE,sCAAA;EACA,2BAAA;EAGA,WAAA;EACA,kBAAA;EACA,mBAAA;EACA,aAAA;EACA,eAAA;EACA,mBAAA;EAGE,cAAA;AAVJ;;AAcA;EACE,YAAA;EACA,eAAA;EACA,2BAAA;AAXF","sourcesContent":["ion-fab {\n  bottom: 140px;\n  z-index: 0;\n\n\n  .cart-fab {\n    --background: var(--button-a-background);\n    --color: var(--button-a-text);\n\n    //opacity: 0.9;\n\n    ion-icon {\n      font-size: 35px;\n      //opacity: 0.9;\n    }\n  }\n}\n\n.whatsapp-fab{\n  --background: #4CAF50;\n  --color: #fff;\n  bottom: 120px;\n}\n\nion-fab {\n  bottom: 55px;\n}\n\n\n.cart-amount {\n  font-size: 12px;\n  border: 1px solid;\n  border-radius: 10px;\n  min-width: 18px;\n  height: auto;\n  text-align: center;\n  position: absolute;\n  top: 7px;\n  right: 8px;\n  padding: 1px;\n  opacity: 0.9;\n}\n\n.cart-amount {\n  border-color: var(--button-a-background);\n  color: var(--button-a-background);\n  background: var(--button-a-text);\n}\n\n.price-box{\n  background: var(--button-a-background);\n  color: var(--button-a-text);\n\n  //position: absolute;\n  width: auto;\n  text-align: center;\n  border-radius: 15px;\n  margin: 5px 0;\n  font-size: 13px;\n  white-space: nowrap;\n\n  //.price-div{\n    padding: 0 5px;\n  //}\n}\n\nion-spinner{\n  height: 11px;\n  margin-top: 2px;\n  color: var(--button-a-text);\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
