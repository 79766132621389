import {Injectable} from '@angular/core';

import {NgModule} from '@angular/core';
import {ColumnModel} from "../model/columnModel";
import {ModelProvider} from "./modelProvider/ModelProvider";
import {ResInterface} from "./modelProvider/ModelProvider";

@NgModule({})
@Injectable()
export class FrameWorkGlobals {
    public columnsModels: Array<ColumnModel>;
    public columnModel: ColumnModel;

    constructor(public modelProvider: ModelProvider) {
        ////console.log'auth service!!')
    }

    public init() {
    }

    //TODO: add user type.
    public createModelColumn(modelKeys: Array<string>, model: any, prefix: string) {

        this.columnModel = new ColumnModel({
            'fields': modelKeys,
            'contentTypeName': model.prefix,
            'prefix': prefix
        });

        ////console.log"add column model: "+prefix);

        this.modelProvider.create(this.columnModel, {'contentTypeName': model.prefix})
            .then((res) => {
                this.setModelColumns();
            });

        return this.columnModel;
    }

    public setModelColumns() {
        this.modelProvider.list('columnModel').then((res: ResInterface) => {
            this.columnsModels = res.models;
        });
    }

    public getColumnModel(modelName: string, tablePrefix: string): ColumnModel {
        for (var key in this.columnsModels) {
            if (this.columnsModels[key].data.contentTypeName.toLowerCase() == modelName.toLowerCase()
                && this.columnsModels[key].data.prefix == tablePrefix) {

                ////console.log'found!')
                ////console.logthis.columnsModels[key]);

                return this.columnsModels[key];
            }
        }

        ////console.log'not found')
        return null;
    }
}
