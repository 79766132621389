import {Component, Input, OnDestroy, OnInit, Renderer2, ViewChild} from '@angular/core';
import {NavigationExtras} from "@angular/router";
import {ModalController, NavController, PopoverController} from "@ionic/angular";
import {ModelProvider} from "../../../../framework/providers/modelProvider/ModelProvider";
import {AdminService} from "../../../../core/services/AdminService";
import {BusinessService} from "../../../../core/services/BusinessService";
import {ModelToolsProvider} from "../../../../framework/providers/model-tools/model-tools";
import {Events} from "../../../../framework/providers/events";
import {OrderService} from "../../../../core/services/OrderService";
import {MainService} from "../../../../core/services/MainService";
import {CalendarWrapperComponent} from "../calendar/calendar-wrapper/calendar-wrapper.component";
import * as moment from "moment";

export interface ProcessComponent {
    data: any;
}

@Component({
    selector: 'app-view-field',
    templateUrl: './view-field.component.html',
    styleUrls: ['./view-field.component.scss'],
})
export class ViewFieldComponent implements OnInit, OnDestroy {
    // @ViewChild('inputElement', {static: false, read: ElementRef}) inputElement: ElementRef;
    @ViewChild('inputElement') inputElement;
    @ViewChild('inputElement1') inputElement1;

    // @ViewChild('processContainer', {read: ViewContainerRef}) container;

    filters = {'ordering': '-id', 'type': 'moving', 'name': 'taarif', 'modelOffset': 0, 'modelLimit': 20};

    @Input()
    editMode: boolean = false;

    @Input()
    index;
    @Input()
    models = false;
    @Input()
    onMainPage = false;
    @Input()
    topPrefix;
    @Input()
    prefix;
    @Input()
    setFocus = false;
    @Input()
    filterMode = false;
    @Input()
    sectionName;
    @Input()
    tableInfoData;
    @Input()
    exTableData;

    @Input()
    showTitle = false;
    @Input()
    addMultiple = false;

    @Input()
    topModelId;
    @Input()
    fieldType;
    @Input()
    fieldData;
    @Input()
    rowTitle;
    @Input()
    onTable = false;

    @Input()
    period;

    @Input()
    fieldKey;

    @Input()
    avoidSave;
    @Input()
    boxSmall;

    saveInterval;

    @Input()
    params: any;// = {'edit': false, 'fullView': true, 'noStyle':True}
    deffaultFullView: boolean = false;

    searchIcon = '';
    extraClass = '';

    inError = false;

    //one filed supported to be tab fields.
    ready: boolean = false;
    currentTab = 1;
    panelModel;

    jsonRes;

    randPref = "selectTablePrefix3" + Math.floor(Math.random() * Math.floor(1000000));

    // forceEdit = false;

    constructor(public events: Events, public modelProvider: ModelProvider,
                public modalController: ModalController,
                public adminService: AdminService,
                public popoverCtrl: PopoverController,
                public mainService: MainService, private renderer: Renderer2,
                public orderService: OrderService,
                public businessService: BusinessService,
                public modelTools: ModelToolsProvider, public navCtrl: NavController) {
    }

    ngOnInit() {

        if (this.fieldKey) {
            if (!this.fieldData) this.fieldData = this.period[this.fieldKey];
            this.fieldType = this.adminService.getFieldsType(this.sectionName, this.exTableData)[this.fieldKey];
        }
        if (!this.fieldType) {
            return;
        }

        let me = this;
        if (['text', 'float', 'number', 'int'].includes(this.fieldType['type']) && this.setFocus) {
            setTimeout(function () {
                //set focuse
                if (me.inputElement) {
                    me.inputElement.setFocus();
                }
                if (me.inputElement1) {
                    me.inputElement1.setFocus();
                }
            }, 100);
        }
        // console.log(this.fieldType['name']);
        // console.log(this.fieldData);
        if (!this.params) this.params = {};

        if (this.params['extraClass']) {
            this.extraClass = this.params['extraClass'];
        }

        if (this.params['editMode'] && !this.fieldType['readOnly']) {
            // this.fieldData['editMode'] = true;
            this.editMode = true;
        }

        if (this.addMultiple) {
            this.fieldType['multiple'] = true;
        }

        // let me = this;
        // if (me.fieldType) {
        //     me.forceEdit = ['filter', 'image', 'page', 'table', 'filterBuilder', 'htmlTemplate', 'equation', 'inventory']
        //         .indexOf(me.fieldType['type']) != -1;
        // }
        this.ready = true;

        //assume true
        if (this.params['fullView'] === null) {
            this.deffaultFullView = true;
        }

        if (this.fieldType['type'] == 'time') {
            if (this.fieldData['value'] == 'None') this.fieldData['value'] = "00:00:00";
            // this.fieldData['value'] = moment(this.fieldData['value']).format('HH:mm');
        }

        if (['number', 'float'].includes(this.fieldType['type'])) {
            if (this.fieldData['value'] == 0) this.fieldData['value'] = '';
        }

        if (this.fieldType['type'] == 'jsonField') {
            this.jsonRes = JSON.parse(this.fieldData['value'])
            //    let it be text.
        }


        if (this.tableInfoData['realSectionName']) {
            this.sectionName = this.tableInfoData['realSectionName'];
        }


        if (this.period) {
            this.panelModel = {'data': {'images': [], 'currencyCode': 'sh', 'options': {}}, 'prefix': this.sectionName}
            for (let k in this.period) {
                this.panelModel['data'][k] = this.period[k]['value'];
            }
            this.panelModel['data']['options'] = {}
        }

        if (this.editMode) {
            this.fieldData['editMode'] = true;
        }
    }

    onInputKeyDown(event) {
        console.log(event.key)
        if (event.key === 'Escape' || event.key === 'Enter') {
            event.preventDefault(); // Prevents the Esc key's default behavior (e.g., closing modals)
            this.events.publish('closeOpenField_' + this.prefix);
        }
    }

    setFieldEdit(fieldData) {
        this.tableInfoData['updated'] = true;
        fieldData['edited'] = true;
        this.period['main']['updated'] = true;
    }

    fieldEdit(event = null, otherField = null) {
        let fieldData = this.fieldData;
        let fieldType = this.fieldType;
        if (otherField) {
            fieldData = this.period[otherField];
            fieldType = this.adminService.getFieldsType(this.sectionName, this.exTableData)[otherField];
        }

        let me = this;
        setTimeout(function () {
            if (['text', 'number', 'float', 'email'].includes(fieldType['type'])) {
                fieldData['displayName'] = fieldData['value'];
            }
        })

        if (fieldType.type == 'selectTable' && fieldData['value']
            && (this.fieldData['readOnly'] || fieldType['readOnly']) && !this.avoidSave) {
            this.presentSelectBar(null, 'modelTableEdit', fieldData['value'], fieldType['model'],
                null, 'bigger-popover', true);
            return;
        }

        if ((fieldType['readOnly'] || fieldData['readOnly'] || ['freeText', '...'].includes(fieldType['type'])) && !this.avoidSave) {
            console.log('read only!');
            return;
        }

        this.adminService.setNeedSave();
        this.markFieldEdit();

        if (fieldType['type'] == 'textarea') {
            if (!this.editMode) {
                this.adminService.popupEditBox(event, this.sectionName, this.exTableData,
                    this.prefix, this.period, 'textArea', false, fieldData, fieldType);
                event.stopPropagation();
                return;
            }
        }
            // else if (fieldType['mapSelect']) {
            //     this.adminService.mapSelect(event, this.prefix, this.exTableData, this.period, null,
            //         {'lat': this.period.lat.value, 'lng': this.period.lng.value}, fieldType, this.sectionName);
            //     return;
            // }
        //fieldChooser
        else if (fieldType['type'] == 'equationPopUp') {
            this.adminService.popupEditBox(event, fieldData['modelDataName'], this.exTableData,
                this.prefix, this.period, 'equation', true, fieldData, fieldType);
            event.stopPropagation();
            return;
        } else if (fieldType['type'] == 'fieldChooser') {
            this.adminService.popupEditBox(event, fieldData['modelDataName'], this.exTableData,
                this.prefix, this.period, 'fieldChooser', false, fieldData);
            event.stopPropagation();
            return;
        } else if (fieldType['type'] == 'boolean') {
            fieldData['value'] = !!!fieldData['value'];
            fieldData['displayName'] = fieldData['value'];
            fieldData['icon'] = fieldData['value'] ? 'checkmark' : 'close';
            fieldData['color'] = fieldData['value'] ? 'success' : 'danger';
            event.stopPropagation();

        } else if (fieldType['name'] == 'icon' && fieldData['editMode']) {
            this.adminService.popupEditBox(event, this.sectionName, this.exTableData,
                this.prefix, this.period, 'selectIcon', false, fieldData);
            event.stopPropagation();
            return;

        } else if (fieldType['name'] == 'color' && fieldType['type'] != 'colorPicker') {
            this.adminService.popupEditBox(event, this.sectionName, this.exTableData,
                this.prefix, this.period, 'selectColor', false, fieldData);

        } else if (fieldType['type'] == 'colorPicker') {
            this.adminService.popupEditBox(event, this.sectionName, this.exTableData,
                'mainPagePrefixevent', this.period, 'colorPicker',
                false, fieldData, fieldType);
        } else if (fieldType['type'] == 'time') {
            this.adminService.popupEditBox(event, this.sectionName, this.exTableData,
                'mainPagePrefixevent', this.period, 'timePicker',
                false, fieldData, fieldType);
        }
            //ready only field:
            //ready only field:
            // if (fieldType.type=='selectTable' && fieldData['value'])
            // {
            //     this.presentSelectBar(null, 'modelTableEdit', fieldData['value'],
            //         fieldType['model'], null, 'bigger-popover')
            // }
        //read only and value ? open select table.
        else if (fieldType.type == 'selectOptions' ||
            (fieldType.type == 'selectOptionFull' && this.onTable) || fieldType.selectPopup) {
            this.presentSelectBar(event, 'selectOptions', null, null, null, false, false, 300);
        } else if (fieldType.type == 'selectTable') {
            this.presentSelectBar(event, 'selectTable', null, null, null, false, false, 300);
        } else {
            fieldData['editMode'] = true;
            this.editMode = true;
        }

        if (fieldType['type'] == 'date' || fieldType['type'] == 'datetime') {
            this.adminService.openCalendar(event, this.tableInfoData, this.sectionName, this.prefix, this.exTableData,
                this.period, fieldData, fieldType, fieldType['rangeField'] ? true : false,
                true, true, {'topModelId': this.topModelId}, this.avoidSave);
            event.stopPropagation();
            return;
        }

        //if field is table.
        if (fieldType['type'] == 'table') {
            return;
        } else if (['number', 'float'].includes(fieldType['type'])) {
            let me = this;
            setTimeout(function () {
                fieldData['displayName'] = fieldData['value'];
            });
        }

        // this.markFieldEdit();
        setTimeout(function () {
            me.adminService.saveToMarkedRows(me.sectionName, fieldType, fieldData);
        })
    }

    markFieldEdit() {
        this.tableInfoData['updated'] = true;
        this.adminService.manageModels[this.sectionName + 'Data']['updated'] = true;
        this.fieldData['edited'] = true;
        this.period['main']['updated'] = true;
        this.fieldData['validate'] = true;
        this.adminService.setNeedSave();
    }

    ngOnDestroy(): void {
        // this.fieldData['edited'] = false;
        // this.fieldData['editMode'] = false;
    }

    save() {
        if (this.avoidSave) return;
        clearTimeout(this.saveInterval);

        this.fieldData['fieldSaveWait'] = true;

        // if (!this.validateField()) {
        //     this.fieldData['validate'] = false;
        //     return;
        // }
        // this.fieldData['validate'] = true;

        let me = this;
        // this.saveInterval = setTimeout(function () {
        if (!me.validateField()) {
            me.fieldData['validate'] = false;
            return;
        }
        me.fieldData['validate'] = true;

        // if (me.onTable) {
        //     let d = {'fieldData': me.fieldData, 'model': me.period, 'fieldType': me.fieldType};
        //     me.events.publish('save' + me.prefix, d);
        // } else {
        me.saveField();
        // }

        // me.fieldData['editMode'] = false;
        // }, 2000);
    }

    validateField() {
        if (!this.fieldData['value']) return true;

        //check text length
        if (this.fieldType['type'] == 'text' || this.fieldType['type'] == 'textarea') {
            if (this.fieldData['value'].length >= this.fieldType['maxLength']) {
                this.inError = true;
                //console.log'text not valid');
                return false;
            }
        }

        //check is number
        if (this.fieldType['type'] == 'number' || this.fieldType['type'] == 'float') {
            if (!this.isNumeric(this.fieldData['value'])) {
                this.inError = true;
                //console.log'number not valid');
                return false;
            }
        }

        this.inError = false;

        return true;
    }

    openPage(event = null) {
        let me = this;
        let fieldData = this.fieldData;
        let fieldType = this.fieldType;
        //move to an other page
        //console.log"open page:");
        //console.logthis.fieldData);
        //console.logthis.fieldType);

        //open external section by field id. page button field would get the id of some model.
        //open orderDetails from lead page.
        if (this.fieldType['openModel']) {
            this.adminService.popupEditBox(event, this.fieldType['openModel'], this.exTableData, 'asdasddadwww',
                null, 'modelTableEdit', true, null, null, this.fieldData['value'])
            return;
        }
        if (this.fieldType['type'] == 'map') {
            return;
        } else if (fieldType['fieldEdit']) {
            this.period[fieldType['fieldEdit']]['value'] = fieldType['fieldEditValue'];
            this.adminService.saveField(this.sectionName, this.period, this.period[fieldType['fieldEdit']], null, null, fieldType['fieldEdit']);
        } else if (fieldData['externalLink']) {
            if (fieldType['share']) {
                this.mainService.shareViewWhatsapp({'data': fieldData['externalLink']});
            } else {
                window.open(fieldData['externalLink']);
            }
            return;
        } else if (this.fieldType['calendar']) {
            this.openCalendar(null, true, true, true);
        } else if (this.fieldType['areyousure']) {
            this.modelTools.yesOrNoAlert(function (data) {
                me.openPageAction();
            }, this.fieldType['areyousureMsgParams'] ? this.fieldType['areyousureMsgParams'] : null, this.fieldType['areyousureText']);

        } else {
            this.openPageAction();
        }
    }

    buttonClick(key, data = null) {
        if (this.onTable) {
            this.events.publish("btnclick_" + this.prefix, {'key': key, 'data': data});
        } else {
            this.adminService.checkSave([this.period], this.sectionName, {}, this.exTableData);
        }
    }

    // can try this if would find a bug here:
    // let filters = {};
    // filters['sectionName'] = this.sectionName;
    // filters['search_in_field'] = true;
    // filters['searchField'] = modeldata['filterkey'];
    // filters['searchFieldValue'] = modeldata['value'];
    //
    // this.events.publish('table_refresh_request_' + this.prefix, filters);
    openPageAction() {
        let me = this;
        let fieldData = this.fieldData;
        let fieldType = this.fieldType;
        if (this.fieldType['type'] == 'filter') {
            let filter = {};
            filter['baseFilter'] = JSON.stringify({
                'searchField': this.fieldType['filterkey'],
                'searchFieldValue': this.fieldData['value']
            });
            filter['clear'] = true;
            filter['extradata'] = this.fieldType['extradata'];
            this.events.publish('refresh_request_' + this.prefix, filter);
            return;
        }

        //click buttons and so

        if (this.fieldType['serverCall']) {
            let filters = {
                'page': fieldData['pagePrefix'],
                'id': this.period['id']['value'],
                'saveAndGetIdPrefix': fieldData['saveAndGetIdPrefix'],
                'manager': 1
            };
            for (let key in this.fieldData['extraFilters']) {
                filters[key] = this.fieldData['extraFilters'][key];
            }

            this.modelProvider.custom(this.fieldData['serverCall'], filters, this.fieldData['domain'])
                .then(function (data) {
                    //console.log'results:');
                    //console.logdata);

                    me.modelTools.presentToast('DONE!');
                    me.period[0]['updated'] = true;
                    me.fieldData['updated'] = true;
                    me.fieldData['serverCallGo'] = true;
                    me.events.publish('saveAndOpenPage' + me.prefix, {});
                });

            return;
        } else if (this.fieldType['quickOpen']) {
            //console.log'quickOpen!!!!');

            let data = {
                'page': this.fieldType['pagePrefix'],
                'id': this.fieldData['value'],
                'saveAndGetIdPrefix': this.fieldData['saveAndGetIdPrefix'],
                'manager': 1
            };

            let page = data['page'];
            if (this.fieldType['pageModelId']) {
                page += "/" + this.fieldData['value'];
            }

            let navigationExtras: NavigationExtras = {
                queryParams: data
            };
            this.navCtrl.navigateForward(page, navigationExtras);

            return;
        } else if (this.fieldType['saveAndGetId']) {

            // if (!this.fieldType['unlimitedClick'] && this.fieldData['pageClickedAlready']) {
            //     me.modelTools.presentToast('Already done.');
            //     return;
            // }
            this.fieldData['pageClickedAlready'] = true;

            // fieldData['createEstimate'] = true;
            Object.assign(this.filters, {
                'page': fieldType['pagePrefix'],
                'id': this.period['id']['value'],
                'saveAndGetIdPrefix': fieldType['saveAndGetIdPrefix'],
                'manager': 1
            });
            let tableData = {};
            this.period['main']['updated'] = true;
            // tableData[this.sectionName] = [this.period];
            tableData[this.sectionName] = [{'main': {'updated': true}, 'id': {'value': this.period.id.value}}];

            this.filters['editedSections'] = [this.sectionName];
            this.filters['sectionName'] = this.sectionName;

            this.modelProvider.customPost('saveDataReport', [tableData], this.filters, true).then((data: any) => {
                if (me.fieldType['afterSaveMsg']) {
                    me.modelTools.presentToast(me.fieldType['afterSaveMsg']);
                }

                for (let key in data['data']['models'][0]) {
                    // if (key == 'id' || key == 'main') continue;
                    this.period[key] = data['data']['models'][0][key];
                }

                setTimeout(function () {
                    if (data['data']['models'][0]['main']['customOpen']) {
                        // me.adminService.openLeftModelBox(me.period['main']['customOpen']['sectionName'], null,
                        //     me.period['main']['customOpen'].id, '3ergerg', me.navCtrl);
                        me.adminService.goToAdmin(me.navCtrl, me.period['main']['customOpen']['sectionName'], null,
                            me.period['main']['customOpen']['id'], null, null, true);
                    } else {
                        me.events.publish('refresh_request_' + me.prefix, {});
                    }
                }, 300);
            });

        }
    }

    openTableModelOptions() {
        this.events.publish('openTableModelOptions' + this.prefix, {'model': this.period, 'fieldData': this.fieldData});
    }

    //open specific model page
    openAdminModel(sectionName, id = '') {
        //console.logsectionName, id);

        let params;
        params = {
            'sectionName': sectionName,
            'baseFilter': {
                'searchFieldValue': id,
                'searchField': 'id',
                'search_in_field': true
            },
            'rowIndex': 1 //skip first row -1
        };

        let navigationExtras: NavigationExtras = {
            queryParams: params
        };
        this.navCtrl.navigateForward('admin-model/' + sectionName + id, navigationExtras);
    }

    callFieldEvent(model, fieldData) {
        //console.logfieldData['event']);
        this.events.publish(fieldData['event'], {'model': model});
    }

    selectFieldPopup(event) {
        this.period[0]['updated'] = true;
        this.fieldData['edited'] = true;
        // return
        // this.events.publish('selectFieldPopup' + (this.topPrefix ? this.topPrefix : this.prefix), {
        //     'fieldData': this.fieldData,
        //     'tableData': this.tableInfoData
        // });
    }

    async openCalendar(myEvent, mini = true, modeleventsType = false, force = false) {
        if (this.fieldType['readOnly'] && !force) return;

        // this.events.publish('showMenu',
        //     {
        //         'menu': 'menuAdminBox',
        //         'id': row.id.value,
        //         'rowModel': row,
        //         'prefix': this.prefix,
        //         'boxMenu': true,
        //         'sectionName': this.sectionName,
        //         'width': 500
        //     });
        // return;
        this.adminService.openCalendar(myEvent, this.tableInfoData, this.sectionName, this.prefix, this.exTableData, this.period, this.fieldData,
            this.fieldType, true, false, false,
            this.fieldData.baseFilter = {}, false, this.navCtrl);
        return

        this.adminService.popupEditBoxByData(null, {
            'miniCalendar': mini,
            'avoidSave': this.avoidSave,
            'modeleventsType': modeleventsType,
            'dateModel': this.period,
            'dateModelSectionName': this.sectionName,
            'dateFieldData': this.fieldData,
            'dateFieldType': this.fieldType,
            'prefix': this.prefix,
            'exTableData': this.exTableData
        }, true, CalendarWrapperComponent);
    }

    //from eye of select table
    async presentSelectBar(myEvent, type, getId = null, sectionName = null, model = null, popoverSize = null, bigPopup = false, maxWidth = null) {
        this.fieldData['modelId'] = this.period['id']['value'];
        this.fieldData['topModelSection'] = this.sectionName;
        this.fieldData['fieldName'] = this.fieldType['name'];
        this.fieldData['mainDisplayName'] = this.period['main']['displayName'];

        // let bigPopup = !myEvent ? true : false;
        // if (popoverSize)
        //     bigPopup = true;//popoverSize;
        // else if (this.fieldType['popoverSize'])
        //     bigPopup = true;// = this.fieldType['popoverSize'];
        // else if (this.fieldType['type'] == 'table')
        //     bigPopup = true;// = 'bigger-popover';
        // else bigPopup = false;// = 'smaller-popover';
        // console.log('open popup - ', size
        this.adminService.popupEditBoxByData(myEvent, {
            'model': model ? model : getId ? null : this.period, // if search by id so dont send model - it will query it from server
            'getModelById': getId,
            'extraTableFilters': this.params && this.params['extraFilters'] ? this.params['extraFilters'] : null,
            'extraSaveFilters': this.params && this.params['extraSaveFilters'] ? this.params['extraSaveFilters'] : null,
            'avoidSave': this.avoidSave,
            'prefix': this.prefix,
            'tableInfoData': this.exTableData[(sectionName ? sectionName : this.sectionName) + 'Data'],
            'fieldData': this.fieldData,
            'fieldType': this.fieldType,
            'exTableData': this.exTableData,
            'sectionName': sectionName ? sectionName : this.sectionName, //if select options and not select table.
            'type': type,
            'onTable': this.onTable,
            'z-index': 14
        }, bigPopup, null, null, maxWidth);

        // 'tableInfoData': this.tableInfoData,
        // const popover = await this.popoverCtrl.create({
        //     component: SelectBarComponent,
        //     componentProps: {
        //         'model': model ? model : getId ? null : this.period, // if search by id so dont send model - it will query it from server
        //         'getModelById': getId,
        //         'avoidSave': this.avoidSave,
        //         'prefix': this.prefix,
        //         'tableInfoData': this.exTableData[(sectionName ? sectionName : this.sectionName) + 'Data'],
        //         'fieldData': this.fieldData,
        //         'fieldType': this.fieldType,
        //         'exTableData': this.exTableData,
        //         'sectionName': sectionName ? sectionName : this.sectionName,//if select options and not select table.
        //         'type': type,
        //         'onTable': this.onTable
        //     },
        //     event: this.fieldType['type'] != 'table' && myEvent ? myEvent : null,
        //     animated: true,
        //     showBackdrop: true,
        //     cssClass: 'note-popover icenter-popover ' + size
        // });
        //right-popup
        // return await popover.present();
    }

    saveField() {

        this.fieldData['edited'] = true;
        this.period['main']['updated'] = true;

        // let fieldType = this.fieldType;
        // let fieldData = this.fieldData;
        // let me = this;
        //
        // let newModel = {
        //     'main': {'value': this.period['id']['value'], 'updated': true, 'name': '', 'type': 'none'},
        //     'id': this.period['id']
        // };
        //
        // newModel[fieldType['name']] = {
        //     'value': fieldData['value'],
        //     'value2': fieldData['value2'],
        //     'name': fieldType['name'],
        //     'edited': true,
        //     'type': fieldType['type'],
        //     'typeData': fieldType
        // };
        //
        // let tableData = {};
        // tableData[this.sectionName] = [newModel];
        //
        // this.filters['editedSections'] = [this.sectionName];
        // this.filters['sectionName'] = this.sectionName;

        // this.adminService.toSave.append({'models':[newModel], 'filters':this.filters);

        //save field
        // this.modelProvider.customPost('saveDataReport', [tableData], this.filters, true).then((data: any) => {
        //     me.exTableData[me.sectionName + 'Data']['edited'] = false;
        //     me.period['main']['updated'] = false;
        //     me.fieldData['edited'] = false;
        //     me.fieldData['displayName'] = data['data']['models'][0]['displayName'];
        //     me.fieldData['fieldSaveWait'] = false;
        //
        //     //update calendar
        //     if (['date', 'datetime'].includes(me.fieldType['type'])) {
        //         me.events.publish('calendarRefresh', {'fieldData': me.fieldData});
        //     }
        // });
    }

    isNumeric(str: any) {
        // if (typeof str != "string") return false; // we only process strings!
        //
        return (!isNaN(str) && !isNaN(parseFloat(str))) // ...and ensure strings of whitespace fail
        // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    }

    openExternalLink() {
        window.open(this.fieldData['value'], '_blank');
    }

    // equations tags
    addTagToField(tag, fieldData) {
        if (!fieldData['value']) fieldData['value'] = '';

        fieldData['value'] += "[" + tag + "]";
    }

    booleanClick() {
        this.fieldData['value'] = !!!this.fieldData['value'];
        this.fieldData['icon'] = this.fieldData['value'] ? 'checkmark' : 'square';
        this.fieldData['color'] = this.fieldData['value'] ? 'success' : 'danger';
    }

    //change company
    //set cart info on cart service
    startInventory(action) {
        //check if already connected to this company.
        if (this.orderService.appuser.data.companyId != this.period['company']['value']) {
            this.events.subscribe('agentCompanyChanged', () => {
                // this.events.unsubscribe('agentCompanyChanged');
                //to orderService - will show on profile page the open carts.
                // this.orderService.savedCartList = this.fieldData['cartData'];
                this.navigateToInventory(action);
            });
            this.orderService.changeAgentCompany(null, this.period['company']['value']);
        } else {
            this.navigateToInventory(action);
        }
    }

    navigateToInventory(action) {
        this.orderService.initCart();
        this.orderService.setOrderAction(action);

        this.orderService.loadCartFromData({
            'cartId': this.fieldData['cartId'],
            'cart': this.fieldData['cartData'],
            'cartData': this.orderService.cartData
        }, false);
        // this.orderService.doCartCalc();

        //save model page state to come back at the end.
        this.mainService.saveLastState(this.period['main']['displayName']);

        let navigationExtras: NavigationExtras = {
            'queryParams': {'menus': ['menuSection', 'cartMenu']}
        };
        this.navCtrl.navigateForward('store', navigationExtras);
    }

    nextPage(e, randPref = null) {
        this.events.publish('nextPage' + randPref + 'SubTable');
        // this.events.subscribe()
        setTimeout(function () {
            if (e) e.target.complete();
        }, 2000);
    }

    // public getComponent(comName) {
    //     const factory = this.resolver.resolveComponentFactory(comName);
    //     let componentRef = this.container.createComponent(factory);
    //     // (<ProcessComponent>componentRef.instance);
    // }
    //
    // private resolveComponentsName(step) {
    //     if (step === 1) {
    //         return AppImgComponent;
    //     } else if (step === 2) {
    //         return AppImgComponent;
    //     } else if (step === 3) {
    //         return AppImgComponent;
    //     }
    // }

    removeIdFromMultiField(i) {
        this.fieldData['multiChooseArr'].splice(i, 1);
        let multichoose = this.fieldData['multiChooseArr'];

        let ids = [];
        for (let k in multichoose) {
            ids.push(multichoose[k]['value'])
        }

        this.fieldData['multiChooseValue'] = JSON.stringify(ids);
        this.markFieldEdit();
        this.adminService.setNeedSave();
    }

    saveJson() {
        this.fieldData['value'] = JSON.stringify(this.jsonRes);
    }


    rowClickIcon($event, iconName, model, fieldType = null) {
        let fieldData = model[fieldType.name];
        if (fieldData['iconsData'] && fieldData['iconsData'][iconName]) {
            let r = fieldData['iconsData'][iconName];
            if (r['type'] == 'map') {
                this.adminService.mapSelect($event, this.prefix, this.exTableData, model,
                    r['addressId'], r['latLng'], fieldType, this.sectionName);
            } else if (r['type'] == 'popupText') {
                this.modelTools.textAlert(r['title'], r['text']);
            }
        }
    }

    getTime(fieldData) {
        setInterval(function () {
            let timeFormat = "HH:mm";
            fieldData['value'] = moment(fieldData['value'], timeFormat).format('HH:mm');
            fieldData['displayName'] = fieldData['value'];
        })
    }
}
