import {Component, Input, OnInit} from '@angular/core';
import {BusinessService} from "../../services/BusinessService";
import {Business} from "../../../framework/model/core/business/business";
import {PopoverController} from "@ionic/angular";

@Component({
    selector: 'opening',
    templateUrl: './opening.component.html',
    styleUrls: ['./opening.component.scss'],
})
export class OpeningComponent implements OnInit {

    public business: Business;
    public openingData = [];

    @Input()
    public title:string;

    @Input()
    public text:string;

    constructor(public businessService: BusinessService, public popoverctrl: PopoverController) {
        this.business = this.businessService.getBusiness();
    }

    ngOnInit() {
        if(this.text) return;
        let data = this.business.data.GlobalSettingsData.openingData;
        for(let k in data['details'])
        {
            let res = {
                'dayName':data['details'][k]['dayName'],
                'dayOff':data['details'][k]['dayOff'],
                'open':this.setTime(data['details'][k]['open']),
                'close':this.setTime(data['details'][k]['close'])
            }
            this.openingData.push(res);
        }
    }

    setTime(time)
    {
        let timeTokens = time.split(':');
        return new Date(1970,0,1, timeTokens[0], timeTokens[1], timeTokens[2]);
    }


    close() {
        this.popoverctrl.dismiss();
    }

}
