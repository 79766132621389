import {Component, Input, OnInit} from '@angular/core';
import {CostumRes} from "../../../../../../../app/global-module/global-module.module";
import {NavigationExtras} from "@angular/router";
import {ModelProvider} from "../../../../../../../framework/providers/modelProvider/ModelProvider";
import {Platform} from "@ionic/angular";
import {DomSanitizer} from "@angular/platform-browser";
import {Events} from "../../../../../../../framework/providers/events";

@Component({
    selector: 'app-payment',
    templateUrl: './payment.component.html',
    styleUrls: ['./payment.component.scss'],
})
export class PaymentComponent implements OnInit {

    public url;

    @Input()
    public filters;

    @Input()
    public widthRest = 0;

    @Input()
    public prefix;

    public interval;
    public width;

    public finish:boolean = false;

    constructor(public modelProvider: ModelProvider,
                public platform: Platform,
                public events: Events,
                private sanitizer: DomSanitizer,) {
    }


    ngOnInit() {
        this.setParams();
        this.checkCashLoop();

        this.width = this.platform.width() - this.widthRest+'px';
    }

    setParams() {
        this.modelProvider.custom('paymentsignature', this.filters).then((res) => {
            // let sign = res['signature'];
            this.url = this.trustSrc(res['url'])
        })
    }

    trustSrc(url) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }

    ngOnDestroy()
    {
        //console.log"PAYMENT DESTORY!")
        clearInterval(this.interval)
    }

    checkCashLoop() {
        let me = this;
        this.interval = setInterval(function () {
            // me.faild = false;
            me.modelProvider.custom("paymentcheck", me.filters).then(function (res: CostumRes) {
                if (me.finish) return;
                if (res['res'] == 1) {
                    // me.faild = false;
                    // let navigationExtras: NavigationExtras = {
                    //     queryParams: {
                    //         'orderId': me.orderId,
                    //         'backToRoot': true
                    //     },
                    //     replaceUrl: true,
                    // };

                    // me.newOrderPopup();
                    // me.navCtrl.navigateRoot('order/' + me.orderId, navigationExtras);
                    // me.orderService.clearCart(true);
                    me.events.publish(me.prefix);

                    // me.navCtrl.remove(me.viewCtrl.index - 1, 2);
                    me.finish = true;
                    clearInterval(me.interval)
                }
                // } else if (res.res == -1) {
                //     // me.faild = true;
                //     me.modelProvider.presentToast(me.translate.instant('faildPaymentTryAgain'), true, 'danger')
                //     me.url = null;
                //     setTimeout(function () {
                //         me.url = me.trustSrc()
                //     })
                // }
            });
        }, 3000);
    }


    // newOrderPopup() {
    //     let alerttxt = this.translate.instant('dearCustomerThankYouForYourOrder') + ' ' + this.business.data.GlobalSettingsData.name + '.';
    //     let alertext = this.translate.instant('orderNumber') + ' ' + this.orderId + '.';
    //     this.modelToolsProvider.textAlert(alerttxt, alertext);
    // }

}
