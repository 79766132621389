import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ModelProvider} from "../../../../../framework/providers/modelProvider/ModelProvider";
import {ItemMaker} from "../../../../../framework/model/core/items/itemMaker";
import {AppDataService} from "../../../../services/AppDataService";
import {BusinessService} from "../../../../services/BusinessService";

@Component({
    selector: 'app-maker',
    templateUrl: './maker.page.html',
    styleUrls: ['./maker.page.scss'],
})

export class MakerPage implements OnInit {
    public model: any;
    public modelId: any;
    public models: Array<ItemMaker> = [];

    public options: object = {};

    constructor(public route: ActivatedRoute,
                public businessService: BusinessService,
                public modelProvider: ModelProvider, public appdataService: AppDataService) {
        this.modelId = this.route.snapshot.paramMap.get('id');
        this.model = this.appdataService.getModelById('makers', 'itemMaker', this.modelId)
        console.log(this.model)
        // this.route.queryParams.subscribe(params => {
        //     this.model = params['model'];
        //     if (!this.model)
        //         this.listMaker();
        // });
    }

    ngOnInit() {
        //console.logthis.model)
    }

    listMaker() {
        this.modelProvider.list('itemMaker', {'id': this.modelId}).then((res) => {
            this.model = res['models'][0];
        })
    }

}
