export const SelectOptions = {
    "YES_NO_DONTKNOW_DEFAULT": "Yes",
    "YES_NO_DONTKNOW": {
        'yes': 'Yes',
        'no': 'No',
        'Dont know': "Don't know"
    },

    "HANDLE_SIDE": {
        'front':'tofront',
        'back':'toback'
    },

    "PAYMENT_METHOD": {
        'creditCard':'creditCard',
        'cash':'cash',
        'check':'check',
        'bill':'bill',
        'govaina':'govaina',
        'checkToDeliveryMan':'checkToDeliveryMan',
        'callMeForCreditCard':'callMeForCreditCard'
    },

    "CLIENT_TYPE": {
        'private':'private',
        'business':'business'
    },

    "PLACE_TYPE": {
        'house':'house',
        'apartment':'apartment'
    },

    "INSURANCE_TYPES": {
        'basic':'basic',
        'extended':'extended',
        'extCompany':'extCompany'
    }

};