import {Component, Input, OnInit} from '@angular/core';
import {AppConfig} from "../../../../../../conf/main";
import {ModelProvider} from "../../../../../../../framework/providers/modelProvider/ModelProvider";
import {ModalController} from "@ionic/angular";
import {ModelAddPage} from "../../../../../../../framework/pages/model-add/model-add.page";
import {Section} from "../../../../../../../framework/model/core/sections/section";
import {Router} from "@angular/router";
import {SectionsManagerService} from "../../providers/sectionManagerService";
import {Events} from "../../../../../../../framework/providers/events";

@Component({
    selector: 'sections-options',
    templateUrl: './sections-options.component.html',
    styleUrls: ['./sections-options.component.scss'],
})
export class SectionsOptionsComponent implements OnInit {
    // @Input()
    // public checkedItems: object;
    //
    @Input()
    public readonly:boolean;

    // @Input()
    // public checkedItemsData: object;

    // @Input()
    public sectionsData: object;
    public itemsData: object;
    public transferData: object;

    public checkedItemsArr:Array<any> = [];


    constructor(public modelProvider: ModelProvider, public events: Events, public router:Router,
                public modalController: ModalController, public sectionsManagerService:SectionsManagerService) {

        this.sectionsData = this.sectionsManagerService.getSectionsData();
        this.itemsData = this.sectionsManagerService.getItemsData();
        this.transferData = this.sectionsManagerService.getTransferData();
    }

    ngOnInit() {
        // this.setCheckedItemsArr();
        // this.initSectionsOptions();
    }

    save()
    {
        this.sectionsManagerService.transfer();
    }

    donecheck()
    {
        this.events.publish('moveToDestTab');
    }

    cancelcheck()
    {
        this.events.publish('moveToOriginTab');
    }

    initAll()
    {
        this.sectionsManagerService.initAll();
    }

    goAddToSection()
    {
        this.transferData['toSectionChanged'] = false;
        this.events.publish('goToTab', {'tab':'2'});
    }




    // initCheckedItems() {
    //     this.events.publish('initCheckedItems')
    // }


    // removeItem(itemId) {
    //     this.checkedItems[itemId] = false;
    //     this.setCheckedItemsArr();
    // }


}
