import {Component, Input, OnInit} from '@angular/core';
import {Events} from "../../../../../../framework/providers/events";
import {AdminService} from "../../../../../../core/services/AdminService";

@Component({
    selector: 'app-field-select',
    templateUrl: './field-select.component.html',
    styleUrls: ['./field-select.component.scss'],
})
export class FieldSelectComponent implements OnInit {

    @Input()
    prefix;

    @Input()
    myprefix = "fieldPrefix" + Math.floor(Math.random() * Math.floor(1000000));

    @Input()
    exTableData;
    @Input()
    sectionName;
    @Input()
    topField;

    @Input()
    fieldPref;
    @Input()
    options = ['direct', 'relative', 'table', 'template'];

    templateModel;
    currentField;

    showFieldType = 'direct';

    tableFields = [];

    constructor(public events: Events, public adminService: AdminService) {
    }

    ngOnInit() {
        if (this.topField && this.topField.type == 'table') {
            this.events.subscribe('addFieldToTemplate' + this.myprefix, (data) => {
                let prefs = data['topPref'].split('|')
                //remove my name and first empty cell
                prefs = prefs.splice(2)

                this.addToTableFields(prefs.join('|') + '|' + data['field']);
            });
        }
    }

    addField(field) {
        if (['table', 'selectTable'].indexOf(field.type) != -1) {
            this.currentField = field;
        } else if (this.topField && this.topField.type == 'table') {
            console.log('on table field!!!');
            this.addToTableFields(field['name']);
        } else {
            this.events.publish('addFieldToTemplate' + this.prefix, {'field': field.name, 'topPref': this.fieldPref});
        }
    }

    tableListAddToHtml()
    {
        this.events.publish('addFieldToTemplate' + this.prefix, {'field': 'fields|'+this.tableFields.join(','), 'topPref': this.fieldPref});
    }

    addToTableFields(fieldName) {
        if (!this.tableFields.includes(fieldName)) {
            this.tableFields.push(fieldName);
        } else {
            this.tableFields.splice(this.tableFields.indexOf(fieldName));
        }
        console.log(this.tableFields);
    }

    addTemplateField() {
        let v = this.templateModel['form']['value'];
        if (this.templateModel['filter']['value']) v = v + ',' + this.templateModel['filter']['value'];
        this.events.publish('addFieldToTemplate' + this.prefix, {'field': 'template|[' + v + ']', 'topPref': this.fieldPref});
    }

    //select table
    //select table template
    async presentSelectBar(myEvent, modelName, fieldName) {
        let baseFilter = {
            'searchFieldValue': this.sectionName,
            'searchField': 'modelData__modelName',
            'search_in_field': true
        };
        let fieldType = {'name': fieldName, 'type': 'selectTable', 'model': modelName};

        if (!this.templateModel) {
            let fieldDataForm = {'value': '', 'baseFilter': baseFilter};
            let fieldDataFilter = {'value': '', 'baseFilter': baseFilter};
            this.templateModel = {'form': fieldDataForm, 'filter': fieldDataFilter, 'id': -1, 'main': {}};
        }
        this.adminService.selectEditBox(myEvent, this.templateModel, "asdasd", modelName, this.exTableData, 'selectTable',
            false, this.templateModel[fieldName], fieldType);
    }

}
