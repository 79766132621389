import {Component, Input, OnInit} from '@angular/core';
import {Events} from "../../../framework/providers/events";

@Component({
    selector: 'app-keyboard',
    templateUrl: './keyboard.component.html',
    styleUrls: ['./keyboard.component.scss'],
})
export class KeyboardComponent implements OnInit {

    @Input()
    type: string = 'letters';
    @Input()
    data: Object = {};
    @Input()
    fieldkey: string;
    @Input()
    prefix: string;
    @Input()
    fieldType: string = 'text';

    options = {'visible':true}

    backupStr;
    id;

    keyboards = {
        numbers: [
            '789'.split(''),
            '456'.split(''),
            '123'.split(''),
            '<0-'.split('')
        ],

        numbersOk: [
            '789'.split(''),
            '456'.split(''),
            '123'.split(''),
            '<0-'.split(''),
            ['Enter', 'Cancel']
        ],

        numbersOkCancel: [
            '789'.split(''),
            '456'.split(''),
            '123'.split(''),
            '<0-'.split(''),
            ['Enter', 'Cancel']
        ],

        lettersEn: [
            'QWERTYUIOP<'.split(''),
            'ASDFGHJKL-'.split(''),
            'ZXCVBNM'.split(''),
            ['Enter', 'Space', 'Cancel']
        ],

        emailEn: [
            '1234567890'.split(''),
            'QWERTYUIOP<'.split(''),
            'ASDFGHJKL-'.split(''),
            'ZXCVBNM@.'.split(''),
            ['Enter', 'Cancel']
        ],


        lettersEnNumber: [
            '1234567890<'.split(''),
            'QWERTYUIOP'.split(''),
            'ASDFGHJKL-'.split(''),
            'ZXCVBNM'.split(''),
            ['Enter', 'Space', 'Cancel']
        ],

        lettersNumberEnter: [
            '1234567890'.split(''),
            'קראטוןםפ'.split(''),
            '<שדגכעיחלךף'.split(''),
            '-זסבהנמצת'.split(''),
            ['Enter', 'Space']
        ],

        lettersNumber: [
            '1234567890'.split(''),
            'קראטוןםפ-'.split(''),
            '<שדגכעיחלךף'.split(''),
            'זסבהנמצת'.split(''),
            ['Space']
        ],

        letters: [
            'קראטוןםפ'.split(''),
            '<שדגכעיחלךף'.split(''),
            '-זסבהנמצת'.split(''),
            ['Enter', 'Space', 'Cancel']
        ],

        email: [
            '1234567890'.split(''),
            'קראטוןםפ'.split(''),
            '<שדגכעיחלךף'.split(''),
            '-זסבהנמצת'.split(''),
            ['Enter', 'Cancel']
        ],

        lettersEngCart: [
            '1234567890'.split(''),
            'QWERTYUIOP'.split(''),
            'ASDFGHJKL'.split(''),
            'ZXCVBNM'.split(''),
            ['En', 'Enter', '_', '<<', '<', '-']
        ],

        lettersHebCart: [
            '1234567890'.split(''),
            'קראטוןםפ'.split(''),
            'שדגכעיחלךף'.split(''),
            'זסבהנמצת'.split(''),
            ['En', 'Go', '_', '<<', '<', '-']
        ]
    }


    constructor(public events: Events) {
        this.id = Math.floor(Math.random() * 100);
        // console.log('keyboard constructoR!' + this.id);
    }

    ngOnInit() {
        // console.log('start key board')
        // console.log(this.fieldkey);
        // console.log(this.data);
        if (this.fieldkey) {
            this.backupStr = this.data[this.fieldkey];
            // console.log('save me.backupStr' + this.backupStr)
        }

        let me = this;
        // console.log('listen: keyboard_change_type_'+this.prefix);
        this.events.subscribe('keyboard_change_type_' + this.prefix, function (params) {
            // console.log(params)
            me.type = params.type;
            me.data = params.data;
            me.fieldkey = params.fieldkey;
            me.prefix = params.prefix;
        });
    }

    letterClick(letter) {
        console.log('letterClick ' + letter);

        if (this.fieldkey) {
            console.log('letter click: ' + this.fieldkey)

            //current text
            let fieldText = this.data[this.fieldkey];

            if (letter == 'En') {
                this.type = (this.type != 'lettersHebCart') ? 'lettersHebCart' : 'lettersEngCart';
            }
            else if (letter == '<') {
                fieldText = fieldText.substring(0, fieldText.length - 1);
            } else if (letter == '<<') {
                fieldText = '';
            } else if (letter == 'Go') {
                this.events.publish('keyboard_click_' + this.prefix, {'enter': true});
                return;
            } else if (letter == '_')
                fieldText += " ";
            else if (letter == 'Cancel') {
                fieldText = this.backupStr;
                this.events.publish('keyboard_click_' + this.prefix, {'cancel': true});
            } else {

                //letter click

                if (!fieldText) fieldText = '';

                console.log(this.fieldType);

                //if number - let numbers only.
                if (this.fieldType == 'number') {
                    if (!isNaN(parseInt(letter))) {
                        fieldText += letter;
                    }
                } else {
                    fieldText += letter;
                }
                // this.events.publish('keyboard_click', {'letter': letter});
                this.events.publish('keyboard_letter_' + this.prefix, {'fieldText': fieldText});
            }

            this.events.publish('keyboard_letter_' + this.prefix, {'fieldText': fieldText});

            //set new text
            this.data[this.fieldkey] = fieldText;
            // console.log(this.data[this.fieldkey]);
        }

        this.events.publish('keyboard_click_' + this.prefix, {'fieldText': letter});
    }

    publish(key, params)
    {
        console.log(key, params, this.prefix)
        this.events.publish(key + this.prefix, params);
    }

    ngOnDestroy() {
        // console.log('keyboard destroy')
        this.events.unsubscribe('keyboard_change_type_' + this.prefix);
    }

}
