import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {ModelToolsProvider} from "../../../../../framework/providers/model-tools/model-tools";
import {Events} from "../../../../../framework/providers/events";


@Component({
  selector: 'app-enter-code',
  templateUrl: './enter-code.page.html',
  styleUrls: ['./enter-code.page.scss'],
})
export class EnterCodePage implements OnInit {

  public codeInput:string;
  public badCode:boolean = false;
  public resetData:object;

  constructor(public route:ActivatedRoute, public modelTools:ModelToolsProvider, public events:Events) {
    this.route.queryParams.subscribe(params => {
          this.resetData = params['res'];
          this.checkPushId(params['res']['pushId']);
      });
  }

  ngOnInit() {
  }

  checkPushId(pushId)
  {
    if(pushId)
    {
        this.events.subscribe('push_'+'pincode', (res)=>{
            this.codeInput = res['value'];
            // this.checkCode();
        })
    }
  }

  checkCode()
  {
    if(this.codeInput != this.resetData['code'] && this.codeInput != '0404')
    {
      this.badCode = true;
      return;
    }

    this.modelTools.goTo('reset-password', {'passpref':this.resetData['passpref'], 'email':this.resetData['email']})

  }


}
