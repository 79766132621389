// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.no-border.item-md.item-block .item-inner {
  border: 0;
}

.no-border {
  background: none;
}

.no-border-radius {
  border-radius: 0px 0px 0px 0px;
}

.select-searchable {
  max-width: 100%;
}

.no-paading-select .select-searchable-value {
  padding: 4px;
}

.no-paading-select .select-searchable-icon-inner {
  top: 11px;
}

.text-box {
  font-size: 13px;
  min-height: 21px;
  padding: 3px 8px 0px 0px;
}

.placeholder {
  color: #9a9a9a;
}`, "",{"version":3,"sources":["webpack://./src/framework/components/field/modelscroll/modelscroll.component.scss"],"names":[],"mappings":"AAAA;EAEE,SAAA;AAAF;;AAGA;EAEE,gBAAA;AADF;;AAGA;EAEE,8BAAA;AADF;;AAIA;EAEE,eAAA;AAFF;;AAKA;EAEE,YAAA;AAHF;;AAMA;EACE,SAAA;AAHF;;AAMA;EAEE,eAAA;EACA,gBAAA;EACA,wBAAA;AAJF;;AAOA;EAEE,cAAA;AALF","sourcesContent":[".no-border.item-md.item-block .item-inner\n{\n  border:0;\n}\n\n.no-border\n{\n  background: none;\n}\n.no-border-radius\n{\n  border-radius: 0px 0px 0px 0px;\n}\n\n.select-searchable\n{\n  max-width: 100%;\n}\n\n.no-paading-select .select-searchable-value\n{\n  padding: 4px;\n}\n\n.no-paading-select .select-searchable-icon-inner {\n  top: 11px;\n}\n\n.text-box\n{\n  font-size: 13px;\n  min-height: 21px;\n  padding: 3px 8px 0px 0px;\n}\n\n.placeholder\n{\n  color:#9a9a9a;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
