import {Component, OnInit} from '@angular/core';

export class CartModel {

    public data;

    constructor(item, coupons = [], data = null, currency_data = []) {
        this.initData(item, coupons, currency_data)
        if(data) this.data = data;
    }

    initData(item, coupons, currency_data){
        let discount_options = []

        // if (cartItem['features'] && cartItem['features']['cart_prefix'] && item.agentToolData && item.agentToolData['cart_prefix']) {
        //     // console.log(cartItem['features']['cart_prefix'], item.agentToolData['cart_prefix'], cartItem['features']['cart_prefix'] != item.agentToolData['cart_prefix'])
        //     if (cartItem['features']['cart_prefix'] != item.agentToolData['cart_prefix'])
        //         return
        // }

        if (!coupons) coupons = [];
        if (!currency_data) currency_data = [];
        // coupons.unshift({'name': 'without', 'code': 0});
        let values = {};
        let data = {
            'prefix': 'cartModel_' + Math.floor(Math.random() * Math.floor(1000000)),
            'fields': ['amount', 'price', 'discount', 'corePrice', 'final_unit_price',
                'stock', 'totalPrice', 'coupon_amount', 'coupon_code', 'currency_code', 'comments', 'is_bonus', 'action_amount'],
            'editFields': ['amount', 'price', 'discount'],
            // 'cartItem': cartItem,
            'expand': false,
            'loading': false,
            'item_id':item.data.id,
            'package_units':item.data.packageUnits,
            'original_discount': item.data.discount ? item.data.discount : 0,

            'currencyData': item.data.currencyCode,
            'isTopItem': item.data.isTopItem,

            'in_cart': values['amount'] > 0,
            'visible': !item.data.isTopItem,
            'ticketId': null,

            'init': true,
            'data': {
                'amount': {
                    'title': 'amount',
                    'value': values['amount'],
                    'input': true,
                    'type': 'number',
                    'numberButtons': true,
                    'readonly': false,
                    'style': {'border': '1px solid #8fb3b3'}
                },
                'action_amount': {
                    'title': 'action_amount',
                    'value': values['action_amount'],
                    'type': 'number',
                },
                'package_amount': {
                    'title': 'amount',
                    'value': values['package_amount'],
                    'type': 'number',
                    'input': true,
                    'readonly': true,
                },
                'code': {'title': 'code',
                    'value': values['code']},
                'stock': {
                    'title': 'stock',
                    'value': values['stock'],
                    'style': {'max-width': '120px'}
                    // 'style': {'flex': '0 0 23%'}
                },
                'price': {
                    'title': 'price',
                    'value': values['price'],
                    'price': true,
                    'input': true,
                    'type': 'number',
                    'style': {'border': '1px solid #8fb3b3', 'max-width': '120px'}
                    // 'style': {'flex': '0 0 20%'}
                },
                'discount': {
                    'title': '%discount',
                    'value': values['discount'],
                    'input': true,
                    'type': 'number',
                    'addTxt': '%',
                    'max': 100,
                    'focus': false,
                    'options': discount_options,
                    'style': {'border': '1px solid #8fb3b3'}
                    // 'style': {'flex': '0 0 16%'}
                },
                'final_unit_price': {
                    'title': 'neto',
                    'value': 'final_unit_price',
                    // 'cartItemField': true,
                    'price': true,
                    // 'hideOnEmpty':true,
                    'type': 'price',
                    // 'cssClass': 'italic',
                    'style': {'max-width': '120px'}
                    // 'style': {'flex': '0 0 27%'}
                },
                'totalPrice': {
                    'title': 'total',
                    'value': 'totalPrice',
                    'type': 'price',
                    // 'cartItemField': true,
                    'price': true,
                    // 'hideOnEmpty':true,
                    'cssClass': 'bold italic',
                    // 'style': {'max-width': '120px'}
                    'style': {'max-width': 'inherit'}
                },
                'coupon_amount': {
                    'title': 'bonus_amount',
                    'value': values['coupon_amount'],
                    'input': true,
                    'isExpand': true,
                    'type': 'number'
                },
                'coupon_code': {
                    'title': 'bonusReason',
                    'value': values['coupon_code'],
                    'select': coupons,
                    'hidden': !coupons.length,
                    'input': true,
                    'isExpand': true,
                    'type': 'text'
                },
                'action_value': {
                    'title': 'action_value',
                    'value': values['action_value'],
                    'type': 'text',
                    'input': true,
                },
                'currency_code': {
                    'title': 'currency',
                    'value': values['currency_code'],
                    'select': currency_data,
                    'hidden': !currency_data.length,
                    'input': true,
                    'isExpand': true,
                    'type': 'text'
                },
                'barcode': {
                    'title': 'barcode', 'value': values['barcode'],
                    'isExpand': true,
                },
                'comments': {
                    'title': 'comments',
                    'value': values['comments'],
                    'type': 'text',
                    'input': true,
                    'style': {'flex': '1 1 100%'},
                    'isExpand': true,
                    'forceInput': true,
                },
                'corePrice': {
                    'title': 'corePrice',
                    'value': values['corePrice'],
                    'price': true,
                    'cssClass': 'italic',
                    'style':  {'max-width': '120px'}
                },
                'salePrice': {
                    'title': 'salePrice',
                    'value': values['salePrice'],
                    'price': true,
                    'cssClass': 'italic',
                    'style':  {'max-width': '120px'}
                },
                'coreTotalPrice': {
                    'title': 'coreTotalPrice',
                    'value': values['coreTotalPrice'],
                    'price': true,
                    'cssClass': 'italic',
                    'style':  {'max-width': '120px'}
                },
                'is_bonus': {
                    'title': 'is_bonus',
                    'value': values['is_bonus'],
                    'type': 'boolean',
                    'style':  {'max-width': '120px'},
                    'isExpand': true,
                },

                'lastPrice': {
                    'title': 'lastOrderedPrice', 'value': values['lastPrice'],
                    'price': true,
                    'isExpand': true,
                },

                'props': {
                    'title': 'props', 'value': values['props'],
                    'price': true,
                    'isExpand': true,
                },

            },
            'features': null,
        };


        //
        // if (cartItem['data']['coupon_code'] || cartItem['data']['coupon_amount']) {
        //     item.agentToolData['expand'] = true;
        // }
        this.data = data;

        this.initValues(item);

    }

    getValues()
    {
        let fields = this.data['fields']
        let values = {}
        for(let key of fields)
        {
            values[key] = this.getValue(key);
        }

        return values
    }

    initValues(item) {
        let itemprice = item.data.price ? item.data.price : 0
        let corePrice = item.data.oldprice ? item.data.oldprice : item.data.price
        this.data.original_discount = item.data.discount ? item.data.discount : 0;
        if(!corePrice) corePrice = 0
        let values = {
            'code': item.data.code,
            'stock': item.data.stock,
            'barcode': item.data.barcode,
            'corePrice': corePrice,
            'lastPrice': item.data['ordered_data'] ? item.data['ordered_data']['last_price'] : '0',
            'amount': 0,
            'package_amount': 0,
            'price': corePrice + '',
            'salePrice': item.data.oldprice && !this.data.original_discount ? item.data.price : null,
            'final_unit_price': itemprice + '',
            'totalPrice': '0',
            'discount': this.data.original_discount,
            'coupon_amount': '0',
            'coupon_code': null,
            'action_value': null,
            'currency_code': item.data['item_currency'],
            'is_bonus': false,
            'comments': null,
            'props': [],
        }

        this.setValues(values);
    }

    setValues(values, skip = [], force = false){
        let data = this.data;
        for (let key in values) {
            if(values['skip_'+key]) continue
            if(!data['data'][key]) continue
            data['data'][key]['value'] = values[key];
            if(!['comments', 'coupon_code', 'is_bonus', 'action_amount', 'currency_code', 'props'].includes(key)) {
                if(!data['data'][key]['value']) continue
                data['data'][key]['value'] += '';
            }
        }

        data['in_cart'] = values['amount'] > 0 || force;
        data['visible'] = data['in_cart'];

        // this.updateModel();
    }

    updateValues(values) // from cartcalc;
    {
        if(this.data['focus']) return
        let to_update = values['to_update']
        for(let k of to_update)
        {
            if(this.getValue(k, 'focus')) continue;
            this.setValue(k, values[k]);
        }
        this.data['features'] = values['features'];
        this.data['saledata'] = values['saledata'];
        if(this.data['features']['currency_data'])
        {
            this.data['currencyData'] = this.data['features']['currency_data'];
        }
    }

    addToCart(key, value, options)
    {
        let keyValue = parseFloat(this.getValue(key));

        if(!keyValue && this.getValue(key, 'focus')) {
            keyValue = parseFloat(this.getValue(key, 'placeholder'))
        }

        if(options['add_to_value']) {
            keyValue += value;

            if(keyValue == 0 && options['in_cart']) return; // removing from cart only if using trash (value=0);
            if(keyValue < 0) return;
        }
        else {
            keyValue = value;

        }

        this.setValue(key, keyValue)
        this.updateModel();
        return this.getValue(key)
    }

    updateModel(force=false)
    {
        let amount = this.getValue('amount');
        let discount = parseFloat(this.getValue('discount'));
        let unitPrice = parseFloat(this.getValue('price'));

        if(unitPrice == 0){
            unitPrice = this.getValue('corePrice');
        }
        if(!unitPrice && this.getValue('price', 'focus')) {
            unitPrice = parseFloat(this.getValue('price', 'placeholder'))
        }

        let corePrice = parseFloat(this.getValue('corePrice'));

        if(!this.data['in_cart'] && unitPrice == corePrice){
            let salePrice = parseFloat(this.getValue('salePrice'));
            unitPrice = salePrice ? salePrice : corePrice;
        }

        if(!amount && !force){
            unitPrice = corePrice;
            this.setValue('price', unitPrice)
        }

        let discountPrice = 0
        if(discount)
        {
            if(discount>100) {
                discount = 100;
                this.setValue('discount', discount);
            }
            discountPrice = unitPrice * discount / 100;
        }

        let final_unit_price = unitPrice - discountPrice;

        let totalPrice = amount * final_unit_price

        if(!amount && !force)
        {
            this.setValue('coupon_amount', 0)
            this.setValue('discount', this.data.original_discount);
        }

        let currencyRate = this.data['currencyData'][1];
        totalPrice *= currencyRate

        if(this.data.package_units > 1)
            this.setValue('package_amount', this.data.package_units ? amount / this.data.package_units : 1)
        this.setValue('final_unit_price', final_unit_price)
        this.setValue('totalPrice', totalPrice.toFixed(2))
        this.setValue('coreTotalPrice', (amount * corePrice).toFixed(2))

        this.data['in_cart'] = amount > 0 || force;
        this.data['visible'] = this.data['in_cart'];
    }

    getValue(key, fieldKey = 'value')
    {
        if(!this.data['data'][key]) return;
        return this.data['data'][key][fieldKey];
    }

    setValue(key, value)
    {
        if(!this.data['data'][key]) return;
        this.data['data'][key]['value'] = value;
    }
}
