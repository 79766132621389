import {Component, Input, OnInit} from '@angular/core';
import {AdminService} from "../../../../../core/services/AdminService";
import {PopoverController, ToastController} from "@ionic/angular";
import {Events} from "../../../../../framework/providers/events";
import {ModelToolsProvider} from "../../../../../framework/providers/model-tools/model-tools";
import {ModelProvider} from "../../../../../framework/providers/modelProvider/ModelProvider";

@Component({
    selector: 'app-app-equation',
    templateUrl: './app-equation.component.html',
    styleUrls: ['./app-equation.component.scss'],
})
export class AppEquationComponent implements OnInit {

    @Input()
    prefix;

    @Input()
    exTableData;
    @Input()
    sectionName;
    @Input()
    model;
    @Input()
    fieldData;
    @Input()
    fieldType;

    testData = {};

    fieldsType;
    saveInterval;

    //selectTable templte/field choose
    selectTableType = 'field';
    templateModel;

    //current field to be choose / copy
    currentField;
    currentEquationField;

    customVars;
    finalCost;
    finalEquation;
    customVarFieldName;

    constructor(private adminService: AdminService, private toastCtrl: ToastController,
                private modelProvider: ModelProvider,
                private popoverCtrl: PopoverController, private modelTools: ModelToolsProvider) {
    }

    ngOnInit() {
        this.fieldsType = this.adminService.getFieldsType(this.sectionName, this.exTableData);

        this.customVarFieldName = this.fieldType['customVarFieldName'];
        if (this.model[this.customVarFieldName]) {
            this.customVars = JSON.parse(this.model[this.customVarFieldName]['value']);
        } else {
            this.customVars = [];
        }
        this.currentEquationField = this.fieldData;
        this.currentEquationField['name'] = this.fieldType['name'];
    }

    addFieldTag(val) {
        if (!this.currentEquationField.value || this.currentEquationField.value == 'null')
            this.currentEquationField.value = '';
        this.currentEquationField.value += val;
        this.save();
    }

    save() {
        clearTimeout(this.saveInterval);

        let fieldData = this.fieldData;
        fieldData = this.model[this.currentEquationField['name']];

        let me = this;
        this.saveInterval = setTimeout(function () {

            let fields = [
                {'fieldType': me.currentEquationField, 'fieldData': fieldData},
            ]
            if (me.fieldsType['optionField']) {
                fields.push({'fieldType': me.fieldsType['optionField'], 'fieldData': me.model['optionField']});
            }

            me.adminService.saveFields(me.sectionName, me.model, fields, function (){
                me.calFinalCost();
            });
        }, 2000);
    }

    saveVars() {
        this.model[this.customVarFieldName]['value'] = JSON.stringify(this.customVars);
        this.model[this.customVarFieldName]['extraData'] = {'customVar':true};
        this.adminService.saveField(this.sectionName, this.model, this.model[this.customVarFieldName], this.fieldsType[this.customVarFieldName]);
    }

    //depricated
    addField(fieldType, extraData = []) {
        this.currentField = fieldType;

        //this types require more data.
        if (['table', 'selectTable'].indexOf(this.currentField.type) != -1 && extraData.length == 0) {
            return;
        }

        let val;

        // if (fieldType['type'] == 'table') {
        //     val = "{" + fieldType['name'];// + "|template|filter|sort|num";
        // } else if (fieldType['type'] == 'selectTable') {
        //     val = "{" + fieldType['name']; //|type|fieldName // type-template/field
        // } else {
        val = fieldType['name'];
        // }

        ////console.logextraData);

        for (let key of extraData) {
            val += '/' + key;
        }
        val = "{" + val + "}"
        this.fieldData.value += val;
        this.adminService.saveField(this.sectionName, this.model, this.fieldData, this.fieldType);
    }

    addVar() {
        let me = this;
        if (!this.customVars) this.customVars = [];

        let inputs = [
            {
                name: 'name',
                type: 'text',
                label: 'Var Name'
            }
        ];

        this.modelTools.inputAlert('Custom Var', 'Please enter name of custom field', function (res) {
            console.log(res);
            me.customVars.push({'name': res['name'], 'isNumber': res['isNumber'] == 'on'});

            me.model[me.customVarFieldName]['value'] = JSON.stringify(me.customVars);

            me.saveVars();

        }, false, inputs);
    }

    calFinalCost() {
        let filters = {'type': 'moving', 'name': 'taarif'};
        let me = this;
        this.finalCost = 'Caculating..';

        filters['getId'] = this.model['id']['value'];
        filters['sectionName'] = this.sectionName;
        filters['fieldName'] = this.currentEquationField['name'];

        // filters['customVars'] = JSON.stringify(this.customVars);
        filters['vars'] = JSON.stringify(this.testData);

        filters['testFormula'] = 1;

        this.modelProvider.list('report', filters, true).then((data: any) => {
            let tableData = data['models'][0].data['finalResReport'][me.sectionName][0];
            me.finalCost = tableData[this.currentEquationField['name']]['finalRes'];
            me.finalEquation = tableData[this.currentEquationField['name']]['finalEquation'];
        });
    }

    setCurrentField(name, fieldData) {
        fieldData['name'] = name;
        let me = this;
        me.currentEquationField = null;
        setTimeout(function () {
            me.currentEquationField = fieldData;
        });
    }

}
