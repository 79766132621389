import {Component, OnInit} from '@angular/core';
import {MainService} from "../../../../services/MainService";
import {AppSettings} from "../../../../conf/appSettings";

import {OrderService} from "../../../../services/OrderService";
import {Events} from "../../../../../framework/providers/events";

@Component({
    selector: 'order-type',
    templateUrl: './order-type.component.html',
    styleUrls: ['./order-type.component.scss'],
})
export class OrderTypeComponent implements OnInit {

    public orderType:string;
    public settings:object;

    constructor(public mainService:MainService, public appSettings:AppSettings, public events:Events, public orderService:OrderService) {
        this.settings = this.appSettings.getSettings('cart');

        this.orderType = this.orderService.getOrderType();

        this.events.subscribe('orderTypeChanged', (params) => {
            this.orderType = params['type']
        });
    }

    ngOnInit() {
    }

    showOrderTypes() {
        this.mainService.showRegisterModal({});
    }

}
