// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-label.select-label {
  max-width: -moz-fit-content;
  max-width: fit-content;
}

ion-footer {
  z-index: 1000;
}

ion-segment {
  background: #fff;
  z-index: 999;
}
ion-segment .new-ticket-btn {
  max-width: 90px;
}

.name-box {
  font-size: 0.7rem;
}

.tickets-row {
  border-top: 1px solid #f8f8f8;
  overflow-x: auto;
  background: #fff;
}
.tickets-row .tickets-btns {
  background: #f8f8f8;
  gap: 1px;
  width: -moz-fit-content;
  width: fit-content;
  height: 41px;
}
.tickets-row .tickets-btns .new-ticket-btn {
  width: 50px;
  text-align: center;
  font-size: larger;
  height: 100%;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--button-a-background);
  color: var(--button-a-text);
}
.tickets-row .tickets-btns div {
  padding: 10px;
  font-size: 14px;
  flex: 0 0 auto;
  background: #fff;
  height: 100%;
}
.tickets-row .tickets-btns div:hover {
  filter: brightness(90%);
  cursor: pointer;
}
.tickets-row .tickets-btns div.active {
  background: #68946c;
  color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/core/pages/cart/components/cart-tickets/cart-tickets.component.scss"],"names":[],"mappings":"AAAA;EACE,2BAAA;EAAA,sBAAA;AACF;;AAEA;EACE,aAAA;AACF;;AAEA;EACE,gBAAA;EACA,YAAA;AACF;AACE;EACE,eAAA;AACJ;;AAIA;EACE,iBAAA;AADF;;AAIA;EACE,6BAAA;EACA,gBAAA;EACA,gBAAA;AADF;AAGE;EACE,mBAAA;EACA,QAAA;EACA,uBAAA;EAAA,kBAAA;EACA,YAAA;AADJ;AAGI;EACE,WAAA;EACA,kBAAA;EACA,iBAAA;EACA,YAAA;EACA,UAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,sCAAA;EACA,2BAAA;AADN;AAII;EACE,aAAA;EACA,eAAA;EACA,cAAA;EACA,gBAAA;EACA,YAAA;AAFN;AAKI;EACE,uBAAA;EACA,eAAA;AAHN;AAMI;EACE,mBAAA;EACA,WAAA;AAJN","sourcesContent":["ion-label.select-label {\n  max-width: fit-content;\n}\n\nion-footer {\n  z-index: 1000;\n}\n\nion-segment {\n  background: #fff;\n  z-index: 999;\n\n  .new-ticket-btn {\n    max-width: 90px;\n  }\n\n}\n\n.name-box {\n  font-size: 0.7rem;\n}\n\n.tickets-row {\n  border-top: 1px solid #f8f8f8;\n  overflow-x: auto;\n  background: #fff;\n\n  .tickets-btns {\n    background: #f8f8f8;\n    gap: 1px;\n    width: fit-content;\n    height: 41px;\n\n    .new-ticket-btn{\n      width: 50px;\n      text-align: center;\n      font-size: larger;\n      height: 100%;\n      padding: 0;\n      display: flex;\n      align-items: center;\n      justify-content: center;\n      background: var(--button-a-background);\n      color: var(--button-a-text);\n    }\n\n    div {\n      padding: 10px;\n      font-size: 14px;\n      flex: 0 0 auto;\n      background: #fff;\n      height: 100%;\n    }\n\n    div:hover{\n      filter: brightness(90%);\n      cursor: pointer;\n    }\n\n    div.active{\n      background: #68946c;\n      color: #fff;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
