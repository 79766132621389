// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bottomTitle {
  position: relative;
  bottom: 10px;
  left: 0;
  right: 0;
  font-size: 0.6rem;
  color: #8a8c8e;
}

.modelContainer {
  display: flex;
}

.modelContainerField {
  display: flex;
}

.modelContainerFieldSide {
  flex-basis: 200px;
  border: 1px solid;
  padding: 4px;
  margin: 3px;
}`, "",{"version":3,"sources":["webpack://./src/app/shaps/components/custom/admin-filter/admin-filter.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,YAAA;EACA,OAAA;EACA,QAAA;EACA,iBAAA;EACA,cAAA;AACF;;AAEA;EACE,aAAA;AACF;;AAEA;EACE,aAAA;AACF;;AAEA;EACE,iBAAA;EACA,iBAAA;EACA,YAAA;EACA,WAAA;AACF","sourcesContent":[".bottomTitle {\n  position: relative;\n  bottom: 10px;\n  left: 0;\n  right: 0;\n  font-size: 0.6rem;\n  color: #8a8c8e;\n}\n\n.modelContainer {\n  display: flex;\n}\n\n.modelContainerField {\n  display: flex;\n}\n\n.modelContainerFieldSide {\n  flex-basis: 200px;\n  border: 1px solid;\n  padding: 4px;\n  margin: 3px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
