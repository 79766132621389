// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-list {
  padding: 0;
  margin: 0;
}

.color-box {
  min-width: 30px;
  min-height: 30px;
  display: inline-block;
  margin: 3px;
  border: 1px solid #dedede;
}

.color-box.active {
  opacity: 0.4;
}

.image-box {
  padding: 0;
}
.image-box ion-item {
  --min-height: 35px;
  --max-height: 35px;
  margin: 0 3px;
}
.image-box ion-thumbnail {
  height: 30px;
  width: 100%;
  margin: 5px;
}
.image-box ion-checkbox {
  margin-top: 5px;
  margin-bottom: 5px;
}`, "",{"version":3,"sources":["webpack://./src/core/pages/item/pages/items-filter/components/filter-accordion/filter-accordion.component.scss"],"names":[],"mappings":"AAAA;EACE,UAAA;EACA,SAAA;AACF;;AAEA;EACE,eAAA;EACA,gBAAA;EACA,qBAAA;EACA,WAAA;EACA,yBAAA;AACF;;AAEA;EACE,YAAA;AACF;;AAEA;EACE,UAAA;AACF;AACE;EACE,kBAAA;EACA,kBAAA;EACA,aAAA;AACJ;AAEE;EACE,YAAA;EACA,WAAA;EACA,WAAA;AAAJ;AAGE;EACE,eAAA;EACA,kBAAA;AADJ","sourcesContent":["ion-list{\n  padding: 0;\n  margin: 0;\n}\n\n.color-box {\n  min-width: 30px;\n  min-height: 30px;\n  display: inline-block;\n  margin: 3px;\n  border: 1px solid #dedede;\n}\n\n.color-box.active {\n  opacity: 0.4;\n}\n\n.image-box {\n  padding: 0;\n\n  ion-item {\n    --min-height: 35px;\n    --max-height: 35px;\n    margin: 0 3px;\n  }\n\n  ion-thumbnail {\n    height: 30px;\n    width: 100%;\n    margin: 5px;\n  }\n\n  ion-checkbox {\n    margin-top: 5px;\n    margin-bottom: 5px;\n  }\n\n  //margin: 3px;\n  //border-color: #dedede;\n  //border-style: solid;\n  //border-top-width: 1px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
