import {Component, Input, OnInit} from '@angular/core';
import {Item} from "../../../../../framework/model/core/items/item";
import {OrderService} from "../../../../services/OrderService";
import {NavController} from "@ionic/angular";
import {ItemProvider} from "../../../item/providers/item-provider/item-provider.service";
import {NavigationExtras} from "@angular/router";
import {AppConfig} from "../../../../conf/main";
import {ModelToolsProvider} from "../../../../../framework/providers/model-tools/model-tools";
import {PropsProvider} from "../../../item/providers/props-provider/props-provider.service";
import {AppSettings} from "../../../../conf/appSettings";
import {Events} from "../../../../../framework/providers/events";

interface CartItemObject {
    itemsLength?: any;
}

@Component({
    selector: 'cart-item',
    templateUrl: './cart-item.component.html',
    styleUrls: ['./cart-item.component.scss'],
})
export class CartItemComponent implements OnInit {
    @Input()
    cartItem: CartItemObject;

    @Input()
    visibleItems: object;

    @Input()
    index: any;

    item: Item;

    @Input()
    ticket: any;

    @Input()
    model: object;

    @Input()
    size: string = 'big';

    @Input()
    cssClass: string = '';

    @Input()
    priceKey: string = 'price';

    @Input()
    innerItem: boolean = false;

    @Input()
    action: string;

    @Input()
    options:object = {};

    @Input()
    positionOptions:object = {};

    disableAddToCartDetails: string;

    @Input()
    imgSize: number = 80;

    isagent: boolean;
    isOpen: boolean = false;

    restMode: boolean = AppConfig.mode == 'rest';

    settings:object;

    constructor(public orderService: OrderService, public itemProvider: ItemProvider, public navCtrl: NavController,
                public modelTools: ModelToolsProvider, public events: Events, public propsProvider: PropsProvider, public appSettings:AppSettings) {
        this.isagent = this.orderService.isagent();
        this.settings = this.appSettings.getSettings('cart');
    }

    ngOnInit() {
        // console.log("CART ITEM", this.model)
        // this.events.subscribe('orderTypeChanged', (params) => {
        //     this.checkOrderType();
        // });
    }

    ngAfterViewInit() {
        if (!this.innerItem) {
            this.cartItem = this.orderService.getCartTicketItem(this.model, this.ticket);
        }
        this.item = this.cartItem['product'];

        // console.log('this.cartItem', this.item, this.cartItem)

        if (this.innerItem) {
            this.imgSize = 50;
        }

        // this.checkOrderType();
    }

    addToCart(amount = 1, popup = false, cartItemEdit = false) {
        // //console.logthis.cartItem)
        this.itemProvider.addToCart(this.item, amount, popup, cartItemEdit, true, this.cartItem['props'],
            this.cartItem['dealData'], this.ticket, this.cartItem['price'], true, this.cartItem['index'], true, null, this.cartItem['actionAmount']);

        let addeditem = this.orderService.getCartTicketItem(this.cartItem['index'], this.ticket);
        if (addeditem)
            this.cartItem = addeditem;

        this.checkRunningProp(amount);
        // //console.log'added cart item', this.cartItem);
        // this.item = this.orderService.addToBasket(null, this.model, 1, null, null, this.cartItem['price'], null, this.item.data.id);
    }

    removeFromCart() {
        this.orderService.removeFromCart(this.ticket, this.cartItem['index'], null, true);
    }


    goTo(page, params) {
        let navigationExtras: NavigationExtras = {
            queryParams: params
        };

        this.navCtrl.navigateForward(page, navigationExtras)
    }

    openItem() {
        this.itemProvider.openItem(this.item, this.cartItem);
    }

    openItemPropsModal() {
        this.itemProvider.openItemPropsModal(this.item, this.cartItem);
    }


    checkOrderType() {
        this.disableAddToCartDetails = null;
        let ordertype = this.orderService.getOrderType();
        if (ordertype == 'delivery' && !this.item.data.shippingable) {
            this.disableAddToCartDetails = 'cantDeliver';
        }
    }

    checkRunningProp(amount) {
        let cartData = this.orderService.getCartData();
        let checkedProps = this.propsProvider.getCheckedProps(this.cartItem['props'])
        // //console.logcartData['actionData'] && cartData['actionData']['colorsType'])
        // //console.log'checkdProps', cartData, this.cartItem['product'])
        if (cartData['actionData'] && cartData['actionData']['colorsType']) {
            let type = cartData['actionData']['type'];
            let colorType = cartData['actionData']['colorsType'].data.key;
            // //console.logcolorType);
            // if(cartData['actionData']['type'] == type)
            // {
            let props = this.cartItem['product'].data.dealprops;
            for (let p in props) {
                if (props[p]['typeKey'] == type && type) {
                    this.propsProvider.initType(checkedProps, props[p])
                    let value = parseInt(cartData['actionData']['types'][type]['current']);
                    let prop = props[p]['props'][0]

                    if (checkedProps[props[p]['id']]['checked'][prop['id']] !== true && checkedProps[props[p]['id']]['checked'][prop['id']] !== false)
                        this.propsProvider.setCheckedProp(checkedProps, props[p], props[p]['props'][0]);

                    let checkedValue = checkedProps[props[p]['id']]['checkedValue'];
                    if (!checkedValue[prop['id']])
                        checkedValue[prop['id']] = '';

                    let res = checkedValue[prop['id']].split(',');

                    if (amount > 0) {
                        res.push(value)

                        if (type == 'runningNo')
                            cartData['actionData']['types'][type]['current']++;
                    } else {
                        res.splice(res.length - 1, 1);
                        if (type == 'runningNo')
                            cartData['actionData']['types'][type]['current']--;
                    }

                    checkedValue[prop['id']] = res.join(',');
                }

                if (props[p]['typeKey'] == colorType && props[p]['typeAction'] == cartData['action']) {
                    for (let pr in props[p]['props']) {
                        // //console.logprops[p]['props'][pr]['gener?alProperty'], cartData['actionData']['color'])
                        if (props[p]['props'][pr]['generalProperty'] == cartData['actionData']['color']) {
                            let prop = props[p]['props'][pr];
                            // //console.log'FOUND!', prop, checkedProps[props[p]['id']]['checked'][prop['id']])
                            this.propsProvider.initType(checkedProps, props[p]);

                            if (checkedProps[props[p]['id']]['checked'][prop['id']] !== true && checkedProps[props[p]['id']]['checked'][prop['id']] !== false)
                                this.propsProvider.setCheckedProp(checkedProps, props[p], prop);
                        }
                    }
                    // //console.log'COLORS TYPE', props[p]);
                }
                // }
            }
        }
        // //console.logcheckedProps)

        this.cartItem['props'] = this.propsProvider.getPropsArr(checkedProps)
        // //console.logthis.cartItem['props'])
    }

    itemActionChecked() {
        // if(!this.cartItem['actionChecked'])
        // {
        //     this.orderService.updateTotalCharge();
        //     return
        // }

        let item = this.cartItem['product'];
        let propsEvent = 'addToCartPrefix_' + Math.floor(Math.random() * Math.floor(1000000));

        this.orderService.setActiveTicket(this.ticket);

        let checkedProps = this.propsProvider.getCheckedProps(this.cartItem['props']);

        this.propsProvider.showPropsModal(item, this.cartItem, checkedProps, propsEvent, null, true);

        this.events.subscribe(propsEvent, (res) => {
            this.addToCartWithProps(item, res)
            this.events.unsubscribe(propsEvent);
        })

        this.events.subscribe(propsEvent + '_closed', (res) => {
            this.addToCartWithProps(item, res)
            this.events.unsubscribe(propsEvent + '_closed');
        })
        // this.orderService.updateTotalCharge();
    }

    addToCartWithProps(item, res = {}) {
        let props = this.cartItem['props'];
        if (res['props']) props = res['props'];
        //console.logres);
        this.itemProvider.addToCart(item, this.cartItem['amount'], false, false, false, props, null, null, null, true, this.cartItem['index'], true, null, this.cartItem['actionAmount'], {'comments': res['comments']});
    }
}
