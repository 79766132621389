// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text-link {
  text-align: center;
  color: #3880ff;
  font-size: 16px;
  font-weight: bold;
}

.text-des {
  padding-top: 10px;
}

.text-item {
  color: #3880ff;
  font-weight: bold;
  padding-top: 10px;
}

.icon-size {
  padding-left: 6px;
}

.item-box {
  padding: 8px;
}`, "",{"version":3,"sources":["webpack://./src/core/pages/technical-literature/components/book-list/book-list.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,cAAA;EACA,eAAA;EACA,iBAAA;AACF;;AAEA;EACE,iBAAA;AACF;;AAEA;EACE,cAAA;EACA,iBAAA;EACA,iBAAA;AACF;;AAEA;EACE,iBAAA;AACF;;AAEA;EACE,YAAA;AACF","sourcesContent":[".text-link {\n  text-align: center;\n  color: #3880ff;\n  font-size: 16px;\n  font-weight: bold;\n}\n\n.text-des{\n  padding-top: 10px;\n}\n\n.text-item{\n  color: #3880ff;\n  font-weight: bold;\n  padding-top: 10px;\n}\n\n.icon-size{\n  padding-left: 6px;\n}\n\n.item-box{\n  padding: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
