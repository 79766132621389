export class Panel {
    data = {
        "id": null,
        "title": null,
        "page": null,
        "size": null,
        "sizeMd": null,
        "sizeLg": null,
        "objectId": null,
        "background": null,
        "userType": null,
        "images": null,
        "type": null,
        "appStyle": null,
        "maxHeight": null,
        "horiz": null,
        "textColor": null,
        "bgColor": null,
        "padding": null,
        "margin": null,
        "radius": null,
        "options": null,
    };

    constructor(public data1: any) {
        this.data = data1;
    }

    //shortHeaders = [];
    prefix = "panel";
    title = "Panels";
    dataFields = {
        id: {'title': 'ID', 'value': null, 'type': 'number', 'readonly': true},
        title: {'title': 'username', 'value': null, 'type': 'text'},
        page: {'title': 'page', 'value': null, 'type': 'text'},
        size: {'title': 'size', 'value': null, 'type': 'text'},
        background: {'title': 'background', 'value': null, 'type': 'boolean'},
        objectId: {'title': 'objectId', 'value': null, 'type': 'text'},
    }
}
