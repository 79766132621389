import {Component, Input, OnInit} from '@angular/core';
import {AppUser} from "../../../../../framework/model/core/appuser/appuser";
import {Address} from "../../../../../framework/model/core/address/address";
import {AddressDetails} from "../../../../../framework/model/core/address/addressDetails";
import {OrderDetails} from "../../../../../framework/model/core/order/orderDetails";
import {ModalController} from "@ionic/angular";
import {ModelProvider} from "../../../../../framework/providers/modelProvider/ModelProvider";
import {AuthService} from "../../../../../framework/providers/auth-service/auth-service";
import {OrderService} from "../../../../services/OrderService";
import {ResObject} from "../../../../../app/global-module/global-module.module";
import {ModelToolsProvider} from "../../../../../framework/providers/model-tools/model-tools";
import {AppSettings} from "../../../../conf/appSettings";
import {Note} from "../../../../../framework/model/general/note/note";
import {Item} from "../../../../../framework/model/core/items/item";
import {Events} from "../../../../../framework/providers/events";

@Component({
    selector: 'app-form',
    templateUrl: './app-form.component.html',
    styleUrls: ['./app-form.component.scss'],
})
export class AppFormComponent implements OnInit {

    public appuser: AppUser;
    public fromAddress: Address;
    public toAddress: Address;
    public fromAddressDetails: AddressDetails;
    public toAddressDetails: AddressDetails;
    public orderDetails: OrderDetails;

    public item: Item;

    public appuserFields: Array<string> = [];
    public orderDetailsFields: Array<string> = [];

    public corePages: object;
    public pages: Array<any>;
    public activePage: object;
    public pagesData: object;
    public notes: object;

    public settings: object;

    public prefix: string = 'appForms_' + +Math.floor(Math.random() * Math.floor(1000000));

    @Input()
    public keys: Array<any> = ['user', 'company', 'from', 'fromDetails', 'to', 'toDetails', 'orderDetails'];

    @Input()
    public view: string = 'slides';

    @Input()
    public listModels: boolean = false;

    @Input()
    public readonly: boolean = false;

    @Input()
    public addFilters: object = {};

    @Input()
    public listFilters: object = {
        'prefix': 'getMovingDetails',
        'filters': null,
    };

    @Input()
    public options = {};


    constructor(public modalController: ModalController, public modelProvider: ModelProvider,
                public modelTools: ModelToolsProvider, public appSettings: AppSettings,
                public events: Events, private auth: AuthService, public orderService: OrderService) {
    }

    ngOnInit() {
        this.initData();

        if (this.listModels) {
            this.getModels();
            return;
        }
        this.start()
    }

    start() {
        this.setOrderDetailsFields(this.activePage);
        this.setPages();

        this.events.subscribe(this.options['prefix'] + '_checkValid', (params) => {
            this.options['valid'] = true;
            for (let k in this.pages) {
                this.events.publish(this.pages[k]['options']['prefix']);
                this.options['valid'] = this.options['valid'] && this.pages[k]['options']['valid'];
            }
        })

        this.events.subscribe(this.options['prefix'] + '_updateForms', (params) => {
            this.updateForms(params['params'], params['filters']);
        })

        this.events.subscribe('fromStateModelScroll', (params) => {
            this.events.publish('fromCityPrefix' + '_updateModelScroll', {'state': params['data']});
        });

        this.events.subscribe('toStateModelScroll', (params) => {
            this.events.publish('toCityPrefix' + '_updateModelScroll', {'state': params['data']});
        });

        this.events.subscribe('typeModelScroll', (params) => {
            this.events.publish('subTypePrefix' + '_updateModelScroll', {'type': params['data']});
            this.checkOtherType('bisType')
        });
        this.checkOtherType('bisType');

        this.events.subscribe('subTypeModelScroll', (params) => {
            this.checkOtherType('bisSubType')
        });
    }

    initData() {
        this.appuser = new AppUser({
            // 'name': 'בדיקה',
            // 'companyName': 'בדיקה',
            // 'companyFileNumber': 'בדיקה',
            // 'cellphone': '123',
            // 'username': 'test19305@onebutton.co.il',
            // 'password': '121212',
            // 'confirmPassword': '121212'
        });

        this.fromAddress = new Address({
            // 'cityId': 3, 'street': 'שש', 'homeNumber': 4
        });
        this.toAddress = new Address({});
        this.fromAddressDetails = new AddressDetails({});
        this.toAddressDetails = new AddressDetails({});
        this.orderDetails = new OrderDetails({});

        this.item = new Item({
            // 'name':'test11',
            // 'description':'test12112',
            // 'sectionId':null,
            // 'price':'25'
        });

        if (!this.options) this.options = {};
        this.options['prefix'] = this.prefix;

        this.settings = this.appSettings.getSettings('forms')

        if (this.keys.indexOf('user') == -1)
            this.appuser = this.orderService.getAppUser();
        //
        // this.events.subscribe('appuserChanged', (params) => {
        //     this.appuser = this.orderService.getAppUser();
        // });

        if (this.settings['user'])
            this.appuserFields = this.copy(this.settings['user']['fields']);

        if (this.options['userFields'])
            this.appuserFields = this.options['userFields'];

        this.corePages = {
            'user': {
                'key': 'user',
                'title': 'userDetails',
                'cls': AppUser,
                'model': this.appuser,
                'fields': this.appuserFields,
                'options': {},
                'errors': {'userExist': null, 'badpassword': null},
                'validCheck': this.checkPassword,
                'callback': this.setAppUser,
                'createCallback': this.options['userCreateCallback'] ? this.options['userCreateCallback'] : this.login,
                'fieldsOptions': {},
                // 'swipToAfterCreate': 2,
                'visible': true,
                'this': this,
            },
            'company': {
                'key': 'company',
                'title': 'companyDetails',
                'cls': AppUser,
                'model': this.appuser,
                'fields': this.settings['company'] ? this.settings['company']['fields'] : [],
                'errors': {'invalidDiscount': null},
                'validCheck': this.checkValidCompany,
                'callback': this.setAppUser,
                'createCallback': this.login,
                'options': {},
                'fieldsOptions': {
                    'bisType': {'options': {'changePrefix': 'typeModelScroll', 'hideAddOther': true}},
                    'bisSubType': {
                        'options': {
                            'changePrefix': 'subTypeModelScroll',
                            'tablePrefix': 'subTypePrefix',
                            'hideAddOther': true
                        }
                    },
                    'bisTypeOther': {'hidden': true}
                },
                'visible': true,
                'this': this,
            },
            'from': {
                'key': 'from',
                'title': this.settings['from'] ? this.settings['from']['title'] : '',
                'titleTemplate': 'map-icon',
                'cls': Address,
                'model': this.fromAddress,
                'fields': this.settings['from'] ? this.settings['from']['fields'] : [],
                'callback': this.setAddress,
                'options': {},
                'fieldsOptions': {
                    'state': {'options': {'changePrefix': 'fromStateModelScroll', 'hideAddOther': true}},
                    'cityId': {'options': {'tablePrefix': 'fromCityPrefix', 'hideAddOther': true}}
                },
                'visible': true,
                'this': this,
            },
            'fromDetails': {
                'key': 'fromDetails',
                'title': 'whereFrom',
                'cls': AddressDetails,
                'model': this.fromAddressDetails,
                'fields': ['placeType', 'elevator', 'truckAccess', 'crane', 'longCarry', 'coi', 'comments'],
                'callback': this.setAddressDetails,
                'options': {},
                'visible': true,
                'this': this,
            },
            'to': {
                'key': 'to',
                'title': 'whereTo',
                'titleTemplate': 'map-icon',
                'cls': Address,
                'model': this.toAddress,
                'fields': this.settings['to'] ? this.settings['to']['fields'] : [],
                'callback': this.setAddress,
                'options': {},
                'fieldsOptions': {
                    'state': {'options': {'changePrefix': 'toStateModelScroll', 'hideAddOther': true}},
                    'cityId': {'options': {'tablePrefix': 'toCityPrefix', 'hideAddOther': true}}
                },
                'visible': true,
                'this': this,
            },

            'toDetails': {
                'key': 'toDetails',
                'title': 'whereTo',
                'cls': AddressDetails,
                'model': this.toAddressDetails,
                'fields': ['placeType', 'elevator', 'truckAccess', 'crane', 'longCarry', 'coi', 'comments'],
                'callback': this.setAddressDetails,
                'options': {},
                'visible': true,
                'this': this,
            },

            'orderDetails': {
                'key': 'orderDetails',
                'title': 'deliveryDetails',
                'cls': OrderDetails,
                'model': this.orderDetails,
                'fields': this.orderDetailsFields,
                'callback': this.createOrderDetails,
                'createCallback': this.setOrderDetails,
                'changeEvents': this.setOrderDetailsEvents,
                'options': {},
                'prefix': 'orderDetailsForm',
                'visible': true,
                'this': this,
            },

            'profileImage': {
                'key': 'profileImage',
                'title': 'profileImage',
                'template': 'profile-image',
                // 'cls': OrderDetails,
                // 'model': this.orderDetails,
                // 'fields': this.orderDetailsFields,
                'callback': this.swipNext,
                // 'createCallback': this.swipNext,
                // 'changeEvents': this.setOrderDetailsEvents,
                'options': {},
                'visible': true,
                'this': this,
            },
            'payment': {
                'key': 'payment',
                'title': 'licensePayment',
                'template': 'app-payment',
                // 'cls': OrderDetails,
                // 'model': this.orderDetails,
                // 'fields': this.orderDetailsFields,
                'callback': this.swipNext,
                'skip': true,
                // 'createCallback': this.swipNext,
                'changeEvents': this.setPaymentEvent,
                'options': {'prefix': 'newpaymentAppuser'},
                'visible': true,
                'hideNext': true,
                'this': this,
            },

            'new-item': {
                'key': 'new-item',
                'cls': Item,
                'model': this.item,
                'fields': ['name', 'price', 'description', 'sectionId', 'itemMaker'],
                'callback': this.saveModel,
                'customPost': 'createItem',
                'options': {},
                'visible': true,
                'this': this,
            },
            'item-image': {
                'key': 'item-image',
                'title': 'image',
                'template': 'add-model-image',
                'callback': this.swipNext,
                'model': this.item,
                // 'createCallback': this.swipNext,
                // 'changeEvents': this.setOrderDetailsEvents,
                'options': {},
                'visible': true,
                'this': this,
            },
            'new-item-note':
                {
                    'key': 'new-item-note',
                    'title': '',
                    'model': null,
                    'callback': this.swipNext,
                    'options': {},
                    'visible': true,
                    'this': this,
                }

        };

        if (this.settings['note']) {
            this.corePages['note'] = {
                'key': 'note',
                'title': this.settings['note']['title'],
                'model': this.settings['note']['modelKey'] ? this.corePages[this.settings['note']['modelKey']]['model'] : null,
                'fields': this.settings['note']['fields'],
                'callback': this.settings['note']['modelKey'] ? this.saveModel : this.swipNext,
                'createCallback': this.settings['note']['modelKey'] ? null : this.swipNext,
                // 'changeEvents': this.setOrderDetailsEvents,
                'options': {},
                'visible': true,
                'this': this,
            }
        }

        //console.logthis.corePages)

    }

    getModels() {
        this.appuser = this.orderService.getAppUser();

        let filters = this.listFilters['filters'];
        if (!filters) {
            filters = {
                'appuser': this.appuser.data.id
            }
        }

        filters['setPrefix'] = true;
        this.modelProvider.custom(this.listFilters['prefix'], filters).then((res) => {
            for (let k in this.keys) {
                if (res[this.keys[k]]) {
                    this.corePages[this.keys[k]]['model'].data = res[this.keys[k]];
                }
            }

            let me = this;
            setTimeout(function () {
                me.start();
            })
        });
    }


    setPages() {
        this.pagesData = {'active': 0};
        this.pages = [];
        for (let k in this.keys) {
            this.corePages[this.keys[k]]['visible'] = this.view == 'slides';
            let page = this.corePages[this.keys[k]]
            // if(this.keys[k] == 'note')
            //     this.setNoteData(page);
            this.pages.push(page);
        }

        this.getNotes();

        this.setActivePage();

    }

    getNotes() {
        this.modelProvider.custom('getFormNotes', {'setPrefix': true}).then((res: any) => {
            this.notes = res;
            for (let k in res) {
                // //console.logk, this.corePages[k])
                if (!this.corePages[k])
                    continue
                this.corePages[k]['note'] = new Note(res[k]);
            }

            // //console.logthis.corePages);
        })
    }

    setPaymentEvent(activePage) {
        let me = activePage['this'];
        me.events.subscribe(activePage['options']['prefix'], (res) => {
            activePage.callback(activePage);
            me.events.unsubscribe(activePage['options']['prefix'])
        })
    }

    // setNoteData(page)
    // {
    //     this.modelProvider.list('note', {'key':this.settings['note']['noteKey']}).then((res)=>{
    //         page['note'] = res['models'][0];
    //     })
    // }

    setAppUser(activePage) {
        let me = activePage['this'];

        let model = activePage['model'];

        activePage['cren'] = {'email': model.data.username, 'password': model.data['password']}
        me.modelProvider.custom('checkUserExsists', {
            'username': model.data.username,
            'loading': true,
            'setPrefix': true
        }).then((res) => {
                if (res > 0 && !model.data.id) {
                    me.setError('userExist', 'theUsernameIsExistsAlready');
                    return;
                } else {
                    me.setError('userExist', null);

                    if (me.settings['user'].fields.indexOf('clientType') != -1) {
                        if (model.data.clientType == 'private') {
                            model.data.companyName = model.data.name;
                            me.pages.splice(1, 1);
                        }
                    }

                    if ((model.data.clientType == 'business' || !model.data.clientType) && activePage['key'] != 'company' && me.keys.includes('company')) {
                        me.swipTo(1, true);
                        return;
                    }

                    //console.logme.options);
                    if (me.options['addParams'] && me.options['addParams'][activePage['key']]) {
                        for (let p in me.options['addParams'][activePage['key']]) {
                            model.data[p] = me.options['addParams'][activePage['key']][p];
                        }
                    }


                    setTimeout(function () {

                        me.saveModel(activePage, true);

                        me.appuserFields.length = 0;
                        let fileds = ['name', 'cellphone'];
                        if (model.data.clientType)
                            fileds.push('clientType');

                        for (let k in fileds)
                            me.appuserFields.push(fileds[k]);

                        me.corePages['user']['fields'] = me.appuserFields;

                        // if(activePage['key'] == 'company')
                        // {
                        //     me.activePage['hideBack'] = true;
                        // //     activePage['fieldsOptions']['bisType']['options']['readonly'] = true;
                        // //     activePage['fieldsOptions']['bisSubType']['options']['readonly'] = true;
                        // }
                    })

                }
            }
        );
    }

    setAddress(activePage) {
        let model = activePage['model'];
        let me = activePage['this'];

        model.data.company = me.appuser.data.companyId;

        me.saveModel(activePage);
    }

    setAddressDetails(activePage) {
        let model = activePage['model'];
        let me = activePage['this'];

        let address = activePage['key'] == 'fromDetails' ? me.fromAddress : me.toAddress;

        model.data.address = address.data.id;
        me.saveModel(activePage);
    }

    createOrderDetails(activePage) {
        let model = activePage['model'];
        let me = activePage['this'];
        model.data.company = me.appuser.data.companyId;
        me.saveModel(activePage);
    }

    setOrderDetails(activePage) {
        let model = activePage['model'];
        let me = activePage['this'];
        me.orderService.setOrderDetails(model);
        me.swipTo(1);
    }

    setOrderDetailsFields(activePage = null) {
        this.orderDetailsFields.length = 0;
        let fields = ['pickupDate', 'flexibleDays', 'directOrder', 'pickupStorageDate', 'needStorage', 'needInsurance'];

        if (this.orderDetails.data.directOrder)
            fields = ['pickupDate', 'flexibleDays', 'directOrder', 'needStorage', 'needInsurance'];

        let insuranceFileds = [];

        let model;
        if (activePage)
            model = activePage['model'];
        else
            model = this.orderDetails;

        if (model) {
            if (model.data.needInsurance)
                insuranceFileds.push('insurance');
            if (model.data.needInsurance && model.data.insurance) {
                if (model.data.insurance == 'extended') {
                    insuranceFileds.push('insuranceValue')
                }
                if (model.data.insurance == 'extCompany') {
                    insuranceFileds.push('insuranceCompanyName', 'insuranceCompanyFile')
                }

            }
        }

        for (let k in fields) {
            this.orderDetailsFields.push(fields[k]);
        }

        for (let k in insuranceFileds) {
            this.orderDetailsFields.push(insuranceFileds[k]);
        }
    }

    setOrderDetailsEvents(activePage) {
        activePage['this'].events.subscribe(activePage['options']['prefix'] + '_directOrder', (params) => {
            activePage['this'].setOrderDetailsFields(activePage)
        });

        activePage['this'].events.subscribe(activePage['options']['prefix'] + '_insurance', (params) => {
            activePage['this'].setOrderDetailsFields(activePage)
        });

        activePage['this'].events.subscribe(activePage['options']['prefix'] + '_needInsurance', (params) => {
            activePage['this'].setOrderDetailsFields(activePage)
        });


    }

    checkPassword(activePage) {
        let appuser = activePage['model'];

        appuser.data['password' + '_invalid'] = appuser.data['password'] != appuser.data['confirmPassword'];
        appuser.data['confirmPassword' + '_invalid'] = appuser.data['password' + '_invalid'];

        if (appuser.data['password' + '_invalid']) return;

        let badpassword = appuser.data['password'] ? appuser.data['password'].length < 6 : false;
        appuser.data['password' + '_invalid'] = badpassword;
        appuser.data['confirmPassword' + '_invalid'] = badpassword;
        if (badpassword)
            activePage['this'].setError('badpassword', 'password6CharactersAtLeast');
        else
            activePage['this'].setError('badpassword', null);
    }

    checkValidCompany(activePage) {
        let appuser = activePage['model'];
        let invalidDiscount = appuser.data['clientsDiscount'] < 10;
        appuser.data['clientsDiscount' + '_invalid'] = invalidDiscount;
        if (invalidDiscount)
            activePage['this'].setError('invalidDiscount', '10minimumForDiscount');
    }

    checkOtherType(key) {
        let me = this;
        setTimeout(function () {
            if (!me.appuser.data[key + 'Data']) return;
            let value = me.appuser.data[key + 'Data'].data.name == 'אחר';
            me.corePages['company']['fieldsOptions']['bisTypeOther']['hidden'] = !value;
            me.corePages['company']['fieldsOptions']['bisSubType']['hidden'] = value && key == 'bisType';
        })
    }

    saveModel(activePage, hideBackButton = false) {
        let model = activePage['model'];
        let me = activePage['this'];

        // //console.logmodel)

        if (model.data.id)
            me.update(activePage, hideBackButton);
        else
            me.create(activePage, hideBackButton);
    }

    create(activePage, hideBackButton) {
        let model = activePage['model'];
        let me = activePage['this'];

        // //console.log'will crate', model)
        //

        let filters = {'loading': true};

        for (let k in this.addFilters) {
            filters[k] = this.addFilters[k];
        }

        if (!activePage['customPost']) {
            me.modelProvider.create(model, filters).then((res: ResObject) => {
                me.createCallback(activePage, hideBackButton, res);
            });
        } else {
            filters['setPrefix'] = true;
            me.modelProvider.customPost(activePage['customPost'], model.data, filters).then((res: ResObject) => {
                activePage['model'].data = new activePage['cls'](res['data']).data;
                me.createCallback(activePage, hideBackButton, res);
            });
        }
    }

    createCallback(activePage, hideBackButton, res) {
        let me = activePage['this'];

        //console.log'create', activePage['model']);
        if (me.options['externalCallback']) {
            me.options['externalCallback'](activePage)
        } else {
            if (activePage['createCallback']) activePage['createCallback'](activePage);
        }

        if (activePage['swipToAfterCreate'])
            me.swipTo(activePage['swipToAfterCreate'], true);
        else
            me.swipTo(1);

        me.activePage['hideBack'] = hideBackButton;
    }

    update(activePage, hideBackButton) {
        let model = activePage['model'];
        let me = activePage['this'];
        //console.log'will update', model)


        if (!activePage['customPost']) {
            me.modelProvider.update(model, {'loading': true}).then((res: ResObject) => {
                me.updateCallBack(activePage, hideBackButton, res);
            });
        } else {
            me.modelProvider.customPost(activePage['customPost'], model.data, {
                'loading': true,
                'setPrefix': true
            }).then((res: ResObject) => {
                activePage['model'].data = new activePage['cls'](res['data']).data;
                this.updateCallBack(activePage, hideBackButton, res);
            });
        }

    }

    updateCallBack(activePage, hideBackButton, res) {
        let me = activePage['this'];

        //console.log'upadte', res);
        if (activePage['createCallback']) activePage['createCallback'](activePage);

        if (activePage['swipToAfterCreate'])
            me.swipTo(activePage['swipToAfterCreate'], true);
        else
            me.swipTo(1);

        me.activePage['hideBack'] = hideBackButton;
    }

    updateForms(params, filters = null) {
        let data = {}
        for (let k in this.pages) {
            data[this.pages[k]['key']] = this.pages[k]['model'].data

            for (let p in params) {
                data[this.pages[k]['key']][p] = params[p];
            }
        }

        if (!filters)
            filters = {'appuser': this.appuser.data.id};

        this.modelProvider.customPost('setMovingDetails', data, filters).then((res) => {
            this.options['data'] = res['data'];
            this.events.publish(this.options['prefix'] + '_formsUpdated')
        })

        // //console.log'data to update:', data);
    }

    proceed() {
        if (this.activePage['validCheck']) {
            this.activePage['validCheck'](this.activePage);
        }

        this.events.publish(this.activePage['options']['prefix']);
        if (!this.activePage['options']['valid'] && !this.activePage['template'] && this.activePage['model']) {
            return false;
        }

        if (this.activePage['callback']) {
            this.activePage['callback'](this.activePage);
        }
    }

    swipNext(activePage) {
        let me = activePage['this'];
        me.swipTo(1);
    }

    swipTo(k, toK = false) {
        if (toK) {
            this.pagesData['active'] = k;
            this.setActivePage();
            return
        }

        if (this.pagesData['active'] == 0 && k < 0 && this.pages.length > 1)
            return;

        if (this.pagesData['active'] == this.pages.length - 1 && k > 0) {
            this.close(true);
            return;
        }

        this.pagesData['active'] += k;
        this.setActivePage()

    }

    setActivePage(showvisisble = false) {
        this.activePage = this.pages[this.pagesData['active']];

        this.setChangeEvents(this.activePage)
    }

    setVisiblePage(page) {
        page['visible'] = !page['visible'];
        this.setChangeEvents(page)
    }

    setChangeEvents(activePage) {
        setTimeout(function () {
            if (activePage['changeEventsSets']) return;
            if (activePage['changeEvents']) {
                activePage['changeEvents'](activePage);
                activePage['changeEventsSets'] = true;
            }
        })
    }

    setError(key, text) {
        if (!this.activePage['errors']) return;
        this.activePage['errors'][key] = text;
    }

    close(finishForms = false) {
        // //console.log'CLOSEE!!')
        if (this.options['finishForm'] !== false) {
            if (finishForms && this.settings['finishForm']) {
                let key = this.settings['finishForm']['noteKey']
                // this.modelProvider.list('note', {'key':this.settings['finishForm']['noteKey']}).then((res)=>{
                //     if(res['models'].length)
                //     {
                //         this.modelTools.textAlert(null, res['models'][0].data.txt);
                //     }
                // })
                if (this.notes[key]) {
                    this.modelTools.textAlert(null, this.notes[key].txt);
                }
            }
            this.modelTools.goTo('main');
        }
        this.closeModal()
    }

    closeModal() {
        this.modalController.dismiss();
    }


    login(activePage) {
        let appuser = activePage['model'];
        //console.logappuser)
        let cren = activePage['cren'];
        let me = activePage['this'];
        // this.events.publish('setBackLoadingOn');
        cren['username'] = cren['email'];
        me.auth.login(cren)
            .subscribe(allowed => {
                    // //console.log"####", allowed)
                    // this.events.publish('setBackLoadingOff');
                    if (allowed) {
                        ////console.log'user login in publish event!!');
                        // this.events.publish('user:login');
                        // this.navCtrl.navigateForward('main');
                    } else {

                        ////console.log'false!')
                        //this.loading.dismiss();
                        // this.showError("Wrong credentials has been provided.");
                    }

                    // this.initAppUser();
                },
                error => {

                    // me.closeModal();
                    // this.loading.dismiss();
                    // this.showError(error);
                });
    }

    copy(data) {
        return JSON.parse(JSON.stringify(data))
    }
}
