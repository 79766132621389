// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logo-size {
  max-height: 80px;
  width: auto;
  margin: 5px;
}

.tabs-content {
  padding: 5px;
}
.tabs-content .tabs-header {
  padding: 0 5px;
}
.tabs-content .tabs-header ion-button {
  margin: 0 2px;
  height: 28px;
  --border-radius: 4px 4px 0 0;
  --border-color: var(--main-top);
  --border-width: 1px 1px 0 1px;
  --border-style: solid;
  --box-shadow: 0;
  --background: none;
  --color: var(--text-b);
  --background-activated: var(--main-top);
}
.tabs-content .tabs-header ion-button.active {
  --background: var(--main-top);
  --color: var(--main-top-text);
}
.tabs-content .tabs-body {
  border: 1px solid var(--main-top);
  border-radius: 4px;
  background: #fff;
  padding: 10px;
  box-shadow: 0px 2px 3px #ccc;
}`, "",{"version":3,"sources":["webpack://./src/core/pages/about/about.page.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,WAAA;EACA,WAAA;AACF;;AAEA;EACE,YAAA;AACF;AACE;EACE,cAAA;AACJ;AACI;EACE,aAAA;EACA,YAAA;EAEA,4BAAA;EACA,+BAAA;EACA,6BAAA;EACA,qBAAA;EACA,eAAA;EAGA,kBAAA;EACA,sBAAA;EAEA,uCAAA;AAHN;AAMI;EACE,6BAAA;EACA,6BAAA;AAJN;AAQE;EACE,iCAAA;EACA,kBAAA;EACA,gBAAA;EACA,aAAA;EACA,4BAAA;AANJ","sourcesContent":[".logo-size {\n  max-height: 80px;\n  width: auto;\n  margin: 5px;\n}\n\n.tabs-content {\n  padding: 5px;\n\n  .tabs-header {\n    padding: 0 5px;\n\n    ion-button {\n      margin: 0 2px;\n      height: 28px;\n\n      --border-radius: 4px 4px 0 0;\n      --border-color: var(--main-top);\n      --border-width: 1px 1px 0 1px;\n      --border-style: solid;\n      --box-shadow: 0;\n\n      //--background: var(--button-b-text);\n      --background: none;\n      --color: var(--text-b);\n\n      --background-activated: var(--main-top);\n    }\n\n    ion-button.active {\n      --background: var(--main-top);\n      --color: var(--main-top-text);\n    }\n  }\n\n  .tabs-body {\n    border: 1px solid var(--main-top);\n    border-radius: 4px;\n    background: #fff;\n    padding: 10px;\n    box-shadow: 0px 2px 3px #ccc;\n  }\n}\n\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
