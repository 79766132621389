import {Component, OnInit} from '@angular/core';
import {CartModel} from "./cartModel";

export class Item {
    data = {
        id: null,
        name: null,
        code: null,
        model: null,
        price: null,
        unit_price: 15,
        dealPropertyTypes: null,
        dealprops: null,
        props: null,
        sectionId: null,
        packageUnits: null,
        groups: null,
        dealItem: null,
        images: null,
        comments: null,
        description: '',
        spec: '',
        topItemPropertyData: null,
        itemPropertyData: null,
        sale: null,
        isTopItem: null,
        itemMaker: null,
        makerName: null,
        makerImage: null,
        makerImages: null,
        stockData: null,
        stock: null,
        outofstock: null,
        favorite: null,
        basePrice: null,
        baseDistributorPrice: null,
        oldprice: null,
        shippingable: null,
        currencyCode: [],
        link: null,
        autoProps: null,
        propsLen: null,
        exLink: null,
        background: null,
        posts: null,
        advs: null,
        barcode: null,
        type: null,
        created: null,
        view_type: null,
        main_button_title: null,
        main_button_icon: null,
        hide_code: null,
        sectionData: {
            id: null,
            name: null,
        },
        rate_data: {
            rate_overall: null,
            rate_avg: null,
        },

    };

    constructor(public data1: any, coupons=[], currency_data = []) {
        this.data = data1;

        let cart_model = new CartModel(this, coupons, null, currency_data);
        this.agentToolData = this.cart_model = cart_model;

        this.data.propsLen = this.data.dealprops ? this.data.dealprops.length : 0;
        // this.getPrice();
    }

    // getPrice() {
    //     let price = this.data.price;
    //     let oldprice = 0;
    //     // let res = {'price': price};
    //     if (this.data.sale) {
    //         if (!this.data.sale['amountToBuy'] || this.data.sale['bonus']) {
    //             oldprice = price;
    //             price = this.data.sale['priceAfterDiscount'];
    //         }
    //     }
    //
    //     this.data.price = price;
    //     this.data.oldprice = oldprice;
    //
    //     // return res;
    // }

    //shortHeaders = [];
    prefix = 'item';
    title = "item";
    dataFields = {
        id: {'title': 'ID', 'value': null, 'type': 'number', 'readonly': true},
        created: {'title': 'Created', 'value': null, 'type': 'date', 'readonly': true},
        updated: {'title': 'Updated', 'value': null, 'type': 'date', 'readonly': true},
        'name': {'title': 'name', 'value': null, 'type': 'text', 'required': true},
        'code': {'title': 'price', 'value': null, 'type': 'text', 'nis': true, 'maxlength': 5},
        'price': {'title': 'price', 'value': null, 'type': 'float', 'nis': true, 'maxlength': 5},
        "type": {
            'showDefault': true, 'value': null,
            'filters': {'contentTypeName': 'item', 'fieldName': 'type'},
            'displayField': 'typeName', 'type': 'modelscroll',
            'modelscroll': 'ModelStatus', 'keyField': 'id', 'valueField': 'name',
            //'multipleSelect':true,
        },
        "sectionId": {
            'title': 'category',
            'showDefault': true, 'value': null,
            'filters': {'ordering': 'order'},
            'displayField': 'sectionName', 'type': 'modelscroll',
            'modelscroll': 'Section', 'keyField': 'id', 'valueField': 'name',
            //'multipleSelect':true,
        },
        "itemMaker": {
            'title': 'maker',
            'showDefault': true, 'value': null,
            'filters': {'ordering': 'id'},
            'displayField': 'makerName', 'type': 'modelscroll',
            'modelscroll': 'ItemMaker', 'keyField': 'id', 'valueField': 'name',
            //'multipleSelect':true,
        },
        'handleSide': {
            'title': 'kitchen',
            'showDefault': true, 'value': null,
            'displayField': 'handleSide', 'type': 'optionsscroll', 'optionsscroll': 'HANDLE_SIDE',
        },
        'description': {'title': 'description', 'value': null, 'type': 'textarea', 'maxlength': 3000},

        'basePrice': {'title': 'basePrice', 'value': null, 'type': 'number'},
        'baseDistributorPrice': {'title': 'distributorPrice', 'value': null, 'type': 'number'},
        'comments': {'title': 'comments', 'value': null, 'type': 'textarea'},



    }


    cart_item = {'amount':0};
    agentToolData = {};

    bounds_data = {};

    cart_model:CartModel = null;
    cart_tickets_models = {
        'tickets': {},
        'count': 0,
    };

    setBounds(el, prefix, key = null){
        let bounds = el.nativeElement;
        let height = bounds.offsetHeight;
        if(!height) return
        this['bounds_data'][prefix] = {
            'item_height': height,
            'item_top': bounds.offsetTop,
            // 'el': el,
        }
    }
}
