import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'add-model-image',
  templateUrl: './add-model-image.component.html',
  styleUrls: ['./add-model-image.component.scss'],
})
export class AddModelImageComponent implements OnInit {

  @Input()
  public model:any;

  @Input()
  public showButton:boolean = true;

  @Input()
  public buttonText:string;

  constructor() { }

  ngOnInit() {}

}
