// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-select {
  max-width: 100%;
  --padding-bottom: 0;
  --padding-top: 0;
}

ion-item {
  --min-height: 30px;
}`, "",{"version":3,"sources":["webpack://./src/framework/components/field/modeloptionscroll/modeloptionscroll.component.scss"],"names":[],"mappings":"AAAA;EAEE,eAAA;EACA,mBAAA;EACA,gBAAA;AAAF;;AAGA;EAEE,kBAAA;AADF","sourcesContent":["ion-select\n{\n  max-width:100%;\n  --padding-bottom: 0;\n  --padding-top: 0;\n}\n\nion-item\n{\n  --min-height: 30px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
