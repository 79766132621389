// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.box {
  height: 40px;
  position: relative;
  margin: auto;
}

.img-box {
  margin: 4px;
}`, "",{"version":3,"sources":["webpack://./src/core/pages/makers/pages/maker/maker.page.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,kBAAA;EACA,YAAA;AACF;;AAEA;EACE,WAAA;AACF","sourcesContent":[".box {\n  height: 40px;\n  position: relative;\n  margin: auto;\n}\n\n.img-box{\n  margin: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
