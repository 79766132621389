import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {Routes, RouterModule} from '@angular/router';

import {IonicModule} from '@ionic/angular';

import {StockPage} from './stock.page';
import {GlobalModule} from "../../../../../app/global-module/global-module.module";

const routes: Routes = [
    {
        path: '',
        component: StockPage
    }
];

@NgModule({
    imports: [
        GlobalModule,
        CommonModule,
        FormsModule,
        IonicModule,
        RouterModule.forChild(routes)
    ],
    declarations: [StockPage]
})
export class StockPageModule {
}
