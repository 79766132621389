import { Component, Input, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'app-countdown-timer',
  template: `
    <div *ngIf="countdownValue" class="fit-content">
      <div class="fit-content">
        <span [hidden]="!countdownValue.days"> {{countdownValue.days }} {{'days' | translate}}, </span>
        {{ countdownValue.hours | number: '2.0-0'}}:{{ countdownValue.minutes | number: '2.0-0' }}:{{ countdownValue.seconds| number: '2.0-0' }}</div>
    </div>
  `,
  styles: []
})
export class CountdownTimerComponent implements OnInit, OnDestroy {
  @Input() endDate: Date | string; // Date parameter

  countdownValue: any;
  interval: any;

  ngOnInit() {
    this.endDate = new Date(this.endDate); // Convert input to Date object

    this.calculateCountdown(); // Initial calculation

    // Update countdown every second
    this.interval = setInterval(() => {
      this.calculateCountdown();
    }, 1000);
  }

  calculateCountdown() {
    const now = new Date().getTime();
    const distance = new Date(this.endDate).getTime() - now;

    if (distance > 0) {
      this.countdownValue = {
        days: Math.floor(distance / (1000 * 60 * 60 * 24)),
        hours: Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
        minutes: Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)),
        seconds: Math.floor((distance % (1000 * 60)) / 1000)
      };
    } else {
      this.countdownValue = null; // Timer reached the end
      clearInterval(this.interval); // Stop the interval
    }
  }

  ngOnDestroy() {
    clearInterval(this.interval); // Clear interval on component destroy
  }
}
