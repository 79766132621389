// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sections-box {
  margin: 2px 4px;
}

.sections-box ion-thumbnail {
  margin-top: 3px;
}

.sections-box ion-thumbnail.clear {
  margin-top: 0;
}

ion-list.list-ios {
  margin: 0;
}

.section-box {
  max-height: 100px;
}

.section-box .title-box {
  bottom: 100px;
}

.section-name {
  margin: 0;
  padding: 0 7px;
}

ion-list ion-item ion-chip {
  margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/core/pages/section/components/section-list/section-list.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;AACF;;AAEA;EACE,eAAA;AACF;;AAEA;EACE,aAAA;AACF;;AAEA;EACE,SAAA;AACF;;AAEA;EAEE,iBAAA;AAAF;;AAEA;EAEE,aAAA;AAAF;;AAGA;EAEE,SAAA;EACA,cAAA;AADF;;AAGA;EACE,SAAA;AAAF","sourcesContent":[".sections-box {\n  margin: 2px 4px;\n}\n\n.sections-box ion-thumbnail {\n  margin-top: 3px;\n}\n\n.sections-box ion-thumbnail.clear {\n  margin-top: 0;\n}\n\nion-list.list-ios {\n  margin: 0;\n}\n\n.section-box\n{\n  max-height: 100px;\n}\n.section-box .title-box\n{\n  bottom:100px;\n}\n\n.section-name{\n  //white-space: pre-line;\n  margin: 0;\n  padding: 0 7px;\n}\nion-list ion-item ion-chip{\n  margin:0;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
