import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {IonSlides, NavController} from "@ionic/angular";
import {NavigationExtras} from "@angular/router";
import {Events} from "../../../providers/events";

@Component({
  selector: 'dynamic-slider-body',
  templateUrl: './dynamic-slider-body.component.html',
  styleUrls: ['./dynamic-slider-body.component.scss'],
})
export class DynamicSliderBodyComponent implements OnInit {
    @Input()
    public data: any;

    @Input()
    public slidesPerView: number = 3;

    @Input()
    public fields: any;

    @Input()
    public callback: any;

    @Input()
    public goToOnClick: boolean;

    @Input()
    public showArrows: boolean = true;

    @Input()
    public onClickPrefix: string;

    @Input()
    public activeModel: string;

    @Input()
    public checkedActive: object = {};

    @Input()
    public checkedDataActive: object = {};

    @Input()
    public eventData: object = {};

    @Input()
    public imageHeight: number = 120;

    @Input()
    public amountBox: number = 0;

    @Input()
    public slidesPrefix: string;

    @Input()
    public goToPage: any;

    @Input()
    public options: object = {'autoplay':0, 'start':true, 'end':false};

    @ViewChild('slides', null) slides: IonSlides;

    public sliderVisible = 'hidden'

    public pages: object;

    constructor(public navCtrl: NavController, public events: Events) {
        this.pages = {
            'deal': 'dealPage',
            'section': 'itemsPage',
        }
    }

    ngOnInit()
    {
    }

    ngAfterViewInit() {
        this.options['slidesPerView'] = this.slidesPerView;

        // this.slides.slidesPerView = this.slidesPerView;
        this.events.publish(this.slidesPrefix, {'slides': this.slides})
    }

    onclick(model) {
        this.eventData['model'] = model

        let navigationExtras: NavigationExtras = {
          queryParams: this.eventData
        };

        if (this.goToOnClick) {
            if(this.goToPage)
                this.navCtrl.navigateForward(this.goToPage, navigationExtras)
            else
                this.navCtrl.navigateForward(this.pages[model.prefix], navigationExtras)
        } else {
            if (this.onClickPrefix) this.events.publish(this.onClickPrefix, navigationExtras);
        }
    }

    amountBoxChanged(model, value) {
        this.eventData['value'] = value
        this.eventData['model'] = model
        if (this.onClickPrefix) this.events.publish(this.onClickPrefix+'_amountBoxChanged', this.eventData);
    }

    slidesDidLoad(slides:IonSlides)
    {
    }

    slideChanged()
    {
        this.slides.length().then(len => {
            this.slides.getActiveIndex().then(index => {
                this.options['isStart'] = index == 0;
                this.options['isEnd'] = index == len - this.options['slidesPerView'];
            });
        });
    }
}