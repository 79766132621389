import {Component, OnInit} from '@angular/core';

export class Business {
    data = {
        'GlobalSettingsData':{
            'shippingPrice':null,
            'vat':null,
            'type':null,
            'orderStatusChangeTime':null,
            'onlyStaffAddressEdit':null,
            'name':null,
            'title':null,
            'massof':null,
            'massofKey':null,
            'about':null,
            'times':null,
            'cellphone':null,
            'directions':null,
            'minimumShipping':null,
            'useTerms':null,
            'registerMessage': null,
            'registerDoneMessage': null,
            'orderMessage': null,
            'supportMessage': null,
            'contactEmail': null,
            'pricesVisible': null,
            'stockReports':null,
            'supportCellphone':null,
            'dollarRate':null,
            'dollarSaleRate':null,
            'catalog':null,
            'iscatalog':null,
            'bisType':null,
            'orderTypes':null,
            'menuText':null,
            'vatIncluded':null,
            'openingData':null,
            'closingText':null,
            'creditRequests':null,
            'appPayment':null,
            'closingForOrders':null,
            'closingForOrdersText':null,
            'quickNavKey':null,
            'tickets':null,
            'bids':null,
            'defaultLang':null,
            'registerForCart':null,
            'menuOnMain':null,
            'cashInDelivery':null,
            'storeTitles':null,
            'whatsappOrderShare':null,
            'pickup':null,
            'paymentTypes':null,
            'itemsView':null,
            'branches':null,
            'onStockOnly':null,
            'topSecInMain':null,
            'orderStatuses':null,
            'inviteText':null,
            'shipmentsManage':null,
            'agentOrderCreatePage':null,
            'pickupBranches':null,
        },
        'prefix':'',
        'erp':'',
        'id':null,
    };

    constructor(public data1: any){
        this.data = data1;
    }
    //shortHeaders = [];
    prefix = "business";
    title = "Business";
    dataFields = {
        id: {'title': 'ID', 'value': null, 'type': 'number', 'readonly': true},
        created: {'title': 'Created', 'value': null, 'type': 'date', 'readonly': true},
        updated: {'title': 'Updated', 'value': null, 'type': 'date', 'readonly': true},
        name: {'title': 'Name', 'value': null, 'type': 'text'},
        prefix: {'title': 'Prefix', 'value': null, 'type': 'text'},
        username: {'title': 'username', 'value': null, 'type': 'text'},
        password: {'title': 'password', 'value': null, 'type': 'text'},
        cellphone: {'title': 'cellphone', 'value': null, 'type': 'text'},
        contactEmail: {'title': 'contactEmail', 'value': null, 'type': 'text'},

    }
}
