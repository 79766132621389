import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Events} from "../../../../framework/providers/events";
import {AdminService} from "../../../../core/services/AdminService";
import {ModelProvider} from "../../../../framework/providers/modelProvider/ModelProvider";
import {ModelToolsProvider} from "../../../../framework/providers/model-tools/model-tools";
import {AppConfig} from 'src/core/conf/main';
import {BusinessService} from "../../../../core/services/BusinessService";

@Component({
    selector: 'app-quick-filter',
    templateUrl: './app-quick-filter.component.html',
    styleUrls: ['./app-quick-filter.component.scss'],
})
export class AppQuickFilterComponent implements OnInit, OnDestroy {

    @Input()
    navCtrl;
    @Input()
    sectionName;
    @Input()
    prefix;
    // [{'fieldKey':..}, ...]
    @Input()
    fields;
    showFields;
    @Input()
    baseFilter = {};
    @Input()
    exTableData;
    @Input()
    buttonsOnly = false;
    @Input()
    display = ['regular'];//'customFilters',

    activeField;

    ready = false;
    quickAddModel;
    fieldTypes;
    advancedFilters;
    advancedFiltersByName;
    tableData;
    activeTab;
    modelFields;
    modelFieldsToShow;

    openFilters = {};
    resFilterData = {};

    //if select custom filter
    selectedFilter;

    buttomFilters = {};
    historyData = {};

    searchResults;

    public AppConfig = AppConfig;

    constructor(public events: Events,
                public modelTools: ModelToolsProvider,
                public adminService: AdminService, public businessService: BusinessService, public modelProvider: ModelProvider) {
    }

    ngOnInit() {
        this.quickAddModel = this.adminService.getNewModel(this.sectionName, this.exTableData);
        this.fieldTypes = this.adminService.getFieldsType(this.sectionName, this.exTableData);
        if (!this.buttonsOnly) {
            this.loadFiltersData();

            this.events.subscribe(this.prefix + '_buttonclickfilterfield', (f) => {
                this.runfilter(f);
            })

            this.events.subscribe("reLoadFilters" + this.prefix, (d) => {
                this.loadFields();
            });

            this.events.subscribe("filterClearClick" + this.prefix, (d) => {
                this.clearFilter();
            });

            this.events.subscribe('onModelsUpdate' + this.prefix, (exTableData) => {
                console.log('result!!')
                // this.exTableData = exTableData;
                // this.loadFilters();
            });
        }
    }

    loadFiltersData() {
        let me = this;
        let filters = {};
        filters['firstRequest'] = true;
        filters['loadModelExtraData'] = this.sectionName;
        this.adminService.getServerModel(this.sectionName, filters)
            .then(function (extableData: any) {
                for (let key in extableData) {
                    me.exTableData[key] = extableData[key];
                }
                console.log('publish: ', 'reLoadFilters' + me.prefix)
                me.events.publish('reLoadFilters' + me.prefix);

                me.loadFields();
                me.initFilers();
                me.loadFilters();
            });
    }

    loadFields() {

        if (this.fields) {
            let res = [];
            let resKeys = [];
            for (let k in this.exTableData[this.sectionName + 'QuickFilter']) {
                if (this.fields.includes(this.exTableData[this.sectionName + 'QuickFilter'][k]['fieldKey'])) {
                    res.push(this.exTableData[this.sectionName + 'QuickFilter'][k]);
                    resKeys.push(this.exTableData[this.sectionName + 'QuickFilter'][k]['fieldKey']);
                }
            }

            for (let k of this.fields) {
                if (!resKeys.includes(k)) {
                    res.push({'fieldKey': k, 'data': null, 'title': k});
                }
            }

            // res;
            this.showFields = res;
        } else {
            this.showFields = this.exTableData[this.sectionName + 'QuickFilter'];
        }
    }

    initFilers() {
        this.modelFields = this.exTableData[this.sectionName + 'Ref'];
        // let modelFieldsByName = this.adminService.getFieldsType(this.sectionName, this.exTableData);
        this.modelFieldsToShow = [];

        let fields = [];
        let fieldNames = [];

        //build external model - related object.
        //can filter
        for (let k in this.modelFields) {
            let field = this.modelFields[k];

            if (field['showTable']) {
                fields.push(field);
                fieldNames.push(field['name']);
            }
        }
        let me = this;
        if (me.advancedFilters) {
            for (let filter of me.advancedFilters) {
                me.advancedFiltersByName[filter.name] = filter;
                me.resFilterData[filter.name] = [];

                if (!me.adminService.checkedData[filter.sectionName + 'ModelCheck']) {
                    me.adminService.checkedData[filter.sectionName + 'ModelCheck'] = {'markedRow': []};
                    me.adminService.checkedData[filter.sectionName + 'ModelCheckById'] = {'markedRow': []};
                }
            }
        }

        // console.log(this.modelFields);
        this.tableData = this.exTableData[this.sectionName + 'Data'];
    }

    ngOnDestroy() {
        // if (!this.buttonsOnly) {
        this.events.unsubscribe("reLoadFilters" + this.prefix);
        this.events.unsubscribe("onModelsUpdate" + this.prefix);
        this.events.unsubscribe("filterClearClick" + this.prefix);
        this.events.unsubscribe(this.prefix + '_buttonclickfilterfield');
        // }
    }

    //custom filters
    loadFilters() {
        if (!this.exTableData['advancedFilters']) return;

        if (this.advancedFilters) return;
        // let filters = this.adminService.getMainFilters();
        // filters['getModelSelectTableOptions'] = this.sectionName;
        // filters['baseFilter'] = JSON.stringify(this.baseFilter ? this.baseFilter : {});
        let me = this;

        // this.modelProvider.list('report', filters)
        //     .then(function (data: any) {
        me.advancedFilters = this.exTableData['advancedFilters']['filters'];
        if (!me.advancedFilters) return

        let defaults = this.exTableData['advancedFilters']['defaults'];
        // console.log(me.advancedFilters);
        me.advancedFiltersByName = {};
        for (let filter of me.advancedFilters) {
            me.advancedFiltersByName[filter.name] = filter;
            filter['search'] = '';
            me.resFilterData[filter.name] = [];

            if (!me.adminService.checkedData[filter.sectionName + 'ModelCheck']) {
                me.adminService.checkedData[filter.sectionName + 'ModelCheck'] = {'markedRow': []};
                me.adminService.checkedData[filter.sectionName + 'ModelCheckById'] = {'markedRow': []};
            }
        }
        // me.initFilers();

        //set defaults
        //get from server
        for (let field of me.modelFields) {
            if (defaults[field.name['value']]) {
                let filter = me.advancedFiltersByName[field.name['value']];
                if (['selectOptionFull', 'selectOptions'].includes(me.fieldTypes[field['name']['value']].type)) {
                    field['options'] = [defaults[field.name['value']]];
                } else if (['selectTable'].includes(me.fieldTypes[field['name']['value']].type)) {
                    for (let fkey in filter.data) {
                        if (filter.data[fkey]['id']['value'] == defaults[field.name['value']]) {
                            filter.data[fkey]['chosen'] = true;
                        }
                    }
                } else {
                }
            }

            if (me.fieldTypes[field['name']['value']] && ['selectTable'].includes(me.fieldTypes[field['name']['value']].type)) {
                field['options'] = [];
            }
        }

        me.ready = true;
        // });
    }

    clearFilter(bubble = false) {
        if (bubble) this.events.publish("filterClearClick" + this.prefix, {});

        this.selectedFilter = null;
        // this.loadFilters();
        this.initFilers();
        this.events.publish("btnclick_" + this.prefix, {
            'key': 'addFilter',
            'data': {'clear': true}
        });

        let me = this;
        this.ready = false;
        setTimeout(function () {
            me.ready = true;
        })
    }

    addFilter() {
        let me = this;
        let inputs = [
            {
                name: 'name',
                type: 'text',
                label: 'Please enter a filter name:'
            }
        ];
        this.modelTools.inputAlert('Custom Var', 'Please enter name of custom field', function (res) {
            me.buttonClick({'addFilter': res['name']});
        }, false, inputs);
    }

    showFilterFields() {
        this.presentSelectBar(null, 'columns', true);
    }

    buttonClick(filters = {}) {
        this.events.publish(this.prefix + '_buttonclickfilterfield', filters);
    }

    runfilter(filters = {}) {
        // console.log('runfilter click! 1');
        if (this.selectedFilter) {
            filters['filterId'] = this.selectedFilter['id']['value'];
        }

        //add specific field filter.
        if (this.showFields && this.showFields.length == 1) {
            for (let k of this.showFields) {
                let f = this.fieldTypes[k['fieldKey']];
                f['isOpen'] = true;
                this.resFilterData[k['fieldKey']] = [];
                this.advancedFilters.push(f);
            }
        }

        if (this.advancedFilters) {
            for (let adv of this.advancedFilters) {
                if (!adv['isOpen']) {
                    continue
                }

                let field = this.fieldTypes[adv['name']];
                let fieldName = field['name'];
                let type = field['type'];

                //search select table on external model special defined field.
                // if (field['extModel']) {
                //     let fName = field['extField']['name']['value'];
                //     if (field['extModel'][fName]['value'] != -1) {
                //         filters[fieldName] = {'options': [field['extModel'][fName]['value']], 'type': 'exModelSelect'};
                //     } else if (field['extField']['options']) {
                //         filters[fieldName] = {'options': field['extField']['options'], 'type': 'exModelSelect'};
                //     }
                // } else
                if (adv['search']) {
                    filters[fieldName] = {'type': 'search'};
                    filters[fieldName]['search'] = adv['search'];
                } else if (['selectOptionFull', 'selectOptions'].includes(type)) {
                    if (field['options'])
                        filters[fieldName] = {'options': field['options'] ? field['options'] : [], 'type': type}
                } else if (type == 'selectTable') {
                    // filters = field['options'];
                    // let adv = this.advancedFiltersByName[field['name']['value']];
                    // if (field['options']) {
                    //     for (let item of adv['data']) {
                    //         console.log(item['id']['value']);
                    //         if (field['options'].includes(item['id']['value'])) {
                    //             console.log(item['main']['displayName']);
                    //             filter.push(item['id']['value']);
                    //         }
                    //     }
                    // }
                    if (this.resFilterData[fieldName] && this.resFilterData[fieldName].length > 0) {
                        filters[fieldName] = {'options': this.resFilterData[fieldName], 'type': type};
                    }
                    //if select from table - not multiple.
                    else if (field['value']) {
                        filters[fieldName] = {'options': [field['value']], 'type': type};
                    }
                } else if (['text', 'textarea'].includes(type)) {
                    if (field['value'] && field['op']) {
                        filters[fieldName] = {'value': field['value'], 'type': type, 'op': field['op']};
                    }

                } else if (['number', 'float'].includes(type)) {
                    filters[fieldName] = {
                        'value': field['value'],
                        'betweenValue': field['betweenValue'],
                        'op': field['op'],
                        'type': type
                    };

                } else if (['date', 'datetime'].includes(type)) {
                    if (field['start'] || field['chooseType']) {
                        filters[fieldName] = {
                            'start': field['start'],
                            'end': field['end'],
                            'chooseType': field['chooseType'],
                            'type': type
                        };
                    }
                } else if (type == 'time') {
                    filters[fieldName] = {
                        'start': field['start'],
                        'end': field['end'],
                        'chooseType': field['chooseType'],
                        'type': type
                    };
                } else if (type == 'boolean' && field['value'] == 'on' || field['value'] == 'off') {
                    filters[fieldName] = {'value': field['value'], 'type': 'boolean'};
                }

                if (adv.filters) {
                    for (let filter of adv.filters) {
                        if (filter.isMarked) {
                            if (!filters[fieldName]) filters[adv.name] = {}
                            filters[fieldName]['type'] = 'filter';
                            filters[fieldName]['value'] = filter.id;
                        }
                    }
                }
            }
            console.log(filters);
        }

        this.filterit(filters);
    }

    filterit(filters) {
        this.events.publish("btnclick_" + this.prefix, {
            'key': 'addFilter',
            'data': {'fieldFilters': filters}
        });
    }


    //filters = {'options': ['all'], 'type': 'selectTable'}
    filteritData(fieldKey, filters, filterData = null) {
        if (!this.buttomFilters[fieldKey]) this.buttomFilters[fieldKey] = {}

        if (filterData && !this.historyData[fieldKey]) {
            this.historyData[fieldKey] = filterData['data'];
        }

        // if (this.buttomFilters[fieldKey]['on'] && this.buttomFilters[fieldKey]['value'] == filters['options'][0]) {
        // this.buttomFilters[fieldKey]['on'] = false;
        // return
        // }

        let filter = this.buttomFilters;
        // filter[fieldKey] = filters;//this field options.
        let options = filter[fieldKey]['options'] ? filter[fieldKey]['options'] : [];
        filter[fieldKey]['type'] = filters['type']
        if (filters['options']) {
            for (let id of filters['options']) {
                if (options.includes(id)) {
                    options.splice(options.indexOf(id), 1);
                } else {
                    if (options.includes('all')) options.splice(options.indexOf('all'), 1);
                    options.push(id);
                }
            }
        }

        if (options.length == 0) {
            options = ['all']
            this.historyData[fieldKey] = null;
        }

        filter[fieldKey]['options'] = options;
        this.events.publish("btnclick_" + this.prefix, {
            'key': 'addFilter',
            'data': {
                'fieldFilters': filter,
                'quickFilters': fieldKey
            },
        });

        this.buttomFilters[fieldKey]['value'] = options;//[0];

        if (filter[fieldKey]['options'].includes('all')) {
            this.buttomFilters[fieldKey]['on'] = false;
        } else {
            this.buttomFilters[fieldKey]['on'] = true;
        }
    }

    addSelectTableOption(field, item, items) {
        console.log(this.fieldTypes[field['name']['value']]);
        if (this.fieldTypes[field['name']['value']]['filterSingle']) {
            for (let item of items) {
                item['chosen'] = false;
            }
        }
        item['chosen'] = !!!item['chosen'];
        // this.buttonClick();
    }

    async presentSelectBar(myEvent, type, bigPopup = false) {
        this.adminService.popupEditBoxByData(null, {
            'prefix': this.prefix,
            'exTableData': this.exTableData,
            'sectionName': this.sectionName,
            'type': type
        }, bigPopup);
    }

    checkFilter(fieldName, id) {
        if (!this.resFilterData[fieldName]) this.resFilterData[fieldName] = [];

        if (this.resFilterData[fieldName].indexOf(id) != -1) {
            this.resFilterData[fieldName].splice(this.resFilterData[fieldName].indexOf(id), 1);
        } else {
            this.resFilterData[fieldName].push(id);
        }
    }

    setActiveField(field) {
        if (field == this.activeField) {
            this.activeField = null;
            return;
        }

        let me = this;
        me.activeField = null;
        setTimeout(function () {
            me.activeField = field;
        }, 100);
    }

    search(sectionName, searchTxt) {
        let me = this;
        return this.adminService.searchModel(sectionName, searchTxt)
            .then(function (data: any) {
                console.log(data);
                me.searchResults = data;
            });
    }

    fieldClick(event, fieldName) {
        //    orderby(key)
        this.adminService.popupEditBox(event, this.sectionName, this.adminService.manageModels,
            'columnDisplay_etr', null, 'columnDisplay',
            false, null, this.adminService.getFieldsType(this.sectionName, this.exTableData)[fieldName], null, null, null,
            {'changeType': 'showTable', 'prefix': this.prefix});
    }
}
