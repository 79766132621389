import {Component, Input, OnInit} from '@angular/core';
import {Section} from "../../../../../framework/model/core/sections/section";
import {NavigationExtras} from "@angular/router";
import {NavController} from "@ionic/angular";
import {BusinessService} from "../../../../services/BusinessService";
import {AppDataService} from "../../../../services/AppDataService";
import {AppConfig} from "../../../../conf/main";
import {Events} from "../../../../../framework/providers/events";
import {OrderService} from "../../../../services/OrderService";
@Component({
    selector: 'section-list',
    templateUrl: './section-list.component.html',
    styleUrls: ['./section-list.component.scss'],
})
export class SectionListComponent implements OnInit {

    public initmodels;
    public models: Array<Section> = [];

    @Input()
    public options: object = {};

    @Input()
    public filters: object = {};

    public listfilters: object = {};

    @Input()
    public initData: boolean = true;

    @Input()
    public listModels: boolean = true;

    @Input()
    public appDataKey: string;

    @Input()
    public dataKeys: Array<any>;

    @Input()
    public prefix: string;

    @Input()
    public clickEvent: string;

    constructor(public navCtrl: NavController, public businessService: BusinessService,
                public orderService:OrderService,
                public appDataService: AppDataService, public events: Events) {
    }

    ngOnInit() {
        if (!AppConfig.useAppData)
            this.listModels = true;
        // this.listModels = true;
        // if(this.initData)
        //     this.initmodels = this.businessService.getAppData(this.filters['sectionId']);
        this.getModels();

        this.events.subscribe(this.prefix + '_updateSectionsFilter', (params) => {
            for (var k in params) {
                this.filters[k] = params[k];
            }

            this.getModels();
        });
    }

    ngAfterViewInit() {
    }

    getModels() {
        if (!this.listModels) {
            if (this.dataKeys) {
                this.models = this.appDataService.getModelsByArr(this.dataKeys, 'section')['models'];
            } else if (this.appDataKey) {
                this.models = this.appDataService.getModelsByKey(this.appDataKey, 'section')['models'];
            } else {
                this.models = this.appDataService.getSectionSubSections(this.filters['sectionId']);
                console.log(this.models)
            }
        }

        this.listfilters = JSON.parse(JSON.stringify(this.filters));
        this.listfilters['limit'] = 30;
        this.listfilters['includeInMenu'] = true;

    }

    openModel(model) {
        ////console.logmodel);
        if (this.clickEvent) {
            this.events.publish(this.clickEvent, {model: model});
            return;
        }

        this.goTo('section/' + model.data.id, {'model': model});
    }


    goTo(page, params) {
        params['filters'] = JSON.parse(JSON.stringify(this.filters));
        params['disableInitData'] = !this.initData;
        ////console.log'PRARMS', params)

        let navigationExtras: NavigationExtras = {
            queryParams: params,
        };

        this.navCtrl.navigateForward(page, navigationExtras)
    }

}
