// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-toolbar {
  --background: var(--main-top);
  color: var(--main-top-text);
  --ion-toolbar-color: var(--main-top-text);
}
ion-toolbar ion-buttons ion-button.button-clear {
  --color: var(--main-top-text);
  --color-activated: var(--main-top-text);
}

.keyboard .keyboard-input {
  font-size: 20px;
}
.keyboard .keyboard-key {
  text-align: center;
  margin: auto;
}
.keyboard .keyboard-key ion-button.button-disabled {
  background: #fff;
}

ion-content::-webkit-scrollbar {
  display: none;
}

.comments-box {
  margin: 5px;
}
.comments-box ion-textarea {
  margin: 0;
}

::ng-deep .price-div {
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/core/pages/agent/agent-keyboard/agent-keyboard.page.scss"],"names":[],"mappings":"AAAA;EACE,6BAAA;EACA,2BAAA;EACA,yCAAA;AACF;AACE;EACE,6BAAA;EACA,uCAAA;AACJ;;AAIE;EACE,eAAA;AADJ;AAIE;EACE,kBAAA;EACA,YAAA;AAFJ;AAII;EACE,gBAAA;AAFN;;AAOA;EACE,aAAA;AAJF;;AAOA;EACE,WAAA;AAJF;AAME;EACE,SAAA;AAJJ;;AAUE;EACE,uBAAA;AAPJ","sourcesContent":["ion-toolbar {\n  --background: var(--main-top);\n  color: var(--main-top-text);\n  --ion-toolbar-color: var(--main-top-text);\n\n  ion-buttons ion-button.button-clear {\n    --color: var(--main-top-text);\n    --color-activated: var(--main-top-text);\n  }\n}\n\n.keyboard {\n  .keyboard-input {\n    font-size: 20px;\n  }\n\n  .keyboard-key {\n    text-align: center;\n    margin: auto;\n\n    ion-button.button-disabled {\n      background: #fff;\n    }\n  }\n}\n\nion-content::-webkit-scrollbar {\n  display: none;\n}\n\n.comments-box {\n  margin: 5px;\n\n  ion-textarea {\n    margin: 0;\n  }\n\n}\n\n::ng-deep{\n  .price-div{\n    justify-content: center;\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
