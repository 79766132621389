// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
ion-list {
  max-height: 500px;
  overflow-y: scroll;
}
ion-list ion-item ion-label div {
  color: black;
}
ion-list ion-item ion-label p ion-icon {
  color: var(--button-a-background);
}

ion-item-divider {
  margin: 10px 0;
}

h1 {
  font-size: 50px;
  font-weight: bold;
  color: black;
  margin: 0;
}

#rating_bar {
  display: flex;
  align-items: center;
  unicode-bidi: bidi-override;
  direction: rtl;
  font-size: 20px;
  flex-direction: row-reverse;
}

#rating_bar > span:before {
  content: "☆";
  color: #c7c5c5;
  cursor: pointer;
  font-size: 30px;
}

#rating_bar > span:hover:before,
#rating_bar span:hover ~ span:before, #rating_bar span.active ~ span:before, #rating_bar span.active:before {
  color: var(--button-a-background);
}

#rating_bar > span:hover:before,
#rating_bar span:hover ~ span:before,
#rating_bar > span.new_rate:before,
#rating_bar span.new_rate ~ span:before {
  color: var(--ion-color-success) !important;
}`, "",{"version":3,"sources":["webpack://./src/core/pages/item/components/item-reviews/item-reviews.component.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAAhB;EACE,iBAAA;EACA,kBAAA;AAEF;AAEM;EACE,YAAA;AAAR;AAIQ;EACE,iCAAA;AAFV;;AASA;EACE,cAAA;AANF;;AASA;EACE,eAAA;EACA,iBAAA;EACA,YAAA;EACA,SAAA;AANF;;AASA;EACE,aAAA;EACA,mBAAA;EACA,2BAAA;EACA,cAAA;EACA,eAAA;EACA,2BAAA;AANF;;AAaA;EACE,YAAA;EACA,cAAA;EACA,eAAA;EACA,eAAA;AAVF;;AAaA;;EAEE,iCAAA;AAVF;;AAaA;;;;EAKE,0CAAA;AAXF","sourcesContent":["\\ion-list{\n  max-height: 500px;\n  overflow-y: scroll;\n\n  ion-item{\n    ion-label{\n      div{\n        color: black;\n      }\n\n      p{\n        ion-icon{\n          color: var(--button-a-background);\n        }\n      }\n    }\n  }\n}\n\nion-item-divider{\n  margin: 10px 0;\n}\n\nh1{\n  font-size: 50px;\n  font-weight: bold;\n  color: black;\n  margin: 0;\n}\n\n#rating_bar {\n  display: flex;\n  align-items: center;\n  unicode-bidi: bidi-override;\n  direction: rtl;\n  font-size: 20px;\n  flex-direction: row-reverse;\n}\n\n#rating_bar>span{\n  //max-height: 20px;\n}\n\n#rating_bar>span:before {\n  content: '☆';\n  color: #c7c5c5;\n  cursor: pointer;\n  font-size: 30px;\n}\n\n#rating_bar>span:hover:before,\n#rating_bar span:hover~span:before, #rating_bar span.active~span:before, #rating_bar span.active:before {\n  color: var(--button-a-background);\n}\n\n#rating_bar>span:hover:before,\n#rating_bar span:hover~span:before,\n#rating_bar>span.new_rate:before,\n#rating_bar span.new_rate~span:before,\n{\n  color: var(--ion-color-success) !important;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
