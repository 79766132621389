import {Component, OnInit} from '@angular/core';
import {Business} from "../../../framework/model/core/business/business";
import {BusinessService} from "../../services/BusinessService";
import {Branch} from "../../../framework/model/core/branch/branch";
import {Ticket} from "../../../framework/model/core/ticket/ticket";
import {NavigationExtras} from "@angular/router";
import {NavController} from "@ionic/angular";
import {Section} from "../../../framework/model/core/sections/section";

@Component({
    selector: 'app-support',
    templateUrl: './support.page.html',
    styleUrls: ['./support.page.scss'],
})

export class SupportPage implements OnInit {
    public ticket: Ticket;
    public business: Business;
    public models: Array<Ticket> = [];

    constructor(public businessService: BusinessService, public navCtrl: NavController) {
        this.ticket = new Ticket({});
        this.business = this.businessService.getBusiness();
    }

    ngOnInit() {
    }

    goTo(page, params) {
        let navigationExtras: NavigationExtras = {
            queryParams: params
        };

        this.navCtrl.navigateForward(page, navigationExtras)
    }

}
