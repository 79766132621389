import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AppConfig} from "../../conf/main";
import {ActivatedRoute} from "@angular/router";
import {ModelProvider} from "../../../framework/providers/modelProvider/ModelProvider";

import * as moment from 'moment';
import {Chart} from 'chart.js';
import {Events} from "../../../framework/providers/events";


@Component({
    selector: 'app-reports',
    templateUrl: './reports.page.html',
    styleUrls: ['./reports.page.scss'],
})
export class ReportsPage implements OnInit, OnDestroy {
    @ViewChild("barCanvas1", {'static': true}) barCanvas1: ElementRef;

    @ViewChild("barCanvas11", {'static': true}) barCanvas11: ElementRef;
    @ViewChild("barCanvas12", {'static': true}) barCanvas12: ElementRef;
    @ViewChild("barCanvas13", {'static': true}) barCanvas13: ElementRef;
    @ViewChild("barCanvas14", {'static': true}) barCanvas14: ElementRef;
    @ViewChild("barCanvas15", {'static': true}) barCanvas15: ElementRef;
    @ViewChild("barCanvas16", {'static': true}) barCanvas16: ElementRef;
    @ViewChild("barCanvas17", {'static': true}) barCanvas17: ElementRef;
    @ViewChild("barCanvas18", {'static': true}) barCanvas18: ElementRef;
    @ViewChild("barCanvas19", {'static': true}) barCanvas19: ElementRef;

    barChart;

    appConfig = AppConfig;
    models = [];
    prefix = 'reports';
    tablExtData = {'showEdit': false};
    filters = {'ordering': '-id', 'type': 'report'};
    modelName;

    ready = false;

    reportPages;
    keys;
    currentReport;
    finalReportRes;

    modelKeys = {
        'orderItem': ['image', 'makerImage', 'itemId', 'created', 'orderId', 'itemName', 'finalUnitPrice', 'amount']
    };

    currentPageIndex = 0;
    currentFieldsKeys;
    currentFields;
    currentPage = 1;
    showTable = {};

    checked = {};

    resultData: any = {};

    fileDownloadUrl;
    presentingElement;

    constructor(public events: Events,
                public modelProvider: ModelProvider,
                public route: ActivatedRoute) {
    }

    ngOnInit() {
        this.modelName = 'reports';
        this.ready = true;

        let me = this;
        this.events.subscribe('update_' + this.prefix, (data) => {
            me.models = data.models;

            let i = 1;
            for (let key in me.models) {
                let finalReportRes = me.models[key].data.finalResReport;
                //console.logfinalReportRes)

                if (finalReportRes) {
                    if (finalReportRes.graph) {
                        this.getGraph(this.getViewCanvas(i),
                            finalReportRes,
                            me.models[key].data.graphType);
                    }
                }
                i++;
            }
        });
    }

    ngAfterViewInit() {
        this.presentingElement = document.querySelector('.reportContent');
    }

    ngOnDestroy(): void {
        this.events.unsubscribe('update_' + this.prefix);
    }

//this.reportPages.data.info.pages =[     0:
// fields:
// {
//  maker:
//  {
//      fieldKey: "id"
//      fieldName: "name"
//      name: "maker"
//      options: Array(62) [0: {id: 25, internalId: 13, name: "Sandisk"]
//  }
// }
    setFieldListCheck(key) {
        //console.logthis.checked);
        this.resultData['data'][key] = this.checked[key];
    }


    getGraph(barCanvas, resReportData, graphType = 'bar') {
        return;

        // graphVarId
        let keys = [];
        let values = [];

        let labelkey;
        for (let key in resReportData.models) {
            //console.loglabelkey);
            labelkey = resReportData.models[key][resReportData['graph'][0]];
            keys.push(labelkey && labelkey != undefined ? labelkey.substr(0, 7) : '-');
            values.push(resReportData.models[key][resReportData['graph'][1]]);
        }
        //console.logkeys);
        //console.logvalues);
        //
        // let lables = ["קבלנים", "..."];
        // let data = [1,52,13,,43];
        let me = this;
        setTimeout(function () {
            me.setChart(barCanvas, keys, values, graphType);
        });
    }

    getReport(report) {
        this.currentReport = report;
        this.currentPage = 1;

        this.modelProvider.get('report', report.data.id, {'pageDesc': true}).then((res: any) => {

            this.reportPages = res.models[0];

            this.currentFields = this.reportPages.data.info['fields'];

            this.currentFieldsKeys = Object.keys(this.currentFields);

            let me = this;
            this.currentFieldsKeys.sort(function (a, b) {
                return me.currentFields[a]['order'] - me.currentFields[b]['order'];
            });

            this.resultData = {'prefix': report.data.name};
            this.resultData['data'] = {};
            this.resultData['dataFields'] = {};
            for (let key in this.currentFields) {
                let type;
                if (this.currentFields[key].type == 'options') {
                    this.resultData['dataFields'][key] = {
                        'title': this.currentFields[key].title,
                        'value': null,
                        'type': 'number'
                    };
                    this.resultData['data'][key] = [];
                } else if (this.currentFields[key].type == 'dateRange') {
                    this.resultData['dataFields'][key] = {
                        'title': this.currentFields[key].title,
                        'value': 'today',
                        'type': 'dateRange'
                    };
                    this.resultData['data'][key] = [];
                } else if (['datetime', 'date'].indexOf(this.currentFields[key].type) != -1) {
                    this.resultData['dataFields'][key] = {
                        'title': this.currentFields[key].title,
                        'value': moment().format('YYYY-MM-DD'),//this.currentFields[key].value ? this.currentFields[key].value : moment().format('YYYY-MM-DD HH:MM'),
                        'type': 'datetime'
                    };
                    // if (this.resultData['dataFields'][key]) {
                    console.log('key:', key)
                    this.resultData['data'][key] = moment().format('YYYY-MM-DD');
                    // }
                    // this.resultData['data'][key] = moment().format('YYYY-MM-DD HH:MM');


                } else if (this.currentFields[key].type == 'text') {

                    this.resultData['dataFields'][key] = {
                        'title': this.currentFields[key].title,
                        'value': null,
                        'type': 'text'
                    };
                    this.resultData['data'][key] = '';

                } else if (this.currentFields[key].type == 'list') {
                    this.resultData['dataFields'][key] = {
                        'title': this.currentFields[key].title,
                        'value': null,
                        'type': 'list'
                    };
                    this.resultData['data'][key] = [];
                    this.checked[key] = {};
                }
            }
        });
    }

    nextPage() {
        //console.logthis.checked);
        this.currentPage++;
        //console.logthis.resultData);
    }

    prevPage() {
        //console.logthis.resultData);
        this.currentPage--;
    }

    optionAdd(key, option) {

        if (this.resultData.data[key].indexOf(option.id) != -1) {
            this.resultData.data[key].splice(this.resultData.data[key].indexOf(option.id), 1)
        } else {
            this.resultData.data[key].push(option.id);
        }
    }

    formIsDone() {
        for (let key in this.resultData.dataFields) {
            if (this.resultData['dataFields'][key].type == 'list') {
                this.resultData['data'][key] = [];
                for (let id in this.checked[key]) {
                    if (this.checked[key][id]) {
                        this.resultData['data'][key].push(id);
                    }
                }

            }

            if (this.resultData['dataFields'][key].type == 'datetime') {
                this.resultData['data'][key] = moment(this.resultData['data'][key])
                    .format('YYYY-MM-DD');
            }
        }

        this.modelProvider.get('report', this.currentReport.data.id,
            {'result': true, 'data': JSON.stringify(this.resultData.data)})
            .then((res: any) => {
                this.finalReportRes = res['models'][0].data.finalResReport;

                if (this.finalReportRes.graph) {
                    this.getGraph(this.barCanvas1, this.finalReportRes);
                }

            });
    }

    //lables = ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"]
    //data = [12, 19, 3, 5, 2, 3]
    setChart(barElement, labales, data, type) {
        if (this.barChart) {
            this.barChart.clear();
        }
        //type: 'horizontalBar',
        this.barChart = new Chart(barElement.nativeElement, {
            type: type,
            data: {
                labels: labales.splice(0, 5),
                datasets: [
                    {
                        label: "",
                        data: data.splice(0, 5),
                        backgroundColor: [
                            "rgba(255, 99, 132, 0.2)",
                            "rgba(54, 162, 235, 0.2)",
                            "rgba(255, 206, 86, 0.2)",
                            "rgba(75, 192, 192, 0.2)",
                            "rgba(153, 102, 255, 0.2)",
                            "rgba(255, 159, 64, 0.2)"
                        ],
                        borderColor: [
                            "rgba(255,99,132,1)",
                            "rgba(54, 162, 235, 1)",
                            "rgba(255, 206, 86, 1)",
                            "rgba(75, 192, 192, 1)",
                            "rgba(153, 102, 255, 1)",
                            "rgba(255, 159, 64, 1)"
                        ],
                        borderWidth: 1
                    }
                ]
            },
            options: {
                scales: {
                    yAxes: [
                        {
                            ticks: {
                                beginAtZero: true
                            }
                        }
                    ]
                }
            }
        });
    }


    getViewCanvas(i) {
        let barCanvas;
        switch (i) {
            case 1:
                barCanvas = this.barCanvas11;
                break;
            case 2:
                barCanvas = this.barCanvas12;
                break;
            case 3:
                barCanvas = this.barCanvas13;
                break;
            case 4:
                barCanvas = this.barCanvas14;
                break;
            case 5:
                barCanvas = this.barCanvas15;
                break;
            case 6:
                barCanvas = this.barCanvas16;
                break;
            case 7:
                barCanvas = this.barCanvas17;
                break;
            case 8:
                barCanvas = this.barCanvas18;
                break;
            case 9:
                barCanvas = this.barCanvas19;
                break;
        }

        return barCanvas;
    }


    export() {
        let filters = {'result': true, 'data': JSON.stringify(this.resultData.data)};
        let me = this;

        filters['reportId'] = this.currentReport.data.id;

        this.modelProvider.download('downloadReportTable', filters).then(function (res) {
            //console.logres);

            me.fileDownloadUrl = res['savePath'];
        });
    }

}
