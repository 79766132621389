import {Component, Input, OnInit} from '@angular/core';
import {AppUser} from '../../../framework/model/core/appuser/appuser';
import {OrderService} from '../../services/OrderService';
import {Section} from '../../../framework/model/core/sections/section';
import {Order} from '../../../framework/model/core/order/order';
import {ActivatedRoute, NavigationExtras} from '@angular/router';
import {ModalController, NavController, PopoverController} from '@ionic/angular';
import {ModelProvider} from "../../../framework/providers/modelProvider/ModelProvider";
import {AppSettings} from '../../conf/appSettings'
import {BusinessService} from "../../services/BusinessService";
import {actionColors, hebrewColors} from "src/core/conf/main";
import {Report} from "../../../framework/model/general/reprot/report";
import {Events} from "../../../framework/providers/events";
import {ListFilterComponent} from "../../../framework/components/list/list-filter/list-filter.component";
import {OrdersFiltersComponent} from "./components/orders-filters/orders-filters.component";
import {AppDataService} from "../../services/AppDataService";
import {ModelStatus} from "../../../framework/model/modelStatus";

@Component({
    selector: 'app-orders',
    templateUrl: './orders.page.html',
    styleUrls: ['./orders.page.scss'],
})
export class OrdersPage implements OnInit {
    public appuser: AppUser;
    public models: Array<Order> = [];

    public prefix: string = 'ordersList_' + Math.floor((Math.random() * 1000) + 1);
    public filters: object = {'limit': 6, 'visible':true, 'ordering': '-id'};
    public tablExtData: object = {displayType: 'boxes', isOpen: true};

    public reportModels: Array<Report> = [];
    public reportPrefix: string = 'reportPrefix_' + Math.floor((Math.random() * 1000) + 1);
    public reportFilters: object = {'ordering': '-id', 'type': 'filter'};
    public reportExtData: object = {displayType: 'boxes'};

    public options: object = {};
    public showCurrentOrder: boolean = false;
    public currentOrder;
    public settings;

    public keys: Array<string> = [
        'id',
        'created',
        'updated',
        'action',
        'contactMan',
        // 'appuser',
        'state',
        'finalPrice',
        'status',
        // 'orderExtId',
        'itemCount',
        // 'bidStatus',
        'companyAgent',
        'companyName',
    ];
    public display: string = 'table';

    public tabs: object = {
        'value': 'companyOrders', 'data': [
            // {
            //     'value': 'myOrders',
            //     'title': 'myOrders',
            //     'checked': true
            // },
        ]
    };

    public activeStatuses = {'checked':{}, 'checkedData': {}, 'checkedArr':[], 'count':0, 'data':[]};
    public activeFilters = {'checked':{}, 'checkedData': {}, 'checkedArr':[], 'count':0};
    public activeActions = {'checked':{}, 'checkedData': {}, 'checkedArr':[], 'count':0, 'data':[]};

    constructor(public orderService: OrderService, public navCtrl: NavController,
                public businessService: BusinessService, public modalController:ModalController,
                public route: ActivatedRoute, public popoverCtrl:PopoverController,
                public appdataService:AppDataService,
                public events: Events, public modelProvider: ModelProvider, public appSettings: AppSettings) {
        this.getAppUser();

        this.settings = this.appSettings.getSettings('orders');

        this.activeStatuses.data = this.appdataService.getPricesListKey('order_statuses')
        this.activeActions.data = this.appdataService.getPricesListKey('order_actions')

        this.events.subscribe('agentCompanyChanged', (res) => {
            this.getAppUser();
            this.initTabs()

            // if (this.tabs['value'] == 'myOrders') return;
            // this.setFilters();
        });


    }

    initTabs(initparam = null){
        this.tabs['data'].length = 0;
        let company_tab = {
            'value': 'companyOrders',
            'title': 'companyOrders',
            'checked': false
        };
        let order_tab = {
            'value': 'allOrders',
            'title': 'allOrders',
            'checked': true
        };
        let is_agent = this.orderService.isAgent();
        let is_staff = this.orderService.isStaff();
        let company_approved = this.appuser.data.companyApproved;
        // if(is_staff)
        // {
        //     this.tabs['data'].push(company_tab)
        //     this.tabs['data'].push(order_tab)
        //     this.tabs['value'] = company_tab['value'];
        // }
        if(!is_agent)
        {
            this.tabs['data'].push(order_tab)
            this.tabs['value'] = order_tab['value'];
        }
        else{
            if(!company_approved)
            {
                this.tabs['data'].push(order_tab)
                this.tabs['value'] = order_tab['value'];
            }
            else{
                this.tabs['data'].push(company_tab)
                this.tabs['value'] = company_tab['value'];
            }
        }

        // if (this.orderService.isAgent() && !this.appuser.data.companyApproved) this.tabs['value'] = 'allOrders';
        this.setFilters(false);
    }

    ngOnInit() {
        this.initTabs()
        let me = this;
        this.events.subscribe('update_' + this.prefix, (data) => {
            me.models = data.models;
        });
        this.events.subscribe('update_' + this.reportPrefix, (data) => {
            me.reportModels = data.models;
        });

        this.route.queryParams.subscribe(params => {
            if(params['active-tab']){
                // this.tabs['value'] = params['active-tab'];
                this.initTabs(params['active-tab'])
                // this.setFilters();
            }
            if(params['action_id'])
            {
                let action = this.activeActions['data'].filter(p=>p.id == params['action_id'])[0]
                this.activeActions['checked'][action.id] = true;
                this.activeActions['checkedData'][action.id] = new ModelStatus(action);
                this.activeActions['checkedArr'].push(action.id);
                this.activeActions['count']++;
                this.filters['action_id'] = action.id+'';
            }

            if(params['status__name'])
            {
                let status = this.activeStatuses['data'].filter(p=>p.name == params['status__name'])[0]
                this.activeStatuses['checked'][status.id] = true;
                this.activeStatuses['checkedData'][status.id] = new ModelStatus(status);
                this.activeStatuses['checkedArr'].push(status.id);
                this.activeStatuses['count']++;
                this.filters['statuses'] = status.id+'';
            }
        });
    }

    getAppUser() {
        this.appuser = this.orderService.getAppUser();
    }

    async viewFilters() {
        const popover = await this.modalController.create({
            component: OrdersFiltersComponent,
            componentProps: {
                'prefix': this.prefix,
                'filters': this.filters,
                'activeFilters': this.activeFilters,
                'activeStatuses': this.activeStatuses,
                'activeActions': this.activeActions,
            },
            // cssClass: 'bottom-sheet-popover'
        });
        return await popover.present();
    }

    setFilters(refresh = true, e = null) {
        if (e) {
            this.tabs['value'] = e['detail']['value'];
        }


        console.log(this.tabs['value'])
        // if(this.tabs['value'] == 'myOrders') {
        //     this.filters['appuser'] = this.appuser.data.id;
        //     this.filters['companyId'] = -1;
        // }
        // else
        this.filters = {'visible':true};
        if (this.tabs['value'] == 'allOrders') {
            this.filters['companyId'] = -1;
            this.filters['appuser'] = -1;
        } else {
            this.filters['companyId'] = this.appuser.data.companyId;
            this.filters['appuser'] = -1;
        }

        if(this.settings['includeItems'])
            this.filters['includeItems'] = this.settings['includeItems'];
        if(this.settings['includeStatuses'])
            this.filters['includeStatuses'] = this.settings['includeStatuses'];

        // if (!refresh) return;
        this.events.publish(this.prefix + '_initPager', this.filters);
        this.events.publish(this.prefix + '_updateFilter', this.filters);
    }

    goTo(page, params) {
        params['updatePrefix'] = this.prefix + '_updateModels';
        let navigationExtras: NavigationExtras = {
            queryParams: params
        };

        this.navCtrl.navigateForward(page, navigationExtras)
    }

    refresh() {
        this.events.publish(this.prefix + '_updateModels', this.filters);
    }

    setCurrentOrder(model) {

        if (model.data.status == 'new') {
            //    get order with parameter.
        }

        if (this.businessService.screenSize[0] > 1000) {
            let me = this;
            me.currentOrder = model;

            this.showCurrentOrder = false;
            setTimeout(function () {
                me.showCurrentOrder = true;
            }, 1)
        } else {
            this.goTo('order/' + model.data.id, {});
        }
    }

    getColor(prop) {
        return actionColors[prop];
    }
}
