import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {Routes, RouterModule} from '@angular/router';

import {IonicModule} from '@ionic/angular';

import {SectionItemsManagerPage} from './section-items-manager.page';
import {GlobalModule} from "../../../../../../../app/global-module/global-module.module";

const routes: Routes = [
    {
        path: '',
        component: SectionItemsManagerPage,
    },
    {
        path: 'section-items-manager/:id',
        component: SectionItemsManagerPage,
    },
    {
        path: 'section-items-manager/:id/section-items-manager/:id',
        component: SectionItemsManagerPage,
    },
    {
        path: 'section-items-manager/:id/section-items-manager/:id/section-items-manager/:id',
        component: SectionItemsManagerPage,
    },
    {
        path: 'section-items-manager/:id/section-items-manager/:id/section-items-manager/:id/section-items-manager/:id',
        component: SectionItemsManagerPage,
    },
    {
        path: 'section-items-manager/:id/section-items-manager/:id/section-items-manager/:id/section-items-manager/:id/section-items-manager/:id',
        component: SectionItemsManagerPage,
    },
    {
        path: 'section-items-manager/:id/section-items-manager/:id/section-items-manager/:id/section-items-manager/:id/section-items-manager/:id/section-items-manager/:id',
        component: SectionItemsManagerPage,
    },
    {
        path: 'section-items-manager/:id/section-items-manager/:id/section-items-manager/:id/section-items-manager/:id/section-items-manager/:id/section-items-manager/:id/section-items-manager/:id',
        component: SectionItemsManagerPage,
    },
    {
        path: 'section-items-manager/:id/section-items-manager/:id/section-items-manager/:id/section-items-manager/:id/section-items-manager/:id/section-items-manager/:id/section-items-manager/:id/section-items-manager/:id',
        component: SectionItemsManagerPage,
    },
    {
        path: 'section-items-manager/:id/section-items-manager/:id/section-items-manager/:id/section-items-manager/:id/section-items-manager/:id/section-items-manager/:id/section-items-manager/:id/section-items-manager/:id/section-items-manager/:id',
        component: SectionItemsManagerPage,
    }
];

@NgModule({
    imports: [
        GlobalModule,
        CommonModule,
        FormsModule,
        IonicModule,
        RouterModule.forChild(routes)
    ],
    declarations: [SectionItemsManagerPage]
})
export class SectionItemsManagerPageModule {
}
