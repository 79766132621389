import {Component, Input, OnInit} from '@angular/core';
import {Platform} from "@ionic/angular";
import {AppConfig} from "../../../../core/conf/main";
import {Events} from "../../../providers/events";

@Component({
    selector: 'app-img',
    templateUrl: './app-img.component.html',
    styleUrls: ['./app-img.component.scss'],
})
export class AppImgComponent implements OnInit {
    appConfig = AppConfig;

    @Input()
    src: object;

    @Input()
    images: Array<object>;

    @Input()
    fieldkey: string;

    @Input()
    datakey: string;

    @Input()
    boxStyle: object = {};

    @Input()
    style: object = {};

    @Input()
    height: any = 80;

    @Input()
    radius: any = 0;

    @Input()
    fullsrc: string;

    @Input()
    background: boolean = true;

    @Input()
    showImage: boolean = true;

    @Input()
    thumbnail: boolean = true;

    @Input()
    class: string;
    @Input()
    showBigHover: boolean = false;

    @Input()
    changePrefix: string;

    constructor(public platform: Platform, public events: Events) {
    }

    ngOnInit() {
        if (!this.style) this.style = {};
        this.setFullsrc();

        if (!this.style['border-radius'])
            this.style['border-radius'] = this.radius + 'px';

        if (this.background) {

            if (this.height && !this.style['height']) {
                if (!parseInt(this.height)) {
                    this.height = this.height.replace('px', '');
                }

                this.style['height'] = this.height;
            }
            // else
            //     this.style['height'] = '100%';
            // this.platform.ready().then((readSource) => {
            //     this.style['width'] = this.platform.width()+'px';
            // });
            // this.style['background-image'] = 'url(' + this.fullsrc + ')';
        }

        if (this.changePrefix) {
            this.events.subscribe(this.changePrefix, (params) => {
                this.src = params['src'];

                this.fullsrc = null;
                this.setFullsrc();
            })
        }


        var obj = {}
        for (var k in this.style)
            obj[k] = this.style[k];
        for (var k in this.boxStyle)
            obj[k] = this.boxStyle[k];
        this.boxStyle = obj;

        // //console.logthis.fullsrc)
    }

    setFullsrc(index = 0) {
        if (this.images && this.images.length)
            this.src = this.images[index];
        if (this.fullsrc) {
            this.src = {};
        } else {
            if (!this.src) {
                this.src = {}
                this.fullsrc = AppConfig.NONE_IMAGEURL ? AppConfig.NONE_IMAGEURL : 'assets/dashboard/none_img.png';
                if (this.background) {
                    this.style['background-image'] = 'url(' + this.fullsrc + ')';
                }
                return;
            }

            if (this.datakey && this.src[this.datakey])
                this.fullsrc = this.src[this.datakey][this.fieldkey];
            else {

                this.fullsrc = this.src[this.fieldkey];
                console.log(this.fullsrc)
            }

            //video
            if (this.fullsrc && this.fullsrc.includes('.mp4')) {
                // if (this.images && this.images[index + 1]) {
                //     this.fullsrc = null;
                //     this.setFullsrc(index + 1)
                //     return;
                // }
                // this.fullsrc = AppConfig.NONE_IMAGEURL;
                // return;
            }
        }

        if (this.thumbnail && !this.appConfig.avoid_thumbnails) {
            this.fullsrc += '.thumbnail';
        }

        if (this.background) {
            this.style['background-image'] = "url('" + this.fullsrc + "')";
        }

        // if(this.class)
        // {
        //     this.style['height'] = null;
        //     this.style['width'] = null;
        // }

        //
        // if(this.changePrefix)
        //     //console.log'fullsrc', this.fullsrc, this.src)
    }

    mouseEnter(imgUrl) {
        this.events.publish('showMainImageShow', {'imgUrl': imgUrl});
    }

    mouseLeave() {
        this.events.publish('hideMainImageShow');
    }
}