import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'objectKeys'
})
export class ObjectKeysPipe implements PipeTransform {

  transform(value: object, ...args) {
		if (value)
			return Object.keys(value);
		else
			return []
	}

}
